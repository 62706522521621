import {
  branch, compose, pure, renderNothing, withProps,
} from 'recompose';

import PreWrapper from './preWrapper';
import { parseTextStyles } from '../../utils';

const enhance = compose(
  branch(({ children }) => !children, renderNothing),
  pure,
  withProps(({ children, messageId }) => ({
    content: parseTextStyles(children, messageId),
  })),
);
export default enhance(PreWrapper);
