import React from 'react';
import PropTypes from 'prop-types';

import { FormsCollection } from '../../components';
import { FormProfile, AvatarProfile } from './components';
import './style.sass';
import { getFullName } from '../../utils/helpers/userHelpers';
import ScrollWrapper from '../../components/scrollWrapper';

const Profile = ({
  handleSubmit, handleChange, values, errors, setFieldValue, userAvatar, isPending,
}) => (
  <ScrollWrapper heightIs="100%">
    <div className="profile">
      <div className="profile__container col-lg-8 offset-lg-2 offset-sm-0">
        <div id="step-1y" className="card-body p-1">
          <div className="form-group">
            <FormsCollection.FormWrapper
              onSubmit={handleSubmit}
              className="profile__form"
            >
              <FormProfile
                pending={isPending}
                values={values}
                errors={errors}
                handleChange={handleChange}
              />
              <AvatarProfile
                alt={getFullName(values)}
                value={values.avatar}
                name="avatar"
                userAvatar={userAvatar}
                setFieldValue={setFieldValue}
                onChange={handleChange}
              />
            </FormsCollection.FormWrapper>
          </div>
        </div>
      </div>
    </div>
  </ScrollWrapper>
);

Profile.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  userAvatar: PropTypes.number,
  isPending: PropTypes.bool.isRequired,
};

Profile.defaultProps = {
  userAvatar: 0,
};

export default Profile;
