import React from 'react';

import { BrillMask, Avatar } from '../../../../components';
import './style.sass';

const History = () => (
  <div className="history">
    <ul className="history__list">
      <li className="history__item">
        <span className="history__time-line">
          1 January 2018
        </span>
        <ul className="history__list">
          <li className="history__item">
            <BrillMask className="history__user-icon" asLink to="/">
              <Avatar alt="Viktor Panasyuk" />
            </BrillMask>
            <span className="history__event">updated the Parent</span>
            <span className="history__time">10:35:00am</span>
          </li>
          <li className="history__item">
            <BrillMask className="history__user-icon" asLink to="/">
              <Avatar alt="Viktor Panasyuk" />
            </BrillMask>
            <span className="history__event">updated the Parent</span>
            <span className="history__time">10:35:00am</span>
          </li>
          <li className="history__item">
            <BrillMask className="history__user-icon" asLink to="/">
              <Avatar alt="Viktor Panasyuk" />
            </BrillMask>
            <span className="history__event">updated the Parent</span>
            <span className="history__time">10:35:00am</span>
          </li>
          <li className="history__item">
            <BrillMask className="history__user-icon" asLink to="/">
              <Avatar alt="Viktor Panasyuk" />
            </BrillMask>
            <span className="history__event">updated the Parent</span>
            <span className="history__time">10:35:00am</span>
          </li>
          <li className="history__item">
            <BrillMask className="history__user-icon" asLink to="/">
              <Avatar alt="Viktor Panasyuk" />
            </BrillMask>
            <span className="history__event">updated the Parent</span>
            <span className="history__time">10:35:00am</span>
          </li>
        </ul>
      </li>
      <li className="history__item">
        <span className="history__time-line">
          1 January 2018
        </span>
        <ul className="history__list">
          <li className="history__item">
            <BrillMask className="history__user-icon" asLink to="/">
              <Avatar alt="Viktor Panasyuk" />
            </BrillMask>
            <span className="history__event">updated the Parent</span>
            <span className="history__time">10:35:00am</span>
          </li>
          <li className="history__item">
            <BrillMask className="history__user-icon" asLink to="/">
              <Avatar alt="Viktor Panasyuk" />
            </BrillMask>
            <span className="history__event">updated the Parent</span>
            <span className="history__time">10:35:00am</span>
          </li>
          <li className="history__item">
            <BrillMask className="history__user-icon" asLink to="/">
              <Avatar alt="Viktor Panasyuk" />
            </BrillMask>
            <span className="history__event">updated the Parent</span>
            <span className="history__time">10:35:00am</span>
          </li>
          <li className="history__item">
            <BrillMask className="history__user-icon" asLink to="/">
              <Avatar alt="Viktor Panasyuk" />
            </BrillMask>
            <span className="history__event">updated the Parent</span>
            <span className="history__time">10:35:00am</span>
          </li>
          <li className="history__item">
            <BrillMask className="history__user-icon" asLink to="/">
              <Avatar alt="Viktor Panasyuk" />
            </BrillMask>
            <span className="history__event">updated the Parent</span>
            <span className="history__time">10:35:00am</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
);
export default History;
