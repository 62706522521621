import {
  compose, withHandlers, withProps, withState,
} from 'recompose';
import { connect } from 'react-redux';

import MembersView from './membersView';
import { usersSelectors } from '../../state/users';
import { uiActions } from '../../state/ui';
import { getMembersOnlineCount } from '../../utils/helpers/userHelpers/status';

const mapStateToProps = state => ({
  usersEntities: usersSelectors.getUsersEntities(state),
});

const mapDispatchToProps = ({
  setModal: uiActions.openModal,
  closeModal: uiActions.closeModal,
});

const onSetModalDeleteMemberHandler = ({ setModal, setSelectedMemberId }) => (memberId) => {
  setModal('deleteMember');
  setSelectedMemberId(memberId);
};

const onSetModalInviteMemberHandler = ({ setModal }) => () => setModal('inviteMembers');

const onDeleteHandler = ({ onDeleteMember, closeModal, selectedMemberId }) => () => {
  onDeleteMember(selectedMemberId, {
    success: () => closeModal('deleteMember'),
  });
};

const onInviteHandler = ({ onInviteMember }) => (memberIds, callbacks) => {
  onInviteMember(memberIds, callbacks);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('selectedMemberId', 'setSelectedMemberId', 0),
  withProps(({ membersList, usersEntities }) => ({
    countOnlineMembers: getMembersOnlineCount(membersList, usersEntities),
    countMembers: membersList.length,
  })),
  withHandlers({
    onSetModalDeleteMember: onSetModalDeleteMemberHandler,
    onSetModalInviteMember: onSetModalInviteMemberHandler,
    onDelete: onDeleteHandler,
    onInvite: onInviteHandler,
  }),
);

export default enhance(MembersView);
