import { compose, hoistStatics, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { withNamespaces } from 'react-i18next';

import { userActions, userSelectors } from '../../../state/user';
import ForgotPassword from './forgotPassword';
import { uiActions, uiSelectors } from '../../../state/ui';
import { TITLES_CONSTANTS } from '../../../constants/titles';

const { forgotPasswordFetch } = userActions;
const { displayNotification } = uiActions;

const mapDispatchToProps = ({
  forgotPasswordRequest: forgotPasswordFetch,
  displayNotification,
  setCurrentPage: uiActions.setPage,
});

const mapStateToProps = state => ({
  authNotification: uiSelectors.getAuthNotification(state),
  pending: userSelectors.getPendingForgotPasswordRequest(state),
});

const rules = yup.object().shape({
  email: yup
    .string()
    .email('Email is not valid')
    .required('Email is required'),
});

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'validation']),
  withFormik({
    validate: async (values, props) => {
      const { displayNotification: showNotification } = props;
      try {
        await rules.validate(values);
        showNotification(null);
      } catch (fail) {
        const { message } = fail;
        showNotification({
          [message.field]: {
            message: message.message,
            params: message.params,
          },
        });
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({ email: '' }),
    validationSchema: rules,
    handleSubmit: (values, { props: { forgotPasswordRequest } }) => {
      forgotPasswordRequest(values);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.PAGE_FORGOT_PASSWORD });
    },
    componentWillUnmount() {
      this.props.displayNotification(null);
    },
  }),
);

export default hoistStatics(enhancer)(ForgotPassword);
