import {
  curry, compose, identity, equals,
} from 'ramda';
import { Future } from 'ramda-fantasy';

const regexElements = {
  regOneSpace: '\\s',
  regAt: '[@]',
  regBreak: '<br>',
  regReply: '(^|\\n)>(?:.*)',
  regBold: '(?:\\*)(?:.*?)\\*',
  regItalic: '(\\s|^)(?:_)(?:.*?)_(\\s|$)',
  regStrike: '(?:~)(?:.*?)~',
  regCode: '(?:`)(?:.*?)`',
  regPer: '(?:```)(?:.*?)```',
  regEmoji: '(?:(?!::)(?::\\w*(?:(?:_|-)\\w*)*:))',
  regMention: '\\[(@[\\w.]*?\\s*?\\w*\\|@\\w+?)]',
  regMentionForReplace: '\\[(@[\\w.]*?\\s*?\\w*\\|@\\w+?)]',
};

const regexRules = {
  regOneSpace: new RegExp(regexElements.regOneSpace),
  regAt: new RegExp(regexElements.regAt),
  regBold: new RegExp(regexElements.regBold, 'gi'),
  regItalic: new RegExp(regexElements.regItalic, 'gi'),
  regStrike: new RegExp(regexElements.regStrike, 'gi'),
  regCode: new RegExp(regexElements.regCode, 'gi'),
  regPer: new RegExp(regexElements.regPer, 'gim'),
  regReply: new RegExp(regexElements.regReply, 'gim'),
  preWrapper: /```/gi,
  breakHtmlElement: /<br>/gi,
  regCharacterAfterSpace: /\s[\w*]/,
  regStartMention: /(^|\s)@/,
  regStartWord: /(^|\s)/,
  regFullEmojiString: new RegExp(regexElements.regEmoji, 'gim'),
  regAllHtml: /<[^>]*>/gim,
  regMention: /(^|\s)@([\w.]*)\s?([\w.]*)(\s\w*)?$/,
  regMentionForConvert: /^@([\w.]*)\s?([\w.]*)(\s\w*)?(\|@\w+)?$/,
  regEmojiForConvert: /(?:(?!::)(?::\w*(?:(?:_|-)\w*)*:))/,
  reqEmoji: /(?:(?!::)(?::\w*(?:(?:_|-)\w*)*:))/,
  regMentionElement: /[(@[\w.]*?\s*?\w*\|@\w+?]/gi,
  reqEmojiCode: /(?:(?!::)(?::\w*(?:(?:_|-)\w*)*:))/gim,
  regIsMentionCompleted: /(^|\s)@([\w.]+)\s([\w.]*)(\s\w*)?\s$/,
  regMentionToTag: /\[(@[\w.]*?\s*?\w*)]/,
  regMessageContent: /\[(@[\w.]*?\s*?\w*\|@\w+?)]|(:\w*((_|-)\w*)*:)|(<br>)|((^|\s)(?:\*)(?:.*?)\*(?:$|\s))|((^|\s)(?:_)(?:.*?)_(?:$|\s))|((^|\s)(?:~)(?:.*?)~(?:$|\s))|((^|\s)(?:`)(?:.*?)`(?:$|\s))|((^|\s)(?:```)(?:.*?)```(?:$|\s))|(^>(?:.*))/gi,
  regSpace: /&nbsp;/,
  regMentionToSelector: /<[a] data-id="([\w.]+)" .*?data-type="mention".*?>(.+?)<\/[a]>/,
  regDirtyCharacters: /(<!-- -->|&nbsp;)/g,
  regIdAndUsername: /(\w*)\|(@\w+)/,
  regTextElements: new RegExp(`(?:${regexElements.regMentionForReplace})|(${regexElements.regEmoji})|(${regexElements.regBreak}|\\s)`, 'g'),
  regTextStyles: new RegExp(`(${regexElements.regBold})|(${regexElements.regItalic})|(${regexElements.regStrike})|(${regexElements.regCode})`, 'g'),
  regTextWrappers: new RegExp(`(${regexElements.regReply})|(${regexElements.regPer})`, 'gi'),
  regEmojiText: /(^|\s*):\w*/gim,
};

const curryRegexTest = curry((rules, data) => rules.test(data));
const curryRegexMatch = curry((rules, string) => string.match(rules));
const currySplit = curry((rules, string) => string.split(rules));
const curryRegexReplace = curry((rules, callback, string) => string.replace(rules, callback));

const regexWithParams = curry((rule, params) => new RegExp(rule, params));

const replaceEachNode = curry((transform, valuePath, rules) => compose(
  transform,
  valuePath,
  curryRegexMatch(rules),
));

const requestPermission = success => Future(async (reject, resolve) => {
  const permission = await Notification.requestPermission();
  resolve(permission);
})
  .fork(identity, success);

const getNotificationPermission = () => Notification.permission;

const isNotificationPermission = type => equals(type, getNotificationPermission());

export {
  replaceEachNode,
  isNotificationPermission,
  getNotificationPermission,
  curryRegexReplace,
  requestPermission,
  currySplit,
  curryRegexTest,
  regexWithParams,
  curryRegexMatch,
  regexRules,
};
