import React from 'react';
import PropTypes from 'prop-types';

import ChannelsItem from '../channelsItem';
import './style.sass';
import { Pagination } from '../../../../components';

const ChannelsList = ({
  title, entities, result, t, count, itemsOnPage, isNext, isPrev, currentPage,
}) => (
  <>
    <div className="channels-list">
      <h2 className="channels-list__title">{title}</h2>
      <ul className="channels-list__list">
        {
        result.length ? result.map(channelId => (
          <ChannelsItem
            channel={entities[channelId]}
            key={entities[channelId].id}
          />
        ))
          : (
            <li className="channels-list__empty">
              {t('There are no channels yet')}
            </li>
          )
      }
      </ul>
    </div>
    <Pagination
      count={count}
      limit={itemsOnPage}
      isNext={isNext}
      isPrev={isPrev}
      currentPage={currentPage}
    />
  </>
);

ChannelsList.propTypes = {
  title: PropTypes.string.isRequired,
  entities: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  result: PropTypes.arrayOf(PropTypes.number).isRequired,
  itemsOnPage: PropTypes.number.isRequired,
  isNext: PropTypes.bool.isRequired,
  isPrev: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export default ChannelsList;
