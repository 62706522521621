import { compose, withHandlers } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import FiltersRow from './filtersRow';
import { uiActions } from '../../../../state/ui';

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};

const onOpenUploadFilesHandler = ({ setOpenModal }) => {
  setOpenModal('uploadFilesToProject');
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withNamespaces(['common', 'project']),
  withHandlers({
    onOpenUploadFiles: onOpenUploadFilesHandler,
  }),
);
export default enhance(FiltersRow);
