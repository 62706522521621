import { compose, lifecycle } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import ProjectsSummary from './projectsSummary';
import { projectsActions, projectsSelectors } from '../../../../state/projects';

const mapDispatchToProps = {
  getProjectsSummaryRequest: projectsActions.getProjectsSummaryRequest,
};

const mapStateToProps = state => ({
  summary: projectsSelectors.getProjectsSummary(state),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['tasks']),
  lifecycle({
    componentDidMount() {
      this.props.getProjectsSummaryRequest();
    },
  }),
);
export default enhance(ProjectsSummary);
