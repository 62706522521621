import React from 'react';
import PropTypes from 'prop-types';

import { Layouts, PermissionElement } from '../../components';
import {
  Header, LeftSideBar, ProjectsList,
} from '../../containers';
import './style.sass';
import { ACTIONS, MODULES } from '../../constants/pemissions';

const Projects = ({ onOpenCreateProjectModal }) => (
  <Layouts.Main className="page-project">
    <LeftSideBar />
    <div className="main-container page-project__main-container">
      <Header isInvertBg>
        <div className="row d-flex align-items-end justify-content-end">
          <PermissionElement module={MODULES.PROJECTS} action={ACTIONS.CREATE}>
            <button type="button" className="btn-primary btn-lg button-create-project btn-plus" onClick={onOpenCreateProjectModal}>
Add
              <span> new project</span>
              <i className="icon-plus-square" />
            </button>
          </PermissionElement>
        </div>
      </Header>
      <div className="page page-projects has-sidebar-left height-full">
        <ProjectsList />
      </div>
    </div>
  </Layouts.Main>
);

Projects.propTypes = {
  onOpenCreateProjectModal: PropTypes.func.isRequired,
};

export default Projects;
