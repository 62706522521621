import {
  always,
  cond,
  curry,
  identity, isNil,
  map,
  omit, pick, prop,
  propOr,
  reduce,
  replace,
  split,
  T,
  path,
  length,
  F,
  trim,
  when, keys,
  slice,
  equals, mergeRight, addIndex, toUpper, join, pathOr, test, lt, filter, includes,
} from 'ramda';
import { compose } from 'recompose';
import data from 'emoji-mart/data/messenger';
import React from 'react';
import { NimbleEmojiIndex } from 'emoji-mart';
import {
  convertSpanEmojiToImg,
  getEmojiHtmlStringById,
} from '../../utils/helpers/uiComponentHelpers/emojiHelpers';
import { getHTMLElementByString } from '../../utils/helpers/uiComponentHelpers/DOMhelpers';
import {
  curryRegexMatch, curryRegexReplace,
  curryRegexTest,
  currySplit,
  regexRules,
} from '../../utils/helpers/uiComponentHelpers/common';
import {
  BoldText, CodeText, ItalicText, PreWrapper, ReplyWrapper, StrikeText, SimpleText,
} from './components';
import MentionTag from '../../containers/chat/components/mentionTag';
import { isNotNil } from '../../utils/helpers/commonHelpers';

const mentionClass = 'mention-tag';
const ownerMentionClass = `${mentionClass} mention-tag--owner`;

const removeCharsetFromStr = curry((str, rule) => replace(rule, '', str));
const getEmojiElementById = compose(
  convertSpanEmojiToImg,
  getHTMLElementByString,
  getEmojiHtmlStringById,
);

const setDataCodeEmoji = curry((code, element) => {
  const el = element;
  if (propOr(false, 'setAttribute', el)) el.setAttribute('data-code', `${code}`);
  return el;
});

const getAllAttributes = compose(reduce((accum, { name, value }) => ({ ...accum, [name]: value }), {}), propOr([], 'attributes'));

const emojiComponentById = (string) => {
  if (!string) return '';
  if (string.length < 2) return string;
  let str = string;
  let emojiSearchInText;
  if (test(/:[\w\d]*:/, string)) {
    str = replace(/:/g, '', string);
  } else if (test(/[\W\d]{2,3}(?!:\s)(?!:$)/, string)) {
    const newStr = replace(/\s/g, '', str);
    const emojiIndex = new NimbleEmojiIndex(data);
    emojiSearchInText = emojiIndex.search(newStr, {
      maxResults: 1,
      emojisToShowFilter: compose(lt(0), length, filter((includes(newStr))), prop('emoticons')),
    });
  }
  if (test(/:/g, string) || emojiSearchInText) {
    const id = cond([
      [pathOr(false, [0, 'id']), path([0, 'id'])],
      [compose(lt(0), length, () => getEmojiHtmlStringById(str)), always(str)],
      [T, F],
    ])(emojiSearchInText);
    if (id) {
      const attrs = getAllAttributes(setDataCodeEmoji(id, getEmojiElementById(`${id}`)));
      const toCamelCase = replace(/\w-\w/g, compose(replace(/.$/, toUpper), join(''), split('-')));
      const transFormKeys = attributes => reduce((accum,
        val) => ({ [toCamelCase(val)]: attributes[val], ...accum }), {}, keys(attributes));

      const styles = reduce((accum, item) => ({
        ...accum,
        [trim(split(/:\s/, item)[0])]: split(/:\s/, item)[1],
      }), {}, split(/;/g, propOr('', 'style', attrs)));

      const EmojiComponent = () => React.createElement('img', { ...omit(['style', 'class'], { ...attrs, className: attrs.class }), style: transFormKeys(styles) });
      return <EmojiComponent />;
    }
  }

  return string;
};

const createMentionFiber = ({
  // eslint-disable-next-line react/prop-types
  username, id, className, onHandler,
}) => (
  <MentionTag
    onMentionClick={onHandler}
    username={username}
    id={Number(id)}
    className={className}
  />
);

const parseTextElements = (ownerId, onHandler, members, content) => compose(
  addIndex(map)((value, index) => cond([
    [curryRegexTest(regexRules.breakHtmlElement), () => (<br key={index} />)],
    [curryRegexTest(regexRules.regMentionForConvert), compose(
      cond([
        [compose(isNotNil, prop(1),
          curryRegexMatch(regexRules.regIdAndUsername)), compose(
          createMentionFiber,
          cond([
            [compose(equals(ownerId), prop('id')),
              mergeRight({ className: ownerMentionClass, onHandler })],
            [T, mergeRight({ className: mentionClass, onHandler })],
          ]),
          item => ({ id: item[1], username: replace(/@/g, '', item[2]) }),
          pick([1, 2]),
          curryRegexMatch(regexRules.regIdAndUsername),
        )],
        [T, identity],
      ]),
      slice(1, Infinity),
    )],
    [T, code => emojiComponentById(code, index)],
  ])(value)),
  currySplit(regexRules.regTextElements),
  curryRegexReplace(/\n/g, '<br>'),
  when(isNil, always('')),
)(content);
const parseTextStyles = curry((content, messageId) => compose(
  addIndex(map)((value, index) => cond([
    [curryRegexTest(regexRules.regBold), str => <BoldText key={`bold-text${index}and${messageId}`}>{removeCharsetFromStr(str, /\*/g)}</BoldText>],
    [curryRegexTest(regexRules.regStrike), str => <StrikeText key={`strike-text${index}and${messageId}`}>{removeCharsetFromStr(str, /~/g)}</StrikeText>],
    [curryRegexTest(regexRules.regItalic), str => <ItalicText key={`italic-text${index}and${messageId}`}>{removeCharsetFromStr(str, /_/g)}</ItalicText>],
    [curryRegexTest(regexRules.regCode), str => <CodeText key={`code-text${index}and${messageId}`}>{removeCharsetFromStr(str, /`/g)}</CodeText>],
    [T, str => <SimpleText key={`simple-text${index}and${messageId}`}>{str}</SimpleText>],
  ])(value)),
  currySplit(regexRules.regTextStyles),
  when(isNil, always('')),
)(content));

const parseStringWrappers = (content, messageId) => compose(
  addIndex(map)((value, index) => cond([
    [curryRegexTest(regexRules.regReply), str => <ReplyWrapper messageId={messageId} key={`reply-text${index}`}>{removeCharsetFromStr(str, /(^|\n)>/g)}</ReplyWrapper>],
    [curryRegexTest(regexRules.regPer), str => <PreWrapper messageId={messageId} key={`pre-text${index}`}>{removeCharsetFromStr(str, /```/g)}</PreWrapper>],
    [T, str => parseTextStyles(str, messageId)],
  ])(value)),
  currySplit(regexRules.regTextWrappers),
  when(isNil, always('')),
  curryRegexReplace(/<br>/g, '\n'),
)(content);

export {
  removeCharsetFromStr,
  parseStringWrappers, parseTextStyles,
  parseTextElements,
};
