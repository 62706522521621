import {
  __, always, append, cond, curry, gt, join, propSatisfies, T, take, when, compose,
} from 'ramda';
import CryptoJS from 'crypto-js';

const pasteWordInBrackets = word => `[${word}]`;

const isStartStringOrWord = curry((isStartString, isStartWord) => cond([
  [isStartString, always(true)],
  [isStartWord, always(true)],
  [T, always(false)],
]));

const encryptString = str => CryptoJS.AES.encrypt(str, process.env.REACT_APP_SALT, {
  iv: process.env.REACT_APP_IV,
}).toString();

const decryptString = (str) => {
  const bytes = CryptoJS.AES.decrypt(str, process.env.REACT_APP_SALT, {
    iv: process.env.REACT_APP_IV,
  });
  return bytes.toString(CryptoJS.enc.Utf8);
};

const truncate = length => when(
  propSatisfies(gt(__, length), 'length'),
  compose(
    join(''),
    append('...'),
    take(length),
  ),
);

export {
  isStartStringOrWord,
  pasteWordInBrackets,
  truncate,
  decryptString,
  encryptString,
};
