import {
  compose, lifecycle, withHandlers, withProps, withState, pure,
} from 'recompose';
import SizeAndPositionManagerInstance from './utils';

import VirtualList from './virtualList';
import { withRefs } from '../enchancers';

const onScrollHandler = ({ setOffset, onScrollRequest }) => (event) => {
  const { target: { scrollTop } } = event;
  onScrollRequest(scrollTop);
  setOffset(scrollTop);
};

const onSetScrollRefHandler = ({ setRef, scrollRef }) => (ref) => {
  setRef('scroll', ref);
  scrollRef(ref);
};

const enhance = compose(
  pure,
  withRefs(),
  withState('offset', 'setOffset', ({ initialOffset }) => initialOffset),
  withState('_forceUpdate', 'forceUpdate'),
  withProps(({
    height, itemSize, itemCount, overCount, defaultItemHeight,
  }) => ({
    sizeAndPositionManager: SizeAndPositionManagerInstance.getInstance(
      height,
      0,
      itemCount,
      defaultItemHeight,
      itemSize,
      overCount,
    ),
  })),
  withHandlers({
    onScroll: onScrollHandler,
    onSetScrollRef: onSetScrollRefHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.scrollToIndex && this.props.scrollToIndex >= 0) {
        const itemOffset = this.props.sizeAndPositionManager.getUpdatedOffsetForIndex(
          this.props.offset,
          this.props.scrollToIndex,
        );
        const scroll = this.props.getRef('scroll');
        scroll.scrollTop(itemOffset);
        setTimeout(() => {
          this.props.setScrollToIndex(null);
        }, 0);
      }
      if (this.props.itemCount !== prevProps.itemCount) {
        this.props.sizeAndPositionManager.setItemsCount(this.props.itemCount);
      }
    },
    componentWillUnmount() {
      SizeAndPositionManagerInstance.deleteInstance();
    },
  }),
);
export default enhance(VirtualList);
