/* eslint-disable import/prefer-default-export */
const ROUTE_TYPES = {
  AUTHENTICATED: 'authenticated',
  GUEST: 'guest',
};

const PRELOADER_DURATION = 1000;

const PRELOADER_DIMENSION = {
  EXTRA_SMALL: 50,
  SMALL: 75,
  MIDDLE: 100,
  DEFAULT: 125,
  LARGE: 200,
};

const KEYS = {
  ENTER: 13,
  TAB: 9,
  ESCAPE: 27,
  DOWN_ARROW: 40,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  UP_ARROW: 38,
  SHIFT: 16,
};

const STATUS = {
  ONLINE: 1,
  OFFLINE: 2,
};

const WINDOW_WIDTH = {
  MIN_WIDTH: 320,
  X_SMALL: 481,
  SMALL: 769,
  MEDIUM: 981,
  LARGE: 1241,
  X_LARGE: 1661,
  EXTRA_LARGE: 2101,
};

const TYPES_NOTIFICATION = {
  MESSAGE: 1,
};

export {
  ROUTE_TYPES,
  TYPES_NOTIFICATION,
  KEYS,
  STATUS,
  PRELOADER_DURATION,
  PRELOADER_DIMENSION,
  WINDOW_WIDTH,
};
