/* eslint-disable import/prefer-default-export */
const STYLE_VIEW = {
  CELL: 'cell',
  LIST: 'list',
};

const TYPE = {
  PUBLIC: 1,
  PRIVATE: 0,
};
const STATUS = {
  DEFAULT: 1,
  COMPLETED: 4,
};

export {
  STYLE_VIEW,
  TYPE,
  STATUS,
};
