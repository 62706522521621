import React from 'react';
import PropTypes from 'prop-types';

const ClearIndicator = ({ innerProps }) => (
  <button className="close-button" type="button" onClick={innerProps.onMouseDown}>
    <span className="icon-close" />
  </button>
);

ClearIndicator.propTypes = {
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
};

export default ClearIndicator;
