import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const AttachImage = ({
  onBlur,
  name, labelText, id, className, value, error, touched, onDeleteFile, onUploadFile, fileUrl, disabled, title,
}) => (
  <div className={`attach-image-field ${className}`}>
    <input
      type="file"
      onChange={onUploadFile}
      name={name}
      onBlur={onBlur}
      className="attach-image-field__field"
      id={id}
      disabled={disabled}
    />
    {
        value ? (
          <div className="attach-image-field__preview-row">
            <div className="attach-image-field__preview-card">
              <div
                title="Delete image"
                className="attach-image-field__preview-delete-button pointer"
                role="button"
                onClick={onDeleteFile}
              >
                <i className="icon-trash text-size--md" />
              </div>
              <img alt="Logo for project" className="attach-image-field__preview-image" src={fileUrl || value} />
            </div>
          </div>
        ) : (
          <label className="btn-success btn-sm btn" title={title} htmlFor={id}>
            {labelText}
          </label>
        )
      }
    {typeof error === 'string' && error.length !== 0 && touched && (
      <span className="attach-image-field__error field__error-text">
        { error}
      </span>
    )}
  </div>
);

AttachImage.propTypes = {
  isPending: PropTypes.bool.isRequired,
  fileUrl: PropTypes.string.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  labelText: PropTypes.string,
  id: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  onBlur: PropTypes.func,
  onDeleteFile: PropTypes.func.isRequired,
  error: PropTypes.string,
};

AttachImage.defaultProps = {
  value: '',
  labelText: 'Add Image',
  onBlur: () => {},
  touched: false,
  className: '',
  error: '',
  disabled: false,
  title: '',
};

export default AttachImage;
