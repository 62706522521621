import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const InviteMember = ({
  isOnline, onClick, t,
}) => (
  <button
    type="button"
    onClick={onClick}
    className="btn btn-sm btn-outline-primary"
    disabled={!isOnline}
    title={!isOnline ? 'Only in online version' : ''}
  >
    {t('Invite members')}
    <span className="icon-add_circle invite-member__icon" />
  </button>
);

InviteMember.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default InviteMember;
