import React from 'react';
import PropTypes from 'prop-types';

const CardActivity = ({ title, children }) => (
  <div className="card no-b my-3 shadow2">
    <div className="card-header white no-b p-2 p-lg-5 d-flex justify-content-between align-items-center ">
      <h4 className="m-0">
        {title}
      </h4>
    </div>
    <div className="card-body pb-lg-5 pl-lg-5 pr-lg-5 p-2">
      {children}
    </div>
  </div>
);
CardActivity.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CardActivity;
