import {
  compose, lifecycle, withContext, withHandlers, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Users from './users';
import { openModal, setPage } from '../../state/ui/actions';
import { TITLES_CONSTANTS } from '../../constants/titles';
import withPermission from '../../utils/enchancers/withPemission';
import { MODULES } from '../../constants/pemissions';

const mapDispatchToProps = ({
  setOpenModal: openModal,
  setCurrentPage: setPage,
});

const onEditUserModalHandler = ({ setSelectedUserId, setOpenModal }) => (userId) => {
  setOpenModal('addEvent');
  setSelectedUserId(userId);
};

const onDeleteUserModalHandler = ({ setSelectedUserId, setOpenModal }) => (userId) => {
  setOpenModal('deleteUser');
  setSelectedUserId(userId);
};

const onInviteUsersModalHandler = ({ setOpenModal }) => () => {
  setOpenModal('inviteMembersModal');
};

const enhancer = compose(
  withPermission([MODULES.USERS]),
  connect(null, mapDispatchToProps),
  withState('selectedUserId', 'setSelectedUserId', 0),
  withState('sort', 'setSelectedSort', {}),
  withHandlers({
    onEditUserModal: onEditUserModalHandler,
    onDeleteUserModal: onDeleteUserModalHandler,
    onInviteUsersModal: onInviteUsersModalHandler,
  }),
  withContext({
    selectedUserId: PropTypes.number,
    onEditUserModal: PropTypes.func,
    onDeleteUserModal: PropTypes.func,
    setSelectedSort: PropTypes.func,
  }, props => ({
    selectedUserId: props.selectedUserId,
    onEditUserModal: props.onEditUserModal,
    onDeleteUserModal: props.onDeleteUserModal,
    setSelectedSort: props.setSelectedSort,
  })),
  lifecycle({
    componentDidMount() {
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.PAGE_USERS });
    },
  }),
);

export default enhancer(Users);
