import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';

import ForgotPasswordSuccess from './forgotPasswordSuccess';

const enhancer = compose(
  withNamespaces('common'),
);

export default enhancer(ForgotPasswordSuccess);
