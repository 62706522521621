const addProjectResponseSchema = ({
  // eslint-disable-next-line camelcase
  title = '', author = '', created_at = '', type = 1, id = new Date().getTime(), ...data
}) => ({
  project: {
    title,
    description: data.description || '',
    type,
    status: 1,
    deadline_time: data.deadline || '',
    start_time: data.deadline || '',
    created_by: author.id,
    name: title,
    created_at,
    updated_at: created_at,
    id,
    image: null,
    members: [],
    author: {},
    customer: null,
    isPending: true,
  },
}
);

const editProjectResponseSchema = data => (
  {
    project: {
      ...data,
      isUpdating: true,
    },
  }
);

const deleteProjectResponseSchema = ({ ...data }) => (
  {
    project: {
      ...data,
      isDeleting: true,
    },
  }
);

const getProjectResponseSchema = data => ({
  data: {
    entities: {
      data: {
        [data.id]: data,
      },
    },
    result: {
      projects: [data.id],
    },
    summary: {
      allCount: 0,
      doneCount: 0,
      inProgressCount: 0,
    },
  },
  project: data,
});


const getTasksResponseSchema = data => ({
  data,
});


export {
  addProjectResponseSchema,
  editProjectResponseSchema,
  deleteProjectResponseSchema,
  getProjectResponseSchema,
  getTasksResponseSchema,
};
