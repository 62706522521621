import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const TextArea = ({
  name,
  onChange,
  label,
  disabled,
  rows,
  id,
  className,
  value,
  inputClass,
  error,
}) => (
  <div className={`form-group ${className}`}>
    {label && (
      <label htmlFor={id} className="col-form-label">{label}</label>)}
    <textarea
      name={name}
      disabled={disabled}
      onChange={onChange}
      className={`form-control ${inputClass}`}
      id={id}
      rows={rows}
    >
      {value}
    </textarea>
    {error && (<small className="text-danger">{error}</small>)}
  </div>
);
TextArea.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
  placeholderJump: PropTypes.string,
  onKeyDown: PropTypes.func,
  isFocus: PropTypes.bool,
  className: PropTypes.string,
  autoHeight: PropTypes.bool,
  tabIndex: PropTypes.number,
  t: PropTypes.func.isRequired,
  children: PropTypes.oneOfType(
    [PropTypes.element,
      PropTypes.string,
      PropTypes.array],
  ),
  value: PropTypes.string,
  isFill: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  dangerouslySetInnerHTML: PropTypes.instanceOf(Object),
  stylesScroll: PropTypes.instanceOf(Object).isRequired,
  onSetRefField: PropTypes.func.isRequired,
  setFocusStatus: PropTypes.func.isRequired,
  onSetRefScroll: PropTypes.func.isRequired,
  onUpdateScroll: PropTypes.func,
  onKeyUp: PropTypes.func,
};

TextArea.defaultProps = {
  name: '',
  error: '',
  touched: false,
  onBlur: () => {},
  value: '',
  onChange: () => {},
  placeholderJump: '',
  onFocus: () => {},
  onKeyPress: null,
  isFocus: false,
  onKeyDown: null,
  className: '',
  autoHeight: false,
  tabIndex: 0,
  onKeyUp: () => {},
  children: '',
  placeholder: '',
  dangerouslySetInnerHTML: {},
  onUpdateScroll: () => {},
};
export default TextArea;
