import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { UserItem } from '../index';

const CustomersList = ({
  customersList,
}) => (
  <tbody>
    {
    customersList.map(customerId => (
      <UserItem customerId={customerId} />
    ))
  }
  </tbody>
);

CustomersList.propTypes = {
  title: PropTypes.string.isRequired,
  entities: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  result: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default CustomersList;
