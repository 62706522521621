import React from 'react';
import PropTypes from 'prop-types';

import { EditNote, DeleteNote, CreateNote } from './components/notesCRUD';
import { ScrollWrapper } from '../../components';
import {
  FilterRow, NotesList, AttachFilesModal, DeleteFileModal,
} from './components';
import './style.sass';

const ProjectsNotes = ({ match: { params: { id } }, onChangeSorting, sortBy }) => (
  <ScrollWrapper>
    <FilterRow onChangeSorting={onChangeSorting} sortBy={sortBy} />
    <NotesList sortBy={sortBy} />
    <EditNote projectId={id} />
    <DeleteNote projectId={id} />
    <CreateNote projectId={id} />
    <AttachFilesModal />
    <DeleteFileModal />
  </ScrollWrapper>
);

ProjectsNotes.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  onChangeSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.instanceOf(Object).isRequired,
};

export default ProjectsNotes;
