import {
  compose, lifecycle, withContext, withHandlers, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Materials from './materials';
import { openModal, setPage } from '../../state/ui/actions';
import { TITLES_CONSTANTS } from '../../constants/titles';
import withPermission from '../../utils/enchancers/withPemission';
import { MODULES } from '../../constants/pemissions';

const mapDispatchToProps = ({
  setOpenModal: openModal,
  setCurrentPage: setPage,
});

const onEditMaterialModalHandler = ({
  setSelectedMaterialId,
  setOpenModal,
}) => (selectedMaterialId) => {
  setOpenModal('editMaterialModal');
  setSelectedMaterialId(selectedMaterialId);
};

const onDeleteMaterialModalHandler = ({
  setSelectedMaterialId,
  setOpenModal,
}) => (selectedMaterialId) => {
  setOpenModal('deleteMaterialModal');
  setSelectedMaterialId(selectedMaterialId);
};

const onOpenAddMaterialModalHandler = ({ setOpenModal }) => () => {
  setOpenModal('addMaterial');
};

const enhancer = compose(
  withPermission([MODULES.MATERIALS]),
  connect(null, mapDispatchToProps),
  withState('selectedMaterialId', 'setSelectedMaterialId', 0),
  withState('sort', 'setSelectedSort', {}),
  withHandlers({
    onOpenAddMaterialModal: onOpenAddMaterialModalHandler,
    onEditMaterialModal: onEditMaterialModalHandler,
    onDeleteMaterialModal: onDeleteMaterialModalHandler,
  }),
  withContext({
    setSelectedMaterialId: PropTypes.func,
    onEditMaterialModal: PropTypes.func,
    onDeleteMaterialModal: PropTypes.func,
    setSelectedSort: PropTypes.func,
    selectedMaterialId: PropTypes.number,
  }, props => ({
    onEditMaterialModal: props.onEditMaterialModal,
    onDeleteMaterialModal: props.onDeleteMaterialModal,
    setSelectedMaterialId: props.setSelectedMaterialId,
    selectedMaterialId: props.selectedMaterialId,
  })),
  lifecycle({
    componentDidMount() {
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.PAGE_MATERIALS });
    },
  }),
);

export default enhancer(Materials);
