import {
  compose, lifecycle, defaultProps, getContext, withHandlers, withProps, withState, withContext,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import Customer from './customer';
import { customersActions, customersSelectors } from '../../state/customers';
import { uiActions } from '../../state/ui';

const ITEMS_ON_PAGE = 10;

const mapStateToProps = (state, { match: { params: { customerId } } }) => ({
  customersList: customersSelectors.getCustomersResult(state),
  customersCount: customersSelectors.getCustomersCount(state),
  customer: customersSelectors.getCustomer(state)(customerId),
});

const mapDispatchToProps = {
  getCustomerRequest: customersActions.getCustomerRequest,
  setOpenModal: uiActions.openModal,
};

const loadMoreRequestHandler = ({
  itemsOnPage, getCustomersRequest, currentPage,
  selectedSearch, selectedSort,
}) => () => {
  const offset = parseInt((currentPage - 1) * itemsOnPage, 10);
  getCustomersRequest({
    limit: itemsOnPage,
    offset,
    sortBy: 'username',
    order: selectedSort.value,
    name: selectedSearch,
  });
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setSelectedSort: PropTypes.func,
  }),
  withRouter,
  withState('selectedSearch', 'setSelectedSearch', ''),
  withState('selectedSort', 'setSelectedSort', {}),
  defaultProps({
    itemsOnPage: ITEMS_ON_PAGE,
  }),
  withProps(({ history, customersCount }) => {
    const page = parseInt(history.location.search.match(/(?!page=)\d*/gi)[5] || 1, 10);
    return {
      currentPage: page,
      isPrev: page > 1,
      isNext: page * ITEMS_ON_PAGE < customersCount,
    };
  }),
  withHandlers(() => ({
    loadMoreRequest: loadMoreRequestHandler,
  })),
  withContext({
    selectedSearch: PropTypes.string,
    selectedSort: PropTypes.shape({}),
    setSelectedSort: PropTypes.func,
    setSelectedSearch: PropTypes.func,
  }, props => ({
    selectedSearch: props.selectedSearch,
    selectedSort: props.selectedSort,
    setSelectedSearch: props.setSelectedSearch,
    setSelectedSort: props.setSelectedSort,
  })),
  getContext({
    onEditCustomerModal: PropTypes.func,
  }),
  lifecycle({
    componentDidMount() {
      this.props.getCustomerRequest({ customerId: this.props.match.params.customerId });
    },
  }),

);
export default enhance(Customer);
