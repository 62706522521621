import React from 'react';
import PropTypes from 'prop-types';

import { ModalWindow, FormsCollection } from '../../../../components';
import { FilesField, FilesItem } from './components/index';
import './style.sass';

const AttachFilesModal = ({
  isOpen, onSetTaskFiles, isPending, onCloseModal, onChange, loadingFiles, onDeleteFile,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    modalName="attachFiles"
    title="Upload file"
    className="modal-window__attach-files"
  >
    <FormsCollection.FormWrapper
      onSubmit={onSetTaskFiles}
      className="attach-files"
    >
      <FilesField pending={isPending} onChange={onChange} />
      <div className="attach-files__uploaded-files">
        {
          loadingFiles.map(item => (
            <FilesItem item={item} key={`item-file${item.filename}`} onDelete={onDeleteFile} />
          ))
        }
      </div>
      <div className="button-group">
        <button
          type="submit"
          className="btn-primary btn w-100px"
        >
          UPLOAD
        </button>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);
AttachFilesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSetTaskFiles: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loadingFiles: PropTypes.instanceOf(Array).isRequired,
  onDeleteFile: PropTypes.func.isRequired,
};

export default AttachFilesModal;
