import React from 'react';
import PropTypes from 'prop-types';

import CoWorkerItem from './coWorkerItem';
import {
  TabWrapper,
} from '../../../../components';
import './style.sass';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';
import Preloader from '../../../../components/preloader';

const CoWorkers = ({
  usersList, setRef, isCoWorkersTabOpen, changeCoWorkersTabStatus, onSetRefContainer, isPreloader,
}) => (
  <div className={`co-workers ${isPreloader ? 'co-workers--pending' : ''}`} ref={onSetRefContainer}>
    <div className="co-workers__nav">
      <TabWrapper onChangeStatusTab={changeCoWorkersTabStatus} defaultStatusTab={isCoWorkersTabOpen} title="Co-Workers">
        {
          (isPreloader && isCoWorkersTabOpen) && (
            <Preloader duration={800} dimension={PRELOADER_DIMENSION.EXTRA_SMALL} />
          )
        }
        {
          <ul className="co-workers__list">
            {
                (usersList.length ? usersList.map(id => (
                  <CoWorkerItem
                    customRef={setRef}
                    key={`coWorkerItem${id}`}
                    id={id}
                  />
                )) : <span className="co-workers__empty">There are no co-workers yet</span>)
            }
          </ul>
        }
      </TabWrapper>
    </div>
  </div>
);

CoWorkers.propTypes = {
  usersList: PropTypes.arrayOf(PropTypes.number).isRequired,
  isCoWorkersTabOpen: PropTypes.bool.isRequired,
  changeCoWorkersTabStatus: PropTypes.func,
  setRef: PropTypes.func.isRequired,
  onSetRefContainer: PropTypes.func.isRequired,
  isPreloader: PropTypes.bool.isRequired,
};

CoWorkers.defaultProps = {
  changeCoWorkersTabStatus: () => {},
};

export default CoWorkers;
