import React from 'react';
import PropTypes from 'prop-types';
import FilterFields from './components';
import './style.sass';

const Filers = ({ filterBy, activeView }) => (
  <div className="statistics-filters">
    <div className="statistics-filters__row">
      <div className="statistics-filters__group-change-view__row flex-row">
        <button
          type="button"
          className={`statistics-filters__group-change-view__button ${activeView === 'myStatistic' ? 'statistics-filters__group-change-view__button--active' : ''}`}
          onClick={() => filterBy('myStatistic')}
        >
          My statistic
        </button>
        <button
          type="button"
          className={`statistics-filters__group-change-view__button ${activeView === 'allUsersStatistic' ? 'statistics-filters__group-change-view__button--active' : ''}`}
          onClick={() => filterBy('allUsersStatistic')}
        >
          Users statistic
        </button>
      </div>
      <div className="statistics-filters__group-fields">
        <FilterFields activeView={activeView} />
      </div>
    </div>
  </div>
);

Filers.propTypes = {
  filterBy: PropTypes.func.isRequired,
  activeView: PropTypes.string,
};
Filers.defaultProps = {
  activeView: '',
};

export default Filers;
