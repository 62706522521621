import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';

import { FormsCollection } from '../../../../components';
import './style.sass';

const FormProfile = ({
  t, values, handleChange, errors,
}) => (
  <div className="form-profile col-lg-8">
    <div className="form-row">
      <FormsCollection.Input
        label="Username"
        className="col-md-6"
        id="profile_name"
        name="username"
        error={path(['username', 'message'], errors)}
        placeholder="User name"
        value={values.username}
        onChange={handleChange}
      />
      <FormsCollection.Input
        label="Position"
        className="col-md-6"
        id="profile_position"
        placeholder="User position"
        name="position"
        value={values.position}
        onChange={handleChange}
      />
    </div>
    <div className="form-row">
      <FormsCollection.Input
        label="First name"
        className="col-md-6"
        id="profile_first-name"
        error={path(['first_name', 'message'], errors)}
        name="first_name"
        value={values.first_name}
        onChange={handleChange}
      />
      <FormsCollection.Input
        label="Last name"
        className="col-md-6"
        error={path(['last_name', 'message'], errors)}
        id="profile_last-name"
        name="last_name"
        value={values.last_name}
        onChange={handleChange}
      />
    </div>
    <div className="form-row">
      <FormsCollection.Input
        label="Password"
        className="col-md-6"
        id="profile_password"
        name="password"
        type="password"
        value={values.password}
        onChange={handleChange}
      />
      <FormsCollection.Input
        label="Confirm password"
        className="col-md-6"
        id="profile_confirm-password"
        name="confirmPassword"
        type="password"
        error={path(['confirmPassword', 'message'], errors)}
        value={values.confirmPassword}
        onChange={handleChange}
      />
    </div>
    <div className="form-row">
      <FormsCollection.Input
        label="Email"
        className="col-md-3"
        id="profile_email"
        name="email"
        error={path(['email', 'message'], errors)}
        placeholder="example@gmail.com"
        value={values.email}
        onChange={handleChange}
      />
      <FormsCollection.Input
        label="Phone"
        className="col-md-3"
        id="profile_phone"
        placeholder="+380 (XXX) XXXXXX"
        name="phone"
        value={values.phone}
        onChange={handleChange}
      />
      <FormsCollection.Input
        label="Facebook"
        className="col-md-3"
        id="profile_facebook"
        name="facebook"
        placeholder="http://facebook.com/id"
        value={values.facebook}
        onChange={handleChange}
      />
      <FormsCollection.Input
        label="Skype"
        className="col-md-3"
        id="profile_skype"
        placeholder="nick_name"
        name="skype"
        value={values.skype}
        onChange={handleChange}
      />
    </div>
    <div className="form-profile__row form-profile__row--buttons">
      <button className="button btn-lg btn-primary w-150px" type="submit">Save</button>
      {/* <button type="button" className="form-profile__cancel-button form-profile__control-button"> */}
      {/*  {t('Cancel')} */}
      {/* </button> */}
    </div>
  </div>
);

FormProfile.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
  onRemoveDisabled: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default FormProfile;
