import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const PlusButton = ({
  onClick, className, onMouseOver, onMouseLeave, title,
}) => (
  <button
    onMouseLeave={onMouseLeave}
    onMouseOver={onMouseOver}
    type="button"
    title={title}
    onClick={onClick}
    onFocus={() => {}}
    className={`plus-button ${className}`}
  >
    <span className="plus-button__hexagon icon-hexagon-shape" />
    <span className="plus-button__plus icon-plus-icon-2" />
  </button>
);

PlusButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  onMouseLeave: PropTypes.func,
  onMouseOver: PropTypes.func,
  title: PropTypes.string,
};

PlusButton.defaultProps = {
  onClick: () => {},
  onMouseLeave: () => {},
  onMouseOver: () => {},
  className: '',
  title: '',
};
export default PlusButton;
