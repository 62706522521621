import React from 'react';
import PropTypes from 'prop-types';

import { pathOr, prop } from 'ramda';
import moment from 'moment';
import { FormsCollection, ModalWindow } from '../../../components';

const ModalView = ({
  isOpen,
  onCloseModal,
  handleChange,
  handleBlur,
  handleSubmit,
  values,
  errors,
  title,
  titleAction,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    modalName=""
    title={title}
    className="new-sprint__modal"
  >
    <div id="step-1y" className="card-body p-1">
      <FormsCollection.FormWrapper onSubmit={handleSubmit}>
        <div className="form-row">
          <FormsCollection.Input
            label="Title"
            className="col-md-12"
            id="add-event__title"
            name="title"
            placeholder="Event title"
            value={values.title}
            error={pathOr('', ['title', 'message'], errors)}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <FormsCollection.TextArea
            label="Description"
            className="col-md-12"
            id="add-event__description"
            name="description"
            placeholder="Description..."
            value={values.description}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <FormsCollection.Select
            label="Color"
            className="col-md-12"
            id="edit-user__role"
            name="color"
            value={values.color}
            onChange={handleChange}
            data={[
              {
                id: '1',
                value: '#2786ff',
                color: '#2786ff',
                label: 'Blue',
              },
              {
                id: '2',
                value: '#eec066',
                color: '#eec066',
                label: 'Yellow',
              },
              {
                id: '3',
                value: '#209961',
                color: '#209961',
                label: 'Green',
              },
              {
                id: '3',
                value: '#ff5fda',
                color: '#ff5fda',
                label: 'Pink',
              },
              {
                id: '3',
                value: '#262927',
                color: '#262927',
                label: 'Black',
              },
            ]}
          />
        </div>
        <div className="form-row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-3 d-flex align-items-center">
                <div>Start</div>
              </div>
              <div className="col-lg-6">
                <FormsCollection.DatePicker
                  className="date-picker"
                  name="start_date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.start_date}
                  touched
                  error={prop('message', errors.start_date)}
                  id="date-picker-start__new-sprint-modal"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-3 d-flex align-items-center">
                <div>Finish</div>
              </div>
              <div className="col-lg-6">
                <FormsCollection.DatePicker
                  className="date-picker"
                  name="end_date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched
                  error={prop('message', errors.end_date)}
                  value={values.end_date}
                  minDate={moment(values.start_date).add(0, 'days').toDate()}
                  id="date-picker-finish__new-sprint-modal"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-row p-2">
          <button
            className="btn btn-primary w-100px d-flex justify-content-center"
            type="submit"
          >
            { titleAction}
          </button>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </ModalWindow>
);
ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
};

export default ModalView;
