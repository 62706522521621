import { createActions } from 'redux-actions';
import { identity, pathOr } from 'ramda';

import types from './types';
import { eventsListSchema } from './schema';

export const {
  addEventRequest,
  deleteEventRequest,
  getEventsRequest,
  updateEventRequest,
  setEvent,
  setEvents,
  deleteEvent,
  reorderEvents,
  updateEvent,
} = createActions(
  {
    [types.ADD_EVENT_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/events',
        selectorName: 'addEventRequest',
        method: 'POST',
        params,
        actions: {
          success: setEvent,
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.DELETE_EVENT_REQUEST]: [
      identity,
      ({ eventId, ...params }, meta) => ({
        async: true,
        route: `/events/${eventId}`,
        selectorName: 'deleteEventRequest',
        method: 'DELETE',
        params,
        actions: {
          success: deleteEvent,
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.GET_EVENTS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/events',
        selectorName: 'getEventsRequest',
        method: 'GET',
        params,
        actions: {
          success: setEvents,
        },
        schema: {
          rules: eventsListSchema,
          pathData: ['data'],
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.UPDATE_EVENT_REQUEST]: [
      identity,
      ({ eventId, ...params }, meta) => ({
        async: true,
        route: `/events/${eventId}`,
        selectorName: 'updateEventRequest',
        method: 'PUT',
        params,
        actions: {
          success: updateEvent,
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
  },
  types.SET_EVENT,
  types.SET_EVENTS,
  types.DELETE_EVENT,
  types.REORDER_EVENTS,
  types.UPDATE_EVENT,
);
