/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import { prop, map } from 'ramda';
import { Link } from 'react-router-dom';
import { Avatar } from '../../../../components';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import PermissionElement from '../../../../components/permissionElement';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const CustomerItem = ({
  customerAvatar, customer, onEditCustomerModal, onDeleteCustomerModal,
}) => (
  <tr className="customer-item">
    <td>
      <div className="custom-control custom-checkbox">
        {prop('id', customer)}
      </div>
    </td>
    <td>
      <div className="avatar avatar-md mr-3 mt-1 float-left">
        <Avatar src={customerAvatar} className="customer-item__avatar" alt={getFullName(customer).length > 1 ? getFullName(customer) : prop('username', customer)} />
      </div>
      <div>
        <div>
          <Link to={`/customers/${customer.id}`}>{getFullName(customer).length > 1 ? getFullName(customer) : prop('username', customer)}</Link>
        </div>
        <small>{prop('email', customer)}</small>
      </div>
    </td>

    <td>
      <ul className="customer-item__projects-list">
        { customer.projects.length ? map(project => <li><Link to={`/projects/${project.id}`}>{project.title}</Link></li>, customer.projects) : '-'}
      </ul>
    </td>
    <td>
      {customer.address || '-'}
    </td>
    <td>{prop('registration_number', customer) || '-'}</td>
    <td>{prop('company_name', customer) || '-'}</td>
    <td>
      <ul className="d-flex social-icons align-items-center">
        <li>
          {prop('facebook', customer) && (
            <a
              title={prop('facebook', customer)}
              target="_blank"
              rel="noopener noreferrer"
              href={prop('facebook', customer)}
              className="icon-facebook-official"
            >
              <span>Facebook</span>
            </a>
          )}
        </li>
        <li>
          {prop('website', customer) && <a title={prop('website', customer)} href={`${prop('website', customer)}`} rel="noopener noreferrer" target="_blank" className="icon-email2"><span>Website</span></a>}
        </li>
        <li>
          {prop('phone', customer) && <a title={prop('phone', customer)} href={`customer.phone${prop('phone', customer)}`} rel="noopener noreferrer" target="_blank" className="icon-phone"><span>Phone</span></a>}
        </li>
        <li>
          {prop('email', customer) && <a title={prop('email', customer)} href={`mailto:${prop('email', customer)}`} rel="noopener noreferrer" target="_blank" className="icon-message"><span>Email</span></a>}
        </li>
      </ul>
    </td>
    <td>
      <PermissionElement module={MODULES.CUSTOMERS} action={ACTIONS.UPDATE}>
        <span
          className="link-color p-1"
          role="button"
          onClick={() => onEditCustomerModal(prop('id', customer))}
        >
          <i className="icon-pencil" />
        </span>
      </PermissionElement>

      <PermissionElement module={MODULES.CUSTOMERS} action={ACTIONS.DELETE}>
        <span
          role="button"
          className="link-color p-1"
          onClick={() => onDeleteCustomerModal(prop('id', customer))}
        >
          <i className="icon-trash" />
        </span>
      </PermissionElement>
    </td>
  </tr>
);

CustomerItem.propTypes = {
  customerAvatar: PropTypes.string.isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  onEditCustomerModal: PropTypes.func.isRequired,
  onDeleteCustomerModal: PropTypes.func.isRequired,
};

export default CustomerItem;
