import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import { FormsCollection } from '../../../../components';

const MessageTextArea = ({
  onChange,
  className,
  value,
  name,
  heightHandler,
  toggleFocusStateHandler,
  onSetRef,
  onKeyPress,
  onKeyDown,
}) => (
  <>
    <FormsCollection.ContentEditable
      autoHeight
      maxHeight={210}
      childrenRef={onSetRef}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
      isFocus
      onUpdateScroll={heightHandler}
      onFocus={() => toggleFocusStateHandler(true)}
      onBlur={() => toggleFocusStateHandler(false)}
      onChange={onChange}
      value={value}
      name={name}
      placeholder="Type a message"
      className={className}
    />
  </>
);

MessageTextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSetRef: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  heightHandler: PropTypes.func.isRequired,
  toggleFocusStateHandler: PropTypes.func.isRequired,
};

MessageTextArea.defaultProps = {
  className: '',
  onKeyDown: null,
};

export default MessageTextArea;
