import {
  branch,
  compose, lifecycle, renderComponent, withHandlers, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { includes } from 'ramda';
import View from './view';
import { offlineRequestActions } from '../../../utils/middlewares/sagaRequestApi/state/offlineRequest/index';
import { uiActions, uiSelectors } from '../../../state/ui';
import { TITLES, TITLES_CONSTANTS } from '../../../constants/titles';
import withWindowWidth from '../../../utils/enchancers/withWindowWidth';
import OfflineUnsupported from '../../../pages/offlineUnsuported/offlineUnsupported';
import withCleanStorageOfflineRequests from '../../../utils/enchancers/withCleanStorageOfflineRequests';
import { messengerActions } from '../../../state/messenger';


const mapStateToProps = state => ({
  isLeftSideBar: uiSelectors.getLeftSidebar(state),
  page: uiSelectors.getPage(state),
  leftSideBar: uiSelectors.getLeftSidebar(state),
});

const mapDispatchToProps = ({
  setLeftSideBar: uiActions.changeLeftSidebarStatus,
  getTotalUnreadCountRequest: messengerActions.getTotalUnreadCountRequest,
  resendOfflineRequests: () => offlineRequestActions.resendOfflineRequests(),
  setActiveChannel: messengerActions.setActiveChannel,
});

const answerNotificationPermissions = () => {
  if (('Notification' in window)) {
    Notification.requestPermission();
  }
};

const UNSUPPORTED_OFFLINE_PAGES = [TITLES_CONSTANTS.PAGE_USERS, TITLES_CONSTANTS.PAGE_CUSTOMERS,
  TITLES_CONSTANTS.EVENTS, TITLES_CONSTANTS.PAGE_STATISTICS, TITLES_CONSTANTS.AGENDA,
  TITLES_CONSTANTS.GROUP_CHANNELS, TITLES_CONSTANTS.DIRECT_CHANNELS, TITLES_CONSTANTS.DOCUMENTS,
  TITLES_CONSTANTS.PAGE_PROFILE];


const withUnsupportedOfflinePages = compose(
  connect(mapStateToProps),
  withCleanStorageOfflineRequests,
  withStateHandlers(() => ({ isUnsupportedOffline: false }), {
    onCheckThatOfflineIsSupport: (prop, { page }) => () => ({
      isUnsupportedOffline: !window.navigator.onLine && includes(page, UNSUPPORTED_OFFLINE_PAGES),
    }),
  }),
  lifecycle({
    componentDidMount() {
      window.addEventListener('offline', () => {
        this.props.onCheckThatOfflineIsSupport();
      });
      window.addEventListener('online', () => {
        this.props.onCheckThatOfflineIsSupport();
      });
      this.props.onCheckThatOfflineIsSupport();
    },
    componentDidUpdate({ page }) {
      if (this.props.page !== page) {
        this.props.onCheckThatOfflineIsSupport();
      }
    },
  }),
);

const enhancer = compose(
  withUnsupportedOfflinePages,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withWindowWidth(),
  branch(({ isUnsupportedOffline }) => isUnsupportedOffline, renderComponent(OfflineUnsupported)),
  withHandlers({
    onSetCorrectHeightPageOnMobileDevices: () => () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.onCheckThatOfflineIsSupport();
      answerNotificationPermissions();
      if (TITLES[this.props.page]) {
        document.title = `${TITLES.SITE_NAME} - ${TITLES[this.props.page] || this.props.page || ''}`;
      } else {
        document.title = `${TITLES.SITE_NAME}`;
      }
      if (!this.props.isLeftSideBar) {
        document.querySelector('body').classList.add('sidebar-collapse');
      } else {
        document.querySelector('body').classList.remove('sidebar-collapse');
      }

      window.addEventListener('online', () => {
        this.props.resendOfflineRequests();
      });
      this.props.getTotalUnreadCountRequest();
      this.props.onSetCorrectHeightPageOnMobileDevices();
      window.addEventListener('resize', this.props.onSetCorrectHeightPageOnMobileDevices);
      this.props.setActiveChannel(null);
    },
    componentDidUpdate({ isLeftSideBar, page }) {
      if (this.props.page !== page) {
        document.title = `${TITLES.SITE_NAME} - ${TITLES[this.props.page] || this.props.page || ''}`;
        if (this.props.windowWidth < 1032) {
          this.props.setLeftSideBar(false);
        }
        this.props.setActiveChannel(null);
      }
      if (isLeftSideBar !== this.props.isLeftSideBar) {
        if (!this.props.isLeftSideBar) {
          document.querySelector('body').classList.add('sidebar-collapse');
        } else {
          document.querySelector('body').classList.remove('sidebar-collapse');
        }
      }
    },
    componentWillUnmount() {
      window.removeEventListener('online', this.props.resendOfflineRequests);
      window.removeEventListener('resize', this.props.onSetCorrectHeightPageOnMobileDevices);
    },
  }),
);
export default enhancer(View);
