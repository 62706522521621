import {
  compose, cond, curry, path,
} from 'ramda';

import { map, renameKeys } from '../commonHelpers';
import {
  checkCurrentUserNotAuthor,
  contentNotificationForGroup,
  checkIfShowMentionNotification,
  getMentionIds,
  setContent,
  truncateMessage,
  tupleWithMessageAndContent, contentNotificationForDirect,
} from './messages';
import { getAvatar, setAvatar } from '../userHelpers';
import {
  isChannelCommon,
  isChannelDirect,
  pathToChannelType,
} from './channelHelpers';

const createMessageNotification = compose(
  renameKeys({ avatar: 'icon', content: 'body' }),
  setContent(truncateMessage),
  setAvatar(getAvatar),
);

const setupMessageNotification = curry(showNotification => compose(
  showNotification,
  createMessageNotification,
));

const channelNotification = (userId, notification) => cond([
  [isChannelCommon(pathToChannelType), compose(
    map(compose(
      setupMessageNotification(notification),
      contentNotificationForGroup,
    )),
    checkIfShowMentionNotification(userId),
    getMentionIds,
    tupleWithMessageAndContent,
  )],
  [isChannelDirect(pathToChannelType), compose(
    setupMessageNotification(notification),
    contentNotificationForDirect,
  )],
]);

const showMessageWhenUserNotAuthor = (userId, notification) => compose(
  map(channelNotification(userId, notification)),
  checkCurrentUserNotAuthor(userId, path(['message', 'created_by'])),
);

export {
  createMessageNotification, setupMessageNotification, showMessageWhenUserNotAuthor,
};
