import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ShowMore } from '../../../../components';
import './style.sass';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const AboutProject = ({ project, imgUrl }) => (
  <div className="about-project">
    <div className="about-project__container">
      {
        imgUrl && (
          <div className="about-project__logo">
            <img className="about-project__logo-image" src={imgUrl} alt="Wetelo" />
          </div>
        )
      }
      <div className="about-project__name">
        <h4 className="about-project__name-title">{project.title}</h4>
      </div>
      <div className="about-project__created-by">
        Created by
        {' '}
        {getFullName(project.author)}
        {' '}
        on
        {' '}
        {moment(project.created_at).format('YYYY-MM-DD')}
      </div>
      <ShowMore className="about-project__show-more">
        <div className="about-project__description">
          {
            project.description
          }
        </div>
      </ShowMore>
    </div>
  </div>
);

AboutProject.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  project: PropTypes.instanceOf(Object).isRequired,
};

export default AboutProject;
