import React from 'react';
import PropTypes from 'prop-types';

import { TaskItem, Project } from './components';
import './style.sass';

const TasksList = ({
  data, setRef, match, toggleTabSprint, sprintsTabStatus, spentTime, estimationTime, sortByProject,
}) => (
  <div className="my-tasks-table" ref={e => setRef('tasksList', e)}>
    {
      sortByProject === 'projects' ? (
        data.map(project => (
          <Project
            key={new Date().getTime() * Math.random()}
            name={project.projectName}
            image={project.projectImage}
            toggleTab={() => toggleTabSprint(project.id)}
            tabStatus={sprintsTabStatus[project.id]}
            spentTime={spentTime}
            estimationTime={estimationTime}
            sortByProject={sortByProject}
          >
            { project.tasks.length ? (project.tasks.map(task => (
              <TaskItem
                data={task}
                url={match.url}
                key={new Date().getTime() * Math.random()}
                sortByProject={sortByProject}
              />
            ))) : (
              <div className="my-tasks-table__row my-tasks-table__row--empty">
                <div className="my-tasks-table__empty-sprint">Plan a sprint by dragging a task</div>
              </div>
            )
            }
          </Project>
        ))
      ) : (
        <Project
          key={new Date().getTime() * Math.random()}
          spentTime={spentTime}
          estimationTime={estimationTime}
          sortByProject={sortByProject}
        >
          { data.map(tasks => (
            tasks.tasks.map(list => (
              <TaskItem
                data={list}
                url={match.url}
                key={new Date().getTime() * Math.random()}
                projectImage={tasks.projectImage}
                projectName={tasks.projectName}
                sortByProject={sortByProject}
              />
            ))
          ))
          }
        </Project>
      )
      }
  </div>
);

TasksList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  setRef: PropTypes.func,
  toggleTabSprint: PropTypes.func.isRequired,
  sprintsTabStatus: PropTypes.shape({}).isRequired,
  spentTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  estimationTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  sortByProject: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  match: PropTypes.shape({}),
};

TasksList.defaultProps = {
  setRef: () => {},
  match: {},
  data: {},
};

export default TasksList;
