import React from 'react';
import View from '../modalView';

const EditProjectModal = ({ ...props }) => (
  <View
    modalTitle="Edit project"
    action="Apply"
    name="editProjectModal"
    {...props}
  />
);
export default EditProjectModal;
