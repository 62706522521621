import {
  compose, lifecycle, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { withWindowWidth } from '../../utils/enchancers';

import Projects from './projects';
import { openModal, setPage } from '../../state/ui/actions';
import { TITLES_CONSTANTS } from '../../constants/titles';
import withPermission from '../../utils/enchancers/withPemission';
import { MODULES } from '../../constants/pemissions';

const mapDispatchToProps = ({
  onOpenCreateProjectModal: () => openModal('createProjectModal'),
  setCurrentPage: setPage,
});

const setCorrectUrlHandler = ({ history }) => () => {
  if (history.location.pathname === '/') history.push('/projects/');
};

const enhancer = compose(
  withPermission([MODULES.PROJECTS]),
  connect(null, mapDispatchToProps),
  withWindowWidth(),
  withNamespaces(['common']),
  withHandlers({
    setCorrectUrl: setCorrectUrlHandler,
  }),
  lifecycle({
    componentDidMount() {
      this.props.setCorrectUrl();
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.PAGE_PROJECTS });
    },
  }),
);
export default enhancer(Projects);
