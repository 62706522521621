import { createSelector } from 'reselect';
import { pathOr, identity } from 'ramda';

const getErrorsRequest = (state, request) => createSelector(pathOr(null, ['errors', request]), error => error)(state);
const getErrorsList = createSelector(identity, pathOr(null, ['errors']));

export {
  // eslint-disable-next-line import/prefer-default-export
  getErrorsRequest,
  getErrorsList,
};
