import {
  compose, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';

import { withNamespaces } from 'react-i18next';
import JoinToChannel from './joinToChannel';
import { getActiveChannelEntity, joinToChannelPending } from '../../../../state/messenger/selectors';
import { joinToChannelRequest } from '../../../../state/messenger/actions';
import { withForwardingRef } from '../../../../utils/enchancers';
import withOnline from '../../../../utils/enchancers/withOnline';

const mapStateToProps = state => ({
  channel: getActiveChannelEntity(state),
  isPending: joinToChannelPending(state),
});

const mapDispatchToProps = {
  joinToChannel: joinToChannelRequest,
};

const onJoinToChannelHandler = ({ joinToChannel, channel: { id } }) => () => joinToChannel({ id });

const enhance = compose(
  withOnline,
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
  withForwardingRef,
  withHandlers({
    onJoinToChannel: onJoinToChannelHandler,
  }),
);
export default enhance(JoinToChannel);
