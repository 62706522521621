import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const Radio = ({
  label, id, name, checked, className, value, onChange, onBlur,
}) => (
  <div className={`radio-field ${className}`}>
    <input
      className="radio-field__input"
      type="radio"
      name={name}
      id={id}
      checked={checked}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
    <label htmlFor={id} className="radio-field__pseudo-input" />
    <label className="radio-field__label" htmlFor={id}>{label}</label>
  </div>
);

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

Radio.defaultProps = {
  label: '',
  className: '',
};

export default Radio;
