import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import events from './events';
import bootData from './user';
import users from './users';
import messenger from './messenger';
import projects from './projects';
import project from './project';
import task from './task';
import sprints from './sprints';
import tasks from './tasks';
import newTask from './newTask';
import materials from './materials';
import customers from './customers';
import statistics from './statistics';
import permissions from './permissions';

import ui from './ui';
import { state } from '../utils/middlewares/sagaRequestApi';
import { state as socketConnection } from '../utils/helpers/webSocket';

const uiPersistConfig = {
  key: 'ui',
  storage,
  whitelist: ['pagination', 'routeTypes', 'leftSidebar', 'rightSidebar', 'tabs'],
};

const userPersistConfig = {
  key: 'bootData',
  storage,
};

const tasksPersistConfig = {
  key: 'tasks',
  storage,
};

const projectsPersistConfig = {
  key: 'projects',
  storage,
};

const projectPersistConfig = {
  key: 'project',
  storage,
};

const materialsPersistConfig = {
  key: 'materials',
  storage,
};

const messengerPersistConfig = {
  key: 'messenger',
  storage,
};

const usersPersistConfig = {
  key: 'users',
  storage,
};


const rootReducer = history => combineReducers({
  router: connectRouter(history),
  permissions,
  statistics,
  customers,
  events,
  messenger: persistReducer(messengerPersistConfig, messenger),
  users: persistReducer(usersPersistConfig, users),
  newTask,
  projects: persistReducer(projectsPersistConfig, projects),
  task,
  project: persistReducer(projectPersistConfig, project),
  tasks: persistReducer(tasksPersistConfig, tasks),
  bootData: persistReducer(userPersistConfig, bootData),
  sprints,
  materials: persistReducer(materialsPersistConfig, materials),
  ui: persistReducer(uiPersistConfig, ui),
  ...state,
  socketConnection,
});

export default rootReducer;
