import {
  compose, defaultProps, getContext, lifecycle, withContext, withHandlers, withProps, withState,
} from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AllProjects from './allProjects';
import { uiActions } from '../../state/ui';
import { projectsActions, projectsSelectors } from '../../state/projects';

const ITEMS_ON_PAGE = 10;

const mapStateToProps = state => ({
  projectsList: projectsSelectors.getProjectsList(state),
  projectsCount: projectsSelectors.getProjectsListCount(state),
  isPending: projectsSelectors.getProjectsListPending(state),
});

const mapDispatchToProps = {
  getProjectsRequest: projectsActions.getProjectsListRequest,
  setOpenModal: uiActions.openModal,
};

const loadMoreRequestHandler = ({
  itemsOnPage, getProjectsRequest, currentPage, setSelectedProjectSortMeta, selectedSort,
  selectedSearch, filterProjects,
}) => () => {
  const offset = parseInt((currentPage - 1) * itemsOnPage, 10);
  setSelectedProjectSortMeta({ limit: itemsOnPage, offset });
  getProjectsRequest({
    limit: itemsOnPage,
    sortBy: 'created_at',
    order: selectedSort.value,
    title: selectedSearch,
    offset,
    status: filterProjects === 'active' ? 1 : 4,
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setSelectedSort: PropTypes.func,
  }),
  withRouter,
  defaultProps({
    itemsOnPage: ITEMS_ON_PAGE,
  }),
  withState('selectedProjectSortMeta', 'setSelectedProjectSortMeta', {}),
  withState('selectedSearch', 'setSelectedSearch', ''),
  withState('selectedSort', 'setSelectedSort', { value: 'desc' }),
  withState('filterProjects', 'setFilterProjects', 'active'),
  withRouter,
  withContext({
    selectedSearch: PropTypes.string,
    selectedSort: PropTypes.shape({}),
    setSelectedSort: PropTypes.func,
    setSelectedSearch: PropTypes.func,
    setFilterProjects: PropTypes.func,
    filterProjects: PropTypes.string,
  }, props => ({
    setFilterProjects: props.setFilterProjects,
    selectedSearch: props.selectedSearch,
    selectedSort: props.selectedSort,
    setSelectedSearch: props.setSelectedSearch,
    setSelectedSort: props.setSelectedSort,
    filterProjects: props.filterProjects,
  })),
  withProps(({ history, projectsCount }) => {
    const page = parseInt(history.location.search.match(/(?!page=)\d*/gi)[5] || 1, 10);
    return {
      currentPage: page,
      isPrev: page > 1,
      isNext: page * ITEMS_ON_PAGE < projectsCount,
    };
  }),
  withHandlers(() => ({
    loadMoreRequest: loadMoreRequestHandler,
  })),
  lifecycle({
    componentDidMount() {
      this.props.loadMoreRequest();
    },
    componentDidUpdate({
      currentPage, selectedSort, selectedSearch, filterProjects,
    }) {
      if (this.props.currentPage !== currentPage || selectedSort !== this.props.selectedSort
        || filterProjects !== this.props.filterProjects) {
        this.props.loadMoreRequest();
      } else if (selectedSearch !== this.props.selectedSearch) {
        this.props.loadMoreRequest();
      }
    },
  }),
);

export default enhance(AllProjects);
