import {
  branch, compose, lifecycle, renderComponent,
} from 'recompose';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { withNamespaces } from 'react-i18next';

import { userSelectors, userActions } from '../../../state/user';
import { uiActions, uiSelectors } from '../../../state/ui';

import JoinYourTeam from './joinYourTeam';
import Page404 from '../../page404';

const { displayNotification } = uiActions;
const { continueSignUpFetch, checkCodeFetch } = userActions;

const mapDispatchToProps = ({
  continueSignUpRequest: continueSignUpFetch,
  displayNotification,
  checkCode: checkCodeFetch,
});

const mapStateToProps = state => ({
  continueSignUpErrors: userSelectors.getContinueSignUpErrors(state),
  email: userSelectors.getEmailFromCodeCheck(state),
  authNotification: uiSelectors.getAuthNotification(state),
  getCheckCodeErrors: userSelectors.getCheckCodeErrors(state),
  pending: userSelectors.getPendingContinueSignUpRequest(state),
});

const rules = yup.object().shape({
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], {
      message: 'Not equal confirm password',
    })
    .required({
      message: 'Confirm password is required',
    }),
  password: yup
    .string()
    .required({
      field: 'password',
      message: 'Password is required',
    }),
  username: yup
    .string()
    .required({
      field: 'username',
      message: 'Username is required',
    }),
  lastName: yup
    .string()
    .required({
      field: 'lastName',
      message: 'Last name is required',
    }),
  firstName: yup
    .string()
    .required({
      field: 'firstName',
      message: 'First name is required',
    }),
});

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'validation']),
  withFormik({
    validate: async (values, props) => {
      const { displayNotification: showNotification } = props;
      try {
        await rules.validate(values);
        showNotification(null);
      } catch (fail) {
        const { message } = fail;
        showNotification({
          [message.field]: {
            message: message.message,
            params: message.params,
          },
        });
      }
    },
    mapPropsToValues: () => ({
      firstName: '',
      lastName: '',
      username: '',
      password: '',
    }),
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: (formValues, { setErrors, props: { continueSignUpRequest, match, email } }) => {
      const { userId, code } = match.params;
      const {
        username,
        password,
        passwordConfirm,
        firstName,
        lastName,
      } = formValues;
      const values = {
        first_name: firstName,
        last_name: lastName,
        email,
        username,
        password,
        repeat: passwordConfirm,
        code,
        id: userId,
      };
      continueSignUpRequest(values, setErrors);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { userId, code } = this.props.match.params;
      this.props.checkCode({ id: userId, code });
    },
    componentWillUnmount() {
      this.props.displayNotification(null);
    },
  }),
  branch(
    ({ getCheckCodeErrors }) => getCheckCodeErrors,
    renderComponent(Page404),
  ),
);

export default enhancer(JoinYourTeam);
