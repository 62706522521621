import {
  take,
  fork,
} from 'redux-saga/effects';

import { uiTypes } from '../ui';
import { sagasManager } from '../../utils';
import { projectTypes } from '.';
import dragDropHelper from '../../utils/helpers/projectHelpers/index';
import dragDropAgendaHelper from '../../utils/helpers/projectHelpers/dragDropAgendaHelper';

function* onDragProjectSaga() {
  while (true) {
    const { payload } = yield take(uiTypes.ON_DRAG_END);
    const {
      destination, source, draggableId: itemId, projectId,
    } = payload;
    yield dragDropHelper({
      destination, source, itemId, projectId,
    });
  }
}

function* onDragAgendaFlow() {
  while (true) {
    const { payload } = yield take(projectTypes.ON_DRAG_AGENDA_END);
    const {
      destination, source, draggableId: itemId, projectId,
    } = payload;
    yield dragDropAgendaHelper({
      destination, source, itemId, projectId,
    });
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(onDragProjectSaga);
  yield fork(onDragAgendaFlow);
});
