import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const FilesItem = ({ item, onDelete }) => (
  <div className="files-upload-entities">
    <span className="files-upload-entities__icon">
      <span className="icon-document-file" />
    </span>
    <h6 className="files-upload-entities__name">{item.original}</h6>
    <button type="button" className="files-upload-entities__remove-button" onClick={onDelete}>
      <span className="icon-close" />
    </button>
  </div>
);

FilesItem.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]).isRequired,
};

export default FilesItem;
