import {
  compose, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';

import { withFormik } from 'formik';
import moment from 'moment';
import { propOr } from 'ramda';
import AddEvent from './addEvent';
import { uiSelectors, uiActions } from '../../../../state/ui';
import { eventsActions } from '../../../../state/events';
import rules from '../rules';

const mapStateToProps = state => ({
  isOpen: uiSelectors.getModal(state)('addEvent'),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('addEvent'),
  onAddEvent: eventsActions.addEventRequest,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ event = {}, selectedDate }) => ({
      ...event,
      start_date: selectedDate.start,
      end_date: selectedDate.end,
    }),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: rules,
    handleSubmit: (formValues,
      {
        resetForm,
        props: {
          onAddEvent,
          onCloseModal,
        },
      }) => {
      onAddEvent({ ...formValues, start_date: moment(new Date(propOr('', 'start_date', formValues))).add(6, 'h').format(), end_date: moment(new Date(propOr('', 'end_date', formValues))).add(6, 'h').format() }, { callbacks: { success: () => { onCloseModal(); resetForm({}); } } });
    },
  }),
  lifecycle({
    componentDidUpdate({ isOpen }) {
      if (!this.props.isOpen && this.props.isOpen !== isOpen) {
        this.props.setSelectedDate({ start: new Date(), end: new Date() });
      }
    },
  }),
);

export default enhance(AddEvent);
