import { eventChannel } from 'redux-saga';

import {
  take,
  takeEvery,
  call,
  fork,
  put,
} from 'redux-saga/effects';

import { socketTypes } from './index';
import socketConnection from '../socket';
import { sagasManager } from '../../../index';

function socketEmitter(event, topic) {
  return eventChannel((emitter) => {
    const { ws } = socketConnection.getSocketConnection();
    const topicSubscribe = ws.getSubscription(topic);
    // eslint-disable-next-line no-unused-expressions
    topicSubscribe && topicSubscribe.on(event, (payload) => {
      emitter(payload);
    });
    return () => console.error('off');
  });
}

function* listen(event, topic, action) {
  const channel = yield call(socketEmitter, event, topic, action);
  while (true) {
    try {
      const payload = yield take(channel);
      yield put(action({
        ...payload,
      }));
    } catch (err) {
      console.error('socket error:', err);
    }
  }
}

function* connectionSocket() {
  while (true) {
    yield take(socketTypes.CONNECT_SOCKET_TO_SERVER);
    yield call(socketConnection.initSocketConnection, localStorage.getItem('authToken'));
  }
}

function* callListener(action) {
  yield call(listen, action.payload.event, action.payload.topic, action.payload.action);
}

function* subscribe() {
  yield takeEvery(socketTypes.LISTEN_EVENT, callListener);
}

function* unsubscribe() {
  while (true) {
    const { payload: { topic } } = yield take(socketTypes.UNSUBSCRIBE_CHANNEL);
    const { ws } = socketConnection.getSocketConnection();
    const topicSubscribe = ws.getSubscription(topic);
    if (topicSubscribe) {
      topicSubscribe.on('close', () => {
        console.log('close');
      });
      topicSubscribe.close();
    }
  }
}

function* subscribeToChannel() {
  while (true) {
    const { payload: { topic } } = yield take(socketTypes.SUBSCRIBE_CHANNEL);
    const ws = socketConnection.getSocketConnection();
    yield call(ws.subscribe, topic);
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(connectionSocket);
  yield fork(subscribeToChannel);
  yield fork(subscribe);
  yield fork(unsubscribe);
});
