import {
  compose, lifecycle, withContext, withHandlers, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Events from './events';
import { openModal, setPage } from '../../state/ui/actions';
import { TITLES_CONSTANTS } from '../../constants/titles';
import withPermission from '../../utils/enchancers/withPemission';
import { MODULES } from '../../constants/pemissions';

const mapDispatchToProps = ({
  setOpenModal: openModal,
  setCurrentPage: setPage,
});

const onOpenAddEventModalHandler = ({ setOpenModal }) => () => {
  setOpenModal('addEvent');
};

const enhancer = compose(
  withPermission([MODULES.EVENTS]),
  connect(null, mapDispatchToProps),
  withState('selectedEventId', 'setSelectedEventId', 0),
  withState('selectedDate', 'setSelectedDate', { start: new Date(), end: new Date() }),
  withHandlers({
    onOpenAddEventModal: onOpenAddEventModalHandler,
  }),
  withContext({
    selectedEventId: PropTypes.number,
    setSelectedEventId: PropTypes.func,
  }, props => ({
    selectedEventId: props.selectedEventId,
    setSelectedEventId: props.setSelectedEventId,
  })),
  lifecycle({
    componentDidMount() {
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.EVENTS });
    },
  }),
);

export default enhancer(Events);
