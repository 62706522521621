import {
  branch, compose, renderNothing, withProps,
} from 'recompose';
import {
  compose as RCompose, propOr, values, filter, head, map,
} from 'ramda';
import { connect } from 'react-redux';
import moment from 'moment';

import Summary from './summary';
import { formatDate } from '../../../../utils/helpers/commonHelpers';
import { PRIORITY, STATUS } from '../../../../constants/tasks';
import withUsersAvatars from '../../../../utils/enchancers/withUserAvatars';
import { getArrayFromEntities, makeObjectWithAvatars } from '../../../../utils/helpers/uiHelpers';
import { usersSelectors } from '../../../../state/users';
import { withUserProfile } from '../../../../utils/enchancers';

const makeAvatars = compose(
  map(makeObjectWithAvatars),
);

const mapStateToProps = state => ({ users: usersSelectors.getUsersEntities(state) });

const getUsersEntities = (list, entities) => map(id => entities[id], list);
const enhance = compose(
  branch(({ task }) => !task.title, renderNothing),
  connect(mapStateToProps),
  withUserProfile,
  withProps(({ task, users }) => {
    const deadline = task.deadline ? formatDate(task.deadline) : '-';

    return {
      deadline,
      started: formatDate(task.created_at),
      isDeadlineToday: moment(task.deadline).startOf('day').valueOf() <= moment().startOf('day').valueOf(),
      watchers: getUsersEntities(propOr([], 'watchers', task), users),
      author: users[task.author],
      assigneeUser: users[task.assigneeUser],
      priority: getArrayFromEntities(PRIORITY)[propOr(1, 'priority', task)].label,
      done: task.estimated_time ? Math.floor((propOr(0, 'spent_time', task) * 100) / propOr(1, 'estimated_time', task)) : 0,
      status: RCompose(
        head,
        filter(item => item.id === task.status),
        values,
      )(STATUS) || {},
    };
  }),
  withUsersAvatars({
    countDisplay: 6,
    users: ({ watchers }) => makeAvatars(watchers),
    selectedAvatars: ({ selectedUsers }) => selectedUsers,
    onClick: ({ onSetUserProfile }) => onSetUserProfile,
    updatedByProps: 'watchers',
  }),
);
export default enhance(Summary);
