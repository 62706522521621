import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const ModulesItem = ({ title, onClick }) => (
  <div className="modules-item">
    <button className="modules-item__button" type="button" onClick={onClick}>{title}</button>
  </div>
);
ModulesItem.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
ModulesItem.defaultProps = {
  onClick: () => {},
};
export default ModulesItem;
