import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const Label = ({ children, className }) => (
  <div className={`label ${className}`}>
    <span className="label__wrapper"><span className="label__text">{children}</span></span>
  </div>
);

Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.element,
  ]),
  className: PropTypes.string,
};

Label.defaultProps = {
  className: '',
  children: 0,
};

export default Label;
