import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PieChart from 'react-minimal-pie-chart';
import './style.sass';
import { Avatar, PermissionElement, PendingControl } from '../../../../../../components';
import { ACTIONS, MODULES } from '../../../../../../constants/pemissions';
import { STATUS } from '../../../../../../constants/projects';
import acceptIcon from '../../../../../../assets/img/icon/checkmark.svg';


const ProjectItem = ({
  project, projectAvatar, onDelete, onEdit, timeToLeft, progressProject, onComplete, onStart,
  onResetOfflineDelete, onResetOfflineCreate, onResetOfflineEdit,
}) => (
  <Link to={`/projects/${project.id}/`} className="card b-0 mb-2 project-item">
    <PendingControl
      onResetOfflineDelete={onResetOfflineDelete}
      onResetOfflineEdit={onResetOfflineEdit}
      onResetOfflineCreate={onResetOfflineCreate}
      isPending={project.isPending}
      isUpdating={project.isUpdating}
      isDeleting={project.isDeleting}
    />
    <div className="card-body">
      <div className="row d-md-flex align-items-center">
        <div className="col-md-4 d-flex align-items-center">
          <Avatar
            src={projectAvatar}
            alt={project.title}
            className="mr-3 r-3 project-item__avatar"
          />
          <div>
            <h6 className="">{project.title}</h6>
            <span>{project.description}</span>
          </div>
        </div>
        <div className="col-md-4 pt-xs-3 pb-xs-3" />
        <div className="col-md-3 d-flex  align-items-center mt-sm-2">
          {
            project.status === STATUS.COMPLETED ? (
              <div className="project-item__complete-label">completed</div>
            ) : (
              <>
                <div className="project-item__progress">
                  <PieChart
                    style={{ width: '60px' }}
                    startAngel={0}
                    radius={40}
                    data={[{ value: progressProject, key: 1, color: '#E38627' }]}
                    totalValue={100}
                    lineWidth={13}
                    background="#cecece"
                    rounded
                    lengthAngle={360}
                    animate
                  />
                  <div className="project-item__label-progress">{Math.ceil(progressProject)}</div>
                </div>
                <div className="ml-2 left-days">
                  <strong>Deadline:</strong>
                  <br />
                  {timeToLeft}
                  {' '}
                  Days Left
                </div>
              </>
            )
          }
        </div>
        <div className="col-md-1 d-flex  align-items-center mt-sm-2 controls-group">

          <PermissionElement module={MODULES.PROJECTS} action={ACTIONS.DELETE}>
            <span
              role="button"
              className="link-color p-1"
              title="Delete project"
              onClick={(e) => { onDelete(project.id); e.preventDefault(); e.stopPropagation(); }}
            >
              <i className="icon-trash" />
            </span>
          </PermissionElement>

          <PermissionElement module={MODULES.PROJECTS} action={ACTIONS.UPDATE}>
            <span
              className="link-color p-1"
              role="button"
              title="Edit project"
              onClick={(e) => { onEdit(project.id); e.preventDefault(); e.stopPropagation(); }}
            >
              <i className="icon-pencil" />
            </span>
          </PermissionElement>
          {
            project.status === STATUS.COMPLETED ? (
              <PermissionElement module={MODULES.PROJECTS} action={ACTIONS.UPDATE}>
                <span
                  className="link-color p-1"
                  role="button"
                  title="Uncomplete project"
                  onClick={(e) => { onStart(project.id); e.preventDefault(); e.stopPropagation(); }}
                >
                  <i className="icon-close" />
                </span>
              </PermissionElement>
            ) : (
              <PermissionElement module={MODULES.PROJECTS} action={ACTIONS.UPDATE}>
                <span
                  className="link-color p-1"
                  role="button"
                  title="Complete project"
                  onClick={(e) => {
                    onComplete(project.id);
                    e.preventDefault(); e.stopPropagation();
                  }}
                >
                  <img src={acceptIcon} className="project-item__accept-icon" alt="" />
                </span>
              </PermissionElement>
            )
          }
        </div>
      </div>
    </div>
  </Link>
);
ProjectItem.propTypes = {
  id: PropTypes.number.isRequired,
  project: PropTypes.instanceOf(Object).isRequired,
  projectAvatar: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  timeToLeft: PropTypes.string.isRequired,
  progressProject: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  onResetOfflineDelete: PropTypes.func.isRequired,
  onResetOfflineCreate: PropTypes.func.isRequired,
  onResetOfflineEdit: PropTypes.func.isRequired,
};
export default ProjectItem;
