import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import { ModalWindow } from '../../../../../components';
import './style.sass';

const DeleteNote = ({
  isOpen,
  onCloseModal,
  note,
  onDelete,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    className="delete-note__modal"
    modalName="Delete document"
    title="Delete document"
  >
    <>
      <div className="row  text-align--left">
        <span className="delete-note__title title-md">
          {t('Are you sure you want to delete')}
          <b className="delete-note__name">{prop('title', note)}</b>
          {`${t('note')}?`}
        </span>
      </div>
      <div className="delete-note__button-row">
        <button
          type="button"
          className="btn btn-primary w-100px"
          onClick={onDelete}
        >
          {t('Delete')}
        </button>
        <button
          type="button"
          className="button--cancel"
          onClick={onCloseModal}
        >
          {t('Cancel')}
        </button>
      </div>
    </>
  </ModalWindow>
);

DeleteNote.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  note: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default DeleteNote;
