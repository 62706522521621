import {
  identity, memoizeWith, pathOr,
} from 'ramda';
import { createSelector } from 'reselect/lib/index';

const projectEntitiesSelector = key => createSelector(
  (state, projectId) => pathOr({}, ['project', projectId, 'entities', key], state),
  identity,
);

const projectResultSelector = key => createSelector(
  (state, projectId) => pathOr([], ['project', projectId, 'result', key], state),
  identity,
);

const getProjectSprintTasks = createSelector(
  identity,
  projects => memoizeWith(identity, projectId => pathOr({}, ['project', projectId, 'entities', 'tasks'], projects)),
);

const getProjectBacklogTasks = createSelector(
  identity,
  projects => memoizeWith(identity, projectId => pathOr({}, ['project', projectId, 'entities', 'backlog'], projects)),
);

const getProjectSprintsTasks = createSelector(
  identity,
  state => memoizeWith(identity, projectId => pathOr({}, ['project', projectId, 'entities', 'tasks'], state)),
);

const getProjectCategoriesTasks = projectEntitiesSelector('categories');
const getProjectTasksAuthors = projectEntitiesSelector('author');
const getProjectTasksAssignees = projectEntitiesSelector('assigneeUser');
const getAgendaStatuses = projectEntitiesSelector('agenda');

const getSprintsList = createSelector(
  identity,
  projects => memoizeWith(identity, projectId => pathOr([], ['project', projectId, 'result', 'sprints'], projects)),
);

const getSprintsEntities = createSelector(
  identity,
  projects => memoizeWith(identity, projectId => pathOr([], ['project', projectId, 'entities', 'sprints'], projects)),
);

const getProjectCategoriesTasksList = projectResultSelector('categories');
const getAgendaStatusesList = createSelector(
  identity,
  (projects, projectId) => pathOr([], ['project', projectId, 'result', 'statuses'], projects),
);
const getUsersWithTasksList = projectResultSelector('usersWithTasks');

const getProjectSprint = createSelector(
  identity,
  state => memoizeWith(identity, (sprintId, projectId) => pathOr({}, ['project', projectId, 'entities', 'sprints', sprintId], state)),
);

const getAgenda = createSelector(
  identity,
  state => (projectId, statusId) => pathOr({}, ['project', projectId, 'entities', 'agenda', statusId], state),
);

const getProjectSprintTasksList = createSelector(
  identity,
  state => memoizeWith(identity, (sprintId, projectId) => pathOr({}, ['project', projectId, 'entities', 'sprints', sprintId, 'tasks'], state)),
);

const getAgendaTasksList = createSelector(
  identity,
  statuses => (projectId, status) => pathOr([], ['project', projectId, 'entities', 'agenda', status, 'tasks'], statuses),
);

const getProjectSprintTask = createSelector(
  getProjectSprintTasks,
  tasks => memoizeWith(identity, task => pathOr({}, [task], tasks)),
);

const getCategory = createSelector(
  projectEntitiesSelector('categories'),
  categories => memoizeWith(identity, categoryId => pathOr({}, [categoryId], categories)),
);

const getAssignee = createSelector(
  projectEntitiesSelector('assignee'),
  assignee => memoizeWith(identity, assigneeId => pathOr({}, [assigneeId], assignee)),
);

const getAuthor = createSelector(
  projectEntitiesSelector('author'),
  author => memoizeWith(identity, authorId => pathOr({}, [authorId], author)),
);


const getBacklogTasksList = createSelector(
  identity,
  projects => projectId => pathOr([], ['project', projectId, 'result', 'backlog'], projects),
);

const getProjectTasksPendingRequest = createSelector(pathOr(false, ['pending', 'getProjectTasksRequest']), identity);
const getProjectRequestPending = createSelector(pathOr(false, ['pending', 'getProject']), identity);
const getPendingAddSprintRequest = createSelector(pathOr(false, ['pending', 'addSprintRequest']), identity);
const getDeleteTaskPendingRequest = createSelector(pathOr(false, ['pending', 'deleteProjectTaskRequest']), identity);
const getAgendaTasksRequestPending = createSelector(pathOr(false, ['pending', 'getAgendaTasksRequest']), identity);
const getAgendaTasksBySprintRequestPending = createSelector(pathOr(false, ['pending', 'getAgendaTasksBySprintRequest']), identity);
const getCategoryAddRequestPending = createSelector(pathOr(false, ['pending', 'addCategoryTaskRequest']), identity);
const getCategoryDeleteRequestPending = createSelector(pathOr(false, ['pending', 'deleteCategoryTaskRequest']), identity);
const getCategoryUpdateRequestPending = createSelector(pathOr(false, ['pending', 'updateCategoryTaskRequest']), identity);
const getImportantTasksRequestPending = createSelector(pathOr(false, ['pending', 'getImportantTasksRequest']), identity);
const getNotesRequestPending = createSelector(pathOr(false, ['pending', 'getNotesRequest']), identity);
const getUpdateNoteRequestPending = createSelector(pathOr(false, ['pending', 'updateNoteRequest']), identity);
const getDeleteNoteRequestPending = createSelector(pathOr(false, ['pending', 'deleteNoteRequest']), identity);
const getAddNoteRequestPending = createSelector(pathOr(false, ['pending', 'addNoteRequest']), identity);
const getDeleteSprintRequestPending = createSelector(pathOr(false, ['pending', 'deleteSprintRequest']), identity);
const getUpdateSprintRequestPending = createSelector(pathOr(false, ['pending', 'updateSprintRequest']), identity);

const addMembersToProjectPending = createSelector(pathOr(false, ['pending', 'addProjectMembersRequest']), identity);
const getDeleteProjectMemberPending = createSelector(pathOr(false, ['pending', 'deleteProjectMemberRequest']), identity);

const getProjectData = createSelector(
  projectEntitiesSelector('data'),
  data => memoizeWith(identity, projectId => pathOr({}, [projectId], data)),
);

const getTask = createSelector(
  state => memoizeWith(identity, (taskId, projectId) => pathOr({}, ['project', projectId, 'entities', 'tasks', taskId], state)), identity,
);

const getBacklogTask = createSelector(
  state => memoizeWith(identity, (taskId, projectId) => pathOr({}, ['project', projectId, 'entities', 'backlog', taskId], state)), identity,
);

const getCategoryTasks = createSelector(
  state => memoizeWith(identity, categoryId => pathOr({}, ['project', 'entities', 'categories', categoryId], state)), identity,
);

const getImportantTasksList = createSelector(pathOr([], ['project', 'result', 'importantTasks']), identity);
const getImportantTasksEntities = createSelector(pathOr({}, ['project', 'entities', 'importantTasks']), identity);

const getTaskAssignee = createSelector(
  state => memoizeWith(identity, assigneeId => pathOr({}, ['project', 'entities', 'assignee', assigneeId], state)), identity,
);

const getProjectSummary = createSelector(pathOr({}, ['project', 'summary']), identity);

const getNotesList = createSelector(pathOr([], ['project', 'result', 'documents']), identity);

const getNotesEntities = createSelector(pathOr({}, ['project', 'entities', 'documents']), identity);

const getNote = createSelector(state => memoizeWith(identity, documentId => pathOr({}, ['project', 'entities', 'documents', documentId], state)), identity);

const getProjectFiles = createSelector(state => memoizeWith(identity, projectId => pathOr([], ['project', 'entities', 'data', projectId, 'files'], state)), identity);

const getProjectMembers = createSelector(pathOr({}, ['project', 'entities', 'members']), identity);

const getProjectMembersList = createSelector(pathOr([], ['project', 'result', 'members']), identity);

export default getProjectData;

export {
  getProjectSprintTask,
  getProjectSprintTasks,
  getProjectSprint,
  getProjectBacklogTasks,
  getCategory,
  getProjectSprintsTasks,
  getAssignee,
  getAuthor,
  getProjectTasksAuthors,
  getBacklogTasksList,
  getProjectTasksAssignees,
  getPendingAddSprintRequest,
  getProjectSprintTasksList,
  getSprintsList,
  getProjectRequestPending,
  getProjectTasksPendingRequest,
  getDeleteTaskPendingRequest,
  getTask,
  getAgendaStatuses,
  getProjectCategoriesTasksList,
  getAgenda,
  getProjectCategoriesTasks,
  getCategoryTasks,
  getBacklogTask,
  getAgendaTasksList,
  getTaskAssignee,
  getUsersWithTasksList,
  getAgendaStatusesList,
  getImportantTasksList,
  getImportantTasksEntities,
  getAgendaTasksBySprintRequestPending,
  getAgendaTasksRequestPending,
  getCategoryAddRequestPending,
  getCategoryUpdateRequestPending,
  getCategoryDeleteRequestPending,
  getImportantTasksRequestPending,
  getDeleteSprintRequestPending,
  getUpdateSprintRequestPending,
  getProjectSummary,
  getNotesList,
  getNotesEntities,
  getNote,
  getDeleteNoteRequestPending,
  getNotesRequestPending,
  getUpdateNoteRequestPending,
  getAddNoteRequestPending,
  getProjectMembers,
  addMembersToProjectPending,
  getProjectMembersList,
  getDeleteProjectMemberPending,
  getSprintsEntities,
  getProjectFiles,
  projectEntitiesSelector,
};
