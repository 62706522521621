import React from 'react';
import PropTypes from 'prop-types';

import { DropDown } from '../../../../components';
import './style.sass';

const FiltersRow = ({
  t, onChangeSorting, sortBy: { val: { id } }, onOpenUploadFiles,
}) => (
  <div className="notes-filters">
    <div className="notes-filters__sort-group d-flex justify-content-between pr-3">
      <DropDown
        label={`${t('Sort')} : `}
        placeholder="Custom"
        name="filter-row"
        onChange={onChangeSorting}
        currentItem={id}
        list={
          [
            {
              id: 0,
              order: 'desc',
              sortBy: 'created_at',
              label: t('Recent'),
            },
            {
              id: 1,
              order: 'asc',
              sortBy: 'created_at',
              label: t('Old'),
            },
            {
              id: 2,
              order: 'asc',
              sortBy: 'title',
              label: 'A-Z',
            },
            {
              id: 3,
              order: 'desc',
              sortBy: 'title',
              label: 'Z-A',
            },
          ]
        }
      />
      <button className="btn btn-primary" type="button" onClick={onOpenUploadFiles}>Upload files</button>
    </div>
  </div>
);

FiltersRow.propTypes = {
  t: PropTypes.func.isRequired,
  onChangeSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.instanceOf(Object).isRequired,
};
export default FiltersRow;
