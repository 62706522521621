import { handleActions } from 'redux-actions';

import { uniq } from 'ramda';
import types from './types';
import {
  mergeDeep,
  mergeIn,
} from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_MATERIALS]: mergeIn(({ payload: { data: { entities: { materials }, result } } }) => ({
    entities: { ...materials },
    result,
  })),
  [types.REORDER_MATERIALS]: mergeIn(({ payload }) => ({
    result: [...payload],
  })),
  [types.UPDATE_MATERIAL]: mergeDeep(({ payload: { material } }) => ({
    entities: { [material.id]: material },
  })),
  [types.SET_MATERIAL]: mergeDeep(({ payload: { material } }, { result }) => ({
    entities: { [material.id]: material },
    result: uniq([...result, material.id]),
  })),
}, {
});

export default reducer;
