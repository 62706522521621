import React from 'react';
import PropTypes from 'prop-types';

const MultiValueLabel = ({ children }) => (
  <span>
    {children}
  </span>
);

MultiValueLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

export default MultiValueLabel;
