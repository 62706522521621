import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../../../../components';
import './style.sass';

const NotesProject = ({ t }) => (
  <div className="notes-project">
    <div className="notes-project__table">
      <div className="notes-project__table-head">
        <dib className="notes-project__row">
          <div className="notes-project__title notes-project__col">{t('Title')}</div>
          <div className="notes-project__description notes-project__col">{t('Description')}</div>
          <div className="notes-project__action notes-project__col">{t('Action')}</div>
        </dib>
      </div>
      <div className="notes-project__table-body">
        <div className="notes-project__row">
          <div className="notes-project__title notes-project__col">
            <h5 className="notes-project__title-text">Test access</h5>
          </div>
          <div className="notes-project__description notes-project__col">
            <div>
Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi eum
              exercitationem fugit ipsum mollitia totam veritatis? Animi aspernatur, cum ducimus
              eius eos fuga in laborum omnis quisquam sit suscipit veritatis?
              <a href="#test">Hello World</a>
            </div>
            <div>
Debitis deleniti doloribus eum eveniet fugiat id, ipsam iure labore necessitatibus
              nesciunt nobis nostrum obcaecati odio porro quisquam vel voluptatibus! Aliquid
              dignissimos eos exercitationem inventore nobis perspiciatis, quibusdam quisquam vitae.
              <a href="#test">Hello World</a>
            </div>
            <div>
Commodi dolor excepturi numquam optio pariatur quaerat quas quibusdam quisquam?
              Consequuntur debitis delectus doloribus, ducimus et ipsa optio quam similique? Ad
              cumque incidunt, laudantium molestias perspiciatis porro ratione veniam voluptas.
            </div>
            <div>
Ab alias aperiam aspernatur distinctio doloremque dolores eaque eos, error
              excepturi, explicabo facilis iusto libero maiores maxime minus, nam non nostrum porro
              quam quas quibusdam quos soluta suscipit totam vel!
            </div>
          </div>
          <div className="notes-project__action notes-project__col">
            <div className="notes-project__btn-group">
              <ButtonsCollection.Button className="button-default--style-2">{t('Edit')}</ButtonsCollection.Button>
              <ButtonsCollection.Button className="button-default--style-1">{t('Delete')}</ButtonsCollection.Button>
            </div>
          </div>
        </div>
        <div className="notes-project__row">
          <div className="notes-project__title notes-project__col">
            <h5 className="notes-project__title-text">Test access</h5>
          </div>
          <div className="notes-project__description notes-project__col">
            <div>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi eum
              exercitationem fugit ipsum mollitia totam veritatis? Animi aspernatur, cum ducimus
              eius eos fuga in laborum omnis quisquam sit suscipit veritatis?
            </div>
            <div>
              Debitis deleniti doloribus eum eveniet fugiat id, ipsam iure labore necessitatibus
              nesciunt nobis nostrum obcaecati odio porro quisquam vel voluptatibus! Aliquid
              dignissimos eos exercitationem inventore nobis perspiciatis, quibusdam quisquam vitae.
            </div>
            <div>
              Commodi dolor excepturi numquam optio pariatur quaerat quas quibusdam quisquam?
              Consequuntur debitis delectus doloribus, ducimus et ipsa optio quam similique? Ad
              cumque incidunt, laudantium molestias perspiciatis porro ratione veniam voluptas.
            </div>
            <div>
              Ab alias aperiam aspernatur distinctio doloremque dolores eaque eos, error
              excepturi, explicabo facilis iusto libero maiores maxime minus, nam non nostrum porro
              quam quas quibusdam quos soluta suscipit totam vel!
            </div>
          </div>
          <div className="notes-project__action notes-project__col">
            <div className="notes-project__btn-group">
              <ButtonsCollection.Button className="button-default--style-2">Edit</ButtonsCollection.Button>
              <ButtonsCollection.Button className="button-default--style-1">Remove</ButtonsCollection.Button>
            </div>
          </div>
        </div>
        <div className="notes-project__row">
          <div className="notes-project__title notes-project__col">
            <h5 className="notes-project__title-text">Test access</h5>
          </div>
          <div className="notes-project__description notes-project__col">
            <div>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi eum
              exercitationem fugit ipsum mollitia totam veritatis? Animi aspernatur, cum ducimus
              eius eos fuga in laborum omnis quisquam sit suscipit veritatis?
            </div>
            <div>
              Debitis deleniti doloribus eum eveniet fugiat id, ipsam iure labore necessitatibus
              nesciunt nobis nostrum obcaecati odio porro quisquam vel voluptatibus! Aliquid
              dignissimos eos exercitationem inventore nobis perspiciatis, quibusdam quisquam vitae.
            </div>
            <div>
              Commodi dolor excepturi numquam optio pariatur quaerat quas quibusdam quisquam?
              Consequuntur debitis delectus doloribus, ducimus et ipsa optio quam similique? Ad
              cumque incidunt, laudantium molestias perspiciatis porro ratione veniam voluptas.
            </div>
            <div>
              Ab alias aperiam aspernatur distinctio doloremque dolores eaque eos, error
              excepturi, explicabo facilis iusto libero maiores maxime minus, nam non nostrum porro
              quam quas quibusdam quos soluta suscipit totam vel!
            </div>
          </div>
          <div className="notes-project__action notes-project__col">
            <div className="notes-project__btn-group">
              <ButtonsCollection.Button className="button-default--style-2">Edit</ButtonsCollection.Button>
              <ButtonsCollection.Button className="button-default--style-1">Remove</ButtonsCollection.Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

NotesProject.propTypes = {
  t: PropTypes.func.isRequired,
};

export default NotesProject;
