import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const Input = ({
  className,
  name,
  type,
  placeholder,
  classInput,
  onChange,
  children,
  onBlur,
  id,
  value,
  label,
  disabled,
  error,
  autocomplete,
  min,
  max,
}) => (
  <div className={`form-group ${className}`}>
    {
      children
    }
    {
      label && (
        <label htmlFor={id} className="col-form-label">{label}</label>)
    }
    <input
      type={type}
      id={id}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      autoComplete="off"
      autoFill="off"
      min={min}
      max={max}
      disabled={disabled}
      className={`form-control ${classInput}`}
      placeholder={placeholder}
    />
    {error && (<small className="text-danger">{error}</small>)}
  </div>
);

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  classInput: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.element,
  onBlur: PropTypes.func,
  autocomplete: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

Input.defaultProps = {
  className: '',
  type: 'text',
  classInput: '',
  placeholder: '',
  children: <></>,
  onBlur: () => {},
  autocomplete: 'off',
  min: 0,
  max: 9999,
};
export default Input;
