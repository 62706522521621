import { compose, withHandlers, getContext } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import CategoriesItem from './categoriesItem';
import { projectSelectors } from '../../../../../state/project';
import { uiActions } from '../../../../../state/ui';

const mapStateToProps = (state, { categoryId }) => ({
  category: projectSelectors.getCategoryTasks(state)(categoryId),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};

const onEditHandler = ({ setOpenModal, category: { id }, setSelectedCategoryId }) => (e) => {
  e.preventDefault();
  setSelectedCategoryId(id);
  setOpenModal('editCategory');
};
const onDeleteHandler = ({ setOpenModal, category: { id }, setSelectedCategoryId }) => (e) => {
  e.preventDefault();
  setSelectedCategoryId(id);
  setOpenModal('deleteCategory');
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'tasks']),
  getContext({
    setSelectedCategoryId: PropTypes.func.isRequired,
  }),
  withHandlers({
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
  }),
);

export default enhance(CategoriesItem);
