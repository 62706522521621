import React from 'react';
import PropTypes from 'prop-types';
import { ModalWindow, FormsCollection, ButtonsCollection } from '../../../../components';
import './style.sass';

const EditRepositoryModal = ({ isOpen, closeModal, id }) => (
  <ModalWindow
    isOpen={isOpen(`editRepositoryModal${id}`)}
    onClose={() => closeModal(`editRepositoryModal${id}`)}
    modalName="editRepository"
    title="Edit repository"
    className="modal-window__edit-repositories"
  >
    <FormsCollection.FormWrapper
      handleSubmit={() => {}}
      className="edit-repositories"
    >
      <FormsCollection.Input
        placeholderJump="Note title"
        className="edit-repositories__title-field"
        id="edit-repositories__title"
        error="Some error in the field"
      />
      <FormsCollection.TextArea
        value="Lorem ipsum dolor sit amet,
         consectetur adipisicing elit. Adipisci doloribus eius harum in magni mollitia
          officia quis quo tempora ut? Accusantium est fugit, non quisquam
          repellat sequi vitae voluptate voluptatum!"
        className="edit-repositories__textarea"
        placeholderJump="Description"
        autoHeight
        maxHeight={190}
      />
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          className="button--md edit-repositories__button-control button-brill--fill"
        >
          SAVE
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

EditRepositoryModal.propTypes = {
  isOpen: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default EditRepositoryModal;
