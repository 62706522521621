import { compose, defaultProps, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import {
  pathOr, pipe, pick, mergeRight, map,
} from 'ramda';
import { withNamespaces } from 'react-i18next';

import { withRouter } from 'react-router';
import CreateTaskModal from './createTaskModal';
import rules from '../rules';
import withCRUDTask from '../../../utils/enchancers/withCRUDTask';
import { setTaskData } from '../../../utils/helpers/taskHelpers/crudHelper';
import { tasksActions } from '../../../state/tasks';
import { newTaskActions } from '../../../state/newTask';
import withOnline from '../../../utils/enchancers/withOnline';

const mapDispatchToProps = ({
  addTask: tasksActions.addTaskRequest,
  setSelectedProjectIdCrud: newTaskActions.setSelectedProjectCrud,
  getCategoriesTaskCrud: newTaskActions.getCategoriesTaskCrudRequest,
  getSprintsTaskCrud: newTaskActions.getSprintsTaskCrudRequest,
});

const enhance = compose(
  connect(null, mapDispatchToProps),
  withOnline,
  withRouter,
  withNamespaces(['common', 'tasks']),
  defaultProps({
    isDisabledProjectField: false,
  }),
  withCRUDTask({
    rules,
    data: () => null,
    modalName: 'createTaskModal',
    onSubmit: (formValues, {
      resetForm,
      props: {
        addTask, onCloseModal, initialValues, match, history,
      },
    }) => {
      const {
        submitButton,
      } = formValues;
      const projectIdFromUrl = pathOr(null, ['params', 'id'], match);
      const projectIdFromValue = pathOr(null, ['project_id', 'value'], formValues);

      const projectId = projectIdFromValue || projectIdFromUrl;
      const isRedirectToProject = parseInt(projectIdFromUrl, 10) !== projectIdFromValue;

      addTask(setTaskData({ ...formValues, materials: map(pathOr([], ['materials', 'value']))(formValues), projectId }), {
        section: 'project',
        callbacks: {
          success: () => {
            resetForm(initialValues);
            if (submitButton !== 'saveAndCreate') {
              onCloseModal('createTaskModal');
            } else {
              const leftoverData = pipe(
                pick(['assignee', 'project_id', 'category_id', 'priority', 'sprint_id']),
                mergeRight(initialValues),
              )(formValues);
              resetForm(leftoverData);
            }
            if (isRedirectToProject) {
              history.push(`/projects/${projectId}`);
            }
          },
        },
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.setSelectedProjectIdCrud(this.props.projectId);
    },
    componentDidUpdate(prevProps) {
      const {
        isOpen, getCategoriesTaskCrud, getSprintsTaskCrud, projectId, resetForm,
      } = this.props;
      if (isOpen !== prevProps.isOpen) {
        resetForm({});
        getSprintsTaskCrud({ projectId });
        getCategoriesTaskCrud({ projectId });
      }
    },
  }),
);

export default enhance(CreateTaskModal);
