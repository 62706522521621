import {
  fork, put, take, takeLatest, select,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';

import {
  pathOr, lt, prop, compose, propOr, includes, identity,
} from 'ramda';

import { uiActions } from '../ui';
import { userSelectors } from '../user';
import { apiRequest } from '../../utils/middlewares/sagaRequestApi';
import { messengerActions, messengerSelectors, messengerTypes } from '.';
import { sagasManager } from '../../utils';
// eslint-disable-next-line no-unused-vars
import { TYPES_NOTIFICATION } from '../../constants/ui';

function* setActiveChannelFlow() {
  while (true) {
    const { payload: { channel, meta: { isSetActive } } } = yield take(messengerTypes.SET_CHANNEL);
    if (isSetActive && channel.id) {
      yield put(messengerActions.setActiveChannel({
        id: channel.id,
        type: channel.type,
      }));
      yield put(messengerActions.getLatestMessagesRequest({
        channelId: channel.id,
        offset: 0,
        limit: 20,
      }));
      yield put(uiActions.setPreloader(false));
    }
  }
}

function* addNewChannelFlow() {
  while (true) {
    const {
      payload: { params, meta },
    } = yield take(messengerTypes.ADD_CHANNEL);
    yield put(apiRequest({
      params,
      route: '/channels',
      selectorName: 'setNewChannel',
      actions: {
        success: () => messengerActions.getGroupChannelsRequest(null,
          { isUpdate: true, limit: 8, offset: 0 }),
      },
      callbacks: {
        success: pathOr(identity, ['callbacks', 'success'], meta),
      },
      method: 'POST',
    }));
  }
}

function* setLoadedChannelFlow() {
  while (true) {
    const { payload: { meta } } = yield take(messengerTypes.SET_LATEST_MESSAGES);
    yield put(messengerActions.setLoadedChannel({ loading: true, channelId: meta.channelId }));
  }
}

// eslint-disable-next-line no-unused-vars
function* updateChannel(channelId) {
  yield put(messengerActions.readMessagesRequest({ channelId }, { isUpdatingCount: true }));
}

function* setNewMessage(payload) {
  yield put(messengerActions.setNewMessage(payload));
}
// function* setUnreadCount(payload) {
//   yield put(messengerActions.setUnreadCount(payload));
// }

function* emitNewMessageFlow() {
  while (true) {
    const { payload } = yield take(messengerTypes.EMIT_NEW_MESSAGE);
    // const activeChannel = yield select(messengerSelectors.getActiveChannel);

    // const activeChannelId = path(['id'], activeChannel);
    // const isUserOnSameChannel = cond([
    //   [compose(equals(activeChannelId), path(['channel', 'id'])), Either.Right],
    //   [T, Either.Left],
    // ]);
    const channel = yield select(state => messengerSelectors.getChannelById(state)(payload.channel.id));
    const user = yield select(state => userSelectors.getUserData(state));
    const isMember = () => parseInt(propOr(0, 'id', channel), 10) === 1 || propOr(0, 'type', channel) === 3 || includes(user.id, propOr([], 'members', channel));
    yield setNewMessage({ ...payload, meta: isMember() });
    yield put(uiActions.showNotification(payload, { type: TYPES_NOTIFICATION.MESSAGE }));
  }
}

function* emitUpdateMessageFlow() {
  while (true) {
    const { payload } = yield take(messengerTypes.EMIT_UPDATE_MESSAGE);
    yield put(messengerActions.updateMessage(payload));
  }
}

function* redirectChannelNotFoundFlow() {
  while (true) {
    yield take(messengerTypes.REDIRECT_CHANNEL_NOT_FOUND);
    yield put(uiActions.setPageNotFound(true));
  }
}

function* handleMessageTextarea({ payload }) {
  // eslint-disable-next-line no-unused-vars
  const { channelId } = payload;
  yield delay(500);

  const channel = yield select(state => messengerSelectors.getChannelById(state)(channelId));
  //
  const isChannelHasUnreadMessage = yield compose(lt(0), prop('unread_count'));

  if (isChannelHasUnreadMessage(channel)) {
    yield put(messengerActions.readMessagesRequest({ channelId }));
  }
}

function* watchUnreadCount() {
  yield takeLatest('TEXTAREA_CHANGED', handleMessageTextarea);
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(setLoadedChannelFlow);
  yield fork(setActiveChannelFlow);
  yield fork(addNewChannelFlow);
  yield fork(watchUnreadCount);
  yield fork(emitNewMessageFlow);
  yield fork(redirectChannelNotFoundFlow);
  yield fork(emitUpdateMessageFlow);
});
