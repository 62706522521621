import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { tasks } from '../../../../constants';
import ImportantTask from './importantTask';
import { withWindowWidth } from '../../../../utils/enchancers';

import { projectActions, projectSelectors } from '../../../../state/project';
import { uiActions, uiSelectors } from '../../../../state/ui';

const { changeImportantTasksTabStatus } = uiActions;
const { getTabStatus } = uiSelectors;

const mapStateToProps = state => ({
  isImportantTasksTabOpen: getTabStatus(state)('isImportantTasksTabOpen'),
  result: projectSelectors.getImportantTasksList(state),
  entities: projectSelectors.getImportantTasksEntities(state),
  isPending: projectSelectors.getImportantTasksRequestPending(state),
});

const mapDispatchToProps = ({
  changeImportantTasksTabStatus,
  getImportantTasks: projectActions.getImportantTasksRequest,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWindowWidth(),
  lifecycle({
    componentDidMount() {
      const { projectId, getImportantTasks } = this.props;
      getImportantTasks({
        projectId,
        where: ([{ field: 'priority', value: tasks.PRIORITY.VERY_HIGH.value }]),
        limit: 10,
        offset: 0,
      });
    },
  }),
);

export default enhance(ImportantTask);
