import {
  compose, lifecycle, withStateHandlers, hoistStatics, withHandlers,
} from 'recompose';

import ShowMoreLazyLoad from './showMoreLazyLoad';

const vectorChange = (
  setCurrentCount, setCurrentVector, currentCount, currentVector, count, limit,
) => {
  if (currentVector) {
    setCurrentCount(limit);
    if (currentCount + limit >= count && currentVector === true && count !== 0) {
      setCurrentVector();
    }
  } else {
    setCurrentCount(-limit);
    if (currentCount - limit <= limit && currentVector === false) {
      setCurrentVector();
    }
  }
};

const onFetchItemsHandler = (
  {
    handlerFetch,
    setOffset,
    setCurrentVector,
    setCurrentCount,
    limit,
    offset,
    hasMore,
    currentVector,
    count,
    currentCount,
  },
) => () => {
  if (hasMore) {
    setOffset(offset + (limit));
    handlerFetch({ limit, offset });
  }
  vectorChange(
    setCurrentCount, setCurrentVector, currentCount, currentVector, count, limit,
  );
};

const setOffsetStateHandler = () => value => ({ offset: value });

const setCurrentCountStateHandler = ({ currentCount }) => value => (
  { currentCount: currentCount + value }
);

const setCurrentVectorStateHandler = ({ currentVector }) => () => ({
  currentVector: !currentVector,
});

const enhance = compose(
  withStateHandlers(
    ({ limit, data }) => (
      {
        offset: 0,
        currentCount: data.length ? limit : 0,
        currentVector: true,
      }), {
      setOffset: setOffsetStateHandler,
      setCurrentCount: setCurrentCountStateHandler,
      setCurrentVector: setCurrentVectorStateHandler,
    },
  ),
  withHandlers({
    onFetchItems: onFetchItemsHandler,
  }),
  lifecycle({
    componentDidMount() {
      if (!this.props.data.length) {
        this.props.onFetchItems({});
      }
    },
  }),
);
export default hoistStatics(enhance)(ShowMoreLazyLoad);
