/* eslint-disable */
import React from 'react';

import ScrollWrapper from '../../../components/scrollWrapper';

const List = ({
  onScroll,
  height,
  style,
  items,
  scrollRef,
}) => (
  <ScrollWrapper
    onScroll={onScroll}
    heightIs={height}
    refCustom={scrollRef}
    autoHide
  >
    <div style={style}>{items}</div>
  </ScrollWrapper>
);

export default List;
