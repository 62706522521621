import {
  branch,
  compose, renderNothing, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';

import DeleteEvent from './deleteEvent';
import { uiSelectors, uiActions } from '../../../../state/ui';
import { eventsActions, eventsSelectors } from '../../../../state/events';

const mapStateToProps = (state, { selectedEventId }) => ({
  isOpen: uiSelectors.getModal(state)('deleteEventModal'),
  event: eventsSelectors.getEvent(state)(selectedEventId),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('deleteEventModal'),
  onDeleteEvent: eventsActions.deleteEventRequest,
};

const onDeleteHandler = ({
  onDeleteEvent, selectedEventId, onCloseModal,
}) => () => {
  onDeleteEvent({
    eventId: selectedEventId,
  }, {
    callbacks: {
      success: onCloseModal,
    },
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ event }) => !event.id, renderNothing),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);

export default enhance(DeleteEvent);
