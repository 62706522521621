import { compose, getContext, pure } from 'recompose';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Filters from './filters';
import withOnline from '../../../../utils/enchancers/withOnline';

const enhance = compose(
  withOnline,
  withNamespaces(['tasks', 'common']),
  pure,
  getContext({
    onSelectAssignee: PropTypes.func,
    onSelectAssigner: PropTypes.func,
  }),
);
export default enhance(Filters);
