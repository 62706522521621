import React from 'react';
import PropTypes from 'prop-types';
import TimeEntryItem from '../timeEntryItem';

import './style.sass';

const getPrevIdResultByIndex = (result, index) => (index !== 0 ? result[index - 1] : null);

const TimeEntryHistory = ({
  timeEntriesList, onEdit, onDelete, user,
}) => (
  <div className="time-entry__history">
    {
      timeEntriesList.map((id, index) => (
        <TimeEntryItem
          key={id}
          onDelete={onDelete}
          onEdit={onEdit}
          id={id}
          user={user}
          prevId={getPrevIdResultByIndex(timeEntriesList, index)}
        />
      ))
    }
  </div>
);

TimeEntryHistory.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  timeEntriesList: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default TimeEntryHistory;
