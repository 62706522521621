import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import TaskItem from './components/taskItem';
import './style.sass';

const TaskList = ({ className, result, entities }) => (
  <div className={`tasks-list ${className}`}>
    <ul className="tasks-list__list">
      {
        result.length ? result.map(id => (
          <TaskItem
            key={entities.id}
            task={propOr({}, id, entities)}
          />
        )) : <div className="files-attached__info-text tasks-list__empty text-align--center">No tasks yet</div>
        }
    </ul>
  </div>
);

TaskList.propTypes = {
  className: PropTypes.string,
  result: PropTypes.arrayOf(PropTypes.number),
  entities: PropTypes.instanceOf(Object).isRequired,
};

TaskList.defaultProps = {
  className: '',
  result: [],
};
export default TaskList;
