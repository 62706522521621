import React from 'react';
import PropTypes from 'prop-types';
import ReactScrollBar from 'react-custom-scrollbars';

import './style.sass';

// Todo: Find solution for Updating isVisible

const ScrollWrapper = ({
  children,
  heightIs,
  className,
  refCustom,
  onVisibleStatus,
  isVisible,
  setVisibleStatus,
  onUpdate,
  ...props
}) => (
  <ReactScrollBar
    className={`scroll-wrapper
    ${isVisible
      ? ''
      : 'scroll-wrapper--hidden'}
       ${className}`}
    ref={refCustom}
    renderView={e => <div {...e} className="scroll-wrapper__view" />}
    onUpdate={(e) => {
      onVisibleStatus(e);
      onUpdate(e);
    }}
    renderTrackVertical={(style, ...e) => <div {...(e)} style={{ ...style }} className="scroll-wrapper__track-vertical" />}
    renderThumbVertical={e => <div {...(e)} className="scroll-wrapper__thumb-vertical" />}
    style={{ height: heightIs || '100%', width: '100%' }}
    {...props}
  >
    {
      children
    }
  </ReactScrollBar>
);
ScrollWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  heightIs: PropTypes.oneOfType(
    [PropTypes.string,
      PropTypes.number],
  ),
  onUpdate: PropTypes.func,
  onVisibleStatus: PropTypes.func.isRequired,
  className: PropTypes.string,
  setVisibleStatus: PropTypes.func.isRequired,
  refCustom: PropTypes.func,
  isVisible: PropTypes.bool.isRequired,
};

ScrollWrapper.defaultProps = {
  heightIs: '',
  className: '',
  onUpdate: () => {},
  refCustom: () => {},
};
export default ScrollWrapper;
