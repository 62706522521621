import {
  compose, withStateHandlers, branch, renderNothing, getContext, withHandlers,
  hoistStatics, lifecycle, withProps,
} from 'recompose';

import PropTypes from 'prop-types';
import {
  T, cond, isEmpty, equals, prop, curry, ifElse, always, isNil, not, map,
} from 'ramda';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import moment from 'moment';
import { userSelectors } from '../../../../../../state/user';
import { usersSelectors } from '../../../../../../state/users';
import { messengerActions, messengerSelectors } from '../../../../../../state/messenger';
import { openModal } from '../../../../../../state/ui/actions';

import { getImageUrl } from '../../../../../../utils/helpers/requestHelpers';
import { getFullName } from '../../../../../../utils/helpers/userHelpers';
import { withUserProfile, withRefs, withPrevMessage } from '../../../../../../utils/enchancers';

import MessageItem from './messageItem';
import { isFirstMessageOnDay } from '../../../../../../utils/helpers/messengerHelpers/messages';
import { convertToUNIX } from '../../../../../../utils/helpers/dateHelpers';
import { offlineRequestActions } from '../../../../../../utils/middlewares/sagaRequestApi/state/offlineRequest';

const mapStateToProps = (state, { id, channelId, prevMessageId }) => ({
  message: messengerSelectors.getMessage(state)(id, channelId),
  prevMessage: messengerSelectors.getMessage(state)(prevMessageId, channelId),
  authors: usersSelectors.getUsersEntities(state),
  bootData: userSelectors.getUserData(state),
});

const mapDispatchToProps = ({
  setOpenModal: openModal,
  deleteMessage: messengerActions.deleteMessage,
  resetOfflineAction: offlineRequestActions.resetOfflineAction,
});

const getContent = prop('content');
const getAuthorId = prop('created_by');

const checkIsUserOwner = curry((authorId, userId) => cond([
  [equals(authorId), () => true],
  [T, () => equals(authorId, userId)],
])(userId));

const setIsUpdatedMessageHeightStateHandler = () => value => ({ isUpdatedMessageHeight: value });
const setContentStateHandler = () => content => ({ content });

const onClickMentionHandler = ({ onSetUserProfile }) => (user) => {
  onSetUserProfile(user);
};

const onUpdateDimensionHandler = ({ index, onResizeMessage }) => (width, height) => onResizeMessage(
  index,
  width,
  height,
);

const getDateCreatedMessage = compose(Number, date => moment(date).format('D'), prop('created_at'));

const messagesHasDifferentDate = (message, prevMessage) => ifElse(isNil,
  T,
  () => (getDateCreatedMessage(prevMessage) !== getDateCreatedMessage(message)))(prevMessage);

const onResetOfflineCreateHandler = ({
  message, resetOfflineAction, setIsScrollToBottom, deleteMessage,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: message.requestIdPost, method: 'POST', selectorName: 'submitMessageRequest' });
  deleteMessage({ message });
  setIsScrollToBottom(true);
};

const enhance = compose(
  withPrevMessage,
  connect(mapStateToProps, mapDispatchToProps),
  withRefs(),
  withUserProfile,
  withNamespaces(['common', 'chat']),
  branch(
    ({ message }) => isNil(message),
    renderNothing,
  ),
  withProps(({
    message, bootData, authors, messageTimestamps, prevMessage,
  }) => {
    const author = authors[message.created_by];
    return {
      isOwner: checkIsUserOwner(getAuthorId(message), prop('id', bootData)),
      isPrevMessageAuthorAndTimeSmall: getAuthorId(prevMessage) === getAuthorId(message)
        && moment(message.created_at).diff(new Date(prevMessage.created_at), 'seconds') < 60,
      userAvatar: ifElse(prop('avatar'),
        () => getImageUrl(author.avatar),
        always(''))(author),
      userName: getFullName(author),
      userOnlineStatus: author.is_online,
      isNewDay: ifElse(isNil, T, () => messagesHasDifferentDate(message, prevMessage))(prevMessage),
      showDateLabel: isFirstMessageOnDay(convertToUNIX(message.created_at))(messageTimestamps),
    };
  }),
  withStateHandlers(({ message }) => ({
    mouseHover: true,
    content: getContent(message),
    isUpdatedMessageHeight: false,
  }), {
    setContent: setContentStateHandler,
    setIsUpdatedMessageHeight: setIsUpdatedMessageHeightStateHandler,
  }),
  getContext(
    {
      setUserProfileId: PropTypes.func,
    },
  ),
  branch(
    ({ message }) => isEmpty(message),
    renderNothing,
  ),
  withHandlers({
    onClickMention: onClickMentionHandler,
    onUpdateDimension: onUpdateDimensionHandler,
    onResetOfflineCreate: onResetOfflineCreateHandler,
  }),
  lifecycle({
    componentDidMount() {
      const {
        getRef, index, onSetOffset, id, lastUserOwnerMessage,
        setLastUserOwnerMessageIndex,
      } = this.props;
      const { offsetHeight } = getRef('message');
      onSetOffset({
        offset: offsetHeight,
        index,
        fresh: true,
      });
      if (equals(id, lastUserOwnerMessage)) {
        setLastUserOwnerMessageIndex(index);
      }
    },
    componentDidUpdate(prevProps) {
      const {
        message, setLastUserOwnerMessageIndex, index, setContent,
        getRef, isUnread, onSetOffset, showDateLabel, id, lastUserOwnerMessage,
      } = this.props;
      const updateOffset = (offsetHeight) => {
        onSetOffset({ offset: offsetHeight, index, fresh: true });
      };

      if (not(equals(isUnread, prevProps.isUnread))) {
        const { offsetHeight } = getRef('message');
        updateOffset(offsetHeight);
      }
      if (not(equals(showDateLabel, prevProps.showDateLabel))) {
        const { offsetHeight } = getRef('message');
        updateOffset(offsetHeight);
      }
      if (not(equals(...map(getContent)([message, prevProps.message])))) {
        setContent(getContent(message));
      }
      if (equals(id, lastUserOwnerMessage)) {
        setLastUserOwnerMessageIndex(index);
      }
    },
  }),
  hoistStatics,
);
export default enhance(MessageItem);
