import { createSelector } from 'reselect/lib/index';
import {
  pathOr, identity,
} from 'ramda';

const getModules = createSelector(
  pathOr([], ['permissions', 'modules']), identity,
);

const getRoles = createSelector(
  pathOr([], ['permissions', 'entities', 'roles']), identity,
);

export {
  getModules,
  getRoles,
};
