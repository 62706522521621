import { schema } from 'normalizr';
import {
  GROUP_CHANNELS, DIRECT_CHANNELS, MESSAGES, PINNED_MESSAGES,
} from './types';

const membersSchema = [new schema.Entity('members', {}, {
  processStrategy: (value, parent) => ({ ...value, sprint: parent.id }),
})];

const groupChannelsSchema = [new schema.Entity(GROUP_CHANNELS)];
const directChannelsSchema = [new schema.Entity(DIRECT_CHANNELS)];
const pinnedMessagesSchema = [new schema.Entity(PINNED_MESSAGES)];
const messagesSchema = [new schema.Entity(MESSAGES, {}, { idAttribute: 'ts' })];

export {
  groupChannelsSchema,
  directChannelsSchema,
  messagesSchema,
  pinnedMessagesSchema,
  membersSchema,
};
