/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Filters } from '..';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import './style.sass';
import { MobileFiltersContainer } from '../../../../components';
import { setFullUsernameAsLabel } from '../../../../utils/helpers/uiHelpers';

const FiltersRow = ({
  assigners, assignees, selectedAssigner, selectedAssignee, t,
}) => (
  <div className="tasks-filter">
    <div className="tasks-filter__sort-group">
      <MobileFiltersContainer onWidth={WINDOW_WIDTH.SMALL} title={t('Filter')}>
        <Filters
          selectedAssigner={selectedAssigner}
          selectedAssignee={selectedAssignee}
          assigners={setFullUsernameAsLabel(assigners)}
          assignees={setFullUsernameAsLabel(assignees)}
        />
      </MobileFiltersContainer>
    </div>
  </div>
);

FiltersRow.propTypes = {
  assigners: PropTypes.instanceOf(Object),
  assignees: PropTypes.instanceOf(Object),
};

FiltersRow.defaultProps = {
  assigners: {},
  assignees: {},
};


export default FiltersRow;
