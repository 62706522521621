import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '../../../../../../../../components';
import './style.sass';

const TotalTimeItem = ({ data }) => (
  <div className="total-time-item">
    <div className="total-time-item__title">
      {data.image && (
        <div className="total-time-item__image">
          <Avatar src={data.image} alt={data.title} />
        </div>
      )}
      <div className="total-time-item__title-text">{data.title}</div>
    </div>
    <div className="total-time-item__estimate">
      {data.estimate}
    </div>
    <div className="total-time-item__spent">
      {data.spent}
    </div>
  </div>
);
TotalTimeItem.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])).isRequired,
};
export default TotalTimeItem;
