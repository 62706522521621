import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';

import { DropDown } from '../../../../components';
import './style.sass';

const Filters = ({
  assigners, assignees, onSelectAssignee, onSelectAssigner, selectedAssignee, selectedAssigner, t,
  isOnline,
}) => (
  <div className="tasks-filter__sort-group__drop-downs">
    <DropDown
      label={`${t('Assigner')} : `}
      name="assigner"
      placeholder={t('All')}
      list={assigners}
      onReset={onSelectAssigner}
      currentItem={Number(selectedAssigner)}
      onChange={isOnline ? onSelectAssigner : identity}
    />
    <DropDown
      label={`${t('Assignee')} : `}
      name="assignee"
      placeholder={t('All')}
      onReset={onSelectAssignee}
      currentItem={Number(selectedAssignee)}
      onChange={isOnline ? onSelectAssignee : identity}
      list={assignees}
    />
  </div>
);

Filters.propTypes = {
  assigners: PropTypes.arrayOf(PropTypes.object),
  assignees: PropTypes.arrayOf(PropTypes.object),
  selectedAssignee: PropTypes.string,
  selectedAssigner: PropTypes.string,
  onSelectAssignee: PropTypes.func.isRequired,
  onSelectAssigner: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isOnline: PropTypes.bool.isRequired,
};

Filters.defaultProps = {
  assigners: [],
  selectedAssignee: null,
  selectedAssigner: null,
  assignees: [],
};

export default Filters;
