import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';

import InviteMember from './inviteMember';
import withOnline from '../../../../utils/enchancers/withOnline';

const enhance = compose(
  withOnline,
  withNamespaces(['common', 'chat']),
);

export default enhance(InviteMember);
