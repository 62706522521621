import { compose, lifecycle } from 'recompose';

const sizeofAllStorage = () => {
  const lengthInUtf8Bytes = (str) => {
    // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
    const m = encodeURIComponent(str).match(/%[89ABab]/g);
    return str.length + (m ? m.length : 0);
  };
  let size = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= localStorage.length - 1; i++) {
    const key = localStorage.key(i);
    size += lengthInUtf8Bytes(localStorage.getItem(key));
  }
  return size;
};

const saveItemsAfterAndClear = () => {
  setTimeout(() => {
    window.localStorage.removeItem('persist:projects');
    window.localStorage.removeItem('persist:messenger');
    window.localStorage.removeItem('persist:materials');
  });
};

const withCleanStorageOfflineRequests = compose(
  lifecycle({
    componentDidMount() {
      if (sizeofAllStorage() > 1000000) saveItemsAfterAndClear();
    },
  }),
);


export default withCleanStorageOfflineRequests;
