import { handleActions } from 'redux-actions';
import { uniq, reverse } from 'ramda';

import types, { USERS_LIST } from './types';
import {
  mergeDeep,
  mergeByPath,
  mergeIn,
} from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_USERS_LIST]: mergeDeep(({ payload: { data, count } }, { entities }) => ({
    entities: { ...entities, ...data.entities[USERS_LIST] },
    count,
    result: reverse(data.result),
  })),
  [types.SET_USERS]: mergeDeep(({ payload }, { entities = {}, result = [] }) => ({
    entities: { ...entities, ...payload.entities },
    count: payload.count,
    result: uniq([
      ...result,
      ...payload.result,
    ]),
  })),
  [types.UPDATE_USERS]: mergeDeep(({ payload }, { result = [] }) => ({
    entities: payload.entities,
    result: uniq([
      ...result,
      ...payload.result,
    ]),
  })),
  [types.SET_USERS_STATUS]: mergeByPath(['entities'], (action, state) => ({
    [action.payload.user_id]: {
      ...state.entities[action.payload.user_id],
      ...{ is_online: action.payload.is_online },
    },
  })),
  [types.SET_USERS_LIST_AUTOCOMPLETE]: mergeIn(
    ({ payload: { users: usersAutocomplete } }, { users }) => ({
      ...users,
      usersAutocomplete,
    }),
  ),
  [types.SET_COWORKERS]: mergeDeep(
    ({ payload: { data: { result } } }) => ({
      coworkers: result,
    }
    ),
  ),
  [types.SET_USER_PROFILE_ID]: mergeDeep(({ payload }) => ({ userProfileId: payload })),
}, { entities: {}, count: 0, usersAutocomplete: [] });

export default reducer;
