import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Label, OnlineStatusLabel } from '../../../../../../components';

const ChannelItem = ({
  channelId, unreadCount, channelStatusOnline, channelName, isOwnChannel,
}) => (
  <li className="treeview">
    <NavLink
      to={`/messenger/${channelId}/`}
      activeClassName="direct-nav__item--active"
      className="direct-nav__item"
    >
      <OnlineStatusLabel status={channelStatusOnline} />
      {channelName}
      {isOwnChannel && ' (you)'}
      {unreadCount ? (<Label className="label--xs label--fill">{unreadCount}</Label>) : ''}
    </NavLink>
  </li>
);

ChannelItem.propTypes = {
  channelId: PropTypes.number.isRequired,
  directChannels: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  unreadCount: PropTypes.number.isRequired,
  channelStatusOnline: PropTypes.number.isRequired,
  channelName: PropTypes.string.isRequired,
  isOwnChannel: PropTypes.bool.isRequired,
};

export default ChannelItem;
