import React from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';

import NotesItem from './components';
import './style.sass';
import { ButtonsCollection, PermissionElement } from '../../../../components';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';

const NotesList = ({
  activeTab,
  changeActiveTabStateHandler,
  notes,
  files,
  onDeleteProjectFile,
}) => (
  <div className="notes-list">
    <div className="notes-list__table">
      <div className="notes-list__table-head">
        <div className="notes-list__table-row">
          <div className="notes-list__table-col"><b>Title</b></div>
          <div className="notes-list__table-col notes-list__table-col--description"><b>Description</b></div>
          <div className="notes-list__table-col" />
        </div>
      </div>
      <div className="notes-list__table-body">
        {
        notes.map(noteId => (
          <NotesItem
            key={noteId}
            isOpen={equals(noteId, activeTab)}
            noteId={noteId}
            toggleIsOpen={changeActiveTabStateHandler}
          />
        ))
      }
        {
          files.map(file => (
            <div
              key={file.id}
              className="notes-list__table-row"
            >
              <div className="notes-list__table-col"><h3 className="text--cut">{file.original}</h3></div>
              <div
                className="notes-list__table-col notes-list__table-col--description"
              >
                <a target="__blank" href={getImageUrl(file.id)}>Download</a>
              </div>
              <div className="notes-list__table-col">
                <div className="notes-list__controls-group">
                  <PermissionElement module={MODULES.PROJECTS_DOCUMENTS} action={ACTIONS.DELETE}>
                    <ButtonsCollection.ButtonIcons
                      className="notes-list__delete-button"
                      onClick={() => onDeleteProjectFile(file.id)}
                      title="Delete note"
                    >
                      <span className="icon-trash" />
                    </ButtonsCollection.ButtonIcons>
                  </PermissionElement>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  </div>
);

NotesList.propTypes = {
  activeTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  notes: PropTypes.objectOf(PropTypes.number).isRequired,
  changeActiveTabStateHandler: PropTypes.func,
};

NotesList.defaultProps = {
  activeTab: '',
  changeActiveTabStateHandler: () => {},
};

export default NotesList;
