import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { getImageUrl } from '../../../../../../utils/helpers/requestHelpers';

const FileIcon = ({ type, id }) => (
  <>
    {
      /^image/g.test(type) ? (
        <div className="file-preloader">
          <img src={getImageUrl(id)} alt="" />
        </div>
      ) : (
        <div className="file-preloader">
          <i className="icon-file" />
        </div>
      )
    }
  </>
);


const FileItem = ({
  className, file, onDeleteFileModal, user,
}) => (
  <div className={`file-row ${className}`}>
    <FileIcon type={file.type} id={file.id} />
    <div className="file-row__wrap">
      <b className="file-row__title file-row__title--post">{file.original}</b>
      <div className="file-row__about text--breaker">
        {/* {prevText} */}
        <div className="row-flex">
          <a href={`${getImageUrl(file.id)}`}>Download</a>
        </div>
      </div>
    </div>
    {
      user.id === file.created_by && (
        <button
          title="Delete"
          className="btn-delete"
          onClick={() => onDeleteFileModal(file.id)}
        >
          <i className="icon-trash" />
        </button>
      )
    }
  </div>
);

FileItem.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  file: PropTypes.instanceOf(Object).isRequired,
  onDeleteFileModal: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

FileItem.defaultProps = {
  className: '',
  type: 'post',
};
export default FileItem;
