import { handleActions } from 'redux-actions';
import {
  prop, isNil, pipe, mapObjIndexed, F, nthArg,
} from 'ramda';

import types from './types';
import { ROUTE_TYPES, WINDOW_WIDTH } from '../../constants/ui';
import {
  mergeDeep,
  mergeDeepInState,
  mergeIn,
  mergeInState,
} from '../../utils/helpers/stateHelpers';
import { mergeByPath, mergeByProp } from '../../utils/helpers/ramdaStateHelpers';

const initialState = ({
  preloader: true,
  rightSidebar: true,
  isSwipeSideBars: true,
  leftSidebar: (window.innerWidth > WINDOW_WIDTH.SMALL),
  pagination: null,
  grids: null,
  routeTypes: ROUTE_TYPES.GUEST,
  isPageNotFound: false,
  isFocus: false,
  tabs: {
    isProjectsTabOpen: false,
    isCoWorkersTabOpen: false,
    isModulesTabOpen: false,
    isImportantTasksTabOpen: false,
    isProjectMembersTabOpen: false,
    isMessengerDetailsTabOpen: false,
    isMessengerMediaTabOpen: false,
    isMessengerPinnedItemsTabOpen: false,
    isMessengerNotificationTabOpen: false,
    isMessengerMembersTabOpen: false,
  },
});

const reducer = handleActions({
  [types.DISPLAY_NOTIFICATION]: mergeIn(action => ({ notification: action.payload })),
  [types.SET_PRELOADER]: mergeIn(action => ({ preloader: action.payload })),
  [types.CHANGE_LEFT_SIDEBAR_STATUS]: mergeIn(action => ({ leftSidebar: action.payload })),
  [types.CHANGE_RIGHT_SIDEBAR_STATUS]: mergeIn(action => ({ rightSidebar: action.payload })),
  [types.CHANGE_SWIPE_SIDEBARS_STATUS]: mergeIn(action => ({ isSwipeSideBars: action.payload })),
  [types.SET_PAGINATION]: mergeInState('pagination', (action, state) => {
    const isPaginationHasInitialized = !isNil(state.pagination)
      && prop(action.payload.component, state.pagination);
    return ({
      [action.payload.component]: ({
        ...(isPaginationHasInitialized ? state.pagination[action.payload.component]
          : state.pagination),
        ...action.payload,
      }),
    });
  }),
  [types.SET_GRID_OPTIONS]: mergeInState('grids', (action, state) => {
    const isGridHasInitialized = !isNil(state.grids) && prop(action.payload.grid, state.grids);
    return ({
      [action.payload.grid]: ({
        ...(isGridHasInitialized && state.grids[action.payload.grid]),
        ...action.payload,
      }),
    });
  }),
  [types.CLEAR_GRID_OPTIONS]: mergeIn(action => action.payload),
  [types.RESET_PAGINATION]: mergeIn(() => ({ pagination: null })),
  [types.SET_IS_FOCUS]: mergeIn(action => ({ isFocus: action.payload })),
  [types.SET_ROUTES_TYPE]: mergeIn(action => ({ routeTypes: action.payload })),
  [types.OPEN_MODAL]: mergeInState('modals', action => ({ [action.payload]: true })),
  [types.CLOSE_MODAL]: mergeInState('modals', action => ({ [action.payload]: false })),
  [types.RESET_MODALS]: mergeIn(() => ({ modals: {} })),
  [types.SET_SORT]: mergeDeepInState('grids', action => ({
    [action.payload.grid]: { ...action.payload },
  })),
  [types.SET_ORDER]: mergeDeepInState('grids', action => ({
    [action.payload.grid]: { ...action.payload },
  })),
  [types.SET_FILTER]: mergeByPath(['filters'], action => ({
    [action.payload.filter]: { ...action.payload },
  })),
  [types.RESET_UI]: mergeIn(() => ({ ...initialState })),
  [types.SET_PAGE_NOT_FOUND]: mergeIn(action => ({ isPageNotFound: action.payload })),
  [types.CHANGE_PROJECTS_TAB_STATUS]: mergeInState(['tabs'], action => ({ isProjectsTabOpen: action.payload })),
  [types.CHANGE_CO_WORKERS_TAB_STATUS]: mergeInState(['tabs'], action => ({ isCoWorkersTabOpen: action.payload })),
  [types.CHANGE_MODULES_TAB_STATUS]: mergeInState(['tabs'], action => ({ isModulesTabOpen: action.payload })),
  [types.CHANGE_IMPORTANT_TASKS_TAB_STATUS]: mergeInState(['tabs'], action => ({
    isImportantTasksTabOpen: action.payload,
  })),
  [types.CHANGE_PROJECT_MEMBERS_TAB_STATUS]: mergeInState(['tabs'], action => ({
    isProjectMembersTabOpen: action.payload,
  })),
  [types.CHANGE_MESSENGER_DETAILS_TAB_STATUS]: mergeInState(['tabs'], action => ({
    isMessengerDetailsTabOpen: action.payload,
  })),
  [types.CHANGE_MESSENGER_MEDIA_TAB_STATUS]: mergeInState(['tabs'], action => ({
    isMessengerMediaTabOpen: action.payload,
  })),
  [types.CHANGE_MESSENGER_PINNED_ITEMS_TAB_STATUS]: mergeInState(['tabs'], action => ({
    isMessengerPinnedItemsTabOpen: action.payload,
  })),
  [types.CHANGE_MESSENGER_NOTIFICATION_TAB_STATUS]: mergeInState(['tabs'], action => ({
    isMessengerNotificationTabOpen: action.payload,
  })),
  [types.CHANGE_MESSENGER_MEMBERS_TAB_STATUS]: mergeInState(['tabs'], action => ({
    isMessengerMembersTabOpen: action.payload,
  })),
  [types.SET_USER_PROFILE_ID]: mergeDeep(({ payload }) => ({ userProfileId: payload })),
  [types.SET_PAGE]: mergeIn(({ payload: { page, subTitle = '', mainTitle = '' } }) => ({ page, mainTitle, subTitle })),
  [types.LOCATION_CHANGE]: mergeByProp('modals', pipe(
    nthArg(1),
    prop('modals'),
    mapObjIndexed(F),
  )),
}, initialState);


export default reducer;
