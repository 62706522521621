import {
  compose, getContext, withHandlers, withProps,
} from 'recompose';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import MemberItem from './memberItem';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { withUserProfile } from '../../../../utils/enchancers';
import { usersSelectors } from '../../../../state/users';

const onShowUserProfileHandler = ({
  onSetUserProfile,
  user: { id },
}) => () => onSetUserProfile(id);

const mapStateToProps = (state, { memberId }) => ({
  user: usersSelectors.getUser(state)(memberId),
});

const onDeleteHandler = ({ onSetModalDeleteMember, memberId }) => () => {
  onSetModalDeleteMember(memberId);
};

const enhance = compose(
  connect(mapStateToProps),
  getContext(
    { setUserProfileId: PropTypes.func },
  ),
  withUserProfile,
  withNamespaces(['common', 'chat']),
  withProps(({ user }) => ({
    userName: getFullName(user),
    userAvatar: getImageUrl(user.avatar),
    onlineStatus: user.is_online,
  })),
  withHandlers({
    onShowUserProfile: onShowUserProfileHandler,
    onDelete: onDeleteHandler,
  }),
);

export default enhance(MemberItem);
