import {
  messagesNormalize,
  unreadMessageMiddleware,
  newMessageNormalize,
  pinMessageMiddleware,
  subscribeChannelMiddleware,
  // eslint-disable-next-line no-unused-vars
  getMessagesMiddleware,
  updateMessagesMiddleware,
  updateFocusedChatMiddleware,
  deleteChannelMiddleWare,
  deleteMessageMiddleware,
} from './messenger';

import clearGridsMiddleware, { showNotificationMiddleware } from './ui';

import {
  // setProjectMiddleware,
  deleteSprintMiddleware,
  deleteCategoryMiddleware,
  deleteNoteMiddleware,
  deleteProjectMemberMiddleware,
  setSprintsMiddleware,
  deleteProjectMiddleware,
} from './project';
import {
  deleteTaskMiddleware,
  setTaskMiddleware,
  setProjectTasksMiddleware,
} from './tasks';
import {
  setUserProfileMiddleware,
  setUsersMiddleware,
} from './users';
import {
  deleteTimeEntryMiddleWare,
  editTimeEntryMiddleware,
  deleteTaskFileMiddleware,
} from './task';
import {
  deleteEventMiddleware,
} from './events';
import {
  deleteMaterialMiddleware,
} from './materials';

const middleware = [
  messagesNormalize,
  newMessageNormalize,
  updateMessagesMiddleware,
  pinMessageMiddleware,
  subscribeChannelMiddleware,
  deleteMessageMiddleware,
  unreadMessageMiddleware,
  updateFocusedChatMiddleware,
  // getMessagesMiddleware,
  // TODO for this middleware we need to developing one instance with all dynamic message dimensions
  /*
  *  Because if we have a lot of items and render them in one pack it was slowly
  * */
  deleteChannelMiddleWare,

  deleteProjectMiddleware,

  setTaskMiddleware,
  deleteCategoryMiddleware,
  deleteSprintMiddleware,
  setSprintsMiddleware,
  editTimeEntryMiddleware,
  deleteTaskMiddleware,
  deleteNoteMiddleware,
  deleteCategoryMiddleware,
  deleteProjectMemberMiddleware,

  clearGridsMiddleware,
  showNotificationMiddleware,

  setUserProfileMiddleware,

  deleteTimeEntryMiddleWare,
  setUsersMiddleware,

  deleteEventMiddleware,

  deleteMaterialMiddleware,
  deleteTaskFileMiddleware,

  setProjectTasksMiddleware,
];

export default middleware;
