import {
  compose, lifecycle, withHandlers, withStateHandlers, defaultProps,
} from 'recompose';
import { connect } from 'react-redux';

import { uiActions, uiSelectors } from '../../state/ui';

const VECTOR_RIGHT = 'vectorRight';
const VECTOR_LEFT = 'vectorLeft';


const mapStateToProps = state => ({
  isSwipeSideBars: uiSelectors.getIsSwipeSideBars(state),
});

const mapDispatchToProps = {
  changeSwipeSidebarsStatus: uiActions.changeSwipeSidebarsStatus,
};

const getVectorSwipe = ({
  coordinatesStart,
  currentCoordinates,
}) => (coordinatesStart < currentCoordinates ? VECTOR_RIGHT : VECTOR_LEFT);


const scrollToBoard = ({ indexBoard }) => {
  const boardElement = document.querySelector('.agenda-board__column-row').children[indexBoard];
  document.querySelector('.agenda-board__column-row').scrollTo({
    left: boardElement.offsetLeft - (16 + (indexBoard === 2) ? 20 : 0),
    behavior: 'smooth',
  });
};

const onTouchEndHandler = ({
  toggleIsTouched, coordinatesStart, setCurrentBoard, currentBoard, getRef, range, boardList,
}) => (e) => {
  const currentCoordinates = e.changedTouches[0].clientX;
  if (Math.abs(coordinatesStart - currentCoordinates) < range) {
    scrollToBoard({ indexBoard: currentBoard, getRef, boardList });
    return currentCoordinates;
  }

  const swipeVector = getVectorSwipe({
    coordinatesStart,
    currentCoordinates,
  });
  const newBoardIndex = swipeVector === VECTOR_RIGHT ? currentBoard - 1 : currentBoard + 1;

  if (newBoardIndex > -1 && newBoardIndex < boardList.length) {
    scrollToBoard({ indexBoard: newBoardIndex, getRef, boardList });
    setCurrentBoard(newBoardIndex);
  }
  toggleIsTouched(false);
  return coordinatesStart;
};


const onTouchMoveHandler = ({
  coordinatesStart,
  startScrollPosition,
  currentBoard,
  changeSwipeSidebarsStatus,
  isSwipeSideBars,
  boardList,
}) => (e) => {
  const currentCoordinates = e.changedTouches[0].clientX;
  const currentRangeSwipe = coordinatesStart - currentCoordinates;
  const swipeVector = getVectorSwipe({
    coordinatesStart,
    currentCoordinates,
  });
  if ((swipeVector === VECTOR_RIGHT && currentBoard === 0)
    || (swipeVector === VECTOR_LEFT && currentBoard === boardList.length - 1)) {
    if (!isSwipeSideBars) changeSwipeSidebarsStatus(true);
  } else if (isSwipeSideBars) changeSwipeSidebarsStatus(false);
  document.querySelector('.agenda-board__column-row').scrollTo(startScrollPosition + currentRangeSwipe, 0);
};

const onTouchStartHandler = ({
  toggleIsTouched, setStartScrollPosition, setStartCoordinates,
}) => (e) => {
  setStartCoordinates(e.changedTouches[0].clientX);
  toggleIsTouched(true);
  setStartScrollPosition(document.querySelector('.agenda-board__column-row').scrollLeft);
};

const toggleIsTouchedStateHandler = () => value => ({ isTouched: value });
const setStartCoordinatesStateHandler = () => value => ({ coordinatesStart: value });
const setCurrentBoardStateHandler = () => value => ({ currentBoard: value });
const setStartScrollPositionStateHandler = () => value => ({ startScrollPosition: value });

const enhance = ({ range = 50, boardList = [] }) => compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    range,
    boardList,
  }),
  withStateHandlers(() => ({
    isTouched: false, coordinatesStart: 0, startScrollPosition: 0, currentBoard: 0,
  }),
  {
    toggleIsTouched: toggleIsTouchedStateHandler,
    setStartCoordinates: setStartCoordinatesStateHandler,
    setCurrentBoard: setCurrentBoardStateHandler,
    setStartScrollPosition: setStartScrollPositionStateHandler,
  }),
  withHandlers({
    onTouchEnd: onTouchEndHandler,
    onTouchMove: onTouchMoveHandler,
    onTouchStart: onTouchStartHandler,
  }),
  lifecycle({
    componentDidMount() {
      const {
        onTouchEnd,
        onTouchStart,
        onTouchMove,
        changeSwipeSidebarsStatus,
      } = this.props;
      requestAnimationFrame(() => {
        document.querySelector('.agenda-board__column-row').addEventListener('touchstart', onTouchStart);
        document.querySelector('.agenda-board__column-row').addEventListener('touchmove', onTouchMove);
        document.querySelector('.agenda-board__column-row').addEventListener('touchend', onTouchEnd);
      });
      changeSwipeSidebarsStatus(false);
    },
    componentWillUnmount() {
      const {
        onTouchEnd,
        onTouchStart,
        onTouchMove,
      } = this.props;
      document.querySelector('.agenda-board__column-row').removeEventListener('touchstart', onTouchStart);
      document.querySelector('.agenda-board__column-row').removeEventListener('touchmove', onTouchMove);
      document.querySelector('.agenda-board__column-row').removeEventListener('touchend', onTouchEnd);
    },
    shouldComponentUpdate({ currentBoard, isTouched, coordinatesStart }) {
      return !(currentBoard !== this.props.currentBoard
        || isTouched !== this.props.isTouched
        || coordinatesStart !== this.props.coordinatesStart);
    },
  }),
);
export default enhance;
