import {
  compose,
  withHandlers,
  withStateHandlers,
  defaultProps,
  pure,
  hoistStatics,
} from 'recompose';

import { withRefs } from '../../../../utils/enchancers';
import TextAreaDefault from './messageTextArea';

const handlerHeightMessageTextArea = ({ getHeight, getRef }) => () => {
  if (getRef('messageTextArea')) {
    getHeight(getRef('messageTextArea').offsetHeight);
  }
};

const heightStateHandler = () => val => ({ height: val });
const toggleFocus = () => val => ({ isFocus: val });


const onSetRefHandler = ({ setRef, childrenRef }) => (ref) => {
  setRef('messageTextArea', ref);
  childrenRef(ref);
};

const enhance = compose(
  pure,
  withRefs(),
  defaultProps({
    onChangeHeight: () => {},
  }),
  withStateHandlers(() => ({ height: 0, isFocus: false, shortKeyActive: null }), {
    heightStateHandler,
    toggleFocusStateHandler: toggleFocus,
  }),
  withHandlers({
    handlerHeightMessageTextArea,
    onSetRef: onSetRefHandler,
  }),
  hoistStatics,
);

export default enhance(TextAreaDefault);
