import {
  compose, lifecycle, withHandlers, withStateHandlers, getContext,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import NotesList from './notesList';
import { projectActions, projectSelectors } from '../../../../state/project';
import preloaderWhileLoading from '../../../../utils/enchancers/preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';
import { uiActions } from '../../../../state/ui';


const mapStateToProps = (state, { match: { params: { id } } }) => ({
  notes: projectSelectors.getNotesList(state),
  files: projectSelectors.getProjectFiles(state)(id),
  isPending: projectSelectors.getNotesRequestPending(state),
});

const mapDispatchToProps = ({
  getNotesRequest: projectActions.getNotesRequest,
  setOpenModal: uiActions.openModal,

});

const changeActiveTab = ({ activeTab }) => (idTab) => {
  if (idTab === activeTab) {
    return {
      activeTab: 0,
    };
  }
  return {
    activeTab: idTab,
  };
};

const onDeleteProjectFileHandler = ({ setSelectedProjectFileId, setOpenModal }) => (fileId) => {
  setSelectedProjectFileId(fileId);
  setOpenModal('deleteProjectFileModal');
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    () => ({ activeTab: 0 }), {
      changeActiveTabStateHandler: changeActiveTab,
    },
  ),
  getContext({
    setSelectedProjectFileId: PropTypes.func.isRequired,
  }),
  withHandlers({
    onDeleteProjectFile: onDeleteProjectFileHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { match: { params: { id } }, getNotesRequest } = this.props;
      getNotesRequest({ projectId: id });
    },
    componentDidUpdate(prevProps) {
      const {
        match: { params: { id } },
        getNotesRequest, sortBy: { val: { order, sortBy } },
      } = this.props;
      if (this.props.sortBy !== prevProps.sortBy && prevProps.sortBy) {
        getNotesRequest({ projectId: id, sortBy, order });
      }
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    delay: 600,
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
);
export default enhance(NotesList);
