import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import { BrillWithIndex } from '../../../../components/index';
import './style.sass';

const ProjectSummary = ({ t, summary }) => (
  <div className="project-summary__cards">
    <div className="project-summary__card project-summary__card--all">
      <BrillWithIndex colorBorder="#fcccc4">{propOr('0', 'allCount', summary)}</BrillWithIndex>
      <h4 className="project-summary__title">{t('All')}</h4>
    </div>
    <div className="project-summary__card project-summary__card--progress">
      <BrillWithIndex colorBorder="#f66f5b" textColor="#f66f5b">{propOr('0', 'inProgressCount', summary)}</BrillWithIndex>
      <h4 className="project-summary__title">{t('In progress')}</h4>
    </div>
    <div className="project-summary__card project-summary__card--done">
      <BrillWithIndex colorBorder="#e6e8eb">{propOr('0', 'doneCount', summary)}</BrillWithIndex>
      <h4 className="project-summary__title">{t('Done')}</h4>
    </div>
  </div>
);

ProjectSummary.propTypes = {
  t: PropTypes.func.isRequired,
  summary: PropTypes.instanceOf(Object).isRequired,
};

export default ProjectSummary;
