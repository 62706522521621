import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { ButtonsCollection, PermissionElement } from '../../../../components';
import ChannelItem from './components';
import { InviteMemberButton } from '..';
import './style.sass';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const DirectChannels = ({ channelsList, openModal, t }) => (
  <ul className="direct-nav sidebar-menu">
    <li className="direct-nav__header light">
      <NavLink
        to="/messenger/directs/"
        className="direct-nav__title"
      >
        {t('DIRECT MESSAGES')}
      </NavLink>

      <PermissionElement module={MODULES.USERS} action={ACTIONS.CREATE}>
        <ButtonsCollection.ButtonPlus
          className="direct-nav__plus-button"
          onClick={() => openModal('inviteMembersModal')}
        />
      </PermissionElement>
    </li>
    <nav className="direct-nav__nav sidebar-menu">
      {channelsList.map(channel => (
        <ChannelItem
          key={channel}
          channelId={channel}
        />
      ))}
    </nav>
    <PermissionElement module={MODULES.USERS} action={ACTIONS.CREATE}>
      <div className="row">
        <div className="col-lg-12 d-flex m-1 justify-content-center">
          <InviteMemberButton onClick={() => openModal('inviteMembersModal')} />
        </div>
      </div>
    </PermissionElement>
  </ul>
);

DirectChannels.propTypes = {
  channelsList: PropTypes.arrayOf(PropTypes.number).isRequired,
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default DirectChannels;
