export default {
  ADD_EVENT_REQUEST: 'ADD_EVENT_REQUEST',
  GET_EVENTS_REQUEST: 'GET_EVENTS_REQUEST',
  UPDATE_EVENT_REQUEST: 'UPDATE_EVENT_REQUEST',
  DELETE_EVENT_REQUEST: 'DELETE_EVENT_REQUEST',


  SET_EVENT: 'SET_EVENT',
  SET_EVENTS: 'SET_EVENTS',
  DELETE_EVENT: 'DELETE_EVENT',
  UPDATE_EVENT: 'UPDATE_EVENT',
  REORDER_EVENTS: 'REORDER_EVENTS',
};
