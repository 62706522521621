import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { FormsCollection } from '../../../../../components';
import { DeleteTimeEntryModal, EditTimeEntryModal } from '../timeEntryCRUD';

const TimeEntryForm = ({
  t, handleChange, handleSubmit, values, errors, isPending,
}) => (
  <div className="time-entry__form-group">
    <div id="step-1y" className="card-body p-1">
      <FormsCollection.FormWrapper onSubmit={handleSubmit} className="time-entry__form">
        <div className="form-row">
          <div className="col-lg-4 col-md-4 col-sm-4 col-12  mb-lg-0 mb-2">
            <div className="row">
              <div className="col-lg-3 d-flex align-items-center">
                <div className="time-entry__label">Date</div>
              </div>
              <div className="col-lg-8">
                <FormsCollection.DatePicker
                  name="date"
                  className="time-entry__field"
                  onChange={handleChange}
                  value={values.date}
                  error={errors.date}
                  touched
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb-lg-0 mb-2">
            <div className="row">
              <div className="col-lg-3 d-flex align-items-center">
                <div className="time-entry__label">Time</div>
              </div>
              <div className="col-lg-8">
                <FormsCollection.Input
                  name="time"
                  id="create-task__estimated-time"
                  placeholder={t('1w 20m 21h 30m')}
                  onChange={handleChange}
                  className="time-entry__field time-entry__field--last m-0"
                  value={values.time}
                  error={errors.time}
                  touched
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-12 d-flex align-items-end">
            <button
              type="submit"
              disabled={isPending}
              className="btn btn-primary w-150px"
            >
            Submit
            </button>
          </div>
        </div>
      </FormsCollection.FormWrapper>
      <DeleteTimeEntryModal />
      <EditTimeEntryModal />
    </div>
  </div>
);

TimeEntryForm.propTypes = {
  t: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
};

export default TimeEntryForm;
