import * as materialsActions from './actions';
import * as materialsSelectors from './selectors';
import * as materialsOperations from './operations';
import * as materialsSchemas from './schema';
import materialsTypes from './types';
import reducer from './reducers';

export {
  materialsActions,
  materialsSelectors,
  materialsOperations,
  materialsSchemas,
  materialsTypes,
};

export default reducer;
