import React from 'react';
import PropTypes from 'prop-types';

import {
  FormsCollection, Layouts,
} from '../../../components';
import './style.sass';
import u5Image from '../../../assets/img/dummy/u5.png';
import { isNotNil } from 'ramda-extension';

const ChangePassword = ({
  values,
  handleChange,
  handleSubmit,
  errors,
}) => (
  <Layouts.Auth>
    <main>
      <div id="primary" className="p-t-b-100 height-full ">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mx-md-auto">
              <div className="text-center">
                <img src={u5Image} alt="" />
                <h3 className="mt-2">Reset password</h3>
              </div>
              <FormsCollection.FormWrapper onSubmit={handleSubmit}>
                <FormsCollection.Input
                  className="form-group has-icon"
                  classInput="form-control form-control-lg"
                  name="password"
                  value={values.password}
                  placeholder="Pasword"
                  type="password"
                  onChange={handleChange}
                >
                  <i className="icon-user-secret" />
                </FormsCollection.Input>
                <FormsCollection.Input
                  className="form-group has-icon"
                  classInput="form-control form-control-lg"
                  name="passwordConfirm"
                  value={values.passwordConfirm}
                  placeholder="Confirm password"
                  type="password"
                  error={isNotNil(errors) ? 'User name or password is not correct' : ''}
                  onChange={handleChange}
                >
                  <i className="icon-user-secret" />
                </FormsCollection.Input>
                <button type="submit" className="btn btn-success btn-lg btn-block">Reset password</button>
              </FormsCollection.FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layouts.Auth>
);

ChangePassword.propTypes = {
  values: PropTypes.shape({
    password: PropTypes.string,
    passwordConfirm: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    passwordConfirm: PropTypes.bool,
    password: PropTypes.bool,
  }).isRequired,
  authNotification: PropTypes.shape({
    password: PropTypes.object,
    passwordConfirm: PropTypes.object,
  }),
  errors: PropTypes.instanceOf(Object).isRequired,
};

ChangePassword.defaultProps = {
  authNotification: {},
};

export default ChangePassword;
