/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { TogglerLeftSideBar } from './components';

import './style.sass';

const Header = ({
  children, titlePage, isInvertBg, subTitle, mainTitle, isOnline, setIsOnline, pendingTitle,
  offlineRequestsStatus,
}) => (
  <div className="has-sidebar-left">
    {
      offlineRequestsStatus === null ? (
        <div className={`offline-bar ${!isOnline ? 'offline-bar--shown' : ''}`}>
          <div className="offline-bar__label">
            <i className="icon-error s-18 m-2" />
            This is offline version, all changes will be accept when your fix problems
            in your network.
            <span role="button" onClick={(e) => { e.preventDefault(); setIsOnline(true); }}>
              hide it
            </span>
            <br />
            <b>{pendingTitle}</b>
          </div>
        </div>
      ) : (
        <>
          {
          offlineRequestsStatus === 'inProgress' ? (
            <div className="offline-bar offline-bar--shown offline-bar--status offline-bar--progress">
              <div className="offline-bar__label">
                <i className="icon-circle s-18 m-2" />
                Offline changes accepting ....
                <br />
              </div>
            </div>
          )
            : (
              <div className={`offline-bar offline-bar--shown offline-bar--status offline-bar--progress ${isOnline ? '' : 'offline-bar--small'}`}>
                <div className="offline-bar__label">
                  <i className="icon-circle s-18 m-2" />
                  Offline changes accept.
                  {' '}
                  <span
                    role="button"
                    onClick={(e) => {
                      e.preventDefault();
                      if (/messenger/g.test(window.location.pathname)) {
                        window.location.reload();
                      } else {
                        window.location.href = '/';
                      }
                    }}
                  >
                    {' '}
Reload this page
                  </span>
                  <span role="button" onClick={(e) => { e.preventDefault(); setIsOnline(false); }}> hide it</span>
                  <br />
                </div>
              </div>
            )
        }
        </>

      )
    }

    <div className="pos-f-t">
      <div className="collapse" id="navbarToggleExternalContent">
        <div className="
         pt-2 pb-2 pl-4 pr-2"
        >
          <div className="search-bar">
            <input
              className="transparent s-24 text-white b-0 font-weight-lighter w-128 height-50"
              type="text"
              placeholder="start typing..."
            />
          </div>
          <div
            data-toggle="collapse"
            data-target="#navbarToggleExternalContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="paper-nav-toggle paper-nav-white active "
          >
            <i />
          </div>
        </div>
      </div>
    </div>
    <div className="sticky">
      <div className={`
          navbar d-flex justify-content-between bd-navbar accent-3

        ${isInvertBg ? 'navbar-expand white b-b' : 'bg-dark navbar-dark blue'}

          `}
      >
        <div className="w-100 row-group d-flex mb-0">
          <div className="col-1 p-0 d-flex justify-content-start">
            <div className="relative">
              <TogglerLeftSideBar />
            </div>
          </div>
          <div className="col-3 main-title-group">
            <h1 className="main-title-page">{mainTitle || titlePage}</h1>
            { subTitle && (
              <>
                <span> - </span>
                <h3 className="main-sub-title-page">{subTitle}</h3>
              </>
            )}
          </div>
          <div className="col-8 d-flex align-items-center justify-content-end pr-0">
            {
              children
            }
          </div>
        </div>
      </div>
    </div>
  </div>
);

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]),
  titlePage: PropTypes.string.isRequired,
  isInvertBg: PropTypes.bool.isRequired,
  subTitle: PropTypes.string.isRequired,
  mainTitle: PropTypes.string.isRequired,
  isOnline: PropTypes.bool.isRequired,
  setIsOnline: PropTypes.func.isRequired,
  pendingTitle: PropTypes.string.isRequired,
  offlineRequestsStatus: PropTypes.string.isRequired,
};

Header.defaultProps = {
  children: '',
};

export default Header;
