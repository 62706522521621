import { compose, getContext } from 'recompose';
import PropTypes from 'prop-types';

import {
  compose as RCompose, map, path, prop, values,
} from 'ramda';
import FilterFields from './filterFields';
import withAutocomplete from '../../../../../../utils/enchancers/withAutocomplete';
import { newTaskActions } from '../../../../../../state/newTask';
import { renameKeysTitleIntoValue } from '../../../../../../utils/helpers/uiHelpers';
import { usersActions } from '../../../../../../state/users';

const enhance = compose(
  withAutocomplete({
    name: 'getProjectAutocomplete',
    action: newTaskActions.getProjectsTaskCrudRequest,
    dataPath: RCompose(
      map(renameKeysTitleIntoValue),
      values,
      path(['data', 'entities', 'projects']),
    ),
    searchField: 'title',
  }),
  withAutocomplete({
    name: 'getUsersAutocomplete',
    action: usersActions.getUsersListAutocompleteRequest,
    dataPath: prop('users'),
  }),
  getContext({
    setUserId: PropTypes.func,
    setProjectId: PropTypes.func,
    setStartDate: PropTypes.func,
    setEndDate: PropTypes.func,
    selectedProjectId: PropTypes.number,
    selectedUserId: PropTypes.number,
    selectedStartDate: PropTypes.number,
    selectedEndDate: PropTypes.number,
  }),
);
export default enhance(FilterFields);
