import {
  compose, getContext, lifecycle, pure, withHandlers,
} from 'recompose';
import { path, identity } from 'ramda';
import PropTypes from 'prop-types';
import { withRefs } from '../../../../utils/enchancers';

import MentionsDropdown from './mentionsDropdown';
import { callWithPreventEvent } from '../../../../utils/helpers/uiComponentHelpers/DOMhelpers';

const onPreventHandler = () => callWithPreventEvent(identity);

const enhance = compose(
  pure,
  withRefs(),
  getContext({
    activeDropdownMember: PropTypes.number,
  }),
  withHandlers({
    onPrevent: onPreventHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { getRef, activeDropdownMember } = this.props;
      if (activeDropdownMember !== prevProps.activeDropdownMember) {
        const itemTop = path(['children', activeDropdownMember, 'offsetTop'])(getRef('mentionsContainer'));
        getRef('scrollWrapper').scrollTop(itemTop);
      }
    },
  }),
);

export default enhance(MentionsDropdown);
