import { createSelector } from 'reselect/lib/index';
import {
  pathOr, identity, memoizeWith,
} from 'ramda';

const getMaterials = createSelector(
  pathOr({}, ['materials', 'entities']), identity,
);

const getMaterialsResult = createSelector(
  pathOr([], ['materials', 'result']), identity,
);

const getMaterial = createSelector(
  getMaterials,
  materials => memoizeWith(identity, materialId => pathOr({}, [materialId], materials)),
);

const getPendingGetMaterials = createSelector(
  pathOr(false, ['pending', 'getMaterialsRequest']), identity,
);

const getPendingUpdateMaterial = createSelector(
  pathOr(false, ['pending', 'updateMaterialRequest']), identity,
);

export {
  getMaterial,
  getMaterials,
  getMaterialsResult,

  getPendingGetMaterials,
  getPendingUpdateMaterial,
};
