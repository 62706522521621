import { compose } from 'recompose';
import FilesField from './filesField';
import { withWindowWidth } from '../../../../../../utils/enchancers';
import preloaderWhileLoading from '../../../../../../utils/enchancers/preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../../../../../constants/ui';

const enhance = compose(
  withWindowWidth(),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    delay: 600,
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
);
export default enhance(FilesField);
