import {
  compose, withProps, withHandlers, withContext, lifecycle, withState, getContext, defaultProps,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { withRouter } from 'react-router';
import Directs from './directs';
import { messengerSelectors, messengerActions } from '../../state/messenger';
import { TITLES_CONSTANTS } from '../../constants/titles';
import * as uiActions from '../../state/ui/actions';

const { getDirectChannelsRequest, setMatchedDirectChannels } = messengerActions;
const ITEMS_ON_PAGE = 10;
const mapStateToProps = state => ({
  directChannelsList: messengerSelectors.getMatchedDirectChannelsList(state),
  directChannelsCount: messengerSelectors.getMatchedDirectChannelsCount(state),
  entities: messengerSelectors.getDirectChannels(state),
});

const mapDispatchToProps = ({
  getChannelsRequest: getDirectChannelsRequest,
  setCurrentPage: uiActions.setPage,
});

const loadMoreRequestHandler = ({
  itemsOnPage, getChannelsRequest, currentPage,
  selectedSearch, selectedSort,
}) => () => {
  const offset = parseInt((currentPage - 1) * itemsOnPage, 10);
  getChannelsRequest({}, {
    sortBy: 'name',
    order: selectedSort.value,
    limit: itemsOnPage,
    search: selectedSearch,
    offset,
    success: setMatchedDirectChannels,
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
  getContext({
    setSelectedSort: PropTypes.func,
  }),
  withState('selectedSearch', 'setSelectedSearch', ''),
  withState('selectedSort', 'setSelectedSort', {}),
  withRouter,
  withContext({
    selectedSearch: PropTypes.string,
    selectedSort: PropTypes.shape({}),
    setSelectedSort: PropTypes.func,
    setSelectedSearch: PropTypes.func,
  }, props => ({
    selectedSearch: props.selectedSearch,
    selectedSort: props.selectedSort,
    setSelectedSearch: props.setSelectedSearch,
    setSelectedSort: props.setSelectedSort,
  })),
  defaultProps({
    itemsOnPage: ITEMS_ON_PAGE,
  }),
  withProps(({ history, directChannelsCount }) => {
    const page = parseInt(history.location.search.match(/(?!page=)\d*/gi)[5] || 1, 10);
    return {
      currentPage: page,
      isPrev: page > 1,
      isNext: page * ITEMS_ON_PAGE < directChannelsCount,
    };
  }),
  withHandlers(() => ({
    loadMoreRequest: loadMoreRequestHandler,
  })),
  lifecycle({
    componentDidMount() {
      this.props.loadMoreRequest();
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.DIRECT_CHANNELS });
    },
    componentDidUpdate({ currentPage, selectedSort, selectedSearch }) {
      if (this.props.currentPage !== currentPage || selectedSort !== this.props.selectedSort) {
        this.props.loadMoreRequest();
      } else if (selectedSearch !== this.props.selectedSearch) {
        this.props.loadMoreRequest();
      }
    },
  }),
);


export default enhance(Directs);
