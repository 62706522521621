import * as permissionsActions from './actions';
import * as permissionsSelectors from './selectors';
import * as permissionsOperations from './operations';
import * as permissionsSchemas from './schema';
import permissionsTypes from './types';
import reducer from './reducers';

export {
  permissionsActions,
  permissionsSelectors,
  permissionsOperations,
  permissionsSchemas,
  permissionsTypes,
};

export default reducer;
