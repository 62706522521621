import {
  compose, lifecycle, defaultProps, getContext, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import UsersContainer from './usersContainer';
import { usersActions, usersSelectors } from '../../state/users';
import { permissionsActions } from '../../state/permissions';
import { uiActions } from '../../state/ui';
import preloaderWhileLoading from '../../utils/enchancers/preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../constants/ui';

const ITEMS_ON_PAGE = 10;

const mapStateToProps = state => ({
  usersList: usersSelectors.getUsersResult(state),
  usersCount: usersSelectors.getUsersCount(state),
  isPending: usersSelectors.getUsersListPending(state),
});

const mapDispatchToProps = {
  getUsersRequest: usersActions.getUsersListFetch,
  setOpenModal: uiActions.openModal,
  getPermissions: permissionsActions.getRolesRequest,
};

const loadMoreRequestHandler = ({
  itemsOnPage, getUsersRequest, currentPage,
  setSelectedSort,
}) => () => {
  const offset = parseInt((currentPage - 1) * itemsOnPage, 10);
  setSelectedSort({ limit: itemsOnPage, offset });
  getUsersRequest({ limit: itemsOnPage, offset });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setSelectedSort: PropTypes.func,
  }),
  withRouter,
  defaultProps({
    itemsOnPage: ITEMS_ON_PAGE,
  }),
  withProps(({ history, usersCount }) => {
    const page = parseInt(history.location.search.match(/(?!page=)\d*/gi)[5] || 1, 10);
    return {
      currentPage: page,
      isPrev: page > 1,
      isNext: page * ITEMS_ON_PAGE < usersCount,
    };
  }),
  withHandlers(() => ({
    loadMoreRequest: loadMoreRequestHandler,
  })),
  lifecycle({
    componentDidMount() {
      this.props.loadMoreRequest();
      this.props.getPermissions();
    },
    componentDidUpdate({ currentPage }) {
      if (this.props.currentPage !== currentPage) this.props.loadMoreRequest();
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.EXTRA_SMALL,
    alignContainerCenter: true,
    delay: 600,
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
);
export default enhance(UsersContainer);
