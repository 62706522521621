import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { withNamespaces } from 'react-i18next';
import FiltersRow from './filtersRow';
import {
  getProjectTasksAssignees, getProjectTasksAuthors,
} from '../../../../state/project/selectors';

const mapStateToProps = (state, { match: { params: { id } } }) => ({
  assignees: getProjectTasksAssignees(state, id),
  assigners: getProjectTasksAuthors(state, id),
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  withNamespaces(['common', 'projects']),
);
export default enhance(FiltersRow);
