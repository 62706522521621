import {
  branch, compose, renderNothing, withHandlers, withProps,
} from 'recompose';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';

import { withRouter } from 'react-router';
import TimeEntryItem from './timeEntryItem';
import { taskActions, taskSelectors } from '../../../../../state/task';
import { getFullName } from '../../../../../utils/helpers/userHelpers';
import { withUserProfile } from '../../../../../utils/enchancers';
import { getTimeStringBySeconds } from '../../../../../utils/helpers/dateHelpers';
import { formatDate } from '../../../../../utils/helpers/commonHelpers';
import { usersSelectors } from '../../../../../state/users';
import { getImageUrl } from '../../../../../utils/helpers/requestHelpers';
import { offlineRequestActions } from '../../../../../utils/middlewares/sagaRequestApi/state';
import { userSelectors } from '../../../../../state/user';

const mapStateToProps = (state, { match: { params: { taskId } } }) => ({
  items: taskSelectors.getTimeEntries(state)(taskId),
  users: usersSelectors.getUsersEntities(state),
  userData: userSelectors.getUserData(state),
});

const mapDispatchToProps = ({
  setNewTimeEntry: taskActions.setNewTimeEntry,
  deleteTimeEntry: taskActions.deleteTimeEntry,
  resetOfflineAction: offlineRequestActions.resetOfflineAction,
});

const onEditTimeEntryHandler = ({ onEdit, id }) => () => onEdit(id);
const onDeleteTimeEntryHandler = ({ onDelete, id }) => () => onDelete(id);

const onResetOfflineDeleteHandler = ({
  setNewTimeEntry, resetOfflineAction, item, match: { params: { taskId } },
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: item.requestIdDelete, method: 'DELETE', selectorName: 'deleteTimeEntryRequest' });
  setNewTimeEntry({
    timeEntry: {
      ...item,
      isDeleting: false,
    },
    meta: {
      taskId,
    },
  });
};
const onResetOfflineEditHandler = ({
  setNewTimeEntry, resetOfflineAction, item, match: { params: { taskId } },
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: item.requestIdPut, method: 'PUT', selectorName: 'updateTimeEntryRequest' });
  setNewTimeEntry({
    timeEntry: {
      id: item.id,
      ...item.oldResult,
      isUpdating: false,
    },
    meta: {
      taskId,
    },
  });
};
const onResetOfflineCreateHandler = ({
  deleteTimeEntry, resetOfflineAction, item, match: { params: { taskId } },
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: item.requestIdPost, method: 'POST', selectorName: 'addTimeEntryRequest' });
  deleteTimeEntry({ timeEntry: item, meta: { taskId } });
};


const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ items, id }) => !items[id], renderNothing),
  withUserProfile,
  withNamespaces(['common', 'tasks']),
  withProps(({
    items, id, prevId, onSetUserProfile, users, userData,
  }) => {
    const {
      user_id, time, created_at, date,
    } = items[id];
    const user = users[user_id].id ? users[user_id] : userData;
    return {
      id,
      item: items[id],
      userName: user ? getFullName(user) : '',
      userAvatar: user ? getImageUrl(user.avatar) : '',
      onShowUserProfile: () => onSetUserProfile(user_id),
      estimatedTime: getTimeStringBySeconds(time),
      dateLog: formatDate(date, 'DD MMMM YYYY'),
      createdAt: formatDate(created_at, 'h:m:s A'),
      isNewDateFlag: prevId === null || moment(date).date() !== moment(items[prevId].date).date(),
    };
  }),
  withHandlers({
    onEditTimeEntry: onEditTimeEntryHandler,
    onDeleteTimeEntry: onDeleteTimeEntryHandler,
    onResetOfflineDelete: onResetOfflineDeleteHandler,
    onResetOfflineEdit: onResetOfflineEditHandler,
    onResetOfflineCreate: onResetOfflineCreateHandler,
  }),
);
export default enhance(TimeEntryItem);
