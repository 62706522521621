import React from 'react';
import PropTypes from 'prop-types';

import { ModalWindow, FormsCollection } from '../../../../components';
import FieldItem from './fieldItem';
import FIELD_NAME from './constants';
import './style.sass';


const getFieldName = idField => `${FIELD_NAME}${idField}`;

const InviteMembersModal = ({
  isOpen,
  handleChange,
  handleBlur,
  handleSubmit,
  values,
  closeModalHandler,
  fieldsArray,
  deleteFieldItemHandler,
  addFieldItemHandler,
  getError,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={closeModalHandler}
    modalName="createChannel"
    title={t('Invite members')}
    className="invite-members__modal"
  >
    <FormsCollection.FormWrapper className="invite-members" onSubmit={handleSubmit}>
      <div className="form-row">
        {
          fieldsArray.map(id => (
            <FieldItem
              key={id}
              id={id}
              t={t}
              value={values[getFieldName(id)]}
              name={getFieldName(id)}
              error={getError(id)}
              touched
              onChange={handleChange}
              onBlur={handleBlur}
              onDelete={fieldsArray.length > 1 ? () => deleteFieldItemHandler(id) : null}
            />
          ))
        }
      </div>
      <div className="form-row">
        <div className="form-group d-flex">
          <span className="invite-members__icon--in-link icon-add-member" />
          <button
            className="invite-members__button-as-link link"
            onClick={() => addFieldItemHandler({ many: false })}
            type="button"
          >
            {t('Add another')}
          </button>
          {t('or add')}
          <button
            onClick={() => addFieldItemHandler({ many: true })}
            className="invite-members__button-as-link link"
            type="button"
          >
            <i className="icon-user-plus m-1" />
            {t('many at once')}
          </button>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <button
            type="submit"
            className="btn btn-primary w-100px"
          >
            {t('Invite')}
          </button>
        </div>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

InviteMembersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
  closeModalHandler: PropTypes.func.isRequired,
  fieldsArray: PropTypes.arrayOf(PropTypes.number).isRequired,
  deleteFieldItemHandler: PropTypes.func.isRequired,
  addFieldItemHandler: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

InviteMembersModal.defaultProps = {
  isPending: false,
};

export default InviteMembersModal;
