import { compose, lifecycle } from 'recompose';

import { connect } from 'react-redux';
import View from './view';
import { TITLES } from '../../../constants/titles';
import { uiSelectors } from '../../../state/ui';

const mapStateToProps = state => ({
  page: uiSelectors.getPage(state),
});

const enhancer = compose(
  connect(mapStateToProps),
  lifecycle({
    componentDidMount() {
      document.title = `${TITLES.SITE_NAME} - ${TITLES[this.props.page] || this.props.page}`;
    },
    componentDidUpdate({ page }) {
      if (this.props.page !== page) {
        document.title = `${TITLES.SITE_NAME} - ${TITLES[this.props.page] || this.props.page}`;
      }
    },
  }),
);

export default enhancer(View);
