import { compose } from 'recompose';
import { connect } from 'react-redux';
import ToggleRightSideBarButton from './toggleRightSideBarButton';
import { uiActions, uiSelectors } from '../../../../state/ui';

const { changeRightSidebarStatus } = uiActions;

const { getRightSidebar } = uiSelectors;

const mapStateToProps = state => ({
  status: getRightSidebar(state),
});

const mapDispatchToProps = dispatch => ({
  changeRightSidebarStatus: data => dispatch(changeRightSidebarStatus(data)),
});

const mergeProps = (fromState, fromDispatch, props) => ({
  toggleRightSideBarHandler: () => fromDispatch.changeRightSidebarStatus(!fromState.status),
  ...props,
  status: fromState.status,
});


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
);
export default enhance(ToggleRightSideBarButton);
