import React from 'react';
import PropTypes from 'prop-types';

import { FormsCollection, ButtonsCollection, Layouts } from '../../../components';
import './style.sass';

const SignUpSuccess = ({ t, prefix }) => (
  <Layouts.Auth>
    <div className="auth-wrapper__wrap">
      <FormsCollection.FormWrapper>
        <div className="auth-wrapper__form-contain">
          <h2 className="title weight--bold title--md text-align--center">
            {t('Sign up to {{domain}}', { domain: prefix })}
          </h2>
          <div className="auth-wrapper__helper-text--to-field">{t('Confirmation email has been sent successfully!')}</div>
          <div className="button-group">
            <ButtonsCollection.ButtonBrill
              className="auth-wrapper__button--with-border button--fill"
              borderColor="#26b5ed"
              asLink
              href="/"
              borderWidth="2"
            >
              {t('close')}
            </ButtonsCollection.ButtonBrill>
          </div>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </Layouts.Auth>
);

SignUpSuccess.propTypes = {
  t: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
};

export default SignUpSuccess;
