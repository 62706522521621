import * as customersActions from './actions';
import * as customersSelectors from './selectors';
import * as customersOperations from './operations';
import * as customersSchemas from './schema';
import customersTypes from './types';
import reducer from './reducers';

export {
  customersActions,
  customersSelectors,
  customersOperations,
  customersSchemas,
  customersTypes,
};

export default reducer;
