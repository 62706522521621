import {
  compose, withHandlers, withProps, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { replace } from 'ramda';

import CommentsTextArea from './commentsTextArea';
import { taskActions, taskSelectors } from '../../../../../../state/task';
import {
  convertDirtyEmojiInPure,
} from '../../../../../../utils/helpers/uiComponentHelpers/emojiHelpers';
import { uiSelectors } from '../../../../../../state/ui';

const mapDispatchToProps = {
  addCommentRequest: taskActions.addTaskCommentRequest,
};

const mapStateToProps = state => ({
  task: taskSelectors.getActiveTask(state),
  isPending: taskSelectors.getAddTaskCommentRequestPending(state),
  isLeftSideBarActive: uiSelectors.getLeftSidebar(state),
  isRightSideBarActive: uiSelectors.getRightSidebar(state),
});

const onChangeHandler = ({ setFieldValue }) => ({ target: { value } }) => setFieldValue(value);

const onSendHandler = ({
  addCommentRequest, value, setFieldValue, task: { project_id, id }, onSendComment,
}) => (e) => {
  e.preventDefault();
  addCommentRequest({
    projectId: project_id,
    taskId: id,
    content: convertDirtyEmojiInPure(replace(/&gt;/g, '>', value)),
  }, {
    callbacks: {
      success: () => {
        setFieldValue('');
        onSendComment(e);
      },
    },
  });
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('value', 'setFieldValue', ''),
  withProps(({ isLeftSideBarActive, isRightSideBarActive }) => ({
    isLeftSideBar: isLeftSideBarActive,
    isRightSideBar: isRightSideBarActive,
    isAllSideBar: isLeftSideBarActive && isRightSideBarActive,
  })),
  withHandlers({
    onChange: onChangeHandler,
    onSend: onSendHandler,
  }),
);
export default enhancer(CommentsTextArea);
