import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { FilterRow } from './components';
import { Pagination, ScrollWrapper } from '../../components';
import CustomersList from './components/customersList';

const CustomersContainer = ({
  customersList, customersCount, itemsOnPage, currentPage, isNext, isPrev,
}) => (
  <ScrollWrapper heightIs="100%" className="scroll-page">
    <div className="container-fluid">
      <div className="tab-content my-3" id="v-pills-tabContent">
        <FilterRow />
        <div
          className="tab-pane animated  show active"
          id="v-pills-all"
          role="tabpanel"
          aria-labelledby="v-pills-all-tab"
        >
          <div className="form-row">
            <div className="col-md-12">
              <div className="card r-0 shadow">
                <div className="table-responsive">
                  <form>
                    <table className="table table-striped table-hover r-0">
                      <thead>
                        <tr className="no-b">
                          <th style={{ width: '30px' }}>
                              #ID
                          </th>
                          <th>USER NAME</th>
                          <th>PROJECTS</th>
                          <th>ADDRESS</th>
                          <th>BUSINESS REGISTRATION NUMBER</th>
                          <th>COMPANY NAME</th>
                          <th>CONTACT INFO</th>
                          <th />
                        </tr>
                      </thead>
                      <CustomersList customersList={customersList} />
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            count={customersCount}
            limit={itemsOnPage}
            isNext={isNext}
            isPrev={isPrev}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  </ScrollWrapper>
);

CustomersContainer.propTypes = {
  customersList: PropTypes.instanceOf(Array).isRequired,
  customersCount: PropTypes.number.isRequired,
  itemsOnPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  isPrev: PropTypes.bool.isRequired,
  isNext: PropTypes.bool.isRequired,
};
export default CustomersContainer;
