import {
  branch, compose, renderNothing, withProps,
} from 'recompose';
import { connect } from 'react-redux';

import { getUser } from '../../../../state/users/selectors';
import AboutConversation from './aboutConversation';
import { userHelpers, requestHelpers } from '../../../../utils/helpers';

const mapStateToProps = (state, { userId }) => ({
  user: getUser(state)(userId),
});

const enhance = compose(
  branch(({ userId }) => !userId,
    renderNothing),
  connect(mapStateToProps),
  withProps(({ user }) => ({
    avatar: requestHelpers.getImageUrl(user.avatar),
    fullName: userHelpers.getFullName(user),
    nickName: user.username,
    onlineStatus: Boolean(user.is_online),
  })),
);
export default enhance(AboutConversation);
