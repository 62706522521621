import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import { ModalWindow, FormsCollection } from '../../../../../../components';
import './style.sass';

const CreateChannel = ({
  isOpen,
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  onClose,
  values,
  errors,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onClose}
    modalName="createChannel"
    title="Create new channel"
    className="modal-window__create-chanel"
  >
    <FormsCollection.FormWrapper onSubmit={handleSubmit} className="create-channel">
      <div className="form-row">
        <FormsCollection.Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
          error={propOr('', 'message', errors.title)}
          touched={touched.title}
          name="title"
          placeholder={t('Channel title')}
          className="col-lg-12"
          label="Title"
          id="create-channel__title"
        />
      </div>
      <div className="form-row">
        <FormsCollection.TextArea
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
          error={propOr('', 'message', errors.description)}
          touched={touched.description}
          name="description"
          className="col-lg-12"
          label={t('Description')}
          autoHeight
          maxHeight={190}
        />
      </div>
      {/* <div className="create-channel__row create-channel__row--privacy"> */}
      {/*  <div className="create-channel__col-label"> */}
      {/*    {t('Privacy')} */}
      {/*  </div> */}
      {/*  <div className="create-channel__col-fields"> */}
      {/*    <FormsCollection.Radio */}
      {/*      label="Public" */}
      {/*      name="privacy" */}
      {/*      value="public" */}
      {/*      onChange={handleChange} */}
      {/*      onBlur={handleBlur} */}
      {/*      checked={values.privacy === 'public'} */}
      {/*      id="create-channel-modal__privacy--public" */}
      {/*    /> */}
      {/*    <FormsCollection.Radio */}
      {/*      label="Private" */}
      {/*      onChange={handleChange} */}
      {/*      onBlur={handleBlur} */}
      {/*      name="privacy" */}
      {/*      value="private" */}
      {/*      checked={values.privacy === 'private'} */}
      {/*      id="create-channel-modal__privacy--private" */}
      {/*    /> */}
      {/*  </div> */}
      {/* </div> */}
      {/* { */}
      {/*  values.privacy === 'private' && ( */}
      {/*    <div className="create-channel__row create-channel__row--members"> */}
      {/*      <div className="create-channel__col-label"> */}
      {/*        {t('Members')} */}
      {/*      </div> */}
      {/*      <div className="create-channel__col-fields"> */}
      {/*        <AutoCompleteField */}
      {/*          options={usersList} */}
      {/*          isMulti */}
      {/*          name="members" */}
      {/*          placeholder={t('Choose members')} */}
      {/*          closeMenuOnSelect={false} */}
      {/*          value={values.members} */}
      {/*          onInputChange={getAutocompleteList} */}
      {/*          onChange={value => setFieldValue('members', value)} */}
      {/*          onBlur={setFieldTouched} */}
      {/*        /> */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  ) */}
      {/* } */}
      <div className="form-row">
        <div className="form-group">
          <button
            className="btn btn-primary w-100px"
            type="submit"
          >
            {t('Save')}
          </button>
        </div>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

CreateChannel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string,
    privacy: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.number),
    description: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    title: PropTypes.bool,
    privacy: PropTypes.bool,
    members: PropTypes.arrayOf(PropTypes.any),
    description: PropTypes.bool,
  }).isRequired,
  errors: PropTypes.shape({
    title: PropTypes.object,
    privacy: PropTypes.object,
    members: PropTypes.object,
    description: PropTypes.object,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default CreateChannel;
