import React from 'react';
import PropTypes from 'prop-types';

import {
  FiltersRow,
  AgendaBoardHeader,
  StatusesBoard,
} from './components';
import { ScrollWrapper } from '../../components';
import './style.sass';

const AgendaBoard = ({
  agendaStatusesList, sprints, onSelectSprint, getFilter, setRef, onSelectUser, onResetSprint, isProjectCompleted,
}) => (
  <div className="agenda-board">
    <ScrollWrapper className="agenda-board__scroll-wrapper">
      <FiltersRow
        sprints={sprints}
        onSelectSprint={onSelectSprint}
        onSelectUser={onSelectUser}
        onResetSprint={onResetSprint}
        currentItem={getFilter(null, 'sprint')}
        selectedUsers={getFilter([], 'assignee')}
        spentTime={68.889}
      />
      <div className="agenda-board__column-header">
        <AgendaBoardHeader sprintId={getFilter(null, 'sprint')} />
      </div>
      <div
        className="agenda-board__column-wrapper"
      >
        <StatusesBoard isDropDisabled={isProjectCompleted} statuses={agendaStatusesList} />
      </div>
    </ScrollWrapper>
  </div>
);

AgendaBoard.propTypes = {
  agendaStatusesList: PropTypes.arrayOf(PropTypes.number).isRequired,
  sprints: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectSprint: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  onResetSprint: PropTypes.func.isRequired,
  onSelectUser: PropTypes.func.isRequired,
};

AgendaBoard.defaultProps = {
};

export default AgendaBoard;
