import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Routes from './routes';
import { uiSelectors } from '../../../state/ui';
import { permissionsActions } from '../../../state/permissions';

const mapStateToProps = state => ({
  routeTypes: uiSelectors.getRouteTypes(state),
});

const withPermissions = compose(
  connect(null, {
    getPermissions: permissionsActions.getRolesRequest,
    getModules: permissionsActions.getModulesRequest,
  }),
  lifecycle({
    componentDidMount() {
      this.props.getPermissions();
      this.props.getModules();
    },
  }),
);

const enhance = compose(
  withPermissions,
  withRouter,
  connect(mapStateToProps),
  lifecycle({
    componentDidMount() {
      // this.props.connectSocket();
    },
  }),
);
export default enhance(Routes);
