/* eslint-disable */
import React from 'react';

import { concat } from 'ramda';
import PropTypes from 'prop-types';

const MentionTag = ({
  username, id, className, onClick,
}) => (
  <>
    &nbsp;
    <a
      data-id={id}
      data-type="mention"
      href="#"
      role="button"
      spellCheck={false}
      onClick={onClick}
      className={className}
    >
      { concat('@')(username) }
    </a>
    &nbsp;
  </>
);

export default MentionTag;

MentionTag.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};
