import { compose, withProps, getContext } from 'recompose';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  ifElse, pathOr, prop, always,
} from 'ramda';
import customerItem from './customerItem';
import { customersSelectors } from '../../../../state/customers';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { ROLE_TITLES } from '../../../../constants/users';

const mapStateToProps = (state, { customerId }) => ({
  customer: customersSelectors.getCustomer(state)(customerId),
});


const enhance = compose(
  connect(mapStateToProps),
  withProps(({ customer }) => {
    const avatarCustomerId = pathOr(null, ['avatar', 'id'], customer);
    return {
      customerName: getFullName(customer),
      customerAvatar: avatarCustomerId ? getImageUrl(avatarCustomerId) : '',
      customerPhone: ifElse(prop('phone'), prop('phone'), always('-'))(customer),
      customerPosition: ifElse(prop('position'), prop('position'), always('-'))(customer),
      customerRole: prop(prop('role_id', customer), ROLE_TITLES),
    };
  }),
  getContext({
    onDeleteCustomerModal: PropTypes.func,
    onEditCustomerModal: PropTypes.func,
  }),
);

export default enhance(customerItem);
