import React from 'react';
import PropTypes from 'prop-types';

const PreWrapper = ({ content }) => (<pre>{content}</pre>);

PreWrapper.propTypes = {
  content: PropTypes.oneOfType([PropTypes.instanceOf(Array)]).isRequired,
};

export default PreWrapper;
