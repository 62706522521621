import {
  compose, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';

import DeleteUser from './deleteUser';
import { uiSelectors, uiActions } from '../../../../state/ui';
import { usersActions, usersSelectors } from '../../../../state/users';

const mapStateToProps = (state, { selectedUserId }) => ({
  isOpen: uiSelectors.getModal(state)('deleteUser'),
  user: usersSelectors.getUser(state)(selectedUserId),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('deleteUser'),
  onDeleteUser: usersActions.deleteUserRequest,
};

const onDeleteHandler = ({
  onDeleteUser, selectedUserId, sort: { limit, offset }, onCloseModal,
}) => () => {
  onDeleteUser({}, {
    userId: selectedUserId,
    limit,
    offset,
    callbacks: {
      success: onCloseModal,
    },
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);

export default enhance(DeleteUser);
