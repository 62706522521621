// eslint-disable-next-line max-len
/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import './style.sass';
import 'react-big-calendar/lib/sass/styles.scss';

import { identity } from 'ramda';
import { PermissionElement, ScrollWrapper } from '../../components';
import { ACTIONS, MODULES } from '../../constants/pemissions';
import ModalWindow from '../../components/modalWindow';


const localizer = momentLocalizer(moment);

const ToolBar = ({
  label, setCurrentDate, date, onNavigate,
}) => (
  <div className="rbc-toolbar-custom p-2">
    <div className="row">
      <div className="col-sm-4 offset-sm-4 col-8 offset-2 d-flex justify-content-between align-items-center">
        <div
          role="button"
          title="Prev month"
          onClick={() => {
            onNavigate(identity, moment(date).subtract(1, 'months').toDate());
            setCurrentDate(moment(date).subtract(1, 'months').toDate());
          }}
          className="rbc-toolbar-custom__button"
        >
          <i className="icon-angle-left s-18 light-blue-text" />
        </div>
        <h2 className="title s-18 m-0 d-flex align-items-center">
          <i
            onClick={() => {
              setCurrentDate(new Date());
              onNavigate(identity, new Date());
            }}
            title="Go to now date"
            className="icon-calendar s-14 light-blue-text mr-2"
            role="button"
          />
          {label}
        </h2>
        <div
          title="Next month"
          onClick={() => {
            setCurrentDate(moment(date).add(1, 'months').toDate());
            onNavigate(identity, moment(date).add(1, 'months').toDate());
          }}
          role="button"
          className="rbc-toolbar-custom__button"
        >
          <i className="icon-angle-right s-18 light-blue-text" />
        </div>
      </div>
    </div>
  </div>
);

const Event = ({
  event, onEditEvent, onDeleteEvent, selectedEventShowId, setSelectedEventShowId,
}) => (
  <span
    className="rbc-event-custom"
    onClick={() => setSelectedEventShowId(event.id)}
    style={{ backgroundColor: event.color }}
  >
    <span className="rbc-event-custom__title">
      {event.title}
    </span>
    <PermissionElement module={MODULES.EVENTS} action={ACTIONS.UPDATE}>
      <span className="rbc-event-custom__button icon-pencil s-12" onClick={(e) => { e.stopPropagation(); onEditEvent(event.id); }} title="Edit" />
    </PermissionElement>

    <PermissionElement module={MODULES.EVENTS} action={ACTIONS.DELETE}>
      <span className="rbc-event-custom__button icon-trash s-12" onClick={(e) => { e.stopPropagation(); onDeleteEvent(event.id); }} title="Delete" />
    </PermissionElement>
    {
      event.title && (
        <ModalWindow
          title={event.title}
          isOpen={selectedEventShowId === event.id}
          onClose={(e) => { e.stopPropagation(); setSelectedEventShowId(0); }}
        >

          <div className="event-window__wrap">
            <span className="event-window">
              <span className="event-window__body">
                {event.description}
              </span>
              <span className="event-window__footer">
                <span className="event-window__date">
                  <b>From:</b>
                  {' '}
                  {`${moment(event.start).format('YYYY-MM-DD')}`}
                  {' '}
                  -
                  {' '}
                  <b>To:</b>
                  {' '}
                  {`${moment(event.end).format('YYYY-MM-DD')}`}
                </span>
              </span>
            </span>
          </div>
        </ModalWindow>
      )
    }
  </span>
);

const EventsContainer = ({
  events, onDeleteEvent, onEditEvent, setCurrentDate, currentDate, setSelectedEventId,
  selectedEventId, selectedEventShowId, setSelectedEventShowId, setOpenModal, setSelectedDate,
}) => (
  <ScrollWrapper heightIs="100%" className="scroll-page">
    <Calendar
      localizer={localizer}
      events={events}
      defaultDate={currentDate}
      components={{
        dateCellWrapper: ({ children, value }) => React.cloneElement(Children.only(children), {
          onClick: () => { setOpenModal('addEvent'); setSelectedDate({ start: value, end: value }); },
        }),
        eventWrapper: props => (
          <Event
            onDeleteEvent={onDeleteEvent}
            onEditEvent={onEditEvent}
            setSelectedEventId={setSelectedEventId}
            setSelectedEventShowId={setSelectedEventShowId}
            selectedEventShowId={selectedEventShowId}
            selectedEventId={selectedEventId}
            {...props}
          />
        ),
        toolbar: props => <ToolBar setCurrentDate={setCurrentDate} {...props} />,
        // eslint-disable-next-line react/prop-types
        dateHeader: ({ label }) => <div>{label}</div>,
      }}
      startAccessor={event => moment(event.start_date).toDate()}
      endAccessor={event => moment(event.end_date).toDate()}
    />
  </ScrollWrapper>
);
ToolBar.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  setCurrentDate: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
};

Event.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  onEditEvent: PropTypes.func.isRequired,
  onDeleteEvent: PropTypes.func.isRequired,
  selectedEventShowId: PropTypes.number.isRequired,
  setSelectedEventShowId: PropTypes.func.isRequired,
};

EventsContainer.propTypes = {
  events: PropTypes.instanceOf(Array).isRequired,
  onDeleteEvent: PropTypes.func.isRequired,
  onEditEvent: PropTypes.func.isRequired,
  setCurrentDate: PropTypes.func.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  setSelectedEventId: PropTypes.func.isRequired,
  selectedEventId: PropTypes.number.isRequired,
  selectedEventShowId: PropTypes.number.isRequired,
  setSelectedEventShowId: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
};

export default EventsContainer;
