import {
  put,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  prop, compose, lensProp,
} from 'ramda';

import { uiActions } from '../../state/ui';
import {
  getOrElse, map, concatProps, safeNotNil, setObjLens,
} from './commonHelpers';
import { getImageUrl } from './requestHelpers';

function* userLogout() {
  yield put(uiActions.resetUi());
  yield put(push('/'));
  localStorage.removeItem('authToken');
}

const getFullName = concatProps(' ', 'first_name', 'last_name');
const getUsername = prop('username');

const lensAvatar = lensProp('avatar');
const setAvatar = setObjLens(lensAvatar);

const maybeAvatarFile = compose(
  getOrElse(''),
  map(compose(getImageUrl, prop('id'))),
  safeNotNil,
);

const getAvatar = compose(maybeAvatarFile, prop('avatar'));

export {
  getAvatar,
  getFullName,
  getUsername,
  userLogout,
  setAvatar,
};
