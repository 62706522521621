import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose';
import { withNamespaces } from 'react-i18next';

import FormProfile from './formProfile';

const onRemoveDisabledHandler = ({ setIsDisabled }) => () => setIsDisabled(false);

const enhance = compose(
  withNamespaces(['common']),
  withState('isDisabled', 'setIsDisabled', false),
  withHandlers({
    onRemoveDisabled: onRemoveDisabledHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { pending, setIsDisabled } = this.props;
      if (pending && prevProps.pending !== pending) {
        setIsDisabled(true);
      }
    },
  }),
);
export default enhance(FormProfile);
