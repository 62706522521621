import {
  compose, getContext, withHandlers, defaultProps, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MentionItem from './mentionItem';
import { withUserData } from '../../../../../../utils/enchancers';
import { getUser } from '../../../../../../state/users/selectors';
import { setGlobalVariableForMention } from '../../../../../../utils/helpers/mentionHelpers/lookup';
import { GLOBAL_MENTION_ID } from '../../../../../../constants/messenger';

const mapStateToProps = (state, props) => ({
  user: getUser(state)(props.itemId),
});

const onClickHandler = ({ onClickMention, index }) => () => onClickMention(index);

const enhance = compose(
  connect(mapStateToProps),
  getContext({
    onClickMention: PropTypes.func.isRequired,
  }),
  withProps(({
    itemId,
    user,
  }) => (itemId === GLOBAL_MENTION_ID ? ({ user: setGlobalVariableForMention([])[0] }) : user)),
  defaultProps({
    dataMentionId: '',
  }),
  withUserData(({ user }) => user),
  withHandlers({
    onClick: onClickHandler,
  }),
);

export default enhance(MentionItem);
