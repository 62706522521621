import React from 'react';
import PropTypes from 'prop-types';
import { TabWrapper } from '../../../../components';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import { TabWrapperMobile, Members } from '..';

const MembersContainer = ({
  windowWidth,
  setActiveTitle,
  activeTabTitle,
  isProjectMembersTabOpen,
  changeProjectMembersTabStatus,
}) => (
  windowWidth > WINDOW_WIDTH.SMALL ? (
    <TabWrapper
      onChangeStatusTab={changeProjectMembersTabStatus}
      defaultStatusTab={isProjectMembersTabOpen}
      title={(
        <h2 className="title">
        Members
        </h2>
    )}
    >
      <Members />
    </TabWrapper>
  ) : (
    <TabWrapperMobile setActiveTitle={setActiveTitle} title="Members" active={activeTabTitle}>
      <Members />
    </TabWrapperMobile>
  )
);

MembersContainer.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  setActiveTitle: PropTypes.func,
  activeTabTitle: PropTypes.string,
  isProjectMembersTabOpen: PropTypes.bool.isRequired,
  changeProjectMembersTabStatus: PropTypes.func.isRequired,
};

MembersContainer.defaultProps = {
  setActiveTitle: () => {},
  activeTabTitle: '',
};

export default MembersContainer;
