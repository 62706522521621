import React from 'react';

import {
  Layouts,
} from '../../components';
import {
  LeftSideBar, Header, ProfileContainer,
} from '../../containers';
import './style.sass';

const Profile = () => (
  <Layouts.Main>
    <LeftSideBar />
    <div className="main-container page-project__main-container">
      <Header />
      <div className="page page-profile  has-sidebar-left height-full">
        <ProfileContainer />
      </div>
    </div>
  </Layouts.Main>
);

export default Profile;
