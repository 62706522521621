/* eslint-disable */
import {
  curry, isEmpty, cond, gte, T,
} from 'ramda';
import { Either } from 'ramda-fantasy';

const { Left, Right } = Either;

const getSize = (index, itemSize) => {
  if (typeof itemSize === 'function') {
    return itemSize(index);
  }
  return Array.isArray(itemSize) ? itemSize[index] : itemSize;
};

const isHighMeasured = curry((itemOffset, offset) => cond([
  [gte(itemOffset), () => Right(offset)],
  [T, () => Left(offset)],
])(offset));

const getStartIndex = curry((
  lastMeasuredOffset,
  searchStart,
  offset,
) => searchStart(isHighMeasured(lastMeasuredOffset, offset)));

const searchStartIndex = curry((
  exponentialSearch,
  binarySearch,
) => Either.either(exponentialSearch, binarySearch));

const exponentialSearch = curry((
  getSizeAndPositionForIndex,
  itemCount,
  binarySearch,
  lastMeasuredIndex,
  offset,
) => {
  let interval = 1;
  let item = Math.max(0, lastMeasuredIndex);

  while (
    lastMeasuredIndex < itemCount()
      && getSizeAndPositionForIndex(item).offset < offset
  ) {
    item += interval;
    interval *= 2;
  }

  return binarySearch(
    getSizeAndPositionForIndex,
    Math.min(item, itemCount() - 1),
    Math.floor(item / 2),
    offset,
  );
});

const binarySearch = curry((
  getSizeAndPositionForIndex,
  low,
  high,
  offset,
) => {
  let middle = 0;
  let currentOffset = 0;

  let itemHigh = high;
  if (high < 0) {
    itemHigh = Math.max(0, high);
  }

  while (low <= itemHigh) {
    middle = low + Math.floor((itemHigh - low) / 2);

    currentOffset = getSizeAndPositionForIndex(middle).offset;
    //
    // console.log('low', low);
    // console.log('itemHigh', itemHigh);
    // console.log('middle', middle);

    if (currentOffset === offset) {
      return middle;
    } if (currentOffset < offset) {
      low = middle + 1;
    } else if (currentOffset > offset) {
      itemHigh = middle - 1;
    }
  }

  if (low > 0) {
    return low - 1;
  }

  return 0;
});

const getUpdatedOffsetForIndex = curry((
  getSizeAndPositionForIndex,
  totalSize,
  align,
  containerSize,
  currentOffset,
  targetIndex,
) => {
  if (containerSize <= 0) {
    return 0;
  }

  const datum = getSizeAndPositionForIndex(targetIndex);

  const maxOffset = datum.offset;
  const minOffset = maxOffset - containerSize + datum.size;

  // eslint-disable-next-line
  let idealOffset;

  const end = 'end';


  switch (align) {
    case end:
      idealOffset = minOffset;
      break;
    // case ALIGNMENT.CENTER:
    //   idealOffset = maxOffset - (containerSize - datum.size) / 2;
    //   break;
    // case ALIGNMENT.START:
    //   idealOffset = maxOffset;
    //   break;
    default:
      idealOffset = Math.max(minOffset, Math.min(maxOffset, currentOffset));
  }

  return  maxOffset - (containerSize - datum.size) / 2;
});

const getSizeAndPositionForIndex = curry((
  lastMeasuredIndex,
  lastMeasuredSizeAndPosition,
  setItemSize,
  setLastMeasured,
  itemSizeAndPositionData,
  itemSizeGetter,
  index,
) => {
  if (index > lastMeasuredIndex()) {
    const test = lastMeasuredSizeAndPosition();
    let offset = test.offset + test.size;
    for (let i = lastMeasuredIndex() + 1; i <= index; i++) {
      const size = getSize(i, itemSizeGetter);
      if (isNaN(size)) {
        throw Error(`Invalid size returned for index ${i} of value ${size}`);
      }
      setItemSize(i, size, offset);
      offset += size;
    }
    setLastMeasured(index);
  }

  return itemSizeAndPositionData[index];
});

const getSizeAndPositionOfMeasuredItem = curry((data, index) => (index >= 0
  ? data[index]
  : { offset: 0, size: 0 }));

const getTotalSize = curry((lastItem, countItems, estimatedItemSize) => {
  return lastItem.offset + lastItem.size + countItems * estimatedItemSize;
});

const getStopIndex = curry((getSizeAndPositionForIndex, height, itemCount, index, offset) => {
  const datum = getSizeAndPositionForIndex(index);
  offset = datum.offset + datum.size;
  let stop = index;
  const maxOffset = height + offset;
  while (offset < maxOffset && stop < itemCount - 1) {
    stop++;
    offset += getSizeAndPositionForIndex(stop).size;
  }
  return stop;
});


const SizeAndPositionManager = (height, offset, itemCount, estimatedItemSize, itemSizeGetter, overCount) => {
  const _itemSizeAndPositionData = {};
  let _lastMeasuredIndex = -1;

  let _start = 0;
  let _stop = 0;
  let _itemCount = itemCount;

  const _setItemSizeAndPositionData = curry((i, size, offset) => _itemSizeAndPositionData[i] = {
    offset,
    size,
  });

  const setItemsCount = curry((index) => {
    _itemCount = index;
  });

  const _setLastMeasuredIndex = curry((index) => {
    _lastMeasuredIndex = index;
  });

  const getLastMeasuredIndex = () => _lastMeasuredIndex;

  const getSizeAndPositionOfLastMeasuredItem = getSizeAndPositionOfMeasuredItem(
    _itemSizeAndPositionData,
  );

  const getSizeAndPositionForIndexK = getSizeAndPositionForIndex(
    () => _lastMeasuredIndex,
    () => getSizeAndPositionOfLastMeasuredItem(_lastMeasuredIndex),
    _setItemSizeAndPositionData,
    _setLastMeasuredIndex,
    _itemSizeAndPositionData,
    itemSizeGetter,
  );


  const exponentialSearchK = exponentialSearch(
    getSizeAndPositionForIndexK,
    () => _itemCount,
    binarySearch,
  );

  const binarySearchK = binarySearch(getSizeAndPositionForIndexK, 0);

  return {
    getVisibleRange: (scrollTop, itemCount) => {
      const last = getLastMeasuredIndex();

      // eslint-disable-next-line
      const { offset } = getSizeAndPositionOfLastMeasuredItem(last);

      // console.log('offset', offset, "scrollTop", scrollTop, 'last', last);
      // console.log('scrollTop', scrollTop);
      // console.log('last', scrollTop);

      const start = getStartIndex(
        offset,
        searchStartIndex(exponentialSearchK(last), binarySearchK(last)),
        scrollTop,
      );

      const stop = getStopIndex(
        getSizeAndPositionForIndexK,
        height,
        itemCount,
        start,
        scrollTop,
      );

      _start = Math.max(0, start - overCount);
      _stop = Math.min(stop + overCount, itemCount - 1);

      return {
        start: _start,
        stop: _stop,
      };
    },
    getTotalSize: () => {
      return getTotalSize(
        getSizeAndPositionOfLastMeasuredItem(_lastMeasuredIndex),
        _itemCount - _lastMeasuredIndex - 1,
        estimatedItemSize,
      );
    },
    getSizeAndPositionForIndex: getSizeAndPositionForIndexK,
    // eslint-disable-next-line
    getUpdatedOffsetForIndex: (offset, target) => getUpdatedOffsetForIndex(
      getSizeAndPositionForIndexK,
      getTotalSize(
        getSizeAndPositionOfLastMeasuredItem(_lastMeasuredIndex),
        _itemCount - _lastMeasuredIndex - 1,
        estimatedItemSize,
      ),
      'end',
      height,
      offset,
      target,
    ),
    getLastMeasuredIndex,
    resetItem: (index) => {
      const reset = Math.min(getLastMeasuredIndex(), index - 1);
      _setLastMeasuredIndex(reset);
    },
    start: () => _start,
    stop: () => _stop,
    itemSizeGetter,
    setItemsCount,
  };
};

const SizeAndPositionManagerInstance = () => {
  let instance = {};

  function createInstance(height, offset, itemCount, estimatedItemSize, itemSizeGetter, overCount) {
    return SizeAndPositionManager(
      height,
      offset,
      itemCount,
      estimatedItemSize,
      itemSizeGetter,
      overCount,
    );
  }

  return {
    // Get the Singleton instance if it exists
    // or create one if doesn't
    getInstance(height, offset, itemCount, estimatedItemSize, itemSizeGetter, overCount) {
      if (isEmpty(instance)) {
        instance = createInstance(
          height,
          offset,
          itemCount,
          estimatedItemSize,
          itemSizeGetter,
          overCount,
        );
      }
      return instance;
    },
    deleteInstance() {
      instance = {};
    },
  };
};


export default SizeAndPositionManagerInstance();
