import {
  compose, withState, lifecycle, branch, renderComponent,
} from 'recompose';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { withNamespaces } from 'react-i18next';

import { userSelectors, userActions } from '../../../state/user';
import ResetPassword from './resetPassword';
import { helpers } from '../../../utils';
import { uiActions } from '../../../state/ui';
import Page404 from '../../page404';
import { TITLES_CONSTANTS } from '../../../constants/titles';

const { resetPasswordFetch, checkResetPasswordToken } = userActions;
const { displayNotification } = uiActions;
const { translateHelpers } = helpers;

const mapDispatchToProps = ({
  resetPasswordRequest: resetPasswordFetch,
  displayNotification,
  checkResetCode: checkResetPasswordToken,
  setCurrentPage: uiActions.setPage,
});

const mapStateToProps = state => ({
  resetPasswordErrors: userSelectors.getResetPasswordErrors(state),
  getCheckCodeErrors: userSelectors.getCheckResetPasswordToken(state),
  pending: userSelectors.getPendingResetPasswordRequest(state),
});

const rules = yup.object().shape({
  password: yup
    .string()
    .required({
      message: 'required',
      field: 'password',
      params: {
        key: translateHelpers.t('common', 'Password'),
      },
    }),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], {
      field: 'passwordConfirm',
      message: 'match',
      params: {
        key: translateHelpers.t('common', 'Passwords'),
      },
    })
    .required({
      message: 'required',
      field: 'passwordConfirm',
      params: {
        key: translateHelpers.t('common', 'Password confirm'),
      },
    }),
});

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('showPassword', 'setShowPassword', true),
  withNamespaces(['common', 'validation']),
  withFormik({
    validate: async (values, props) => {
      const { displayNotification: showNotification } = props;
      try {
        await rules.validate(values);
        showNotification(null);
      } catch (fail) {
        const { message } = fail;
        showNotification({
          [message.field]: {
            message: message.message,
            params: message.params,
          },
        });
      }
    },
    mapPropsToValues: () => ({ password: '', passwordConfirm: '' }),
    validationSchema: rules,
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: (values, { props: { resetPasswordRequest, match } }) => {
      const { userId: id, code } = match.params;
      const { password, passwordConfirm: repeat } = values;
      const data = {
        password, repeat, id, code,
      };

      resetPasswordRequest(data);
    },
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.displayNotification(null);
    },
    componentDidMount() {
      const { userId, code } = this.props.match.params;
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.PAGE_RESET_PASSWORD });
      this.props.checkResetCode({ id: userId, code });
    },
  }),
  branch(
    ({ getCheckCodeErrors }) => getCheckCodeErrors,
    renderComponent(Page404),
  ),
);

export default enhancer(ResetPassword);
