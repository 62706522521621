import * as yup from 'yup';
import { isValidLogTime } from '../../../../../utils/helpers/dateHelpers';

const rules = yup.object().shape({
  date: yup
    .date()
    .nullable('Is required')
    .required('Is required'),
  time: yup
    .string()
    .required('Is required')
    .test('estimated_time', 'logTimeIsNotValid',
      isValidLogTime),
});

export default rules;
