import React from 'react';
import PropTypes from 'prop-types';

import { Header, Channels } from '../../../../containers';
import './style.sass';

const ChannelsContainer = ({ history: { goBack } }) => (
  <div className="main-container">
    <Header />
    <div className="main-container__content main-container__content--profile">
      <Channels onGoBack={goBack} />
    </div>
  </div>
);

ChannelsContainer.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default ChannelsContainer;
