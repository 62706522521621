import {
  compose, hoistStatics, lifecycle, withHandlers, withStateHandlers, defaultProps,
} from 'recompose';
import { withRefs } from '../../../../utils/enchancers';
import TimePickerComponent from './timePicker';

// TODO: Refactor for VP
const convertTime = val => (parseInt(val, 10) < 10 ? `0${parseInt(val, 10)}` : `${parseInt(val, 10)}`);

const clickOutsideHandler = ({ getRef }) => ({ event, togglePicker, isPicker }) => {
  if (isPicker && !getRef('inputFieldGroup').contains(event.target)) {
    togglePicker(false);
  }
};

const changeHourSliderHandler = ({
  setHourStateHandler, getRef,
}) => (oldHour, newHour) => {
  setHourStateHandler(newHour, getRef);
  const fieldElement = getRef('fieldHour').inputElement;
  fieldElement.value = convertTime(newHour);
};

const changeMinuteSliderHandler = ({
  setMinuteStateHandler, getRef,
}) => (oldMinute, newMinute) => {
  setMinuteStateHandler(newMinute, getRef);
  const fieldElement = getRef('fieldMinute').inputElement;
  fieldElement.value = convertTime(newMinute);
};

const changeMinuteHandler = ({
  setMinuteStateHandler, getRef,
}) => (e, updateSlider = true) => {
  if (updateSlider) {
    getRef('minuteSlider').slickGoTo(e.value, true);
  }
  setMinuteStateHandler(e.value);
};

const changeHourHandler = ({ setHourStateHandler, getRef }) => (e, updateSlider = true) => {
  if (updateSlider) {
    getRef('hoursSlider').slickGoTo(e.value, true);
  }
  setHourStateHandler(e.value);
};

const togglePicker = ({
  getRef, isPicker, currentMinute, currentHour, togglePickerStateHandler,
}) => () => {
  const fieldMinute = getRef('fieldMinute');
  const fieldHour = getRef('fieldHour');

  fieldMinute.inputElement.value = convertTime(currentMinute);
  fieldHour.inputElement.value = convertTime(currentHour);
  getRef('hoursSlider').slickGoTo(currentHour, true);
  getRef('minuteSlider').slickGoTo(currentMinute, true);
  togglePickerStateHandler(!isPicker);
};

const updateSliderMinute = ({ getRef }) => (val) => {
  getRef('minuteSlider').slickGoTo(val, true);
};

const setInitialValue = ({ getRef, disabled }) => ({ hours, minutes }) => {
  const fieldMinute = getRef('fieldMinute');
  const fieldHour = getRef('fieldHour');

  fieldMinute.inputElement.value = disabled ? '' : convertTime(hours);
  fieldHour.inputElement.value = disabled ? '' : convertTime(minutes);
};

const updateSliderHour = ({ getRef }) => (val) => {
  getRef('hourSlider').slickGoTo(val, true);
};

const setHourStateHandler = () => val => ({ currentHour: val });
const setMinuteStateHandler = () => val => ({ currentMinute: val });
const setFocusStateHandler = () => val => ({ inFocus: val });
const togglePickerStateHandler = () => val => ({ isPicker: val });

const enhance = compose(
  withRefs(),
  defaultProps(
    {
      slidersConfig: {
        dots: false,
        infinite: true,
        speed: 250,
        vertical: true,
        slidesToShow: 3,
        loop: true,
        initialSlide: 0,
        arrows: true,
      },
      onChange: () => {
      },
      value: {
        hours: 0,
        minutes: 0,
      },
    },
  ),
  withStateHandlers(() => ({
    currentHour: 0, currentMinute: 0, isPicker: false, inFocus: false,
  }), {
    setHourStateHandler,
    setMinuteStateHandler,
    setFocusStateHandler,
    togglePickerStateHandler,
  }),
  withHandlers({
    changeHourSliderHandler,
    changeMinuteSliderHandler,
    changeMinuteHandler,
    changeHourHandler,
    clickOutsideHandler,
    updateSliderMinute,
    updateSliderHour,
    togglePicker,
    setInitialValueHandler: setInitialValue,
    convertTimeToString: () => val => convertTime(val),
  }),
  lifecycle({
    componentDidMount() {
      const { setInitialValueHandler, value } = this.props;
      const hours = typeof value.hours === 'number' ? value.hours : '';
      const minutes = typeof value.minutes === 'number' ? value.minutes : '';

      setInitialValueHandler({ hours, minutes });
      document.addEventListener('click', e => this.props.clickOutsideHandler({ event: e, ...this.props }), true);
    },
    componentWillUnmount() {
      document.removeEventListener('click', e => this.props.clickOutsideHandler({ event: e, ...this.props }), true);
    },
    componentDidUpdate(prevProps) {
      const {
        onChange, currentHour, currentMinute, name, disabled, setInitialValueHandler,
      } = this.props;
      if (disabled !== prevProps.disabled) {
        setInitialValueHandler({ hours: 0, minutes: 0 });
      }
      if (currentHour !== prevProps.currentHour || currentMinute !== prevProps.currentMinute) {
        onChange({
          target: {
            value: {
              hours: currentHour,
              minutes: currentMinute,
            },
            name,
          },
        });
      }
    },
  }),
);
export default hoistStatics(enhance)(TimePickerComponent);
