import {
  compose, withProps, withHandlers, withContext, lifecycle, withState, getContext, defaultProps,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';

import { propOr } from 'ramda';
import Channels from './channels';
import { messengerSelectors, messengerActions } from '../../state/messenger';
import { CHANNELS_YOU_BELONG_TO, CHANNELS_YOU_CAN_JOIN } from './constants';
import { setYouBelongToChannels, setYouCanJoinChannels } from '../../state/messenger/actions';
import { TITLES_CONSTANTS } from '../../constants/titles';
import { uiActions } from '../../state/ui';

const ITEMS_ON_PAGE = 10;

const mapStateToProps = state => ({
  youBelongToChannelsList: messengerSelectors.getYouBelongToChannelsList(state),
  youCanJoinChannelsList: messengerSelectors.getYouCanJoinChannelsList(state),
  youBelongToChannelsCount: messengerSelectors.getYouBelongToChannelsCount(state),
  youCanJoinChannelsCount: messengerSelectors.getYouCanJoinChannelsCount(state),
  entities: messengerSelectors.getGroupChannels(state),
});

const mapDispatchToProps = ({
  getChannelsRequest: messengerActions.getGroupChannelsRequest,
  setCurrentPage: uiActions.setPage,
});

const getChannelsAction = ({ type }) => {
  if (type === CHANNELS_YOU_CAN_JOIN) return setYouCanJoinChannels;
  return setYouBelongToChannels;
};


const loadMoreRequestHandler = ({
  itemsOnPage, getChannelsRequest, currentPage,
  selectedSearch, selectedSort, selectedView,
}) => () => {
  const offset = parseInt((currentPage - 1) * itemsOnPage, 10);
  const notMember = selectedView.id === 1 || !selectedView.id ? { notMember: true } : {};
  getChannelsRequest({}, {
    sortBy: 'name',
    order: selectedSort.value,
    limit: itemsOnPage,
    search: selectedSearch,
    offset,
    success: getChannelsAction({ type: propOr(1, 'value', selectedView) }),
    ...notMember,
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
  getContext({
    setSelectedSort: PropTypes.func,
  }),
  withState('selectedSearch', 'setSelectedSearch', ''),
  withState('selectedView', 'setSelectedView', {}),
  withState('selectedSort', 'setSelectedSort', {}),
  withProps(({ selectedView: { value }, ...props }) => ({
    result: value === CHANNELS_YOU_BELONG_TO ? props.youBelongToChannelsList : props.youCanJoinChannelsList,
    count: value === CHANNELS_YOU_BELONG_TO ? props.youBelongToChannelsCount : props.youCanJoinChannelsCount,
    entities: props.entities,
  })),
  withRouter,
  withContext({
    selectedSearch: PropTypes.string,
    selectedSort: PropTypes.shape({}),
    selectedView: PropTypes.shape({}),
    setSelectedSort: PropTypes.func,
    setSelectedSearch: PropTypes.func,
    setSelectedView: PropTypes.func,
  }, props => ({
    selectedSearch: props.selectedSearch,
    selectedSort: props.selectedSort,
    setSelectedSearch: props.setSelectedSearch,
    setSelectedSort: props.setSelectedSort,
    selectedView: props.selectedView,
    setSelectedView: props.setSelectedView,
  })),
  defaultProps({
    itemsOnPage: ITEMS_ON_PAGE,
  }),
  withProps(({ history, count }) => {
    const page = parseInt(history.location.search.match(/(?!page=)\d*/gi)[5] || 1, 10);
    return {
      currentPage: page,
      isPrev: page > 1,
      isNext: page * ITEMS_ON_PAGE < count,
    };
  }),
  withHandlers(() => ({
    loadMoreRequest: loadMoreRequestHandler,
  })),
  lifecycle({
    componentDidMount() {
      this.props.loadMoreRequest();
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.GROUP_CHANNELS });
    },
    componentDidUpdate({
      currentPage, selectedSort, selectedSearch, selectedView,
    }) {
      if (this.props.currentPage !== currentPage || selectedSort !== this.props.selectedSort || selectedView !== this.props.selectedView) {
        this.props.loadMoreRequest();
      } else if (selectedSearch !== this.props.selectedSearch) {
        this.props.loadMoreRequest();
      }
    },
  }),
);


export default enhance(Channels);
