import React from 'react';
import PropTypes from 'prop-types';

import { ScrollWrapper } from '../../components';
import {
  FiltersRow,
  DragDropArea,
} from './components';
import './style.sass';

const Tasks = ({
  sprints, getTasks, onDragEnd, getFilter, projectTasksPendingRequest, isProjectCompleted,
}) => (
  <div className="tasks">
    <ScrollWrapper className="tasks__scroll-wrapper" heightIs="100%">
      <FiltersRow
        selectedAssignee={getFilter(null, 'assignee')}
        selectedAssigner={getFilter(null, 'created_by')}
        spentTime={68.889}
      />
      <DragDropArea
        getTasks={getTasks}
        onDragEnd={onDragEnd}
        isProjectCompleted={isProjectCompleted}
        isLoading={projectTasksPendingRequest}
        sprints={sprints}
      />
    </ScrollWrapper>
  </div>
);

Tasks.propTypes = {
  match: PropTypes.shape({}),
  sprints: PropTypes.arrayOf(PropTypes.number),
  getTasks: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  projectTasksPendingRequest: PropTypes.bool.isRequired,
};

Tasks.defaultProps = {
  match: {},
  sprints: [],
};

export default Tasks;
