import { compose, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { withNamespaces } from 'react-i18next';
import { push } from 'connected-react-router';

import { closeModal } from '../../../../../../state/ui/actions';
import { getModal } from '../../../../../../state/ui/selectors';
import { messengerActions } from '../../../../../../state/messenger';
import { usersActions, usersSelectors } from '../../../../../../state/users';
import CreateChannel from './createChannel';

const { addChannel } = messengerActions;
const { getUsersListAutocompleteRequest, setUsersListAutocomplete } = usersActions;
const { getUsersForSelect } = usersSelectors;

const getAutocompleteList = ({
  getUsersAutocomplete, setUsersAutocomplete, values,
}) => (value, { action }) => {
  if (action === 'input-change') {
    getUsersAutocomplete({
      q: value,
      limit: 5,
      selectedIds: values.members.map(item => item.value).join(','),
    });
  }
  if (action === 'set-value') {
    setUsersAutocomplete({
      users: [],
    });
  }
};

const onClose = ({ resetForm, onCloseModal }) => () => {
  resetForm();
  onCloseModal();
};
const submitForm = (formValues, {
  resetForm,
  props: { addChannelRequest, onCloseModal, pushRoute },
}) => {
  const {
    title,
    privacy,
    members,
    description,
  } = formValues;
  const params = {
    name: title,
    is_public: privacy === 'public',
    members: members.map(member => member.value),
    description,
  };
  addChannelRequest({
    params,
    meta: {
      callbacks: {
        success: ({ channel }) => {
          pushRoute(`/messenger/${channel.id}`);
          resetForm({});
          onCloseModal();
        },
      },
    },
  });
};

const rules = yup.object().shape({
  title: yup
    .string()
    .required({
      field: 'title',
      message: 'Title is required',
    }),
  privacy: yup
    .string()
    .required({
      message: 'Privacy required',
    }),
  members: yup
    .array(),
  description: yup
    .string()
    .required({
      message: 'Description is required',
    }),
});

const mapStateToProps = state => ({
  usersList: getUsersForSelect(state),
  isOpen: getModal(state)('createChannelModal'),
});

const mapDispatchToProps = ({
  addChannelRequest: addChannel,
  getUsersAutocomplete: getUsersListAutocompleteRequest,
  setUsersAutocomplete: setUsersListAutocomplete,
  onCloseModal: () => closeModal('createChannelModal'),
  pushRoute: push,
});


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'validation']),
  withFormik({
    mapPropsToValues: () => ({
      title: '',
      privacy: 'public',
      members: [],
      description: '',
    }),
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: submitForm,
  }),
  withHandlers({
    getAutocompleteList,
    onClose,
  }),
  lifecycle({
    componentDidMount() {
      this.props.getUsersAutocomplete({
        q: '',
        limit: 5,
        selectedIds: [],
      });
    },
  }),
);
export default enhance(CreateChannel);
