import {
  values, compose, assoc, map, path, curry, prop, set, lensProp, pathOr,
} from 'ramda';
import { renameEachObjectKeys, renameKeys } from './commonHelpers';
import { getFullName } from './userHelpers';
import { getImageUrl } from './requestHelpers';
import { setFocusAfterNodeForMention } from './uiComponentHelpers/caretHelpers';

const setLabelAsUsername = taskData => assoc('label', getFullName(taskData), taskData);

const renameKeysForUsers = renameEachObjectKeys({ username: 'label', id: 'value' });
const renameKeysTitleIntoLabel = renameEachObjectKeys({ title: 'label' });
const renameKeysTitleIntoValue = renameKeys({ title: 'label', id: 'value' });

const makeSelectArrayFromEntities = curry((transform, source) => compose(
  transform,
  values,
)(source));

const setFullUsernameAsLabel = compose(
  map(setLabelAsUsername),
  values,
);

const makeObjectWithAvatars = user => ({
  alt: getFullName(user),
  id: prop('id', user),
  select: false,
  src: pathOr(null, ['avatar'], user) ? getImageUrl(path(['avatar'], user)) : null,
});

const avatarLens = lensProp('active');

const toggleAvatar = curry((selector, item) => {
  const avatar = item;
  if (avatar.id === selector) {
    return set(avatarLens, !avatar.active, item);
  }
  return avatar;
});

const setAvatarActive = curry(id => map(toggleAvatar(id)));

const getImageFrom = path(['image', 'id']);
const getArrayFromEntities = makeSelectArrayFromEntities(renameKeysTitleIntoLabel);

const setCaretToTheEndPosition = (element) => {
  const range = document.createRange();
  const sel = window.getSelection();
  let node = element.lastChild;
  if (node && node.lastChild) {
    node = element.lastChild;
  }
  if (!node) {
    element.focus();
  } else {
    setFocusAfterNodeForMention(sel, range, node.length, node);
  }
};

export {
  setAvatarActive,
  renameKeysForUsers,
  renameKeysTitleIntoValue,
  getImageFrom,
  setCaretToTheEndPosition,
  getArrayFromEntities,
  setFullUsernameAsLabel,
  makeSelectArrayFromEntities,
  renameKeysTitleIntoLabel,
  makeObjectWithAvatars,
};
