import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormsCollection, Layouts } from '../../../components';
import './style.sass';
import u5Image from '../../../assets/img/dummy/u5.png';
import { path } from 'ramda';

const SignUp = ({
  handleBlur,
  values,
  handleSubmit,
  saveEmailWithDomain,
  errors,
}) => (
  <Layouts.Auth>
    <main>
      <div id="primary" className="p-t-b-100 height-full ">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mx-md-auto">
              <div className="text-center">
                <img src={u5Image} alt="" />
                <h3 className="mt-2">Sign Up</h3>
              </div>
              <FormsCollection.FormWrapper onSubmit={handleSubmit}>
                <FormsCollection.Input
                  className="form-group has-icon sign-up-field"
                  classInput="form-control form-control-lg"
                  name="emailWithoutPrefix"
                  onChange={e => saveEmailWithDomain(e)}
                  onBlur={handleBlur}
                  value={values.emailWithoutPrefix}
                  error={path(['emailWithoutPrefix', 'message'], errors)}
                  id="sing-up__email-without-prefix"
                  placeholder="example"
                >
                  <i className="icon-envelope-o" />
                  <div className="sign-up-field__pseudo-placeholder">
                    @wetelo.com
                  </div>
                </FormsCollection.Input>
                <button type="submit" className="btn btn-success btn-lg btn-block">Sign up</button>
                <div className="row">
                  <div className="col-lg-12">
                    <Link to="/forgot-password" className="forget-pass"> Forgot password?</Link>
                  </div>
                  <div className="col-lg-12">
                    <Link to="/" className="forget-pass">Do have an account?</Link>
                  </div>
                </div>
              </FormsCollection.FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layouts.Auth>
);

SignUp.propTypes = {
  errors: PropTypes.instanceOf(Object).isRequired,
  saveEmailWithDomain: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SignUp;
