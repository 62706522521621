import React from 'react';
import PropTypes from 'prop-types';

import { Layouts, PermissionElement } from '../../components';
import { AddEvent, DeleteEvent, EditEvent } from './components';
import { LeftSideBar, Header, EventsContainer } from '../../containers';
import './style.sass';
import { ACTIONS, MODULES } from '../../constants/pemissions';

const Events = ({
  onOpenAddEventModal, selectedEventId, selectedDate, setSelectedDate,
}) => (
  <Layouts.Main className="page-project">
    <LeftSideBar>
      {/* <Categories /> */}
      {/* <CoWorkers /> */}
    </LeftSideBar>
    <div className="main-container page-project__main-container">
      <Header>
        <div className="form-row d-flex w-100 mb-0">
          <div className="col-lg-12 pr-0 d-flex align-items-center justify-content-end">
            <PermissionElement module={MODULES.EVENTS} action={ACTIONS.CREATE}>
              <button
                type="button"
                className="btn-primary btn-lg button-create-project"
                onClick={onOpenAddEventModal}
              >
Add new
                {' '}
                {' '}
                <span className="hidden-sm">event</span>
              </button>
            </PermissionElement>
          </div>
        </div>
      </Header>
      <div className="page  has-sidebar-left height-full">
        <EventsContainer setSelectedDate={setSelectedDate} />
      </div>
    </div>
    <AddEvent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
    <DeleteEvent selectedEventId={selectedEventId} />
    <EditEvent selectedEventId={selectedEventId} />
  </Layouts.Main>
);

Events.propTypes = {
  onOpenAddEventModal: PropTypes.func.isRequired,
  selectedEventId: PropTypes.number.isRequired,
  selectedDate: PropTypes.string.isRequired,
  setSelectedDate: PropTypes.string.isRequired,
};

export default Events;
