import { DragDropContext } from 'react-beautiful-dnd';
import React from 'react';
import PropTypes from 'prop-types';

import { ColumnBoard } from '..';

const StatusesBoard = ({
  statuses, onDragEnd, onDragStart, onDragUpdate, isDragging, updateDroppable, isDropDisabled,
}) => (
  <div
    className="agenda-board__column-row"
  >
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate} onDragStart={onDragStart}>
      {statuses.map(status => (
        <ColumnBoard
          isDragging={isDragging}
          isDropDisabled={isDropDisabled}
          updateDroppable={updateDroppable}
          statusId={status}
          key={`agenda - ${status}`}
        />
      ))}
    </DragDropContext>
  </div>
);

StatusesBoard.propTypes = {
  statuses: PropTypes.arrayOf(PropTypes.number).isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragUpdate: PropTypes.func.isRequired,
  updateDroppable: PropTypes.number,
  isDragging: PropTypes.bool.isRequired,
};

StatusesBoard.defaultProps = {
  updateDroppable: null,
};

export default StatusesBoard;
