import {
  branch,
  compose, getContext, renderNothing, withHandlers,
} from 'recompose';
import { prop } from 'ramda';
import { connect } from 'react-redux';
import { withFormik } from 'formik/dist/index';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import rules from '../rules';
import { uiActions, uiSelectors } from '../../../../../state/ui';
import { projectActions, projectSelectors } from '../../../../../state/project';
import EditNote from './editNote';

const mapStateToProps = (state, { selectedNoteId }) => ({
  isOpen: uiSelectors.getModal(state)('editNoteModal'),
  note: projectSelectors.getNote(state)(selectedNoteId),
  isPending: projectSelectors.getUpdateNoteRequestPending(state),
});

const mapDispatchToProps = ({
  closeModal: () => uiActions.closeModal('editNoteModal'),
  updateNote: projectActions.updateNoteRequest,
});

const formSubmit = (formValues, { resetForm, closeModal, ...props }) => {
  const { updateNote, projectId, selectedNoteId } = props.props;
  const data = { ...formValues, projectId, noteId: selectedNoteId };
  updateNote(data, {
    callbacks: {
      success: props.props.closeModal,
    },
  });
};

const onCloseModalHandler = ({ resetForm, closeModal, note }) => () => {
  resetForm({
    title: note.title,
    content: note.content,
  });
  closeModal();
};

const enhance = compose(
  getContext({
    selectedNoteId: PropTypes.number.isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'tasks']),
  branch(({ note }) => !prop('title', note), renderNothing),
  withFormik({
    mapPropsToValues: ({ note }) => ({
      title: note.title,
      content: note.content,
    }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: formSubmit,
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
  }),
);

export default enhance(EditNote);
