import React from 'react';
import { isNotEmpty, isNotNil, notEqual } from 'ramda-extension';
import {
  always,
  replace,
  path,
  concat,
  keys,
  filter,
  compose,
  cond,
  curry,
  equals,
  flip,
  forEachObjIndexed,
  head,
  inc,
  lensProp,
  lt,
  map,
  omit,
  pick,
  prop,
  slice,
  T, assoc, identity, length,
} from 'ramda';
import { Tuple } from 'ramda-fantasy';

import MessageElements from '../../../components/messageElements';

import {
  curryRegexMatch,
  curryRegexReplace,
  curryRegexTest,
  currySplit,
  regexRules,
} from '../uiComponentHelpers/common';
import {
  getOrElse, isConditionRight, safeNotNil, setObjLens, map as functorMap,
} from '../commonHelpers';
import {
  convertDirtyEmojiInPure,
} from '../uiComponentHelpers/emojiHelpers';
import { convertToSelector } from '../mentionHelpers/events';
import { truncate } from '../stringHelpers/common';

import { convertTsWithTimeZone } from '../dateHelpers';
import { messenger } from '../../../constants';
import { pathToChannelName } from './channelHelpers';
import { getFullName } from '../userHelpers';
import { GLOBAL_MENTION_ID } from '../../../constants/messenger';

const getCreatedBy = prop('created_by');
const pathToMessageContent = path(['message', 'content']);
const propMessage = prop('message');

const lensContent = lensProp('content');
const setContent = setObjLens(lensContent);

const isUserNotAuthor = curry((userId, pathToMessageAuthor) => compose(
  notEqual(userId),
  pathToMessageAuthor,
));

const checkCurrentUserNotAuthor = curry((id, pathToAuthor) => compose(
  isConditionRight(isUserNotAuthor(id, pathToAuthor)),
));

const convertToHtml = curry((ownerId, members, onHandler, messageId, options) => content => (
  <MessageElements
    ownerId={ownerId}
    members={members}
    options={options}
    key={`message-elements${messageId}`}
    messageId={messageId}
    onHandler={onHandler}
  >
    {content}
  </MessageElements>
));


const convertMessageBeforeSubmit = compose(
  curryRegexReplace(regexRules.regDirtyCharacters, ''),
  convertDirtyEmojiInPure,
  convertToSelector,
);

const increaseMessageIndex = (items, countMessage) => (value) => {
  const messages = items;
  messages[value.index + countMessage] = {
    offset: value.offset,
    index: value.index + countMessage,
    fresh: true,
  };
  return messages;
};

const makeNewDimensions = (initialObj, removedIndex, size) => (value, key) => {
  const obj = initialObj;
  // eslint-disable-next-line no-return-assign
  const changedObj = (index, isFresh) => obj[index] = ({
    index: Number(index),
    fresh: isFresh,
    offset: value.offset,
  });
  cond([
    [lt(removedIndex), () => changedObj(key - 1, true)],
    [compose(
      cond([[equals(true), () => equals(size, removedIndex)]]),
      equals(size),
      inc,
    ), () => changedObj(key, true)],
    [T, () => changedObj(key, true)],
  ])(Number(key));
};

const removeMessageFromDimensions = curry((newDimensions, itemsHeight, size, index) => compose(
  always(newDimensions),
  forEachObjIndexed(makeNewDimensions(newDimensions, index, size)),
  flip(omit)(itemsHeight),
)([index]));

// TODO TZ must be dynamic
const formatMessageDate = convertTsWithTimeZone('Europe/KIEV', messenger.DATE_MESSAGE_FORMAT);
const truncateMessage = compose(truncate(134), prop('content'));

const splitMentionInMessage = (string) => {
  const tuple = Tuple(string);
  const splitString = currySplit(regexRules.regMessageContent)(string);
  return tuple(splitString);
};

const isMention = compose(curryRegexTest(regexRules.regMentionForConvert));

const leaveMentions = filter(isMention);
const tupleWithUsernameAndIs = (data) => {
  const id = Number(prop(1, data));
  const username = prop(2, data);
  return Tuple(id, username);
};

const parseMentionSelector = compose(
  tupleWithUsernameAndIs,
  curryRegexMatch(regexRules.regIdAndUsername),
  slice(1, Infinity),
);

const parseMentionSelectors = map(parseMentionSelector);

const makeMentionsArray = compose(
  parseMentionSelectors,
  getOrElse([]),
  map(leaveMentions),
  safeNotNil,
  Tuple.snd,
);

const parseMentionsFromString = compose(makeMentionsArray, splitMentionInMessage);

const tupleWithMessageAndContent = data => Tuple(data, pathToMessageContent(data));

const getMentionIds = message => compose(
  Tuple(Tuple.fst(message)),
  parseMentionsFromString,
  Tuple.snd,
)(message);

const isUserMentioned = userId => compose(
  equals(userId),
  Tuple.fst,
);
const isMessageForAllChannel = compose(
  equals(GLOBAL_MENTION_ID),
  Tuple.fst,
);


const checkIfShowMentionNotification = userId => message => compose(
  functorMap(Tuple(Tuple.fst(message))),
  cond([
    [compose(lt(0), length, filter(isMessageForAllChannel)), isConditionRight(
      compose(isNotEmpty, filter(isMessageForAllChannel)),
    )],
    [T, isConditionRight(compose(isNotEmpty, filter(isUserMentioned(userId))))],
  ]),
  Tuple.snd,
)(message);

const replaceUsername = message => (mention) => {
  const username = Tuple.snd(mention);
  const selector = `[@${Tuple.fst(mention)}|${username}]`;
  return setContent(compose(
    replace(selector, `${username} `),
    prop('content'),
  ), message);
};

const replaceMentionToUsername = (mentions, start) => (payload) => {
  const lengthMentions = mentions.length;
  if (start >= lengthMentions) {
    return payload;
  }
  const message = replaceUsername(payload)(mentions[start]);
  return replaceMentionToUsername(mentions, start + 1)(message);
};

const assocTitleToNotification = channelData => (message) => {
  const fullName = getFullName(message);
  const title = concat(`${pathToChannelName(channelData)} `, fullName);
  return assoc('title', title, message);
};

const getNotificationData = pick(['avatar', 'content', 'first_name', 'last_name']);

const contentNotificationForGroup = tupleWithMessage => compose(
  assocTitleToNotification(Tuple.fst(tupleWithMessage)),
  getNotificationData,
  replaceMentionToUsername(Tuple.snd(tupleWithMessage), 0),
  propMessage,
  Tuple.fst,
)(tupleWithMessage);

const contentNotificationForDirect = data => compose(
  identity,
  assoc('title', getFullName(propMessage(data))),
  getNotificationData,
  propMessage,
)(data);

const isFirstMessageOnDay = messageDate => compose(
  cond([
    [isNotNil, identity],
    [T, () => null],
  ]),
  prop(messageDate),
);

const getFirstMessageByCondition = condition => compose(
  head,
  keys,
  filter(condition),
);

const pathMessageEntities = path(['entities', 'MESSAGES']);

export {
  formatMessageDate,
  isFirstMessageOnDay,
  getCreatedBy,
  truncateMessage,
  checkIfShowMentionNotification,
  propMessage,
  getMentionIds,
  contentNotificationForGroup,
  convertToHtml,
  tupleWithMessageAndContent,
  convertMessageBeforeSubmit,
  pathToMessageContent,
  pathMessageEntities,
  getFirstMessageByCondition,
  increaseMessageIndex,
  checkCurrentUserNotAuthor,
  setContent,
  removeMessageFromDimensions,
  contentNotificationForDirect,
};
