import React from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty,
} from 'ramda';

import {
  DropDown,
  UserListAvatar,
} from '../../../../components';
import './style.sass';

const Filters = ({
  t,
  displayAvatars,
  hiddenAvatars,
  onClickAvatar,
  onSelectSprint,
  sprintsList,
  currentItem,
  onResetSprint,
}) => (
  <div className="agenda-board__row">
    <div className="agenda-board__tasks-filter">
      <div className="agenda-board__tasks-filter__sort-group">
        {!isEmpty(sprintsList) && (
          <DropDown
            label={`${t('Sprints')} : `}
            placeholder={t('All')}
            onChange={onSelectSprint}
            onReset={onResetSprint}
            currentItem={Number(currentItem)}
            name="sprints-row"
            list={sprintsList}
          />
        )}
      </div>
    </div>
    <UserListAvatar
      onClick={onClickAvatar}
      displayAvatars={displayAvatars}
      hiddenAvatars={hiddenAvatars}
    />
  </div>
);

Filters.propTypes = {
  displayAvatars: PropTypes.instanceOf(Object),
  hiddenAvatars: PropTypes.instanceOf(Object),
  onSelectSprint: PropTypes.func.isRequired,
  onResetSprint: PropTypes.func.isRequired,
  onClickAvatar: PropTypes.func.isRequired,
  currentItem: PropTypes.number,
  sprintsList: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  currentItem: null,
  hiddenAvatars: null,
  displayAvatars: null,
};

export default Filters;
