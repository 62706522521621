import {
  compose,
  lifecycle,
  pure,
  withHandlers,
  withProps,
  withState,
  withStateHandlers,
  withContext,
} from 'recompose';
import {
  always, cond, equals, ifElse, propOr, T, replace, test, when, call, identity, curry, prop, head,
  concat, F,
} from 'ramda';
import PropTypes from 'prop-types';

import { NimbleEmojiIndex } from 'emoji-mart';
import emojiData from 'emoji-mart/data/messenger';
import { notEqual } from 'ramda-extension';
import {
  createTexNode,
  getWritingWord,
  replaceNodeByOffset,
  setFocusAfterNode,
  positionCurrentWord,
  isFocusInElement,
  insertNodeInCurrentFocus,
  insertNodeAfterElement,
} from '../helpers/uiComponentHelpers/caretHelpers';
import {
  convertEmojiForCopy,
  convertSpanEmojiToImg,
  getEmojiHtmlStringById,
} from '../helpers/uiComponentHelpers/emojiHelpers';
import {
  callWithPreventEvent,
  getHTMLElementByString,
} from '../helpers/uiComponentHelpers/DOMhelpers';
import { KEYS } from '../../constants/ui';
import { regexRules } from '../helpers/uiComponentHelpers/common';
import { setCaretToTheEndPosition } from '../helpers/uiHelpers';

const setDataCodeEmoji = curry((code, element) => {
  const el = element;
  if (propOr(false, 'setAttribute', el)) el.setAttribute('data-code', `:${code}:`);
  return el;
});

const getEmojiElementById = compose(
  convertSpanEmojiToImg,
  getHTMLElementByString,
  getEmojiHtmlStringById,
);

const onSetEmojiByAutocompleteHandler = ({
  getField,
  onUpdateContent,
  setEmojiAutoComplete,
}) => (emoji) => {
  if (!emoji) return false;
  const { id } = emoji;
  const field = getField().getEl();
  const emptyTextNode = call(createTexNode, '');
  const emojiElement = getEmojiElementById(id);

  const range = document.createRange();
  const selection = window.getSelection();
  const { focusNode, focusOffset } = selection;
  const node = setDataCodeEmoji(id, emojiElement);
  const { start, end } = positionCurrentWord(focusOffset, focusNode.data);
  replaceNodeByOffset(range, selection, field, focusNode, start, end, node);
  node.parentNode.insertBefore(emptyTextNode, emojiElement.nextSibling);
  setFocusAfterNode(field, emptyTextNode);
  onUpdateContent(field.innerHTML);
  requestAnimationFrame(() => setEmojiAutoComplete('')); // clean autocomplete after replace  string to emoji
  return true;
};

const onOpenEmojiHandler = ({ setIsEmojiOpen }) => () => callWithPreventEvent(
  call(setIsEmojiOpen, true),
);

const onCloseEmojiHandler = ({ setIsEmojiOpen }) => () => callWithPreventEvent(
  call(setIsEmojiOpen, false),
);

const setIsEmojiOpenStateHandlers = () => value => ({ isEmojiOpen: value });

const setIsEmojiAutocompleteOpenStateHandler = () => value => ({ isEmojiAutoComplete: value });

const setSelectedEmoji = (onSet, emoji) => () => when(propOr(false, 'id', emoji), call(onSet, emoji));

const onResetAutoCompleteHandler = ({ setEmojiAutoComplete }) => () => call(setEmojiAutoComplete, '');

const setSelectedEmojiPrev = (setEmojiSelected, emojiSelected) => () => {
  const isEmojiPrev = emojiSelected - 1 > -1;
  if (isEmojiPrev) setEmojiSelected(emojiSelected - 1);
  return false;
};

const setSelectedEmojiNext = (setEmojiSelected, emojiSelected, emojisAutocomplete) => () => {
  const isEmojiNext = emojisAutocomplete.length > emojiSelected + 1;
  if (isEmojiNext) setEmojiSelected(emojiSelected + 1);
  return false;
};

const listenerShortKeys = (e,
  onSetEmojiByAutocomplete,
  resetAutoCompleteValue,
  selectedEmojiDataByIndex,
  setEmojiSelected,
  emojiSelected,
  emojisAutocomplete) => {
  const params = [setEmojiSelected, emojiSelected, emojisAutocomplete];

  return cond([
    [equals(KEYS.ENTER), setSelectedEmoji(onSetEmojiByAutocomplete,
      selectedEmojiDataByIndex)],
    [equals(KEYS.ESCAPE), resetAutoCompleteValue],
    [equals(KEYS.LEFT_ARROW), setSelectedEmojiPrev(...params)],
    [equals(KEYS.RIGHT_ARROW), setSelectedEmojiNext(...params)],
    [equals(KEYS.TAB), setSelectedEmojiNext(...params)],
    [T, always(true)],
  ])(e.keyCode);
};

const getEmojiValue = curry(isEmoji => emojiString => ifElse(
  () => equals(true, isEmoji),
  replace(/:/g, ''),
  always(''),
)(emojiString));

const checkEmojiStringIsFully = (onSet, selectedEmojiIndex) => (string) => {
  const isFullEmojiString = test(regexRules.regEmojiForConvert);
  ifElse(
    isFullEmojiString,
    compose(setSelectedEmoji(onSet, selectedEmojiIndex)),
    T,
  )(string);

  return string;
};

const onSetEmojiAutocompleteHandler = ({
  onSetEmojiByAutocomplete,
  emojisAutocomplete,
  emojiSelected,
  setEmojiAutoComplete,
  setEmojiTyping,
  setEmojiSelected,
  setIsEmojiOpen,
  isEmojiOpen,
}) => (e) => {
  const writingWord = () => getWritingWord();
  if (isEmojiOpen) setIsEmojiOpen(false);
  if (replace(/\W/g, '', writingWord()).length < 1) { setEmojiAutoComplete(''); return e; }
  const isEmojiWriting = test(regexRules.regEmojiText)(writingWord());
  const resetAutoCompleteValue = () => call(setEmojiAutoComplete, '');
  const selectedEmojiByIndex = emojisAutocomplete[emojiSelected];

  const onEmojiWriting = compose(
    (emojiString) => {
      setEmojiAutoComplete(emojiString);
      setEmojiTyping(emojiString);
    },
    getEmojiValue(isEmojiWriting),
    checkEmojiStringIsFully(onSetEmojiByAutocomplete, selectedEmojiByIndex),
    writingWord,
    setEmojiTyping,
    writingWord,
  );

  const shortKeysHandler = () => listenerShortKeys(e,
    onSetEmojiByAutocomplete,
    resetAutoCompleteValue,
    selectedEmojiByIndex, setEmojiSelected, emojiSelected, emojisAutocomplete);

  const setPreventDefaultWhenShortKey = when(
    equals(false),
    () => call(callWithPreventEvent, identity, e),
  );

  return when(equals(true), compose(
    setPreventDefaultWhenShortKey,
    shortKeysHandler,
    onEmojiWriting,
  ))(isEmojiWriting);
};

const getEmojiDataByString = (string) => {
  const emojiCustomIndex = new NimbleEmojiIndex(emojiData);
  return emojiCustomIndex.search(replace(/:/g, '', string)) || [];
};

const onPasteTextCheckEmojiHandler = ({
  onUpdateContent,
  setEmojiAutoComplete,
}) => (field, value) => {
  const replaceEmojiStringToEmoji = compose(
    str => concat('', str),
    e => e.outerHTML,
    code => compose(setDataCodeEmoji(code), getEmojiElementById)(code),
    prop('id'),
    head,
    getEmojiDataByString,
  );
  // console.log(replaceEmojiStringToEmoji(value));

  // const textElement = createTexNode(pastedData);
  // insertNodeInCurrentFocus(textElement);
  // onSetMessageContent(field.innerHTML);
  // setFocusAfterNode(field, textElement);
  const newFieldValue = replace(
    regexRules.regFullEmojiString,
    replaceEmojiStringToEmoji,
    value,
  );
  const element = document.createElement('template');
  const emptyElement = createTexNode();
  element.innerHTML = newFieldValue;
  element.content.appendChild(emptyElement);
  element.className = 'js-element-paste-text';
  document.execCommand('insertHtml', null, '');
  insertNodeInCurrentFocus(element.content);

  onUpdateContent(field.innerHTML);
  requestAnimationFrame(() => {
    setFocusAfterNode(field, emptyElement);
  });
  setEmojiAutoComplete('');
};

const onFocusFieldHandler = ({ setIsFocusField }) => () => setIsFocusField(true);

const onBlurFieldHandler = ({ setIsFocusField, onResetAutoComplete }) => () => {
  setIsFocusField(false);
  onResetAutoComplete();
};

const onCopyEmojisHandler = () => (e) => {
  const range = window.getSelection();
  const r = range.getRangeAt(0);
  const content = r.cloneContents();
  const span = document.createElement('span');
  span.appendChild(content);
  const copiedContent = span.innerHTML;
  const toBufferContent = replace(regexRules.regAllHtml, '', convertEmojiForCopy(copiedContent));
  requestAnimationFrame(() => {
    navigator.clipboard.writeText(toBufferContent).then();
    range.removeAllRanges();
  });
  e.preventDefault();
  return false;
};

const onSetEmojiHandler = ({ getField, onUpdateContent }) => ({ id }) => {
  const field = getField().getEl();
  const emptyTextNode = createTexNode('');
  const emojiElement = getEmojiElementById(id);
  const range = document.createRange();
  const selection = window.getSelection();
  const isFocusInField = call(isFocusInElement, field);
  const setCaretToEndField = when(equals(F, isFocusInField),
    () => setCaretToTheEndPosition(
      range,
      selection,
      field,
    ));
  const node = setDataCodeEmoji(id, emojiElement);

  const result = compose(() => {
    insertNodeInCurrentFocus(node);
    insertNodeAfterElement(node, emptyTextNode);
    setFocusAfterNode(field, emptyTextNode);
  }, setCaretToEndField);
  result();
  onUpdateContent(field.innerHTML);
};

const withEmoji = ({ parentElement, onUpdateContent }) => compose(
  pure,
  withProps(props => ({
    getField: () => call(parentElement, props),
    onUpdateContent: onUpdateContent(props),
  })),
  withStateHandlers(() => ({ isEmojiOpen: false }), {
    setIsEmojiOpen: setIsEmojiOpenStateHandlers,
    setIsEmojiAutocompleteOpen: setIsEmojiAutocompleteOpenStateHandler,
  }),
  withState('emojiAutoComplete', 'setEmojiAutoComplete', ''),
  withState('isAutoCompleteOpen', 'setIsAutoCompleteOpen', false),
  withState('emojiTyping', 'setEmojiTyping', ''),
  withState('isFocusField', 'setIsFocusField', false),
  withState('emojiSelected', 'setEmojiSelected', 0),
  withState('isListeners', 'setListeners', false),
  withProps(({ emojiAutoComplete }) => ({
    emojisAutocomplete: getEmojiDataByString(emojiAutoComplete) || [],
  })),
  withHandlers({
    onSetEmoji: onSetEmojiHandler,
    onSetEmojiByAutocomplete: onSetEmojiByAutocompleteHandler,
  }),
  withContext({
    emojisAutocomplete: PropTypes.instanceOf(Array),
    emojiTyping: PropTypes.string,
    setEmojiSelected: PropTypes.func,
    emojiSelected: PropTypes.func,
    onSetEmojiByAutocomplete: PropTypes.func,
  },
  props => ({
    emojisAutocomplete: props.emojisAutocomplete,
    emojiTyping: props.emojiTyping,
    emojiSelected: props.emojiSelected,
    setEmojiSelected: props.setEmojiSelected,
    onSetEmojiByAutocomplete: props.onSetEmojiByAutocomplete,
  })),
  withHandlers({
    onOpenEmoji: onOpenEmojiHandler,
    onResetAutoComplete: onResetAutoCompleteHandler,
    onCloseEmoji: onCloseEmojiHandler,
    onSetEmojiAutocomplete: onSetEmojiAutocompleteHandler,
    onSetEmojiByAutocomplete: onSetEmojiByAutocompleteHandler,
    onPasteTextCheckEmoji: onPasteTextCheckEmojiHandler,
    onCopyEmojis: onCopyEmojisHandler,
  }),
  withHandlers({
    onFocusField: onFocusFieldHandler,
    onBlurField: onBlurFieldHandler,
  }),
  lifecycle({
    shouldComponentUpdate(prevProps) {
      return this.props.isListeners === prevProps.isListeners;
    },
    componentDidUpdate(prevProps) {
      const {
        getField, onSetEmojiAutocomplete, onBlurField, onCopyEmojis,
        setListeners, isListeners, isEmojiOpen,
        onResetAutoComplete, emojiAutoComplete, setIsAutoCompleteOpen,
      } = this.props;

      when(notEqual(prevProps.emojiAutoComplete),
        () => setIsAutoCompleteOpen(true))(emojiAutoComplete);

      when(notEqual(prevProps.isEmojiOpen), onResetAutoComplete)(isEmojiOpen);

      const fieldElement = () => getField().getEl();

      when(notEqual(true), compose(
        (element) => {
          document.addEventListener('click', this.props.onClickOutside, true);
          document.addEventListener('copy', onCopyEmojis);
          setListeners(true);
          element.addEventListener('keyup', onSetEmojiAutocomplete);
          element.addEventListener('blur', onBlurField);
        },
        fieldElement,
      ))(isListeners);
    },
  }),
);
export default withEmoji;
