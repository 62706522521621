import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const getColor = (percents) => {
  if (percents < 50) {
    return '#ff8a65';
  } if (percents > 50 && percents < 99) {
    return '#f66f5b';
  }
  return '#91e291';
};
const StatusBar = ({ percents = 0 }) => (
  <div className="progress-bar" title={`${percents}%`}>
    <div className="progress-bar__line" style={{ width: `${percents}%`, backgroundColor: getColor(percents) }} />
  </div>
);
StatusBar.propTypes = {
  percents: PropTypes.number.isRequired,
};
export default StatusBar;
