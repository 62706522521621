import { createSelector } from 'reselect/lib/index';
import {
  pathOr, identity, memoizeWith, propOr, has, map,
} from 'ramda';

const getUsersList = createSelector(
  pathOr([], ['users']), identity,
);

const getUsersForSelect = createSelector(
  pathOr([], ['users', 'usersAutocomplete']),
  identity,
);

const getUsersResult = createSelector(
  pathOr([], ['users', 'result']),
  identity,
);

const getUsersCount = createSelector(
  pathOr([], ['users', 'count']),
  identity,
);

const getUsersListPending = createSelector(
  pathOr(false, ['pending', 'getUsersList']),
  identity,
);

const getUsersEntities = createSelector(
  pathOr({}, ['users', 'entities']),
  identity,
);

const getCoworkersList = createSelector(
  pathOr([], ['users', 'coworkers']),
  identity,
);

const getUser = createSelector(
  pathOr({}, ['users', 'entities']),
  users => memoizeWith(identity, userId => propOr({}, userId, users)),
);

const getUsers = createSelector(
  getUsersList,
  users => memoizeWith(identity, usersList => usersList.filter(id => has(id, users))),
);

const getUsersByResult = createSelector(
  getUsersEntities,
  users => memoizeWith(identity, usersList => map(id => users[id], usersList)),
);


const getInviteUsersError = createSelector(pathOr(null, ['errors', 'setUsersInviteRequest']), identity);

const getInviteUsersPending = createSelector(pathOr(false, ['pending', 'setUsersInviteRequest']), identity);

export {
  getUsersList,
  getUsersResult,
  getUsersForSelect,
  getUser,
  getInviteUsersError,
  getInviteUsersPending,
  getUsers,
  getUsersEntities,
  getUsersByResult,
  getUsersListPending,
  getCoworkersList,
  getUsersCount,
};
