import { Draggable, Droppable } from 'react-beautiful-dnd';
import React from 'react';

import PropTypes from 'prop-types';
import { ifElse, equals, identity } from 'ramda';
import { TaskCard } from '..';

const getStyleForDroppable = (isDragging, updateDroppable, droppableId, snapshot) => {
  if (isDragging && updateDroppable === droppableId) {
    return 'column-board__body--update';
  }
  if (isDragging && !snapshot.draggingFromThisWith) {
    return 'column-board__body--active';
  }
  return '';
};

const DroppableArea = ({
  statusId, isDragging, updateDroppable, tasks, isDropDisabled, windowWidth,
}) => (
  <Droppable isDropDisabled={isDropDisabled} droppableId={statusId}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        className="column-board__drag"
        style={{
          backgroundColor: provided.isDragging ? 'green' : 'lightblue',
        }}
        {...provided.droppableProps}
      >
        <div className={`column-board__body ${getStyleForDroppable(isDragging, updateDroppable, statusId, snapshot)}`}>
          {tasks.map((task, index) => (
            <Draggable
              isDropDisabled={isDropDisabled}
              key={`project-task-key ${task}`}
              index={index}
              draggableId={task}
            >
              {(providedDrag, draggableSnapshot) => (
                <div
                  className={`project-task__drag-wrapper ${isDropDisabled ? 'agenda-task__drag-disabled' : ''}`}
                  ref={providedDrag.innerRef}
                  {...providedDrag.draggableProps}
                  {...providedDrag.dragHandleProps}
                  {
                    ...ifElse(equals(true), () => ({
                      onMouseDown: identity,
                      onTouchStart: identity,
                    }), () => {})(isDropDisabled || windowWidth < 768)
                  }
                >
                  <TaskCard
                    id={task}
                    statusId={statusId}
                    isEditDisabled={isDropDisabled}
                    isDragging={draggableSnapshot.isDragging}
                    key={`task ${task}`}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      </div>
    )}
  </Droppable>
);

DroppableArea.propTypes = {
  statusId: PropTypes.number.isRequired,
  isDragging: PropTypes.bool.isRequired,
  updateDroppable: PropTypes.number,
  tasks: PropTypes.arrayOf(PropTypes.number),
};

DroppableArea.defaultProps = {
  updateDroppable: null,
  tasks: null,
};

export default DroppableArea;
