import { createSelector } from 'reselect';
import { identity, pathOr } from 'ramda';

const getRequestsList = createSelector(
  state => selectorName => pathOr([], ['offlineRequest', selectorName, 'requests'], state), identity,
);

const getRequestsSelectorsList = createSelector(
  state => pathOr([], ['offlineRequest'], state), identity,
);

const getOfflineRequestsStatus = createSelector(
  state => pathOr(null, ['offlineRequest', 'status'], state), identity,
);


export {
  // eslint-disable-next-line import/prefer-default-export
  getRequestsList,
  getRequestsSelectorsList,
  getOfflineRequestsStatus,
};
