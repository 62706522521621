import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Label, PermissionElement, ScrollWrapper } from '../../components';
import { UserControls } from './components';
import { logo } from '../../assets/img/index';

import './style.scss';
import { ACTIONS, MODULES } from '../../constants/pemissions';

const LeftSideBar = ({
  onToggleUserControls, isUserControls, children, setRef, totalUnreadCount,
}) => (
  <aside className="main-sidebar fixed offcanvas shadow" data-toggle="offcanvas" ref={e => setRef('leftSideBarElement', e)}>
    <section className="sidebar">
      <div className="w-100 mt-4 mb-4 sidebar__element">
        <img src={logo} alt="" className="sidebar__logo" />
      </div>
      <div className="relative sidebar__element">
        <div
          onClick={onToggleUserControls}
          className="btn-fab btn-fab-sm absolute fab-right-bottom fab-top btn-primary shadow1 pointer "
        >
          <i className="icon icon-cogs" />
        </div>
        <UserControls isUserControls={isUserControls} />
      </div>
      <ScrollWrapper heightIs={`calc(100% - ${isUserControls ? '240px' : '169px'})`}>
        <ul className="sidebar-menu">
          <li className="header"><strong>MAIN NAVIGATION</strong></li>

          <PermissionElement module={MODULES.USERS} action={ACTIONS.READ}>
            <li className="treeview">
              <NavLink to="/users" activeClassName="active">
                <i className="icon icon-account_box light-green-text s-18" />
                Users
              </NavLink>
            </li>
          </PermissionElement>
          <PermissionElement module={MODULES.CUSTOMERS} action={ACTIONS.READ}>
            <li className="treeview">
              <NavLink to="/customers">
                <i className="icon icon-people light-green-text s-18" />
                Customers
              </NavLink>
            </li>
          </PermissionElement>
          <PermissionElement module={MODULES.PROJECTS} action={ACTIONS.READ}>
            <li className="treeview">
              <NavLink to="/projects">
                <i className="icon icon-package light-green-text s-18" />
                Projects
              </NavLink>
            </li>
          </PermissionElement>
          <PermissionElement module={MODULES.STATS} action={ACTIONS.READ}>
            <li className="treeview">
              <NavLink to="/statistics">
                <i className="icon icon-sailing-boat-water light-green-text s-18" />
                Statistics
              </NavLink>
            </li>
          </PermissionElement>
          <PermissionElement module={MODULES.CHANNELS} action={ACTIONS.READ}>
            <li className="treeview no-b">

              <NavLink to="/messenger/1">
                <i className="icon icon-chat light-green-text s-18" />
                Messenger
                { totalUnreadCount > 0 && (
                <Label
                  className="label--xs label--fill total-unread-count-label"
                >
                  {totalUnreadCount}
                </Label>
                )}
              </NavLink>
            </li>
          </PermissionElement>
          <PermissionElement module={MODULES.EVENTS} action={ACTIONS.READ}>
            <li className="treeview no-b">
              <NavLink to="/events">
                <i className="icon icon-calendar light-green-text s-18" />
                Events
              </NavLink>
            </li>
          </PermissionElement>
          <PermissionElement module={MODULES.MATERIALS} action={ACTIONS.READ}>
            <li className="treeview no-b">
              <NavLink to="/materials">
                <i className="icon icon-box2 light-green-text s-18" />
                Materials
              </NavLink>
            </li>
          </PermissionElement>
        </ul>
        {
          children && (
            <>
              <div className="sidebar__children">
                {children}
              </div>
            </>
          )
        }
      </ScrollWrapper>
    </section>
  </aside>
);

LeftSideBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array,
  ]),
  setLeftSideBarRef: PropTypes.func.isRequired,
};
LeftSideBar.defaultProps = {
  children: '',
};
export default LeftSideBar;
