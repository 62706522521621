import {
  compose,
  hoistStatics,
  lifecycle,
  withContext,
  withHandlers,
  withProps,
  withStateHandlers,
  getContext, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  cond, identity, includes, isNil, subtract, T, ifElse, always, map, keys, prop, propOr,
} from 'ramda';
import {
  equalsToFalse, isNotNil, notEqual, notEqualToZero,
} from 'ramda-extension';

import { withRouter } from 'react-router';
import { messengerActions, messengerSelectors } from '../../state/messenger';
import { userSelectors } from '../../state/user';
import { uiActions } from '../../state/ui';

import preloaderWhileLoading from '../../utils/enchancers/preloaderWhileLoading';
import { convertMessageBeforeSubmit } from '../../utils/helpers/messengerHelpers/messages';
import withRefs from '../../utils/enchancers/withRefs';
import { getMessageTimeInMs } from '../../utils/helpers/dateHelpers';
import { encryptString } from '../../utils/helpers/stringHelpers/common';
import {
  getIsShowUnreadMessage,
  getUnreadCount,
} from '../../utils/helpers/messengerHelpers/channelHelpers';

import { PRELOADER_DIMENSION } from '../../constants/ui';
import { withUnread } from '../../utils/enchancers';

import Chat from './chat';
import { setGlobalVariableForMention } from '../../utils/helpers/mentionHelpers/lookup';
import { DIRECT_CHANNELS } from '../../constants/messenger';

const mapStateToProps = (state, { channelId }) => ({
  messages: messengerSelectors.getMessageList(state)(channelId),
  countMessages: messengerSelectors.getCountLoadedMessages(state)(channelId),
  hasMore: messengerSelectors.getHasMoreMessages(state)(channelId),
  isChannelLoaded: messengerSelectors.getLoadedChannels(state)(channelId),
  activeChannel: messengerSelectors.getActiveChannel(state),
  channelMembers: messengerSelectors.getActiveChannelMembers(state),
  user: userSelectors.getUserData(state),
  members: messengerSelectors.getMembers(state),
  channel: messengerSelectors.getChannelById(state)(channelId),
  isPending: messengerSelectors.getMessagesRequest(state),
  isChannelPending: messengerSelectors.getGroupChannelPending(state),
});

const mapDispatchToProps = ({
  setMessage: messengerActions.setNewMessage,
  readMessageRequest: messengerActions.readMessagesRequest,
  setUnreadCount: messengerActions.setUnreadCount,
  submitMessage: messengerActions.submitMessageRequest,
  textareaChanged: messengerActions.textareaChanged,
  setOpenAttachFilesModal: () => uiActions.openModal('attachFilesChatModal'),
});

const setContainerSize = () => size => ({ containerSize: size });
const setContainerWidthStateHandler = () => size => ({ containerWidth: size });
const setIsScrollToBottomStateHandler = () => value => ({ isScrollToBottom: value });
const updateHeightMessengersArea = () => val => ({ heightMessengersArea: val });
const setIsSizeChangedStateHandler = () => val => ({ isSizeChanged: val });

const updateHeightChatContainer = ({
  setContainerSizeStateHandler,
  getRef,
}) => ({ height }) => {
  const container = getRef('container');
  const heightMessengerField = getRef('fieldMessenger').offsetHeight;
  setContainerSizeStateHandler(container.offsetHeight - (height || heightMessengerField));
};


const onSubmitMessageHandler = ({
  submitMessage,
  setIsScrollToBottom,
  channelId,
  setMessage,
  attachedFiles,
  user,
  setAttachedFiles,
}) => (content) => {
  const messageMs = getMessageTimeInMs();
  const clientMsgId = encryptString(messageMs);
  submitMessage({
    content: convertMessageBeforeSubmit(content),
    client_msg_id: clientMsgId,
    files: map(key => attachedFiles[key].id, keys(attachedFiles)),
    id: channelId,
  }, {
    callbacks: {
      success: (data) => {
        setMessage({
          ...data,
          isFromCallBack: !window.navigator.onLine,
          channel: {
            id: channelId,
          },
        });
        requestAnimationFrame(() => {
          setIsScrollToBottom(true);
          window.dispatchEvent(new Event('resize'));
        });
        setAttachedFiles([]);
      },
    },
  });
  setMessage({
    channel: {
      id: channelId,
    },
    message: {
      content: convertMessageBeforeSubmit(content),
      channel_id: channelId,
      isPendingOfflineRender: true,
      created_by: user.id,
      ts: messageMs,
      is_pending: true,
      isPending: !window.navigator.onLine,
      client_msg_id: clientMsgId,
    },
  });
  setIsScrollToBottom(true);
};

const onResizeHandler = ({ setContainerWidth }) => (width) => {
  setContainerWidth(width);
};

const onTextAreaKeyDownHandler = ({
  setUnreadIndex, textareaChanged,
  unreadIndex, channelId,
}) => () => {
  textareaChanged({ channelId });
  if (isNotNil(unreadIndex) && notEqualToZero(unreadIndex)) {
    setUnreadIndex(null);
  }
};

const enhance = compose(
  getContext({
    isLoadingChannel: PropTypes.bool,
  }),
  withRouter,
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.SMALL,
    alignContainerCenter: true,
    delay: 800,
    isLoading: ({ isLoadingChannel }) => isLoadingChannel || (window.navigator.online),
  }),
  withState('attachedFiles', 'setAttachedFiles', []),
  connect(mapStateToProps, mapDispatchToProps),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.SMALL,
    alignContainerCenter: true,
    delay: 800,
    isLoading: ({ isPending }) => isPending,
    onAction: ({ isChannelPending }) => isChannelPending,
  }),
  withRefs(),
  withState('scrollToIndex', 'setScrollToIndex', ({ countMessages }) => countMessages),
  withContext(
    {
      messageDays: PropTypes.object,
      channelId: PropTypes.number,
    },
    props => ({
      messageDays: props.messageDays,
      channelId: props.channelId,
    }),
  ),
  withProps(({
    user: { id }, channelMembers, channel, members,
  }) => ({
    isUserMember: includes(id, map(prop('id'), channelMembers)),
    membersForMention: ifElse(includes(prop('type', channel)),
      always([]),
      () => setGlobalVariableForMention(members))(DIRECT_CHANNELS),
    unreadCount: compose(
      cond([
        [isNil, () => 0],
        [T, identity],
      ]),
      getUnreadCount,
    )(channel),
    isMember: parseInt(propOr(0, 'id', channel), 10) === 1 || propOr(0, 'type', channel) === 3 || includes(id, channelMembers),
    isShowUnreadMessage: getIsShowUnreadMessage(channel),
  })),
  withStateHandlers(() => ({
    heightMessengersArea: 0,
    itemSize: {},
    containerSize: 0,
    containerWidth: 0,
    isScrollToBottom: false,
    isResetDimensions: false,
    isHideUnreadLabel: false,
  }), {
    updateHeightMessengersArea,
    setContainerSizeStateHandler: setContainerSize,
    setContainerWidth: setContainerWidthStateHandler,
    setIsScrollToBottom: setIsScrollToBottomStateHandler,
    setIsSizeChanged: setIsSizeChangedStateHandler,
  }),
  withUnread({
    unreadIndex: ({ countMessages, unreadCount }) => cond([
      [notEqualToZero, subtract(countMessages)],
      [T, () => null],
    ])(unreadCount),
  }),
  withHandlers({
    updateHeightChatContainerHandler: updateHeightChatContainer,
    onSubmitMessage: onSubmitMessageHandler,
    onResize: onResizeHandler,
    onTextAreaKeyDown: onTextAreaKeyDownHandler,
  }),
  withContext(
    {
      members: PropTypes.object,
      getHeightMessagesArea: PropTypes.func,
    },
    ({
      members, getRef,
    }) => ({
      members,
      getHeightMessagesArea: () => getRef('scroll'),
    }),
  ),
  lifecycle({
    componentDidMount() {
      const {
        getRef, readMessageRequest, channel,
        setContainerSizeStateHandler, setIsScrollToBottom, match: { params: { id } },
      } = this.props;
      const messengerOffsetHeight = getRef('container').offsetHeight;
      const heightMessengerField = getRef('fieldContainer').offsetHeight;
      readMessageRequest({ channelId: id });
      setContainerSizeStateHandler(messengerOffsetHeight - heightMessengerField);
      setIsScrollToBottom(true);
      window.addEventListener('scroll', () => prop('unread_count', channel) > 0 && readMessageRequest({ channelId: id }));
      window.addEventListener('click', () => prop('unread_count', channel) > 0 && readMessageRequest({ channelId: id }));
    },
    componentDidUpdate(prevProps) {
      const { isShowUnreadMessage, setUnreadIndex } = this.props;
      const { isShowUnreadMessage: prevIsShowUnreadMessage } = prevProps;
      if (equalsToFalse(isShowUnreadMessage)
        && notEqual(isShowUnreadMessage, prevIsShowUnreadMessage)
      ) {
        setUnreadIndex(0);
      }
    },
  }),
  hoistStatics,
);

export default enhance(Chat);
