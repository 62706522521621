/* eslint-disable import/prefer-default-export */
import { getFullName } from './userHelpers';
import { stringToColor, getShortName } from '../../components/avatar/utils';

const getAvatarInBase64 = (object) => {
  const fullName = getFullName(object);
  const canvas = document.createElement('canvas');
  canvas.setAttribute('width', '48');
  canvas.setAttribute('height', '48');
  canvas.className = 'canvas-element';
  document.querySelector('body').appendChild(canvas);
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = stringToColor(fullName);
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.font = '18px sans-serif';
  ctx.fillStyle = '#fff';
  ctx.textAlign = 'center';
  ctx.fillText(getShortName(fullName), canvas.width / 2, canvas.height / 1.6);
  return canvas.toDataURL('image/jpeg');
};

export {
  getAvatarInBase64,
};
