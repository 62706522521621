import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import ColumnBoard from './columnBoard';
import { projectSelectors } from '../../../../state/project';
import withWindowWidth from '../../../../utils/enchancers/withWindowWidth';

const mapStateToProps = (state, { statusId, match: { params: { projectId } } }) => ({
  agenda: projectSelectors.getAgenda(state)(projectId, statusId),
});

const enhance = compose(
  withRouter,
  withWindowWidth(),
  connect(mapStateToProps),
);
export default enhance(ColumnBoard);
