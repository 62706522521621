import {
  compose, getContext, lifecycle, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import {
  compose as RCompose, map, path, prop, propOr, values,
} from 'ramda';
import { withFormik } from 'formik';

import { usersActions, usersSelectors } from '../../state/users';
import { uiActions, uiSelectors } from '../../state/ui';
import { tasksSelectors } from '../../state/tasks';
import { newTaskActions, newTaskSelectors } from '../../state/newTask';
import withAutocomplete from './withAutocomplete';
import { dateHelpers, uiHelpers } from '../helpers';
import { PRIORITY } from '../../constants/tasks';
import {
  setAssigneeLabel,
  setCategoryLabel,
  setProjectLabel,
  setSprintLabel,
} from '../helpers/taskHelpers/crudHelper';
import { getSelectedProjectIdCrud } from '../../state/newTask/selectors';

const { renameKeysTitleIntoValue, renameKeysForUsers, getArrayFromEntities } = uiHelpers;

const mapStateToProps = modalName => state => ({
  isPending: tasksSelectors.getAddTaskPendingRequest(state),
  categoriesEntities: newTaskSelectors.getCategoriesEntities(state),
  sprintsEntities: newTaskSelectors.getSprintsEntities(state),
  projects: newTaskSelectors.getProjectsEntities(state),
  getUsersByResult: usersSelectors.getUsersByResult(state),
  users: usersSelectors.getUsersEntities(state),
  selectedProjectId: getSelectedProjectIdCrud(state),
  getUserById: usersSelectors.getUser(state),
  isOpen: uiSelectors.getModal(state)(modalName),
});

const mapDispatchToProps = ({
  setUsersAutocomplete: usersActions.setUsersListAutocomplete,
  getCategoriesTaskCrud: newTaskActions.getCategoriesTaskCrudRequest,
  getSprintsTaskCrud: newTaskActions.getSprintsTaskCrudRequest,
  onCloseModal: uiActions.closeModal,
});

const convertEntitiesToOptions = RCompose(
  map(renameKeysTitleIntoValue),
  values,
);

const onChangeProjectHandler = ({ setSelectedProjectIdCrud, setFieldValue }) => (value) => {
  setSelectedProjectIdCrud(value.value);
  setFieldValue('project_id', value);
};

const withCRUDTask = ({
  rules, data, onSubmit, modalName,
}) => compose(
  connect(mapStateToProps(modalName), mapDispatchToProps),
  getContext({
    projectId: PropTypes.number,
  }),
  withProps(({ getUsersByResult, getUserById, ...props }) => {
    const task = data(props);
    const deadline = propOr(false, 'deadline', task) ? new Date(task.deadline) : null;
    return ({
      initialValues: ({
        title: propOr('', 'title', task),
        description: propOr('', 'description', task),
        assignee: compose(setAssigneeLabel, getUserById, propOr(null, 'assigneeUser'))(task),
        watchers: compose(renameKeysForUsers, getUsersByResult, propOr([], 'watchers'))(task),
        materials: compose(map(renameKeysTitleIntoValue), propOr([], 'materials'))(task),
        project_id: setProjectLabel(props.projects, task, props.projectId),
        priority: propOr(PRIORITY.LOW.value, 'priority', task),
        category_id: setCategoryLabel(props.categoriesEntities, task),
        deadline,
        estimated_time: dateHelpers.getTimeStringBySeconds(propOr(null, 'estimated_time', task)),
        sprint_id: setSprintLabel(props.sprintsEntities, task),
      }),
      categoriesList: convertEntitiesToOptions(prop('categoriesEntities', props)),
      sprintsList: convertEntitiesToOptions(prop('sprintsEntities', props)),
      priorities: getArrayFromEntities(PRIORITY),
    });
  }),
  withNamespaces(['common']),
  withAutocomplete({
    name: 'getUsersAutocomplete',
    action: usersActions.getUsersListAutocompleteRequest,
    dataPath: prop('users'),
  }),
  withAutocomplete({
    name: 'getProject',
    action: newTaskActions.getProjectsTaskCrudRequest,
    dataPath: RCompose(
      map(renameKeysTitleIntoValue),
      values,
      path(['data', 'entities', 'projects']),
    ),
    searchField: 'title',
  }),
  withAutocomplete({
    name: 'getMaterials',
    action: newTaskActions.getMaterialsTaskCrudRequest,
    dataPath: RCompose(
      map(renameKeysTitleIntoValue),
      values,
      path(['data', 'entities', 'materials']),
    ),
    searchField: 'title',
  }),
  withFormik({
    mapPropsToValues: ({ initialValues }) => initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: rules,
    handleSubmit: onSubmit,
  }),
  withHandlers({
    onChangeProject: onChangeProjectHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        setFieldValue,
        getCategoriesTaskCrud, getSprintsTaskCrud, selectedProjectId,
      } = this.props;

      if (selectedProjectId !== prevProps.selectedProjectId) {
        setFieldValue('category_id', null);
        setFieldValue('sprint_id', null);
        getCategoriesTaskCrud({ projectId: selectedProjectId });
        getSprintsTaskCrud({ projectId: selectedProjectId });
      }
    },
  }),
);

export default withCRUDTask;
