import {
  branch, compose, lifecycle, pure, renderComponent, withHandlers, withState,
} from 'recompose';

import {
  always,
  and,
  cond,
  curry,
  equals,
  flip,
  identity,
  ifElse,
  isEmpty,
  isNil,
  not,
  or,
  pick,
  prop, replace,
  T,
  tap, when,
} from 'ramda';
import { Either, Maybe } from 'ramda-fantasy';
import { isNotEmpty } from 'ramda-extension';

import TypeMessage from './sendMessage';
import JoinToChannel from '../joinToChannel';

import withMentions from '../../../../utils/enchancers/withMentions';
import {
  appendMention, appendOrFilterMention, filterMentions, replaceOrAppendMention,
} from '../../../../utils/helpers/mentionHelpers/events';
import withOnline from '../../../../utils/enchancers/withOnline';
import {
  isMentionEdit,
  isMentionEditedRight,
  isNextMentionExist,
} from '../../../../utils/helpers/mentionHelpers/lookup';
import withMessageContent from '../../../../utils/enchancers/withMessageContent';
import withEmoji from '../../../../utils/enchancers/withEmoji';
import withRefs from '../../../../utils/enchancers/withRefs';
import {
  setFocusAfterNodeForMention,
} from '../../../../utils/helpers/uiComponentHelpers/caretHelpers';
import { setCaretToTheEndPosition } from '../../../../utils/helpers/uiHelpers';
import { isNotNil } from '../../../../utils/helpers/commonHelpers';
import { KEYS } from '../../../../constants/ui';
import { callWithPreventEvent } from '../../../../utils/helpers/uiComponentHelpers/DOMhelpers';

const FIELD_ERRORS = {
  FIELD_IS_EMPTY: {
    name: 'FIELD_IS_EMPTY',
    title: 'This is empty field',
  },
};

const setBreakToField = () => {
  document.execCommand('insertHTML', false, '<br><br>');
};

const onClickOutsideTypeMessageWrapperHandler = () => () => {};

const onTextareaKeyDownHandler = ({
  onKeyDownSaveContent,
  onMentionKeyDown,
  isDropdownMentionsOpen,
  isEmojiOpen,
  onKeyDown,
  emojisAutocomplete,
  onKeyDownPureTextArea,
  messageContent,
}) => (event) => {
  onMentionKeyDown(event);

  const isEmojiAutoComplete = compose(not, isEmpty)(emojisAutocomplete);
  const isPreventSpecialEnter = or(isEmojiOpen, isEmojiAutoComplete);
  if (!isDropdownMentionsOpen && !isPreventSpecialEnter) {
    onKeyDownSaveContent(event);
  }
  if (onKeyDown) {
    onKeyDown(event);
  }
  if (onKeyDownPureTextArea && !isEmojiOpen && !isDropdownMentionsOpen && !messageContent) {
    onKeyDownPureTextArea(event);
  }
};

const onSpecialKeysHandler = ({
  lastPressedKey,
  setLastPressedKey,
  emojisAutocomplete,
  onClickSaveContent,
  isEmojiOpen,
  isDropdownMentionsOpen,
}) => (e) => {
  const { keyCode, type } = e;
  const isEmojiAutoComplete = compose(not, isEmpty)(emojisAutocomplete);
  const isPreventSpecialEnter = or(isEmojiOpen, isEmojiAutoComplete);
  const isNotShiftLastPressingKey = compose(not, equals(KEYS.SHIFT))(lastPressedKey);
  const isNotMention = !isDropdownMentionsOpen;
  const isEnterForSubmitForm = () => isNotMention && and(isNotShiftLastPressingKey,
    not(isPreventSpecialEnter));
  const onPreventByEmojis = () => when(
    equals(true),
    () => callWithPreventEvent(identity, e),
  )(isPreventSpecialEnter);

  const listenerKeyDown = code => cond([
    [equals(KEYS.SHIFT), setLastPressedKey],
    [equals(KEYS.LEFT_ARROW), onPreventByEmojis],
    [equals(KEYS.RIGHT_ARROW), onPreventByEmojis],
    [equals(KEYS.UP_ARROW), onPreventByEmojis],
    [equals(KEYS.DOWN_ARROW), onPreventByEmojis],
    [equals(KEYS.ENTER),
      cond([
        [isEnterForSubmitForm, () => callWithPreventEvent(onClickSaveContent, e)],
        [T, cond([
          [() => equals(true, isEmojiAutoComplete), () => callWithPreventEvent(identity, e)],
          [T, () => callWithPreventEvent(setBreakToField, e)],
        ])],
      ])],
  ])(code);

  const listenerKeyUp = code => cond([
    [equals(KEYS.SHIFT), () => setLastPressedKey(null)],
    [equals(KEYS.ENTER),
      ifElse(
        isEnterForSubmitForm,
        () => callWithPreventEvent(identity, e),
        ifElse(
          () => equals(true, isEmojiAutoComplete),
          () => callWithPreventEvent(identity, e),
          T,
        ),
      ),
    ],
  ])(code);

  const listenerShortKeys = cond([
    [equals('keydown'), () => when(equals(true), () => listenerKeyDown(keyCode))(isNotMention)],
    [equals('keyup'), () => when(equals(true), () => listenerKeyUp(keyCode))(isNotMention)],
  ]);

  return listenerShortKeys(type);
};

const onResetAllHtmlHandler = ({
  getField, onPasteTextCheckEmoji,
}) => (e) => {
  const field = getField().getEl();
  const selection = window.getSelection();
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = clipboardData.getData('Text');
  e.preventDefault();
  if (selection.focusNode) {
    requestAnimationFrame(() => {
      onPasteTextCheckEmoji(field, pastedData);
    });
  }
};


const onChangeContentHandler = ({
  onSetMessageContent,
  getRef,
  setMentions,
  setIsDropdownMentionsOpen,
  onCloseMentionDropdown,
  isDropdownMentionsOpen,
  mentionTag,
  members,
  errorField,
  setErrorField,
}) => ({ target: { value } }) => {
  errorField.name && setErrorField({});
  const clearValue = value.replace(/&nbsp;/g, ' ');
  const typeMessage = getRef('typeMessage').getEl();

  const range = document.createRange();
  const selection = window.getSelection();
  const { focusNode, anchorOffset } = selection;

  const isMentionEdited = isMentionEdit(anchorOffset)(focusNode);

  if (clearValue.length === 0) {
    if (typeMessage.firstChild) {
      setFocusAfterNodeForMention(selection, range, 0, typeMessage.firstChild);
      typeMessage.firstChild.remove();
    }
  }

  const cratedMention = mentionTag(range, selection);
  Either.either(identity, cratedMention.replaceTagToText)(isMentionEdited);
  appendOrFilterMention(
    focusNode.data,
    anchorOffset,
    appendMention(members, compose(cratedMention.appendNode, pick(['username', 'id']))),
    Maybe.maybe(
      isDropdownMentionsOpen ? onCloseMentionDropdown() : identity,
      compose(cond([
        [isNotEmpty, () => setIsDropdownMentionsOpen(true)],
        [T, () => setIsDropdownMentionsOpen(false)],
      ]), tap(setMentions), filterMentions(members)),
    ),
  );

  const { innerHTML } = typeMessage;
  onSetMessageContent(innerHTML);
};

const onSetFocusFieldHandler = ({ getRef }) => () => {
  setCaretToTheEndPosition(getRef('typeMessage').getEl());
};

const onSetRefWrapTextAreaHandler = ({ setRef }) => e => setRef('typeMessageWrapper', e);

const enhance = compose(
  withOnline,
  withState('errorField', 'setErrorField', {}),
  withRefs(),
  pure,
  branch(
    ({ showJoinToChannel }) => showJoinToChannel,
    renderComponent(JoinToChannel),
  ),
  withMessageContent({
    value: ({ value }) => value,
    onSaveMessageContent: ({
      onSubmit, messageContent, onClearMessageContent,
      setErrorField,
    }) => {
      if (messageContent === undefined || messageContent.length === 0) {
        setErrorField(FIELD_ERRORS.FIELD_IS_EMPTY);
        return false;
      }
      onClearMessageContent();
      onSubmit(compose(replace(/&gt;/g, '>'), replace(/&lt;/g, '<'))(messageContent));
    },
  }),
  withEmoji({
    parentElement: ({ getRef }) => getRef('typeMessage'),
    onUpdateContent: ({ onSetMessageContent }) => onSetMessageContent,
  }),
  withMentions({
    mentions: ({ members }) => members,
    parentElement: ({ getRef }) => (!isNil(getRef('typeMessage')) ? getRef('typeMessage').getEl() : null),
    onUpdateContent: ({ onSetMessageContent }) => onSetMessageContent,
    onSelectMention: ({
      mentionTag, setSelectedMention, parentElement,
      onUpdateContent, isMentionEdited, setIsMentionEdited, mentions,
    }) => (mentionIndex) => {
      const selection = window.getSelection();
      const range = document.createRange();
      const tag = mentionTag(range, selection, () => ({
        start: selection.anchorOffset - 1,
        end: selection.anchorOffset,
      }));
      const appendAndSetActiveMention = curry(action => compose(
        compose(action, pick(['username', 'id']), flip(prop)(mentions)),
        tap(setSelectedMention),
      ));

      Either.either(identity, index => replaceOrAppendMention(
        appendAndSetActiveMention(tag.replaceTag),
        appendAndSetActiveMention(compose(() => setIsMentionEdited(true), tag.appendNode)),
      )(isMentionEditedRight(index)(isMentionEdited)))(
        isNextMentionExist(mentions.length)(mentionIndex),
      );

      onUpdateContent(parentElement.innerHTML);
    },
  }),
  withState('lastPressedKey', 'setLastPressedKey', null),
  withHandlers({
    onChangeContent: onChangeContentHandler,
    onSetRefWrapTextArea: onSetRefWrapTextAreaHandler,
  }),
  withHandlers({
    onSpecialKeys: onSpecialKeysHandler,
    onResetAllHtml: onResetAllHtmlHandler,
    onSetFocusField: onSetFocusFieldHandler,
    onTextareaKeyDown: onTextareaKeyDownHandler,
    onClickOutsideTypeMessageWrapper: onClickOutsideTypeMessageWrapperHandler,
  }),
  lifecycle({
    componentDidMount() {
      const {
        getRef, onSpecialKeys, onResetAllHtml, onClickOutsideTypeMessageWrapper,
      } = this.props;
      const isTextArea = cond([
        [compose(isNotNil), Maybe.Just],
        [T, Maybe.Nothing],
      ]);
      document.addEventListener('click', onClickOutsideTypeMessageWrapper);
      requestAnimationFrame(() => {
        Maybe.maybe(always('Empty'), compose(
          setCaretToTheEndPosition,
          element => element.getEl(),
        ))(isTextArea(getRef('typeMessage')));
      });
      setTimeout(() => {
        if (getRef('typeMessage')) {
          getRef('typeMessage').getEl().addEventListener('keydown', onSpecialKeys);
          getRef('typeMessage').getEl().addEventListener('keyup', onSpecialKeys);
          getRef('typeMessage').getEl().addEventListener('paste', onResetAllHtml);
        }
      });
    },
    componentWillUnmount() {
      const {
        getRef, onSpecialKeys, onResetAllHtml, onClickOutsideTypeMessageWrapper,
      } = this.props;
      document.removeEventListener('click', onClickOutsideTypeMessageWrapper);
      if (getRef('typeMessage')) {
        getRef('typeMessage').getEl().removeEventListener('keyup', onSpecialKeys);
        getRef('typeMessage').getEl().removeEventListener('keydown', onSpecialKeys);
        getRef('typeMessage').getEl().removeEventListener('paste', onResetAllHtml);
      }
    },
  }),
);

export default enhance(TypeMessage);
