import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import testAvatar from '../../../../../../assets/testAssets/avatar.jpeg';
import {
  StatusBar, LabelPriorityTask, ButtonsCollection, DropDown, BrillMask, Avatar,
} from '../../../../../../components';
import './style.sass';

const getClassByStatus = (status) => {
  if (status === 0) {
    return 'my-tasks-table__row--new';
  }
  if (status === 4) {
    return 'my-tasks-table__row--closed';
  }
  return '';
};

const TaskItem = ({
  data, url, sortByProject, projectImage,
}) => (
  <div
    className={`my-tasks-table__row ${sortByProject === 'tasks' ? 'sort-by-tasks' : 'sort-by-projects'} ${getClassByStatus(data.status)}`}
  >

    <Link to={`${url}1`} className="my-tasks-table__id">
      <div className="my-tasks-table__col--id">
        {data.id}
      </div>
    </Link>
    <Link to={`${url}1`} className="my-tasks-table__title my-tasks-table__col--subject">
      <div className="my-tasks-table__col--subject">
        <h3 className="my-tasks-table__title-name-task text--cut">
          {data.title}
        </h3>
      </div>
    </Link>
    <div className="my-tasks-table__col--priority">
      <LabelPriorityTask className="my-tasks-table__priority-label" status={data.priority} />
    </div>
    <Link to="/" className="my-tasks-table__col--category"><span className="text--cut">Improvements</span></Link>
    <div className="my-tasks-table__col--author">
      <BrillMask asLink to="/">
        <Avatar src={testAvatar} alt={data.author} />
      </BrillMask>
    </div>
    {
      sortByProject === 'tasks' ? (
        <div className="my-tasks-table__col--project">
          <BrillMask asLink to="/">
            <Avatar src={projectImage} alt={data.author} />
          </BrillMask>
        </div>
      ) : (
        <div className="my-tasks-table__col--assignee">
          <BrillMask asLink to="/">
            <Avatar alt={data.author} />
          </BrillMask>
        </div>
      )
    }
    <div className="my-tasks-table__col--estimation">
      <b className="my-tasks-table__estimation">
        {data.estimation}
      </b>
    </div>
    <div className="my-tasks-table__col--spent">
      <b className="my-tasks-table__spent">
        {data.spent}
      </b>
    </div>
    <div className="my-tasks-table__col--deadline">
      <b className="my-tasks-table__deadline">
        {data.deadline}
      </b>
    </div>
    <div className="my-tasks-table__col--progress">
      <StatusBar percents={data.done} />
    </div>
    <div className="my-tasks-table__col--controls">
      <DropDown
        className="controls-group-task"
        label={(
          <ButtonsCollection.ButtonIcons
            className="my-tasks-table__controls-button-task"
            title="Controls for sprint"
          >
            <span className="my-tasks-table__control-icon icon-settings-vertical">
              <span
                className="path1"
              />
              <span className="path2" />
              <span
                className="path3"
              />
            </span>
          </ButtonsCollection.ButtonIcons>
          )}
        list={
          [
            {
              label: (
                <>
                  <span className="controls-group-task__icon icon-pencil-2" />
                  <span className="controls-group-task__text">Edit</span>
                </>
              ),
            },
            {
              label: (
                <>
                  <span className="controls-group-task__icon icon-pin" />
                  <span className="controls-group-task__text">Pin</span>
                </>
              ),
            },
            {
              label: (
                <>
                  <span className="controls-group-task__icon icon-delete-icon" />
                  <span className="controls-group-task__text">Delete</span>
                </>
              ),
            },
          ]
        }
      />
    </div>
  </div>
);

TaskItem.propTypes = {
  url: PropTypes.string.isRequired,
  sortByProject: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  projectImage: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.number,
    id: PropTypes.string,
    title: PropTypes.string,
    priority: PropTypes.number,
    done: PropTypes.number,
  }).isRequired,
};

TaskItem.defaultProps = {
  projectImage: '',
};

export default TaskItem;
