import React from 'react';
import { Line, Pie } from 'react-chartjs-2';
import './style.sass';
import { map, keys } from 'ramda';
import PropTypes from 'prop-types';

import { CardActivity, TotalTimeList } from './components';

const CardsActivityContainer = ({
  myStatisticsDaysResult, myStatisticsDaysEntities, myStatisticsByProjects, myStatisticTotal,
  myStatisticsByProjectsPie, windowWidth,
}) => (
  <div className="cards-statistics-container">
    <div className="cards-statistics-container__row">
      <div className="cards-statistics-container__col">
        {
          windowWidth > 768 && (
            <CardActivity title="Monthly Expenses">
              <Line
                data={{
                  labels: myStatisticsDaysResult,
                  datasets: [{
                    type: 'bar',
                    label: 'Spent hours',
                    data: map(itemId => myStatisticsDaysEntities[itemId].totalSpend,
                      keys(myStatisticsDaysEntities)),
                    backgroundColor: 'rgba(52,50,255,0.8)',
                    borderColor: [
                      'rgb(255,180,40)',
                    ],
                    borderSkipped: false,
                    borderWidth: 1,
                  }],
                }}
                options={{
                  scales: {
                    xAxes: [{
                      stacked: true,
                      barThickness: 8,
                      min: 0,
                    }],
                    yAxes: [{
                      ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 100,
                      },
                    }],
                  },
                }}
              />
            </CardActivity>
          )
        }
        <CardActivity title="Total time per project">
          <TotalTimeList data={myStatisticsByProjects} />
        </CardActivity>
      </div>
      <div className="cards-statistics-container__col">

        {
            windowWidth > 768 && (
            <CardActivity title="Projects">
              <Pie data={myStatisticsByProjectsPie} />
            </CardActivity>
            )
          }
        <CardActivity title="Your total time">
          <TotalTimeList data={myStatisticTotal} />
        </CardActivity>
      </div>
    </div>
  </div>
);

CardsActivityContainer.propTypes = {
  myStatisticsDaysResult: PropTypes.instanceOf(Array).isRequired,
  myStatisticsDaysEntities: PropTypes.instanceOf(Object).isRequired,
  myStatisticsByProjects: PropTypes.instanceOf(Object).isRequired,
  myStatisticTotal: PropTypes.instanceOf(Object).isRequired,
  myStatisticsByProjectsPie: PropTypes.instanceOf(Object).isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default CardsActivityContainer;
