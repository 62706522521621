import {
  compose,
  defaultProps,
  lifecycle,
  withContext,
  withHandlers,
  withProps,
  withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { map, path, prop } from 'ramda';

import { withRefs, withWindowWidth } from '.';
import { uiActions, uiSelectors } from '../../state/ui';

const getCoordinatesFromTouchEvent = e => prop('clientX', path(['touches', '0'], e));

const LEFT_SIDE_BAR = 'left-side-bar';
const RIGHT_SIDE_BAR = 'right-side-bar';
const VECTOR_LEFT = 'left';
const VECTOR_RIGHT = 'right';
const SIDEBAR_OPEN = true;
const SIDEBAR_CLOSE = false;
const MIN_RANGE_FOR_EFFECT = 20;

const { changeLeftSidebarStatus, changeRightSidebarStatus } = uiActions;
const { getLeftSidebar, getRightSidebar, getIsSwipeSideBars } = uiSelectors;

const mapStateToProps = state => ({
  leftSideBarStatus: getLeftSidebar(state),
  rightSideBarStatus: getRightSidebar(state),
  isSwipeSideBars: getIsSwipeSideBars(state),
});

const mapDispatchToProps = {
  setLeftSideBarStatus: changeLeftSidebarStatus,
  setRightSideBarStatus: changeRightSidebarStatus,
};

const getSideBarOffsetToClose = (sideBar, isTablet, sideBarElement) => {
  if (sideBar === LEFT_SIDE_BAR) {
    return window.innerWidth > 480 ? 1 : sideBarElement.offsetWidth * -1;
  }
  return sideBarElement.offsetWidth;
};

const getSideBar = ({
  coordinatesStart,
  leftSideBarStatus,
  rightSideBarStatus,
  range,
}) => {
  const screenWidth = window.innerWidth;
  const isTablet = screenWidth > 767;
  if (isTablet) {
    if ((coordinatesStart < 248)) {
      return LEFT_SIDE_BAR;
    }
    if (screenWidth - 248 < coordinatesStart) {
      return RIGHT_SIDE_BAR;
    }
  } else {
    if ((leftSideBarStatus && !rightSideBarStatus) || coordinatesStart < range) {
      return LEFT_SIDE_BAR;
    }
    if ((!leftSideBarStatus && rightSideBarStatus) || screenWidth - range < coordinatesStart) {
      return RIGHT_SIDE_BAR;
    }
  }
  return false;
};

const handlerEndAnimationSideBar = (
  waitTransitionEnd,
  cb,
  sideBarElement,
  propertyName,
) => {
  if (waitTransitionEnd) {
    if (propertyName === 'transform') {
      cb();
      sideBarElement.dispatchEvent(new Event('statusChanged'));
      sideBarElement.removeAttribute('style');
    }
  }

  sideBarElement.removeAttribute('style');
};
const setStatusForSideBarByStyles = ({ getRef, isTablet }) => (status,
  cb,
  sideBar,
  waitTransitionEnd = true) => {
  const sideBarElement = sideBar === LEFT_SIDE_BAR ? getRef('leftSideBar') : getRef('rightSideBar');
  if (!sideBarElement) return false;
  const startPositionSideBar = 1;
  const offsetWidthClose = getSideBarOffsetToClose(sideBar, isTablet, sideBarElement);
  const translateTo = status ? startPositionSideBar : offsetWidthClose;
  sideBarElement.style.transform = `translateX(${translateTo}px)`;
  if (waitTransitionEnd) {
    sideBarElement.setAttribute('data-statusWillChange', true);
  }
  sideBarElement.addEventListener('transitionend', function listener({ propertyName }) {
    handlerEndAnimationSideBar(
      waitTransitionEnd,
      cb,
      sideBarElement,
      propertyName,
    );
    sideBarElement.removeEventListener('transitionend', listener);
  });
  return sideBar;
};
const actionChangeSideBar = (
  setLeftSideBarStatus,
  setRightSideBarStatus,
  newStatus,
  sideBar,
) => (sideBar === LEFT_SIDE_BAR
  ? setLeftSideBarStatus(newStatus)
  : setRightSideBarStatus(newStatus));

const toggleSideBarHandler = ({
  leftSideBarStatus,
  rightSideBarStatus,
  setLeftSideBarStatus,
  setRightSideBarStatus,
  deleteIsTouched,
  getRef,
  isTablet,
}) => ({ status, sideBar }) => {
  deleteIsTouched();
  const sideBarStatus = sideBar === LEFT_SIDE_BAR ? leftSideBarStatus : rightSideBarStatus;
  const oppositeSideBar = sideBar === LEFT_SIDE_BAR ? RIGHT_SIDE_BAR : LEFT_SIDE_BAR;
  if (sideBarStatus === status) return sideBar;
  const setStatusForLeftSideBarByStylesC = setStatusForSideBarByStyles({ getRef, isTablet });

  setStatusForLeftSideBarByStylesC(false,
    () => actionChangeSideBar(setLeftSideBarStatus, setRightSideBarStatus, false, oppositeSideBar),
    oppositeSideBar);
  setStatusForLeftSideBarByStylesC(status,
    () => actionChangeSideBar(setLeftSideBarStatus, setRightSideBarStatus, status, sideBar),
    sideBar);
  return sideBar;
};

const getVectorSwipe = ({ coordinatesStart, currentCoordinate }) => (
  coordinatesStart < currentCoordinate ? VECTOR_LEFT : VECTOR_RIGHT
);

const toggleSideBarsStatus = ({ handler, vector }) => (
  vector === VECTOR_LEFT ? handler(SIDEBAR_OPEN) : handler(SIDEBAR_CLOSE)
);

const getCurrentSideBarHandler = ({ sideBar, params, action }) => (vector) => {
  const handler = status => action(params)({ status, sideBar });
  const revertVector = VECTOR_LEFT === vector ? VECTOR_RIGHT : VECTOR_LEFT;
  const newVector = sideBar === RIGHT_SIDE_BAR ? revertVector : vector;

  return {
    vector: newVector,
    handler,
  };
};

const checkRangeSwipe = ({
  coordinatesStart,
  range,
}) => currentCoordinate => (Math.abs(currentCoordinate - coordinatesStart) > range);

const setVectorForSideBarC = ({ toggleVector, getVectorHandler, vectorSelector }) => compose(
  toggleVector,
  getVectorHandler,
  vectorSelector,
);

const setStatusForSideBars = ({
  coordinatesStart,
  currentCoordinate,
  sideBar,
  leftSideBarStatus,
  rightSideBarStatus,
  setLeftSideBarStatus,
  setRightSideBarStatus,
  deleteIsTouched,
  getRef,
  isTablet,
}) => {
  const params = {
    leftSideBarStatus,
    rightSideBarStatus,
    setRightSideBarStatus,
    setLeftSideBarStatus,
    deleteIsTouched,
    getRef,
    isTablet,
  };
  setVectorForSideBarC({
    toggleVector: toggleSideBarsStatus,
    getVectorHandler: getCurrentSideBarHandler({ params, sideBar, action: toggleSideBarHandler }),
    vectorSelector: getVectorSwipe,
  })({ coordinatesStart, currentCoordinate });
};


const setSideBarHandler = props => (currentCoordinate) => {
  const { coordinatesStart } = props;
  const sideBar = getSideBar({ ...props, coordinatesStart });
  if (!sideBar) return false;
  setStatusForSideBars({ ...props, currentCoordinate, sideBar });
  return sideBar;
};

const eventsMaker = ({ arrayHandlers, listeners, element }) => {
  const removeEvent = ({ handler, event }) => element.removeEventListener(event, handler);
  const addEvent = ({ handler, event }) => element.addEventListener(event, handler);

  if (listeners === 'add') {
    map(addEvent, arrayHandlers);
  } else if (listeners === 'remove') {
    map(removeEvent, arrayHandlers);
  }
};
const swipeScreen = ({
  getRef, onTouchMove, onAnimation, onTouchStart, onTouchEnd, listeners,
}) => {
  const mainContainer = getRef('main-container');
  const arrayHandlers = [
    {
      handler: onTouchStart,
      event: 'touchstart',
    },
    {
      handler: onTouchEnd,
      event: 'touchend',
    },
    {
      handler: onTouchMove,
      event: 'touchmove',
    },
    {
      handler: onAnimation,
      event: 'animationHandler',
    },
  ];

  eventsMaker({ arrayHandlers, listeners, element: mainContainer });
};


const translateSideBar = props => (currentCoordinate) => {
  const {
    coordinatesStart, getRef, leftSideBarStatus, rightSideBarStatus, isTablet,
  } = props;
  const sideBar = getSideBar({ ...props, coordinatesStart });
  if (!sideBar) return false;
  const sideBarElement = sideBar === LEFT_SIDE_BAR ? getRef('leftSideBar') : getRef('rightSideBar');
  if (!sideBarElement) return false;
  const sideBarStatus = sideBar === LEFT_SIDE_BAR ? leftSideBarStatus : rightSideBarStatus;
  const lengthSwipe = currentCoordinate - coordinatesStart;
  const offsetWidthClose = getSideBarOffsetToClose(sideBar, isTablet, sideBarElement);
  const positionSideBarToClose = offsetWidthClose + lengthSwipe;
  const translateCount = sideBarStatus
    ? lengthSwipe
    : positionSideBarToClose;

  if (sideBar === LEFT_SIDE_BAR && translateCount > 0) return sideBar;
  if (sideBar === RIGHT_SIDE_BAR && translateCount < 0) return sideBar;

  if (sideBar === LEFT_SIDE_BAR && translateCount > 0) {
    sideBarElement.style.transform = `translateX(${translateCount}px)`;
  } else {
    sideBarElement.style.transform = 'translateX(1px)';
  }
  return currentCoordinate;
};

const isMinRangeForEffectSwipe = (coordinatesStart,
  coordinatesNow) => MIN_RANGE_FOR_EFFECT < Math.abs(coordinatesStart - coordinatesNow);

const onTouchMoveHandler = props => (e) => {
  const {
    isSwipeSideBars, isTouched, isPrevent, setLastCoordinate, coordinatesStart,
  } = props;
  const currentCoordinate = getCoordinatesFromTouchEvent(e);
  const isTranslateRange = isMinRangeForEffectSwipe(coordinatesStart, currentCoordinate);

  setLastCoordinate(currentCoordinate);
  if (isTranslateRange && isSwipeSideBars && isTouched && !isPrevent) {
    const isChangeSideBarStatus = compose(
      checkRangeSwipe(props),
      translateSideBar(props),
    )(currentCoordinate);

    if (isChangeSideBarStatus) setSideBarHandler(props)(currentCoordinate);
  }
};

const onTouchStartHandler = ({
  setLastCoordinate,
  setStartCoordinate,
  setIsTouched,
}) => (e) => {
  const currentCoordinate = getCoordinatesFromTouchEvent(e);
  compose(
    setLastCoordinate,
    setIsTouched,
    setStartCoordinate,
  )(currentCoordinate);
  setLastCoordinate(currentCoordinate);
  return e;
};

const translateBackSideBar = ({
  sideBarElement, sideBarStatus, getRef, sideBar, isTablet,
}) => {
  const setStatusForLeftSideBarByStylesC = setStatusForSideBarByStyles({
    getRef, isTablet,
  });
  if (sideBarElement.hasAttribute('data-statusWillChange')) {
    const eventHandler = () => {
      setStatusForLeftSideBarByStylesC(!sideBarStatus, () => {}, sideBar);
    };
    sideBarElement.addEventListener('statusChanged', eventHandler);
    sideBarElement.removeEventListener('statusChanged', eventHandler);
    sideBarElement.removeAttribute('data-statusWillChange');
  } else {
    setStatusForLeftSideBarByStylesC(sideBarStatus, () => {}, sideBar, false);
  }
};

const onTouchEndHandler = ({
  deleteIsTouched, leftSideBarStatus, rightSideBarStatus, getRef, isTablet, coordinatesStart,
  coordinatesLast,
}) => (e) => {
  const isTranslateRange = isMinRangeForEffectSwipe(coordinatesStart, coordinatesLast);

  if (isTranslateRange) {
    translateBackSideBar({
      sideBarElement: getRef('leftSideBar'),
      sideBarStatus: leftSideBarStatus,
      getRef,
      sideBar: LEFT_SIDE_BAR,
      isTablet,
    });
    translateBackSideBar({
      sideBarElement: getRef('rightSideBar'),
      sideBarStatus: rightSideBarStatus,
      getRef,
      sideBar: RIGHT_SIDE_BAR,
      isTablet,
    });
    return compose(deleteIsTouched)();
  }
  return e;
};

const setDefaultWidthByScreenWidthHandler = ({
  windowWidth,
  setRightSideBarStatus,
  setLeftSideBarStatus,
}) => () => {
  if (windowWidth < 481) {
    setRightSideBarStatus(false);
    setLeftSideBarStatus(false);
  }
  if (windowWidth < 1200) {
    setRightSideBarStatus(false);
  }
};

const setStartCoordinateStateHandler = () => value => ({ coordinatesStart: value });
const setLastCoordinateStateHandler = () => value => ({ coordinatesLast: value });
const setIsTouchedStateHandler = () => () => ({ isTouched: true });
const deleteIsTouchedStateHandler = () => () => ({ isTouched: false });
const setStatusUpdateSideBarsStateHandler = () => value => ({ isUpdate: value });

const withSideBarSwipe = ({ range = 100 }) => compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWindowWidth(),
  withRefs(),
  defaultProps({
    range,
  }),
  withProps(({ windowWidth }) => ({
    isTablet: windowWidth > 767,
  })),
  withContext(
    {
      setRightSideBarRef: PropTypes.func,
      setLeftSideBarRef: PropTypes.func,
    },
    props => ({
      setLeftSideBarRef: props.setRef,
      setRightSideBarRef: props.setRef,
    }),
  ),
  withStateHandlers(() => ({ coordinatesStart: 0, coordinatesLast: 0, isTouched: false }), {
    setStartCoordinate: setStartCoordinateStateHandler,
    setLastCoordinate: setLastCoordinateStateHandler,
    setIsTouched: setIsTouchedStateHandler,
    setStatusUpdateSideBars: setStatusUpdateSideBarsStateHandler,
    deleteIsTouched: deleteIsTouchedStateHandler,
  }),
  withHandlers({
    onTouchStart: onTouchStartHandler,
    onTouchMove: onTouchMoveHandler,
    onTouchEnd: onTouchEndHandler,
    setDefaultWidthByScreenWidth: setDefaultWidthByScreenWidthHandler,
  }),
  lifecycle({
    componentDidMount() {
      this.props.setDefaultWidthByScreenWidth();
      swipeScreen({ ...this.props, listeners: 'add' });
    },
    componentWillUnmount() {
      swipeScreen({ ...this.props, listeners: 'remove' });
    },
    shouldComponentUpdate(prevProps) {
      return !(prevProps.isTouched !== this.props.isTouched
          || prevProps.coordinatesStart !== this.props.coordinatesStart
        || prevProps.coordinatesLast !== this.props.coordinatesLast);
    },
  }),
);

export default withSideBarSwipe;
