import { compose, lifecycle, withHandlers } from 'recompose';

import AvatarProfile from './avatarProfile';
import { withUploadFile } from '../../../../utils/enchancers';

const onResetAvatarHandler = ({ setFileValue, setFieldValue, name }) => () => {
  setFileValue(null);
  setFieldValue(name, null);
};

const enhance = compose(
  withUploadFile,
  withHandlers({
    onResetAvatar: onResetAvatarHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { setFieldValue, userAvatar } = this.props;
      setFieldValue('avatar', userAvatar);
    },
  }),
);

export default enhance(AvatarProfile);
