import React from 'react';
import { values } from 'ramda';
import PropTypes from 'prop-types';

import './style.sass';
import { ScrollWrapper } from '../..';
import EmojiItem from './emojiItem';
import CategoryItem from './categoryItem/categoryItem';

const EmojiPicker = ({
  isOpen,
  onSetEmoji,
  setRef,
  data,
  onOpen,
  onEventPrevent,
  onSetRefEmoji,
  selectedEmoji,
  onSetRefScrollWrapper,
  selectedCategory,
}) => (
  <div className={`emoji ${isOpen ? 'emoji--active' : ''}`}>
    <button
      type="button"
      className="emoji btn btn-outline-secondary b-0 mr-2"
      onClick={onOpen}
      onMouseDown={onEventPrevent}
    >
      <span className="icon-face-glasses" />
    </button>
    {
      isOpen && (
        <div className="emoji__picker" ref={e => setRef('emojiPicker', e)}>
          <div className="emoji__picker-header">
            <ul className="emoji__picker-categories">
              {
                data.map(category => (
                  <CategoryItem
                    key={`emoji__picker-item-cat${category.id}`}
                    category={category}
                  />
                ))
              }
            </ul>
          </div>
          <div className="emoji__picker-body" ref={onSetRefEmoji}>
            <ScrollWrapper refCustom={onSetRefScrollWrapper} className="emoji__picker-scroll">
              <ul className="emoji__picker-list" ref={e => setRef('emojiList', e)}>
                {
                  data.map((category, categoryIndex) => (
                    <div
                      className="emoji__picker-group"
                      key={`category-group-${category.id}`}
                    >
                      <h3
                        key={`emoji__picker-item-${category.name}title`}
                        className="emoji__picker-category-title"
                        id={`emoji__category-${category.id}`}
                      >
                        {category.name}
                      </h3>
                      {
                       values(category.emojis).map((item, index) => (
                         <div
                           key={`emoji__picker-item-${category.name}${item}`}
                           className={`emoji__picker-emoji 
                            ${(selectedEmoji === index && selectedCategory === categoryIndex)
                             ? 'emoji__picker-emoji--active' : ''}`}
                         >
                           <EmojiItem
                             item={item}
                             onClick={onSetEmoji}
                           />
                         </div>
                       ))
                     }
                    </div>
                  ))
                }
              </ul>
            </ScrollWrapper>
          </div>
        </div>
      )
    }
  </div>
);

EmojiPicker.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onEventPrevent: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onSetEmoji: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  setRef: PropTypes.func.isRequired,
  onSetRefEmoji: PropTypes.func.isRequired,
  selectedEmoji: PropTypes.number.isRequired,
  onSetRefScrollWrapper: PropTypes.func.isRequired,
  selectedCategory: PropTypes.number.isRequired,
};

export default EmojiPicker;
