import React from 'react';
import { Link } from 'react-router-dom';
import { Favorite } from '../../../../components/index';
import './style.sass';

const FavoriteProjects = () => (
  <div className="favorite-projects">
    <ul className="favorite-projects__list">
      <li className="favorite-projects__item">
        <div className="favorite-projects__item-project">
          <Favorite onClick={() => {}} status />
          <Link to="/" className="favorite-projects__link">Some long link for projects hello World</Link>
        </div>
      </li>
      <li className="favorite-projects__item">
        <div className="favorite-projects__item-project">
          <Favorite onClick={() => {}} status />
          <Link to="/" className="favorite-projects__link">Rocket Chief</Link>
        </div>
      </li>
      <li className="favorite-projects__item">
        <div className="favorite-projects__item-project">
          <Favorite onClick={() => {}} status={0} />
          <Link to="/" className="favorite-projects__link">Spearhead</Link>
        </div>
      </li>
      <li className="favorite-projects__item">
        <div className="favorite-projects__item-project">
          <Favorite onClick={() => {}} status />
          <Link to="/" className="favorite-projects__link">KelPie</Link>
        </div>
      </li>
      <li className="favorite-projects__item">
        <div className="favorite-projects__item-project">
          <Favorite onClick={() => {}} status />
          <Link to="/" className="favorite-projects__link">Anex Baby</Link>
        </div>
      </li>
    </ul>
  </div>
);
export default FavoriteProjects;
