import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  LabelPriorityTask, BrillMask, Avatar,
  ButtonsCollection, DropDown, PendingControl,
} from '../../../../components';

import './style.sass';
import { formatDate, getCategoryTitle } from '../../../../utils/helpers/taskHelpers/crudHelper';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { getTimeStringBySeconds } from '../../../../utils/helpers/dateHelpers';
import PermissionElement from '../../../../components/permissionElement';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const TaskCard = ({
  t,
  categories,
  isDragging,
  assignees,
  task,
  P,
  onEdit,
  onDelete,
  onResetOfflineDelete,
  onResetOfflineEdit,
  onResetOfflineCreate,
  isEditDisabled,
  taskStatuses,
  onSetTaskStatus,
  windowWidth,
}) => {
  const categoryTitle = getCategoryTitle(categories[task.category_id]);
  const assignee = assignees[task.category_id];
  return (
    <Link to={`/projects/${task.project_id}/task/${task.id}`} className={`agenda-task ${isDragging ? 'agenda-task--drag' : ''}`}>
      <PendingControl
        onResetOfflineDelete={onResetOfflineDelete}
        onResetOfflineEdit={onResetOfflineEdit}
        onResetOfflineCreate={onResetOfflineCreate}
        isPending={task.isPending}
        isUpdating={task.isUpdating}
        isDeleting={task.isDeleting}
      />
      <div className="agenda-task__header">
        <b className="agenda-task__category">{categoryTitle}</b>
        <h4 className="agenda-task__title">{task.title}</h4>
        <PermissionElement module={MODULES.TASKS} action={[ACTIONS.CREATE, ACTIONS.DELETE]}>
          {
            !isEditDisabled && (
              <DropDown
                className="controls-group-task"
                name="controls-group-task"
                label={(
                  <ButtonsCollection.ButtonIcons
                    className="agenda-task__controls-button"
                    title="Controls for sprint"
                  >
                    <div className="s-18 icon-more_vert" />
                  </ButtonsCollection.ButtonIcons>
                )}
                list={
                  [
                    ...(P(MODULES.TASKS, ACTIONS.CREATE) ? ([{
                      id: 0,
                      onClick: onEdit,
                      label: (
                        <>
                          <span className="controls-group-task__icon icon-pencil-2" />
                          <span className="controls-group-task__text">{t('Edit')}</span>
                        </>
                      ),
                    }]) : []),
                    ...(P(MODULES.TASKS, ACTIONS.DELETE) ? ([{
                      id: 2,
                      onClick: onDelete,
                      label: (
                        <>
                          <span className="controls-group-task__icon icon-delete-icon" />
                          <span className="controls-group-task__text">{t('Delete')}</span>
                        </>
                      ),
                    }]) : []),

                  ]
                }
              />
            )
          }
        </PermissionElement>
      </div>
      <div className="agenda-task__body">
        {
            assignee && (
              <BrillMask className="agenda-task__avatar-user" disableHighlight asLink to="/">
                <Avatar src={assignee.avatar} alt={getFullName(assignee)} />
              </BrillMask>
            )
          }
        <div className="agenda-task__date">
          {formatDate(task.created_at)}
        </div>
        <div className="agenda-task__time-entry">
          {getTimeStringBySeconds(task.spent_time)}
          {' '}
        </div>
        {
          windowWidth < 768 && (
            <DropDown
              className="controls-group-task"
              name="controls-group-task-status"
              label={(
                <div className="agenda-task__label">
                  {taskStatuses[task.status].title}
                </div>
              )}
              list={
                [
                  ...(P(MODULES.TASKS, ACTIONS.UPDATE) ? ([{
                    id: 0,
                    onClick: () => onSetTaskStatus(1),
                    label: (
                      <>
                        <span className="controls-group-task__icon icon-pencil-2" />
                        <span className="controls-group-task__text">{t('To do')}</span>
                      </>
                    ),
                  }]) : []),
                  ...(P(MODULES.TASKS, ACTIONS.UPDATE) ? ([{
                    id: 2,
                    onClick: () => onSetTaskStatus(2),
                    label: (
                      <>
                        <span className="controls-group-task__icon icon-delete-icon" />
                        <span className="controls-group-task__text">{t('In progress')}</span>
                      </>
                    ),
                  }]) : []),
                  ...(P(MODULES.TASKS, ACTIONS.UPDATE) ? ([{
                    id: 3,
                    onClick: () => onSetTaskStatus(3),
                    label: (
                      <>
                        <span className="controls-group-task__icon icon-delete-icon" />
                        <span className="controls-group-task__text">{t('Done')}</span>
                      </>
                    ),
                  }]) : []),

                ]
              }
            />
          )
        }
        <div className="agenda-task__info">
          <LabelPriorityTask status={task.priority} />
          <div className="agenda-task__number">{`#${task.id}`}</div>
        </div>
        <div className="agenda-task__deadline-row">
          <div className="agenda-task__deadline">
            Deadline:
            {' '}
            {task.deadline ? formatDate(task.deadline) : '-'}
          </div>
        </div>
      </div>
    </Link>
  );
};


TaskCard.propTypes = {
  categories: PropTypes.instanceOf(Object).isRequired,
  assignees: PropTypes.instanceOf(Object).isRequired,
  task: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};


export default TaskCard;
