import {
  compose, lifecycle, withContext, withHandlers, withProps, withStateHandlers,
} from 'recompose';
import {
  curry, equals, isNil, path, pathOr,
} from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { projectActions, projectSelectors } from '../../state/project';

import TasksWindow from './tasks';
import { preloaderWhileLoading, withFilters, withUrlParams } from '../../utils/enchancers';
import { PRELOADER_DIMENSION } from '../../constants/ui';
import { uiActions } from '../../state/ui';
import {
  filterAndTransformCondition,
  filterParamKey,
  setFilterForSingleParam,
} from '../../utils/helpers/uiComponentHelpers/filters';
import withPermission from '../../utils/enchancers/withPemission';
import { MODULES } from '../../constants/pemissions';
import { offlineRequestSelectors } from '../../utils/middlewares/sagaRequestApi/state';
import { STATUS } from '../../constants/projects';

const mapDispatchToProps = ({
  getCategoriesTask: projectActions.getCategoriesTaskRequest,
  sortProjectTasks: projectActions.sortProjectTasksRequest,
  getTasks: projectActions.getProjectTasksRequest,
  onDragEnd: uiActions.onDragEnd,
});

const mapStateToProps = (state, { match }) => ({
  sprints: projectSelectors.getSprintsList(state)(path(['params', 'id'], match)),
  projectTasksPendingRequest: projectSelectors.getProjectTasksPendingRequest(state),
  offlineRequestsList: offlineRequestSelectors.getRequestsList(state),
  project: projectSelectors.default(state)(path(['params', 'id'], match)),
});

const setSelectedTaskIdStateHandler = () => value => ({
  selectedTaskId: value,
});

const setSelectedSortStateHandler = () => value => ({ selectedSort: value });

const onDragEndHandler = ({
  onDragEnd, match: { params: { id } },
}) => (data) => {
  const { destination, source } = data;
  if (!destination) return;
  if (destination.droppableId === source.droppableId && destination.index === source.index) return;
  onDragEnd({ ...data, projectId: id }, { section: 'project' });
};

const changeParamKey = (e) => {
  const [key, value] = e;
  return ({ field: key, value });
};

const onSelectUserHandler = curry((type, {
  setFilters, onSetUrlParam, onResetUrlParam, mergeFilters,
}) => (item) => {
  const id = pathOr(null, ['val', 'id'], item);
  const action = isNil(id) ? onResetUrlParam : onSetUrlParam;
  return setFilterForSingleParam(mergeFilters, setFilters, action, type, id);
});

const enhance = compose(
  withPermission([MODULES.TASKS]),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withProps(({ match }) => ({ projectId: path(['params', 'id'], match) })),
  withProps(({ match, getTasks, project }) => ({
    projectId: path(['params', 'id'], match),
    getTasksWithFilters: ({ filters }) => {
      const projectId = path(['params', 'id'], match);
      const where = filterAndTransformCondition(filterParamKey, changeParamKey, filters);
      getTasks({ projectId, where, withRelated: 'tasks' });
    },
    isProjectCompleted: project.status === STATUS.COMPLETED,
  })),
  withStateHandlers(
    () => ({ selectedTaskId: 0, selectedSort: {} }),
    {
      setSelectedTaskId: setSelectedTaskIdStateHandler,
      setSelectedSort: setSelectedSortStateHandler,
    },
  ),
  withUrlParams({ params: { concat: ['grid'] } }),
  withFilters({
    initial: ({ getUrlParam }) => ({ assignee: getUrlParam(['assignee']), created_by: getUrlParam(['assigner']) }),
  }),
  withHandlers({
    onDragEnd: onDragEndHandler,
    onSelectAssignee: onSelectUserHandler('assignee'),
    onSelectAssigner: onSelectUserHandler('assigner'),
  }),
  withContext({
    selectedTaskId: PropTypes.number,
    selectedSort: PropTypes.instanceOf(Object),
    onSelectAssignee: PropTypes.func,
    onSelectAssigner: PropTypes.func,
    onSetUrlParam: PropTypes.func,
    getUrlParam: PropTypes.func,
  }, props => ({
    selectedTaskId: props.selectedTaskId,
    selectedSort: props.selectedSort,
    onSelectAssignee: props.onSelectAssignee,
    onSelectAssigner: props.onSelectAssigner,
    onSetUrlParam: props.onSetUrlParam,
    getUrlParam: props.getUrlParam,
  })),
  lifecycle({
    componentDidMount() {
      const {
        getCategoriesTask, projectId, getTasksWithFilters, getUrlParams, offlineRequestsList,
      } = this.props;
      const filters = getUrlParams();
      getTasksWithFilters({ filters });
      getCategoriesTask({ id: projectId });
      window.addEventListener('online', () => {
        if (offlineRequestsList.length === 0) getTasksWithFilters({ filters });
      });
    },
    componentDidUpdate(prevProps) {
      const { getTasksWithFilters, filters } = this.props;
      if (!equals(filters, prevProps.filters)) {
        getTasksWithFilters({ filters });
      }
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    isLoading: props => props.projectTasksPendingRequest,
  }),
);

export default enhance(TasksWindow);
