/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect/lib/index';
import {
  pathOr, identity, memoizeWith,
} from 'ramda';

const getActiveTask = createSelector(
  state => pathOr({}, ['task', 'entities', 'data'], state),
  identity,
);

const getActiveTaskPending = createSelector(
  pathOr(false, ['pending', 'getActiveTaskRequest']), identity,
);

const getTaskComments = createSelector(
  pathOr({}, ['task', 'entities', 'comments']), identity,
);

const getTaskCommentsList = createSelector(
  pathOr([], ['task', 'result', 'comments']), identity,
);

const getComment = createSelector(
  getTaskComments,
  comments => memoizeWith(identity, id => pathOr({}, [id], comments)),
);

const getHasMoreTaskComments = createSelector(pathOr(false, ['task', 'entities', 'comments', 'hasMore']), identity);
const getTimeEntries = createSelector(state => taskId => pathOr({}, ['task', taskId, 'entities', 'time_entries'], state), identity);
const getTimeEntriesList = createSelector(state => taskId => pathOr([], ['task', taskId, 'result', 'time_entries'], state), identity);

const getAddTaskCommentRequestPending = createSelector(pathOr(false, ['pending', 'addTaskCommentRequest']), identity);
const getShowMoreTaskCommentsRequestPending = createSelector(pathOr(false, ['pending', 'getShowMoreTaskCommentsRequest']), identity);
const getTaskCommentsRequestPending = createSelector(pathOr(false, ['pending', 'getTaskCommentsRequest']), identity);
const getTimeEntriesRequestPending = createSelector(pathOr(false, ['pending', 'getTimeEntriesRequest']), identity);
const getAddTimeEntryRequestPending = createSelector(pathOr(false, ['pending', 'addTimeEntryRequest']), identity);


const getTimeEntry = createSelector(state => (timeEntryId, taskId) => pathOr({}, ['task', taskId, 'entities', 'time_entries', timeEntryId], state), identity);

export {
  getActiveTask,
  getActiveTaskPending,
  getTaskComments,
  getTaskCommentsList,
  getAddTaskCommentRequestPending,
  getShowMoreTaskCommentsRequestPending,
  getTaskCommentsRequestPending,
  getHasMoreTaskComments,
  getTimeEntries,
  getTimeEntriesList,
  getTimeEntriesRequestPending,
  getAddTimeEntryRequestPending,
  getTimeEntry,
  getComment,
};
