import React from 'react';
import PropTypes from 'prop-types';

import { MessageTextArea } from '../../../../../../components';
import './style.sass';

const CommentsTextArea = ({
  setFieldValue, value, onSend, isPending,
  isLeftSideBar,
  isRightSideBar,
  isAllSideBar,
}) => (
  <div className={
    `task-comments__message-text-area
    ${isLeftSideBar ? 'task-comments__message-text-area--leftSideBar-active ' : ''}
    ${isRightSideBar ? 'task-comments__message-text-area--rightSideBar-active ' : ''}
    ${isAllSideBar ? 'task-comments__message-text-area--allSideBar-active ' : ''}
    `
  }
  >
    <MessageTextArea
      onKeyDown
      name="chat"
      pending={isPending}
      customRef={() => {}}
      // onChangeHeight={e => updateHeightChatContainerHandler({ heightFieldChat: e.newHeight })}
      onSubmit={onSend}
      setHeight={() => {}}
      onChange={setFieldValue}
      value={value}
    />
  </div>
);

CommentsTextArea.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onSend: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  isLeftSideBar: PropTypes.bool.isRequired,
  isRightSideBar: PropTypes.bool.isRequired,
  isAllSideBar: PropTypes.bool.isRequired,
};

export default CommentsTextArea;
