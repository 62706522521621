import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const Preloader = ({
  fullScreen,
  alignContainerCenter,
  dimension,
  className,
}) => (
  <div className="preloader--big-wrap">
    <div className="preloader-wrapper big active">
      <div className="spinner-layer spinner-blue">
        <div className="circle-clipper left">
          <div className="circle" />
        </div>
        <div className="gap-patch">
          <div className="circle" />
        </div>
        <div className="circle-clipper right">
          <div className="circle" />
        </div>
      </div>

      <div className="spinner-layer spinner-red">
        <div className="circle-clipper left">
          <div className="circle" />
        </div>
        <div className="gap-patch">
          <div className="circle" />
        </div>
        <div className="circle-clipper right">
          <div className="circle" />
        </div>
      </div>

      <div className="spinner-layer spinner-yellow">
        <div className="circle-clipper left">
          <div className="circle" />
        </div>
        <div className="gap-patch">
          <div className="circle" />
        </div>
        <div className="circle-clipper right">
          <div className="circle" />
        </div>
      </div>

      <div className="spinner-layer spinner-green">
        <div className="circle-clipper left">
          <div className="circle" />
        </div>
        <div className="gap-patch">
          <div className="circle" />
        </div>
        <div className="circle-clipper right">
          <div className="circle" />
        </div>
      </div>
      <div className="spinner-layer spinner-blue-only">
        <div className="circle-clipper left">
          <div className="circle" />
        </div>
        <div className="gap-patch">
          <div className="circle" />
        </div>
        <div className="circle-clipper right">
          <div className="circle" />
        </div>
      </div>
    </div>
  </div>
);

Preloader.propTypes = {
  fullScreen: PropTypes.bool,
  setRef: PropTypes.func.isRequired,
  dimension: PropTypes.number,
  alignContainerCenter: PropTypes.bool,
  className: PropTypes.string,
};

Preloader.defaultProps = {
  fullScreen: false,
  alignContainerCenter: false,
  dimension: 200,
  className: '',
};

export default Preloader;
