import {
  compose, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import {
  isEmpty, prop, propOr,
} from 'ramda';
import { withRouter } from 'react-router';

import { withTasksCRUDHandlers, withUserProfile } from '../../../../utils/enchancers';
import { projectSelectors } from '../../../../state/project';
import TaskItem from './taskItem';
import { getTimeStringBySeconds } from '../../../../utils/helpers/dateHelpers';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { usersSelectors } from '../../../../state/users';
import { withPermissionHandlers } from '../../../../components/permissionElement';
import { tasksActions } from '../../../../state/tasks';
import { offlineRequestActions } from '../../../../utils/middlewares/sagaRequestApi/state';
import { formatDate } from '../../../../utils/helpers/commonHelpers';

const mapStateToProps = (state, { isTaskHasSprint }) => ({
  categories: projectSelectors.getProjectCategoriesTasks(state),
  getUserById: usersSelectors.getUser(state),
  tasks: isTaskHasSprint ? projectSelectors.getProjectSprintTasks(state)
    : projectSelectors.getProjectBacklogTasks(state),
});

const onOpenProfileAssigneeHandler = ({
  onSetUserProfile,
  data,
}) => () => onSetUserProfile(prop('assignee', data));

const onOpenProfileAuthorHandler = ({
  onSetUserProfile,
  data,
}) => () => onSetUserProfile(prop('author', data));

const getClassByStatus = (status) => {
  if (status === 0) {
    return 'project-task__row--new';
  }
  if (status === 4) {
    return 'project-task__row--closed';
  }
  return '';
};

const onEditHandler = ({ onEditTask, projectId, id }) => () => {
  onEditTask({ taskId: id, projectId });
};

const onDeleteHandler = ({ onDeleteTask, projectId, id }) => () => {
  onDeleteTask({ taskId: id, projectId });
};

const mapDispatchToProps = ({
  setTask: tasksActions.setTask,
  deleteTask: tasksActions.deleteTask,
  resetOfflineAction: offlineRequestActions.resetOfflineAction,
});

const onResetOfflineDeleteHandler = ({
  data, setTask, resetOfflineAction,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: data.requestIdDelete, method: 'DELETE', selectorName: 'deleteTaskRequest' });
  setTask({
    task: {
      id: data.id,
      isDeleting: false,
    },
  });
};
const onResetOfflineEditHandler = ({
  data, setTask, resetOfflineAction,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: data.requestIdPut, method: 'PUT', selectorName: 'updateTaskRequest' });
  setTask({
    task: {
      id: data.id,
      ...data.oldResult,
      isUpdating: false,
    },
    meta: {
      isUpdate: true,
    },
  });
};
const onResetOfflineCreateHandler = ({
  data, resetOfflineAction, deleteTask,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: data.requestIdPost, method: 'POST', selectorName: 'addTaskRequest' });
  deleteTask({ task: data });
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withUserProfile,
  withTasksCRUDHandlers,
  withPermissionHandlers,
  withNamespaces(['common']),
  withProps(({
    id, getUserById, projectId, ...props
  }) => {
    const tasks = props.tasks(projectId);
    const {
      estimated_time, spent_time, status, author, assigneeUser,
    } = tasks[id];
    const authorData = getUserById(author);
    const assigneeData = getUserById(assigneeUser);
    return {
      progress: tasks[id] ? Math.floor((propOr(0, 'spent_time', tasks[id]) * 100) / propOr(1, 'estimated_time', tasks[id])) : 0,
      classNameStatus: getClassByStatus(status),
      data: tasks[id],
      authorName: getFullName(authorData) || null,
      author: authorData,
      assignee: isEmpty(assigneeData) ? false : assigneeData,
      assigneeName: getFullName(assigneeData) || null,
      estimatedTime: getTimeStringBySeconds(estimated_time),
      spentTime: getTimeStringBySeconds(spent_time),
      deadline: tasks[id].deadline ? formatDate(tasks[id].deadline) : '-',
    };
  }),
  withHandlers({
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
    onOpenProfileAssignee: onOpenProfileAssigneeHandler,
    onOpenProfileAuthor: onOpenProfileAuthorHandler,
  }),
  withHandlers({
    onResetOfflineDelete: onResetOfflineDeleteHandler,
    onResetOfflineEdit: onResetOfflineEditHandler,
    onResetOfflineCreate: onResetOfflineCreateHandler,
  }),
);
export default enhance(TaskItem);
