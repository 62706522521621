import React from 'react';

import ModalView from '../modalView';

const EditMaterial = ({
  ...props
}) => (
  <ModalView title="Edit material" titleAction="Update" {...props} />
);

export default EditMaterial;
