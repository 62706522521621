import React from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';

import MaterialItem from './components';
import './style.sass';

const MaterialsList = ({
  activeTab,
  changeActiveTabStateHandler,
  materials,
}) => (
  <div className="materials-list">
    <div className="materials-list__table">
      <div className="materials-list__table-head">
        <div className="materials-list__table-row">
          <div className="materials-list__table-col"><b>Title</b></div>
          <div className="materials-list__table-col materials-list__table-col--count"><b>Quantity</b></div>
          <div className="materials-list__table-col materials-list__table-col--description"><b>Description</b></div>
          <div className="materials-list__table-col" />
        </div>
      </div>
      <div className="materials-list__table-body">
        {
          materials.map(materialId => (
            <MaterialItem
              key={materialId}
              isOpen={equals(materialId, activeTab)}
              materialId={materialId}
              toggleIsOpen={changeActiveTabStateHandler}
            />
          ))
      }
      </div>
    </div>
  </div>
);

MaterialsList.propTypes = {
  activeTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  materials: PropTypes.objectOf(PropTypes.number).isRequired,
  changeActiveTabStateHandler: PropTypes.func,
};

MaterialsList.defaultProps = {
  activeTab: '',
  changeActiveTabStateHandler: () => {},
};

export default MaterialsList;
