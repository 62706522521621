import {
  compose, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { withNamespaces } from 'react-i18next';
import { path } from 'ramda';

import { helpers } from '../../../utils';
import { userActions, userSelectors } from '../../../state/user';
import { uiActions, uiSelectors } from '../../../state/ui';

import SignIn from './signIn';
import { TITLES_CONSTANTS } from '../../../constants/titles';

const { requestHelpers } = helpers;
const { loginFetch, loginGoogleFetch } = userActions;

const mapDispatchToProps = ({
  userLoginRequest: loginFetch,
  userLoginGoogleRequest: loginGoogleFetch,
  displayNotification: uiActions.displayNotification,
  setCurrentPage: uiActions.setPage,
});

const mapStateToProps = state => ({
  authNotification: uiSelectors.getAuthNotification(state),
  pending: userSelectors.getPendingLoginRequest(state),
});

const rules = yup.object().shape({
  password: yup
    .string()
    .required({
      message: 'Password is required',
    }),
  username: yup
    .string()
    .required({
      field: 'username',
      message: 'Username is required',
    }),
});

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'validation']),
  withFormik({
    validate: async (values, props) => {
      const { displayNotification } = props;
      try {
        await rules.validate(values);
        displayNotification(null);
      } catch (fail) {
        const { message } = fail;
        displayNotification({
          [message.field]: {
            message: message.message,
            params: message.params,
          },
        });
      }
    },
    mapPropsToValues: props => ({ username: props.username, password: props.password }),
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: (values, { props: { userLoginRequest } }) => {
      userLoginRequest(values);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { userLoginGoogleRequest } = this.props;
      const code = requestHelpers.getQueryVariable('code');
      if (code) {
        userLoginGoogleRequest({ code });
      }
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.PAGE_SIGN_IN });
    },
    componentDidUpdate(prevProps) {
      const { authNotification, setErrors } = this.props;
      if (prevProps.authNotification !== authNotification) {
        if (path(['password'], authNotification)) setErrors({ usernameOrPassword: 'Username or password is not correct' });
      }
    },
    componentWillUnmount() {
      this.props.displayNotification(null);
    },
  }),
);

export default enhancer(SignIn);
