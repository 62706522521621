import {
  compose, lifecycle, defaultProps, getContext, withHandlers, withProps, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { map } from 'ramda';
import moment from 'moment';
import EventsContainer from './eventsContainer';
import { uiActions } from '../../state/ui';
import preloaderWhileLoading from '../../utils/enchancers/preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../constants/ui';
import { eventsActions, eventsSelectors } from '../../state/events';

const ITEMS_ON_PAGE = 10;

const mapStateToProps = state => ({
  isPendingGetEvents: eventsSelectors.getPendingGetEvents(state),
  eventsList: eventsSelectors.getEvents(state),
  eventsResult: eventsSelectors.getEventsResult(state),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
  getEvents: eventsActions.getEventsRequest,
};

const onDeleteEventHandler = ({ setSelectedEventId, setOpenModal }) => (eventId) => {
  setSelectedEventId(eventId);
  setOpenModal('deleteEventModal');
};

const onEditEventHandler = ({ setSelectedEventId, setOpenModal }) => (eventId) => {
  setSelectedEventId(eventId);
  setOpenModal('editEventModal');
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setSelectedEventId: PropTypes.func,
  }),
  withRouter,
  withState('currentDate', 'setCurrentDate', new Date()),
  withState('selectedEventShowId', 'setSelectedEventShowId', 0),
  defaultProps({
    itemsOnPage: ITEMS_ON_PAGE,
  }),
  withProps(({ eventsList, eventsResult }) => ({
    events: map(itemId => ({
      ...eventsList[itemId],
      start: new Date(eventsList[itemId].start_date),
      color: eventsList[itemId].color,
      end: new Date(eventsList[itemId].end_date),
    }), eventsResult),
  })),
  withHandlers(() => ({
    onDeleteEvent: onDeleteEventHandler,
    onEditEvent: onEditEventHandler,
  })),
  lifecycle({
    componentDidMount() {
      const startOfMonth = moment(this.props.currentDate).format('YYYY-MM-01');
      const endOfMonth = moment(this.props.currentDate).format('YYYY-MM-') + moment().daysInMonth();
      this.props.getEvents({ start_date: startOfMonth, end_date: endOfMonth });
    },
    componentDidUpdate({ currentDate }) {
      if (currentDate !== this.props.currentDate) {
        const startOfMonth = moment(this.props.currentDate).format('YYYY-MM-01');
        const endOfMonth = moment(this.props.currentDate).format('YYYY-MM-') + moment().daysInMonth();
        this.props.getEvents({ start_date: startOfMonth, end_date: endOfMonth });
      }
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.EXTRA_SMALL,
    alignContainerCenter: true,
    delay: 600,
    isLoading: () => false,
    onAction: ({ isPendingGetEvents }) => isPendingGetEvents,
  }),

);
export default enhance(EventsContainer);
