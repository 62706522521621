import React from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
} from '../../../../components';
import './style.sass';
import { Link } from 'react-router-dom';

const UserControls = ({
  onLogOut, userName, userAvatar, onlineStatus, isUserControls,
}) => (
  <div className="user-panel p-3 light mb-2">
    <div>
      <div className="float-left image">
        <Avatar className="user_avatar" alt={userName} src={userAvatar} />
      </div>
      <div className="float-left info">
        <h6 className="font-weight-light mt-2 mb-1">{userName}</h6>
        {
          onlineStatus === 0 ? (
            <span>
              <i className="icon-circle text-danger blink" />
              {' '}
Offline
            </span>
          ) : (
            <span>
              <i className="icon-circle text-primary blink" />
              {' '}
Online
            </span>
          )
        }
      </div>
    </div>
    <div className="clearfix" />
    <div
      className={`collapse multi-collapse
      ${isUserControls ? 'show' : ''}
    `}
      id="userSettingsCollapse"
    >
      <div className="list-group mt-3">
        {/*        <a href="index.html" className="list-group-item list-group-item-action "> */}
        {/*          <i className="mr-2 icon-umbrella text-blue" /> */}
        {/* Profile */}
        {/*        </a> */}
        <Link to="/profile" className="list-group-item list-group-item-action">
          <i
            className="mr-2 icon-cogs text-yellow"
          />
Profile
        </Link>
        <button onClick={onLogOut} className="list-group-item list-group-item-action">
          <i
            className="mr-2 icon-security text-purple"
          />
          Log Out
        </button>
      </div>
    </div>
  </div>
);

UserControls.propTypes = {
  push: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  userAvatar: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
  onlineStatus: PropTypes.number,
};

UserControls.defaultProps = {
  onlineStatus: 0,
};

export default UserControls;
