import {
  compose, hoistStatics, lifecycle, withHandlers, withStateHandlers, withProps, defaultProps,
} from 'recompose';
import { withNamespaces } from 'react-i18next';

import withRefs from '../../../../utils/enchancers/withRefs';
import Select from './select';

const onClickOutsideHandler = ({ getRef, onToggleOpen }) => (e) => {
  const body = getRef('field');
  if (body && !body.contains(e.target)) {
    onToggleOpen(false);
  }
};

const onChangeValueStateHandler = () => value => ({ value });

const onToggleHandler = ({
  onToggleOpen, isOpen, name, onFocus,
}) => () => {
  onToggleOpen(!isOpen);
  if (!isOpen) {
    onFocus({ name });
  }
};

const onToggleOpenStateHandler = () => value => ({ isOpen: value });
const setToTopStatusStateHandler = () => value => ({ isToTop: value });

const changeVectorForOpenHandler = ({ getRef, setToTopStatus }) => () => {
  const body = getRef('body');
  if (!body) return;
  const { y, height } = body.getBoundingClientRect();
  if (window.innerHeight < y + height) setToTopStatus(true);
};

const onSelectOptionHandler = ({
  onChangeValue, onToggleOpen, onChange,
}) => ({ target }) => {
  onChange({ target: { name: target.name, value: target.value } });
  onChangeValue(target.value);
  onToggleOpen(false);
};

const enhance = compose(
  withRefs(),
  withNamespaces(['validation']),
  defaultProps({
    placeholderName: '',
    onFocus: () => {},
  }),
  withProps(({ data }) => (
    {
      optionsList: data,
    }
  )),
  withStateHandlers(({ value }) => ({
    value, isOpen: false, isToTop: false,
  }), {
    onChangeValue: onChangeValueStateHandler,
    onToggleOpen: onToggleOpenStateHandler,
    setToTopStatus: setToTopStatusStateHandler,
  }),
  withHandlers({
    onToggle: onToggleHandler,
    onSelectOption: onSelectOptionHandler,
    onClickOutside: onClickOutsideHandler,
    changeVectorForOpen: changeVectorForOpenHandler,
  }),
  lifecycle({
    componentDidMount() {
      document.addEventListener('click', this.props.onClickOutside, true);
    },
    componentDidUpdate({ isOpen }) {
      if (isOpen !== this.isOpen) this.props.changeVectorForOpen();
    },
    componentWillUnmount() {
      document.removeEventListener('click', this.props.onClickOutside, true);
    },
  }),
);
export default hoistStatics(enhance)(Select);
