/* eslint-disable import/prefer-default-export */
import {
  isEmpty, compose, filter, values, propEq, reject,
} from 'ramda';
import { SPRINT_STATUS } from '../../../constants/sprints';

const hasActiveSprint = compose(isEmpty, filter(sprint => sprint.status !== 0), values);

const getOnlyActiveSprints = reject(propEq('status', SPRINT_STATUS.NOT_ACTIVE));

export {
  hasActiveSprint,
  getOnlyActiveSprints,
};
