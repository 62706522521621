import {
  compose, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';

import DeleteCustomer from './deleteCustomer';
import { uiSelectors, uiActions } from '../../../../state/ui';
import { customersActions, customersSelectors } from '../../../../state/customers';

const mapStateToProps = (state, { selectedCustomerId }) => ({
  isOpen: uiSelectors.getModal(state)('deleteCustomer'),
  customer: customersSelectors.getCustomer(state)(selectedCustomerId),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('deleteCustomer'),
  onDeleteCustomer: customersActions.deleteCustomerRequest,
};

const onDeleteHandler = ({
  onDeleteCustomer, selectedCustomerId, sort: { limit, offset }, onCloseModal,
}) => () => {
  onDeleteCustomer({}, {
    customerId: selectedCustomerId,
    limit,
    offset,
    callbacks: {
      success: onCloseModal,
    },
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);

export default enhance(DeleteCustomer);
