import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../progressBar/index';
import { WINDOW_WIDTH } from '../../../../../../constants/ui';
import { ButtonsCollection } from '../../../../../../components';
import './style.sass';

const filesFieldContent = (pending, windowWidth) => {
  if (windowWidth > WINDOW_WIDTH.X_SMALL) {
    if (pending) {
      return <ProgressBar />;
    }
    return <label htmlFor="files-field__input" className="files-field__label">Drop file here or click to upload</label>;
  }
  return (
    <ButtonsCollection.ButtonBrill type="button" className="button--md files-field__upload-more">
      <label htmlFor="files-field__input" className="files-field__label-button">
          Upload more
      </label>
    </ButtonsCollection.ButtonBrill>
  );
};
const FilesField = ({ pending, windowWidth, onChange }) => (
  <div className="files-field">
    <input type="file" className="files-field__input" id="files-field__input" multiple onChange={onChange} />
    {
      filesFieldContent(pending, windowWidth)
    }
  </div>
);
FilesField.propTypes = {
  pending: PropTypes.bool,
  windowWidth: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
FilesField.defaultProps = {
  pending: true,
};

export default FilesField;
