import { compose } from 'recompose';
import { prop } from 'ramda';

import CreateProjectModal from './createProjectModal';
import withCRUDProject from '../../../../../utils/enchancers/withCRUDProject';
import rules from '../rules';
import withOnline from '../../../../../utils/enchancers/withOnline';

const enhance = compose(
  withOnline,
  withCRUDProject({
    rules,
    data: () => null,
    modalName: 'createProjectModal',
    onSubmit: (formValues, {
      resetForm, props: {
        addProject, setCloseModal, goToProject, initialValues,
      },
    }) => {
      const data = ({
        ...formValues,
        type: Number(formValues.type),
        members: formValues.members.map(prop('value')),
      });
      addProject(data, {
        callbacks: {
          success: ({ project: { id }, isAfterOffline }) => {
            resetForm(initialValues);
            setCloseModal('createProjectModal');
            // !isAfterOffline && goToProject(`/projects/${id}/`);
          },
        },
      });
    },
  }),
);

export default enhance(CreateProjectModal);
