import * as yup from 'yup';

const rules = yup.object().shape({
  title: yup
    .string()
    .required({
      message: 'Title required',
    }),
  start_date: yup
    .date()
    .required({
      message: 'Start date is required',
    }),
  end_date: yup
    .date()
    .required({
      message: 'Start date is required',
    }),
});
export default rules;
