import React from 'react';
import PropTypes from 'prop-types';

import ScrollWrapper from '../../scrollWrapper';

const Menu = ({ children }) => (
  <ScrollWrapper
    className="auto-complete__scroll-wrapper"
    autoHeight
    renderTrackVertical={({ style, ...props }) => (
      <div style={{ ...style }} {...props} className="auto-complete__track" />
    )}
    renderThumbVertical={props => <div {...props} className="auto-complete__thumb" />}
  >
    {children}
  </ScrollWrapper>
);

Menu.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.arrayOf(PropTypes.element),
      PropTypes.shape()],
  ).isRequired,
};

export default Menu;
