import {
  compose, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { pathOr, path } from 'ramda';

import * as moment from 'moment';
import ProjectItem from './projectItem';
import { projectsActions, projectsSelectors } from '../../../../../../state/projects';
import { getImageUrl } from '../../../../../../utils/helpers/requestHelpers';
import { offlineRequestActions } from '../../../../../../utils/middlewares/sagaRequestApi/state/offlineRequest';

const mapStateToProps = (state, { projectId }) => ({
  project: projectsSelectors.getProject(state)(projectId),
});

const mapDispatchToProps = ({
  resetOfflineAction: offlineRequestActions.resetOfflineAction,
  updateProject: projectsActions.updateProject,
  deleteProject: projectsActions.deleteProject,
});

const setProgressProjectHandler = (project) => {
  const allTasks = path(['__meta__', 'tasksCount'], project);
  const closedTasks = allTasks - pathOr(0, ['__meta__', 'openTasksCount'], project);
  return allTasks ? (closedTasks / allTasks) * 100 : 0;
};

const onResetOfflineDeleteHandler = ({
  projectId, updateProject, resetOfflineAction, project,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: project.requestIdDelete, method: 'DELETE', selectorName: 'deleteProjectRequest' });
  updateProject({
    project: {
      id: projectId,
      isDeleting: false,
    },
  });
};
const onResetOfflineEditHandler = ({
  projectId, updateProject, resetOfflineAction, project,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: project.requestIdPut, method: 'PUT', selectorName: 'editProjectRequest' });
  updateProject({
    project: {
      id: projectId,
      ...project.oldResult,
      isUpdating: false,
    },
  });
};
const onResetOfflineCreateHandler = ({
  projectId, resetOfflineAction, project, deleteProject,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: project.requestIdPost, method: 'POST', selectorName: 'addProject' });
  deleteProject({ id: projectId });
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ project }) => {
    const now = new Date();
    const then = moment(project.deadline_time).toDate();
    const differentDate = moment(then, 'DD/MM/YYYY HH:mm:ss').diff(moment(now, 'DD/MM/YYYY HH:mm:ss'), 'days');
    return {
      projectAvatar: getImageUrl(pathOr('', ['image', 'id'], project) || project.image),
      progressProject: setProgressProjectHandler(project),
      timeToLeft: differentDate >= 0 ? differentDate : 0,
    };
  }),
  withHandlers({
    onResetOfflineDelete: onResetOfflineDeleteHandler,
    onResetOfflineEdit: onResetOfflineEditHandler,
    onResetOfflineCreate: onResetOfflineCreateHandler,
  }),
);
export default enhance(ProjectItem);
