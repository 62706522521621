/* eslint-disable max-len */
import { handleActions } from 'redux-actions';
import { pathOr, keys } from 'ramda';
import types from './types';
import { mergeDeep, mergeIn } from '../../../../helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_OFFLINE_REQUEST]: mergeDeep((action, state) => {
    const oldRequests = pathOr([], [action.payload.selectorName, 'requests'], state);
    return {
      [action.payload.selectorName]: {
        requests: {
          [keys(oldRequests).length + 1]: action.payload,
        },
      },
    };
  }),
  [types.RESET_OFFLINE_REQUESTS]: mergeIn(({ payload }) => ({ [payload.selectorName]: { requests: payload.requests } })),
  [types.SET_OFFLINE_REQUESTS_STATUS]: mergeDeep(({ payload: { status } }) => ({ status })),
}, { requests: [] });

export default reducer;
