export default {
  ADD_MATERIAL_REQUEST: 'ADD_MATERIAL_REQUEST',
  GET_MATERIALS_REQUEST: 'GET_MATERIALS_REQUEST',
  DELETE_MATERIAL_REQUEST: 'DELETE_MATERIAL_REQUEST',
  UPDATE_MATERIAL_REQUEST: 'UPDATE_MATERIAL_REQUEST',

  REORDER_MATERIALS: 'REORDER_MATERIALS',
  SET_MATERIALS: 'SET_MATERIALS',
  SET_MATERIAL: 'SET_MATERIAL',
  DELETE_MATERIAL: 'DELETE_MATERIAL',
  UPDATE_MATERIAL: 'UPDATE_MATERIAL',
};
