import {
  branch,
  compose, renderNothing,
} from 'recompose';
import { connect } from 'react-redux';

import { withFormik } from 'formik';
import EditMaterial from './editMaterial';
import { uiSelectors, uiActions } from '../../../../state/ui';
import rules from '../rules';
import { materialsActions, materialsSelectors } from '../../../../state/materials';

const mapStateToProps = (state, { selectedMaterialId }) => ({
  isOpen: uiSelectors.getModal(state)('editMaterialModal'),
  material: materialsSelectors.getMaterial(state)(selectedMaterialId),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('editMaterialModal'),
  onEditMaterial: materialsActions.updateMaterialRequest,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ material, isOpen }) => !material.id || !isOpen, renderNothing),
  withFormik({
    mapPropsToValues: ({ material }) => material,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: (formValues,
      {
        props: {
          onEditMaterial,
          onCloseModal,
          selectedMaterialId,
        },
      }) => {
      onEditMaterial(({
        ...formValues,
        materialId: selectedMaterialId,
      }), { callbacks: { success: () => { onCloseModal(); } } });
    },
  }),
);

export default enhance(EditMaterial);
