import { compose } from 'recompose';
import React from 'react';
import { connect } from 'react-redux';

const mapDispatchToProps = ({
  name, action, dataPath, params, searchField = 'q',
}) => (dispatch, ownProps) => ({
  [name]: (inputValue, callback) => dispatch(action({
    [searchField]: inputValue,
    limit: 10,
    ...(params && params(ownProps)),
  }, {
    callbacks: {
      success: (data) => {
        callback(dataPath(data));
      },
    },
  })),
});

const WithAutocomplete = ({
  name, action, dataPath, params, searchField,
}) => (BaseComponent) => {
  const WrapperComponent = props => <BaseComponent {...props} />;
  const enhancer = compose(
    connect(null, mapDispatchToProps({
      name, action, dataPath, params, searchField,
    })),
  );
  return enhancer(WrapperComponent);
};

export default WithAutocomplete;
