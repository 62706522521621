import {
  compose, getContext, lifecycle, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { withWindowWidth } from '../../utils/enchancers';
import RightSideBar from './rightSideBar';
import { uiActions, uiSelectors } from '../../state/ui';

const { getRightSidebar, getLeftSidebar } = uiSelectors;

const mapStateToProps = state => ({
  leftSideBarStatus: getLeftSidebar(state),
  rightSideBarStatus: getRightSidebar(state),
  userProfileId: uiSelectors.getUserProfileId(state),
});

const mapDispatchToProps = {
  changeRightSidebarStatus: uiActions.changeRightSidebarStatus,
  changeLeftSidebarStatus: uiActions.changeLeftSidebarStatus,
  setUserProfileId: uiActions.setUserProfileId,
};

const onToggleHandler = ({
  leftSideBarStatus,
  rightSideBarStatus,
  windowWidth,
  changeLeftSidebarStatus,
  changeRightSidebarStatus,
  setUserProfileId,
  userProfileId,
}) => () => {
  if (userProfileId) {
    setUserProfileId(null);
  } else {
    if (windowWidth < 768 && leftSideBarStatus && !rightSideBarStatus) {
      changeLeftSidebarStatus(false);
    }
    changeRightSidebarStatus(!rightSideBarStatus);
  }
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withWindowWidth(),
  getContext({
    setRightSideBarRef: PropTypes.func,
  }),
  withHandlers({
    onToggle: onToggleHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { location: { pathname }, onToggle } = this.props;

      if (prevProps.location.pathname !== pathname) onToggle();
    },
  }),
);

export default enhance(RightSideBar);
