import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import './style.sass';

const Number = ({
  placeholder,
  setRef,
  onChange,
  handlerValue,
  customRef,
  onFocus,
  onBlur,
  specialKeysHandle,
  disabled,
}) => (
  <div className="field-number-time-picker">
    <MaskedInput
      mask={[/\d/, /\d/]}
      placeholder={placeholder}
      ref={(e) => { setRef('input', e); customRef(e); }}
      onBlur={onBlur}
      disabled={disabled}
      onChange={e => onChange({
        value: e.currentTarget.value,
      })}
      onFocus={onFocus}
      onKeyDown={specialKeysHandle}
      className="field-number-time-picker__place-write"
    />
    <div className="field-number-time-picker__controls">
      <button
        type="button"
        className="field-number-time-picker__plus"
        onClick={() => !disabled && handlerValue(1)}
        onKeyPress={() => !disabled && handlerValue(1)}
        tabIndex="0"
      />
      <button
        type="button"
        className="field-number-time-picker__minus"
        onClick={() => !disabled && handlerValue(-1)}
        onKeyPress={() => !disabled && handlerValue(-1)}
        tabIndex="0"
      />
    </div>
  </div>
);

Number.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  setRef: PropTypes.func,
  handlerValue: PropTypes.func,
  customRef: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  specialKeysHandle: PropTypes.func.isRequired,
};
Number.defaultProps = {
  placeholder: '',
  disabled: false,
  setRef: () => {},
  handlerValue: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
  customRef: () => {},
};
export default Number;
