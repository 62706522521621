import { compose, pure, withHandlers } from 'recompose';

import Avatar from './avatar';

const onClickAvatarHandler = ({ id, onClick }) => (e) => {
  if (onClick) {
    e.stopPropagation();
    return onClick(id);
  }
  return null;
};

const enhance = compose(
  pure,
  withHandlers({
    onClickAvatar: onClickAvatarHandler,
  }),
);
export default enhance(Avatar);
