import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { LabelPriorityTask } from '../../../../../../components';

import './style.sass';

const TaskItem = ({ task }) => (
  <li className="tasks-list__item">
    <Link to={`/projects/${task.project_id}/task/${task.id}/`} className="tasks-list__link">
      <LabelPriorityTask className="tasks-list__priority-icon" status={task.priority} />
      <div className="text--cut">
        {task.title}
      </div>
    </Link>
  </li>
);

TaskItem.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
};


export default TaskItem;
