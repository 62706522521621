import { compose } from 'recompose';
import { connect } from 'react-redux';

import DeleteProjectModal from './deleteProjectModal';
import { getModal } from '../../../../../state/ui/selectors';
import { projectsSelectors } from '../../../../../state/projects';
import { uiActions } from '../../../../../state/ui';

const mapStateToProps = (state, { selectedProjectId }) => ({
  isOpen: getModal(state)('deleteProjectModal'),
  project: projectsSelectors.getProject(state)(selectedProjectId),
});

const mapDispatchToProps = ({
  onCloseModal: () => uiActions.closeModal('deleteProjectModal'),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(DeleteProjectModal);
