import React from 'react';
import PropTypes from 'prop-types';
import ResizeDetector from 'react-resize-detector';

import { Avatar, OnlineStatusLabel, PendingControl } from '../../../../../../components';
import { DateLabel } from './components';
import './style.sass';
import { formatMessageDate } from '../../../../../../utils/helpers/messengerHelpers/messages';
import LabelUnreadMessage from '../../../labelUnreadMessage';
import { propOr } from 'ramda';
import { getImageUrl } from '../../../../../../utils/helpers/requestHelpers';

const MessageItem = ({
  message, content, top,
  onClickMention, setRef,
  userAvatar, userName, onRenderContent, onUpdateDimension,
  isUnread, isNewDay, userOnlineStatus, onResetOfflineCreate,
}) => (
  <>
    <div
      ref={e => setRef('message', e)}
      className="messenger__list_item
        "
      style={{ top: top || 0 }}
    >
      {
      (isUnread && isNewDay) ? (
        <div className="message-item__double-label">
          { isNewDay && <DateLabel date={message.created_at} /> }
          { isUnread && <LabelUnreadMessage className="label--md" /> }
        </div>
      ) : (
        <>
          { isNewDay && <DateLabel date={message.created_at} /> }
          { isUnread && <div className="solo-unread-label"><LabelUnreadMessage /></div> }
        </>
      )
    }
      <div
        className="messenger__message message-card"
      >
        <PendingControl
          onResetOfflineCreate={onResetOfflineCreate}
          isPending={message.isPending}
        />
        <div className="message-card__avatar">
          <OnlineStatusLabel status={userOnlineStatus} />
          <Avatar src={userAvatar} alt={userName} />
        </div>
        <div className="message-card__body">
          <div className="message-card__head">
            <div className="message-card__user">
              <div tabIndex="0" role="button" className="message-card__name">
                { userName }
              </div>
            </div>
            <span className="message-card__date">
              { formatMessageDate(message.created_at) }
            </span>
          </div>
          <div className="message-card__content">
            { onRenderContent(content, onClickMention, message.id) }
          </div>
          {
            propOr([], 'files', message).length > 0 && (
            <ul className="message__file-list">
              {
                propOr([], 'files', message).map(file => (
                  <li>
                    <a href={getImageUrl(file.id)}>
                      <i className="icon-attach_file" />
                      {file.original}
                    </a>
                  </li>
                ))
              }
            </ul>
            )
          }
        </div>
      </div>
      <ResizeDetector
        skipOnMount
        handleHeight
        onResize={onUpdateDimension}
      />
    </div>
  </>
);

MessageItem.propTypes = {
  onClickMention: PropTypes.func.isRequired,
  onRenderContent: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  onUpdateDimension: PropTypes.func.isRequired,
  onShowUserProfile: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
  channelId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isShowActions: PropTypes.bool.isRequired,
  isUnread: PropTypes.bool.isRequired,
  showDateLabel: PropTypes.number,
  userAvatar: PropTypes.string.isRequired,
  message: PropTypes.instanceOf(Object).isRequired,
};

MessageItem.defaultProps = {
  showDateLabel: null,
};

export default MessageItem;
