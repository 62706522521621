import React from 'react';
import PropTypes from 'prop-types';

import {
  Avatar, MessageElements,
} from '../../../../../../components';
import './style.sass';
import { getFullName } from '../../../../../../utils/helpers/userHelpers';
import { formatDate } from '../../../../../../utils/helpers/commonHelpers';

const CommentItem = ({
  content, author, createdAt, authorAvatarSrc,
}) => (
  <>
    <div className="row-flex comment__item">
      <div className="comment-card">
        <div className="comment-card__head">
          <Avatar src={authorAvatarSrc} alt={getFullName(author)} />
          <div className="comment-card__info">
            <div className="comment-card__name">
              {getFullName(author)}
            </div>
            <span className="comment-card__date">
              <span className="comment-card__date__date">{formatDate(createdAt)}</span>
              <span className="comment-card__date__time">{formatDate(createdAt, 'h:m:s A')}</span>
            </span>
          </div>
        </div>
        <div className="comment-card__body"><MessageElements>{content}</MessageElements></div>
      </div>
    </div>
  </>
);

CommentItem.propTypes = {
  author: PropTypes.instanceOf(Object).isRequired,
  createdAt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  authorAvatarSrc: PropTypes.string.isRequired,
};

export default CommentItem;
