import {
  compose, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import Members from './members';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { projectActions, projectSelectors } from '../../../../state/project';
import { projectsSelectors } from '../../../../state/projects';

const { getTabStatus } = uiSelectors;

const mapStateToProps = (state, { projectId }) => ({
  isProjectMembersTabOpen: getTabStatus(state)('isProjectMembersTabOpen'),
  membersList: projectSelectors.getProjectMembersList(state),
  project: projectsSelectors.getProject(state)(projectId),

  isPendingDelete: projectSelectors.getDeleteProjectMemberPending(state),
  isPendingInvite: projectSelectors.addMembersToProjectPending(state),
});

const mapDispatchToProps = ({
  openModal: uiActions.openModal,
  deleteMemberRequest: projectActions.deleteProjectMemberRequest,
  inviteMembersRequest: projectActions.addProjectMembersRequest,
});

const onDeleteMemberHandler = ({ deleteMemberRequest, projectId }) => (memberId, callbacks) => {
  deleteMemberRequest({ memberId, projectId }, {
    memberId,
    callbacks,
  });
};

const onInviteMemberHandler = ({ inviteMembersRequest, projectId }) => (memberIds, callbacks) => {
  inviteMembersRequest({ memberIds, projectId }, {
    callbacks,
  });
};
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common']),
  withHandlers({
    onDeleteMember: onDeleteMemberHandler,
    onInviteMember: onInviteMemberHandler,
  }),
);

export default enhance(Members);
