import {
  branch, compose, lifecycle, renderNothing, defaultProps,
} from 'recompose';
import { connect } from 'react-redux';
import { map, pathOr, prop } from 'ramda';
import { withNamespaces } from 'react-i18next';

import { withRouter } from 'react-router';
import EditTaskModal from './editTaskModal';
import rules from '../rules';
import { getActiveTask, getActiveTaskPending } from '../../../state/task/selectors';
import { setTaskData } from '../../../utils/helpers/taskHelpers/crudHelper';
import withCRUDTask from '../../../utils/enchancers/withCRUDTask';
import { tasksActions } from '../../../state/tasks';
import { newTaskActions } from '../../../state/newTask';
import withOnline from '../../../utils/enchancers/withOnline';
import { getTask } from '../../../state/project/selectors';

const mapStateToProps = (state, { selectedTaskId, match: { params: { id } } }) => ({
  task: getActiveTask(state).id ? getActiveTask(state) : getTask(state)(selectedTaskId, id),
  isActiveTaskPending: getActiveTaskPending(state),
});

const mapDispatchToProps = ({
  updateTask: tasksActions.updateTaskRequest,
  setSelectedProjectIdCrud: newTaskActions.setSelectedProjectCrud,
  getCategoriesTaskCrud: newTaskActions.getCategoriesTaskCrudRequest,
  getSprintsTaskCrud: newTaskActions.getSprintsTaskCrudRequest,
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withOnline,
  branch(
    ({ isActiveTaskPending }) => isActiveTaskPending,
    renderNothing,
  ),
  defaultProps({
    isDisabledProjectField: true,
  }),
  withNamespaces(['tasks', 'common']),
  withCRUDTask({
    rules,
    data: ({ task }) => task,
    modalName: 'editTaskModal',
    onSubmit: (formValues, {
      props: {
        updateTask, onCloseModal, selectedMeta, match, task,
      },
    }) => {
      const {
        submitButton,
      } = formValues;
      updateTask({
        ...setTaskData(formValues), materials: map(prop('value'), formValues.materials), taskId: task.id, projectId: pathOr(null, ['params', 'id'], match),
      }, {
        callbacks: {
          success: () => {
            if (submitButton !== 'saveAndCreate') {
              onCloseModal('editTaskModal');
            }
          },
        },
        ...selectedMeta,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        getCategoriesTaskCrud, getSprintsTaskCrud, projectId,
      } = this.props;
      this.props.setSelectedProjectIdCrud(this.props.projectId);
      getSprintsTaskCrud({ projectId });
      getCategoriesTaskCrud({ projectId });
    },
  }),
);

export default enhance(EditTaskModal);
