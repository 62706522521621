import {
  compose, lifecycle, withState,
} from 'recompose';
import { connect } from 'react-redux';
import {
  propOr, pathOr, values, prop,
} from 'ramda';
import { push } from 'connected-react-router';

import { helpers } from '../../../../utils';
import { messengerActions, messengerSelectors } from '../../../../state/messenger';
import MessengerContainer from './messengerContainer';
import { LAST_CHANNEL_MESSENGER_STORAGE } from '../../../../constants/messenger';
import { TITLES_CONSTANTS } from '../../../../constants/titles';
import { uiActions } from '../../../../state/ui';
import withWindowWidth from '../../../../utils/enchancers/withWindowWidth';

const { webSocketHelpers } = helpers;

const mapStateToProps = state => ({
  loadedChannels: messengerSelectors.getLoadedChannels(state),
  groupChannels: messengerSelectors.getGroupChannels(state),
  activeChannel: messengerSelectors.getActiveChannelEntity(state),
});

const mapDispatchToProps = ({
  push,
  getLatestMessagesRequest: messengerActions.getLatestMessagesRequest,
  getChannelRequest: messengerActions.getChannelRequest,
  getDirectChannelsRequest: messengerActions.getDirectChannelsRequest,
  getPinnedMessages: messengerActions.getPinnedMessagesRequest,
  connectUserToChannel: messengerActions.connectUserToChannel,
  connectSocketToServer: webSocketHelpers.actions.connectSocketToServer,
  setActiveChannel: messengerActions.setActiveChannel,
  subscribeChannel: webSocketHelpers.actions.subscribeChannel,
  listenEvent: webSocketHelpers.actions.listenEvent,
  unsubscribeChannel: webSocketHelpers.actions.unsubscribeChannel,
  setCurrentPage: uiActions.setPage,
  setLeftSideBarStatus: uiActions.changeLeftSidebarStatus,
});


const setLastChannelIdToStorage = (channelId) => {
  const event = new CustomEvent('storageChange');
  window.localStorage.setItem(LAST_CHANNEL_MESSENGER_STORAGE, channelId);
  window.dispatchEvent(event);
};

const getChannelId = ({ groupChannels, match }) => {
  const channelId = pathOr(propOr(null,
    'id', values(groupChannels)[0]), ['params', 'id'], match);
  return channelId ? parseInt(channelId, 10) : channelId;
};

const channelIdFromUrl = pathOr(false, ['params', 'id']);

const redirectToChannel = (channelId, actionPush) => channelId && actionPush(`/messenger/${channelId}`);

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWindowWidth(),
  withState('loading', 'setLoading', true),
  withState('isRenderMessenger', 'setIsRenderMessenger', false),
  withState('channelId', 'setChannelId', null),
  lifecycle({
    componentDidMount() {
      const {
        match,
        setChannelId,
        connectUserToChannel, getPinnedMessages,
        groupChannels, getChannelRequest, activeChannel, setCurrentPage,
      } = this.props;
      setCurrentPage({ page: TITLES_CONSTANTS.PAGE_MESSENGER, subTitle: activeChannel.name });
      const urlChannelId = channelIdFromUrl(match);
      const channelId = urlChannelId || prop('id', values(groupChannels)[0]);
      const channelIdLast = window.localStorage.getItem(LAST_CHANNEL_MESSENGER_STORAGE);
      if (!urlChannelId) {
        redirectToChannel(channelIdLast || prop('id', values(groupChannels)[0]), this.props.push);
      } else {
        setLastChannelIdToStorage(channelId);
        getChannelRequest(channelId);
        getPinnedMessages({ channelId });
        setChannelId(channelId);
        connectUserToChannel({ channelId });
      }
    },
    componentDidUpdate(prevProps) {
      const prevParamsChannelId = getChannelId(prevProps);
      const paramsChannelId = getChannelId(this.props);
      const {
        setChannelId, connectUserToChannel, unsubscribeChannel,
        getChannelRequest, setCurrentPage, activeChannel,
      } = this.props;
      if (activeChannel.name !== prevProps.activeChannel.name) {
        setCurrentPage({ page: TITLES_CONSTANTS.PAGE_MESSENGER, subTitle: activeChannel.name });
      }
      if ((!prevParamsChannelId || prevParamsChannelId !== paramsChannelId)) {
        if (this.props.windowWidth < 768) this.props.setLeftSideBarStatus(false);
        this.props.setActiveChannel({ id: parseInt(paramsChannelId, 0) });
        setLastChannelIdToStorage(paramsChannelId);
        setChannelId(parseInt(paramsChannelId, 0));
        getChannelRequest(paramsChannelId);
        connectUserToChannel({ channelId: paramsChannelId });
        const unsubscribeChannelId = prevParamsChannelId;
        if (unsubscribeChannelId !== paramsChannelId) {
          unsubscribeChannel({ topic: `channel:${unsubscribeChannelId}` });
        }
      }
    },
  }),
);

export default enhancer(MessengerContainer);
