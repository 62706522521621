import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const ShowMore = ({
  children,
  isOpen,
  toggleStatus,
  className,
  setRef,
  height,
  isMore,
}) => (
  <div className={`show-more ${className}`}>
    <div
      style={{ height }}
      className={`show-more__body${isOpen ? ' open' : ''}`}
      ref={e => setRef('body', e)}
    >
      {children}
    </div>
    {
      isMore && (
        <div className="show-more__row">
          <button type="button" className="show-more__button" onClick={() => toggleStatus()}>
            <span className="show-more__button-text">{isOpen ? 'Show less' : 'Show more'}</span>
            <span
              className="show-more__button-icon icon-expand-arrow-dn"
            />
          </button>
        </div>
      )
    }
  </div>
);

ShowMore.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  toggleStatus: PropTypes.func.isRequired,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  setRef: PropTypes.func.isRequired,
  isMore: PropTypes.bool.isRequired,
};

ShowMore.defaultProps = {
  className: '',
};

export default ShowMore;
