import React from 'react';
import PropTypes from 'prop-types';

import { BrillWithIndex } from '../../../../components';
import './style.sass';

const TasksSummary = ({ data, t }) => (
  <div className="projects-summary">
    <div className="projects-summary__card all">
      <BrillWithIndex colorBorder="#fcccc4">{data.all}</BrillWithIndex>
      <h4 className="projects-summary__title">{t('All')}</h4>
    </div>
    <div className="projects-summary__card progress">
      <BrillWithIndex colorBorder="#f66f5b" textColor="#f66f5b">{data.inProgress}</BrillWithIndex>
      <h4 className="projects-summary__title">{t('In progress')}</h4>
    </div>
    <div className="projects-summary__card paused">
      <BrillWithIndex colorBorder="#e6e8eb">{data.paused}</BrillWithIndex>
      <h4 className="projects-summary__title">{t('Paused')}</h4>
    </div>
    <div className="projects-summary__card done">
      <BrillWithIndex colorBorder="#bce589">{data.done}</BrillWithIndex>
      <h4 className="projects-summary__title">{t('Done')}</h4>
    </div>
  </div>
);

TasksSummary.propTypes = {
  data: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
};

TasksSummary.defaultProps = {
  data: {},
};

export default TasksSummary;
