import React from 'react';
import { prop, propOr, pathOr } from 'ramda';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './style.sass';

const JoinToChannel = ({
  channel,
  isPending,
  onJoinToChannel,
  forwardedRef,
  t,
  isOnline,
}) => (
  <div className="join-to-channel" ref={forwardedRef}>
    <div className="row text-align--center">
      <span className="join-to-channel__name-group">
        {t('You are viewing')}
        {' '}
        <span className="join-to-channel__icon icon-chanel-icon" />
        {' '}
        <h5 className="join-to-channel__name">{prop('name', channel)}</h5>
      </span>
    </div>
    <div className="row text-align--center">
      <span className="join-to-channel__author-group">
        {t('Created by') }
        {' '}
        <Link to="/1" className="join-to-channel__author">
          {pathOr('', ['author', 'first_name'], channel) }
          {' '}
          {' '}
          {' '}
          {pathOr('', ['author', 'last_name'], channel)}
        </Link>
        {' '}
        {propOr('-', 'created_at', channel)}
      </span>
    </div>
    <div className="row join-to-channel__button-row">
      <button
        disabled={isPending || !isOnline}
        title={!isOnline ? 'Only in online version' : ''}
        onClick={onJoinToChannel}
        className="btn btn-outline-primary"
      >
        {t('Join Channel')}
      </button>
    </div>
  </div>
);

JoinToChannel.propTypes = {
  forwardedRef: PropTypes.func.isRequired,
  channel: PropTypes.instanceOf(Object).isRequired,
  isPending: PropTypes.bool.isRequired,
  onJoinToChannel: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default JoinToChannel;
