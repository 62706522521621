import { createActions } from 'redux-actions';
import types from './types';

export const {
  offlineRequest,
  setOfflineRequest,
  resendOfflineRequests,
  resetOfflineRequests,
  resetOfflineAction,
  setOfflineRequestsStatus,
} = createActions(
  types.OFFLINE_REQUEST,
  types.SET_OFFLINE_REQUEST,
  types.RESEND_OFFLINE_REQUESTS,
  types.RESET_OFFLINE_REQUESTS,
  types.RESET_OFFLINE_ACTION,
  types.SET_OFFLINE_REQUESTS_STATUS,
);
