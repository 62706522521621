import { createSelector } from 'reselect/lib/index';
import {
  pathOr, identity, path, memoizeWith, compose, filter, isNil, keys, map,
} from 'ramda';

const getProjectsKeys = compose(
  map(item => Number(item)),
  keys,
  filter(item => isNil(item.sprint_id)),
);

const tasksEntitiesSelector = createSelector(
  state => path(['project', 'entities', 'tasks'], state),
  identity,
);

const getTaskActive = createSelector(
  state => pathOr({}, ['tasks', 'active'], state),
  identity,
);

const getTasks = tasksEntitiesSelector;

const getTasksList = createSelector(
  pathOr([], ['tasks', 'result']), identity,
);

const getTask = createSelector(
  tasksEntitiesSelector,
  tasks => memoizeWith(identity, taskId => pathOr({}, [taskId], tasks)),
);

const getAddTaskPendingRequest = createSelector(pathOr(false, ['pending', 'addTask']), identity);
const getTasksPendingRequest = createSelector(pathOr(false, ['pending', 'getTasksRequest']), identity);

const getAddTaskErrors = createSelector(
  pathOr([], ['errors', 'addTask']), identity,
);

const getBacklogTasks = createSelector(
  tasksEntitiesSelector,
  tasks => tasks && getProjectsKeys(tasks),
);

const getDeleteTaskPendingRequest = createSelector(pathOr(false, ['pending', 'deleteTaskRequest']), identity);

export {
  getTasks,
  getTask,
  getTasksList,
  getAddTaskErrors,
  getBacklogTasks,
  getAddTaskPendingRequest,
  getTasksPendingRequest,
  getDeleteTaskPendingRequest,
  getTaskActive,
};
