import {
  always, compose,
  cond, curry, equals, gt,
  includes, isNil, not, path, concat, ifElse, identity, is,
  prop, slice, T,
} from 'ramda';
import { Either, Maybe } from 'ramda-fantasy';

import { fireActionIfSiblingNotNil } from '../DOMHelper/node';
import { getNodeAttribute } from '../uiComponentHelpers/DOMhelpers';
import { getFullName, getUsername } from '../userHelpers';
import { curryRegexMatch, curryRegexTest, regexRules } from '../uiComponentHelpers/common';
import { isConditionRight, isNotNil } from '../commonHelpers';
import { isStartStringOrWord } from '../stringHelpers/common';
import { GLOBAL_MENTION_ID } from '../../../constants/messenger';

const { Left, Right } = Either;
const { Just, Nothing } = Maybe;

const isTextNotEmpty = isConditionRight(isNotNil);

const isStartMentionValid = isStartStringOrWord(isNil, curryRegexTest(regexRules.regOneSpace));

const isNodeMention = curry(element => compose(
  equals('mention'),
  fireActionIfSiblingNotNil(
    cond([
      [compose(equals(Node.ELEMENT_NODE), prop('nodeType')), getNodeAttribute('data-type')],
    ]),
  ),
  prop(element),
));

const isParentNodeMention = cond([
  [isNodeMention('parentElement'), Right],
  [T, Left],
]);

const isMentionEditedRight = curry(index => cond([
  [equals(true), () => Left(index)],
  [T, () => Right(index)],
]));

const lookupMentions = curry(value => cond([
  [isNil, Nothing],
  [T, Just],
])(value));

const isNextMentionExist = curry(totalMentions => isConditionRight(gt(totalMentions)));

const isMentionIncludes = curry((compare, mention) => includes(compare, getUsername(mention)));

const isNotAtOrSpace = curry((start, focusText) => cond([
  [curryRegexTest(regexRules.regAt), compose(
    cond([
      [equals(true), () => Right(true)],
      [T, () => Left(false)],
    ]), () => isStartMentionValid(focusText[start - 1]),
  )],
  [isNil, () => Left(false)],
  [T, () => Left(true)],
]));

const getMentionPosition = start => focusText => Either.either(cond([
  [equals(false), always(null)],
  [T, () => getMentionPosition(start - 1)(focusText)],
]), () => start + 1, isNotAtOrSpace(start, focusText)(focusText[start]));

const mentionPosition = curry(start => Either.either(() => null, getMentionPosition(start - 1)));

const getUsernameAndFireAction = curry((mentions, action) => compose(
  action,
  index => getUsername(mentions[index]),
));

const isMention = cond([
  [curryRegexTest(regexRules.regMention), compose(
    cond([
      [compose(isNil, path(['groups', 'other'])), data => Right(data)],
      [T, data => Left(lookupMentions(getFullName(path(['groups'], data))))],
    ]),
    curryRegexMatch(regexRules.regMention),
  )],
  [T, mention => Left(lookupMentions(mention))],
]);

const checkIfFilterOrAppend = curry((anchorOffset, focusText, append, filterMention, start) => {
  const textWithAt = slice(start - 1, anchorOffset)(focusText);
  return Either.either(left => append(left), () => {
    filterMention(lookupMentions(slice(start, anchorOffset, focusText)));
  })(isMention(textWithAt));
});

const isMentionEdit = curry(offset => cond([
  [isNodeMention('parentElement'), focusNode => Either.Right({ focusNode, typeNode: 'parentNode' })],
  [isNodeMention('nextSibling'), cond([
    [compose(equals(offset), prop('length')), cond([
      [compose(
        not,
        equals('@'),
        prop(0),
        path(['nextSibling', 'textContent']),
      ), focusNode => Either.Right({ focusNode, typeNode: 'nextSibling' })],
      [T, () => Either.Left(null)],
    ])],
    [T, () => Either.Left(null)],
  ])],
  [T, () => Either.Left(null)],
]));

const isHasMemberId = curry(ids => compose(
  id => includes(id, ids),
  prop('id'),
));

const setGlobalVariableForMention = compose(concat([{
  id: GLOBAL_MENTION_ID, username: 'channel', first_name: 'c', last_name: 'h',
}]), ifElse(is(Array), identity, () => []));

export {
  isMentionIncludes,
  getUsernameAndFireAction,
  isTextNotEmpty,
  isNotAtOrSpace,
  isHasMemberId,
  isParentNodeMention,
  isMentionEditedRight,
  lookupMentions,
  isMentionEdit,
  isNextMentionExist,
  checkIfFilterOrAppend,
  mentionPosition,
  isNodeMention,
  setGlobalVariableForMention,
};
