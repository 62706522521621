const GROUP_CHANNELS = 'GROUP_CHANNELS';
const DIRECT_CHANNELS = 'DIRECT_CHANNELS';
const YOU_BELONG_TO_CHANNELS = 'YOU_BELONG_TO_CHANNELS';
const YOU_CAN_JOIN_CHANNELS = 'YOU_CAN_JOIN_CHANNELS';
const MESSAGES = 'MESSAGES';
const PINNED_MESSAGES = `PINNED_${MESSAGES}`;

export default {
  CONNECT_USER_TO_CHANNEL: 'CONNECT_USER_TO_CHANNEL',
  LOADING_CHANNEL_HISTORY: 'LOADING_CHANNEL_HISTORY',
  READ_MESSAGES_REQUEST: 'READ_MESSAGES_REQUEST',

  SET_GROUP_CHANNELS: `SET_${GROUP_CHANNELS}`,
  UPDATE_GROUP_CHANNELS: `UPDATE_${GROUP_CHANNELS}`,
  SET_DIRECT_CHANNELS: `SET_${DIRECT_CHANNELS}`,
  SET_MATCHED_DIRECT_CHANNELS: `SET_MATCHED_${DIRECT_CHANNELS}`,
  SET_YOU_CAN_JOIN_CHANNELS: `SET_${YOU_CAN_JOIN_CHANNELS}`,
  SET_YOU_BELONG_TO_CHANNELS: `SET_${YOU_BELONG_TO_CHANNELS}`,
  SET_ACTIVE_CHANNEL: 'SET_ACTIVE_CHANNEL',
  SET_UNREAD_COUNT: 'SET_UNREAD_COUNT',
  SET_LOADED_CHANNEL: 'SET_LOADED_CHANNEL',
  GET_CHANNEL_REQUEST: 'GET_CHANNEL_REQUEST',
  GET_GROUP_CHANNELS_REQUEST: 'GET_GROUP_CHANNELS_REQUEST',
  GET_DIRECT_CHANNELS_REQUEST: 'GET_DIRECT_CHANNELS_REQUEST',
  REDIRECT_CHANNEL_NOT_FOUND: 'REDIRECT_CHANNEL_NOT_FOUND',
  SET_CHANNEL: 'SET_CHANNEL',
  ADD_CHANNEL: 'ADD_CHANNEL',
  SET_LATEST_MESSAGES: `SET_LATEST_${MESSAGES}`,
  SET_MORE_MESSAGES: 'SET_MORE_MESSAGES',
  SET_NEW_MESSAGE: 'SET_NEW_MESSAGE',
  SET_TIMESTAMPS: 'SET_TIMESTAMPS',
  SET_HAS_NEW_MESSAGES: `SET_HAS_NEW_${MESSAGES}`,
  SET_LAST_MESSAGE_TIME: 'SET_LAST_MESSAGE_TIME',
  SET_LAST_MESSAGE_ID: 'SET_LAST_MESSAGE_ID',
  SET_COUNT_LOADED_MESSAGE: 'SET_COUNT_LOADED_MESSAGE',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',

  GET_LATEST_MESSAGES_REQUEST: `GET_LATEST_${MESSAGES}_REQUEST`,
  UPDATE_MESSAGE_REQUEST: 'UPDATE_MESSAGE_REQUEST',
  DELETE_PINNED_MESSAGE_REQUEST: 'DELETE_PINNED_MESSAGE_REQUEST',
  CANCEL_MESSAGE_REQUEST: 'CANCEL_MESSAGE_REQUEST',
  CHANGE_CHANNEL: 'CHANGE_CHANNEL',

  EMIT_NEW_MESSAGE: 'EMIT_NEW_MESSAGE',
  EMIT_UPDATE_MESSAGE: 'EMIT_UPDATE_MESSAGE',

  SUBMIT_MESSAGES_REQUEST: 'SUBMIT_MESSAGE_REQUEST',

  GET_PINNED_MESSAGES: `GET_${PINNED_MESSAGES}`,
  SET_PINNED_MESSAGES: `SET_${PINNED_MESSAGES}`,
  ADD_PIN_MESSAGE_REQUEST: 'ADD_PIN_MESSAGE_REQUEST',
  GET_PINNED_MESSAGES_REQUEST: 'GET_PINNED_MESSAGES_REQUEST',
  SET_PIN_MESSAGE: 'SET_PIN_MESSAGE',
  SET_PINNED_MESSAGE: 'SET_PINNED_MESSAGE',
  DELETE_PIN_MESSAGE: 'DELETE_PIN_MESSAGE',
  SET_READ_CHANNEL: 'SET_READ_CHANNEL',

  ADD_MEMBER_TO_CHANNEL_REQUEST: 'ADD_MEMBER_TO_CHANNEL_REQUEST',
  DELETE_MEMBER_FROM_CHANNEL_REQUEST: 'DELETE_MEMBER_FROM_CHANNEL_REQUEST',

  SET_MEMBER_TO_CHANNEL: 'SET_MEMBER_TO_CHANNEL',
  DELETE_MEMBER_FROM_CHANNEL: 'DELETE_MEMBER_FROM_CHANNEL',

  JOIN_TO_CHANNEL_REQUEST: 'JOIN_TO_CHANNEL_REQUEST',
  UPDATE_GROUP_CHANNEL: 'UPDATE_GROUP_CHANNEL',

  DELETE_CHANNEL: 'DELETE_CHANNEL',
  DELETE_GROUP_CHANNEL: 'DELETE_GROUP_CHANNEL',

  DELETE_MESSAGE: 'DELETE_MESSAGE',
  DELETE_MESSAGE_REQUEST: 'DELETE_MESSAGE_REQUEST',

  TEXTAREA_CHANGED: 'TEXTAREA_CHANGED',

  GET_TOTAL_UNREAD_COUNT_REQUEST: 'GET_TOTAL_UNREAD_COUNT_REQUEST',
  SET_TOTAL_UNREAD_COUNT: 'SET_TOTAL_UNREAD_COUNT',
};

export {
  GROUP_CHANNELS,
  MESSAGES,
  PINNED_MESSAGES,
  DIRECT_CHANNELS,
  YOU_BELONG_TO_CHANNELS,
  YOU_CAN_JOIN_CHANNELS,
};
