import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import { ModalWindow } from '../../../components';
import './style.sass';

const DeleteTaskModal = ({
  isOpen,
  isPending,
  onCloseModal,
  task,
  onDeleteTask,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={() => onCloseModal('deleteTaskModal')}
    className="delete-task__modal"
    title={t('Delete task')}
    modalName={t('Delete task')}
  >
    <>
      <div className="row  text-align--left">
        <span className="delete-task__title title-md">
          {t('Are you sure you want to delete')}
          <b className="delete-task__name">{prop('title', task)}</b>
          {`${t('Task')}?`}
        </span>
      </div>
      <div className="delete-task__button-row">
        <button
          type="button"
          onClick={onDeleteTask}
          pending={isPending}
          className="btn btn-outline-primary"
        >
          {t('Delete')}
        </button>
        <button
          type="button"
          className="button--cancel"
          onClick={() => onCloseModal('deleteTaskModal')}
        >
          {t('Cancel')}
        </button>
      </div>
    </>
  </ModalWindow>
);

DeleteTaskModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  task: PropTypes.objectOf(PropTypes.object).isRequired,
  onDeleteTask: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default DeleteTaskModal;
