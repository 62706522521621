import React from 'react';
import PropTypes from 'prop-types';
import { DropDown, FormsCollection } from '../../../../components';
import './style.sass';

const FiltersRow = ({
  setSelectedSearch,
  selectedSearch,
  setSelectedSort,
  selectedSort,
  setFilterProjects,
  filterProjects,
  t,
}) => (
  <div className="directs-filter projects-filter">
    <div className="directs-filter__row">
      <div className="projects-tab">
        <div className="projects-tab">
          <button
            type="button"
            disabled={filterProjects === 'active'}
            className="btn-primary btn-sm w-100px m-2"
            onClick={() => setFilterProjects('active')}
          >
            Active
          </button>
          <button
            type="button"
            disabled={filterProjects === 'completed'}
            className="btn-primary btn-sm w-100px m-2"
            onClick={() => setFilterProjects('completed')}
          >
            Completed
          </button>
        </div>
      </div>
      <FormsCollection.Search
        className="search-field--circle directs-filter__search-field mr-1 ml-auto"
        name="search"
        withoutControl
        value={selectedSearch}
        onChange={e => setSelectedSearch(e.target.value)}
      />
      <DropDown
        label="Sort: "
        currentItem={selectedSort.id}
        list={
          [
            {
              id: 1,
              label: t('All'),
              value: null,
            },
            {
              id: 2,
              label: t('Recent'),
              value: 'desc',
            },
            {
              id: 3,
              label: t('Old'),
              value: 'asc',
            },
          ]
        }
        onChange={({ val }) => setSelectedSort(val)}
      />
    </div>
  </div>
);

FiltersRow.propTypes = {
  setSelectedSearch: PropTypes.func.isRequired,
  selectedSearch: PropTypes.string.isRequired,
  setSelectedSort: PropTypes.func.isRequired,
  selectedSort: PropTypes.func.isRequired,
  setFilterProjects: PropTypes.func.isRequired,
  filterProjects: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default FiltersRow;
