import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../../../../components';
import CategoriesItem from './categoriesItem';
import './style.sass';

const Categories = ({
  categoriesList, openModal,
}) => (
  <div className="category-nav">
    <div className="category-nav__header">
      <span className="category-nav__title">Categories</span>
      <ButtonsCollection.ButtonPlus
        className="category-nav__plus-button"
        title="Create category"
        onClick={() => openModal('createCategory')}
      />
    </div>
    <nav className="category-nav__nav">
      {categoriesList.length ? categoriesList.map(category => (
        <CategoriesItem
          key={category}
          categoryId={category}
        />
      )) : (
        <span className="category-nav__empty">
          There are no categories yet
        </span>
      )}
    </nav>
  </div>
);

Categories.propTypes = {
  categoriesList: PropTypes.arrayOf(PropTypes.number).isRequired,
  openModal: PropTypes.func.isRequired,
};

export default Categories;
