import { schema } from 'normalizr';


const sprintsSchema = [new schema.Entity('sprints')];
const projectsSchema = [new schema.Entity('projects')];
const categoriesSchema = [new schema.Entity('categories')];
const materialsSchema = [new schema.Entity('materials')];

export {
  sprintsSchema,
  projectsSchema,
  categoriesSchema,
  materialsSchema,
};
