import { handleActions } from 'redux-actions';
import types from './types';
import {
  mergeDeep,
  mergeIn,
} from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_ROLES]: mergeIn(({ payload: { data } }) => ({
    entities: {
      roles: data.entities.roles,
    },
    result: {
      roles: data.result,
    },
  })),
  [types.SET_MODULES]: mergeIn(({ payload: { data } }) => ({ modules: data })),
  [types.SET_MY_ROLE]: mergeDeep(({ payload: { role } }) => ({ myPermissions: role.permissions })),
  [types.UPDATE_ROLE]: mergeDeep(({ payload: { role } }) => ({
    entities: {
      roles: {
        [role.id]: role,
      },
    },
  })),
}, {
});

export default reducer;
