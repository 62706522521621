import React from 'react';
import PropTypes from 'prop-types';

const DropDownItem = ({
  item,
  onChangeValue,
  itemLabel,
  isMulti,
}) => (
  !isMulti ? (
    <li>
      <button
        type="button"
        className="btn"
        onClick={(e) => {
          e.preventDefault();
          onChangeValue(e, item);
        }}
      >
        { item.label }
      </button>
    </li>
  ) : (
    <li>
      <button
        type="button"
        className={`btn drop-down__multi-btn ${item.select ? 'drop-down__multi-btn--selected' : ''}`}
        onClick={e => onChangeValue(e, item)}
      >
        <div className="drop-down__multi-checkbox">
          <span className="drop-down__multi-checkbox-icon icon-ok" />
        </div>
        { itemLabel && itemLabel(item) }
      </button>
    </li>
  )
);

DropDownItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  isMulti: PropTypes.bool,
  itemLabel: PropTypes.func,
  onChangeValue: PropTypes.func.isRequired,
};
DropDownItem.defaultProps = {
  isMulti: false,
  itemLabel: null,
};

export default DropDownItem;
