/* eslint-disable camelcase */
import { createActions } from 'redux-actions';
import {
  identity, path, pathOr, prop, propOr,
} from 'ramda';

import types from './types';
import { taskSchema, tasksListSchema } from './schema';
import { setUsersUpdateMiddleware } from '../../utils/helpers/stateHelpers';
import { addTaskResponseSchema, editTaskResponseSchema, deleteTaskResponseSchema } from './responseSchema';

export const {
  getTasksRequest,
  addTaskRequest,
  deleteTaskRequest,
  updateTaskRequest,
  setTasks,
  setTask,
  deleteTask,
} = createActions(
  {
    [types.GET_TASKS_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/projects/${id}/tasks`,
        selectorName: 'getTasksRequest',
        method: 'GET',
        params,
        schema: {
          rules: tasksListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setTasks,
        },
        ...meta,
      }),
    ],
    [types.ADD_TASK_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: '/tasks',
        selectorName: 'addTaskRequest',
        method: 'POST',
        params: { ...params, project_id: propOr(prop('projectId', params), 'project_id', params) },
        callbacks: {
          success: path(['callbacks', 'success'], meta),
        },
        responseSchema: addTaskResponseSchema,
        actions: {
          offlineSuccess: setTask,
          success: action => setTask({ ...action, meta }),
        },
        ...meta,
      }),
    ],
    [types.DELETE_TASK_REQUEST]: [
      identity,
      ({ projectId, taskId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/tasks/${taskId}`,
        selectorName: 'deleteTaskRequest',
        method: 'DELETE',
        params,
        callbacks: path(['callbacks', 'success'], meta),
        responseStatePath: pathOr(pathOr(identity, ['projects', projectId, 'entities', 'backlog', taskId]), ['projects', projectId, 'entities', 'tasks', taskId]),
        responseSchema: data => deleteTaskResponseSchema({ id: taskId, ...data }),
        actions: {
          offlineSuccess: setTask,
          success: action => deleteTask({ ...action, meta }),
        },
        ...meta,
      }),
    ],
    [types.UPDATE_TASK_REQUEST]: [
      identity,
      ({ taskId, ...params }, meta) => ({
        async: true,
        route: `/tasks/${params.id || taskId}`,
        params: { ...params, project_id: propOr(prop('projectId', params), 'project_id', params) },
        selectorName: 'updateTaskRequest',
        method: 'PUT',
        schema: {
          rules: taskSchema,
          pathData: ['task'],
        },
        responseStatePath: pathOr(pathOr(null, ['project', params.project_id || params.projectId, 'entities', 'backlog', taskId]), ['project', params.project_id || params.projectId, 'entities', 'tasks', taskId]),
        responseSchema: data => editTaskResponseSchema({ ...data, id: taskId }),
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        actions: {
          offlineSuccess: action => setTask({ ...action, ...{ meta: { isUpdate: true } } }),
          success: !prop('isNotUpdate', meta) && setUsersUpdateMiddleware(setTask,
            {
              ...meta,
              isUpdate: true,
              multiPathEntities: [
                ['data', 'entities', 'author'],
                ['data', 'entities', 'assigneeUser'],
                ['data', 'entities', 'watchers'],
              ],
            }),
        },
        ...meta,
      }),
    ],
  },
  types.SET_TASKS,
  types.SET_TASK,
  types.DELETE_TASK,
);
