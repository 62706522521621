import {
  compose, lifecycle, getContext, withState, withContext, withHandlers, withStateHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import ProjectsNotes from './projectsNotes';
import PROJECT_TABS from '../../constants/tabNavigation';
import { uiActions } from '../../state/ui';
import { TITLES_CONSTANTS } from '../../constants/titles';
import { projectsSelectors } from '../../state/projects';

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
  setCurrentPage: uiActions.setPage,
};


const onEditNoteHandler = ({ setSelectedNoteId, setOpenModal }) => (noteId) => {
  setSelectedNoteId(noteId);
  setOpenModal('editNoteModal');
};

const onDeleteNoteHandler = ({ setSelectedNoteId, setOpenModal }) => (noteId) => {
  setSelectedNoteId(noteId);
  setOpenModal('deleteNoteModal');
};

const mapSateToProps = (state, { match: { params: { id } } }) => ({
  project: projectsSelectors.getProject(state)(id),
});


const onChangeSortingStateHandler = () => val => ({
  sortBy: val,
});

const enhance = compose(
  withRouter,
  connect(mapSateToProps, mapDispatchToProps),
  withState('selectedNoteId', 'setSelectedNoteId', 0),
  withState('selectedProjectFileId', 'setSelectedProjectFileId', 0),
  getContext({
    setActiveTab: PropTypes.func,
  }),
  lifecycle({
    componentDidMount() {
      const { setActiveTab } = this.props;
      setActiveTab(PROJECT_TABS.NOTES);
    },
    componentWillUnmount() {
      const { setActiveTab } = this.props;
      setActiveTab(PROJECT_TABS.TASKS);
    },
  }),
  withHandlers({
    onEditNote: onEditNoteHandler,
    onDeleteNote: onDeleteNoteHandler,
  }),
  withStateHandlers(() => ({ sortBy: { val: { id: 0 } } }), {
    onChangeSorting: onChangeSortingStateHandler,
  }),
  withContext({
    selectedNoteId: PropTypes.number.isRequired,
    selectedProjectFileId: PropTypes.number,
    onEditNote: PropTypes.func.isRequired,
    onDeleteNote: PropTypes.func.isRequired,
    setSelectedProjectFileId: PropTypes.func.isRequired,
  }, props => ({
    selectedNoteId: props.selectedNoteId,
    onEditNote: props.onEditNote,
    onDeleteNote: props.onDeleteNote,
    setSelectedProjectFileId: props.setSelectedProjectFileId,
    selectedProjectFileId: props.selectedProjectFileId,
  })),
  lifecycle({
    componentDidMount() {
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.DOCUMENTS, mainTitle: this.props.project.title });
    },
  }),
);
export default enhance(ProjectsNotes);
