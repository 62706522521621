import React from 'react';
import PropTypes from 'prop-types';

const StrikeText = ({ content }) => (<s>{content}</s>);

StrikeText.propTypes = {
  content: PropTypes.oneOfType([PropTypes.instanceOf(Array)]).isRequired,
};

export default StrikeText;
