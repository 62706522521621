import { createActions } from 'redux-actions';
import {
  identity, pathOr, prop,
} from 'ramda';

import types from './types';
import {
  authorSchema, timeEntriesSchema, taskCommentsSchema, taskSchema,
} from './schema';
import { setUsersUpdateMiddleware } from '../../utils/helpers/stateHelpers';
import {
  addTimeEntryResponseSchema,
  deleteTimeEntryResponseSchema,
  updateTimeEntryResponseSchema,
} from './responseSchema';

export const {
  getActiveTaskRequest,
  addTaskCommentRequest,
  getTaskCommentsRequest,
  getTimeEntriesRequest,
  addTimeEntryRequest,
  deleteTimeEntryRequest,
  updateTimeEntryRequest,
  addTaskFilesRequest,
  deleteTaskFileRequest,
  updateActiveTaskRequest,
  setActiveTask,
  redirectTaskNotFound,
  setTaskComment,
  setTaskComments,
  updateActiveTask,
  setTimeEntries,
  setTimeEntry,
  deleteTimeEntry,
  reorderTimeEntries,
  deleteTimeEntryEntity,
  setTimeEntryEntity,
  reorderSpentTimeTask,
  deleteTaskFile,
  resetTaskFiles,
  updateTask,
  setNewTimeEntry,
} = createActions(
  {
    [types.GET_ACTIVE_TASK_REQUEST]: [
      identity,
      ({ taskId, projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/tasks/${taskId}`,
        selectorName: 'getActiveTaskRequest',
        method: 'GET',
        params,
        schema: {
          rules: taskSchema,
          pathData: [],
        },
        actions: {
          success: setUsersUpdateMiddleware(setActiveTask,
            {
              multiPathEntities: [
                ['data', 'entities', 'author'],
                ['data', 'entities', 'assigneeUser'],
                ['data', 'entities', 'watchers'],
              ],
            }),
          error: redirectTaskNotFound,
        },
        ...meta,
      }),
    ],
    [types.ADD_TASK_COMMENT_REQUEST]: [
      identity,
      ({ taskId, projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/tasks/${taskId}/comments`,
        selectorName: 'addTaskCommentRequest',
        method: 'POST',
        params,
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        schema: {
          rules: { comment: authorSchema },
          pathData: [],
        },
        actions: {
          success: setUsersUpdateMiddleware(setTaskComment,
            {
              pathEntities: ['entities', 'author'],
            }),
        },
        ...meta,
      }),
    ],
    [types.GET_TASK_COMMENTS_REQUEST]: [
      identity,
      ({ taskId, projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/tasks/${taskId}/comments`,
        selectorName: meta.isShowMoreRequest ? 'getShowMoreTaskCommentsRequest' : 'getTaskCommentsRequest',
        method: 'GET',
        params,
        schema: {
          rules: taskCommentsSchema,
          pathData: ['data'],
        },
        actions: {
          success: setUsersUpdateMiddleware(setTaskComments,
            {
              pathEntities: ['entities', 'author'],
            }),
        },
        ...meta,
      }),
    ],
    [types.GET_TIME_ENTRIES_REQUEST]: [
      identity,
      ({ taskId, projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/tasks/${taskId}/time-entries`,
        selectorName: !prop('isNotPending', meta)
          ? 'getTimeEntriesRequest'
          : 'getTimeEntriesIsNotPendingRequest',
        method: 'GET',
        params: {
          sortBy: 'date', order: 'asc', ...params,
        },
        schema: {
          rules: timeEntriesSchema,
          pathData: ['data'],
        },
        actions: {
          success: setUsersUpdateMiddleware(setTimeEntries,
            {
              taskId,
              pathEntities: ['data', 'entities', 'user'],
            }),
        },
        ...meta,
      }),
    ],
    [types.ADD_TIME_ENTRY_REQUEST]: [
      identity,
      ({ taskId, projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/tasks/${taskId}/time-entries`,
        selectorName: 'addTimeEntryRequest',
        method: 'POST',
        params,
        responseSchema: addTimeEntryResponseSchema,
        actions: {
          offlineSuccess: data => setNewTimeEntry({ ...data, meta: { projectId, taskId } }),
          success: action => setTimeEntry({
            ...action,
            meta: { taskId, projectId },
          }),
        },
        ...meta,
      }),
    ],
    [types.DELETE_TIME_ENTRY_REQUEST]: [
      identity,
      ({
        taskId, projectId, timeEntryId, ...params
      }, meta) => ({
        async: true,
        route: `/projects/${projectId}/tasks/${taskId}/time-entries/${timeEntryId}`,
        selectorName: 'deleteTimeEntryRequest',
        method: 'DELETE',
        params,
        responseSchema: data => deleteTimeEntryResponseSchema({ ...data, id: timeEntryId }),
        actions: {
          offlineSuccess: data => setNewTimeEntry({ ...data, meta: { projectId, taskId } }),
          success: action => deleteTimeEntry({ ...action, meta: { taskId, projectId } }),
        },
        ...meta,
      }),
    ],
    [types.UPDATE_TIME_ENTRY_REQUEST]: [
      identity,
      ({
        taskId, projectId, timeEntryId, ...params
      }, meta) => ({
        async: true,
        route: `/projects/${projectId}/tasks/${taskId}/time-entries/${timeEntryId}`,
        selectorName: 'updateTimeEntryRequest',
        method: 'PUT',
        params,
        responseSchema: data => updateTimeEntryResponseSchema({ ...data, id: timeEntryId }),
        actions: {
          offlineSuccess: data => setNewTimeEntry({ ...data, meta: { projectId, taskId } }),
          success: action => setTimeEntry({
            ...action,
            meta: { taskId, projectId },
          }),
        },
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.ADD_TASK_FILES_REQUEST]: [
      identity,
      ({
        taskId, ...params
      }, meta) => ({
        async: true,
        route: `/tasks-files/${taskId}/files`,
        selectorName: 'addTaskFilesRequest',
        method: 'POST',
        params,
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.DELETE_TASK_FILE_REQUEST]: [
      identity,
      ({
        taskId, projectId, fileId, ...params
      }, meta) => ({
        async: true,
        route: `/projects/${projectId}/tasks/${taskId}/files/${fileId}`,
        selectorName: 'deleteTaskFileRequest',
        method: 'DELETE',
        params,
        actions: {
          success: updateTask,
        },
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.UPDATE_TASK_ACTIVE_REQUEST]: [
      identity,
      ({ taskId, ...params }, meta) => ({
        async: true,
        route: `/tasks/${taskId}`,
        params,
        selectorName: 'updateTaskActiveRequest',
        method: 'PUT',
        actions: {
          success: updateTask,
        },
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
  },
  types.SET_ACTIVE_TASK,
  types.REDIRECT_TASK_NOT_FOUND,
  types.SET_TASK_COMMENT,
  types.SET_TASK_COMMENTS,
  types.UPDATE_ACTIVE_TASK,
  types.SET_TIME_ENTRIES,
  types.SET_TIME_ENTRY,
  types.DELETE_TIME_ENTRY,
  types.REORDER_TIME_ENTRIES,
  types.DELETE_TIME_ENTRY_ENTITY,
  types.SET_TIME_ENTRY_ENTITY,
  types.REORDER_SPENT_TIME_TASK,
  types.DELETE_TASK_FILE,
  types.RESET_TASK_FILES,
  types.UPDATE_TASK,
  types.SET_NEW_TIME_ENTRY,
);
