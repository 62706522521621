import {
  compose, hoistStatics,
} from 'recompose';

import AttachImage from './attachImage';
import { withUploadFile } from '../../../../utils/enchancers';

const enhance = compose(
  withUploadFile,
);
export default hoistStatics(enhance)(AttachImage);
