import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection } from '../../../../components';

const ActiveTabTitle = ({ onClick, title }) => (
  <div className="flex-row active-tab-title">
    <ButtonsCollection.ButtonIcons
      onClick={onClick}
      title="Hide tab"
      className="active-tab-title__close-button"
    >
      <span className="icon-expand-arrow-left" />
    </ButtonsCollection.ButtonIcons>
    <h3 className="active-tab-title__title">{title}</h3>
  </div>
);

ActiveTabTitle.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ActiveTabTitle;
