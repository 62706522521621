export default {
  GET_ACTIVE_TASK_REQUEST: 'GET_ACTIVE_TASK_REQUEST',
  GET_TASK_COMMENTS_REQUEST: 'GET_TASK_COMMENTS_REQUEST',
  GET_TIME_ENTRIES_REQUEST: 'GET_TIME_ENTRIES_REQUEST',
  ADD_TASK_FILES_REQUEST: 'ADD_TASK_FILES_REQUEST',
  DELETE_TASK_FILE_REQUEST: 'DELETE_TASK_FILE_REQUEST',
  UPDATE_TASK_ACTIVE_REQUEST: 'UPDATE_ACTIVE_TASK_REQUEST',

  ADD_TASK_COMMENT_REQUEST: 'ADD_TASK_COMMENT_REQUEST',
  ADD_TIME_ENTRY_REQUEST: 'ADD_TIME_ENTRY_REQUEST',

  UPDATE_TIME_ENTRY_REQUEST: 'UPDATE_TIME_ENTRY_REQUEST',

  DELETE_TIME_ENTRY_REQUEST: 'DELETE_TIME_ENTRY_REQUEST',

  SET_ACTIVE_TASK: 'SET_ACTIVE_TASK',
  SET_TASK_COMMENT: 'SET_TASK_COMMENT',
  SET_TASK_COMMENTS: 'SET_TASK_COMMENTS',
  SET_TIME_ENTRIES: 'SET_TIME_ENTRIES',
  SET_TIME_ENTRY: 'SET_TIME_ENTRY',
  SET_TIME_ENTRY_ENTITY: 'SET_TIME_ENTRY_ENTITY',

  DELETE_TASK_FILE: 'DELETE_TASK_FILE',

  RESET_TASK_FILES: 'RESET_TASK_FILES',
  DELETE_TIME_ENTRY: 'DELETE_TIME_ENTRY',

  DELETE_TIME_ENTRY_ENTITY: 'DELETE_TIME_ENTRY_ENTITY',

  REORDER_TIME_ENTRIES: 'REORDER_TIME_ENTRIES',
  REORDER_SPENT_TIME_TASK: 'REORDER_SPENT_TIME_TASK',

  UPDATE_ACTIVE_TASK: 'UPDATE_ACTIVE_TASK',
  UPDATE_TASK: 'UPDATE_TASK',
  REDIRECT_TASK_NOT_FOUND: 'REDIRECT_TASK_NOT_FOUND',

  SET_NEW_TIME_ENTRY: 'SET_NEW_TIME_ENTRY',
};
