import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';

import { connect } from 'react-redux';
import ChannelsList from './channelsList';
import { messengerSelectors } from '../../../../state/messenger';
import preloaderWhileLoading from '../../../../utils/enchancers/preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';

const mapStateToProps = state => ({
  isPending: messengerSelectors.getGroupChannelsPending(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withNamespaces(['common', 'chat']),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.EXTRA_SMALL,
    alignContainerCenter: true,
    delay: 600,
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
);

export default enhance(ChannelsList);
