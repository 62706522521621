import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const OnlineStatusLabel = ({ status, className, t }) => (
  <>
    <span
      title={status ? t('Online') : t('Offline')}
      className={`status-online-label 
      ${className} ${status ? 'status-online-label--online' : ''}`}
    />
  </>
);

OnlineStatusLabel.propTypes = {
  status: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};
OnlineStatusLabel.defaultProps = {
  status: false,
  className: '',
};
export default OnlineStatusLabel;
