import { compose } from 'recompose';
import { connect } from 'react-redux';

import { projectSelectors } from '../../../../state/project';
import { openModal } from '../../../../state/ui/actions';
import Categories from './categories';

const mapStateToProps = state => ({
  categoriesList: projectSelectors.getProjectCategoriesTasksList(state),
});
const mapDispatchToProps = ({
  openModal,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
);
export default enhance(Categories);
