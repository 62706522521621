import {
  branch, compose, renderNothing, withProps, pure,
} from 'recompose';
import { prop, path, pathOr } from 'ramda';
import { connect } from 'react-redux';

import CommentItem from './commentItem';
import { addBreakerToContentEditableValue } from '../../../../../../utils/helpers/commonHelpers';
import { usersSelectors } from '../../../../../../state/users';
import { taskSelectors } from '../../../../../../state/task';
import { getImageUrl } from '../../../../../../utils/helpers/requestHelpers';

const mapStateToProps = (state, { commentId }) => ({
  users: usersSelectors.getUsersEntities(state),
  comment: taskSelectors.getComment(state)(commentId),
});

const enhancer = compose(
  connect(mapStateToProps),
  branch(({ comment }) => !prop('content', comment), renderNothing),
  withProps(({ comment: { content, author, created_at }, users }) => {
    const authorAvatarId = path(['avatar', 'id'], users[author]) || pathOr(null, ['avatar'], users[author]);

    return {
      content: addBreakerToContentEditableValue(content),
      createdAt: created_at,
      author: users[author],
      authorAvatarSrc: getImageUrl(authorAvatarId) || '',
    };
  }),
  pure,
);

export default enhancer(CommentItem);
