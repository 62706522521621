import {
  branch,
  compose, getContext, pure, renderNothing, withProps,
} from 'recompose';

import PropTypes from 'prop-types';
import BrillMask from './strikeText';
import { parseTextElements } from '../../utils';

const enhance = compose(
  branch(({ children }) => !children, renderNothing),
  pure,
  getContext({
    ownerId: PropTypes.number,
    onHandler: PropTypes.func,
    members: PropTypes.instanceOf(Array),
  }),
  withProps(({
    children, ownerId, onHandler, members,
  }) => ({
    content: parseTextElements(ownerId, onHandler, members, children),
  })),
);
export default enhance(BrillMask);
