import {
  compose,
  lifecycle,
  withHandlers, withState,
  withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { reduce } from 'ramda';
import {
  BRILL_NAME,
  DELIMITER_LEFT,
  DELIMITER_RIGHT,
  OFFSET_LEFT,
  OFFSET_RIGHT,
} from './constants';

import { withRefs } from '../../../../utils/enchancers';
import { usersActions, usersSelectors } from '../../../../state/users';
import CoWorker from './coWorkers';
import { uiSelectors } from '../../../../state/ui';

const { getUsersListPending, getCoworkersList } = usersSelectors;
const { getTabStatus } = uiSelectors;

const mapStateToProps = state => ({
  usersList: getCoworkersList(state),
  isPending: getUsersListPending(state),
  leftSideBarStatus: uiSelectors.getLeftSidebar(state),
  isCoWorkersTabOpen: getTabStatus(state)('isCoWorkersTabOpen'),
});

const mapDispatchToProps = ({
  getCoworkersRequest: usersActions.getCoworkersRequest,
});

const setRefToArray = ({ coWorkers }) => el => ({
  coWorkers: coWorkers.concat(el),
});

const getCountBrillInRow = ({ getRef }) => {
  const containerElement = getRef('container');
  if (!containerElement) return 0;
  const containerWidth = parseInt(containerElement.offsetWidth, 10) - 11;
  const brillWidth = 43;
  return Math.floor(containerWidth / brillWidth);
};

const getDelimitersAmount = countBrillInRow => ({
  delimiterLeft: countBrillInRow,
  brillInRow: countBrillInRow,
  delimiterRight: (countBrillInRow * 2) - 2,
});

const setOffsetForBrill = ({ getRef, id, offsetPosition }) => {
  const brillElement = getRef(`${BRILL_NAME}${id}`);
  if (!brillElement) return offsetPosition;
  brillElement.setAttribute(offsetPosition, 'true');
  return offsetPosition;
};

const currentDelimiter = ({ index, delimiterLeft, delimiterRight }) => {
  if (delimiterLeft === index) return { offset: OFFSET_LEFT, delimiter: delimiterLeft };
  if (delimiterRight === index) return { offset: OFFSET_RIGHT, delimiter: delimiterRight };
  return false;
};

const deleteDelimiterAttribute = ({ attribute, element }) => {
  if (element.hasAttribute(attribute)) {
    element.removeAttribute(attribute);
  }
  return element;
};


const checkDelimiter = ({
  acc, brillInRow, getRef, id,
}) => (params) => {
  if (!params) return { ...acc, ...{ currentIndex: acc.currentIndex + 1 } };
  const { delimiter, offset } = params;
  const keyDelimiter = offset === OFFSET_LEFT ? DELIMITER_LEFT : DELIMITER_RIGHT;
  setOffsetForBrill({ getRef, id, offsetPosition: offset });

  return ({
    ...acc,
    ...{
      [keyDelimiter]: delimiter + (brillInRow * 2) - 1,
      currentIndex: acc.currentIndex + 1,
    },
  });
};

const setGridBrill = ({ getRef, brillInRow }) => (acc, key) => {
  const { delimiterLeft, delimiterRight } = acc;
  deleteDelimiterAttribute({ attribute: OFFSET_RIGHT, element: getRef(`${BRILL_NAME}${key}`) });
  deleteDelimiterAttribute({ attribute: OFFSET_LEFT, element: getRef(`${BRILL_NAME}${key}`) });
  return compose(checkDelimiter({
    acc,
    brillInRow,
    getRef,
    id: key,
  }), currentDelimiter)({
    index: acc.currentIndex,
    delimiterLeft,
    delimiterRight,
  });
};

const setDataOffsetAttribute = ({
  getRef,
  usersList,
}) => ({ delimiterLeft, brillInRow, delimiterRight }) => {
  reduce(
    setGridBrill({ getRef, brillInRow }),
    { delimiterLeft, delimiterRight, currentIndex: 0 },
    usersList,
  );
};

const onSetRefContainerHandler = ({ setRef }) => e => setRef('container', e);

const makeGrid = ({ usersList, getRef }) => {
  if (!usersList) return false;
  const countBrillInRow = getCountBrillInRow({ getRef, usersList });
  if (countBrillInRow) {
    compose(
      setDataOffsetAttribute({ getRef, usersList }),
      getDelimitersAmount,
    )(countBrillInRow);
  }
  return true;
};

const setPreloaderHandler = ({ setIsPreloader }) => () => {
  setIsPreloader(true);
  setTimeout(() => setIsPreloader(false), 800);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRefs(),
  withState('isPreloader', 'setIsPreloader', false),
  withStateHandlers(() => ({ coWorkers: [] }), {
    setRefToArrayStateHandler: setRefToArray,
  }),
  withHandlers({
    onSetRefContainer: onSetRefContainerHandler,
    setPreloader: setPreloaderHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { isCoWorkersTabOpen } = this.props;
      this.props.getCoworkersRequest();
      if (isCoWorkersTabOpen) {
        makeGrid(this.props);
        window.addEventListener('resize', () => makeGrid(this.props));
      }
    },
    componentDidUpdate(prevProps) {
      const { usersList, setPreloader, leftSideBarStatus } = this.props;
      if (prevProps.leftSideBarStatus !== leftSideBarStatus) {
        setPreloader();
      }
      if (usersList !== prevProps.usersList) {
        makeGrid(this.props);
      }
    },
    componentWillUnmount() {
      window.removeEventListener('resize', () => makeGrid(this.props));
    },
  }),
);
export default enhance(CoWorker);
