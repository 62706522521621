import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const MessageElements = ({ content }) => (
  <>{content}</>
);

MessageElements.propTypes = {
  content: PropTypes.oneOfType([PropTypes.instanceOf(Array)]).isRequired,
};

export default React.memo(MessageElements);
