import * as yup from 'yup';

const rules = yup.object().shape({
  first_name: yup
    .string()
    .required({
      message: 'First name required',
    }),
  last_name: yup
    .string()
    .required({
      message: 'Last name required',
    }),
  username: yup
    .string()
    .required({
      message: 'User name required',
    }),
  email: yup
    .string()
    .email({ message: 'Email is not valid' })
    .required({
      message: 'User name required',
    }),
});

export default rules;
