import React from 'react';
import {
  Tabs, TabPanel, TabList, Tab,
} from 'react-tabs';
import PropTypes from 'prop-types';

import {
  TaskAbout,
  History,
  TimeEntry,
  Comments,
  AttachedFiles,
  AttachFilesModal,
} from './components';
import { ScrollWrapper } from '../../components';
import './style.sass';

const Task = ({
  task, t, onScrollSetRef, scrollElement, isOnline,
}) => (
  <ScrollWrapper refCustom={onScrollSetRef}>
    <div className="task">
      <TaskAbout task={task} />
      <Tabs selectedTabClassName="task-navigation__link--active">
        <div className="task-navigation">
          <TabList className="task-navigation__list">
            <Tab className="task-navigation__link">{t('Time Entry')}</Tab>
            <Tab className="task-navigation__link" disabled={!isOnline} title={!isOnline ? 'Only in online version' : ''}>{t('Attached Files')}</Tab>
            <Tab className="task-navigation__link" disabled={!isOnline} title={!isOnline ? 'Only in online version' : ''}>{t('Comments')}</Tab>
            {/* <Tab className="task-navigation__link">{t('History')}</Tab> */}
          </TabList>
        </div>
        <TabPanel>
          <TimeEntry />
        </TabPanel>
        <TabPanel>
          {
            isOnline ? (<AttachedFiles />) : (<b className="task__only-online">This tab is available only in online version</b>)
          }
        </TabPanel>
        <TabPanel>
          {
            isOnline ? (<Comments scrollElement={scrollElement} />) : (<b className="task__only-online">This tab is available only in online version</b>)
          }
        </TabPanel>
        <TabPanel>
          {
            isOnline ? (<History />) : (<b className="task__only-online">This tab is available only in online version</b>)
          }
        </TabPanel>
      </Tabs>
    </div>
    <AttachFilesModal />
  </ScrollWrapper>
);

Task.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
  onScrollSetRef: PropTypes.func.isRequired,
  scrollElement: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default Task;
