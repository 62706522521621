import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection } from '../../../../components';
import './style.sass';

const TabWrapperMobile = ({
  title, children, active, setActiveTitle, onKeyPress, t,
}) => (
  <div
    role="button"
    onKeyPress={onKeyPress}
    tabIndex="0"
    onClick={() => setActiveTitle(title)}
    className={`tab-wrapper-mobile ${active === title ? 'tab-wrapper-mobile--active' : ''}`}
  >
    <div className="tab-wrapper-mobile__header">
      <h3 className="tab-wrapper-mobile__title">{title}</h3>
      <ButtonsCollection.ButtonIcons className="tab-wrapper-mobile__toggle-button" title={t('Toggle tab')}>
        <span className="icon-expand-arrow-dn" />
      </ButtonsCollection.ButtonIcons>
    </div>
    <div className="tab-wrapper-mobile__body">
      {children}
    </div>
  </div>
);

TabWrapperMobile.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.string,
  t: PropTypes.func.isRequired,
  setActiveTitle: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  children: PropTypes.node.isRequired,
};
TabWrapperMobile.defaultProps = {
  onKeyPress: () => {},
  active: '',
};

export default TabWrapperMobile;
