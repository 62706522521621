import {
  cond, curry, identity, T,
} from 'ramda';
import { Either } from 'ramda-fantasy';
import PropTypes from 'prop-types';
import { compose, withHandlers, withStateHandlers } from 'recompose';

import { KEYS } from '../../constants/ui';

const { Right } = Either;

const onChangeContentStateHandler = () => messageContent => ({ messageContent });
const onClearContentStateHandler = () => () => ({ messageContent: '' });

const isContentIsNotEmpty = cond([
  [T, Right],
]);

const submitMessage = Either.either(identity);

const onClickSaveContentHandler = curry((onSaveAction, props) => (element) => {
  element.preventDefault();
  submitMessage(() => onSaveAction(props), isContentIsNotEmpty(props.messageContent));
});

const onKeyDownSaveContentHandler = curry((onSaveAction, props) => (event) => {
  if (event.keyCode === KEYS.ENTER && !event.shiftKey) {
    event.preventDefault();
    submitMessage(() => onSaveAction(props), isContentIsNotEmpty(props.messageContent));
  }
});

const WithMessageContent = ({ value, onSaveMessageContent }) => compose(
  withStateHandlers(props => ({ messageContent: value(props) }), ({
    onSetMessageContent: onChangeContentStateHandler,
    onClearMessageContent: onClearContentStateHandler,
  })),
  withHandlers({
    onKeyDownSaveContent: onKeyDownSaveContentHandler(onSaveMessageContent),
    onClickSaveContent: onClickSaveContentHandler(onSaveMessageContent),
  }),
);

WithMessageContent.propTypes = {
  sortBy: PropTypes.func.isRequired,
};

export default WithMessageContent;
