import {
  compose, lifecycle, withStateHandlers, hoistStatics, withHandlers,
} from 'recompose';
import { path } from 'ramda';
import withRefs from '../../../../utils/enchancers/withRefs';
import Search from './search';


const debounceFunc = (func, time) => {
  if (path(['intervalDebounce', 'clearInterval'], window)) window.intervalDebounce.clearInterval();
  window.intervalDebounce = setTimeout(() => { func(); window.intervalDebounce = null; }, time);
};

const onChangeWatcherHandler = ({ onChange }) => (e) => {
  e.persist();
  debounceFunc(() => onChange(e), 2000);
};

const enhance = compose(
  withRefs(),
  withStateHandlers(() => ({ inFocus: false }), {
    handlerInFocus: () => val => ({
      inFocus: val,
    }),
  }),
  withHandlers({
    onChangeWatcher: onChangeWatcherHandler,
  }),
  lifecycle({
    componentDidMount() {
      const el = this.props.getRef('input');
      el.addEventListener('focus', () => {
        this.props.handlerInFocus(true);
      });
      el.addEventListener('blur', () => {
        this.props.handlerInFocus(false);
      });
      el.addEventListener('search', (element) => {
        this.props.onSearch(element);
      });
    },
  }),
);
export default hoistStatics(enhance)(Search);
