import { handleActions } from 'redux-actions';
import {
  last, pathOr, propOr, uniq, concat,
} from 'ramda';

import types, {
  GROUP_CHANNELS, DIRECT_CHANNELS, YOU_BELONG_TO_CHANNELS, YOU_CAN_JOIN_CHANNELS,
} from './types';
import {
  mergeInState, mergeIn, mergeDeepInState,
} from '../../utils/helpers/stateHelpers';

import {
  mergeDeep,
  mergeDeepByPath,
  mergeDeepByPathWithConcatReverse,
} from '../../utils/helpers/ramdaStateHelpers';
import { camelCase } from '../../utils/helpers/commonHelpers';
import { CHANNEL_TYPES } from '../../constants/messenger';

const initialState = ({
  channels: null,
  messages: {},
  totalUnreadCount: 0,
  loadedChannels: {},
});

const reducer = handleActions({
  [types.SET_MEMBER_TO_CHANNEL]: mergeDeepByPath(['channels', 'groupChannels'],
    ({ payload }) => ({
      entities: {
        ...{ [payload.data.result.channel.id]: payload.data.result.channel },
      },
    })),
  [types.DELETE_MEMBER_FROM_CHANNEL]: mergeDeepByPath(['channels', 'groupChannels'],
    ({ payload }) => ({
      entities: {
        ...{ [payload.data.result.channel.id]: payload.data.result.channel },
      },
    })),
  [types.UPDATE_GROUP_CHANNEL]: mergeDeepByPath(['channels', 'groupChannels'],
    ({ payload }, state) => ({
      result: [...state.channels.groupChannels.result, payload.data.result.channel.id],
      entities: {
        ...{ [payload.data.result.channel.id]: payload.data.result.channel },
      },
    })),
  [types.SET_GROUP_CHANNELS]: mergeInState('channels', ({ payload: { data, count } }, { entities }) => ({
    [camelCase(GROUP_CHANNELS)]: {
      entities: { ...propOr({}, [GROUP_CHANNELS], entities), ...data.entities[GROUP_CHANNELS] },
      result: data.result,
      count,
    },
  })),
  [types.UPDATE_GROUP_CHANNELS]: mergeDeepInState('channels', ({ payload: { data, count } }) => ({
    [camelCase(GROUP_CHANNELS)]: {
      entities: data.entities[GROUP_CHANNELS],
      result: data.result,
      count,
    },
  })),
  [types.SET_DIRECT_CHANNELS]: mergeInState('channels', ({ payload: { data, count } }, { entities }) => ({
    [camelCase(DIRECT_CHANNELS)]: {
      entities: { ...propOr({}, [DIRECT_CHANNELS], entities), ...data.entities[DIRECT_CHANNELS] },
      result: data.result,
      count,
    },
  })),
  [types.SET_MATCHED_DIRECT_CHANNELS]: mergeDeepByPath(['channels', camelCase(DIRECT_CHANNELS)],
    ({ payload: { data, count } }, { entities }) => ({
      entities: { ...data.entities[DIRECT_CHANNELS], ...propOr({}, DIRECT_CHANNELS, entities) },
      matched: data.result,
      matchedCount: count,
    })),
  [types.SET_YOU_BELONG_TO_CHANNELS]: mergeDeepByPath(['channels', 'groupChannels'],
    ({ payload: { data, count } }, { channels }) => ({
      entities: { ...channels.groupChannels.entities, ...data.entities[GROUP_CHANNELS] },
      [camelCase(YOU_BELONG_TO_CHANNELS)]: data.result,
      [`${camelCase(YOU_BELONG_TO_CHANNELS)}Count`]: count,
    })),
  [types.SET_YOU_CAN_JOIN_CHANNELS]: mergeDeepByPath(['channels', 'groupChannels'],
    ({ payload: { data, count } }, { channels }) => ({
      entities: { ...channels.groupChannels.entities, ...data.entities[GROUP_CHANNELS] },
      [camelCase(YOU_CAN_JOIN_CHANNELS)]: data.result,
      [`${camelCase(YOU_CAN_JOIN_CHANNELS)}Count`]: count,
    })),
  [types.SET_LOADED_CHANNEL]: mergeDeepInState('loadedChannels', action => ({
    [action.payload.channelId]: { ...action.payload },
  })),
  [types.CHANGE_CHANNEL]: mergeIn(action => action.payload),
  [types.SET_MORE_MESSAGES]: mergeDeepByPathWithConcatReverse(['messages'], action => ({
    [action.payload.channelId]: ({
      ...action.payload,
    }),
  })),
  [types.SET_NEW_MESSAGE]: mergeDeep((action, state) => ({
    messages: {
      [action.payload.channel]: ({
        ...action.payload,
        result: pathOr(false, ['payload', 'result'], action) ? uniq(concat(pathOr([], ['messages', action.payload.channel, 'result'], state), pathOr([], ['payload', 'result'], action))) : [],
      }),
    },
  })),
  [types.SET_LATEST_MESSAGES]: mergeDeepByPath(['messages'], action => ({
    [action.payload.channelId]: ({
      ...action.payload,
      totalCount: action.payload.totalCount,
      hasMore: action.payload.hasMore,
      lastMessageId: last(action.payload.result),
    }),
  })),
  [types.SET_LAST_MESSAGE_ID]: mergeDeepInState('messages', action => ({
    [action.payload.channelId]: ({
      ...action.payload,
    }),
  })),
  [types.SET_COUNT_LOADED_MESSAGE]: mergeDeepInState('messages', action => ({
    [action.payload.channelId]: ({
      ...action.payload,
    }),
  })),
  [types.SET_TIMESTAMPS]: (state, action) => ({
    ...state,
    messages: ({
      ...state.messages,
      [action.payload.channelId]: ({
        ...state.messages[action.payload.channelId],
        timestamps: { ...action.payload.timestamps },
      }),
    }),
  }),
  [types.SET_UNREAD_COUNT]: mergeDeepByPath(['channels'], ({ payload }) => ({
    [camelCase(CHANNEL_TYPES[payload.channel.type])]: {
      entities: {
        [payload.channel.id]: { unread_count: payload.unread_count },
      },
    },
  })),

  [types.SET_LAST_MESSAGE_TIME]: mergeInState('messages', (action, state) => ({
    [action.payload.channelId]: ({
      ...state.messages[action.payload.channelId],
      ...action.payload,
      lastMessageTime: action.payload.lastMessageTime,
    }),
  })),
  [types.SET_ACTIVE_CHANNEL]: mergeIn(action => ({
    activeChannel: { ...action.payload },
  })),
  [types.SET_CHANNEL]: mergeDeepByPath(['channels'], ({ payload }) => ({
    [camelCase(CHANNEL_TYPES[payload.channel.type])]: {
      entities: {
        ...payload.channels,
        ...{ [payload.data.result.channel.id]: payload.data.result.channel },
      },
    },
  })),
  [types.SET_PIN_MESSAGE]: mergeDeepInState('pinnedMessages', ({ payload }) => ({
    [payload.channelId]: payload.message,
  })),
  [types.SET_PINNED_MESSAGES]: mergeInState('pinnedMessages', (action) => {
    const { meta, ...payload } = action.payload;
    return {
      [meta.channelId]: {
        ...payload.data,
      },
    };
  }),
  [types.DELETE_PIN_MESSAGE]: mergeInState('pinnedMessages', (action) => {
    const { channelId, ...payload } = action.payload;
    return {
      [channelId]: {
        ...payload.data,
      },
    };
  }),
  [types.UPDATE_MESSAGE]: mergeDeepByPath(['messages'], ({ payload }) => {
    const { channel_id, ts, ...props } = payload;
    return {
      [channel_id]: {
        entities: {
          messages: {
            [ts]: { ...props },
          },
        },
      },
    };
  }),
  [types.SET_TOTAL_UNREAD_COUNT]: mergeDeep(({ payload }) => (
    {
      totalUnreadCount: propOr(0, 'count', payload),
    }
  )),
  [types.DELETE_MESSAGE]: (state, {
    payload: {
      updatedList, updatedEntities, channel_id, isNotDissCount,
    },
  }) => ({
    ...state,
    messages: {
      [channel_id]: ({
        ...state.messages[channel_id],
        entities: { messages: updatedEntities },
        result: updatedList,
        count: state.messages[channel_id].count - (isNotDissCount ? 0 : 1),
      }),
    },
  }),
},
initialState);

export default reducer;
