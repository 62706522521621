import { values, mapObjIndexed } from 'ramda';

import { t } from './translateHelpers';

const getValidationError = (errors) => {
  try {
    const { message, params } = values(errors)[0];
    return t('validation', message, { ...params });
  } catch (e) {
    return '';
  }
};

const makeFormData = (data) => {
  const formData = new FormData();
  mapObjIndexed((value, key) => formData.append(key, value), data);
  return formData;
};

const getFile = e => e.currentTarget.files[0];

export {
  getValidationError,
  makeFormData,
  getFile,
};
