import {
  compose, lifecycle, withContext, withHandlers, withProps, withState,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { equals, propOr } from 'ramda';
import { withRouter } from 'react-router';

import Project from './project';
import { projectActions } from '../../state/project';
import { closeModal, openModal } from '../../state/ui/actions';
import { withWindowWidth } from '../../utils/enchancers';
import PROJECT_TABS from '../../constants/tabNavigation';
import { uiActions } from '../../state/ui';
import withPermission from '../../utils/enchancers/withPemission';
import { MODULES } from '../../constants/pemissions';
import * as projectsSelectors from '../../state/projects/selectors';

const mapStateToProps = (state, ownProps) => ({
  project: projectsSelectors.getProject(state)(ownProps.match.params.id),
});

const mapDispatchToProps = ({
  getProject: projectActions.getProjectRequest,
  setCloseModal: closeModal,
  setOpenModal: openModal,
  openModal,
  getSprintsRequest: projectActions.getSprintsRequest,
  onOpenCreateTaskModal: () => openModal('createTaskModal'),
  clearProjectData: projectActions.clearProjectData,
  setCurrentPage: uiActions.setPage,
});


const onDeleteTaskModalHandler = ({
  setOpenModal,
  setSelectedSort, setSelectedTaskId, setIsRedirectToProject, setSelectedMeta,
}) => (id, sort, meta) => {
  setSelectedTaskId(id);
  setOpenModal('deleteTaskModal');
  setSelectedSort(sort);
  setSelectedMeta(meta);
  setIsRedirectToProject(propOr(false, 'isRedirectToProject', meta));
};

const onEditTaskModalHandler = ({
  setOpenModal,
  setSelectedSort, setSelectedTaskId, setSelectedMeta,
}) => (id, sort, meta) => {
  setSelectedTaskId(id);
  setOpenModal('editTaskModal');
  setSelectedSort(sort);
  setSelectedMeta(meta);
};

const onEditNotesModalHandler = ({
  setOpenModal,
}) => () => setOpenModal('createNoteModal');

const enhancer = compose(
  withPermission([MODULES.PROJECTS]),
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'tasks', 'projects']),
  withRouter,
  withWindowWidth(),
  withState('activeTab', 'setActiveTab', PROJECT_TABS.TASKS),
  withState('selectedTaskId', 'setSelectedTaskId', 0),
  withState('selectedSprintId', 'setSelectedSprintId', 0),
  withState('sprintCRUDMeta', 'setSprintCRUDMeta', {}),
  withState('selectedCategoryId', 'setSelectedCategoryId', 0),
  withState('selectedSort', 'setSelectedSort', {}),
  withState('selectedMeta', 'setSelectedMeta', {}),
  withState('isRedirectToProject', 'setIsRedirectToProject', false),
  withProps(({ activeTab, match: { params: { id } } }) => {
    const isTabIsEqual = equals(activeTab);
    return {
      isTabNotesActive: isTabIsEqual(PROJECT_TABS.NOTES),
      isTabTasksActive: isTabIsEqual(PROJECT_TABS.TASKS),
      isTabTaskActive: isTabIsEqual(PROJECT_TABS.TASK),
      isTabRepositoryActive: isTabIsEqual(PROJECT_TABS.REPOSITORY),
      id: Number(id),
    };
  }),
  withHandlers({
    onDeleteTaskModal: onDeleteTaskModalHandler,
    onEditTaskModal: onEditTaskModalHandler,
    onEditNotesModal: onEditNotesModalHandler,
  }),
  withContext({
    selectedTaskId: PropTypes.number,
    onDeleteTaskModal: PropTypes.func,
    onEditTaskModal: PropTypes.func,
    projectId: PropTypes.number,
    selectedSort: PropTypes.instanceOf(Object),
    setActiveTab: PropTypes.func.isRequired,
    isRedirectToProject: PropTypes.bool.isRequired,
    setIsRedirectToProject: PropTypes.func.isRequired,
    setSelectedSprintId: PropTypes.func.isRequired,
    selectedSprintId: PropTypes.number.isRequired,
    setSelectedCategoryId: PropTypes.func.isRequired,
    selectedCategoryId: PropTypes.number.isRequired,
    selectedMeta: PropTypes.instanceOf(Object).isRequired,
    sprintCRUDMeta: PropTypes.instanceOf(Object).isRequired,
    setSprintCRUDMeta: PropTypes.func.isRequired,
  }, props => ({
    selectedTaskId: props.selectedTaskId,
    selectedSort: props.selectedSort,
    onDeleteTaskModal: props.onDeleteTaskModal,
    onEditTaskModal: props.onEditTaskModal,
    projectId: Number(props.match.params.id),
    setActiveTab: props.setActiveTab,
    setIsRedirectToProject: props.setIsRedirectToProject,
    isRedirectToProject: props.isRedirectToProject,
    selectedSprintId: props.selectedSprintId,
    setSelectedSprintId: props.setSelectedSprintId,
    selectedCategoryId: props.selectedCategoryId,
    setSelectedCategoryId: props.setSelectedCategoryId,
    selectedMeta: props.selectedMeta,
    sprintCRUDMeta: props.sprintCRUDMeta,
    setSprintCRUDMeta: props.setSprintCRUDMeta,
  })),
  lifecycle({
    componentDidMount() {
      const {
        getProject, getSprintsRequest,
        history: { location: { pathname } },
        match: { params: { id } },
      } = this.props;
      getProject({ projectId: id });
      const isAgendaBoard = /\/projects\/\d*\/agenda-board\//gi.test(pathname);
      getSprintsRequest({ projectId: id }, { isAgendaBoard });
      this.props.setCurrentPage({
        mainTitle: this.props.project.title,
        page: this.props.project.title,
      });
    },
    componentDidUpdate({ project }) {
      if (project.id !== this.props.project.id) {
        this.props.setCurrentPage({
          mainTitle: this.props.project.title,
          page: this.props.project.title,
        });
      }
    },
  }),
);

export default enhancer(Project);
