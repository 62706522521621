import { handleActions } from 'redux-actions';
import types from './types';
import {
  mergeDeep,
  mergeIn,
} from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_CUSTOMERS]: mergeIn(({
    payload: {
      data: { entities: { customers }, result },
      count, hasMore,
    },
  }) => ({
    entities: { ...customers },
    result,
    count,
    hasMore,
  })),
  [types.UPDATE_CUSTOMER]: mergeDeep(({ payload: { user } }) => ({
    entities: { [user.id]: user },
  })),
  [types.SET_CUSTOMER]: mergeDeep(({ payload: { user } }) => ({
    entities: { [user.id]: user },
  })),
  [types.SET_CUSTOMERS_LIST_AUTOCOMPLETE]: mergeIn(
    ({ payload: { users: usersAutocomplete } }, { customers }) => ({
      ...customers,
      usersAutocomplete,
    }),
  ),
}, {
});

export default reducer;
