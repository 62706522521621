import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';

import rules from './rules';
import TimeEntryForm from './timeEntryForm';
import { taskActions, taskSelectors } from '../../../../../state/task';
import { getSecondsFromTimeString } from '../../../../../utils/helpers/dateHelpers';

const setInitialValues = () => ({
  time: '',
  date: new Date(),
});

const mapStateToProps = state => ({
  isPending: taskSelectors.getAddTimeEntryRequestPending(state),
});

const mapDispatchToProps = {
  addTimeEntry: taskActions.addTimeEntryRequest,
};

const sendForm = ({ date, time }, props) => {
  const { match: { params: { id, taskId } }, addTimeEntry } = props.props;
  addTimeEntry({
    projectId: id, taskId, date: moment(date).format('YYYY-MM-DD'), time: getSecondsFromTimeString(time),
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withNamespaces(['tasks']),
  withFormik({
    mapPropsToValues: setInitialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: sendForm,
  }),
);
export default enhance(TimeEntryForm);
