import { compose, lifecycle } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import DirectsContainer from './directsContainer';
import { setPage } from '../../../../state/ui/actions';
import { SUB_TITLES, TITLES_CONSTANTS } from '../../../../constants/titles';

const mapDispatchToProps = ({
  setCurrentPage: setPage,
});

const enhancer = compose(
  connect(null, mapDispatchToProps),
  withNamespaces(['common']),
  lifecycle({
    componentDidMount() {
      this.props.setCurrentPage({
        page: TITLES_CONSTANTS.PAGE_MESSENGER,
        subTitle: SUB_TITLES.PAGE_MESSENGER_DIRECTS,
      });
    },
  }),
);
export default enhancer(DirectsContainer);
