import React from 'react';
import * as PropTypes from 'prop-types';

import { compose, lifecycle, withState } from 'recompose';
import { Preloader } from '../../components';
import { PRELOADER_DURATION } from '../../constants/ui';

const PreLoaderWhileLoading = ({
  delay = PRELOADER_DURATION,
  isLoading,
  fullScreen = false,
  dimension,
  alignContainerCenter = false,
  onAction,
  className = '',
}) => (BaseComponent) => {
  const WrapperComponent = (props) => {
    const { displayUI } = props;
    return displayUI ? <BaseComponent {...props} />
      : (
        <Preloader
          fullScreen={fullScreen}
          className={className}
          alignContainerCenter={alignContainerCenter}
          dimension={dimension}
          duration={delay}
        />
      );
  };
  const enhancer = compose(
    withState('displayUI', 'setDisplayUI', false),
    withState('isInitialPreloader', 'setIsInitialPreloader', true),
    withState('timer', 'setTimer', null),
    lifecycle({
      componentDidMount() {
        if (isLoading) {
          if (!isLoading(this.props)) {
            this.props.setDisplayUI(true);
          }
        }
      },
      componentDidUpdate() {
        const {
          setTimer, isInitialPreloader, setIsInitialPreloader, setDisplayUI, displayUI,
        } = this.props;
        if (onAction) {
          const isOnActionPreloader = onAction(this.props);
          if (isOnActionPreloader && displayUI && !isInitialPreloader) {
            setDisplayUI(false);
            setTimer(
              setTimeout(() => {
                setDisplayUI(true);
              }, delay),
            );
          }
        }
        if (isLoading) {
          if (!isLoading(this.props) && isInitialPreloader) {
            setIsInitialPreloader(false);
            setTimer(
              setTimeout(() => {
                setDisplayUI(true);
              }, delay),
            );
          }
        }
      },
      componentWillUnmount() {
        clearTimeout(this.props.timer);
      },
    }),
  );

  WrapperComponent.propTypes = {
    displayUI: PropTypes.bool.isRequired,
    setDisplayUI: PropTypes.func.isRequired,
  };

  return enhancer(WrapperComponent);
};


export default PreLoaderWhileLoading;
