import {
  branch,
  compose, lifecycle,
  renderNothing,
} from 'recompose';
import { connect } from 'react-redux';

import EditProjectModal from './editProjectModal';
import { withCRUDProject } from '../../../../../utils/enchancers';
import rules from '../rules';

import { projectsActions, projectsSelectors } from '../../../../../state/projects';
import { projectSelectors } from '../../../../../state/project';
import withOnline from '../../../../../utils/enchancers/withOnline';
import { customersActions, customersSelectors } from '../../../../../state/customers';

const mapStateToProps = (state, { selectedProjectId }) => ({
  project: projectsSelectors.getProject(state)(selectedProjectId),
  isProjectRequestEnded: projectSelectors.getProjectRequestPending(state),
  getCustomer: customersSelectors.getCustomer(state),
});

const mapDispatchToProps = ({
  editProject: projectsActions.editProjectRequest,
  getCustomerRequest: customersActions.getCustomerRequest,
});

const enhance = compose(
  withOnline,
  connect(mapStateToProps, mapDispatchToProps),
  branch(
    ({ isProjectRequestEnded }) => isProjectRequestEnded,
    renderNothing,
  ),
  lifecycle({
    componentDidMount() {
      this.props.project.customer_id && this.props.getCustomerRequest({ customerId: this.props.project.customer_id });
    },
  }),
  branch(({ project, getCustomer }) => !getCustomer(project.customer_id).id && project.customer_id, renderNothing),
  withCRUDProject({
    rules,
    data: ({ project }) => project,
    modalName: 'editProjectModal',
    onSubmit: (formValues, {
      resetForm, props: {
        editProject, setCloseModal, selectedProjectId, initialValues,
      },
    }) => {
      editProject({
        ...formValues,
        id: selectedProjectId,
        type: Number(formValues.type),
        members: formValues.members.map(member => member.value),
      }, {
        callbacks: {
          success: () => {
            setCloseModal('editProjectModal');
            resetForm(initialValues);
          },
        },
      });
    },
  }),
);
export default enhance(EditProjectModal);
