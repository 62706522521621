import {
  compose, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { tasks } from '../../../../constants';

import ImportantTasks from './importantTasks';
import { projectActions, projectSelectors } from '../../../../state/project';

const mapStateToProps = state => ({
  result: projectSelectors.getImportantTasksList(state),
  entities: projectSelectors.getImportantTasksEntities(state),
  isPending: projectSelectors.getImportantTasksRequestPending(state),
});

const mapDispatchToProps = {
  getImportantTasks: projectActions.getImportantTasksRequest,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { projectId, getImportantTasks } = this.props;
      getImportantTasks({
        projectId,
        where: ([{ field: 'priority', value: tasks.PRIORITY.VERY_HIGH.value }]),
        limit: 10,
        offset: 0,
      });
    },
  }),
);
export default enhance(ImportantTasks);
