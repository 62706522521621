import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import * as components from '../components';
import { toTitle } from '../../../utils/helpers/commonHelpers';
import '../style.sass';

const DefaultAutoComplete = ({
  error, onBlur, touched, onFocus, name, t, ...props
}) => (
  <div className={`auto-complete ${error && touched ? 'auto-complete--has-error' : ''}`}>
    <Select
      components={components}
      className="auto-complete__field"
      classNamePrefix="auto-complete"
      {...props}
      onBlur={onBlur}
      onFocus={onFocus}
    />
    {error.length !== 0 && touched && (
      <span className="auto-complete__error field__error-text">
        {t(error, { key: toTitle(name) })}
      </span>
    )}
  </div>
);

DefaultAutoComplete.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  onBlur: PropTypes.func,
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  touched: PropTypes.bool,
};

DefaultAutoComplete.defaultProps = {
  error: '',
  onFocus: () => {},
  onBlur: () => {},
  touched: false,
};

export default DefaultAutoComplete;
