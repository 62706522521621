import { handleActions } from 'redux-actions';
import { uniq } from 'ramda';

import types from './types';
import {
  mergeByPath,
  mergeDeep,
  mergeDeepByPath,
  mergeIn,
} from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_PROJECTS_LIST]: mergeIn(({
    payload: {
      data: { result, entities },
      hasMore,
      count,
    },
  }) => ({
    entities: {
      ...entities,
      hasMore,
      count,
    },
    result: {
      projects: result,
    },
  })),
  [types.UPDATE_PROJECT]: mergeDeep(({ payload: { project } }, { result, pendingResult }) => ({
    entities: {
      projects: { [project.id]: project },
    },
    result: {
      projects: uniq([...result.projects, project.id]),
    },
    pendingResult: uniq([...pendingResult, project.id]),
  })),
  [types.SET_NEW_PROJECT]: mergeDeep(({ payload: { project } }, { result, pendingResult }) => ({
    entities: {
      projects: { [project.id]: project },
    },
    result: {
      projects: uniq([...result.projects, project.id]),
    },
    pendingResult: uniq([...pendingResult, project.id]),
  })),
  [types.SET_MORE_PROJECTS]: mergeDeep(({
    payload: {
      data: { result, entities },
      hasMore,
      count,
    },
  }, state) => ({
    entities: {
      ...entities,
      hasMore,
      count,
    },
    result: {
      projects: [
        ...state.result,
        ...result,
      ],
    },
  })),
  [types.SET_IMPORTANT_TASKS_PROJECTS]: mergeDeep(({
    payload: {
      data: { result, entities },
      hasMore,
      count,
    },
  }) => ({
    entities: {
      ...entities,
      hasMore,
      count,
    },
    result: {
      importantTasks: result,
    },
  })),
  [types.SET_PROJECTS_LIST_MENU]: mergeDeep(({
    payload: {
      data: { entities, result },
      count,
      hasMore,
    },
  }, state) => ({
    entities: {
      ...entities,
      hasMoreMenu: hasMore,
      countMenu: count,
    },
    result: {
      menu: uniq([...state.result.menu, ...result]),
    },
  })),
  [types.SET_PROJECTS_FAVORITE]: mergeDeepByPath(['entities', 'projects'],
    ({ payload: { meta: { id } } }) => ({ [id]: { favorite: 1 } })),
  [types.RESET_PROJECTS_LIST]: mergeIn(() => ({ result: [] })),
  [types.REMOVE_PROJECTS_FAVORITE]: mergeDeepByPath(['entities', 'projects'],
    ({ payload: { meta: { id } } }) => ({ [id]: { favorite: 0 } })),
  [types.SET_PROJECTS_SUMMARY]: mergeByPath(['summary'], ({ payload: { data } }) => data),
  [types.REORDER_PROJECT]: mergeDeep(({ payload }) => ({
    result: {
      projects: payload,
    },
  })),
}, {
  entities: {},
  result: {
    menu: [],
    projects: [],
  },
  pendingResult: [],
  viewStyle: '',
});

export default reducer;
