import React from 'react';

let isDisabled = false;

const onPendingCall = (click, disabled) => (e) => {
  if (!isDisabled && disabled === false) {
    isDisabled = true;
    click && click(e);
    setTimeout(() => {
      isDisabled = false;
    }, 1400);
  }
};

const Button = ({
  children, className, type, disabled, ...props
}) => (
  <button
    type={type}
    onClick={onPendingCall(props.onClick, disabled)}
    className={`btn ${className}`}
    disabled={isDisabled || disabled}
    {...props}
  >
    {children}
  </button>
);

Button.defaultProps = {
  type: 'button',
  className: '',
  children: 'Submit',
  disabled: false,
};

export default Button;
