import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import DroppableArea from '../droppableArea';

const ColumnBoard = ({
  agenda, statusId, isDragging, updateDroppable, isDropDisabled, windowWidth,
}) => (
  <div className="column-board">
    <div className="column-board__header">
      <h2 className="column-board__title">
        {agenda.title}
      </h2>
    </div>
    <DroppableArea
      isDropDisabled={isDropDisabled}
      tasks={agenda.tasks}
      statusId={statusId}
      isDragging={isDragging}
      updateDroppable={updateDroppable}
    />
  </div>
);

ColumnBoard.propTypes = {
  agenda: PropTypes.instanceOf(Object).isRequired,
  statusId: PropTypes.number.isRequired,
  isDragging: PropTypes.bool.isRequired,
  updateDroppable: PropTypes.number,
};

ColumnBoard.defaultProps = {
  updateDroppable: null,
};

export default ColumnBoard;
