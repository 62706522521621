import {
  compose, getContext, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { withRouter } from 'react-router';
import { closeModal } from '../../../../../../state/ui/actions';
import { getModal } from '../../../../../../state/ui/selectors';
import { taskActions, taskSelectors } from '../../../../../../state/task';
import DeleteTimeEntry from './deleteTimeEntry';
import { usersSelectors } from '../../../../../../state/users';
import { getFullName } from '../../../../../../utils/helpers/userHelpers';

const mapStateToProps = (state, { selectedTimeEntryId, match: { params: { taskId } } }) => ({
  isOpen: getModal(state)('deleteTimeEntry'),
  users: usersSelectors.getUsersEntities(state),
  timeEntry: taskSelectors.getTimeEntry(state)(selectedTimeEntryId, taskId),
});

const mapDispatchToProps = ({
  onCloseModal: () => closeModal('deleteTimeEntry'),
  deleteTimeEntry: taskActions.deleteTimeEntryRequest,
});

const onDeleteHandler = ({
  deleteTimeEntry, selectedTimeEntryId, onCloseModal, match: { params: { id, taskId } },
}) => () => {
  deleteTimeEntry({ timeEntryId: selectedTimeEntryId, taskId, projectId: id }, {
    callbacks: {
      success: onCloseModal,
    },
  });
};

const enhance = compose(
  withRouter,
  getContext({
    selectedTimeEntryId: PropTypes.number.isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ users, timeEntry }) => ({
    userName: getFullName(users[timeEntry.user_id]),
  })),
  withNamespaces(['common', 'tasks']),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);

export default enhance(DeleteTimeEntry);
