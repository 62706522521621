import React from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';

import './style.sass';

const Select = ({
  optionsList,
  name,
  className,
  onSelectOption,
  value,
  label,
  disabled,
  id,
  color,
}) => (
  <div className={`form-group ${className}`}>
    { label && (<label htmlFor={id} className="col-form-label">{label}</label>)}
    <select id={id} name={name} disabled={disabled} onChange={onSelectOption} className="form-control">
      {
        optionsList ? optionsList.map(option => (
          <option
            key={`name ${option.value}`}
            selected={equals(value, option.value)}
            value={option.value}
            style={{ color: option.color }}
          >
            {option.label}
          </option>
        )) : (
          <option value="0">Empty list</option>
        )
      }
    </select>
  </div>
);


Select.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  isToTop: PropTypes.bool.isRequired,
  optionsList: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onSelectOption: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  placeholder: PropTypes.string.isRequired,
  title: PropTypes.string,
  setRef: PropTypes.func.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  isRequired: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

Select.defaultProps = {
  error: '',
  title: '',
  value: '',
  touched: false,
};

export default Select;
