/* eslint-disable */
import React from 'react';
import List from './list';

const style = height => ({
  position: 'relative',
  overflow: 'hidden',
  minHeight: '100%',
  height,
});

const VirtualList = ({
 renderItem,
 offset,
 onSetScrollRef,
 sizeAndPositionManager,
 onScroll,
 onItemsRendered,
 height,
 forceUpdate,
 _forceUpdate,
 listRef,
 itemCount
}) => {
  const items = [];

  const { start, stop } = sizeAndPositionManager.getVisibleRange(offset, itemCount);
  const totalSize = sizeAndPositionManager.getTotalSize();

  for (let index = start; index <= stop; index++) {
    items.push(
      renderItem({
        index,
        style: sizeAndPositionManager.getSizeAndPositionForIndex(index),
      }),
    );
  }

  onItemsRendered({
    start: start,
    stop: stop,
    reset: sizeAndPositionManager.resetItem,
  });

  return <List
    sizeAndPositionManager={sizeAndPositionManager}
    height={height}
    ref={listRef}
    scrollRef={onSetScrollRef}
    forceUpdate={forceUpdate}
    _forceUpdate={_forceUpdate}
    onScroll={onScroll}
    items={items}
    style={style(totalSize)}
  />

};

export default VirtualList;
