import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { withWindowWidth } from '../../utils/enchancers';
import { openModal } from '../../state/ui/actions';
import MyTasks from './myTasks';

const mapDispatchToProps = {
  openModal,
};

const enhancer = compose(
  connect(null, mapDispatchToProps),
  withWindowWidth(),
  withNamespaces(['common', 'tasks', 'projects']),
);

export default enhancer(MyTasks);
