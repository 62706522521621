import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import ProjectSummary from './projectSummary';
import { projectSelectors } from '../../../../state/project';

const mapStateToProps = state => ({
  summary: projectSelectors.getProjectSummary(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withNamespaces(['tasks']),
);
export default enhance(ProjectSummary);
