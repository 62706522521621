const ROLE_TITLES = {
  1: 'Administrator',
  2: 'Buyer',
  3: 'Subscriber',
};


export {
  ROLE_TITLES,
};
