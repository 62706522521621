import React from 'react';
import PropTypes from 'prop-types';

import ResizeDetector from 'react-resize-detector';

import { MessageHistory, SendMessage, JoinToChannel } from './components';
import './style.sass';

const Chat = ({
  onSubmitMessage, setRef, messages, hasMore, isChannelLoaded,
  activeChannel, isScrollToBottom, setIsScrollToBottom, members, containerSize,
  containerWidth, countMessages, unreadIndex, setScrollToIndex, onResize,
  onTextAreaKeyDown, scrollToIndex, membersForMention, setOpenAttachFilesModal, setAttachedFiles, attachedFiles, isMember,
}) => (
  <div ref={e => setRef('container', e)} className="messenger">
    <MessageHistory
      activeChannel={activeChannel}
      hasMore={hasMore}
      isChannelLoaded={isChannelLoaded}
      unreadIndex={unreadIndex}
      isScrollToBottom={isScrollToBottom}
      setIsScrollToBottom={setIsScrollToBottom}
      height={containerSize}
      setScrollToIndex={setScrollToIndex}
      scrollToIndex={scrollToIndex}
      messagesCount={countMessages}
      containerWidth={containerWidth}
      messages={messages}
      members={members}
    />
    <div className="message-textArea" ref={e => setRef('fieldContainer', e)}>
      {
        isMember ? (
          <SendMessage
            className="message-textArea__textArea"
            setOpenAttachFilesModal={setOpenAttachFilesModal}
            onSubmit={onSubmitMessage}
            members={membersForMention}
            onKeyDown={onTextAreaKeyDown}
            setAttachedFiles={setAttachedFiles}
            attachedFiles={attachedFiles}
          />
        ) : (
          <JoinToChannel />
        )
      }
    </div>
    <ResizeDetector
      skipOnMount
      refreshMode="throttle"
      handleWidth
      onResize={onResize}
    />
  </div>
);

Chat.propTypes = {
  activeChannel: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.number,
  }).isRequired,
  isChannelLoaded: PropTypes.shape({
    id: PropTypes.number,
    loading: PropTypes.bool,
  }).isRequired,
  containerSize: PropTypes.number.isRequired,
  unreadIndex: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  members: PropTypes.objectOf(PropTypes.object).isRequired,
  membersForMention: PropTypes.objectOf(PropTypes.object).isRequired,
  countMessages: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isScrollToBottom: PropTypes.bool.isRequired,
  messages: PropTypes.arrayOf(PropTypes.number).isRequired,
  setIsScrollToBottom: PropTypes.func.isRequired,
  onSubmitMessage: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  onResize: PropTypes.func.isRequired,
  onTextAreaKeyDown: PropTypes.func.isRequired,
  scrollToIndex: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.number,
  ]).isRequired,
  setScrollToIndex: PropTypes.func.isRequired,
};

export default Chat;
