/* eslint-disable import/prefer-default-export */
import { compose } from 'recompose';
import {
  filter, isEmpty, propOr, values, length,
} from 'ramda';
import projectTypes from '../../../state/project/types';

import {
  deleteCategoryTask, deleteNote, deleteSprint, deleteProjectMember,
} from '../../helpers/projectHelpers/crudHelper';
import { uiActions } from '../../../state/ui';
import { projectActions, projectSelectors } from '../../../state/project';
import { projectsActions, projectsSelectors, projectsTypes } from '../../../state/projects';


const deleteProjectMemberMiddleware = ({ getState }) => next => (action) => {
  if (action.type === projectTypes.DELETE_PROJECT_MEMBER) {
    const { payload: { meta: { memberId } } } = action;
    deleteProjectMember(getState(), memberId, next);
  } else {
    next(action);
  }
};

const deleteCategoryMiddleware = ({ getState }) => next => (action) => {
  if (action.type === projectTypes.DELETE_CATEGORY_TASK) {
    const { payload: { category } } = action;
    deleteCategoryTask(getState(), category.id, next);
  } else {
    next(action);
  }
};

const deleteNoteMiddleware = ({ getState }) => next => (action) => {
  if (action.type === projectTypes.DELETE_NOTE) {
    const { payload: { document } } = action;
    deleteNote(getState(), document.id, next);
  } else {
    next(action);
  }
};

const setSprintsMiddleware = () => next => (action) => {
  if (action.type === projectTypes.SET_SPRINTS) {
    const { payload: { meta = {}, data: { entities } } } = action;
    const hasActiveSprint = compose(isEmpty, filter(sprint => sprint.status !== 0), values);
    if (meta.isAgendaBoard && hasActiveSprint(propOr([], 'sprints', entities))) next(uiActions.setPageNotFound(true));
    next(projectActions.setSprints(action.payload));
  } else {
    next(action);
  }
};

const deleteSprintMiddleware = ({ getState }) => next => (action) => {
  if (action.type === projectTypes.DELETE_SPRINT) {
    const { payload: { meta, sprint: { id, status, project_id } } } = action;
    const sprints = projectSelectors.getProjectSprintsTasks(getState())(project_id);
    const countActiveSprints = compose(length, filter(item => item.status !== 0), values)(sprints);
    if (meta.isAgendaBoard
      && status !== 0
      && countActiveSprints === 1) next(uiActions.setPageNotFound(true));
    deleteSprint(id, project_id, getState(), next);
  } else {
    next(action);
  }
};

const deleteProjectMiddleware = ({ getState }) => next => (action) => {
  if (action.type === projectsTypes.DELETE_PROJECT) {
    const { payload } = action;
    const projectsList = projectsSelectors.getProjectsList(getState());

    next(projectsActions.reorderProject(filter(id => id !== payload.id, projectsList)));
  } else {
    next(action);
  }
};


export {
  deleteCategoryMiddleware,
  deleteNoteMiddleware,
  deleteProjectMemberMiddleware,
  deleteSprintMiddleware,
  setSprintsMiddleware,
  deleteProjectMiddleware,
};
