import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ChannelsContainer, MessengerContainer, DirectsContainer, GroupChannels, DirectChannels,
} from './components';
import { Layouts } from '../../components';
import { LeftSideBar } from '../../containers';
import './style.sass';
import { InviteMembersModal } from './modals';

const Messenger = () => (
  <Layouts.Main className="page-messenger">
    <LeftSideBar>
      <GroupChannels />
      <DirectChannels />
    </LeftSideBar>
    <div className="main-container page-messenger__main-container">
      <div className="page height-full">
        <Switch>
          <Route exact path="/messenger/channels/" component={ChannelsContainer} />
          <Route exact path="/messenger/directs/" component={DirectsContainer} />
          <Route component={MessengerContainer} />
        </Switch>
      </div>
      <InviteMembersModal />
    </div>
  </Layouts.Main>
);

export default Messenger;
