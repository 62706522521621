import { compose, lifecycle } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import Profile from './profile';
import { TITLES_CONSTANTS } from '../../constants/titles';
import { uiActions } from '../../state/ui';
import withPermission from '../../utils/enchancers/withPemission';
import { MODULES } from '../../constants/pemissions';

const mapDispatchToProps = {
  setCurrentPage: uiActions.setPage,
};

const enhancer = compose(
  withPermission([MODULES.PROFILE]),
  connect(null, mapDispatchToProps),
  withNamespaces(['common']),
  lifecycle({
    componentDidMount() {
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.PAGE_PROFILE });
    },
  }),
);

export default enhancer(Profile);
