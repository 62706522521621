import React from 'react';
import PropTypes from 'prop-types';
import {
  pathOr, propOr, isEmpty,
} from 'ramda';

import {
  Avatar, UserListAvatar,
} from '../../../../components';
import './style.sass';
import { dateHelpers, userHelpers } from '../../../../utils/helpers';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';

const { getFullName } = userHelpers;

const Summary = ({
  task,
  done,
  status,
  onClickAvatar,
  displayAvatars,
  hiddenAvatars,
  deadline, started, priority, isDeadlineToday, assigneeUser, author,
}) => (
  <div className="summary-task">
    <h3 className="summary-task__title">Summary</h3>
    <ul className="summary-task__list">
      <li className="summary-task__item">
        <div className="summary-task__item-label">
          Status:
        </div>
        <div className="summary-task__item-value">
          {status.title}
        </div>
      </li>
      <li className="summary-task__item">
        <div className="summary-task__item-label">
          Priority:
        </div>
        <div className="summary-task__item-value">
          {priority}
        </div>
      </li>
      <li className="summary-task__item">
        <div className="summary-task__item-label">
          Started:
        </div>
        <div className="summary-task__item-value">{started}</div>
      </li>
      <li className="summary-task__item">
        <div className="summary-task__item-label">
          DeadLine:
        </div>
        <div className={`summary-task__item-value ${isDeadlineToday ? 'summary-task__item-value--deadline' : ''}`}>
          <span>{deadline}</span>
          {
            isDeadlineToday && (
            <svg
              width="16px"
              height="16px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g
                id="icon/fire/color"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="Group" transform="translate(6.000000, 2.000000)">
                  <path d="M3.79310345,0 C6.13793103,3.06896552 6.24137931,8.31034483 3.79310345,11.0344828 C3.13793103,10.2758621 2.96551724,9.31034483 2.75862069,8.27586207 C-2.37931034,11.9655172 0.206896552,20 6.55172414,20 C16.0689655,20 14.137931,4.06896552 3.79310345,0 Z" id="Path" fill="#DF3312" />
                  <path d="M6.93103448,12.1724138 C7.79310345,13.6896552 5.62068966,15.8965517 4.31034483,15.5862069 C4.48275862,16.5862069 5.37931034,17.3793103 6.44827586,17.3793103 C7.65517241,17.3793103 8.5862069,16.4137931 8.62068966,15.2068966 C8.68965517,13.8965517 8.24137931,12.1724138 6.93103448,12.1724138 Z" id="Path" fill="#FFEA00" />
                </g>
              </g>
            </svg>
            )
          }
        </div>
      </li>
      <li className="summary-task__item">
        <div className="summary-task__item-label">
          Estimate:
        </div>
        <div className="summary-task__item-value">{dateHelpers.getTimeStringBySeconds(propOr(0, 'estimated_time', task))}</div>
      </li>
      <li className="summary-task__item">
        <div className="summary-task__item-label">
          Spent time:
        </div>
        <div className="summary-task__item-value">{dateHelpers.getTimeStringBySeconds(propOr(0, 'spent_time', task))}</div>
      </li>
      <li className="summary-task__item">
        <div className="summary-task__item-label">
          Done:
        </div>
        <div className="summary-task__item-value">
          <span className="summary-task__item-value-percent-progress">{`${done}%`}</span>
        </div>
      </li>
    </ul>
    <div className="summary-task__author">
      <h3 className="summary-task__author-title">Author</h3>
      <div className="row-author">
        <Avatar
          src={getImageUrl(pathOr(null, ['avatar'], author))}
          alt={getFullName(author)}
        />
        <div className="summary-task__author-wrap">
          <a href="/" className="summary-task__author-name">
            {getFullName(author)}
          </a>
          <span className="summary-task__author-position" />
        </div>
      </div>
    </div>
    <div className="summary-task__author">
      <h3 className="summary-task__author-title">Assignee</h3>
      {
        !isEmpty(assigneeUser) ? (
          <div className="row">
            <Avatar

              src={getImageUrl(pathOr(null, ['avatar'], assigneeUser))}
              alt={getFullName(assigneeUser)}
            />
            <div className="summary-task__author-wrap">
              <a href="/" className="summary-task__author-name">
                {getFullName(assigneeUser)}
              </a>
              <span className="summary-task__author-position" />
            </div>
          </div>
        ) : (<span className="files-attached__info-text">No assignee</span>)
      }
    </div>
    <div className="summary-task__author">
      <h3 className="summary-task__author-title">Watcher</h3>
      {
        !isEmpty(displayAvatars) ? (
          <UserListAvatar
            onClick={onClickAvatar}
            displayAvatars={displayAvatars}
            hiddenAvatars={hiddenAvatars}
          />
        ) : (<span className="files-attached__info-text">No watchers</span>)
      }
    </div>
  </div>
);

Summary.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
  author: PropTypes.instanceOf(Object).isRequired,
  assigneeUser: PropTypes.instanceOf(Object),
  isDeadlineToday: PropTypes.bool.isRequired,
  priority: PropTypes.string.isRequired,
  hiddenAvatars: PropTypes.instanceOf(Object).isRequired,
  displayAvatars: PropTypes.instanceOf(Object).isRequired,
  onClickAvatar: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  deadline: PropTypes.string.isRequired,
  started: PropTypes.string.isRequired,
  done: PropTypes.number.isRequired,
};

Summary.defaultProps = {
  assigneeUser: {},
};

export default Summary;
