import {
  branch, compose, renderComponent, renderNothing,
} from 'recompose';
import React from 'react';

import { connect } from 'react-redux';
import {
  pathOr, equals, always, cond, includes, values,
} from 'ramda';

import { Redirect } from 'react-router';
import { userSelectors } from '../../state/user';

const mapStateToProps = state => ({
  myRole: userSelectors.getUserData(state).role_id,
});

const withMyPermissions = compose(
  connect(state => ({
    myPermissions: userSelectors.getMyPermissions(state),
  })),
);

const withPermission = module => compose(
  withMyPermissions,
  branch(({ myPermissions }) => !myPermissions, renderNothing),
  connect(mapStateToProps),
  branch(({ myPermissions }) => !pathOr(
    cond([
      [equals(0), always(false)],
      [includes(1, values(myPermissions)), always(true)],
      // todo: Sometimes backkend response not fully list permissions for module ,
      //  here but if user can UPDATE, DELETE, CREATE element I think he also can read it =)
    ]), [module, 'read'], myPermissions,
  ), renderComponent(() => <Redirect to="/404" />)),
);

export default withPermission;
