import React from 'react';
import PropTypes from 'prop-types';

import { MembersView } from '../../../../components';
import './style.sass';

const Members = ({
  membersList, onDeleteMember, onInviteMember, t, isPendingInvite, isPendingDelete,
}) => (
  <>
    <MembersView
      titleMain="Channel"
      titleItem="member"
      isPendingInvite={isPendingInvite}
      isPendingDelete={isPendingDelete}
      onInviteMember={onInviteMember}
      onDeleteMember={onDeleteMember}
      membersList={membersList}
      t={t}
    />
  </>
);

Members.propTypes = {
  membersList: PropTypes.arrayOf(PropTypes.number).isRequired,
  onInviteMember: PropTypes.func.isRequired,
  onDeleteMember: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isPendingDelete: PropTypes.bool.isRequired,
  isPendingInvite: PropTypes.bool.isRequired,
};

export default Members;
