/* eslint-disable import/prefer-default-export */
import { filter, compose } from 'ramda';

import { materialsActions, materialsSelectors, materialsTypes } from '../../../state/materials';

const deleteMaterialMiddleware = ({ getState }) => next => (action) => {
  if (action.type === materialsTypes.DELETE_MATERIAL) {
    const newResult = compose(filter(id => id !== action.payload.material.id),
      materialsSelectors.getMaterialsResult, getState)();
    next(materialsActions.reorderMaterials(newResult));
  } else {
    next(action);
  }
};

export {
  deleteMaterialMiddleware,
};
