import {
  compose, lifecycle, withContext, withHandlers, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Customers from './customers';
import Customer from './customer';
import { openModal } from '../../state/ui/actions';
import { TITLES_CONSTANTS } from '../../constants/titles';
import { uiActions } from '../../state/ui';
import { customersActions } from '../../state/customers';
import withPermission from '../../utils/enchancers/withPemission';
import { MODULES } from '../../constants/pemissions';

const mapDispatchToProps = ({
  setOpenModal: openModal,
  setCurrentPage: uiActions.setPage,
  onAddCustomer: () => openModal('addCustomer'),
  onExportCustomers: () => customersActions.exportCustomersRequest(),
});

const onEditCustomerModalHandler = ({ setSelectedCustomerId, setOpenModal }) => (userId) => {
  setOpenModal('editCustomer');
  setSelectedCustomerId(userId);
};

const onDeleteCustomerModalHandler = ({ setSelectedCustomerId, setOpenModal }) => (userId) => {
  setOpenModal('deleteCustomer');
  setSelectedCustomerId(userId);
};

const onImportCustomersModalHandler = ({ setOpenModal }) => () => {
  setOpenModal('importCustomers');
};

const enhancer = compose(
  withPermission([MODULES.CUSTOMERS]),
  connect(null, mapDispatchToProps),
  withState('selectedCustomerId', 'setSelectedCustomerId', 0),
  withState('sort', 'setSelectedSort', {}),
  withHandlers({
    onEditCustomerModal: onEditCustomerModalHandler,
    onDeleteCustomerModal: onDeleteCustomerModalHandler,
    onImportCustomersModal: onImportCustomersModalHandler,
  }),
  withContext({
    selectedCustomerId: PropTypes.number,
    onEditCustomerModal: PropTypes.func,
    onDeleteCustomerModal: PropTypes.func,
    setSelectedSort: PropTypes.func,
  }, props => ({
    selectedCustomerId: props.selectedCustomerId,
    onEditCustomerModal: props.onEditCustomerModal,
    onDeleteCustomerModal: props.onDeleteCustomerModal,
    setSelectedSort: props.setSelectedSort,
  })),
  lifecycle({
    componentDidMount() {
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.PAGE_CUSTOMERS });
    },
  }),
);

const CustomerPage = enhancer(Customer);

export default enhancer(Customers);

export {
  CustomerPage,
};
