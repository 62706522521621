import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { ModalWindow } from '../index';

const ConfirmModal = ({
  onClose, onAction, isPending, title, isOpen, actionTitle, className, children,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onClose}
    className={`confirm-modal ${className}`}
    modalName={title}
    title={title}
  >
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="text-align--left">
            <span className="confirm-modal__title title-md">
              {children}
            </span>
          </div>
        </div>
      </div>
      <div className="confirm-modal__button-row">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={onAction}
          disabled={isPending}
        >
          { actionTitle }
        </button>
        <button
          type="button"
          className="button--cancel"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </>
  </ModalWindow>
);

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  actionTitle: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

ConfirmModal.defaultProps = {
  className: '',
  actionTitle: 'Yes',
  children: 'Are you sure?',
};

export default ConfirmModal;
