import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection, PendingControl } from '../../../../../../components';
import './style.sass';
import PermissionElement from '../../../../../../components/permissionElement';
import { ACTIONS, MODULES } from '../../../../../../constants/pemissions';

const MaterialItem = ({
  isOpen,
  toggleIsOpen,
  materialId,
  material,
  onEdit,
  onDelete,
  onResetOfflineDelete,
  onResetOfflineEdit,
  onResetOfflineCreate,
}) => (
  <div
    className={`materials-list__table-row ${isOpen ? 'materials-list__table-row--active' : ''}`}
  >
    <PendingControl
      onResetOfflineDelete={onResetOfflineDelete}
      onResetOfflineEdit={onResetOfflineEdit}
      onResetOfflineCreate={onResetOfflineCreate}
      isPending={material.isPending}
      isUpdating={material.isUpdating}
      isDeleting={material.isDeleting}
    />
    <div className="materials-list__table-col"><h3 className="text--cut">{material.title}</h3></div>
    <div className="materials-list__table-col materials-list__table-col--count"><i>{material.quantity}</i></div>
    <div
      className="materials-list__table-col materials-list__table-col--description"
      dangerouslySetInnerHTML={{ __html: `<span>${material.description || ''}</span>` }}
    />
    <div className="materials-list__table-col">
      <div className="materials-list__controls-group">
        <ButtonsCollection.ButtonIcons
          className="materials-list__show-more-button"
          title={`${isOpen ? 'Hide information' : 'Show information'}`}
          onClick={() => toggleIsOpen(materialId)}
        >
          <div className="s-18 icon-angle-down" />
        </ButtonsCollection.ButtonIcons>

        <PermissionElement module={MODULES.MATERIALS} action={ACTIONS.UPDATE}>
          <ButtonsCollection.ButtonIcons
            className="materials-list__edit-button"
            title="Edit material"
            onClick={onEdit}
          >
            <span className="icon-pencil" />
          </ButtonsCollection.ButtonIcons>
        </PermissionElement>

        <PermissionElement module={MODULES.MATERIALS} action={ACTIONS.DELETE}>
          <ButtonsCollection.ButtonIcons
            className="materials-list__delete-button"
            onClick={onDelete}
            title="Delete material"
          >
            <span className="icon-trash" />
          </ButtonsCollection.ButtonIcons>
        </PermissionElement>
      </div>
    </div>
  </div>
);
MaterialItem.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleIsOpen: PropTypes.func.isRequired,
  materialId: PropTypes.number.isRequired,
  material: PropTypes.instanceOf(Object).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
export default MaterialItem;
