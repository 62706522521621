import { compose, withStateHandlers } from 'recompose';
import { withWindowWidth } from '../../utils/enchancers';
import MobileFiltersContainer from './mobileFiltersContainer';

const changeContainerStatus = () => val => ({ isOpen: val });
const enhance = compose(
  withWindowWidth(),
  withStateHandlers(
    () => ({ isOpen: false }), {
      changeContainerStatusStateHandler: changeContainerStatus,
    },
  ),
);
export default enhance(MobileFiltersContainer);
