import { compose, withProps, getContext } from 'recompose';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  ifElse, pathOr, prop, always,
} from 'ramda';
import UserItem from './userItem';
import { usersSelectors } from '../../../../state/users';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';

const mapStateToProps = (state, { userId }) => ({
  user: usersSelectors.getUser(state)(userId),
});


const enhance = compose(
  connect(mapStateToProps),
  withProps(({ user }) => {
    const avatarUserId = pathOr(null, ['avatar', 'id'], user);
    return {
      userName: getFullName(user),
      userAvatar: avatarUserId ? getImageUrl(avatarUserId) : '',
      userPhone: ifElse(prop('phone'), prop('phone'), always('-'))(user),
      userPosition: ifElse(prop('position'), prop('position'), always('-'))(user),
      userRole: pathOr('-', ['role', 'name'], user),
    };
  }),
  getContext({
    onEditUserModal: PropTypes.func,
    onDeleteUserModal: PropTypes.func,
  }),
);

export default enhance(UserItem);
