import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { pathOr, prop } from 'ramda';
import UserControls from './userControls';
import { userActions, userSelectors } from '../../../../state/user';
import { usersSelectors } from '../../../../state/users';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';

const mapStateToProps = (state, { userId }) => ({
  user: usersSelectors.getUser(state)(userId),
  userBoot: userSelectors.getUserData(state),
});

const mapDispatchToProps = ({
  onLogOut: userActions.logout,
  push,
});

const withOwnUserObject = compose(
  connect(state => ({ userId: compose(prop('id'), userSelectors.getUserData)(state) }), mapDispatchToProps),
);
const enhance = compose(
  withOwnUserObject,
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ user, userBoot }) => {
    const avatarUserId = pathOr(null, ['avatar', 'id'], userBoot);
    return {
      userName: getFullName(userBoot),
      userAvatar: avatarUserId ? getImageUrl(avatarUserId) : '',
      onlineStatus: pathOr(0, ['is_online'], user),
    };
  }),
);

export default enhance(UserControls);
