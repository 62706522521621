import React from 'react';
import PropTypes from 'prop-types';

import { Layouts, PermissionElement } from '../../components';
import { LeftSideBar, Header, MaterialsContainer } from '../../containers';
import './style.sass';
import { AddMaterial, DeleteMaterial, EditMaterial } from './components';
import { ACTIONS, MODULES } from '../../constants/pemissions';

const Materials = ({ onOpenAddMaterialModal, selectedMaterialId }) => (
  <Layouts.Main className="page-project">
    <LeftSideBar />
    <div className="main-container page-project__main-container">
      <Header>
        <div className="w-100 row-group d-flex mb-0">
          <div className="col-lg-12 pr-0 d-flex align-items-center justify-content-end">
            <PermissionElement module={MODULES.MATERIALS} action={ACTIONS.CREATE}>
              <button type="button" className="btn btn-primary" onClick={onOpenAddMaterialModal}>
Add new
                <span className="hidden-sm">material</span>
              </button>
            </PermissionElement>
          </div>
        </div>
      </Header>
      <div className="page page-materials  has-sidebar-left height-full">
        <MaterialsContainer />
      </div>
    </div>
    <AddMaterial />
    <EditMaterial selectedMaterialId={selectedMaterialId} />
    <DeleteMaterial selectedMaterialId={selectedMaterialId} />
  </Layouts.Main>
);

Materials.propTypes = {
  onOpenAddMaterialModal: PropTypes.func.isRequired,
  selectedMaterialId: PropTypes.number.isRequired,
};

export default Materials;
