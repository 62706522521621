import {
  compose, lifecycle, withStateHandlers, hoistStatics, defaultProps, withProps, withHandlers,
} from 'recompose';

import withRefs from '../../utils/enchancers/withRefs';
import ShowMore from './showMore';

const cssPropertyValueToNumber = propertyValue => parseInt(
  propertyValue
    .substr(0, propertyValue.length - 2),
  10,
);

const getLineHeight = element => cssPropertyValueToNumber(getComputedStyle(element).lineHeight);

const makeShowMore = ({
  setMinHeight, setMaxHeight, getRef, countLines, minHeight,
}) => {
  const bodyElement = getRef('body');
  const lineHeight = getLineHeight(bodyElement);
  const textHeight = bodyElement.offsetHeight;
  setMinHeight(lineHeight * countLines);
  if (textHeight !== minHeight) setMaxHeight(textHeight);
};

const getHeightByIsOpenHandler = ({
  maxHeight,
  minHeight,
  isOpen,
}) => () => (isOpen ? maxHeight : minHeight);


const toggleStatusStateHandler = ({ isOpen }) => (value = !isOpen) => ({ isOpen: value });
const setMaxHeightStateHandler = () => value => ({ maxHeight: value });
const setMinHeightStateHandler = () => value => ({ minHeight: value });

const enhance = compose(
  withRefs(),
  defaultProps({
    countLines: 3,
  }),
  withStateHandlers(() => ({ isOpen: false, maxHeight: 0, minHeight: 0 }), {
    toggleStatus: toggleStatusStateHandler,
    setMaxHeight: setMaxHeightStateHandler,
    setMinHeight: setMinHeightStateHandler,
  }),
  withHandlers({
    getHeightByIsOpen: getHeightByIsOpenHandler,
  }),
  withProps(({ maxHeight, getHeightByIsOpen, minHeight }) => ({
    height: maxHeight !== 0 ? getHeightByIsOpen() : 'initial',
    isMore: maxHeight > minHeight,
  })),
  lifecycle({
    componentDidMount() {
      makeShowMore(this.props);
    },
    componentDidUpdate({ children }) {
      if (children !== this.props.children) {
        makeShowMore(this.props);
      }
    },
  }),
);
export default hoistStatics(enhance)(ShowMore);
