import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection } from '../../../../components';

import './style.sass';

const ToggleButtonLeftSideNav = ({ onToggle }) => (
  <>
    <ButtonsCollection.ButtonIcons
      className="close-left-aside"
      title="Close aside"
      onClick={onToggle}
    >
      <span className="icon-cross" />
    </ButtonsCollection.ButtonIcons>
    <button title="Toggle left sidebar" type="button" className="toggle-left-aside" onClick={onToggle}>
      <span className="icon-expand-arrow-right" />
    </button>
  </>
);

ToggleButtonLeftSideNav.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

export default ToggleButtonLeftSideNav;
