import { createSelector } from 'reselect/lib/index';
import {
  pathOr, identity, path, memoizeWith,
} from 'ramda';

const getProjects = createSelector(
  pathOr({}, ['projects', 'entities', 'projects']), identity,
);

const getProjectsList = createSelector(
  pathOr([], ['projects', 'result', 'projects']), identity,
);

const getProjectsListHasMore = createSelector(
  pathOr(true, ['projects', 'entities', 'hasMore']), identity,
);

const getProjectsListMenu = createSelector(
  pathOr([], ['projects', 'result', 'menu']), identity,
);

const getProjectsListMenuHasMore = createSelector(
  pathOr(true, ['projects', 'entities', 'hasMoreMenu']), identity,
);
const getProjectsListMenuCount = createSelector(
  pathOr(0, ['projects', 'entities', 'countMenu']), identity,
);

const getProjectsListCount = createSelector(
  pathOr(0, ['projects', 'entities', 'count']), identity,
);

const getProject = createSelector(
  state => path(['projects', 'entities', 'projects'], state),
  projects => memoizeWith(identity, projectId => pathOr({}, [projectId], projects)),
);

const getAddProjectErrors = createSelector(
  pathOr([], ['errors', 'addProject']), identity,
);

const getProjectsViewStyle = createSelector(
  pathOr('', ['projects', 'viewStyle']), identity,
);
const getProjectsListMenuPending = createSelector(
  pathOr(false, ['pending', 'getProjectsListMenuRequest']), identity,
);

const getProjectsListPending = createSelector(
  pathOr(false, ['pending', 'getProjectsList']), identity,
);

const getProjectsSummary = createSelector(
  pathOr({}, ['projects', 'summary']), identity,
);


const getImportantTasksRequestPending = createSelector(pathOr(false, ['pending', 'getImportantTasksProjectsRequest']), identity);

const getImportantTasksList = createSelector(pathOr([], ['projects', 'result', 'importantTasks']), identity);

const getImportantTasksEntities = createSelector(pathOr({}, ['projects', 'entities', 'importantTasks']), identity);


export {
  getProjects,
  getProject,
  getProjectsList,
  getProjectsListHasMore,
  getProjectsListCount,
  getAddProjectErrors,
  getProjectsViewStyle,
  getProjectsListMenu,
  getProjectsListMenuHasMore,
  getProjectsListMenuCount,
  getProjectsListMenuPending,
  getProjectsListPending,
  getProjectsSummary,
  getImportantTasksRequestPending,
  getImportantTasksList,
  getImportantTasksEntities,
};
