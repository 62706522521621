import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

import { withFormik } from 'formik';
import { pathOr, propOr } from 'ramda';
import Profile from './profile';
import { userSelectors, userActions } from '../../state/user';
import rules from './rules';
import { preloaderWhileLoading } from '../../utils/enchancers';
import { PRELOADER_DIMENSION } from '../../constants/ui';

const mapStateToProps = state => ({
  user: userSelectors.getUserData(state),
  isPending: userSelectors.getPendingUpdateUserProfileRequest(state),
});

const mapDispatchToProps = {
  updateUserProfileRequest: userActions.updateUserProfileRequest,
};

const formSend = (formValues, props) => {
  props.props.updateUserProfileRequest(formValues);
};

const initialValues = ({
  user,
}) => ({
  ...user,
  avatar: propOr(null, 'id', user.avatar),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ user }) => ({
    userAvatar: pathOr('', ['avatar', 'id'], user),
  })),
  withFormik({
    mapPropsToValues: initialValues,
    validate: ({ password, confirmPassword }) => {
      if (password !== confirmPassword) {
        return {
          confirmPassword: {
            message: 'Wrong password',
          },
        };
      }
    },
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: formSend,
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
);

export default enhance(Profile);
