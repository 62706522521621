import React from 'react';
import PropTypes from 'prop-types';

import {
  Avatar, OnlineStatusLabel, ButtonsCollection,
} from '../../../index';

const MemberItem = ({
  user, onDelete, t, userAvatar, userName,
}) => (
  <li className="project-members__item">
    <Avatar src={userAvatar} alt={userName}>
      <OnlineStatusLabel status={user.is_online} />
    </Avatar>
    <button type="button" className="project-members__link">
      {`${user.first_name} ${user.last_name}`}
    </button>
    <ButtonsCollection.ButtonIcons
      className="project-members__button-remove"
      onClick={onDelete}
      title={t('Remove member')}
    >
      <span className="icon-close" />
    </ButtonsCollection.ButtonIcons>
  </li>
);

MemberItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  userName: PropTypes.string.isRequired,
  userAvatar: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default MemberItem;
