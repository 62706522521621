/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, BrillMask, OnlineStatusLabel } from '../../../../../../components';
import './style.sass';
import { GLOBAL_MENTION_ID } from '../../../../../../constants/messenger';

const MentionItem = ({
 onClick, isActive, user, itemId,
}) => (
  <li
    role="button"
    data-mentionId={user.id}
    onClick={onClick}
    className={`drop-down-mentions__member ${isActive ? 'drop-down-mentions__member--active' : ''}`}
  >
      <Avatar src={user.userAvatar}  alt={user.fullName} />
    <button type="button" className="drop-down-mentions__link">
      { user.username }
    </button>
  </li>
);

MentionItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.object).isRequired,
  isActive: PropTypes.bool.isRequired,
  itemId: PropTypes.number.isRequired,
};

export default MentionItem;
