/* eslint-disable react/prop-types */
import React from 'react';
import { is, propOr } from 'ramda';
import PropTypes from 'prop-types';

import DropDownItem from './components';
import './style.sass';
import { getItem } from '../../utils/helpers/commonHelpers';

const renderCurrentValue = (label, currentItem, list, placeholder) => {
  const isLabelString = is(String, label);
  if (isLabelString) {
    return (
      <>
        <span>
          { label }
          <span className="drop-down__current-value">
            { propOr(null, 'label', getItem('id', currentItem, list)) || placeholder}
          </span>
        </span>
        <i className="icon-down" />
      </>
    );
  }
  return label;
};

const DropDown = ({
  label,
  list,
  currentItem,
  isOpen,
  onChangeValue,
  name,
  className,
  toggleDropDown,
  positionElement,
  onReset,
  setRef,
  placeholder,
  itemLabel,
  onResetValue,
  isMulti,
}) => (
  <div
    className={`drop-down${isOpen ? ' open' : ''}
     ${className}
     ${positionElement ? ' drop-down--right' : ''} ${isMulti ? 'drop-down__multi' : ''}`}
    ref={e => setRef('dropDown', e)}
  >
    <div
      className="drop-down__head"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        toggleDropDown(!isOpen);
      }}
      onKeyPress={() => toggleDropDown(!isOpen)}
      tabIndex="0"
      role="button"
    >
      { renderCurrentValue(label, currentItem, list, placeholder) }
    </div>
    <div
      className="drop-down__body"
      ref={e => setRef('dropDownBody', e)}
    >
      <ul>
        { (placeholder && onReset) && (
        <li>
          <button
            type="button"
            onClick={onResetValue}
            className="btn"
          >
            { placeholder }
          </button>
        </li>
        )}
        {
          list.map(item => (
            !isMulti ? (
              <DropDownItem
                key={`dropdown - ${item.id} ${name}`}
                onChangeValue={onChangeValue}
                name={name}
                item={item}
              />
            ) : (
              <DropDownItem
                key={`dropdown - ${item.id} ${name}`}
                onChangeValue={onChangeValue}
                name={name}
                itemLabel={itemLabel}
                isMulti
                item={item}
              />
            )
          ))
        }
      </ul>
    </div>
  </div>
);

DropDown.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  name: PropTypes.string,
  currentItem: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  positionElement: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  // placeholder: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  onResetValue: PropTypes.func.isRequired,
  toggleDropDown: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setRef: PropTypes.func.isRequired,
};
DropDown.defaultProps = {
  currentItem: 1,
  name: '',
  label: '',
  className: '',
  isMulti: false,
};
export default DropDown;
