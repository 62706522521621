import * as yup from 'yup';

const rules = yup.object().shape({
  title: yup
    .string()
    .required({
      message: 'Title required',
    }),
  type: yup
    .number()
    .required({
      message: 'Type required',
    }),
  members: yup
    .array(),
  description: yup
    .string(),
  status: yup
    .number(),
  image: yup
    .string()
    .nullable(),
});

export default rules;
