const addTimeEntryResponseSchema = data => ({
  timeEntry: {
    id: new Date().getTime(),
    ...data,
    isPending: true,
  },
  meta: data.meta,
}
);
const deleteTimeEntryResponseSchema = data => ({
  timeEntry: {
    ...data,
    isDeleting: true,
  },
  meta: data.meta,
}
);

const updateTimeEntryResponseSchema = data => ({
  timeEntry: {
    ...data,
    isUpdating: true,
  },
  meta: data.meta,
}
);

export {
  addTimeEntryResponseSchema,
  deleteTimeEntryResponseSchema,
  updateTimeEntryResponseSchema,
};
