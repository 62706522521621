/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';

const myStatisticsSchema = [new schema.Entity('statistics', {
  activityPerDay: [new schema.Entity('activityPerDay')],
})];
const activityPerDaySchema = [new schema.Entity('activityPerDay')];
const activityPerMonthSchema = [new schema.Entity('activityPerMonth')];

export {
  myStatisticsSchema,
  activityPerDaySchema,
  activityPerMonthSchema,
};
