import {
  compose, withStateHandlers, defaultProps, withHandlers,
} from 'recompose';

import ToggleButton from './toggleButton';

const changeButton = ({ toggleStatusStateHandler, onChange, status }) => () => {
  toggleStatusStateHandler();
  onChange({ status: !status });
};
const toggleStatus = ({ status }) => () => ({ status: !status });

const enhance = compose(
  defaultProps({
    onChange: () => {},
  }),
  withStateHandlers(({ defaultStatus }) => ({
    status: defaultStatus,
  }), {
    toggleStatusStateHandler: toggleStatus,
  }),
  withHandlers({
    changeButtonHandler: changeButton,
  }),
);
export default enhance(ToggleButton);
