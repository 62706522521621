import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { Link } from 'react-router-dom';

import {
  FormsCollection, Layouts,
} from '../../../components';

import './style.sass';
import u5Image from '../../../assets/img/dummy/u5.png';

const JoinYourTeam = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  errors,
}) => (
  <Layouts.Auth>
    <main>
      <div id="primary" className="p-t-b-100 height-full ">
        <div className="container">
          <div className="form-row">
            <div className="col-lg-4 mx-md-auto">
              <div className="text-center">
                <img src={u5Image} alt="" />
                <h3 className="mt-2">Join your team</h3>
              </div>
              <FormsCollection.FormWrapper onSubmit={handleSubmit}>
                <FormsCollection.Input
                  className="form-group has-icon"
                  classInput="form-control form-control-lg"
                  name="firstName"
                  error={path(['firstName', 'message'], errors)}
                  value={values.firstName}
                  placeholder="First name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <i className="icon-user" />
                </FormsCollection.Input>
                <FormsCollection.Input
                  className="form-group has-icon"
                  classInput="form-control form-control-lg"
                  name="lastName"
                  error={path(['lastName', 'message'], errors)}
                  value={values.lastName}
                  placeholder="Last name"
                  type="text"
                  onChange={handleChange}
                >
                  <i className="icon-user" />
                </FormsCollection.Input>
                <FormsCollection.Input
                  className="form-group has-icon"
                  classInput="form-control form-control-lg"
                  name="username"
                  error={path(['username', 'message'], errors)}
                  value={values.username}
                  placeholder="Username"
                  type="text"
                  onChange={handleChange}
                >
                  <i className="icon-user-secret" />
                </FormsCollection.Input>
                <FormsCollection.Input
                  className="form-group has-icon"
                  classInput="form-control form-control-lg"
                  name="password"
                  error={path(['password', 'message'], errors)}
                  value={values.password}
                  placeholder="Password"
                  type="password"
                  onChange={handleChange}
                >
                  <i className="icon-lock" />
                </FormsCollection.Input>
                <FormsCollection.Input
                  className="form-group has-icon"
                  classInput="form-control form-control-lg"
                  name="passwordConfirm"
                  error={path(['passwordConfirm', 'message'], errors)}
                  value={values.passwordConfirm}
                  placeholder="Password confirm"
                  type="password"
                  onChange={handleChange}
                >
                  <i className="icon-lock" />
                </FormsCollection.Input>
                <button type="submit" className="btn btn-success btn-lg btn-block">Join</button>
                <div className="row">
                  <div className="col-lg-12">
                    <Link to="/" className="forget-pass">
                      You already have an account?
                    </Link>
                  </div>
                </div>
              </FormsCollection.FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layouts.Auth>
);

JoinYourTeam.propTypes = {
  errors: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
    username: PropTypes.bool,
    password: PropTypes.bool,
  }).isRequired,
  authNotification: PropTypes.shape({
    firstName: PropTypes.object,
    lastName: PropTypes.object,
    username: PropTypes.object,
    password: PropTypes.object,
  }),
};

JoinYourTeam.defaultProps = {
  authNotification: {},
};

export default JoinYourTeam;
