import { compose, getContext } from 'recompose';
import { prop } from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Grid from './grid';

import { withSorting } from '../../../../utils/enchancers';
import { getOppositeValue } from '../../../../utils/helpers/commonHelpers';
import { projectActions } from '../../../../state/project';

const mapDispatchToProps = ({
  sortProjectTasks: projectActions.sortProjectTasksRequest,
});

const enhance = compose(
  connect(null, mapDispatchToProps),
  withNamespaces(['tasks']),
  getContext({
    projectId: PropTypes.number,
    onSetUrlParam: PropTypes.func,
    getUrlParam: PropTypes.func,
  }),
  withSorting({
    selector: props => props.getUrlParams(),
    values: ({
      order: ({ getUrlParam, name }) => getUrlParam(['grid', name, 'order'], 'asc'),
      sort: ({ getUrlParam, name }) => getUrlParam(['grid', name, 'sort'], 'id'),
    }),
    onSortBy: ({
      order,
      name,
      sortProjectTasks,
      projectId,
      isSprint,
      onSetUrlParam,
      id,
    }) => (dataSorting) => {
      const sortBy = prop('sortBy', dataSorting);
      const newOrdering = getOppositeValue('asc', 'desc', order);
      onSetUrlParam({ grid: ({ [name]: ({ sort: sortBy, order: newOrdering }) }) });
      sortProjectTasks({
        projectId,
        where: ([{ field: 'sprint_id', value: isSprint ? id : 'null' }]),
        sortBy,
        order: newOrdering,
      }, { sprintId: id, isSprint });
    },
  }),
);
export default enhance(Grid);
