import { createSelector } from 'reselect';
import {
  pathOr,
  identity,
  memoizeWith,
  path,
  prop,
  propEq,
  find,
  compose,
  values,
  filter,
  always,
  T,
  cond,
  sortBy,
  findLast,
  curry,
} from 'ramda';

import { isNotNil } from 'ramda-extension';
import * as messenger from '../../constants/messenger';
import { getUsersEntities } from '../users/selectors';
import { isHasMemberId } from '../../utils/helpers/mentionHelpers/lookup';
import { STATUS } from '../../constants/ui';
import { sortUserByOnlineStatus } from '../../utils/helpers/userHelpers/status';
import { getChannel } from '../../utils/helpers/messengerHelpers/channelHelpers';

const messagesSelector = createSelector(
  state => path(['messenger', 'messages'], state),
  identity,
);

const channelsSelector = createSelector(
  state => path(['messenger', 'channels'], state),
  identity,
);

const pendingWhenOffline = curry((func, state) => (window.navigator.onLine ? func(state) : false));

const getMessages = createSelector(
  messagesSelector,
  messages => memoizeWith(identity, channelId => prop('entities', messages[channelId])),
);

const getMessage = createSelector(
  messagesSelector,
  messages => memoizeWith(identity, (messageId, channelId) => pathOr(null, [channelId, 'entities', 'messages', messageId], messages)),
);

const getMessageList = createSelector(
  messagesSelector,
  messages => memoizeWith(identity, channelId => prop('result', messages[channelId])),
);

const getMessageDays = createSelector(
  messagesSelector,
  messages => memoizeWith(identity, channelId => pathOr({}, ['timestamps'], messages[channelId])),
);

const getCountLoadedMessages = createSelector(
  messagesSelector,
  messages => memoizeWith(identity, channelId => pathOr(null, ['count'], messages[channelId])),
);

const getHasMoreMessages = createSelector(
  messagesSelector,
  messages => memoizeWith(identity, channelId => prop('hasMore', messages[channelId])),
);

const getChannelById = createSelector(
  channelsSelector,
  channels => memoizeWith(identity, channelId => getChannel(channelId)(channels)),
);

const getLoadedChannels = createSelector(
  state => path(['messenger', 'loadedChannels'], state),
  loadedChannels => memoizeWith(identity, channelId => pathOr(null, [channelId], loadedChannels)),
);

const getLastMessageTime = createSelector(
  messagesSelector,
  messages => memoizeWith(identity, channelId => pathOr(null, ['lastMessageTime'], messages[channelId])),
);

const getActiveChannel = createSelector(pathOr(null, ['messenger', 'activeChannel']), identity);

const getGroupChannels = createSelector(
  pathOr({}, ['messenger', 'channels', 'groupChannels', 'entities']),
  identity,
);

const getGroupChannelsList = createSelector(
  pathOr([], ['messenger', 'channels', 'groupChannels', 'result']),
  identity,
);

const getGroupChannelsCount = createSelector(
  pathOr(0, ['messenger', 'channels', 'groupChannels', 'count']),
  identity,
);

const getDirectChannels = createSelector(
  pathOr(false, ['messenger', 'channels', 'directChannels', 'entities']),
  identity,
);

const getDirectChannelsList = createSelector(
  pathOr([], ['messenger', 'channels', 'directChannels', 'result']),
  identity,
);

const getDirectChannelsCount = createSelector(
  pathOr(0, ['messenger', 'channels', 'directChannels', 'count']),
  identity,
);

const getMatchedDirectChannelsList = createSelector(
  pathOr([], ['messenger', 'channels', 'directChannels', 'matched']),
  identity,
);

const getMatchedDirectChannelsCount = createSelector(
  pathOr(0, ['messenger', 'channels', 'directChannels', 'matchedCount']),
  identity,
);

const getYouCanJoinChannelsList = createSelector(
  pathOr([], ['messenger', 'channels', 'groupChannels', 'youCanJoinChannels']),
  identity,
);

const getYouCanJoinChannelsCount = createSelector(
  pathOr(0, ['messenger', 'channels', 'groupChannels', 'youCanJoinChannelsCount']),
  identity,
);

const getYouBelongToChannelsList = createSelector(
  pathOr([], ['messenger', 'channels', 'groupChannels', 'youBelongToChannels']),
  identity,
);

const getYouBelongToChannelsCount = createSelector(
  pathOr(0, ['messenger', 'channels', 'groupChannels', 'youBelongToChannelsCount']),
  identity,
);

const getDirectChannelId = createSelector(
  getDirectChannels,
  channels => memoizeWith(identity, userId => compose(
    prop('id'),
    find(propEq('user_id', userId)),
    values,
  )(channels)),
);

const getActiveChannelEntity = createSelector(
  (state) => {
    if (!getActiveChannel(state)) return {};
    const { type, id } = getActiveChannel(state);
    if (type === messenger.GROUP_CHANNEL
      || type === messenger.PRIVATE_CHANNEL
      || type === messenger.GENERAL_CHANNEL) {
      return pathOr({}, ['messenger', 'channels', 'groupChannels', 'entities', id], state);
    }
    return pathOr({}, ['messenger', 'channels', 'directChannels', 'entities', id], state);
  },
  identity,
);

const getMembersEntities = createSelector(
  pathOr({}, ['messenger', 'channels', 'groupChannels', 'members']),
  identity,
);

const getMembers = createSelector(
  [getActiveChannelEntity, getUsersEntities],
  (channel, users) => {
    const members = prop('members', channel);
    const getMembersData = compose(
      sortUserByOnlineStatus(STATUS.ONLINE),
      filter(isHasMemberId(members)),
    );
    return cond([
      [isNotNil, () => getMembersData(users)],
      [T, always(null)],
    ])(members);
  },
);

const getActiveChannelMembers = createSelector(
  getActiveChannelEntity,
  pathOr([], ['members']),
);

const getPinnedMessages = createSelector(
  state => path(['messenger', 'pinnedMessages'], state),
  pinnedMessages => memoizeWith(identity, channelId => pathOr({}, [channelId, 'entities'], pinnedMessages)),
);

const getPinnedMessagesId = createSelector(
  state => path(['messenger', 'pinnedMessages'], state),
  pinnedMessages => memoizeWith(identity, channelId => pathOr([], [channelId, 'result'], pinnedMessages)),
);

const getLastMessageId = createSelector(
  messagesSelector,
  messages => memoizeWith(identity, channelId => pathOr(null, ['lastMessageId'], messages[channelId])),
);

const getLastUserMessage = createSelector(
  messagesSelector,
  messages => memoizeWith(identity, (channelId, userId) => compose(
    findLast(propEq('created_by', userId)),
    sortBy(prop('ts')),
    values,
    pathOr(null, ['entities', 'messages']),
  )(messages[channelId])),
);

const getLastUserOwnerMessage = createSelector(
  messagesSelector,
  messages => memoizeWith(identity, channelId => pathOr(null, ['lastUserOwnerMessage'], messages[channelId])),
);

const joinToChannelPending = createSelector(
  pathOr(false, ['pending', 'joinToChannelRequest']),
  identity,
);

const submitMessageRequest = createSelector(
  pathOr(false, ['pending', 'submitMessageRequest']),
  identity,
);

const addMembersToChannelPending = createSelector(
  pathOr(false, ['pending', 'addMemberToChannelRequest']),
  identity,
);
const deleteMemberFromChannelPending = createSelector(
  pathOr(false, ['pending', 'deleteMemberFromChannelRequest']),
  identity,
);

const getGroupChannelPending = createSelector(
  pendingWhenOffline(pathOr(false, ['pending', 'getChannelRequest'])),
  identity,
);

const getDirectChannelsPending = createSelector(
  pathOr(false, ['pending', 'getDirectChannelsRequest']),
  identity,
);

const getGroupChannelsPending = createSelector(
  pathOr(false, ['pending', 'getGroupChannelsRequest']),
  identity,
);


const getIsDeleteMessagePending = createSelector(
  pathOr(false, ['pending', 'deleteMessageRequest']),
  identity,
);

const getMessagesRequest = createSelector(
  pendingWhenOffline(pathOr(false, ['pending', 'getMessagesRequest'])),
  identity,
);

const getMember = createSelector(
  state => memoizeWith(identity, memberId => pathOr({}, [
    'messenger',
    'channels',
    'groupChannels',
    'members',
    memberId,
  ], state)), identity,
);

const getTotalUnreadCount = createSelector(
  pathOr(0, ['messenger', 'totalUnreadCount']),
  identity,
);

export {
  getTotalUnreadCount,
  getGroupChannels,
  getMessageDays,
  getGroupChannelsList,
  getIsDeleteMessagePending,
  getMessages,
  getLoadedChannels,
  getMessageList,
  getCountLoadedMessages,
  getDirectChannels,
  getDirectChannelsList,
  getDirectChannelsCount,
  getMatchedDirectChannelsList,
  getMatchedDirectChannelsCount,
  getYouBelongToChannelsList,
  getYouBelongToChannelsCount,
  getYouCanJoinChannelsList,
  getYouCanJoinChannelsCount,
  submitMessageRequest,
  getHasMoreMessages,
  getActiveChannelEntity,
  channelsSelector,
  getActiveChannel,
  getMembers,
  getChannelById,
  getLastMessageTime,
  getDirectChannelId,
  getMessage,
  getActiveChannelMembers,
  getPinnedMessages,
  getPinnedMessagesId,
  getLastMessageId,
  getLastUserMessage,
  getLastUserOwnerMessage,
  joinToChannelPending,
  addMembersToChannelPending,
  getMessagesRequest,
  deleteMemberFromChannelPending,
  getMembersEntities,
  getMember,
  getGroupChannelsCount,
  getGroupChannelPending,
  getDirectChannelsPending,
  getGroupChannelsPending,
};

export default messagesSelector;
