import React from 'react';
import PropTypes from 'prop-types';
import { propOr, prop } from 'ramda';
import moment from 'moment';

import {
  ModalWindow, FormsCollection,
} from '../../../../components/index';
import './style.sass';

const ModalView = ({
  isOpen,
  isPending,
  onCloseModal,
  handleChange,
  handleBlur,
  handleSubmit,
  values,
  errors,
  getValidFinishDate,
  onChangeStartDate,
  title,
  titleAction,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    modalName="pinMessage"
    title={title}
    className="new-sprint__modal"
  >
    <div id="step-1y" className="card-body p-1">
      <FormsCollection.FormWrapper
        onSubmit={handleSubmit}
      >
        <div className="form-row">
          <FormsCollection.Input
            placeholderJump="Sprint title"
            name="title"
            label="Title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            touched
            error={propOr('', 'message', errors.title)}
            className="col-lg-12"
            id="new-sprint__title"
          />
        </div>
        <div className="form-row">
          <FormsCollection.TextArea
            placeholder="Description..."
            label="Description"
            className="col-lg-12"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            error={prop('message', errors.description)}
            touched
            autoHeight
            maxHeight={100}
          />
        </div>
        <div className="form-row">
          <div className="col-lg-2  col-sm-4 col-3 d-flex align-items-center">
            <div>Start</div>
          </div>
          <div className="col-lg-3 col-sm-4 col-5 ">
            <FormsCollection.DatePicker
              className="date-picker"
              name="startDate"
              onChange={onChangeStartDate}
              onBlur={handleBlur}
              value={values.startDate}
              touched
              error={prop('message', errors.startDate)}
              id="date-picker-start__new-sprint-modal"
            />
          </div>
          <div className="col-lg-3 offset-lg-1 offset-0 col-sm-3 col-4">
            <FormsCollection.TimePicker
              className="time-picker"
              name="startTime"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!values.startDate}
              touched
              value={values.startTime}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-2 col-sm-4 col-3 d-flex align-items-center">
            <div>Finish</div>
          </div>
          <div className="col-lg-3 col-sm-4 col-5">
            <FormsCollection.DatePicker
              className="date-picker"
              name="finishDate"
              onChange={handleChange}
              onBlur={handleBlur}
              touched
              error={prop('message', errors.finishDate)}
              value={getValidFinishDate()}
              minDate={moment(values.startDate).add(1, 'day').toDate()}
              id="date-picker-finish__new-sprint-modal"
            />
          </div>
          <div className="col-lg-3 offset-lg-1 offset-0 col-sm-3 col-4">
            <FormsCollection.TimePicker
              className="time-picker"
              name="finishTime"
              disabled={!values.finishDate}
              onChange={handleChange}
              onBlur={handleBlur}
              touched
              value={values.finishTime}
            />
          </div>
        </div>
        <div className="form-row">
          <button
            pending={isPending}
            className="btn btn-primary"
            type="submit"
          >
            {titleAction}
          </button>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  getValidFinishDate: PropTypes.func.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
};


export default ModalView;
