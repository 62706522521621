import React from 'react';
import PropTypes from 'prop-types';

const MultiValueRemove = ({ innerProps }) => (
  <button className="close-button" type="button" onClick={innerProps.onClick} onTouchStart={innerProps.onClick}>
    <span className="icon-close" />
  </button>
);

MultiValueRemove.propTypes = {
  innerProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }).isRequired,
};

export default MultiValueRemove;
