import { compose, withHandlers, withStateHandlers } from 'recompose';

import ScrollWrapper from './scrollWrapper';

const setVisibleStatusStateHandler = () => value => ({ isVisible: value });

const onVisibleStatusHandler = ({
  setVisibleStatus,
  isVisible,
}) => ({ clientHeight, scrollHeight }) => {
  const newIsVisibleStatus = clientHeight !== scrollHeight;
  if (isVisible !== newIsVisibleStatus) setVisibleStatus(!isVisible);
};

const enhance = compose(
  withStateHandlers(() => ({ isVisible: false }), {
    setVisibleStatus: setVisibleStatusStateHandler,
  }),
  withHandlers({
    onVisibleStatus: onVisibleStatusHandler,
  }),
);
export default enhance(ScrollWrapper);
