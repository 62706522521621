import {
  call,
  fork,
  cancelled,
  take,
  cancel,
  put,
  select,
} from 'redux-saga/effects';

import { normalize } from 'normalizr';
import {
  toLower, path, propOr,
} from 'ramda';

import moment from 'moment';
import sagasManager from '../../sagasManager';

import {
  pendingActions, requestTypes, errorActions, requestActions,
} from './state';
import requestBuilder from './methods';
import { getErrors } from './utils';
import { userSelectors } from '../../../state/user';

const { apiRequestSuccess, apiRequestError } = requestActions;

const fileLoaderResponse = (response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `customers${moment().format('YYYY-MM-DD')}.csv`);
  document.body.appendChild(link);
  link.click();
};

function* callApi({
  config, method, featureActions, selector, callbacks, schema, params = { isFileLoading: false },
  route, metaData,
}) {
  const api = requestBuilder(config);
  const apiRequest = api[toLower(method)];
  const featureActionSuccess = propOr(null, 'success', featureActions);
  const featureActionError = propOr(null, 'error', featureActions);
  const callbackFunctionsSuccess = propOr(null, 'success', callbacks);
  const callbackFunctionsError = propOr(null, 'error', callbacks);
  const meta = propOr({}, 'meta', featureActions);
  yield put(pendingActions.setPendingStatus({ [selector]: true }));
  try {
    const response = yield call(apiRequest, { params, route });
    if (params.isFileLoading) {
      requestAnimationFrame(() => {
        fileLoaderResponse(response);
      });
    }
    const { data: { result } } = response;
    let data = result;
    if (schema) {
      let normalizeData = normalize(path(schema.pathData, result), schema.rules);
      if (schema.flat) {
        const flatProp = Object.keys(normalizeData.entities)[0];
        normalizeData = {
          ...normalizeData,
          entities: normalizeData.entities[flatProp],
        };
      }
      data = { ...result, data: normalizeData };
    }
    if (path(['payload', 'meta', 'callbackFunctionsSuccess'], metaData)) {
      metaData.payload.meta.callbackFunctionsSuccess(data);
    } else if (callbackFunctionsSuccess) {
      const author = select(state => userSelectors.getUserData(state));
      yield call(callbackFunctionsSuccess, {
        ...data,
        isAfterOffline: path(['payload', 'meta', 'isAfterOffline'], metaData),
        author,
      });
    }
    if (!path(['payload', 'meta', 'isAfterOffline'], metaData) && featureActionSuccess) {
      const author = select(state => userSelectors.getUserData(state));
      return yield put(apiRequestSuccess({
        data,
        action: featureActionSuccess,
        meta,
        author,
      }));
    }
    return data;
  } catch (error) {
    // eslint-disable-next-line no-unused-expressions
    path(['payload', 'meta', 'isAfterOffline'],
      metaData) && metaData.payload.meta.callbackFunctionsSuccess({});
    const errorMessages = getErrors(error);
    yield put(errorActions.setRequestError({ [selector]: errorMessages }));
    if (callbackFunctionsError) {
      yield call(callbackFunctionsError, error);
    }
    if (featureActionError) {
      return yield put(apiRequestError({
        action: featureActionError,
        error: errorMessages,
      }));
    }
    return error;
  } finally {
    if (yield cancelled()) {
      yield put(pendingActions.setPendingStatus({ [selector]: false }));
    }
    yield put(pendingActions.setPendingStatus({ [selector]: false }));
  }
}


function requestFlow(data) {
  return function* startRequest() {
    const task = yield fork(callApi, data);
    yield take([data.cancelActions]);
    yield cancel(task);
    return task;
  };
}

const sagaRequestApi = config => () => next => (action) => {
  next(action);
  try {
    if (action.type === requestTypes.API_REQUEST) {
      const {
        payload: {
          method, params, route, actions: featureActions, selectorName,
          schema, callbacks, cancelActions,
        },
        type,
      } = action;
      sagasManager.addSagaToRoot(function* watcher() {
        const selector = selectorName || type;
        yield fork(requestFlow({
          method,
          params,
          route,
          config,
          selector,
          featureActions,
          schema,
          callbacks,
          cancelActions,
          metaData: action,
        }));
      });
    }
  } catch (e) {
    throw new Error(e);
  }
};

export default sagaRequestApi;
