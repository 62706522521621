import {
  compose, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';

import { withFormik } from 'formik';
import {
  compose as RCompose, map, path, pathOr, prop, values,
} from 'ramda';
import EditCustomer from './editCustomer';
import { uiSelectors, uiActions } from '../../../../state/ui';
import { customersActions, customersSelectors } from '../../../../state/customers';
import rules from '../rules';
import withAutocomplete from '../../../../utils/enchancers/withAutocomplete';
import { newTaskActions } from '../../../../state/newTask';
import { renameKeysTitleIntoValue } from '../../../../utils/helpers/uiHelpers';

const mapStateToProps = (state, { selectedCustomerId }) => ({
  isOpen: uiSelectors.getModal(state)('editCustomer'),
  customer: customersSelectors.getCustomer(state)(selectedCustomerId),
  updateCustomerError: pathOr({}, ['errors', 'updateCustomerRequest'], state),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('editCustomer'),
  onEditCustomer: customersActions.updateCustomerRequest,
  getCustomer: customersActions.getCustomerRequest,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAutocomplete({
    name: 'getProject',
    action: newTaskActions.getProjectsTaskCrudRequest,
    dataPath: RCompose(
      map(renameKeysTitleIntoValue),
      values,
      path(['data', 'entities', 'projects']),
    ),
    searchField: 'title',
  }),
  withFormik({
    mapPropsToValues: ({ customer }) => ({
      ...customer,
      projects: RCompose(
        map(renameKeysTitleIntoValue),
        values,
        prop('projects'),
      )(customer),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: rules,
    handleSubmit: (formValues,
      {
        props: {
          onEditCustomer,
          onCloseModal,
          selectedCustomerId,
          sort: {
            limit,
            offset,
          },
        },
      }) => {
      onEditCustomer({ ...formValues, role_id: parseInt(formValues.role_id, 10), projects: map(prop('value'), formValues.projects) }, {
        customerId: selectedCustomerId,
        limit,
        offset,
        callbacks: {
          success: onCloseModal,
        },
      });
    },
  }),
  lifecycle({
    componentDidUpdate({ isOpen, updateCustomerError }) {
      if (isOpen !== this.props.isOpen) {
        this.props.getCustomer({ customerId: this.props.selectedCustomerId });
      }
      if (this.props.updateCustomerError.username !== updateCustomerError.username) {
        this.props.setFieldError('username', { message: 'Username must be uniq' });
      }
    },
  }),
);

export default enhance(EditCustomer);
