import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import Wrapper from './wrapper';
import { uiActions } from '../../../state/ui';
import { preloaderWhileLoading } from '../../../utils/enchancers';

const mapDispatchToProps = {
  setGridOptions: uiActions.setGridOptions,
  clearGridOptions: uiActions.clearGridOptions,
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  preloaderWhileLoading({
    dimension: 100,
    fullScreen: true,
    isLoading: props => props.isLoading,
  }),
  lifecycle({
    componentDidMount() {
      const { setGridOptions, name } = this.props;
      setGridOptions({
        grid: name,
      });
    },
    componentWillUnmount() {
      const { clearGridOptions, name, isKeepOptions } = this.props;
      if (!isKeepOptions) {
        clearGridOptions({ grid: name });
      }
    },
  }),
);
export default enhance(Wrapper);
