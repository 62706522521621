import { handleActions } from 'redux-actions';
import types from './types';
import {
  mergeIn,
} from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_USER_STATISTIC]: mergeIn(({ payload: { data, meta } }) => ({
    [meta.userId]: {
      total: data.total,
      entities: data.entities,
      result: data.result,
    },
  })),
}, {
});

export default reducer;
