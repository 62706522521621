/* eslint-disable no-plusplus */
import {
  pathOr, path, keys, reduce, filter, includes, reverse, map, omit, cond, has, T, prop,
} from 'ramda';
import { put } from 'redux-saga/effects';
import { setOfflineRequest, resetOfflineRequests, setOfflineRequestsStatus } from './state/offlineRequest/actions';
import { offlineRequestSelectors, offlineRequestTypes } from './state/offlineRequest';
import { apiRequest } from './state/request/actions';
import sagasManager from '../../sagasManager';
import { getRequestsSelectorsList } from './state/offlineRequest/selectors';
import { userSelectors } from '../../../state/user';

const REQUEST_WITHOUT_ACTION = 'REQUEST_WITHOUT_ACTION';

const REQUESTS_PRIORITY = [
  'addProject', 'editProjectRequest', 'deleteProjectRequest',
  'addSprintRequest', 'updateSprintRequest', 'deleteSprintRequest',
  'addTaskRequest', 'updateTaskRequest', 'deleteTaskRequest',
  'addTimeEntryRequest', 'updateTimeEntryRequest', 'deleteTimeEntryRequest',
  'submitMessageRequest',
];
/*
*  This module need for emulation requets when user is offline
*
* All requests wich made without internet will be save into redux in `requests`, when network
* will be call RESENDER_REQUESTS action.
*
*  - REQUESTS_PRIORITY - this is array selectors for make priorities offline requests
*
*
*  */

const requestFilter = (state, action, next) => {
  const {
    method, route, selectorName,
  } = action.payload;
  const requestsList = offlineRequestSelectors.getRequestsList(state)(selectorName);
  let countSimilarRequestsUpdate = 0;
  let countSimilarRequestsDelete = 0;
  const newRequestList = reduce((accum, requestId) => {
    const request = requestsList[requestId];
    if (method === 'PUT' && request.route === route && method === request.method) {
      // When some entitie, edited more that once. Will be accept only last request
      if (countSimilarRequestsUpdate > 0) {
        return accum;
      }
      countSimilarRequestsUpdate += 1;
    }
    if (method === 'DELETE' && request.route === route && method === request.method) {
      // When some entitie, delete more that once. Will be accept only last request
      if (countSimilarRequestsDelete > 0) {
        return accum;
      }
      countSimilarRequestsDelete += 1;
    }
    return { ...accum, [requestId]: requestsList[requestId] };
  }, {}, reverse(keys(requestsList)));
  next(resetOfflineRequests({ requests: newRequestList, selectorName }));
};

const pendingRequests = {};

const resenderRequests = (getState, next) => {
  const callResendSelector = (currentSelectorIndex = 0, selectorNames, selectorsLowPriority = false) => {
    if (!selectorNames) return null;
    const selectorName = selectorNames[currentSelectorIndex];
    if (selectorNames.length <= currentSelectorIndex) {
      if (selectorsLowPriority) {
        next(setOfflineRequestsStatus({ status: 'success' }));
        return null;
      }
      const selectorsLowPriorityList = filter(selector => !includes(selector,
        [...REQUESTS_PRIORITY, 'requests', 'status', 'undefined']),
      keys(getRequestsSelectorsList(getState())));
      callResendSelector(0, selectorsLowPriorityList, true);
    }
    if (!selectorName) return callResendSelector(1 + currentSelectorIndex);
    const requestsList = pathOr([], ['offlineRequest', selectorName, 'requests'], getState());
    // eslint-disable-next-line no-shadow
    const refreshChildrenActionsBySuccess = (selectorName, childSelectors, parentId,
      helpersProp) => {
      const { stateCaller, dispatchCaller } = helpersProp;

      const updateRequestsByRouter = ({
        selector, routes: {
          replaceRegex, equalsRegex, replaceTo, equalsRegexFunc, replaceRegexFunc,
        },
      }) => {
        const requests = offlineRequestSelectors.getRequestsList(stateCaller())(selector);
        const newCreateSprintRequests = reduce((accum, key) => {
          const requestSprintAdd = requests[key];
          if (!requestSprintAdd) return accum;
          const newRequestObj = parseInt(equalsRegexFunc
            ? equalsRegexFunc(requestSprintAdd.route)
            : requestSprintAdd.route.match(equalsRegex), 10) === parseInt(parentId, 10)
            ? {
              ...requestSprintAdd,
              route: replaceRegexFunc
                ? replaceRegexFunc(requestSprintAdd.route)
                : requestSprintAdd.route.replace(replaceRegex, replaceTo),
            } : requestSprintAdd;
          return { ...accum, [key]: newRequestObj };
        }, {}, keys(requests));
        dispatchCaller(resetOfflineRequests({
          requests: newCreateSprintRequests,
          selectorName: selector,
        }));
      };
      const updateRequestsByParams = ({
        selector, params: {
          equalProp, replaceProp,
          replaceTo,
        },
      }) => {
        const requests = offlineRequestSelectors.getRequestsList(stateCaller())(selector);
        const newRequests = reduce((accum, key) => {
          const requestTaskAdd = requests[key];
          if (!requestTaskAdd) return accum;
          const newRequestObj = parseInt(prop(equalProp,
            requestTaskAdd.params), 10) === parseInt(parentId, 10)
            ? { ...requestTaskAdd, params: { ...requestTaskAdd.params, [replaceProp]: replaceTo } }
            : requestTaskAdd;
          return { ...accum, [key]: newRequestObj };
        }, {}, keys(requests));
        dispatchCaller(resetOfflineRequests({ requests: newRequests, selectorName: selector }));
      };

      map(cond([
        [has('routes'), updateRequestsByRouter],
        [has('params'), updateRequestsByParams],
        [T, () => console.log('Can not check what should replace this filter')],
      ]), childSelectors);
    };
    const callRequest = (currentRequestIndex = 1) => {
      const request = pathOr(false, ['offlineRequest', selectorName, 'requests',
        currentRequestIndex], getState());
      if (request) {
        const requestId = currentRequestIndex;
        if (!includes(requestId, pathOr([], [request.selectorName], pendingRequests))) {
          pendingRequests[request.selectorName] = [...pathOr([], [request.selectorName],
            pendingRequests), requestId];
          sagasManager.addSagaToRoot(function* watcher() {
            yield put(apiRequest({
              ...request,
              meta: {
                isAfterOffline: true,
                callbackFunctionsSuccess: (data) => {
                  const newRequestList = omit([currentRequestIndex], pathOr([],
                    ['offlineRequest', selectorName, 'requests'], getState()));
                  if (request.selectorName === 'addProject') {
                    refreshChildrenActionsBySuccess('addProject', [{
                      selector: 'addSprintRequest',
                      routes: {
                        equalsRegex: /\d{1,}/,
                        replaceRegex: /\d{1,}/,
                        replaceTo: data.project.id,
                      },
                    }, {
                      selector: 'addTaskRequest',
                      params: {
                        equalProp: 'project_id',
                        replaceProp: 'project_id',
                        replaceTo: data.project.id,
                      },
                    }], data.created_id, {
                      stateCaller: getState,
                      dispatchCaller: next,
                      request,
                    });
                  } else if (request.selectorName === 'addTaskRequest') {
                    refreshChildrenActionsBySuccess('addProject', [{
                      selector: 'addTimeEntryRequest',
                      routes: {
                        equalsRegexFunc: str => str.match(/(\/tasks\/\d{1,})/)[0].match(/\d{1,}/)[0],
                        replaceRegex: /\d{1,}/,
                        replaceRegexFunc: str => str.replace(/(\/tasks\/\d{1,})/,
                          `/tasks/${data.task.id}`).replace(/(\/projects\/\d{1,})/,
                          `/projects/${data.task.project_id}`),
                      },
                    }], data.task.created_id, {
                      stateCaller: getState,
                      dispatchCaller: next,
                      request,
                    });
                  }
                  next(resetOfflineRequests({
                    requests: newRequestList,
                    selectorName: request.selectorName,
                  }));
                  callRequest(currentRequestIndex + 1);
                },
              },
            }));
          });
        }
      }
      if (currentRequestIndex >= keys(requestsList).length) {
        setTimeout(() => callResendSelector(currentSelectorIndex + 1,
          selectorNames, selectorsLowPriority), keys(requestsList).length > 0 ? 500 : 0);
      }
    };
    callRequest();
  };

  callResendSelector(0, REQUESTS_PRIORITY);
};

const offlineRequestMiddleware = () => ({ getState }) => next => (action) => {
  if (action.type === 'OFFLINE_REQUEST') {
    const offlineAction = pathOr(REQUEST_WITHOUT_ACTION, ['payload', 'actions', 'offlineSuccess'], action);
    const offlineSuccessCallback = pathOr(null, ['payload', 'callbacks', 'success'], action);
    const responseSchema = path(['payload', 'responseSchema'], action);
    const responseStatePath = pathOr(null, ['payload', 'responseStatePath'], action);
    const author = userSelectors.getUserData(getState());
    const { method } = action.payload;
    let result;
    const requestsList = offlineRequestSelectors.getRequestsList(getState());
    let requestNameForId;
    if (method === 'POST') requestNameForId = 'requestIdPost';
    if (method === 'DELETE') requestNameForId = 'requestIdDelete';
    if (method === 'PUT') requestNameForId = 'requestIdPut';
    const requestId = { [requestNameForId]: requestsList.length };
    let oldResult;
    if (responseStatePath) {
      result = responseStatePath(getState());
      oldResult = (method === 'PUT' && !result.oldResult) ? { oldResult: result } : {};
      result = { ...result, ...requestId };
    }
    if (responseSchema) {
      result = responseSchema({
        ...result, ...action.payload.params, ...requestId, ...oldResult, author,
      });
    }
    if (offlineAction) {
      next(setOfflineRequest(action.payload));
      if (REQUEST_WITHOUT_ACTION !== offlineAction) {
        requestFilter(getState(), action, next);
        sagasManager.addSagaToRoot(function* watcher() {
          yield put(offlineAction({ ...result, author }));
        });
      }
    }
    if (offlineSuccessCallback) {
      offlineSuccessCallback({ ...result, author });
    }
  }
  if (action.type === offlineRequestTypes.RESEND_OFFLINE_REQUESTS) {
    next(setOfflineRequestsStatus({ status: 'inProgress' }));
    resenderRequests(getState, next);
  }
  if (action.type === offlineRequestTypes.RESET_OFFLINE_ACTION) {
    // eslint-disable-next-line max-len
    const requestsList = offlineRequestSelectors.getRequestsList(getState())(action.payload.selectorName);
    const newList = omit([action.payload.requestId], requestsList);
    next(resetOfflineRequests({ requests: newList, selectorName: action.payload.selectorName }));
  }

  next(action);
};

export default offlineRequestMiddleware;
