import {
  equals, path, cond, omit, compose, prop, replace,
} from 'ramda';
import { push } from 'connected-react-router';

import { uiActions, uiSelectors, uiTypes } from '../../../state/ui';
import { getGridsSelector } from '../../../state/ui/selectors';
import { userSelectors } from '../../../state/user';

import { TYPES_NOTIFICATION } from '../../../constants/ui';

import { map } from '../../helpers/commonHelpers';
import { isUserFocusOnChat, pathToActiveChannelId } from '../../helpers/messengerHelpers/channelHelpers';
import { showMessageWhenUserNotAuthor } from '../../helpers/messengerHelpers/notifications';
import { getAvatarInBase64 } from '../../helpers/canvasHelpers';
import { regexRules } from '../../helpers/uiComponentHelpers/common';

const getNotificationType = path(['meta', 'type']);
const checkNotificationType = notificationType => compose(
  equals(notificationType),
  getNotificationType,
);
const showNotification = ({ onClick }) => (data) => {
  const defaultUserAvatar = getAvatarInBase64(data);
  const notification = new Notification(prop('title', data),
    {
      ...data,
      body: replace(regexRules.regAllHtml, '', data.body),
      icon: data.icon || defaultUserAvatar,
    });
  notification.addEventListener('click', onClick);
  setTimeout(notification.close.bind(notification), 9000);
};

const clearGridsMiddleware = ({ getState }) => next => (action) => {
  if (action.type === uiTypes.CLEAR_GRID_OPTIONS) {
    const gridsState = getGridsSelector(getState());
    const { payload: { grid } } = action;
    const grids = omit([grid], gridsState);
    next(uiActions.clearGridOptions({ grids }));
  } else {
    next(action);
  }
};

const showNotificationMiddleware = ({ getState, dispatch }) => next => (action) => {
  if (action.type === uiTypes.SHOW_NOTIFICATION) {
    const state = getState();
    const { id } = userSelectors.getUserData(state);
    const isWindowFocus = uiSelectors.getIsWindowFocus(state);
    const channel = pathToActiveChannelId(state);
    const redirectToChannel = () => dispatch(push(`/messenger/${path(['payload', 'message', 'channel_id'], action)}`));
    cond([
      [checkNotificationType(TYPES_NOTIFICATION.MESSAGE), compose(
        map(compose(
          showMessageWhenUserNotAuthor(id, showNotification({ onClick: redirectToChannel })),
          prop('payload'),
        )),
        isUserFocusOnChat(isWindowFocus, channel, path(['payload', 'message', 'channel_id'])),
      )],
    ])(action);
  }
  next(action);
};

export default clearGridsMiddleware;
export {
  showNotificationMiddleware,
};
