import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ChannelItem, CreateChannel } from './components';
import './style.sass';
import AttachFilesModal from '../attachFilesModal';
import { PermissionElement } from '../../../../components';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const GroupChannels = ({
  groupChannelsList,
  onClickOpenModal,
  isOnline,
  t,
}) => (
  <ul className="chanel-nav-group sidebar-menu">
    <li className="chanel-nav-group__header light mt-3">
      <NavLink
        to="/messenger/channels/"
        className="chanel-nav-group__title"
      >
        {t('CHANNELS')}
      </NavLink>
      <PermissionElement module={MODULES.CHANNELS} action={ACTIONS.CREATE}>
        <button
          className="chanel-nav-group__add-channel"
          type="button"
          disabled={!isOnline}
          title={!isOnline ? 'Only in online version' : 'Create channel'}
          onClick={onClickOpenModal}
        >
          <span className="icon-add_circle chanel-nav-group__icon" />
        </button>
      </PermissionElement>
    </li>
    <nav className="chanel-nav-group__nav sidebar-menu">
      { groupChannelsList.map(channel => (
        <ChannelItem
          key={channel}
          channelId={channel}
        />
      ))}
    </nav>
    <CreateChannel />
    <AttachFilesModal />
  </ul>
);

GroupChannels.propTypes = {
  groupChannelsList: PropTypes.arrayOf(PropTypes.number).isRequired,
  onClickOpenModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isOnline: PropTypes.bool.isRequired,
};

export default GroupChannels;
