export default {
  GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
  GET_ROLE_REQUEST: 'GET_ROLE_REQUEST',
  GET_MODULES_REQUEST: 'GET_MODULES_REQUEST',

  SET_MODULES: 'SET_MODULES',
  SET_ROLES: 'SET_ROLES',
  SET_MY_ROLE: 'SET_MY_ROLE',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  UPDATE_ROLE: 'UPDATE_ROLE',
};
