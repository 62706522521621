/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React from 'react';

import './style.sass';

const PendingControl = ({
  isPending, isUpdating, isDeleting, onResetOfflineDelete, onResetOfflineCreate, onResetOfflineEdit,
}) => (
  <div className="pending-wrap">
    {isPending && (
      <>
        <div className="pending-wrap__button" title="Reset add" onClick={onResetOfflineCreate}>
          <i className="icon-close s-14" />
        </div>
        <div className="pending-wrap__info" title="Will be add when you will have an internet">
          {' '}
          <i className="icon-timer s-14" />
        </div>
      </>
    )}
    {isUpdating && (
      <>
        <div className="pending-wrap__button" title="Reset update" onClick={onResetOfflineEdit}>
          <i className="icon-close s-14" />
        </div>
        <div className="pending-wrap__info" title="Will be update when you will have an internet">
          {' '}
          <i className="icon-refresh2 s-14" />
        </div>
      </>
    )}
    {isDeleting && (
      <>
        <div className="pending-wrap__button" title="Reset delete" onClick={onResetOfflineDelete}>
          <i className="icon-close s-14" />
        </div>
        <div className="pending-wrap__info" title="Will be delete when you will have an internet">
          {' '}
          <i className="icon-delete_sweep s-14" />
        </div>
      </>
    )}
  </div>
);

export default PendingControl;
