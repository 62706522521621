import { compose, withHandlers, withStateHandlers } from 'recompose';
import Comments from './comments';

const setContentMessage = () => e => ({ contentMessage: e.target.value });

const onSendCommentHandler = ({ scrollElement }) => () => {
  setTimeout(() => scrollElement.scrollToBottom(scrollElement.getValues().scrollHeight));
};

const onScrollSetRefHandler = ({ setRef }) => e => setRef('commentsScroll', e);

const enhancer = compose(
  withStateHandlers(() => ({
    contentMessage: '',
  }), {
    setContentValueStateHandler: setContentMessage,
  }),
  withHandlers({
    onScrollSetRef: onScrollSetRefHandler,
    onSendComment: onSendCommentHandler,
  }),
);
export default enhancer(Comments);
