import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import GroupChannels from './groupChannels';
import { messengerSelectors } from '../../../../state/messenger';
import { openModal } from '../../../../state/ui/actions';
import withOnline from '../../../../utils/enchancers/withOnline';

const mapStateToProps = state => ({
  groupChannelsList: messengerSelectors.getGroupChannelsList(state),
});

const mapDispatchToProps = ({
  openModal,
});

const onClickOpenModalHandler = ({
  // eslint-disable-next-line no-shadow
  openModal,
}) => () => openModal('createChannelModal');

const enhance = compose(
  withOnline,
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
  withHandlers({
    onClickOpenModal: onClickOpenModalHandler,
  }),
);

export default enhance(GroupChannels);
