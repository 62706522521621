import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { verticalBlueArrow } from '../../../../constants/assets';
import './style.sass';

const BreadCrumbs = ({ t }) => (
  <div className="bread-crumbs">
    <Link to="/" className="bread-crumbs__main-link">
      <span className="bread-crumbs__hash-icon">#</span>
general
    </Link>
    <img src={verticalBlueArrow} className="bread-crumbs__break-blue-arrow" alt="" />
    <h3 className="bread-crumbs__current-page">{t('News & Discussions')}</h3>
  </div>
);

BreadCrumbs.propTypes = {
  t: PropTypes.func.isRequired,
};

export default BreadCrumbs;
