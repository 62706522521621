import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { map, pathOr, prop } from 'ramda';
import { Link } from 'react-router-dom';
import { ScrollWrapper } from '../../components';
import { getFullName } from '../../utils/helpers/userHelpers';

const Customer = ({
  customer, onEditCustomerModal,
}) => (
  <ScrollWrapper heightIs="100%" className="scroll-page">
    <div className="container-fluid">
      <div className="tab-content my-3" id="v-pills-tabContent">
        <div className="customer-card col-lg-6 offset-lg-3">
          <h1 className="customer__title">{getFullName(customer)}</h1>
          <ul className="customer__list">
            <li>
              <b>Phone: </b>
              {customer.phone}
            </li>
            <li>
              <b>Email: </b>
              {customer.email}
            </li>
            <li>
              <b>Website: </b>
              {customer.website}
            </li>
            <li>
              <b>Facebook: </b>
              {customer.facebook}
            </li>
            <li>
              <b>Address: </b>
              {customer.address}
            </li>
            <li>
              <b>Organization: </b>
              {customer.organization}
            </li>
            <li>
              <b>Projects: </b>
              <ul className="customer-item__projects-list">
                { pathOr(false, ['projects', 'length'], customer)
                  ? map(project => (
                    <li>
                      <Link to={`/projects/${project.id}`}>{project.title}</Link>
                    </li>
                  ),
                  customer.projects) : '-'}
              </ul>
            </li>
          </ul>
          <div className="row">
            <div className="col-lg-12">
              <button
                type="button"
                className="btn btn-primary w-125px"
                onClick={() => onEditCustomerModal(prop('id', customer))}
              >
Edit customer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ScrollWrapper>
);

Customer.propTypes = {
  customer: PropTypes.instanceOf(Object).isRequired,
  onEditCustomerModal: PropTypes.func.isRequired,
};

export default Customer;
