import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import {
  ModalWindow, FormsCollection,
} from '../../..';
import { AsyncAutoComplete } from '../../../autoCompleteField';
import './style.sass';

const inviteMembersModal = ({
  isOpen,
  isPending,
  onCloseModal,
  handleSubmit,
  setFieldValue,
  getAutocomplete,
  errors,
  values,
  titleMain,
  titleItem,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    modalName="inviteChannelMembersModal"
    title={`Invite ${titleItem}s to ${titleMain.toLowerCase()}`}
    className="modal-window__create-chanel"
  >
    <div id="step-1y" className="card-body p-1">
      <FormsCollection.FormWrapper onSubmit={handleSubmit} className="invite-channel-members">
        <div className="form-row">
          <div className="invite-channel-members__col-label">
            {t('Members')}
          </div>
          <div className="invite-channel-members__col-fields">
            <AsyncAutoComplete
              isMulti
              name="members"
              loadOptions={getAutocomplete}
              placeholder={`${t('Choose members')}...`}
              closeMenuOnSelect={false}
              value={values.members}
              placeholderJump={t('Members')}
              error={propOr('', 'message', errors.members)}
              onChange={value => setFieldValue('members', value)}
            />
          </div>
        </div>
        <div className="row d-flex justify-content-end">
          <button type="submit" className="btn btn-primary w-100px mt-1">{t('Invite')}</button>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </ModalWindow>
);

inviteMembersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  values: PropTypes.shape({
    members: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  getAutocomplete: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  titleMain: PropTypes.string.isRequired,
  titleItem: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default inviteMembersModal;
