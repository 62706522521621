import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  FormsCollection, Layouts,
} from '../../../components';

import './style.sass';
import u5Image from '../../../assets/img/dummy/u5.png';

const ForgotPassword = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  errors,
}) => (

  <Layouts.Auth>
    <main>
      <div id="primary" className="p-t-b-100 height-full ">
        <div className="container">
          <div className="form-row">
            <div className="col-lg-4 mx-md-auto">
              <div className="text-center">
                <img src={u5Image} alt="" />
                <h3 className="mt-2">Forgot password ?</h3>
              </div>
              <FormsCollection.FormWrapper onSubmit={handleSubmit}>
                <FormsCollection.Input
                  className="form-group has-icon"
                  classInput="form-control form-control-lg"
                  name="email"
                  error={errors.email}
                  value={values.email}
                  placeholder="Email address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <i className="icon-envelope-o" />
                </FormsCollection.Input>
                <button type="submit" className="btn btn-success btn-lg btn-block">Remind</button>
                <div className="row">
                  <div className="col-lg-12">
                    <Link to="/" className="forget-pass">Don`t have an account?</Link>
                  </div>
                </div>
              </FormsCollection.FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layouts.Auth>
);

ForgotPassword.propTypes = {
  errors: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    email: PropTypes.bool,
  }).isRequired,
  authNotification: PropTypes.shape({
    email: PropTypes.object,
  }),
};

ForgotPassword.defaultProps = {
  authNotification: {},
};

export default ForgotPassword;
