import {
  branch, compose, pure, renderNothing,
} from 'recompose';

import { isNil } from 'ramda';
import UserListAvatar from './userListAvatar';

const enhance = compose(
  pure,
  branch(
    ({ displayAvatars }) => isNil(displayAvatars),
    renderNothing,
  ),
);

export default enhance(UserListAvatar);
