import * as yup from 'yup';

const rules = yup.object().shape({
  title: yup
    .string()
    .required({
      message: 'Title required',
    }),
  quantity: yup
    .number()
    .required({
      message: 'Quantity is required',
    }),
});
export default rules;
