/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { trim } from 'ramda';
import { getShortName, stringToColor } from './utils';
import './style.sass';

const Avatar = ({
  children, src, colorText, alt, onClickAvatar, className,
}) => (
  <>
    <div
      className={`avatar-group ${className}`}
      title={trim(alt || '') ? alt : 'Unkwown user'}
      onClick={onClickAvatar}
      style={{ backgroundColor: src ? 'transparent' : `${trim(alt || '') ===  '' ? '#b5b2b285' : '#0288d1'}` ,  backgroundImage: `url(${src})`}}
    >
      {src
        ? (<></>)
        : (
          <div className="avatar-group__short-name" style={{ color: colorText }}>
            <span data-testid="shortName">{trim(alt || '') ? getShortName(alt) : 'UN'}</span>
          </div>
        )}
    </div>
    {children}
  </>
);
Avatar.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.element,
      PropTypes.string,
      PropTypes.array],
  ),
  colorText: PropTypes.string,
  src: PropTypes.string,
  onClickAvatar: PropTypes.func,
  alt: PropTypes.string,
};
Avatar.defaultProps = {
  children: '',
  colorText: '#fff',
  alt: '',
  onClickAvatar: null,
  src: null,
};
// For usage
export default Avatar;
// For tests
export {
  getShortName,
};
