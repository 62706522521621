import {
  curry, compose, propOr,
} from 'ramda';

const insertNodeAfterElement = curry((
  element,
  node,
) => element.parentNode.insertBefore(node, element.nextSibling));

const isFocusInElement = element => window.getSelection().baseNode === element;

const setFocusAfterNode = curry((parentElement, node) => {
  const s = window.getSelection();
  const r = document.createRange();
  r.selectNodeContents(parentElement);
  s.removeAllRanges();
  r.setStart(node, 0);
  r.collapse(true);
  s.addRange(r);
});

const setFocusAfterNodeForMention = curry((s, r, offset, node) => {
  r.selectNodeContents(node);
  s.removeAllRanges();
  r.setStart(node, offset);
  r.collapse(true);
  s.addRange(r);
  return node;
});

const createTexNode = (text = '\u00A0') => document.createTextNode(text);

const insertNodeInCurrentFocus = (element) => {
  const selection = window.getSelection();
  if (selection) {
    const range = selection.getRangeAt(0);
    range.collapse(true);
    range.insertNode(element);
    selection.removeAllRanges();
  }
  return element;
};

// const setFocusCaret = (el) => {
//   const s = window.getSelection();
//   const r = document.createRange();
//   r.selectNodeContents(el);
//   const lastChild = el.lastChild.lastChild || el.lastChild;
//   const len = lastChild.innerHTML ? lastChild.innerHTML.length
//     : lastChild.length;
//   r.setStart(lastChild, len);
//   r.setEnd(lastChild, len);
//   s.addRange(r);
// };

const positionCurrentWord = (offset, text) => {
  const getOffsetStart = (currentOffset) => {
    const f = (o, t) => {
      if (!t) return false;
      if ((t[o] !== ' ' && o > 0) || o === currentOffset) return f(o - 1, t);
      return o;
    };
    return f(currentOffset, text);
  };
  const getOffsetEnd = (currentOffset) => {
    const f = (o, t) => {
      if (!t) return false;
      if (t[o] !== ' ' && o < text.length) return f(o + 1, t);
      return o;
    };
    return f(currentOffset, text);
  };
  return {
    start: getOffsetStart(offset, text),
    end: getOffsetEnd(offset, text),
  };
};

const getWritingWord = () => {
  const s = window.getSelection();
  const { focusNode, anchorOffset } = s;
  const data = propOr(false, 'data', focusNode);
  const { start, end } = positionCurrentWord(anchorOffset, data);

  return data ? data.slice(start, end) : '';
};

const setCaretFocusByOffset = curry((el, offset) => {
  const range = document.createRange();
  const sel = window.getSelection();
  range.setStart(el, offset);
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
});

const appendNodeAndSetFocus = curry((s, r, parentElement, siblingNode, text = '\u00A0') => compose(
  setFocusAfterNodeForMention(r, s, 1),
  insertNodeAfterElement(siblingNode),
  createTexNode,
)(text));

// TODO need to refactoring
const replaceNodeByOffset = curry((
  range,
  selection,
  parentElement,
  focusNode,
  start,
  end,
  node,
) => {
  range.selectNodeContents(parentElement);
  range.setStart(focusNode, start);
  range.setEnd(focusNode, end);
  range.deleteContents();
  range.insertNode(node);
  selection.removeAllRanges();
  return node;
});

const replaceNodeTagByOffset = curry((
  range,
  selection,
  parentElement,
  focusNode,
  coordinates,
  node,
) => {
  range.selectNodeContents(parentElement);
  range.setStart(focusNode, coordinates.start);
  range.setEnd(focusNode, coordinates.stop);
  range.deleteContents();
  range.insertNode(node);
  selection.removeAllRanges();
  return node;
});

export {
  insertNodeAfterElement,
  isFocusInElement,
  setFocusAfterNodeForMention,
  setFocusAfterNode,
  appendNodeAndSetFocus,
  setCaretFocusByOffset,
  // setFocusAfterNodeForMention,
  createTexNode,
  replaceNodeTagByOffset,
  insertNodeInCurrentFocus,
  positionCurrentWord,
  getWritingWord,
  replaceNodeByOffset,
};
