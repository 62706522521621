import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './style.sass';

const Pagination = ({
  className, count, limit, currentPage, listPages,
}) => (
  <nav className={className} aria-label="Page navigation">

    <ul className="pagination">
      {
        (currentPage > 3 && Math.ceil(count / limit) > 5) && (
          <>
            <li className="page-item">
              <Link to="?page=1" className="page-link">{1}</Link>
            </li>
            <li className="page-item">
              <span className="page-link disabled">...</span>
            </li>
          </>
        )
        }
      {
          Array.from(Array(Math.ceil(count / limit)).keys()).length > 1 && (
            <>
              {
                listPages.map(page => (
                  <li className={`page-item ${page + 1 === currentPage ? 'active' : ''}`}>
                    <Link to={`?page=${page + 1}`} className="page-link" href="#">{page + 1}</Link>
                  </li>
                ))
              }
            </>
          )
        }
      {
        (currentPage <= Math.ceil(count / limit) - 3 && Math.ceil(count / limit) > 5) && (
          <>

            <li className="page-item">
              <span className="page-link disabled">...</span>
            </li>
            <li className="page-item">
              <Link to={`?page=${Math.ceil(count / limit)}`} className="page-link">{Math.ceil(count / limit)}</Link>
            </li>
          </>
        )
      }
    </ul>
  </nav>
);

Pagination.propTypes = {
  className: PropTypes.string,
  offset: PropTypes.number,
  count: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  goPreviousPage: PropTypes.func,
  goNextPage: PropTypes.func,
};

Pagination.defaultProps = {
  className: '',
  offset: 0,
  goPreviousPage: () => {},
  goNextPage: () => {},
};

export default Pagination;
