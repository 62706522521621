/* eslint-disable import/prefer-default-export */
import {
  dissocPath, keys, pathOr, reduce, map,
} from 'ramda';
import { usersActions } from '../../../state/users';
import { uiActions, uiTypes } from '../../../state/ui';
import { setUsers } from '../../../state/users/actions';

const setUserProfileMiddleware = () => next => (action) => {
  if (action.type === uiTypes.SET_USER_PROFILE) {
    const { payload: { user } } = action;
    next(usersActions.updateUsers({ entities: { [user.id]: user }, result: [user.id] }));
    next(uiActions.setUserProfileId(user.id));
  } else {
    next(action);
  }
};

// TODO move to helpers
const getEntities = (dataPath, action) => {
  if (dataPath) {
    return reduce((accum, index) => ({
      ...accum,
      [pathOr({}, [...dataPath, index, 'id'], action.payload)]: pathOr({}, [...dataPath, index], action.payload),
    }),
    {}, keys(pathOr({}, dataPath, action.payload)));
  }
  const multiDataPath = pathOr([], ['payload', 'meta', 'multiPathEntities'], action);
  return reduce(
    (accum, path) => ({ ...accum, ...pathOr({}, path, action.payload) }), {}, multiDataPath,
  );
};

const getClearDataAction = (dataPath, action) => {
  if (dataPath) return dissocPath(['payload', ...dataPath], action);
  const multiDataPath = pathOr([], ['payload', 'meta', 'multiPathEntities'], action);
  return reduce(
    (accum, path) => dissocPath(['payload', ...path], accum), action, multiDataPath,
  );
};

const setUsersMiddleware = () => next => (action) => {
  if (pathOr(false, ['payload', 'meta', 'isUsersUpdate'], action) || pathOr(false, ['meta', 'isUsersUpdate'], action)) {
    const dataPath = pathOr(false, ['payload', 'meta', 'pathEntities'], action);
    const entities = getEntities(dataPath, action);
    const result = map(val => parseInt(val, 10), keys(entities));
    const isNotClear = pathOr(false, ['payload', 'meta', 'isNotClear'], action);
    const clearDataAction = isNotClear ? action : getClearDataAction(dataPath, action);
    next(setUsers({ entities, result }));
    next(clearDataAction);
  } else {
    next(action);
  }
};


export {
  setUserProfileMiddleware,
  setUsersMiddleware,
};
