import { createSelector } from 'reselect';
import { pathOr, identity } from 'ramda';

const getLoginErrors = createSelector(pathOr(null, ['errors', 'loginRequest']), identity);

const getResetPasswordErrors = createSelector(pathOr(false, ['errors', 'resetPasswordRequest']),
  identity);

const getSignUpErrors = createSelector(pathOr(null, ['errors', 'signUpRequest']));

const getContinueSignUpErrors = createSelector(pathOr(null, ['errors', 'continueSignUpRequest']));

const getCheckResetPasswordToken = createSelector(pathOr(null, ['errors', 'checkResetPasswordToken']), identity);

const getCheckCodeErrors = createSelector(pathOr(null, ['errors', 'checkCode']), identity);

const getEmailFromCodeCheck = createSelector(pathOr(null, ['bootData', 'user', 'email']), identity);

const getUserData = createSelector(pathOr(null, ['bootData', 'user']), identity);

const getMyPermissions = createSelector(pathOr(null, ['bootData', 'permissions']), identity);

const getPendingLoginRequest = createSelector(pathOr(false, ['pending', 'loginRequest']), identity);
const getPendingForgotPasswordRequest = createSelector(pathOr(false, ['pending', 'forgotPasswordRequest']), identity);
const getPendingSignUpRequest = createSelector(pathOr(false, ['pending', 'signUpRequest']), identity);
const getPendingContinueSignUpRequest = createSelector(pathOr(false, ['pending', 'continueSignUpRequest']), identity);
const getPendingResetPasswordRequest = createSelector(pathOr(false, ['pending', 'resetPasswordRequest']), identity);
const getPendingUpdateUserProfileRequest = createSelector(pathOr(false, ['pending', 'updateUserProfileRequest']), identity);

export {
  getLoginErrors,
  getResetPasswordErrors,
  getSignUpErrors,
  getContinueSignUpErrors,
  getCheckCodeErrors,
  getEmailFromCodeCheck,
  getUserData,
  getCheckResetPasswordToken,
  getPendingLoginRequest,
  getPendingForgotPasswordRequest,
  getPendingContinueSignUpRequest,
  getPendingSignUpRequest,
  getPendingResetPasswordRequest,
  getPendingUpdateUserProfileRequest,
  getMyPermissions,
};
