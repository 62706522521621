import { compose, withStateHandlers } from 'recompose';
import TasksList from './tasksList';


const toggleTabSprint = ({ sprintsTabStatus }) => (id) => {
  const newSprintObject = {};
  newSprintObject[id] = sprintsTabStatus[id] === undefined
    ? newSprintObject[id] = true : !sprintsTabStatus[id];
  return {
    sprintsTabStatus: {
      ...sprintsTabStatus,
      ...newSprintObject,
    },
  };
};

const enhance = compose(
  withStateHandlers(
    () => ({ sizeTableList: '', sprintsTabStatus: {} }), {
      handlerSizeTableList: () => val => ({
        sizeTableList: val,
      }),
      toggleTabSprint,
    },
  ),
);
export default enhance(TasksList);
