import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { projectsActions, projectsSelectors } from '../../../../state/projects';
import { uiSelectors, uiActions } from '../../../../state/ui';
import ProjectsNav from './projectsNav';

const {
  getProjects, getProjectsListMenu,
  getProjectsListMenuHasMore,
  getProjectsListMenuCount,
  getProjectsListMenuPending,
} = projectsSelectors;
const {
  getProjectsListMenuRequest,
  addToFavoriteProjectsFetch,
  removeFromFavoriteProjectsFetch,
} = projectsActions;

const { changeProjectsTabStatus } = uiActions;
const { getTabStatus } = uiSelectors;

const mapStateToProps = state => ({
  projectListResult: getProjectsListMenu(state),
  projectListEntities: getProjects(state),
  projectListHasMore: getProjectsListMenuHasMore(state),
  projectCount: getProjectsListMenuCount(state),
  projectListPending: getProjectsListMenuPending(state),
  isProjectsTabOpen: getTabStatus(state)('isProjectsTabOpen'),
});
// TODO add handler and delete "projectListRequest" function
const mapDispatchToProps = ({
  projectListRequest:
    ({ ...params }) => getProjectsListMenuRequest(
      { ...{ sortBy: 'favorite', order: 'desc' }, ...params },
    ),
  addToFavoriteProjectRequest: addToFavoriteProjectsFetch,
  removeFromFavoriteProjectRequest: removeFromFavoriteProjectsFetch,
  changeProjectsTabStatus,
});

const handlerFavorite = props => (status, id) => {
  if (status) {
    props.removeFromFavoriteProjectRequest({ id });
  } else {
    props.addToFavoriteProjectRequest({ id });
  }
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handlerFavorite,
  }),
);
export default enhance(ProjectsNav);
