import React from 'react';
import PropTypes from 'prop-types';

const CodeText = ({ content }) => (<code>{content}</code>);

CodeText.propTypes = {
  content: PropTypes.oneOfType([PropTypes.instanceOf(Array)]).isRequired,
};

export default CodeText;
