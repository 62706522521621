import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './style.sass';

const Button = ({
  children, className, disabled, onClick, type, asLink, href, name,
}) => (
  <>
    {
    asLink ? (
      <Link disabled={disabled} onClick={() => onClick()} type={type} to={href} className={`button-default ${className}`}>
        {children}
      </Link>
    ) : (
      // eslint-disable-next-line react/button-has-type
      <button name={name} disabled={disabled} onClick={e => onClick(e)} type={type} className={`button-default ${className}`}>
        {children}
      </button>
    )
    }
  </>
);

// TODO: Dirty component with anonymous function

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.element,
  onClick: PropTypes.func,
  asLink: PropTypes.bool,
  href: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  type: 'button',
  className: '',
  asLink: false,
  href: '',
  children: 'Submit',
  onClick: () => {},
};


export default Button;
