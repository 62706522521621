import {
  compose, lifecycle, withContext, withHandlers, withState, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { propOr } from 'ramda';
import StatisticsContainer from './statisticsContainer';
import { statisticsActions } from '../../state/statistics';
import { userSelectors } from '../../state/user';
import { withWindowWidth } from '../../utils/enchancers';

const mapStateToProps = state => ({
  user: userSelectors.getUserData(state),
});

const mapDispatchToProps = ({
  getStatistics: statisticsActions.getMyStatisticsRequest,
});

const onLoadStatisticsHandler = ({
  getStatistics, selectedStartDate, selectedEndDate, selectedProjectId, selectedUserId, user,
}) => () => {
  getStatistics({
    startDate: selectedStartDate, endDate: selectedEndDate, user_id: propOr(null, 'value', selectedUserId), project_id: propOr(null, 'value', selectedProjectId),
  }, { userId: user.id });
};

const changeView = () => view => ({ view });
const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWindowWidth(),
  withState('selectedUserId', 'setUserId', 0),
  withState('selectedProjectId', 'setProjectId', null),
  withState('selectedStartDate', 'setStartDate', null),
  withState('selectedEndDate', 'setEndDate', null),
  withStateHandlers(
    () => ({ view: 'myStatistic' }), {
      changeViewStateHandler: changeView,
    },
  ),
  withHandlers({
    onLoadStatistics: onLoadStatisticsHandler,
  }),
  withContext({
    setUserId: PropTypes.func,
    setProjectId: PropTypes.func,
    setStartDate: PropTypes.func,
    setEndDate: PropTypes.func,
    selectedProjectId: PropTypes.number,
    selectedUserId: PropTypes.number,
    selectedStartDate: PropTypes.number,
    selectedEndDate: PropTypes.number,
  }, props => ({
    setUserId: props.setUserId,
    setProjectId: props.setProjectId,
    setStartDate: props.setStartDate,
    setEndDate: props.setEndDate,
    selectedProjectId: props.selectedProjectId,
    selectedUserId: props.selectedUserId,
    selectedStartDate: props.selectedStartDate,
    selectedEndDate: props.selectedEndDate,
  })),
  lifecycle({
    componentDidMount() {
      this.props.onLoadStatistics();
    },
    componentDidUpdate({
      selectedEndDate, selectedStartDate, selectedProjectId, selectedUserId,
    }) {
      if (((selectedStartDate !== this.props.selectedStartDate
          || selectedEndDate !== this.props.selectedEndDate
      ) && this.props.selectedEndDate && this.props.selectedStartDate)
      || (selectedProjectId !== this.props.selectedProjectId)
        || (selectedUserId !== this.props.selectedUserId)
      ) {
        this.props.onLoadStatistics();
      }
    },
  }),
);
export default enhancer(StatisticsContainer);
