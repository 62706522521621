import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import CompleteProjectModal from './startProjectModal';
import { getModal } from '../../../../../state/ui/selectors';
import { projectsActions, projectsSelectors } from '../../../../../state/projects';
import { uiActions } from '../../../../../state/ui';
import { STATUS } from '../../../../../constants/projects';

const mapStateToProps = (state, { selectedProjectId }) => ({
  isOpen: getModal(state)('startProjectModal'),
  project: projectsSelectors.getProject(state)(selectedProjectId),
});

const mapDispatchToProps = ({
  onCloseModal: () => uiActions.closeModal('startProjectModal'),
  editProject: projectsActions.editProjectRequest,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onStart: ({
      editProject,
      selectedProjectId, onCloseModal, project,
    }) => () => editProject({ id: selectedProjectId, ...project, status: STATUS.DEFAULT }, {
      callbacks: {
        success: onCloseModal,
      },
    }),
  }),
);

export default enhance(CompleteProjectModal);
