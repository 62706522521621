import { createSelector } from 'reselect/lib/index';
import {
  pathOr, identity, memoizeWith,
} from 'ramda';

const getCustomers = createSelector(
  pathOr({}, ['customers', 'entities']), identity,
);

const getCustomersResult = createSelector(
  pathOr([], ['customers', 'result']), identity,
);

const getCustomersCount = createSelector(
  pathOr([], ['customers', 'count']), identity,
);

const getCustomersHasMore = createSelector(
  pathOr([], ['customers', 'hasMore']), identity,
);


const getCustomer = createSelector(
  getCustomers,
  customers => memoizeWith(identity, customerId => pathOr({}, [customerId], customers)),
);

const getPendingGetCustomers = createSelector(
  pathOr(false, ['pending', 'getCustomersRequest']), identity,
);

const getPendingUpdateCustomer = createSelector(
  pathOr(false, ['pending', 'updateCustomerRequest']), identity,
);

export {
  getCustomers,
  getCustomer,
  getCustomersResult,

  getPendingGetCustomers,
  getPendingUpdateCustomer,
  getCustomersCount,
  getCustomersHasMore,
};
