import {
  compose, getContext, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';

import { getFullName } from '../../../../utils/helpers/userHelpers';
import DeleteMemberModal from './deleteMemberModal';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { usersSelectors } from '../../../../state/users';

const mapStateToProps = (state, { memberId }) => ({
  isOpen: uiSelectors.getModal(state)('deleteMember'),
  member: usersSelectors.getUser(state)(memberId),
});

const mapDispatchToProps = ({
  onCloseModal: () => uiActions.closeModal('deleteMember'),
});

const enhance = compose(
  getContext({ selectedMemberId: PropTypes.number }),
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
  withProps(({ member }) => ({
    memberName: getFullName(member),
  })),
);

export default enhance(DeleteMemberModal);
