import { handleActions } from 'redux-actions';

import types from './types';
import { mergeDeep } from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.UPDATE_TASK_REQUEST]: mergeDeep(action => action.payload.data),
}, {});

export default reducer;
