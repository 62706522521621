import {
  lifecycle, compose, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { pathOr, prop, propOr } from 'ramda';
import { withFormik } from 'formik';
import { push } from 'connected-react-router';
import moment from 'moment';
import { usersActions, usersSelectors } from '../../state/users';
import { projectsActions, projectsSelectors } from '../../state/projects';
import { uiActions, uiSelectors } from '../../state/ui';

import { customersActions, customersSelectors } from '../../state/customers';
import { TYPE } from '../../constants/projects';
import { uiHelpers } from '../helpers';
import withAutocomplete from './withAutocomplete';
import { setAssigneeLabel } from '../helpers/taskHelpers/crudHelper';

const mapStateToProps = modalName => state => ({
  usersList: usersSelectors.getUsersForSelect(state),
  addProjectErrors: projectsSelectors.getAddProjectErrors(state),
  getUsersByResult: usersSelectors.getUsersByResult(state),
  isOpen: uiSelectors.getModal(state)(modalName),
  getUserById: usersSelectors.getUser(state),
  getCustomerById: customersSelectors.getCustomer(state),
});

const mapDispatchToProps = ({
  addProject: projectsActions.addProjectRequest,
  setCloseModal: uiActions.closeModal,
  goToProject: push,
  uploadTempFile: uiActions.uploadTempFileRequest,
});
const onCloseModalHandler = ({
  resetForm, setCloseModal, initialValues,
}) => (name) => {
  resetForm(initialValues);
  setCloseModal(name);
};

const getValidFinishDate = ({ values }) => () => {
  const { finishDate, startDate } = values;

  if (!finishDate) return '';

  if (finishDate < startDate) {
    return moment(startDate).add(1, 'day').toDate();
  }
  return finishDate;
};

const onChangeStartDateHandler = ({
  handleChange, setFieldValue,
}) => (e) => {
  const { target: { value } } = e;
  const finishDate = value ? moment(value).add(14, 'day').toDate() : '';

  setFieldValue('deadline_time', finishDate);
  handleChange(e);
  return value;
};

const combineTimeAndDate = (date, hours, minutes) => {
  const dateObject = new Date(date);
  dateObject.setMinutes(minutes);
  dateObject.setHours(hours);
  return dateObject;
};

const addDaysToDate = (date, count) => moment(date).add('days', count).toDate();

const getObjectForSend = ({
  startDate, startTime, finishDate, finishTime, ...props
}) => ({
  start_time: combineTimeAndDate(startDate, startTime.hours, startTime.minutes),
  deadline_time: combineTimeAndDate(finishDate, finishTime.hours, finishTime.minutes),
  customer_id: props.customer.value,
  ...props,
});
const withCRUDProject = ({
  rules, data, onSubmit, modalName,
}) => compose(
  connect(mapStateToProps(modalName), mapDispatchToProps),
  withNamespaces(['common']),
  withProps(({ getUsersByResult, ...props }) => {
    const project = data(props);
    return ({
      initialValues: ({
        title: propOr('', 'title', project),
        owner: propOr('', 'owner', project),
        type: propOr(TYPE.PUBLIC, 'type', project),
        image: propOr(pathOr(null, ['image', 'id'], project), 'image', project),
        members: compose(uiHelpers.renameKeysForUsers, getUsersByResult, propOr([], 'members'))(project),
        status: propOr(1, 'status', project),
        description: propOr('', 'description', project),
        startDate: new Date(propOr(new Date(), 'start_time', project)),
        customer: compose(setAssigneeLabel, props.getCustomerById, propOr(null, 'customer_id'))(project),
        finishDate: new Date(propOr(addDaysToDate(new Date(), 14), 'deadline_time', project)),
        startTime: {
          hours: 0,
          minutes: 0,
        },
        finishTime: {
          hours: 0,
          minutes: 0,
        },
      }),
    });
  }),
  withAutocomplete({
    name: 'getUsersAutocomplete',
    action: usersActions.getUsersListAutocompleteRequest,
    dataPath: prop('users'),
  }),
  withAutocomplete({
    name: 'getCustomers',
    action: customersActions.getCustomersListAutocompleteRequest,
    dataPath: prop('users'),
  }),
  withFormik({
    mapPropsToValues: ({ initialValues }) => initialValues,
    enableReinitialize: false,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: rules,
    handleSubmit: (formData, props) => onSubmit(getObjectForSend(formData), props),
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
    getValidFinishDate,
    onChangeStartDate: onChangeStartDateHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        addProjectErrors, setErrors, setSubmitting,
      } = this.props;
      if (prevProps.addProjectErrors !== addProjectErrors && addProjectErrors) {
        setErrors(this.props.addProjectErrors);
        setSubmitting(false);
      }
    },
    componentWillUnmount() {
      this.props.setErrors(null);
    },
  }),
);

export default withCRUDProject;
