export default {
  GET_CATEGORIES_TASK_CRUD_REQUEST: 'GET_CATEGORIES_TASK_CRUD_REQUEST',
  GET_SPRINTS_TASK_CRUD_REQUEST: 'GET_SPRINTS_TASK_CRUD_REQUEST',
  GET_PROJECTS_TASK_CRUD_REQUEST: 'GET_PROJECTS_TASK_CRUD_REQUEST',
  GET_MATERIALS_TASK_CRUD_REQUEST: 'GET_MATERIALS_TASK_CRUD_REQUEST',

  SET_CATEGORIES_TASK_CRUD: 'SET_CATEGORIES_TASK_CRUD',
  SET_SPRINTS_TASK_CRUD: 'SET_SPRINTS_TASK_CRUD',
  SET_PROJECTS_TASK_CRUD: 'SET_PROJECTS_TASK_CRUD',
  SET_MATERIALS_TASK_CRUD: 'SET_MATERIALS_TASK_CRUD',
  SET_SELECTED_PROJECT_TASK_CRUD: 'SET_SELECTED_PROJECT_CRUD',
};
