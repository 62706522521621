import {
  branch, compose, renderNothing, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import {
  prop, T, cond, always,
} from 'ramda';
import { isNotNil } from 'ramda-extension';

import UserProfile from './userProfile';
import { getShortName, stringToColor } from '../../../../components/avatar/utils';
import { usersSelectors, usersActions } from '../../../../state/users';
import { messengerSelectors } from '../../../../state/messenger';
import { preloaderWhileLoading } from '../../../../utils/enchancers';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';
import { uiSelectors } from '../../../../state/ui';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const { getDirectChannelId } = messengerSelectors;

const mapStateToProps = (state, { id }) => ({
  userId: uiSelectors.getUserProfileId(state),
  users: usersSelectors.getUsersEntities(state),
  channelId: getDirectChannelId(state)(id),
  isPending: uiSelectors.getUserProfileRequestPending(state),
});

const mapDispatchToProps = {
  getUserProfile: usersActions.getUserProfileRequest,
};

const onGoToSendMessageHandler = ({ history: { push } }) => () => {
  push(`/messenger/${1}/`);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ users, userId }) => ({
    user: users[userId],
  })),
  branch(({ user }) => !prop('id', user), renderNothing),
  withNamespaces(['common', 'user']),
  withRouter,
  withHandlers({
    onGoToSendMessage: onGoToSendMessageHandler,
  }),
  withProps(({ user }) => ({
    shortName: getShortName(getFullName(user)),
    userAvatar: compose(cond([
      [compose(isNotNil, prop('id')), compose(getImageUrl, prop('id'))],
      [isNotNil, getImageUrl],
      [T, always('')],
    ]), prop('avatar'))(user),
    backgroundShortName: stringToColor(getFullName(user)),
  })),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.SMALL,
    alignContainerCenter: true,
    delay: 600,
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
);

export default enhance(UserProfile);
