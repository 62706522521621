import * as yup from 'yup';

const rules = yup.object().shape({
  username: yup
    .string()
    .required({
      message: 'Username is required',
    }),
  email: yup
    .string()
    .email({ message: 'Email is not valid' })
    .required({
      message: 'Email is required',
    }),
});
export default rules;
