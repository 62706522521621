import {
  pipe, length, split, ifElse, slice, map, join, toUpper, head,
} from 'ramda';

export const getShortName = (name) => {
  if (!name || (typeof name !== 'string')) return '';
  return pipe(
    split(' '),
    ifElse(
      str => length(str) > 1,
      pipe(
        slice(0, 2),
        map(head),
        join(''),
        toUpper,
      ),
      pipe(
        head,
        slice(0, 2),
        toUpper,
      ),
    ),
  )(name);
};

export const stringToColor = (str) => {
  const avangaColors = [
    '#d32f2f',
    '#ffc107',
    '#ffeb3b',
    '#0288d1',
    '#2196f3',
    '#4caf50',
    '#f16e5e',
    '#d16052',
    '#b25246',
  ];
  return avangaColors[str ? str.length % 9 : 0];
};
