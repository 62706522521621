import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';

import {
  categoriesSchema,
  projectsSchema,
  sprintsSchema,
  materialsSchema,
} from './schema';

export const {
  getCategoriesTaskCrudRequest,
  getProjectsTaskCrudRequest,
  getSprintsTaskCrudRequest,
  getMaterialsTaskCrudRequest,
  setCategoriesTaskCrud,
  setProjectsTaskCrud,
  setSprintsTaskCrud,
  setSelectedProjectCrud,
  setMaterialsTaskCrud,
} = createActions(
  {
    [types.GET_CATEGORIES_TASK_CRUD_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/task-categories`,
        selectorName: 'getCategoriesTaskCrudRequest',
        method: 'GET',
        params,
        schema: {
          rules: categoriesSchema,
          pathData: ['data'],
        },
        actions: {
          success: setCategoriesTaskCrud,
        },
        ...meta,
      }),
    ],
    [types.GET_PROJECTS_TASK_CRUD_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/projects',
        selectorName: 'getProjectsTaskCrudRequest',
        method: 'GET',
        params,
        schema: {
          rules: projectsSchema,
          pathData: ['data'],
        },
        actions: {
          success: setProjectsTaskCrud,
        },
        ...meta,
      }),
    ],
    [types.GET_SPRINTS_TASK_CRUD_REQUEST]: [
      identity,
      ({ projectId = 2, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/sprints`,
        selectorName: 'getSprintsTaskCrudRequest',
        method: 'GET',
        params,
        schema: {
          rules: { sprints: sprintsSchema },
          pathData: ['data'],
        },
        actions: {
          success: setSprintsTaskCrud,
        },
        ...meta,
      }),
    ],
    [types.GET_MATERIALS_TASK_CRUD_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/materials',
        selectorName: 'getMaterialsTaskCrudRequest',
        method: 'GET',
        params,
        schema: {
          rules: materialsSchema,
          pathData: ['data'],
        },
        actions: {
          success: setProjectsTaskCrud,
        },
        ...meta,
      }),
    ],
  },
  types.SET_CATEGORIES_TASK_CRUD,
  types.SET_PROJECTS_TASK_CRUD,
  types.SET_SPRINTS_TASK_CRUD,
  types.SET_SELECTED_PROJECT_TASK_CRUD,
  types.SET_MATERIALS_TASK_CRUD,
);
