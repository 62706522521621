import { compose, withHandlers } from 'recompose';

import withRefs from '../../../../utils/enchancers/withRefs';

import Number from './number';

const filterValue = (min, max, value, onChange) => {
  let newValue = value ? parseInt(value, 10) : 0;
  if (newValue > max) {
    newValue = max;
  } else if (newValue < min) {
    newValue = min;
  }
  onChange({
    value: newValue,
  });
  return newValue.toString();
};
const handlerValue = ({
  min, max, getRef, onChange,
}) => (updateValue) => {
  const input = getRef('input').inputElement;
  input.value = filterValue(min, max, parseInt(input.value, 10) + updateValue, onChange);
};
const middlewareMask = ({ max, min }) => (newState) => {
  let { value } = newState;
  if (parseInt(newState.value, 10) > max) {
    value = max;
  } else if (parseInt(newState.value, 10) < min) {
    value = min;
  }
  return {
    selection: newState.selection,
    value,
  };
};

const specialKeysHandle = ({
  getRef, min, max, onChange,
}) => (e) => {
  const input = getRef('input').inputElement;
  const inputValue = parseInt(input.value || 0, 10);
  switch (e.keyCode) {
    case 38:
      input.value = filterValue(min, max, inputValue + 1, onChange);
      break;
    case 40:
      input.value = filterValue(min, max, inputValue - 1, onChange);
      break;
    default:
  }
};

const enhance = compose(
  withRefs(),
  withHandlers(
    {
      handlerValue,
      middlewareMask,
      specialKeysHandle,
    },
  ),
);
export default enhance(Number);
