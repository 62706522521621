import React from 'react';
import PropTypes from 'prop-types';
import { TabWrapper } from '../../../../components';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import { TabWrapperMobile } from '..';
import { TaskList } from '../../../leftSideBar/components';

const ImportantTask = ({
  windowWidth,
  setActiveTitle,
  activeTabTitle,
  entities,
  result,
  isPending,
  isImportantTasksTabOpen,
  changeImportantTasksTabStatus,
}) => (
  windowWidth > WINDOW_WIDTH.SMALL ? (
    <TabWrapper onChangeStatusTab={changeImportantTasksTabStatus} defaultStatusTab={isImportantTasksTabOpen} title={<h2 className="title">Important tasks</h2>}>
      <TaskList pending={isPending} entities={entities} result={result} className="important-tasks--right-side-bar" />
    </TabWrapper>
  ) : (
    <TabWrapperMobile setActiveTitle={setActiveTitle} title="Important tasks" active={activeTabTitle}>
      <TaskList pending={isPending} entities={entities} result={result} className="important-tasks--right-side-bar" />
    </TabWrapperMobile>
  )
);

ImportantTask.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  setActiveTitle: PropTypes.func,
  activeTabTitle: PropTypes.string,
  entities: PropTypes.instanceOf(Object).isRequired,
  result: PropTypes.objectOf(PropTypes.number).isRequired,
  isPending: PropTypes.bool.isRequired,
  isImportantTasksTabOpen: PropTypes.bool.isRequired,
  changeImportantTasksTabStatus: PropTypes.func.isRequired,
};

ImportantTask.defaultProps = {
  setActiveTitle: () => {},
  activeTabTitle: '',
};

export default ImportantTask;
