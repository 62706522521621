/* eslint-disable max-len */
import {
  compose, lifecycle, withHandlers, withProps, withStateHandlers,
} from 'recompose';
import {
  curry, is, map, includes, equals, cond, T, slice, identity, prop, symmetricDifference, juxt,
} from 'ramda';
import { Either } from 'ramda-fantasy';

import { setAvatarActive } from '../helpers/uiHelpers';

const setUserAvatarsStateHandlers = () => displayAvatars => ({ displayAvatars });
const setAdditionalAvatarsStateHandler = () => hiddenAvatars => ({ hiddenAvatars });
const setAvatarActiveStateHandler = ({ userAvatars }) => id => ({
  userAvatars: setAvatarActive(id)(userAvatars),
});

const toggleAdditionalAvatarStateHandler = () => id => ({ hiddenAvatars: id });

const { Left, Right } = Either;

const getIdAndCheckIsIncludes = curry(ids => compose(
  id => includes(Number(id), ids),
  prop('id'),
));

const toggleAvatarStatus = curry((reset, avatar) => {
  const changedAvatar = avatar;
  changedAvatar.select = reset ? false : !changedAvatar.select;
  return changedAvatar;
});

const isIdInclude = curry((ids, avatar) => cond([
  [getIdAndCheckIsIncludes(ids), Right],
  [T, Left],
])(avatar));

const convertEachNumber = curry(data => cond([
  [is(Array), items => map(item => Number(item), items)],
  [T, item => [Number(item)]],
])(data));

const updateAvatar = curry((left, right, ids) => compose(
  Either.either(left, right),
  isIdInclude(ids),
));

const setAvatar = curry((left, action, selectedIds) => compose(
  action,
  map(
    updateAvatar(
      identity,
      toggleAvatarStatus(false),
      selectedIds,
    ),
  ),
));

const withUsersAvatars = ({
  users, onClick, selectedAvatars = () => [], countDisplay, updatedByProps,
}) => compose(
  withProps(props => ({
    selected: selectedAvatars(props),
  })),
  withStateHandlers(
    () => ({ displayAvatars: [], hiddenAvatars: [] }), {
      setDisplayAvatars: setUserAvatarsStateHandlers,
      setAvatarActive: setAvatarActiveStateHandler,
      setHiddenAvatars: setAdditionalAvatarsStateHandler,
      toggleAdditionalAvatar: toggleAdditionalAvatarStateHandler,
    },
  ),
  withHandlers({
    onClickAvatar: onClick,
  }),
  lifecycle({
    componentDidMount() {
      const { setDisplayAvatars, selected, setHiddenAvatars } = this.props;
      const data = users(this.props);
      const countAvatarDisplay = countDisplay - data.length;
      const selectedIds = convertEachNumber(selected);
      if (countAvatarDisplay < 0) {
        setAvatar(toggleAvatarStatus(true), setHiddenAvatars, selectedIds)(slice(countDisplay, Infinity, data));
      }
      setAvatar(toggleAvatarStatus(true), setDisplayAvatars, selectedIds)(slice(0, countDisplay, data));
    },
    componentDidUpdate(prevProps) {
      const {
        selected, hiddenAvatars, setDisplayAvatars,
        displayAvatars, setHiddenAvatars,
      } = this.props;

      if (!equals(...juxt([prop(updatedByProps)])(this.props, prevProps))) {
        // Todo: make simple without repeat code
        const data = users(this.props);
        const countAvatarDisplay = countDisplay - data.length;
        const selectedIds = convertEachNumber(selected);
        if (countAvatarDisplay < 0) {
          setAvatar(toggleAvatarStatus(true), setHiddenAvatars, selectedIds)(slice(countDisplay, Infinity, data));
        }
        setAvatar(toggleAvatarStatus(true), setDisplayAvatars, selectedIds)(slice(0, countAvatarDisplay, data));
      }
      if (!equals(selected, prevProps.selected)) {
        const selectedIds = symmetricDifference(convertEachNumber(selected), convertEachNumber(prevProps.selected));
        setAvatar(identity, setHiddenAvatars, selectedIds)(hiddenAvatars);
        setAvatar(identity, setDisplayAvatars, selectedIds)(displayAvatars);
      }
    },
  }),
);

export default withUsersAvatars;
