import {
  assoc,
  compose as RCompose,
  compose, curry, prop, propOr,
  filter, reject,
  omit, pathOr,
} from 'ramda';
import moment from 'moment';

import { getValueBy, setNewPropertyData } from '../commonHelpers';
import { renameKeysTitleIntoValue } from '../uiHelpers';
import { getSecondsFromTimeString } from '../dateHelpers';
import { userHelpers } from '../index';
import { projectActions } from '../../../state/project';
import { reorderTimeEntries, deleteTimeEntryEntity } from '../../../state/task/actions';

const getValue = getValueBy('value');

const setTaskData = compose(
  setNewPropertyData('created_id', () => new Date().getTime()),
  setNewPropertyData('estimated_time', getSecondsFromTimeString),
  setNewPropertyData('category_id', getValue),
  setNewPropertyData('project_id', getValue),
  setNewPropertyData('sprint_id', getValue),
  setNewPropertyData('watchers', getValue),
  setNewPropertyData('assignee', getValue),
);

const setCategoryLabel = curry((categories, task) => compose(
  renameKeysTitleIntoValue,
  category => prop(category, categories),
  propOr('', 'category_id'),
)(task));

const setProjectLabel = curry((projects, task, projectId) => compose(
  renameKeysTitleIntoValue,
  project => prop(project, projects),
  propOr(projectId, 'project_id'),
)(task));


const setAssigneeLabel = RCompose(
  renameKeysTitleIntoValue,
  taskData => assoc('label', userHelpers.getFullName(taskData), taskData),
);

const setSprintLabel = curry((sprints, task) => RCompose(
  renameKeysTitleIntoValue,
  sprint => prop(sprint, sprints),
  propOr('', 'sprint_id'),
)(task));

const deleteTask = (entities,
  list, taskId, sprintId, next, isAgenda, statusId, agendaEntities, tasks, projectId, isTaskHasSprint) => {
  const resultTasksArray = filter(value => value !== taskId, list);
  const tasksEntities = {
    tasks: omit([taskId], tasks),
  };

  const agendaTasks = isAgenda ? {
    agenda: {
      [statusId]: {
        tasks: reject(id => id === taskId, agendaEntities(projectId, statusId).tasks),
      },
    },
  } : { agenda: {} };

  const tasksResult = {
    entities: {
      sprints: {
        [sprintId]: {
          tasks: resultTasksArray,
        },
      },
      ...tasksEntities,
      ...agendaTasks,
    },
    result: !isTaskHasSprint ? { backlog: resultTasksArray } : {},
  };
  next(projectActions.deleteProjectTask({ ...tasksResult, ...agendaTasks, projectId }));
};

const deleteTimeEntry = (state, timeEntryId, next, taskId) => {
  const entities = pathOr({}, ['task', taskId, 'entities', 'time_entries'], state);
  const result = pathOr({}, ['task', taskId, 'result', 'time_entries'], state);
  next(reorderTimeEntries({ result: filter(id => timeEntryId !== id, result), taskId }));
  next(deleteTimeEntryEntity({ result: omit([timeEntryId], entities), taskId }));
};

const getCategoryTitle = propOr('', 'title');
const formatDate = curry(date => moment(date).format('DD/MM/YY'));

export {
  setTaskData,
  formatDate,
  setAssigneeLabel,
  setCategoryLabel,
  getCategoryTitle,
  setSprintLabel,
  setProjectLabel,
  deleteTask,
  deleteTimeEntry,
};
