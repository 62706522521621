import { compose, withState } from 'recompose';
import { withNamespaces } from 'react-i18next';

import SignUpSuccess from './signUpSuccess';

const enhancer = compose(
  withNamespaces('common'),
  withState('prefix', 'setPrefix', 'Wetelo, Inc.'),
);

export default enhancer(SignUpSuccess);
