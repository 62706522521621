/* eslint-disable no-param-reassign */
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import {
  curry, replace, without, cond, is, T, isNil, clone, pathOr, includes, concat,
  mergeDeepWithKey, forEachObjIndexed, mapObjIndexed, hasIn, identity,
} from 'ramda';
import { push } from 'connected-react-router';
import { stringify, parse } from 'qs';
import { withRouter } from 'react-router';

const mapDispatchToProps = ({
  pushParams: push,
});

const getParamFromUrl = curry(location => compose(
  parse,
  replace('?', ''),
)(location));

const convertToArray = cond([
  [is(Array), identity],
  [T, data => [data]],
]);

const convertParamsToValidData = curry((strategy, num, key) => {
  if (!isNil(strategy) && includes(key, strategy.concat)) {
    return convertToArray(num);
  }
  return num;
});

const modifyParams = curry((source, key, valuesForReset) => cond([
  [isNil, () => delete source[key]],
  [is(Array), () => {
    const restValues = without(valuesForReset, source[key]);
    source[key] = restValues;
  }],
])(valuesForReset));

const modifyHasInParams = curry((source, key, values) => cond([
  [hasIn(key), () => modifyParams(source, key, values[key])],
  [T, () => source],
])(values));

const resetEachValue = curry((paramsForReset, value, key, obj) => modifyHasInParams(
  obj,
  key,
  paramsForReset,
));

const resetParams = curry((stringifyParams, processFunc, convert) => compose(
  stringifyParams,
  forEachObjIndexed(processFunc),
  convert,
  clone,
));

const addParams = curry(stringifyParams => compose(
  stringifyParams,
  clone,
));

const stringifyParams = curry(params => stringify(params, { arrayFormat: 'repeat' }));

const makeNewArrayParams = compose(stringifyParams, mergeDeepWithKey);

const stringifyArrayParam = curry((params, value, urlParams) => {
  const concatValues = (k, l, r) => {
    if (!isNil(params) && includes(k, params.concat)) {
      return concat(convertToArray(l), r);
    }
    return r;
  };
  return makeNewArrayParams(concatValues, urlParams, value);
});

const onSetUrlParamHandler = curry((params, { location, pushParams }) => curry((value) => {
  const paramFromUrl = getParamFromUrl(location.search);
  const newSearchParamValue = addParams(
    stringifyArrayParam(params, value),
    mapObjIndexed(convertParamsToValidData(params)),
  )(paramFromUrl);
  pushParams({ search: newSearchParamValue });
}));

const onResetUrlParamHandler = curry((params, { location, pushParams }) => curry((value) => {
  const paramsFromUrl = getParamFromUrl(location.search);
  const newSearchParamValue = resetParams(
    stringifyParams,
    resetEachValue(value),
    mapObjIndexed(convertParamsToValidData(params)),
  )(paramsFromUrl);
  pushParams({ search: newSearchParamValue });
}));

const onGetUrlParam = ({ location }) => (param, or = null) => {
  const urlsParam = getParamFromUrl(location.search);
  // NOTE: param should be like ['limit'], not 'limit'
  return pathOr(or, param, urlsParam);
};

const onGetUrlParams = ({ location }) => () => getParamFromUrl(location.search);

const withUrlParams = ({ params = null }) => compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withHandlers({
    onSetUrlParam: onSetUrlParamHandler(params),
    onResetUrlParam: onResetUrlParamHandler(params),
    getUrlParam: onGetUrlParam,
    getUrlParams: onGetUrlParams,
  }),
);

export default withUrlParams;
