import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './style.sass';

const DescriptionChannel = ({
  createdAt,
  description,
  createdBy,
  t,
}) => (
  <div className="description-channel">
    <p className="description-channel__text">
      {description}
    </p>
    <span className="description-channel__created">
      <span className="description-channel__created-helper">{t('Created')}</span>
      { createdBy && `${t('by')} ${createdBy}` }
      {`${t('on')} ${moment(new Date(createdAt)).format('MMMM D, YYYY')}`}
    </span>
  </div>
);

DescriptionChannel.propTypes = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  description: PropTypes.string,
  t: PropTypes.func.isRequired,
};

DescriptionChannel.defaultProps = {
  description: '',
};

export default React.memo(DescriptionChannel);
