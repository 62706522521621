import {
  compose, withProps, withHandlers, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { uiActions } from '../../state/ui';
import Page404 from './page404';
import { TITLES_CONSTANTS } from '../../constants/titles';

const mapDispatchToProps = ({
  goBackFromErrorPage: uiActions.goBackFromErrorPage,
  setPageNotFound: uiActions.setPageNotFound,
  setCurrentPage: uiActions.setPage,
});

const deletePage404Handler = ({ goBackFromErrorPage, setPageNotFound }) => () => {
  setPageNotFound(false);
  goBackFromErrorPage('');
};

const enhancer = compose(
  connect(null, mapDispatchToProps),
  withNamespaces(['common']),
  withProps({ label: localStorage.getItem('authToken') ? 'All projects' : ' Go back' }),
  withHandlers({
    deletePage404: deletePage404Handler,
  }),
  lifecycle({
    componentDidMount() {
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.PAGE_404 });
    },
  }),
);

export default enhancer(Page404);
