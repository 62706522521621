import React from 'react';
import PropTypes from 'prop-types';
import { DropDown, FormsCollection, MobileFiltersContainer } from '../../../../components';
import {
  SORT_BY_LETTERS_DOWN,
  SORT_BY_LETTERS_UP,
} from '../../constants';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import './style.sass';

const FiltersRow = ({
  setSelectedSearch,
  selectedSearch,
  setSelectedSort,
  selectedSort,
  t,
}) => (
  <div className="channels-filter">
    <div className="channels-filter__row d-flex justify-content-start">
      <FormsCollection.Search
        className="search-field--circle directs-filter__search-field"
        name="search"
        withoutControl
        value={selectedSearch}
        onChange={e => setSelectedSearch(e.target.value)}
      />
      <MobileFiltersContainer title="Filter" onWidth={WINDOW_WIDTH.SMALL}>
        <DropDown
          className="channels-filter__drop-down ml-2"
          label="Sort: "
          currentItem={selectedSort.id}
          list={
            [
              {
                id: 1,
                label: t('All'),
                value: null,
              },
              {
                id: 2,
                label: t('A-Z'),
                value: SORT_BY_LETTERS_DOWN,
              },
              {
                id: 3,
                label: t('Z-A'),
                value: SORT_BY_LETTERS_UP,
              },
            ]
          }
          onChange={({ val }) => setSelectedSort(val)}
        />
      </MobileFiltersContainer>
    </div>
  </div>
);

FiltersRow.propTypes = {
  values: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

export default FiltersRow;
