import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { Page404, Auth } from '../../../pages';


const Routes = ({ location }) => (
  <Switch>
    <Route
      exact
      strict
      path="/:url*"
      render={() => location.pathname.slice(-1) !== '/' && <Redirect to={`${location.pathname}/`} />}
    />
    <Route path="/forgot-password/" component={Auth.ForgotPassword} />
    <Route path="/forgot-password-success" component={Auth.ForgotPasswordSuccess} />
    <Route path="/reset-password/:userId/:code" component={Auth.ResetPassword} />
    {/* <Route exact path="/sign-up" component={Auth.SignUp} /> */}
    {/* <Route path="/sign-up-success" component={Auth.SignUpSuccess} /> */}
    <Route path="/join-your-team/:userId/:code" component={Auth.JoinYourTeam} />
    <Route exact path="/" component={Auth.SignIn} />
    <Route component={Page404} />
  </Switch>
);

Routes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(Routes);
