/* eslint-disable no-unused-vars */
import {
  compose, lifecycle, withContext, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import {
  propOr, pathOr, values, prop,
} from 'ramda';
import PropTypes from 'prop-types';

import { messengerActions, messengerSelectors } from '../../state/messenger';
import { withGroupChannels } from '../../utils/enchancers';
import Messenger from './messenger';
import { CHANNELS_URL, DIRECTS_URL } from '../../constants/messenger';
import withPermission from '../../utils/enchancers/withPemission';
import { MODULES } from '../../constants/pemissions';

const mapStateToProps = state => ({
  activeChannel: messengerSelectors.getActiveChannelEntity(state),
  groupChannels: messengerSelectors.getGroupChannels(state),
});

const mapDispatchToProps = ({
  setActiveChannel: messengerActions.setActiveChannel,
  getChannelRequest: messengerActions.getChannelRequest,
  readMessageRequest: messengerActions.readMessagesRequest,
  getDirectChannelsRequest: messengerActions.getDirectChannelsRequest,
});

const getChannelId = ({ groupChannels, match }) => {
  const channelId = pathOr(propOr(null,
    'id', values(groupChannels)[0]), ['params', 'id'], match);
  return channelId || channelId;
};

const enhancer = compose(
  withPermission([MODULES.CHANNELS]),
  connect(mapStateToProps, mapDispatchToProps),
  withGroupChannels({}),
  withContext({
    isLoadingChannel: PropTypes.bool,
  }, ({
    groupChannels,
    match: { url },
  }) => ({
    isLoadingChannel: !values(groupChannels)[0]
      || !url === CHANNELS_URL || !url === DIRECTS_URL,
  })),
  withStateHandlers(() => ({ userProfileId: null }), {
    setUserProfileId: () => val => ({
      userProfileId: val || null,
    }),
  }),
  lifecycle({
    componentDidMount() {
      const {
        match,
        getChannelRequest,
        groupChannels,
        getDirectChannelsRequest,
      } = this.props;
      if (match.url !== CHANNELS_URL || match.url !== DIRECTS_URL) {
        const channelId = getChannelId({ groupChannels, match });
        getChannelRequest(channelId || prop('id', values(groupChannels)[0]));
      }
      getDirectChannelsRequest(null, { limit: 7, offset: 0 });
    },
    componentWillUnmount() {
      const { match, groupChannels, getChannelRequest } = this.props;
      this.props.setActiveChannel(null);
      if (match.url !== CHANNELS_URL || match.url !== DIRECTS_URL) {
        const channelId = getChannelId({ groupChannels, match });
        getChannelRequest(channelId || prop('id', values(groupChannels)[0]));
      }
    },
  }),
);

export default enhancer(Messenger);
