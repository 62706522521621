/* eslint-disable no-throw-literal */
import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from '../../errorBoundary';

const ErrorNotification = () => {
  throw 'Wrapper must have name';
};

const Wrapper = ({
  children, className, name, pagination,
}) => (
  <>
    <ErrorBoundary render={e => <div>{e}</div>}>
      <div className={className}>
        { !name && <ErrorNotification />}
        {children}
      </div>
    </ErrorBoundary>
    {pagination && pagination}
  </>
);

Wrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pagination: PropTypes.element,
};

Wrapper.defaultProps = {
  pagination: <> </>,
};

export default Wrapper;
