/* eslint-disable no-param-reassign */

import {
  path, prop, propOr, filter,
} from 'ramda';
import { normalize } from 'normalizr';
import tasksTypes from '../../../state/tasks/types';

import {
  getBacklogTasksList,
  getProjectBacklogTasks, getProjectSprintsTasks,
  getProjectSprintTasksList, getProjectSprintTasks, getAgenda,
} from '../../../state/project/selectors';
import { deleteTask } from '../../helpers/taskHelpers/crudHelper';
import { tasksSchema } from '../../../state/project/schema';
import { setTaskToBacklog, setTaskToSprint } from '../../helpers/projectHelpers/crudHelper';
import * as taskActions from '../../../state/task/actions';
import { projectActions, projectSelectors } from '../../../state/project';


const setTaskMiddleware = ({ getState }) => next => (action) => {
  if (action.type === tasksTypes.SET_TASK) {
    const { payload: { task, meta = {} } } = action;
    const isTaskHasSprint = prop('sprint_id', task);
    const getAuthor = path(['entities', 'author']);
    const getAssigneeUser = path(['entities', 'assigneeUser']);
    const getTask = path(['entities', 'tasks']);
    const data = normalize(task, tasksSchema);
    const isUpdate = propOr(false, 'isUpdate', meta);
    if (meta.isSetActiveTask) next(taskActions.updateActiveTask(action.payload));
    if (isUpdate) {
      const sprintId = projectSelectors.getTask(getState())(task.id, task.project_id).sprint_id;
      const tasksList = projectSelectors.getBacklogTasksList(getState())(task.project_id);
      const newTaskResult = filter(id => id !== task.id, tasksList);
      next(projectActions.reorderBacklogTasks({
        sprintId,
        projectId: task.project_id,
        tasks: newTaskResult,
      }));
      const tasksList2 = projectSelectors.getProjectSprintTasksList(getState())(sprintId
        || task.sprint_id, task.project_id);
      const newTaskResult2 = filter(id => id !== task.id, tasksList2);
      next(projectActions.reorderSprintTasks({
        sprintId: sprintId || task.sprint_id,
        projectId: task.project_id,
        tasks: newTaskResult2,
      }));
    }
    if (!isTaskHasSprint) {
      setTaskToBacklog(getAuthor(data), getAssigneeUser(data), getTask(data), task.id,
        task.project_id || task.projectId, next);
    } else {
      setTaskToSprint(getAuthor(data), getAssigneeUser(data), getTask(data), task.id,
        task.project_id || task.projectId, next);
    }
  } else {
    next(action);
  }
};

const deleteTaskMiddleware = ({ getState }) => next => (action) => {
  if (action.type === tasksTypes.DELETE_TASK) {
    const { payload: { task, meta } } = action;
    const isTaskHasSprint = prop('sprint_id', task);
    const isTaskHasProject = propOr(prop('projectId', task), 'project_id', task);
    const sprintId = prop('sprint_id', task);
    const state = getState();
    const isAgendaBoard = propOr(false, 'isAgendaBoard', meta);
    const statusId = propOr(null, 'statusId', meta);
    const agendaStatuses = getAgenda(state);

    if (isTaskHasProject) {
      const tasks = getProjectSprintTasks(state)(isTaskHasProject);
      const tasksList = isTaskHasSprint
        ? getProjectSprintTasksList(state)(sprintId, isTaskHasProject)
        : getBacklogTasksList(state)(isTaskHasProject);

      const tasksEntities = isTaskHasSprint
        ? getProjectSprintsTasks(state)(isTaskHasProject)
        : getProjectBacklogTasks(state)(isTaskHasProject);
      deleteTask(tasksEntities,
        tasksList,
        task.id, sprintId, next, isAgendaBoard, statusId, agendaStatuses, tasks, isTaskHasProject,
        isTaskHasSprint);
    }
  } else {
    next(action);
  }
};

const setProjectTasksMiddleware = () => next => (action) => {
  next(action);
};

export {
  setTaskMiddleware,
  deleteTaskMiddleware,
  setProjectTasksMiddleware,
};
