const TASK = 'TASK';
const TASKS = 'TASKS';

export default {
  GET_TASKS_REQUEST: `GET_${TASKS}_REQUEST`,
  GET_TASK_REQUEST: `GET_${TASK}_REQUEST`,

  ADD_TASK_REQUEST: `ADD_${TASK}_REQUEST`,
  UPDATE_TASK_REQUEST: `UPDATE_${TASK}_REQUEST`,
  DELETE_TASK_REQUEST: `DELETE_${TASK}_REQUEST`,

  SET_TASKS: `SET_${TASKS}`,
  SET_TASK: `SET_${TASK}`,
  DELETE_TASK: `DELETE_${TASK}`,
};
