import React from 'react';
import PropTypes from 'prop-types';

import {
  ModalWindow, FormsCollection,
} from '../../../../../components';
import './style.sass';

const ModalView = ({
  isOpen,
  onCloseModal,
  handleSubmit,
  handleChange,
  values,
  errors,
  isPending,
  title,
  titleAction,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    className="edit-timeEntry__modal"
    title={title}
  >
    <div id="step-1y" className="card-body p-1">
      <FormsCollection.FormWrapper
        onSubmit={handleSubmit}
        className="edit-timeEntry"
      >
        <div className="form-row">
          <div className="col-lg-4">
            <FormsCollection.DatePicker
              name="date"
              onChange={handleChange}
              value={values.date}
              error={errors.date}
              touched
            />
          </div>
          <div className="col-lg-4">
            <FormsCollection.Input
              name="time"
              id="create-task__estimated-time"
              placeholder={t('1w 20m 21h 30m')}
              onChange={handleChange}
              value={values.time}
              error={errors.time}
              touched
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <button
              pending={isPending}
              className="btn btn-primary"
              type="submit"
            >
              {titleAction}
            </button>
          </div>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  isPending: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default ModalView;
