import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const Auth = ({ children }) => (
  <>
    {children}
  </>
);

Auth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]),
};

Auth.defaultProps = {
  children: '',
};

export default Auth;
