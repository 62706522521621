import Ws from '@adonisjs/websocket-client';

function Socket() {
  function socketConnection(token) {
    window.ws = Ws(process.env.REACT_APP_WS_URL);
    window.ws.withJwtToken(token).connect({ reconnection: true });
    window.ws.on('open', () => {});
    window.ws.on('error', e => console.log('Some socket error', e));
  }

  function subscribe(topic) {
    const getSubscription = window.ws.getSubscription(topic);
    if (!getSubscription) {
      return window.ws.subscribe(topic);
    }
    return getSubscription;
  }

  return {
    initSocketConnection() {
      if (!window.ws) {
        socketConnection(window.token ? window.token : localStorage.getItem('authToken'));
      }
    },
    getSocketConnection() {
      if (!window.ws) {
        this.initSocketConnection();
      }
      return {
        ws: window.ws,
        subscribe: topic => subscribe(topic),
      };
    },
  };
}

export default Socket();
