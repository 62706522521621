import React from 'react';
import PropTypes from 'prop-types';

import { Emoji } from 'emoji-mart';

const CategoryItem = ({ category }) => (
  <li className="emoji__picker-category-item">
    <a href={`#emoji__category-${category.id}`} className="emoji__picker-category-button" title={category.name}>
      <Emoji
        emoji={category.emojis[0]}
        size={24}
      />
    </a>
  </li>
);

CategoryItem.propTypes = {
  category: PropTypes.instanceOf(Object).isRequired,
};

export default CategoryItem;
