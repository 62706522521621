import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { path } from 'ramda';

import { withRouter } from 'react-router';
import { openModal } from '../../../../state/ui/actions';
import Backlog from './backlog';
import { projectSelectors } from '../../../../state/project';

const mapDispatchToProps = {
  openModal,
};

const mapStateToProps = (state, { match }) => ({
  tasks: projectSelectors.getBacklogTasksList(state)(path(['params', 'id'], match)),
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['tasks']),
  withProps(() => ({ gridName: 'backlog-grid' })),
);

export default enhance(Backlog);
