import { compose, getContext, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MaterialItem from './materialItem';
import { materialsActions, materialsSelectors } from '../../../../../../state/materials';
import { offlineRequestActions } from '../../../../../../utils/middlewares/sagaRequestApi/state/offlineRequest';

const mapStateToProps = (state, { materialId }) => ({
  material: materialsSelectors.getMaterial(state)(materialId),
});

const mapDispatchToProps = ({
  resetOfflineAction: offlineRequestActions.resetOfflineAction,
  deleteMaterial: materialsActions.deleteMaterial,
  updateMaterial: materialsActions.updateMaterial,
});

const onEditHandler = ({ onEditMaterialModal, materialId }) => () => onEditMaterialModal(materialId);
const onDeleteHandler = ({ onDeleteMaterialModal, materialId }) => () => onDeleteMaterialModal(materialId);
const onResetOfflineDeleteHandler = ({
  updateMaterial, resetOfflineAction, material,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: material.requestIdDelete, method: 'DELETE', selectorName: 'deleteMaterialRequest' });
  updateMaterial({
    material: {
      ...material,
      isDeleting: false,
    },
  });
};
const onResetOfflineEditHandler = ({
  updateMaterial, resetOfflineAction, material,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: material.requestIdPut, method: 'PUT', selectorName: 'updateMaterialRequest' });
  updateMaterial({
    material: {
      id: material.id,
      ...material.oldResult,
      isUpdating: false,
    },
  });
};
const onResetOfflineCreateHandler = ({
  deleteMaterial, resetOfflineAction, material,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: material.requestIdPost, method: 'POST', selectorName: 'createMaterialRequest' });
  deleteMaterial({ material });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    onEditMaterialModal: PropTypes.func.isRequired,
    onDeleteMaterialModal: PropTypes.func.isRequired,
  }),
  withHandlers({
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
    onResetOfflineDelete: onResetOfflineDeleteHandler,
    onResetOfflineEdit: onResetOfflineEditHandler,
    onResetOfflineCreate: onResetOfflineCreateHandler,
  }),
);
export default enhance(MaterialItem);
