import { createSelector } from 'reselect/lib/index';
import {
  pathOr, identity, memoizeWith,
} from 'ramda';

const getEvents = createSelector(
  pathOr({}, ['events', 'entities']), identity,
);

const getEventsResult = createSelector(
  pathOr([], ['events', 'result']), identity,
);

const getEvent = createSelector(
  getEvents,
  events => memoizeWith(identity, eventId => pathOr({}, [eventId], events)),
);

const getPendingGetEvents = createSelector(
  pathOr(false, ['pending', 'getEventsRequest']), identity,
);

const getPendingUpdateEvent = createSelector(
  pathOr(false, ['pending', 'updateEventRequest']), identity,
);

export {
  getEvents,
  getEvent,
  getEventsResult,

  getPendingGetEvents,
  getPendingUpdateEvent,
};
