import {
  compose, lifecycle, pure, withHandlers,
} from 'recompose';
import { withRefs } from '../../utils/enchancers';

import ModalWindow from './modalWindow';

const clickOutside = ({ getRef, onClose, isOpen }) => (e) => { isOpen && !getRef('bodyModal').contains(e.target) && window.confirm('You are sure want close this modal window ?') && onClose(); };

const enhance = compose(
  pure,
  withRefs(),
  withHandlers({
    clickOutsideHandler: clickOutside,
  }),
  lifecycle({
    componentDidUpdate() {
      const { getRef, clickOutsideHandler } = this.props;
      const container = getRef('modalContainer');
      if (container) {
        getRef('modalContainer').addEventListener('click', clickOutsideHandler, true);
      }
    },
    componentWillUnmount() {
      const { getRef, clickOutsideHandler } = this.props;
      const container = getRef('modalContainer');
      if (container) {
        container.removeEventListener('click', clickOutsideHandler, true);
      }
    },
  }),
);

export default enhance(ModalWindow);
