import React from 'react';
import PropTypes from 'prop-types';
import { RightSideBar } from '../../../../containers';
import { ScrollWrapper, TabWrapper } from '../../../../components';
import {
  ActiveTabTitle, AboutProject, ProjectSummary, Members,
} from '..';
import {
  ImportantTask,
} from '../../../../containers/rightSideBar/components';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import './style.sass';

const RightSideBarContainer = ({
  setActiveTabTitleStateHandler, windowWidth, activeTabTitle, isNotPublic,
  projectId, t,
}) => (
  <>
    {
      windowWidth > WINDOW_WIDTH.SMALL ? (
        <RightSideBar className="right-side-bar--project" title="Project summary">
          <ScrollWrapper className="right-side-bar__scroll-wrapper">
            <AboutProject projectId={projectId} />
            <ProjectSummary />
            <ImportantTask
              projectId={projectId}
              activeTabTitle={activeTabTitle}
              setActiveTitle={setActiveTabTitleStateHandler}
            />
            {/* <TabWrapper title={( */}
            {/*  <h2 className="title"> */}
            {/*    Pinned tasks */}
            {/*  </h2> */}
            {/* )} */}
            {/* > */}
            {/*  <TaskList className="pinned-tasks--right-side-bar" /> */}
            {/* </TabWrapper> */}
            {
              isNotPublic && (
                <TabWrapper
                  title={(
                    <h3 className="title">
                      {t('Members')}
                    </h3>
                  )}
                >
                  <Members
                    activeTabTitle={activeTabTitle}
                    projectId={projectId}
                    setActiveTitle={setActiveTabTitleStateHandler}
                  />
                </TabWrapper>
              )
            }
          </ScrollWrapper>
        </RightSideBar>
      ) : (
        <RightSideBar
          className="right-side-bar--project"
          titleComponentRender={activeTabTitle ? (
            () => (
              <ActiveTabTitle
                onClick={() => setActiveTabTitleStateHandler()}
                title={activeTabTitle}
              />
            )
          ) : (
            () => <h3>{t('Project summary')}</h3>
          )}
        >
          <ScrollWrapper className="right-side-bar__scroll-wrapper">
            <AboutProject projectId={projectId} />
            <ProjectSummary />
            <ImportantTask
              projectId={projectId}
              activeTabTitle={activeTabTitle}
              setActiveTitle={setActiveTabTitleStateHandler}
            />
            {/* <TabWrapperMobile
                  setActiveTitle={setActiveTabTitleStateHandler}
                  title="Pinned tasks"
                  active={activeTabTitle}> */}
            {/*  <TaskList className="pinned-tasks--right-side-bar" /> */}
            {/* </TabWrapperMobile> */}
            {
              isNotPublic && (
                <Members
                  activeTabTitle={activeTabTitle}
                  projectId={projectId}
                  setActiveTitle={setActiveTabTitleStateHandler}
                />
              )
            }
          </ScrollWrapper>
        </RightSideBar>
      )
    }
  </>
);

RightSideBarContainer.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  activeTabTitle: PropTypes.string,
  projectId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  setActiveTabTitleStateHandler: PropTypes.func.isRequired,
  isNotPublic: PropTypes.bool.isRequired,
};
RightSideBarContainer.defaultProps = {
  activeTabTitle: '',
};

export default RightSideBarContainer;
