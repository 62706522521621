import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import './style.sass';

const Number = ({
  placeholder,
  setRef,
  middlewareMask,
  onChange,
  handlerValue,
  customRef,
  getRef,
  onFocus,
  onBlur,
  specialKeysHandle,
}) => (
  <div className="field-number">
    <MaskedInput
      mask={[/\d/, /\d/]}
      placeholder={placeholder}
      onChange={() => onChange({
        value: getRef('input').value,
      })}
      ref={(e) => { setRef('input', e); customRef(e); }}
      maskChar=""
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={specialKeysHandle}
      beforeMaskedValueChange={e => middlewareMask(e)}
      className="field-number__place-write"
    />
    <div className="field-number__controls">
      <div role="button" className="field-number__plus" onClick={() => handlerValue(1)} onKeyPress={() => handlerValue(1)} tabIndex="0">⯅</div>
      <div role="button" className="field-number__minus" onClick={() => handlerValue(-1)} onKeyPress={() => handlerValue(-1)} tabIndex="0">⯆</div>
    </div>
  </div>
);

Number.propTypes = {
  placeholder: PropTypes.string,
  middlewareMask: PropTypes.func,
  setRef: PropTypes.func,
  getRef: PropTypes.func,
  onChange: PropTypes.func,
  handlerValue: PropTypes.func,
  customRef: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  specialKeysHandle: PropTypes.func,
};
Number.defaultProps = {
  onChange: () => {},
  placeholder: '',
  middlewareMask: () => {},
  setRef: () => {},
  getRef: () => {},
  handlerValue: () => {},
  onFocus: () => {},
  onBlur: () => {},
  specialKeysHandle: () => {},
  customRef: () => {},
};
export default Number;
