import { handleActions } from 'redux-actions';
import {
  always, keys, pathOr, uniq, ifElse, map, reduce,
} from 'ramda';

import {
  mergeDeepByPath,
  mergeDeep,
  mergeByPath,
  mergeIn,
} from '../../utils/helpers/ramdaStateHelpers';

import types from './types';

const filterTaskWhenOnline = (list = []) => {
  // todo
  const newArr = ifElse(() => (window.navigator.onLine), () => reduce((accum, index) => ({ ...accum, [index]: { ...list[index], isUpdating: false, isPending: false } }), {}, keys(list)), always(list))();
  return newArr;
};

const reducer = handleActions({
  [types.SET_CATEGORY_TASK]: mergeDeepByPath(['entities', 'categories'], ({ payload: { category } }) => ({
    [category.id]: category,
  })),
  [types.DELETE_CATEGORY_TASK_ENTITY]: mergeByPath(['entities'], ({ payload }) => ({ categories: payload })),
  [types.REORDER_CATEGORY_TASK]: mergeByPath(['result'], ({ payload }) => ({ categories: payload })),
  [types.SET_PROJECT]: mergeDeep(({ payload }) => {
    if (payload.data) {
      const { entities, result: { summary, ...result } } = payload.data;
      return (
        {
          entities,
          result: { ...result, members: entities.data[payload.project.id].members },
          summary,
        }
      );
    }
    return (
      {
        entities: {
          data: {
            [payload.project.id]: payload.project,
          },
        },
      }
    );
  }),
  [types.SET_SPRINTS]: mergeDeep(({ payload: { data, meta: { projectId } } }) => ({
    [projectId]: {
      ...data,
      entities: {
        sprints: map(item => ({
          ...item, isPending: false, idDeleting: false, isUpdating: false,
        }), data.entities.sprints),
      },
    },
  })),
  [types.SET_PROJECT_TASKS]: mergeDeep(({ payload: { data, meta } }) => ({
    [meta.projectId]: {
      ...data,
      entities: {
        ...data.entities,
        tasks: filterTaskWhenOnline(data.entities.tasks),
        backlog: filterTaskWhenOnline(data.entities.backlog),
      },
    },
  })),
  [types.SET_SPRINT]: mergeDeep(({ payload }, state) => {
    if (payload.isOnlineUpdate) {
      return {
        [payload.sprint.project_id]: {
          entities: {
            sprints: {
              ...pathOr({}, [payload.project_id, 'entities', 'sprints'], state),
              [payload.sprint.id]: payload.sprint,
            },
          },
          result: {
            sprints: uniq([...pathOr([], [payload.sprint.project_id, 'result', 'sprints'], state), payload.sprint.id]),
          },
        },
      };
    }
    return {
      [payload.project_id]: {
        entities: {
          sprints: {
            ...pathOr({}, [payload.project_id, 'entities', 'sprints'], state),
            [payload.id]: payload,
          },
        },
        result: {
          sprints: uniq([...pathOr([], [payload.project_id, 'result', 'sprints'], state), payload.id]),
        },
      },
    };
  }),
  [types.REORDER_SPRINT_TASKS]: mergeDeep(({ payload: { sprintId, projectId, tasks = [] } },
    state) => ({
    [projectId]: {
      entities: {
        sprints: {
          [sprintId]: {
            ...state[projectId].entities.sprints[sprintId],
            tasks: [...tasks],
          },
        },
      },
    },
  })),
  [types.SET_SPRINT_TASK_TO_LIST]: mergeDeep(({ payload: { sprintId, itemId, projectId } },
    state) => ({
    [projectId]: {
      entities: {
        sprints: {
          [sprintId]: {
            ...pathOr({}, [projectId, 'entities', 'sprints', sprintId], state),
            tasks: uniq([...pathOr([], [projectId, 'entities', 'sprints', sprintId, 'tasks'],
              state), itemId]),
          },
        },
      },
    },
  })),
  [types.REORDER_BACKLOG_TASKS]: mergeDeep(({ payload: { tasks, projectId } }, state) => ({
    [projectId]: {
      result: {
        ...pathOr({}, [projectId, 'result'], state),
        backlog: [...tasks],
      },
    },
  }
  )),
  [types.SET_BACKLOG_TASK_TO_LIST]: mergeDeep(({ payload: { itemId, projectId } }, state) => ({
    [projectId]: {
      result: {
        backlog: uniq([...pathOr([], [projectId, 'result', 'backlog'], state), itemId]),
      },
    },
  }
  )),
  [types.SET_BACKLOG_TASK]: mergeDeep(({ payload }) => {
    const task = payload[keys(payload)[0]];
    return {
      [task.project_id || task.projectId]: {
        entities: {
          backlog: payload,
        },
      },
    };
  }),
  [types.SET_SPRINT_TASK]: mergeDeep(({ payload }) => {
    const task = payload[keys(payload)[0]];
    return {
      [task.project_id || task.projectId]: {
        entities: {
          tasks: payload,
        },
      },
    };
  }),
  [types.SET_USERS_WITH_TASKS]: mergeDeep(({ payload: { data: { result, entities } } }) => ({
    entities,
    result: ({ usersWithTasks: uniq(result) }),
  })),
  [types.SET_CATEGORIES_TASK]: mergeIn((action, state) => ({
    entities: {
      ...state.entities,
      ...action.payload.data.entities,
    },
    result: {
      ...state.result,
      ...{ categories: action.payload.data.result },
    },
  })),
  [types.SORT_BACKLOG_TASKS]: mergeDeep(({ payload: { data: { result }, meta } }) => ({
    [meta.projectId]: {
      result: {
        backlog: [...result],
      },
    },
  })),
  [types.SORT_SPRINT_TASKS]: mergeDeep(({ payload: { data: { result }, meta } }) => ({
    [meta.projectId]: {
      entities: {
        sprints: {
          [meta.sprintId]: ({ tasks: [...result] }),
        },
      },
    },
  })),
  [types.REMOVE_SPRINT_TASK]: mergeByPath(['entities'], ({ payload }) => ({ tasks: payload })),
  [types.REMOVE_BACKLOG_TASK]: mergeByPath(['entities'], ({ payload }) => ({ backlog: payload })),
  [types.SET_AGENDA_TASKS]: mergeDeep(({
    payload: {
      data,
      meta: { projectId },
    },
  }) => ({ [projectId]: data })),
  [types.REORDER_AGENDA_TASKS]: mergeDeep(({ payload: { status, tasks, projectId } }) => ({
    [projectId]: {
      entities: {
        agenda: { [status]: ({ tasks: uniq([...tasks]) }) },
      },
    },
  })),
  [types.REMOVE_BACKLOG_TASK]: mergeByPath(['entities'], ({ payload }) => ({ backlog: payload })),
  [types.DELETE_PROJECT_TASK]: mergeDeep(({
    payload:
    { entities: { agenda, sprints, tasks }, result = {}, projectId },
  }, state) => ({
    [projectId]: {
      entities: {
        ...state[projectId].entities,
        agenda,
        sprints,
        tasks,
      },
      result: { ...state[projectId].result, ...result },
    },
  })),
  [types.REORDER_SPRINT]: mergeDeep(({ payload }) => ({
    [payload.projectId]: { result: { sprints: payload.result } },
  })),
  [types.DELETE_SPRINT_ENTITY]: mergeByPath(['entities'], ({ payload }) => ({ sprints: payload })),
  [types.SET_IMPORTANT_TASKS]: mergeDeep(({
    payload: { data: { entities: { tasks }, result } },
  }) => ({
    entities: { importantTasks: tasks },
    result: { importantTasks: result },
  })),
  [types.SET_NOTES]: mergeDeep(({ payload: { data: { entities, result } } }) => ({
    entities,
    result: {
      documents: result,
    },
  })),
  [types.SET_NOTE]: mergeIn(({ payload: { document } }, { result, entities }) => ({
    entities: {
      ...entities,
      documents: { ...entities.documents, [document.id]: document },
    },
    result: {
      ...result,
      documents: uniq([...result.documents, document.id]),
    },
  })),
  [types.REORDER_NOTES]: mergeByPath(['result'], ({ payload }) => ({ documents: payload })),
  [types.DELETE_NOTE_ENTITY]: mergeByPath(['entities'], ({ payload }) => ({ documents: payload })),
  [types.SET_PROJECT_MEMBERS]: mergeDeep(({
    payload:
    { data: { entities }, project: { id } },
  }) => ({
    result: {
      members: entities.data[id].members,
    },
  })),
  // [types.SET_PROJECT_MEMBERS]: (state, action) => {
  //   console.log('state', state);
  //   console.log('action', action);
  // },
  [types.REORDER_PROJECT_MEMBERS]: mergeByPath(['result'], ({ payload }) => ({ members: payload })),
  [types.CLEAR_PROJECT_DATA]: () => [],
}, {});

export default reducer;
