import React from 'react';
import PropTypes from 'prop-types';

import { FormsCollection, ModalWindow } from '../../../../components';

const EditUser = ({
  isOpen,
  onCloseModal,
  handleChange,
  handleSubmit,
  values,
  roles,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    modalName="pinMessage"
    title="Edit user"
    className="new-sprint__modal"
  >
    <div id="step-1y" className="card-body p-1">
      <FormsCollection.FormWrapper onSubmit={handleSubmit}>
        <div className="form-row">
          <FormsCollection.Input
            label="Name"
            className="col-md-6"
            id="edit-user__email"
            name="username"
            placeholder="User name"
            value={values.username}
            onChange={handleChange}
          />
          <FormsCollection.Input
            label="Position"
            className="col-md-6"
            id="edit-user__position"
            placeholder="User position"
            name="position"
            value={values.position}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <FormsCollection.Input
            label="First name"
            className="col-md-6"
            id="edit-user__fName"
            name="first_name"
            value={values.first_name}
            onChange={handleChange}
          />
          <FormsCollection.Input
            label="Last name"
            className="col-md-6"
            id="edit-user__lName"
            name="last_name"
            value={values.last_name}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <FormsCollection.Input
            label="Email"
            className="col-md-12"
            id="edit-user__email"
            name="email"
            value={values.email}
            onChange={handleChange}
            placeholder="example@gmail.com"
          />
          <FormsCollection.Select
            label="User role"
            className="col-md-12"
            id="edit-user__role"
            name="role_id"
            value={values.role_id}
            onChange={handleChange}
            data={roles}
          />
        </div>
        <button type="submit" className="btn btn-primary">Save Changes</button>
      </FormsCollection.FormWrapper>
    </div>
  </ModalWindow>
);

EditUser.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  roles: PropTypes.instanceOf(Object).isRequired,
};

export default EditUser;
