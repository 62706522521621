import { compose } from 'recompose';
import { connect } from 'react-redux';
import ModulesList from './modulesList';

import { uiActions, uiSelectors } from '../../../../state/ui';

const { changeModulesTabStatus } = uiActions;
const { getTabStatus } = uiSelectors;

const mapStateToProps = state => ({
  isModulesTabOpen: getTabStatus(state)('isModulesTabOpen'),
});

const mapDispatchToProps = ({
  changeModulesTabStatus,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
);
export default enhance(ModulesList);
