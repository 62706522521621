import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '..';
import './style.sass';

const Pin = ({
  status, onClick, className, title, t,
}) => (
  <ButtonsCollection.ButtonIcons
    className={`pin-button ${className} ${status ? 'pin-star--active' : ''}`}
    onClick={onClick}
    title={`${status ? t(`Unpin ${title}`) : t(`Pin ${title}`)}`}
  >
    {
      status ? (
        <span className="icon-star-empty-project--active" />
      ) : (
        <span className="icon-pin" />
      )
    }
  </ButtonsCollection.ButtonIcons>
);
Pin.propTypes = {
  status: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  t: PropTypes.func.isRequired,
};
Pin.defaultProps = {
  status: false,
  className: '',
  title: '',
  onClick: () => {},
};
export default Pin;
