import React from 'react';
import PropTypes from 'prop-types';
import { ScrollWrapper, FormsCollection, MobileFiltersContainer } from '../../components';
import {
  FiltersRow,
  TasksList,
} from './components';
import './style.sass';
import { WINDOW_WIDTH } from '../../constants/ui';
import projectImage from '../../assets/otherAssets/labelDefault.png';

const data = [
  {
    id: 'task-el__id-1',
    projectName: 'Facebook',
    projectImage,
    tasks: [
      {
        id: '29291',
        title: 'Some long task name for test lorem ipsum sit ammet liremslslsls for test lorem '
          + 'ipsum sit ammet liremslslslsfor test lorem ipsum sit ammet liremslslslsfor test lorem '
          + 'ipsum sit ammet liremslslslsfor test lorem ipsum sit ammet liremslslsls',
        status: 0, // 0 - new, 1 - urgent, 2 - solved, 3 - feedback, 4 - closed
        priority: 0, // 0 - red, 1 - green, 2 - pink, 3 - blue, 4 - empty
        author: 'Mr. Pedro',
        assignee: 'Little Slave',
        done: 10,
        spent: '20h 20m',
        estimation: '10h 10m',
        deadline: '10/22/2019',
      },
      {
        id: '29292',
        title: 'Some long task name for test lorem ipsum sit ammet liremslslsls',
        status: 1,
        priority: 2,
        author: 'Mr. Pedro',
        assignee: 'Little Slave',
        done: 29,
        spent: 10,
        estimation: 20,
        deadline: '10/22/2019',
      },
      {
        id: '29293',
        title: 'Реализация покупки на сайте P2P',
        status: 2,
        priority: 2,
        author: 'Ms Elizabet',
        assignee: 'Little Slave',
        done: 79,
        spent: 10,
        estimation: 20,
        deadline: '10/22/2019',
      },
      {
        id: '29294',
        title: 'Реализация покупки на сайте P2P',
        status: 4,
        priority: 2,
        author: 'Mr. Pedro',
        assignee: 'Little Slave',
        done: 29,
        spent: 10,
        estimation: 20,
        deadline: '10/22/2019',
      },
      {
        id: '2929',
        title: 'Реализация покупки на сайте P2P',
        status: 2,
        priority: 1,
        author: 'Ms Elizabet',
        assignee: 'Little Slave',
        done: 100,
        spent: 10,
        estimation: 20,
        deadline: '10/22/2019',
        pin: true,
      },
    ],
    estimation: '20h 10m',
    spent: '3d 40h 20m',
  },
  {
    id: 'task-el__id-2',
    projectName: 'Coca-Cola',
    projectImage,
    tasks: [
      {
        id: '1',
        title: 'Реализация покупки на сайте P2P',
        status: 0, // 0 - new, 1 - urgent, 2 - solved, 3 - feedback, 4 - closed
        priority: 0, // 0 - red, 1 - green, 2 - pink, 3 - blue, 4 - empty
        author: 'Mr. Pedro',
        assignee: 'Little Slave',
        done: 10,
        spent: 10,
        estimation: 20,
        deadline: '10/22/2019',
      },
    ],
    estimation: '20h 10m',
    spent: '3d 40h 20m',
  },
  {
    id: 'task-el__id-3',
    projectName: 'Nike',
    projectImage,
    tasks: [
      {
        id: '29281',
        title: 'Реализация покупки на сайте P2P',
        status: 0, // 0 - new, 1 - urgent, 2 - solved, 3 - feedback, 4 - closed
        priority: 0, // 0 - red, 1 - green, 2 - pink, 3 - blue, 4 - empty
        author: 'Mr. Pedro',
        assignee: 'Little Slave',
        done: 10,
        spent: 10,
        estimation: 20,
        deadline: '10/22/2019',
      },
      {
        id: '29282',
        title: 'Реализация покупки на сайте P2P',
        status: 1,
        priority: 3,
        author: 'Mr. Pedro',
        assignee: 'Little Slave',
        done: 29,
        spent: 10,
        estimation: 20,
        deadline: '10/22/2019',
      },
      {
        id: '29283',
        title: 'Реализация покупки на сайте P2P',
        status: 2,
        priority: 2,
        author: 'Ms Elizabet',
        assignee: 'Little Slave',
        done: 79,
        spent: 10,
        estimation: 20,
        deadline: '10/22/2019',
      },
      {
        id: '29284',
        title: 'Реализация покупки на сайте P2P',
        status: 4,
        priority: 2,
        author: 'Mr. Pedro',
        assignee: 'Little Slave',
        done: 29,
        spent: 10,
        estimation: 20,
        deadline: '10/22/2019',
      },
      {
        id: '29285',
        title: 'Реализация покупки на сайте P2P',
        status: 2,
        priority: 1,
        author: 'Ms Elizabet',
        assignee: 'Little Slave',
        done: 100,
        spent: 10,
        estimation: 20,
        deadline: '10/22/2019',
        pin: true,
      },
    ],
    estimation: 20,
    spent: 10,
  },
];

const MyTasksWindow = ({
  match, setGroupByStateHandler, groupBy,
}) => (
  <ScrollWrapper>
    <div className="my-tasks-window__tasks-filters">
      <FormsCollection.Search className="search-field--circle" name="search" placeholder="Search by project name" />
      <MobileFiltersContainer title="Filter" onWidth={WINDOW_WIDTH.SMALL}>
        <FiltersRow setGroupBy={setGroupByStateHandler} />
      </MobileFiltersContainer>
    </div>
    <TasksList spentTime="15h 45m" estimationTime="20h 30m" match={match} data={data} sortByProject={groupBy} />
  </ScrollWrapper>
);

MyTasksWindow.propTypes = {
  match: PropTypes.shape({}),
  setGroupByStateHandler: PropTypes.func.isRequired,
  groupBy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

MyTasksWindow.defaultProps = {
  match: {},
  groupBy: 0,
};

export default MyTasksWindow;
