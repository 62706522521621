import React from 'react';

import './style.sass';
import LabelTimeMessage from '../messageHistory/components/messageItem/components/labelTimeMessage';

const LabelUnreadMessage = ({ className }) => (
  <>
    <div className="row text-align--center time-label__vertical-break-line unread-label">
      <span className="display--inline-block">
        <LabelTimeMessage className={className}>
          Unread message
        </LabelTimeMessage>
      </span>
    </div>
  </>
);

export default LabelUnreadMessage;
