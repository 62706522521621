import { compose, pure } from 'recompose';
import DragDropArea from './dragDropArea';
import { preloaderWhileLoading } from '../../../../utils/enchancers';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';

const enhance = compose(
  pure,
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    isLoading: () => false,
    onAction: ({ isLoading }) => isLoading,
  }),
);
export default enhance(DragDropArea);
