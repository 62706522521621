/* eslint-disable camelcase */
import { createActions } from 'redux-actions';
import {
  identity, pathOr,
} from 'ramda';

import types from './types';
import { messengerSchemas } from './index';
import { setUsersUpdateMiddleware } from '../../utils/helpers/stateHelpers';
import { getIsShowUnreadMessage } from '../../utils/helpers/messengerHelpers/channelHelpers';

export const {
  getLatestMessagesRequest,
  updateMessageRequest,
  getChannelRequest,
  getGroupChannelsRequest,
  getDirectChannelsRequest,
  addPinMessageRequest,
  getPinnedMessagesRequest,
  deletePinnedMessageRequest,
  submitMessageRequest,
  addMemberToChannelRequest,
  joinToChannelRequest,
  deleteMemberFromChannelRequest,
  deleteMessageRequest,
  readMessagesRequest,
  getTotalUnreadCountRequest,
  deletePinMessage,
  setLatestMessages,
  setGroupChannels,
  updateGroupChannels,
  setDirectChannels,
  setMatchedDirectChannels,
  setYouBelongToChannels,
  setYouCanJoinChannels,
  connectUserToChannel,
  setActiveChannel,
  changeChannel,
  cancelMessageRequest,
  setMoreMessages,
  setNewMessage,
  emitNewMessage,
  setHasNewMessages,
  setTimestamps,
  setLastMessageTime,
  setPinMessage,
  addChannel,
  setLoadedChannel,
  setChannel,
  setPinnedMessages,
  setUnreadCount,
  setLastMessageId,
  setCountLoadedMessage,
  updateGroupChannel,
  setMemberToChannel,
  deleteMemberFromChannel,
  redirectChannelNotFound,
  deleteChannel,
  deleteGroupChannel,
  emitUpdateMessage,
  updateMessage,
  deleteMessage,
  setReadChannel,
  textareaChanged,
  setTotalUnreadCount,
} = createActions(
  {
    [types.GET_LATEST_MESSAGES_REQUEST]: [
      // TODO: В ідеалі меседжі також мають мати просто обєкт юзера
      identity,
      ({ limit, channelId, offset }, meta) => ({
        async: true,
        params: { limit, offset },
        route: `/channels/${channelId}/messages`,
        selectorName: 'getMessagesRequest',
        method: 'GET',
        ...meta,
        actions: {
          success: offset <= 0 ? setLatestMessages : setMoreMessages,
          meta: { channelId },
        },
        schema: {
          rules: messengerSchemas.messagesSchema,
          pathData: ['data'],
        },
      }),
    ],
    [types.UPDATE_MESSAGE_REQUEST]: [
      identity,
      ({ channelId, message_id, content }, meta) => ({
        async: true,
        route: `/channels/${channelId}/messages/${message_id}`,
        selectorName: 'updateMessage',
        method: 'PUT',
        params: {
          content,
          id: channelId,
          message_id,
        },
        ...meta,
      }),
    ],
    [types.GET_CHANNEL_REQUEST]: [
      () => ({}),
      (id, meta = { isSetActive: true }) => ({
        async: true,
        route: `/channels/${id}`,
        selectorName: 'getChannelRequest',
        method: 'GET',
        schema: {
          rules: { channel: { members: messengerSchemas.membersSchema } },
          pathData: [],
        },
        actions: {
          success: setUsersUpdateMiddleware(setChannel, { ...meta, pathEntities: ['data', 'entities', 'members'] }),
          error: meta.isSetActive && redirectChannelNotFound,
        },
        ...meta,
      }),
    ],
    [types.GET_GROUP_CHANNELS_REQUEST]: [
      identity,
      (payload, { isUpdate, ...meta }) => ({
        async: true,
        route: '/channels',
        params: {
          limit: meta.limit,
          offset: meta.offset,
          name: meta.search,
          order: meta.order,
          sortBy: meta.sortBy,
          notMember: meta.notMember,
        },
        selectorName: 'getGroupChannelsRequest',
        method: 'GET',
        schema: {
          rules: messengerSchemas.groupChannelsSchema,
          pathData: ['data'],
        },
        actions: {
          success: meta.success || (isUpdate ? updateGroupChannels : setGroupChannels),
        },
        ...meta,
      }),
    ],
    [types.GET_DIRECT_CHANNELS_REQUEST]: [
      identity,
      (payload, meta) => ({
        async: true,
        route: '/channels/direct',
        params: {
          limit: meta.limit,
          offset: meta.offset,
          order: meta.order,
          name: meta.search,
          sortBy: meta.sortBy,
        },
        selectorName: 'getDirectChannelsRequest',
        method: 'GET',
        schema: {
          rules: messengerSchemas.directChannelsSchema,
          pathData: ['data'],
        },
        actions: {
          success: meta.success || setDirectChannels,
        },
        ...meta,
      }),
    ],
    [types.ADD_PIN_MESSAGE_REQUEST]: [
      identity,
      ({ channelId, message_id }, meta) => ({
        async: true,
        params: {
          id: channelId,
          message_id,
        },
        route: `/channels/${channelId}/pinned-messages/${message_id}`,
        selectorName: 'addPinMessage',
        method: 'POST',
        ...meta,
      }),
    ],
    [types.GET_PINNED_MESSAGES_REQUEST]: [
      identity,
      ({ channelId }, meta) => ({
        async: true,
        params: { channelId },
        actions: {
          success: setPinnedMessages,
          meta: {
            channelId,
          },
        },
        schema: {
          rules: messengerSchemas.pinnedMessagesSchema,
          pathData: [],
          flat: true,
        },
        route: `/channels/${channelId}/pinned-messages`,
        selectorName: 'getPinnedMessages',
        method: 'GET',
        ...meta,
      }),
    ],
    [types.DELETE_PINNED_MESSAGE_REQUEST]: [
      identity,
      ({ channelId, message_id }, meta) => ({
        async: true,
        params: {
          id: channelId,
          message_id,
        },
        route: `/channels/${channelId}/pinned-messages/${message_id}`,
        selectorName: 'deletePinMessage',
        method: 'DELETE',
        ...meta,
      }),
    ],
    [types.SUBMIT_MESSAGES_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        params,
        route: `/channels/${params.id}/messages`,
        responseSchema: ({ author }) => ({
          message: {
            ...params,
            id: new Date().getTime(),
            channel_id: params.id,
            client_msg_id: new Date().getTime(),
            ts: new Date().getTime(),
            created_by: author.id,
            first_name: author.first_name,
            last_name: author.last_name,
            avatar: author.avatar,
            isPending: true,
          },
        }),
        callbacks: {
          success: pathOr(identity, ['callbacks', 'success'], meta),
        },
        selectorName: 'submitMessageRequest',
        method: 'POST',
      }),
    ],
    [types.ADD_MEMBER_TO_CHANNEL_REQUEST]: [
      identity,
      ({ channelId, ...params }, meta) => ({
        async: true,
        params,
        route: `/channels/${channelId}/members/add`,
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        selectorName: 'addMemberToChannelRequest',
        method: 'PUT',
        ...meta,
      }),
    ],
    [types.JOIN_TO_CHANNEL_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        params,
        route: `/channels/${id}/members/join`,
        selectorName: 'joinToChannelRequest',
        schema: {
          rules: { channel: { members: messengerSchemas.membersSchema } },
          pathData: [],
        },
        actions: {
          success: updateGroupChannel,
        },
        method: 'PUT',
        ...meta,
      }),
    ],
    [types.DELETE_MEMBER_FROM_CHANNEL_REQUEST]: [
      identity,
      ({ channelId, memberId, ...params }, meta) => ({
        async: true,
        params,
        route: `/channels/${channelId}/members/${memberId}`,
        selectorName: 'deleteMemberFromChannelRequest',
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        schema: {
          rules: { channel: { members: messengerSchemas.membersSchema } },
          pathData: [],
        },
        actions: {
          success: deleteMemberFromChannel,
        },
        method: 'DELETE',
        ...meta,
      }),
    ],
    [types.DELETE_MESSAGE_REQUEST]: [
      identity,
      ({ channelId, messageId }, meta) => ({
        async: true,
        params: ({
          message_id: messageId,
          channel_id: channelId,
        }),
        route: `/channels/${channelId}/messages/${messageId}`,
        selectorName: 'deleteMessageRequest',
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        actions: {
          success: deleteMessage,
        },
        method: 'DELETE',
        ...meta,
      }),
    ],
    [types.READ_MESSAGES_REQUEST]: [
      identity,
      ({ channelId, ...params }, meta) => ({
        async: true,
        params,
        route: `/channels/${channelId}/read`,
        selectorName: 'readMessagesRequest',
        actions: {
          success: setUnreadCount,
          meta: ({
            isShowUnreadMessage: getIsShowUnreadMessage(meta),
          }),
        },
        method: 'GET',
        ...meta,
      }),
    ],
    [types.GET_TOTAL_UNREAD_COUNT_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        params,
        route: '/channels/unread-messages-count',
        selectorName: 'getTotalUnreadCountRequest',
        actions: {
          success: setTotalUnreadCount,
        },
        method: 'GET',
        ...meta,
      }),
    ],
  },
  types.DELETE_PIN_MESSAGE,
  types.SET_LATEST_MESSAGES,
  types.SET_GROUP_CHANNELS,
  types.UPDATE_GROUP_CHANNELS,
  types.SET_DIRECT_CHANNELS,
  types.SET_MATCHED_DIRECT_CHANNELS,
  types.SET_YOU_BELONG_TO_CHANNELS,
  types.SET_YOU_CAN_JOIN_CHANNELS,
  types.CONNECT_USER_TO_CHANNEL,
  types.SET_ACTIVE_CHANNEL,
  types.CHANGE_CHANNEL,
  types.CANCEL_MESSAGE_REQUEST,
  types.SET_MORE_MESSAGES,
  types.SET_NEW_MESSAGE,
  types.EMIT_NEW_MESSAGE,
  types.SET_HAS_NEW_MESSAGES,
  types.SET_TIMESTAMPS,
  types.SET_LAST_MESSAGE_TIME,
  types.SET_PIN_MESSAGE,
  types.ADD_CHANNEL,
  types.SET_LOADED_CHANNEL,
  types.SET_CHANNEL,
  types.SET_PINNED_MESSAGES,
  types.SET_UNREAD_COUNT,
  types.SET_LAST_MESSAGE_ID,
  types.SET_COUNT_LOADED_MESSAGE,
  types.UPDATE_GROUP_CHANNEL,
  types.SET_MEMBER_TO_CHANNEL,
  types.DELETE_MEMBER_FROM_CHANNEL,
  types.REDIRECT_CHANNEL_NOT_FOUND,
  types.DELETE_CHANNEL,
  types.DELETE_GROUP_CHANNEL,
  types.EMIT_UPDATE_MESSAGE,
  types.UPDATE_MESSAGE,
  types.DELETE_MESSAGE,
  types.SET_READ_CHANNEL,
  types.TEXTAREA_CHANGED,
  types.SET_TOTAL_UNREAD_COUNT,
);
