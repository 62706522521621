import React from 'react';
import PropTypes from 'prop-types';
import {
  GROUP_CHANNEL,
  PRIVATE_CHANNEL,
  GENERAL_CHANNEL,
} from '../../../../constants/messenger';
import './style.sass';

const BreadCrumbs = ({ channelType, title }) => (
  <div className="bread-crumbs bread-crumbs--messenger">
    {
      channelType === GENERAL_CHANNEL && (
        <div className="bread-crumbs__privacy">
          <span className="icon-chanel-icon" />
        </div>
      )
    }
    {
      channelType === GROUP_CHANNEL && (
        <div className="bread-crumbs__privacy">
          <span className="icon-chanel-icon" />
        </div>
      )
    }
    {
      channelType === PRIVATE_CHANNEL && (
        <div className="bread-crumbs__privacy">
          <span className="icon-chanel-lock-icon" />
        </div>
      )
    }
    <h2 className="bread-crumbs__current-page title--xl">{ title }</h2>
  </div>
);
BreadCrumbs.propTypes = {
  channelType: PropTypes.number,
  title: PropTypes.string,
};
BreadCrumbs.defaultProps = {
  channelType: 1,
  title: '',
};
export default BreadCrumbs;
