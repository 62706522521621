import {
  compose, withState, withHandlers, withProps,
} from 'recompose';
import { isNotificationPermission, requestPermission } from '../helpers/uiComponentHelpers/common';

const onEnableNotificationHandler = ({ setNotificationPermission }) => () => {
  requestPermission(setNotificationPermission);
};

const withNotification = compose(
  withState('notificationPermission', 'setNotificationPermission', null),
  withProps(() => ({
    isNotificationPermissionDefault: isNotificationPermission('default'),
  })),
  withHandlers({
    onEnableNotification: onEnableNotificationHandler,
  }),
);

export default withNotification;
