import React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonsCollection, Pin, DropDown,
} from '../../../../components';
import './style.sass';
import { Summary } from '..';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';
import PermissionElement from '../../../../components/permissionElement';

const TaskAbout = ({
  task, onEdit, onDelete, t, P,
}) => (
  <div className="task-about">
    <div className="task-about__header">
      <div className="task-about__code">
        {`#${task.id}`}
      </div>
      <h2 className="task-about__title">{task.title}</h2>
      <div className="task-about__control">
        <Pin className="task-about__control-button" title={t('Task name')} />

        <PermissionElement module={MODULES.TASKS} action={[ACTIONS.CREATE, ACTIONS.DELETE]}>
          <DropDown
            className="controls-group-task"
            name="controls-group-task"
            label={(
              <ButtonsCollection.ButtonIcons
                className="project-task__controls-button-task task-about__control-button"
                title={t('Control buttons')}
              >
                <div className="s-24 icon-more_vert" />
              </ButtonsCollection.ButtonIcons>
          )}
            list={
            [
              ...(P(MODULES.TASKS, ACTIONS.CREATE) ? ([{
                id: 0,
                onClick: onEdit,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-pencil-2" />
                    <span className="controls-group-task__text">{t('Edit')}</span>
                  </>
                ),
              }]) : []),
              ...(P(MODULES.TASKS, ACTIONS.DELETE) ? ([{
                id: 2,
                onClick: onDelete,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-delete-icon" />
                    <span className="controls-group-task__text">{t('Delete')}</span>
                  </>
                ),
              }]) : []),

            ]
          }
          />
        </PermissionElement>
      </div>
    </div>
    <div className="task-about__body">
      <div className="task-about__body-content">
        <h3>{t('Description')}</h3>
        {task.description}
      </div>
      <div className="task-about__aside">
        <Summary task={task} />
      </div>
    </div>
  </div>
);

TaskAbout.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  task: PropTypes.instanceOf(Object).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default TaskAbout;
