/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import { Avatar } from '../../../../components';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import PermissionElement from '../../../../components/permissionElement';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const UserItem = ({
  userAvatar, user, userPhone, userRole, onEditUserModal, onDeleteUserModal, userPosition,
}) => (
  <tr className="user-item">
    <td>
      {user.id}
    </td>
    <td>
      <div className="avatar avatar-md mr-3 mt-1 float-left">
        <Avatar src={userAvatar} className="user-item__avatar" alt={getFullName(user)} />
      </div>
      <div>
        <div>
          <strong>{getFullName(user)}</strong>
        </div>
        <small>{user.email}</small>
      </div>
    </td>

    <td>
      {userPosition}
    </td>

    <td>{userPhone}</td>
    <td><span className="r-3 badge badge-success ">{userRole}</span></td>
    <td>
      <PermissionElement module={MODULES.USERS} action={ACTIONS.UPDATE}>
        <span
          className="link-color p-1"
          role="button"
          onClick={() => onEditUserModal(user.id)}
        >
          <i className="icon-pencil" />
        </span>
      </PermissionElement>

      <PermissionElement module={MODULES.USERS} action={ACTIONS.DELETE}>
        <span
          role="button"
          className="link-color p-1"
          onClick={() => onDeleteUserModal(user.id)}
        >
          <i className="icon-trash" />
        </span>
      </PermissionElement>
    </td>
  </tr>
);

UserItem.propTypes = {
  userAvatar: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  userPhone: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  onEditUserModal: PropTypes.func.isRequired,
  onDeleteUserModal: PropTypes.func.isRequired,
  userPosition: PropTypes.string.isRequired,
};

export default UserItem;
