import React from 'react';
import PropTypes from 'prop-types';

import ContentEditable from 'react-contenteditable';

import { ScrollWrapper } from '../../..';
import './style.sass';

const setClassTextAreaGroup = (error, isFill, isFocus, touched, autoHeight, className) => (
  `textArea-group
   ${error && touched ? 'textArea-group--has-error' : ''}
   ${isFill ? 'textArea-group--has-value' : ''} ${className} ${
    isFocus ? 'textArea-group--pseudo-focus' : ''} ${autoHeight ? 'textArea-group--auto-height' : ''}`
);

const TextArea = ({
  name,
  onChange,
  value,
  error,
  tabIndex,
  touched,
  onBlur,
  placeholderJump,
  isFocus,
  autoHeight,
  className,
  onKeyPress,
  children,
  onFocus,
  onUpdateScroll,
  isFill,
  placeholder,
  t,
  onKeyDown,
  dangerouslySetInnerHTML,
  onKeyUp,
  stylesScroll,
  onSetRefField,
  setFocusStatus,
  onSetRefScroll,
}) => (
  <div className={setClassTextAreaGroup(error, isFill, isFocus, touched, autoHeight, className)}>
    <div className="textArea-group__wrap">
      <ScrollWrapper
        refCustom={onSetRefScroll}
        className="textArea-group__scroll-wrapper"
        style={stylesScroll}
        autoHeight
        autoHide
        onUpdate={onUpdateScroll}
      >
        {
          (placeholder && !isFill) && <div className="textArea-group__placeholder">{placeholder}</div>
        }
        <ContentEditable
          id={name}
          tabIndex={tabIndex}
          disabled={false}
          onChange={e => onChange({ ...e, target: { ...e.target, name } })}
          ref={onSetRefField}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          html={value}
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
          onKeyPress={onKeyPress}
          onBlur={(e) => { setFocusStatus(false); onBlur(e); }}
          onFocus={(e) => { setFocusStatus(true); onFocus(e); }}
          className="textArea-group__place-write"
        />
      </ScrollWrapper>
      {children}
    </div>
    {placeholderJump && (<label htmlFor={name} className="textArea-group__placeholder-jump">{placeholderJump}</label>)}
    {error && touched && (
      <span className="textArea-group__error field__error-text">
        {t(error, { key: placeholderJump })}
      </span>
    )}
  </div>
);
TextArea.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
  placeholderJump: PropTypes.string,
  onKeyDown: PropTypes.func,
  isFocus: PropTypes.bool,
  className: PropTypes.string,
  autoHeight: PropTypes.bool,
  tabIndex: PropTypes.number,
  t: PropTypes.func.isRequired,
  children: PropTypes.oneOfType(
    [PropTypes.element,
      PropTypes.string,
      PropTypes.array],
  ),
  value: PropTypes.string,
  isFill: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  dangerouslySetInnerHTML: PropTypes.instanceOf(Object),
  stylesScroll: PropTypes.instanceOf(Object).isRequired,
  onSetRefField: PropTypes.func.isRequired,
  setFocusStatus: PropTypes.func.isRequired,
  onSetRefScroll: PropTypes.func.isRequired,
  onUpdateScroll: PropTypes.func,
  onKeyUp: PropTypes.func,
};

TextArea.defaultProps = {
  name: '',
  error: '',
  touched: false,
  onBlur: () => {},
  value: '',
  onChange: () => {},
  placeholderJump: '',
  onFocus: () => {},
  onKeyPress: null,
  isFocus: false,
  onKeyDown: null,
  className: '',
  autoHeight: false,
  tabIndex: 0,
  onKeyUp: () => {},
  children: '',
  placeholder: '',
  dangerouslySetInnerHTML: {},
  onUpdateScroll: () => {},
};
export default TextArea;
