/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { equals, or } from 'ramda';

import './style.sass';
import MentionItem from './components/mentionItem';
import { ScrollWrapper } from '../../../../components';

const MentionsDropdown = ({
  className, members, isOpen, activeItem, setRef, onPrevent,
}) => (
  <div
    onMouseDown={onPrevent}
    className={`drop-down-mentions ${className} ${isOpen ? 'drop-down-mentions--open' : ''}`}
  >
    <ScrollWrapper
      className="dropDown-select__scroll-wrapper"
      autoHeight
      autoHide
      renderTrackVertical={({ style, ...props }) => (
        <div style={{ ...style }} {...props} className="dropDown-select__track" />
      )}
      refCustom={node => setRef('scrollWrapper', node)}
      renderThumbVertical={props => <div {...props} className="dropDown-select__thumb" />}
    >
      <ul className="drop-down-mentions__list" ref={node => setRef('mentionsContainer', node)}>
        {or(members, []).map((item, index) => (
          <MentionItem
            key={`member-chat-${item.id}`}
            index={index}
            isActive={equals(index, activeItem)}
            itemId={item.id}
          />
        ))}
      </ul>
    </ScrollWrapper>
  </div>
);

MentionsDropdown.propTypes = {
  onPrevent: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  activeItem: PropTypes.number.isRequired,
  setRef: PropTypes.func.isRequired,
};

export default MentionsDropdown;
