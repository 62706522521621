import { handleActions } from 'redux-actions';
import { pathOr, reverse, uniq } from 'ramda';
import types from './types';
import {
  mergeByPath,
  mergeDeep, mergeDeepByPath,
  mergeIn,
} from '../../utils/helpers/ramdaStateHelpers';

const initialState = {
  entities: {
    comments: {},
    time_entries: {},
    data: {},
  },
  result: {
    comments: [],
    time_entries: [],
  },
};

const reducer = handleActions({
  [types.SET_ACTIVE_TASK]: mergeIn(({
    payload: {
      data: { entities: { task } }, task: { id },
    },
  }) => ({
    ...initialState,
    ...{
      entities: { data: task[id] },
      result: initialState.result,
    },
  })),
  [types.UPDATE_ACTIVE_TASK]: mergeDeep(({
    payload: { data: { entities: { task } }, task: { id } },
  }) => ({
    entities: { data: task[id] },
  })),
  [types.UPDATE_TASK]: mergeDeep(({
    payload: { task },
  }) => ({
    entities: { data: { ...task } },
  })),
  [types.SET_TASK_COMMENT]: mergeIn(({ payload: { comment } }, { result, entities }) => ({
    entities: {
      ...entities,
      comments: {
        ...entities.comments,
        [comment.id]: {
          ...comment,
          author: comment.author.id,
        },
      },
    },
    result: {
      ...result,
      comments: [...result.comments, comment.id],
    },
  })),
  [types.SET_TASK_COMMENTS]: mergeIn(({ payload: { data, hasMore } }, { result, entities }) => ({
    entities: {
      ...entities,
      comments: {
        ...entities.comments,
        ...data.entities.comments,
        hasMore,
      },
    },
    result: {
      ...result,
      comments: uniq([...reverse(data.result), ...result.comments]),
    },
  })),
  [types.SET_TIME_ENTRIES]: mergeIn(({
    payload: { data: { entities, result }, meta: { taskId } },
  }, state) => ({
    [taskId]: {
      entities: {
        ...pathOr({}, [taskId, 'entities'], state),
        time_entries: entities.time_entries,
      },
      result: {
        ...pathOr({}, [taskId, 'result'], state),
        time_entries: result,
      },
    },
  })),
  [types.SET_TIME_ENTRY_ENTITY]: mergeIn(({ payload: { timeEntry } }, { entities }) => ({
    entities: {
      ...entities,
      time_entries: {
        ...entities.time_entries,
        [timeEntry.id]: timeEntry,
      },
    },
  })),
  [types.SET_NEW_TIME_ENTRY]: mergeDeep(({
    payload: {
      timeEntry, meta: { taskId },
      author,
    },
  }, state) => {
    const authorInfo = author ? { user: author.id, user_id: author.id } : {};
    return {
      [taskId]: {
        entities: {
          time_entries: {
            ...pathOr({}, [taskId, 'entities', 'time_entries'], state),
            [timeEntry.id]: { ...timeEntry, ...authorInfo },
          },
        },
        result: {
          time_entries: uniq([...pathOr([], [taskId, 'result', 'time_entries'], state), timeEntry.id]),
        },
      },
    };
  }),
  [types.REORDER_TIME_ENTRIES]: mergeDeep(({ payload: { result, taskId } }) => ({
    [taskId]: {
      result: {
        time_entries: result,
      },
    },
  })),
  [types.DELETE_TIME_ENTRY_ENTITY]: mergeDeep(({ payload: { result, taskId } }) => ({
    [taskId]: {
      entities: {
        time_entries: result,
      },
    },
  })),
  [types.RESET_TASK_FILES]: mergeByPath(['entities', 'data'], ({ payload }) => ({
    files: payload,
  })),
  [types.REORDER_SPENT_TIME_TASK]: mergeDeepByPath(['entities', 'data'], ({ payload }) => ({ spent_time: payload })),
}, initialState);

export default reducer;
