import React from 'react';

import './style.sass';
import { Link } from 'react-router-dom';
import u5Image from '../../../assets/img/dummy/u5.png';

const ForgotPasswordSuccess = () => (
  <main>
    <div id="primary" className="p-t-b-100 height-full ">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 mx-md-auto">
            <div className="text-center">
              <img src={u5Image} alt="" />
              <h3 className="mt-2">Forgot password success</h3>
              <p className="p-t-b-20">Check your inbox for reset password</p>
            </div>
            <Link to="/" className="btn btn-success btn-lg btn-block">Back</Link>
          </div>
        </div>
      </div>
    </div>
  </main>
);

export default ForgotPasswordSuccess;
