import {
  compose, getContext, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { projectSelectors } from '../../state/project';
import { taskActions } from '../../state/task';

const mapStateToProps = (state, { isTaskHasSprint }) => ({
  tasks: isTaskHasSprint ? projectSelectors.getProjectSprintTasks(state)
    : projectSelectors.getProjectBacklogTasks(state),
});

const mapDispatchToProps = ({
  getTask: taskActions.getActiveTaskRequest,
});

const onEditTaskHandler = ({
  onEditTaskModal, sort, order, getTask,
}) => ({ taskId, projectId }) => {
  getTask({ projectId, taskId });
  onEditTaskModal(taskId, { sort, order });
};

const onDeleteTaskHandler = ({
  onDeleteTaskModal, sort, order, getTask,
}) => ({ taskId, projectId, meta }) => {
  getTask({ projectId, taskId });
  onDeleteTaskModal(taskId, { sort, order }, meta);
};

const withTasksCRUDHandlers = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    selectedTaskId: PropTypes.number,
    onDeleteTaskModal: PropTypes.func,
    onEditTaskModal: PropTypes.func,
  }),
  withHandlers({
    onEditTask: onEditTaskHandler,
    onDeleteTask: onDeleteTaskHandler,
  }),
);
export default withTasksCRUDHandlers;
