import { compose } from 'recompose';
import { connect } from 'react-redux';


import { withCRUDSprint } from '../../../../../utils/enchancers';
import rules from '../rules';
import NewSprintModal from './newSprintModal';
import { projectActions, projectSelectors } from '../../../../../state/project';

const mapStateToProps = state => ({
  isPending: projectSelectors.getPendingAddSprintRequest(state),
});

const mapDispatchToProps = {
  addSprint: projectActions.addSprintRequest,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withCRUDSprint({
    rules,
    modalName: 'createSprintModal',
    onSubmit: (formData, props) => {
      const {
        addSprint, closeModal, projectId,
      } = props.props;

      addSprint({ ...formData, projectId, id: new Date().getTime() }, {
        callbacks: {
          success: () => closeModal('createSprintModal'),
        },
      });
    },
  }),
);
export default enhance(NewSprintModal);
