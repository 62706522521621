import React from 'react';
import PropTypes from 'prop-types';
import { TaskList } from '../../../../containers/leftSideBar/components';

import './style.sass';

const ImportantTasks = ({ entities, result, isPending }) => (
  <TaskList pending={isPending} entities={entities} result={result} />
);

ImportantTasks.propTypes = {
  entities: PropTypes.instanceOf(Object).isRequired,
  result: PropTypes.objectOf(PropTypes.number).isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default ImportantTasks;
