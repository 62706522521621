import {
  compose, hoistStatics, getContext, withHandlers, branch, renderNothing,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import DeleteTaskModal from './deleteTaskModal';
import { getModal } from '../../../state/ui/selectors';
import { closeModal } from '../../../state/ui/actions';
import { tasksSelectors, tasksActions } from '../../../state/tasks';
import { taskSelectors } from '../../../state/task';

const mapStateToProps = state => ({
  isOpen: getModal(state)('deleteTaskModal'),
  isActiveTaskPending: taskSelectors.getActiveTaskPending(state),
  isPending: tasksSelectors.getDeleteTaskPendingRequest(state),
  task: taskSelectors.getActiveTask(state),
});

const mapDispatchToProps = {
  deleteTaskRequest: tasksActions.deleteTaskRequest,
  onCloseModal: () => closeModal('deleteTaskModal'),
};

const onDeleteTaskHandler = ({
  deleteTaskRequest,
  projectId,
  selectedTaskId,
  selectedSort: {
    sort,
    order,
  },
  onCloseModal,
  history,
  selectedMeta,
  isRedirectToProject = false,
}) => () => deleteTaskRequest({
  projectId,
  taskId: selectedTaskId,
}, {
  callbacks: {
    success: () => {
      if (isRedirectToProject) history.push(`/projects/${projectId}`);
      onCloseModal();
    },
  },
  sortBy: sort,
  order,
  ...selectedMeta,
});

const enhance = compose(
  getContext({
    projectId: PropTypes.number,
    selectedMeta: PropTypes.instanceOf(Object),
    selectedSort: PropTypes.instanceOf(Object),
    isRedirectToProject: PropTypes.bool,
  }),
  branch(
    ({ isActiveTaskPending }) => isActiveTaskPending,
    renderNothing,
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['tasks', 'common']),
  withRouter,
  withHandlers({
    onDeleteTask: onDeleteTaskHandler,
  }),
  hoistStatics,
);

export default enhance(DeleteTaskModal);
