import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../../..';

import './style.sass';

const Search = ({
  name,
  onChange,
  value,
  placeholder,
  setRef,
  className,
  inFocus,
  withoutControl,
  children,
  onKeyPress,
  onChangeWatcher,
}) => (
  <div className={`search-field ${className} ${inFocus ? 'search-field--focus' : ''}`}>
    <input
      type="search"
      name={name}
      onKeyUp={onChangeWatcher}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      autoComplete="off"
      className="search-field__place-write"
      ref={e => setRef('input', e)}
    />
    { !withoutControl ? (
      <ButtonsCollection.ButtonIcons
        title="Submit search"
        type="button"
        className="search-field__button"
      >
        <span className="icon-search-icon2" />
      </ButtonsCollection.ButtonIcons>
    ) : null}
    {children}
  </div>
);

Search.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  inFocus: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  setRef: PropTypes.func,
  className: PropTypes.string,
  withoutControl: PropTypes.bool,
  children: PropTypes.element,
  onKeyPress: PropTypes.func,
};
Search.defaultProps = {
  withoutControl: false,
  onChange: () => {},
  onKeyPress: () => {},
  value: '',
  placeholder: 'Search',
  setRef: () => {},
  className: '',
  inFocus: false,
  children: <></>,
};

export default Search;
