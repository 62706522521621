import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { messengerActions } from '../../state/messenger';

const mapDispatchToProps = ({
  getGroupChannelsRequest: messengerActions.getGroupChannelsRequest,
});

const withGroupChannels = ({ limit = 8, offset = 0 }) => compose(
  connect(null, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.getGroupChannelsRequest(null, { limit, offset });
    },
  }),
);

export default withGroupChannels;
