import {
  compose, hoistStatics, withHandlers, getContext,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';

import DeleteFileModal from './deleteFileModal';
import { getModal } from '../../../../state/ui/selectors';
import { closeModal } from '../../../../state/ui/actions';
import { projectsSelectors } from '../../../../state/projects';
import { projectActions } from '../../../../state/project';

const mapStateToProps = (state, { match: { params: { id } } }) => ({
  isOpen: getModal(state)('deleteProjectFileModal'),
  project: projectsSelectors.getProject(state)(id),
});

const mapDispatchToProps = {
  deleteProjectFileRequest: projectActions.deleteProjectFileRequest,
  onCloseModal: () => closeModal('deleteProjectFileModal'),
};

const onDeleteProjectFileHandler = ({
  deleteProjectFileRequest,
  selectedProjectFileId,
  match: { params: { id } },
  onCloseModal,
}) => () => deleteProjectFileRequest({
  projectId: id,
  fileId: selectedProjectFileId,
}, {
  callbacks: {
    success: onCloseModal,
    isSoloProjectUpdate: true,
  },
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    selectedProjectFileId: PropTypes.number.isRequired,
  }),
  withNamespaces(['tasks', 'common']),
  withRouter,
  withHandlers({
    onDeleteProjectFile: onDeleteProjectFileHandler,
  }),
  hoistStatics,
);

export default enhance(DeleteFileModal);
