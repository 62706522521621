import {
  compose, lifecycle, defaultProps, getContext, withHandlers, withProps, withState, withContext,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import CustomersContainer from './customersContainer';
import { customersActions, customersSelectors } from '../../state/customers';
import { uiActions } from '../../state/ui';

const ITEMS_ON_PAGE = 10;

const mapStateToProps = state => ({
  customersList: customersSelectors.getCustomersResult(state),
  customersCount: customersSelectors.getCustomersCount(state),
});

const mapDispatchToProps = {
  getCustomersRequest: customersActions.getCustomersRequest,
  setOpenModal: uiActions.openModal,
};

const loadMoreRequestHandler = ({
  itemsOnPage, getCustomersRequest, currentPage,
  selectedSearch, selectedSort,
}) => () => {
  const offset = parseInt((currentPage - 1) * itemsOnPage, 10);
  getCustomersRequest({
    limit: itemsOnPage,
    offset,
    sortBy: 'username',
    order: selectedSort.value,
    name: selectedSearch,
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setSelectedSort: PropTypes.func,
  }),
  withRouter,
  withState('selectedSearch', 'setSelectedSearch', ''),
  withState('selectedSort', 'setSelectedSort', {}),
  defaultProps({
    itemsOnPage: ITEMS_ON_PAGE,
  }),
  withProps(({ history, customersCount }) => {
    const page = parseInt(history.location.search.match(/(?!page=)\d*/gi)[5] || 1, 10);
    return {
      currentPage: page,
      isPrev: page > 1,
      isNext: page * ITEMS_ON_PAGE < customersCount,
    };
  }),
  withHandlers(() => ({
    loadMoreRequest: loadMoreRequestHandler,
  })),
  withContext({
    selectedSearch: PropTypes.string,
    selectedSort: PropTypes.shape({}),
    setSelectedSort: PropTypes.func,
    setSelectedSearch: PropTypes.func,
  }, props => ({
    selectedSearch: props.selectedSearch,
    selectedSort: props.selectedSort,
    setSelectedSearch: props.setSelectedSearch,
    setSelectedSort: props.setSelectedSort,
  })),
  lifecycle({
    componentDidMount() {
      this.props.loadMoreRequest();
    },
    componentDidUpdate({
      currentPage, selectedSort, selectedSearch,
    }) {
      if (this.props.currentPage !== currentPage || selectedSort !== this.props.selectedSort) {
        this.props.loadMoreRequest();
      } else if (selectedSearch !== this.props.selectedSearch) {
        this.props.loadMoreRequest();
      }
    },
  }),

);
export default enhance(CustomersContainer);
