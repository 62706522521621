import {
  compose, lifecycle, withHandlers, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';

import MainNav from './mainNav';
import { openModal } from '../../../../state/ui/actions';
import { LAST_CHANNEL_MESSENGER_STORAGE } from '../../../../constants/messenger';

const mapDispatchToProps = ({
  setOpenModal: openModal,
});

const channelIdFromStorage = () => window.localStorage.getItem(LAST_CHANNEL_MESSENGER_STORAGE) || 1;

const onChangeStorageHandler = ({ setLastChannelId }) => () => {
  const channelId = channelIdFromStorage();
  setLastChannelId(channelId);
};

const setLastChannelIdStateHandler = () => channelId => ({
  lastChannelId: channelId || '',
});

const onCreateProjectModalHandler = ({ setOpenModal }) => (e) => {
  e.preventDefault();
  setOpenModal('createProjectModal');
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withStateHandlers(() => ({ lastChannelId: channelIdFromStorage() }), {
    setLastChannelId: setLastChannelIdStateHandler,
  }),
  withHandlers({
    onCreateProjectModal: onCreateProjectModalHandler,
    onChangeStorage: onChangeStorageHandler,
  }),
  lifecycle({
    componentDidMount() {
      window.addEventListener('storageChange', this.props.onChangeStorage, false);
      window.addEventListener('storage', this.props.onChangeStorage, false);
    },
    componentDidUpdate({ lastChannelId }) {
      const storageChannelId = channelIdFromStorage();
      if (storageChannelId && lastChannelId !== storageChannelId) {
        this.props.onChangeStorage();
      }
    },
    componentWillUnmount() {
      window.removeEventListener('storageChange', this.props.onChangeStorage, false);
      window.removeEventListener('storage', this.props.onChangeStorage, false);
    },
  }),
);
export default enhance(MainNav);
