import React from 'react';
import PropTypes from 'prop-types';

import {
  ConfirmModal,
} from '../../../../components';

const DeleteCustomer = ({
  isOpen,
  isPending,
  onCloseModal,
  customer,
  onDelete,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    onAction={onDelete}
    onClose={onCloseModal}
    title="Delete customer"
  >
    <span className="text-size--sm">
      Are you sure you want to delete a user
      {' '}
      <b>{customer.username}</b>
?
    </span>
  </ConfirmModal>
);

DeleteCustomer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteCustomer;
