import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const FilesField = ({ t }) => (
  <div className="files-field">
    <input type="file" className="files-field__input" id="files-field__input" />
    <label htmlFor="files-field__input" className="files-field__label">{t('Drop file here or click to upload')}</label>
  </div>
);

FilesField.propTypes = {
  t: PropTypes.func.isRequired,
};

export default FilesField;
