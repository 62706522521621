import {
  compose, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { identity, pathOr } from 'ramda';
import { push } from 'connected-react-router';
import { getTempFileUploadPending } from '../../state/ui/selectors';
import { getImageUrl } from '../helpers/requestHelpers';
import { projectActions } from '../../state/project';
import { uiActions } from '../../state/ui';

const mapStateToProps = state => ({
  isPending: getTempFileUploadPending(state),
});
const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
  deleteSprintRequest: projectActions.deleteSprintRequest,
  closeModal: uiActions.closeModal,
  routerPush: push,
};

const onDeleteSprintHandler = ({
  deleteSprintRequest, closeModal,
}) => ({
  sprintId, projectId, meta, modalName,
}) => {
  deleteSprintRequest({ projectId, sprintId }, {
    ...meta,
    ...{
      callbacks: {
        success: compose(() => closeModal(modalName), pathOr(identity, ['callbacks', 'success`'], meta)),
      },
    },
  });
};

const onSprintModalHandler = ({
  setOpenModal, routerPush,
}) => ({
  isAgenda, modalName, sprintId, setSelectedSprintId, setSprintCRUDMeta, sprint,
}) => {
  setSelectedSprintId(sprintId);
  if (isAgenda) {
    setSprintCRUDMeta({
      isAgendaBoard: true,
      callbacks: {
        success: routerPush(`/projects/${sprint.project_id}/agenda-board/`),
      },
    });
  }
  setOpenModal(modalName);
};

const onCloseModalHandler = ({ closeModal }) => closeModal;

const withSprintsCRUDHandlers = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onDeleteSprint: onDeleteSprintHandler,
    onCloseModal: onCloseModalHandler,
    onSprintModal: onSprintModalHandler,
  }),
  withProps(({ value }) => ({ value: getImageUrl(value) })),
);
export default withSprintsCRUDHandlers;
