import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistStore } from 'redux-persist';

import { composeWithDevTools } from 'redux-devtools-extension';

import { feature, core } from './utils/middlewares';
import { sagasManager } from './utils';
import requestApi, { offlineRequestMiddleware } from './utils/middlewares/sagaRequestApi';
import reducers from './state';

const sagaMiddleware = createSagaMiddleware();
const history = createBrowserHistory();

const store = createStore(
  reducers(history),
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
      ...feature,
      ...core,
      offlineRequestMiddleware({
        baseUrl: process.env.REACT_APP_API_URL,
      }),
      requestApi({
        baseUrl: process.env.REACT_APP_API_URL,
      }),
    ),
  ),
);

const persistor = persistStore(store);
sagaMiddleware.run(sagasManager.getRootSaga());

export {
  history,
  persistor,
};

export default store;
