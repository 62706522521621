import React from 'react';
import PropTypes from 'prop-types';

import { pathOr } from 'ramda';
import { FormsCollection, ModalWindow } from '../../../components';

const ModalView = ({
  isOpen,
  isPending,
  onCloseModal,
  handleChange,
  handleSubmit,
  values,
  errors,
  title,
  titleAction,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    modalName=""
    title={title}
    className="new-sprint__modal"
  >
    <div id="step-1y" className="card-body p-1">
      <FormsCollection.FormWrapper onSubmit={handleSubmit}>
        <div className="form-row">
          <FormsCollection.Input
            label="Title"
            className="col-md-12"
            id="add-event__title"
            name="title"
            error={pathOr('', ['title', 'message'], errors)}
            placeholder="Event title"
            value={values.title}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <FormsCollection.TextArea
            label="Description"
            className="col-md-12"
            id="add-event__description"
            name="description"
            placeholder="Description..."
            value={values.description}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <FormsCollection.Input
            label="Quantity"
            className="col-md-6"
            id="add-event__title"
            type="number"
            name="quantity"
            placeholder=""
            error={pathOr('', ['quantity', 'message'], errors)}
            value={values.quantity}
            onChange={handleChange}
          />
        </div>
        <div className="form-row p-2">
          <button
            pending={isPending}
            className="btn btn-primary w-100px"
            type="submit"
          >
            { titleAction}
          </button>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
};

export default ModalView;
