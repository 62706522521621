import React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonsCollection, DropDown, PendingControl, PermissionElement,
} from '../../../../components';
import './style.sass';
import TasksGrid from '../tasksGrid';
import { SPRINT_STATUS } from '../../../../constants/sprints';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const Sprint = ({
  t, onOpenTabSprint,
  isOpenSprintTab,
  item, id, onEdit, onDelete, onComplete, onStart, totalSpent, totalEstimated,
  P, isOnline, onResetOfflineDelete, onResetOfflineEdit, onResetOfflineCreate, isProjectCompleted,
  deadline,
}) => (
  <div
    title={SPRINT_STATUS.COMPLETED === item.status ? 'This sprint is completed' : ''}
    className={`table-tasks__sprint ${isOpenSprintTab ? 'table-tasks__sprint--open' : ''}
     ${SPRINT_STATUS.COMPLETED === item.status
    || isProjectCompleted ? 'table-tasks__sprint--completed' : ''}`}
  >
    <PendingControl
      onResetOfflineDelete={onResetOfflineDelete}
      onResetOfflineEdit={onResetOfflineEdit}
      onResetOfflineCreate={onResetOfflineCreate}
      isPending={item.isPending}
      isUpdating={item.isUpdating}
      isDeleting={item.isDeleting}
    />
    <div className="table-tasks__about-sprint">
      <ButtonsCollection.ButtonIcons
        className="table-tasks__sprint-toggle-button"
        title={`${!isOpenSprintTab ? t('Show more') : t('Show less')}`}
        onClick={() => onOpenTabSprint()}
      >
        <span className="icon-expand-arrow-dn" />
      </ButtonsCollection.ButtonIcons>
      <h2 className="table-tasks__name-sprint">{ item.title }</h2>
      <PermissionElement module={MODULES.TASKS} action={[ACTIONS.CREATE, ACTIONS.DELETE]}>
        <DropDown
          className="table-tasks__sprint-control"
          label={(
            <ButtonsCollection.ButtonIcons
              className="agenda-task__controls-button "
              title="Controls for sprint"
            >
              <div className="s-24 icon-more_vert" />
            </ButtonsCollection.ButtonIcons>
          )}
          list={
            [
              ...(P(MODULES.TASKS, ACTIONS.CREATE) ? ([{
                id: 0,
                onClick: onEdit,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-pencil-2" />
                    <span className="controls-group-task__text">{t('Edit')}</span>
                  </>
                ),
              }]) : []),
              ...(P(MODULES.TASKS, ACTIONS.DELETE) ? ([{
                id: 2,
                onClick: onDelete,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-delete-icon" />
                    <span className="controls-group-task__text">{t('Delete')}</span>
                  </>
                ),
              }]) : []),
            ]
          }
        />
      </PermissionElement>
      <div className="table-tasks__sprint-info">
        <div className="table-tasks__sprint-info__field hidden-sm">
          <div className="table-tasks__sprint-info__field-label">
            Deadline:
          </div>
          <div className="table-tasks__sprint-info__field-value">{deadline}</div>
        </div>
        <div className="table-tasks__sprint-info__field">
          <div className="table-tasks__sprint-info__field-label">
            {`${t('Spent')}:`}
          </div>
          <div className="table-tasks__sprint-info__field-value">{totalSpent}</div>
        </div>
        <div className="table-tasks__sprint-info__field">
          <div className="table-tasks__sprint-info__field-label">
            {`${t('Estimation')}:`}
          </div>
          <div className="table-tasks__sprint-info__field-value">{totalEstimated}</div>
        </div>
      </div>

      <PermissionElement module={MODULES.TASKS} action={[ACTIONS.CREATE]}>
        <>
          {
            (item.status === SPRINT_STATUS.NOT_ACTIVE
              || item.status === SPRINT_STATUS.COMPLETED) && (
              <button
                type="button"
                disabled={!isOnline || isProjectCompleted}
                onClick={onStart}
                title={!isOnline ? 'Only in online version' : ''}
                className="btn btn-primary w-100px"
              >
                Start
              </button>
            )
          }
        </>
      </PermissionElement>
      <PermissionElement module={MODULES.TASKS} action={[ACTIONS.DELETE]}>
        <>
          {

          item.status === SPRINT_STATUS.ACTIVE && (
            <button
              type="button"
              disabled={!isOnline || isProjectCompleted}
              onClick={onComplete}
              title={!isOnline ? 'Only in online version' : ''}
              className="btn btn-primary w-100px"
            >
              Complete
            </button>
          )
        }
        </>
      </PermissionElement>
    </div>
    <TasksGrid
      isTaskHasSprint
      name={`sprint-grid-${id}`}
      items={item.tasks}
      isDisabledSprint={SPRINT_STATUS.COMPLETED === item.status || isProjectCompleted}
      isSprint
      id={id}
    />
  </div>
);

Sprint.propTypes = {
  onOpenTabSprint: PropTypes.func.isRequired,
  isOpenSprintTab: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  totalSpent: PropTypes.string.isRequired,
  totalEstimated: PropTypes.string.isRequired,
  item: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  P: PropTypes.func.isRequired,
  isOnline: PropTypes.bool.isRequired,
  onResetOfflineDelete: PropTypes.func.isRequired,
  onResetOfflineEdit: PropTypes.func.isRequired,
  onResetOfflineCreate: PropTypes.func.isRequired,
  isProjectCompleted: PropTypes.bool.isRequired,
  deadline: PropTypes.string.isRequired,
};

export default Sprint;
