import {
  compose, withStateHandlers, lifecycle, getContext, withHandlers, withProps,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import PROJECT_TABS from '../../constants/tabNavigation';
import { taskActions, taskSelectors } from '../../state/task';
import Task from './task';
import withRefs from '../../utils/enchancers/withRefs';
import withPermission from '../../utils/enchancers/withPemission';
import { MODULES } from '../../constants/pemissions';
import { projectSelectors } from '../../state/project';
import preloaderWhileLoading from '../../utils/enchancers/preloaderWhileLoading';
import withOnline from '../../utils/enchancers/withOnline';

const mapDispatchToProps = {
  getTask: taskActions.getActiveTaskRequest,
};

const mapStateToProps = (state, { match: { params: { id, taskId } } }) => ({
  task: !projectSelectors.getTask(state)(taskId, id).title ? projectSelectors.getBacklogTask(state)(taskId, id) : projectSelectors.getTask(state)(taskId, id),
  isLoading: taskSelectors.getActiveTaskPending(state),
});

const withActiveTask = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { getTask, match: { params: { id, taskId } } } = this.props;
      getTask({ projectId: id, taskId });
    },
  }),
);


const onScrollSetRefHandler = ({ setRef }) => e => setRef('commentsScroll', e);

const enhance = compose(
  withPermission([MODULES.TASKS]),
  withRefs(),
  withOnline,
  withNamespaces(['tasks']),
  withActiveTask,
  preloaderWhileLoading({
    dimension: 100,
    alignContainerCenter: true,
    isLoading: ({ task, isLoading }) => isLoading || !task.title,
  }),
  withStateHandlers(
    () => ({ statusVisible: 'brill' }), {
      handlerStatusVisible: () => val => ({
        statusVisible: val,
      }),
    },
  ),
  withHandlers({
    onScrollSetRef: onScrollSetRefHandler,
  }),
  withProps(({ getRef }) => ({
    scrollElement: getRef('commentsScroll'),
  })),
  getContext({
    setActiveTab: PropTypes.func,
  }),
  lifecycle({
    componentDidMount() {
      const { setActiveTab } = this.props;
      setActiveTab(PROJECT_TABS.TASK);
    },
    componentWillUnmount() {
      const { setActiveTab } = this.props;
      setActiveTab(PROJECT_TABS.TASKS);
    },
  }),
);
export default enhance(Task);
