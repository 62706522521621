import React from 'react';
import ModalView from '../modalView';

const AddMaterial = ({
  ...props
}) => (
  <ModalView title="Add material" titleAction="Save" {...props} />
);

export default AddMaterial;
