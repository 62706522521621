import { compose, withStateHandlers } from 'recompose';
import MyTasksWindow from './myTasksWindow';

const setGroupBy = () => groupBy => ({ groupBy });

const enhance = compose(
  withStateHandlers(
    () => ({ statusVisible: 'brill', groupBy: 'tasks' }), {
      setGroupByStateHandler: setGroupBy,
    },
  ),
);
export default enhance(MyTasksWindow);
