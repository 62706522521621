import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const FormWrapper = ({
  children, onSubmit, name, className, customRef,
}) => (
  <div className="form-group">
    <form
      action=""
      ref={e => customRef(e)}
      onSubmit={onSubmit}
      name={name}
      className={`form-group__form ${className}`}
      autoComplete="off"
    >
      <>
        <input type="text" style={{ display: 'none' }} />
        <input type="password" style={{ display: 'none' }} />
      </>
      { children }
    </form>
  </div>
);

FormWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.any,
  ]).isRequired,
  name: PropTypes.string,
  customRef: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
};

FormWrapper.defaultProps = {
  name: '',
  className: '',
  customRef: () => {},
};

export default FormWrapper;
