import React from 'react';
import PropTypes from 'prop-types';
import { DropDown, FormsCollection } from '../../../../components';
import './style.sass';
import {
  SORT_BY_LETTERS_DOWN,
  SORT_BY_LETTERS_UP,
} from '../../constants';

const FiltersRow = ({
  setSelectedSearch,
  selectedSearch,
  setSelectedSort,
  selectedSort,
  t,
}) => (
  <div className="directs-filter">
    <div className="directs-filter__row">
      <FormsCollection.Search
        className="search-field--circle directs-filter__search-field"
        name="search"
        withoutControl
        value={selectedSearch}
        onChange={e => setSelectedSearch(e.target.value)}
      />
      <DropDown
        label="Sort: "
        currentItem={selectedSort.id}
        list={
          [
            {
              id: 1,
              label: t('All'),
              value: null,
            },
            {
              id: 2,
              label: t('A-Z'),
              value: SORT_BY_LETTERS_DOWN,
            },
            {
              id: 3,
              label: t('Z-A'),
              value: SORT_BY_LETTERS_UP,
            },
          ]
        }
        onChange={({ val }) => setSelectedSort(val)}
      />
    </div>
  </div>
);

FiltersRow.propTypes = {
  setSelectedSearch: PropTypes.func.isRequired,
  selectedSearch: PropTypes.string.isRequired,
  setSelectedSort: PropTypes.func.isRequired,
  selectedSort: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

export default FiltersRow;
