import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { FormsCollection } from '../../../../../../components';
import './style.sass';
import { AsyncAutoComplete } from '../../../../../../components/autoCompleteField';

const FilterFields = ({
  activeView,
  setStartDate,
  setEndDate,
  selectedStartDate,
  setProjectId,
  setUserId,
  selectedEndDate,
  getProjectAutocomplete,
  selectedProjectId,
  selectedUserId,
  getUsersAutocomplete,
}) => (
  <div className="statistics-filter-fields">
    <div className="statistics-filter-fields__row">
      <FormsCollection.FormWrapper
        handleSubmit={() => {}}
      >
        <div className="statistics-filter-fields__form-fields flex-row">
          {
            activeView === 'allUsersStatistic' && (
              <AsyncAutoComplete
                name="users"
                placeholder="Users"
                closeMenuOnSelect
                value={selectedUserId}
                loadOptions={getUsersAutocomplete}
                onChange={user => setUserId(user)}
              />
            )
          }
          <AsyncAutoComplete
            name="projects"
            placeholder="Project"
            closeMenuOnSelect
            value={selectedProjectId}
            loadOptions={getProjectAutocomplete}
            onChange={project => setProjectId(project)}
          />
          <div className="statistics-filter-fields__date-picker">
            <div className="statistics-filter-fields__date-picker-label">
              From
            </div>
            <FormsCollection.DatePicker
              onChange={({ target: { value } }) => setStartDate(value)}
              value={selectedStartDate}
            />
          </div>
          <div className="statistics-filter-fields__date-picker">
            <div className="statistics-filter-fields__date-picker-label">
              To
            </div>
            <FormsCollection.DatePicker
              onChange={({ target: { value } }) => setEndDate(value)}
              value={selectedEndDate}
              minDate={moment(selectedStartDate).add(1, 'days').toDate()}
            />
          </div>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </div>
);

FilterFields.propTypes = {
  activeView: PropTypes.string.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  selectedStartDate: PropTypes.instanceOf(Date).isRequired,
  setProjectId: PropTypes.func.isRequired,
  setUserId: PropTypes.func.isRequired,
  selectedEndDate: PropTypes.instanceOf(Date).isRequired,
  getProjectAutocomplete: PropTypes.func.isRequired,
  selectedProjectId: PropTypes.number.isRequired,
  selectedUserId: PropTypes.number.isRequired,
  getUsersAutocomplete: PropTypes.func.isRequired,
};

export default FilterFields;
