import React from 'react';
import PropTypes from 'prop-types';

import { Layouts } from '../../components';
import {
  EditCustomer, DeleteCustomer, AddCustomer, ImportCustomers,
} from './components';
import {
  LeftSideBar, Header, CustomersContainer,
} from '../../containers';
import './style.sass';
import PermissionElement from '../../components/permissionElement';
import { ACTIONS, MODULES } from '../../constants/pemissions';

const Customers = ({
  selectedCustomerId, sort, onAddCustomer, onExportCustomers, onImportCustomersModal,
}) => (
  <Layouts.Main className="page-project">
    <LeftSideBar>
      {/* <Categories /> */}
      {/* <CoWorkers /> */}
    </LeftSideBar>
    <div className="main-container page-project__main-container">
      <Header>
        <div className="w-100 row-group d-flex mb-0 justify-content-center customers-buttons">
          <div className="col-lg-12 d-flex align-items-center justify-content-end">
            <PermissionElement module={MODULES.CUSTOMERS} action={ACTIONS.CREATE}>
              <button type="button" className="btn-secondary btn pointer ml-1" onClick={onImportCustomersModal}>
                Import
                <span className="hidden-md">
                  {' '}
customers
                </span>
              </button>
            </PermissionElement>
            <PermissionElement module={MODULES.CUSTOMERS} action={ACTIONS.READ}>
              <button type="button" className="btn-success  btn pointer ml-1" onClick={onExportCustomers}>
                Export
                <span className="hidden-md">
                  {' '}
customers
                </span>
              </button>
            </PermissionElement>
            <PermissionElement module={MODULES.CUSTOMERS} action={ACTIONS.CREATE}>
              <button type="button" className="btn-primary btn pointer ml-1" onClick={onAddCustomer}>
                Add
                <span className="hidden-md">
                  {' '}
customer
                </span>
              </button>
            </PermissionElement>
          </div>
        </div>
      </Header>
      <div className="page has-sidebar-left page-customers height-full">
        <CustomersContainer />
      </div>
    </div>
    <AddCustomer />
    <ImportCustomers />
    <EditCustomer selectedCustomerId={selectedCustomerId} sort={sort} />
    <DeleteCustomer selectedCustomerId={selectedCustomerId} sort={sort} />
  </Layouts.Main>
);

Customers.propTypes = {
  selectedCustomerId: PropTypes.number.isRequired,
  sort: PropTypes.instanceOf(Object).isRequired,
  onAddCustomer: PropTypes.func.isRequired,
  onExportCustomers: PropTypes.func.isRequired,
  onImportCustomersModal: PropTypes.func.isRequired,
};

export default Customers;
