import React from 'react';
import PropTypes from 'prop-types';

import { ProjectsAsList, FiltersRow } from './components';
import { Pagination, ScrollWrapper } from '../../components';
import './style.sass';

const AllProjects = ({
  searchName,
  data,
  sort,
  order,
  onSortBy,
  count,
  gridName,
  projectsListPending,
  pagination,
  projectsCount,
  itemsOnPage,
  isNext,
  isPrev,
  currentPage,
  isPending,
  selectedProjectSortMeta,
}) => (
  <ScrollWrapper heightIs="100%">
    <div className="row no-gutters pr-3 pl-3 py-1">
      <div className="col-md-12">
        <FiltersRow />
      </div>
    </div>
    <ProjectsAsList
      gridName={gridName}
      pagination={pagination}
      onSortBy={onSortBy}
      sort={sort}
      order={order}
      isLoading={projectsListPending}
      count={count}
      data={data}
      isPending={isPending}
      searchName={searchName}
      selectedProjectSortMeta={selectedProjectSortMeta}
    />
    <div className="row">
      <div className="col-lg-12">
        <Pagination
          count={projectsCount}
          limit={itemsOnPage}
          isNext={isNext}
          isPrev={isPrev}
          currentPage={currentPage}
        />
      </div>
    </div>
  </ScrollWrapper>
);

AllProjects.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  sort: PropTypes.string,
  order: PropTypes.string,
  gridName: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  onSortBy: PropTypes.func.isRequired,
  projectsListPending: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
  }).isRequired,
  searchName: PropTypes.string.isRequired,
  projectsCount: PropTypes.number.isRequired,
  itemsOnPage: PropTypes.number.isRequired,
  isNext: PropTypes.bool.isRequired,
  isPrev: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  isPending: PropTypes.bool.isRequired,
  selectedProjectSortMeta: PropTypes.instanceOf(Object).isRequired,
};

AllProjects.defaultProps = {
  sort: '',
  order: '',
};

export default AllProjects;
