/* eslint-disable arrow-body-style */
import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import StatusesBoard from './statusBoard';
import { projectActions, projectSelectors } from '../../../../state/project';

const mapDispatchToProps = ({
  onDragAgendaEnd: projectActions.onDragAgendaEnd,
});

const mapStateToProps = state => ({
  assigneeUsers: projectSelectors.getProjectTasksAssignees(state),
});

const isDestinationAndSourceEqual = (destination, source) => (
  destination.droppableId === source.droppableId && destination.index === source.index);

const onDragEndHandler = ({
  onDragAgendaEnd,
  setIsDragging, match: { params: { projectId } },
}) => (data) => {
  const { destination, source } = data;
  if (!destination) {
    setIsDragging(false);
    return false;
  }
  if (isDestinationAndSourceEqual(destination, source)) {
    setIsDragging(false);
    return false;
  }
  onDragAgendaEnd({ ...data, projectId });
  setIsDragging(false);
  return data;
};

const onDragUpdateHandler = ({
  setUpdateDroppable,
}) => (data) => {
  const { destination, source } = data;
  if (!destination) {
    return false;
  }
  if (isDestinationAndSourceEqual(destination, source)) {
    return false;
  }
  setUpdateDroppable(destination.droppableId);
  return data;
};

const onDragStartHandler = ({
  setIsDragging,
}) => (data) => {
  setIsDragging(true);
  return data;
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withState('isDragging', 'setIsDragging', false),
  withState('updateDroppable', 'setUpdateDroppable', null),
  withHandlers({
    onDragEnd: onDragEndHandler,
    onDragUpdate: onDragUpdateHandler,
    onDragStart: onDragStartHandler,
  }),
);

export default enhance(StatusesBoard);
