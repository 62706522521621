import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmModal } from '../../../../../components';
import './style.sass';

const StartProjectModal = ({
  isOpen,
  onCloseModal,
  project,
  onStart,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    actionTitle="Start"
    onAction={onStart}
    onClose={onCloseModal}
    title="Start project"
  >
    <span className="text-size--sm">
      Are you sure you want to start a
      {' '}
      <b>{project.title}</b>
?
    </span>
  </ConfirmModal>
);

StartProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  project: PropTypes.objectOf(PropTypes.object).isRequired,
  onDeleteProject: PropTypes.func.isRequired,
};

export default StartProjectModal;
