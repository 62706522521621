import {
  compose, getContext, withHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import TaskAbout from './taskAbout';
import { withWindowWidth } from '../../../../utils/enchancers';
import { withPermissionHandlers } from '../../../../components/permissionElement';

const onEditHandler = ({
  onEditTaskModal, task: { id }, sort, order,
}) => () => {
  onEditTaskModal(id, { sort, order }, {
    isSetActiveTask: true,
  });
};

const onDeleteHandler = ({
  onDeleteTaskModal, task: { id }, sort, order,
}) => () => {
  onDeleteTaskModal(id, { sort, order }, {
    isRedirectToProject: true,
  });
};
const enhance = compose(
  withWindowWidth(),
  withPermissionHandlers,
  withNamespaces(['tasks', 'common']),
  getContext({
    selectedTaskId: PropTypes.number,
    onDeleteTaskModal: PropTypes.func,
    onEditTaskModal: PropTypes.func,
    isRedirectToProject: PropTypes.bool,
  }),
  withHandlers({
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
  }),
);
export default enhance(TaskAbout);
