import React from 'react';
import PropTypes from 'prop-types';
import { DropDown, FormsCollection, MobileFiltersContainer } from '../../../../components';
import {
  CHANNELS_YOU_BELONG_TO,
  CHANNELS_YOU_CAN_JOIN,
  SORT_BY_LETTERS_DOWN,
  SORT_BY_LETTERS_UP,
} from '../../constants';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import './style.sass';

const FiltersRow = ({
  setSelectedSearch,
  selectedSearch,
  setSelectedSort,
  selectedSort,
  selectedView,
  setSelectedView,
  t,
}) => (
  <div className="channels-filter">
    <div className="channels-filter__row">
      <FormsCollection.Search
        className="search-field--circle directs-filter__search-field"
        name="search"
        withoutControl
        value={selectedSearch}
        onChange={e => setSelectedSearch(e.target.value)}
      />
      <MobileFiltersContainer title="Filter" onWidth={WINDOW_WIDTH.SMALL}>
        <DropDown
          className="channels-filter__drop-down"
          label="Show: "
          currentItem={selectedView.id}
          list={
            [
              {
                id: 1,
                label: t('Channels you can join'),
                value: CHANNELS_YOU_CAN_JOIN,
              },
              {
                id: 2,
                label: t('Channels you belong to'),
                value: CHANNELS_YOU_BELONG_TO,
              },
            ]
          }
          onChange={({ val }) => setSelectedView(val)}
        />
        <DropDown
          className="channels-filter__drop-down"
          label="Sort: "
          currentItem={selectedSort.id}
          list={
            [
              {
                id: 1,
                label: t('All'),
                value: null,
              },
              {
                id: 2,
                label: t('A-Z'),
                value: SORT_BY_LETTERS_DOWN,
              },
              {
                id: 3,
                label: t('Z-A'),
                value: SORT_BY_LETTERS_UP,
              },
            ]
          }
          onChange={({ val }) => setSelectedSort(val)}
        />
      </MobileFiltersContainer>
    </div>
  </div>
);

FiltersRow.propTypes = {
  setSelectedSearch: PropTypes.func.isRequired,
  selectedSearch: PropTypes.string.isRequired,
  setSelectedSort: PropTypes.func.isRequired,
  selectedSort: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  selectedView: PropTypes.instanceOf(Object).isRequired,
  setSelectedView: PropTypes.func.isRequired,
};

export default FiltersRow;
