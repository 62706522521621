import {
  branch, compose, renderNothing, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { propOr } from 'ramda';
import { withNamespaces } from 'react-i18next';

import { DIRECT_CHANNEL } from '../../../../constants/messenger';
import InformationRightSideBar from './informationRightSideBar';
import { messengerSelectors } from '../../../../state/messenger';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { uiActions, uiSelectors } from '../../../../state/ui';

const {
  changeMessengerDetailsTabStatus,
  changeMessengerMediaTabStatus,
  changeMessengerPinnedItemsTabStatus,
  changeMessengerNotificationTabStatus,
  changeMessengerMembersTabStatus,
} = uiActions;
const { getTabStatus } = uiSelectors;

const mapStateToProps = state => ({
  channel: messengerSelectors.getActiveChannelEntity(state),
  isMessengerDetailsTabOpen: getTabStatus(state)('isMessengerDetailsTabOpen'),
  isMessengerMediaTabOpen: getTabStatus(state)('isMessengerMediaTabOpen'),
  isMessengerPinnedItemsTabOpen: getTabStatus(state)('isMessengerPinnedItemsTabOpen'),
  isMessengerNotificationTabOpen: getTabStatus(state)('isMessengerNotificationTabOpen'),
  isMessengerMembersTabOpen: getTabStatus(state)('isMessengerMembersTabOpen'),
});

const mapDispatchToProps = ({
  changeMessengerDetailsTabStatus,
  changeMessengerMediaTabStatus,
  changeMessengerPinnedItemsTabStatus,
  changeMessengerNotificationTabStatus,
  changeMessengerMembersTabStatus,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(
    ({ channel }) => !propOr(false, 'name', channel),
    renderNothing,
  ),
  withNamespaces(['common', 'chat']),
  withProps(({ channel: { type, author } }) => ({
    title: type === DIRECT_CHANNEL ? 'About this conversation' : 'About this channel',
    createdBy: author ? getFullName(author) : '',
  })),
);
export default enhance(InformationRightSideBar);
