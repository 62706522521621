import {
  compose, lifecycle, withHandlers,
} from 'recompose';

import withRefs from '../../../../../../utils/enchancers/withRefs';

import Number from './number';

const filterValue = (min, max, value, convertTimeToString, onChange) => {
  let newValue = value ? parseInt(value, 10) : 0;
  if (newValue > max) {
    newValue = max;
  } else if (newValue < min) {
    newValue = min;
  }
  onChange({
    value: newValue,
  });
  return convertTimeToString(newValue);
};
const handlerValue = ({
  min, max, getRef, convertTimeToString, onChange,
}) => (updateValue) => {
  const input = getRef('input').inputElement;
  input.value = filterValue(min, max,
    parseInt(input.value, 10) + updateValue, convertTimeToString, onChange);
};

const convertTime = ({
  convertTimeToString, min, max, getRef, onChange,
}) => () => {
  const input = getRef('input').inputElement;
  input.value = filterValue(min, max, input.value, convertTimeToString, onChange);
};

const specialKeysHandle = ({
  getRef, min, max, convertTimeToString, onChange,
}) => (e) => {
  const input = getRef('input').inputElement;
  const inputValue = parseInt(input.value || 0, 10);
  switch (e.keyCode) {
    case 38:
      input.value = filterValue(min, max, inputValue + 1, convertTimeToString, onChange);
      break;
    case 40:
      input.value = filterValue(min, max, inputValue - 1, convertTimeToString, onChange);
      break;
    default:
  }
};

const enhance = compose(
  withRefs(),
  withHandlers(
    {
      handlerValue,
      convertTimeHandler: convertTime,
      specialKeysHandle,
    },
  ),
  lifecycle({
    componentDidMount() {
      const { getRef, convertTimeHandler } = this.props;
      const fieldElement = getRef('input').inputElement;
      fieldElement.addEventListener('blur', convertTimeHandler, true);
    },
    componentWillUnmount() {
      const { getRef, convertTimeHandler } = this.props;
      const fieldElement = getRef('input').inputElement;
      fieldElement.removeEventListener('blur', convertTimeHandler, true);
    },
  }),
);
export default enhance(Number);
