import React from 'react';
import PropTypes from 'prop-types';

import { ScrollWrapper } from '../../components';
import { FilterRow, ChannelsList } from './components';
import './style.sass';

const Channels = ({
  onGoBack,
  entities,
  title,
  result,
  count,
  itemsOnPage,
  isNext,
  isPrev,
  currentPage,
  t,
}) => (
  <ScrollWrapper>
    <div className="channels">
      <div className="channels__container">
        <button
          onClick={onGoBack}
          type="button"
          className="channels__button-close"
          title={t('Close page Channels')}
          aria-label={t('Close page Channels')}
        >
          <span className="icon-close" />
        </button>
        <FilterRow />
        <ChannelsList
          title={title}
          entities={entities}
          result={result}
          count={count}
          itemsOnPage={itemsOnPage}
          isNext={isNext}
          isPrev={isPrev}
          currentPage={currentPage}
        />
      </div>
    </div>
  </ScrollWrapper>
);

Channels.propTypes = {
  t: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  entities: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  itemsOnPage: PropTypes.number.isRequired,
  isNext: PropTypes.bool.isRequired,
  isPrev: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  result: PropTypes.instanceOf(Array).isRequired,
};
export default Channels;
