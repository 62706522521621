/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { prop, propOr } from 'ramda';
import PropTypes from 'prop-types';
import { FormsCollection, ModalWindow } from '../../../../components';
import { AsyncAutoComplete } from '../../../../components/autoCompleteField';

const EditUser = ({
  isOpen,
  onCloseModal,
  handleChange,
  handleSubmit,
  values,
  errors,
  getProject,
  setFieldValue,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    modalName="pinMessage"
    title="Edit customer"
    className="new-sprint__modal"
  >
    <div id="step-1y" className="card-body p-1">
      <FormsCollection.FormWrapper onSubmit={handleSubmit}>
        <div className="form-row">
          <FormsCollection.Input
            label="Name"
            className="col-md-6"
            id="edit-user__email"
            name="username"
            placeholder="User name"
            error={prop('message', errors.username)}
            value={values.username}
            onChange={handleChange}
          />
          <FormsCollection.Input
            label="Business registration number"
            className="col-md-6"
            id="edit-user__position"
            placeholder="User position"
            name="registration_number"
            value={values.registration_number}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <FormsCollection.Input
            label="First name"
            className="col-md-6"
            id="edit-user__fName"
            name="first_name"
            value={values.first_name}
            onChange={handleChange}
          />
          <FormsCollection.Input
            label="Last name"
            className="col-md-6"
            id="edit-user__lName"
            name="last_name"
            value={values.last_name}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <FormsCollection.Input
            label="Address"
            className="col-md-12"
            id="address_customer-add"
            name="address"
            value={values.address}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <FormsCollection.Input
            label="Organization"
            className="col-md-12"
            id="organization_customer-add"
            name="organization"
            value={values.organization}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label className="col-form-label">Projects</label>
            <AsyncAutoComplete
              isMulti
              name="projects"
              loadOptions={getProject}
              placeholder="Choose projects..."
              closeMenuOnSelect={false}
              value={values.projects}
              placeholderJump="Projects"
              error={propOr('', 'message', errors.projects)}
              onChange={value => setFieldValue('projects', value)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <h3 className="title--sm font-weight-bold">Contacts</h3>
          </div>
          <FormsCollection.Input
            label="Email"
            className="col-md-6"
            id="edit-user__email"
            name="email"
            error={prop('message', errors.email)}
            value={values.email}
            onChange={handleChange}
            placeholder="example@gmail.com"
          />
          <FormsCollection.Input
            label="Phone number"
            className="col-md-6"
            id="edit-user__phone"
            name="phone"
            value={values.phone}
            onChange={handleChange}
            placeholder="+380 (XXX) XXXXXXX"
          />
          <FormsCollection.Input
            label="Website"
            className="col-md-6"
            id="edit-user__website"
            name="website"
            value={values.website}
            onChange={handleChange}
            placeholder="http://example.com"
          />
          <FormsCollection.Input
            label="Facebook"
            className="col-md-6"
            id="edit-user__facebook"
            name="facebook"
            value={values.facebook}
            onChange={handleChange}
            placeholder="http://facebook.com"
          />
        </div>
        <button type="submit" className="btn btn-primary w-150px">Save Changes</button>
      </FormsCollection.FormWrapper>
    </div>
  </ModalWindow>
);

EditUser.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  getProject: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default EditUser;
