import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';

import { getFullName } from '../../../../utils/helpers/userHelpers';

const ChannelsItem = ({ channel }) => (
  <li key={channel.id} className="channels-list__item">
    <Link to={`/messenger/${channel.id}`} className="channels-list__link">
      <div className="channels-list__title-group">
        {
          channel.status ? (
            <span className="channels-list__title-group-icon icon-chanel-lock-icon" />
          ) : (
            <span className="channels-list__title-group-icon icon-chanel-icon" />
          )
        }
        <h3 className="channels-list__title-group-title">{channel.name}</h3>
      </div>
      <div className="channels-list__created-by">
        Created by
        <Link to="/">{`${getFullName(channel.author)} `}</Link>
        {`at ${moment(channel.created_at).format('DD MMM YYYY')}`}
      </div>
    </Link>
  </li>
);

ChannelsItem.propTypes = {
  channel: PropTypes.instanceOf(Object).isRequired,
};

export default ChannelsItem;
