import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const TogglerLeftSideBar = ({ onToggle }) => (
  <>
    <div
      role="button"
      onClick={onToggle}
      aria-label="Toggle navigation"
      className="paper-nav-toggle pp-nav-toggle"
    >
      <i />
    </div>
  </>
);

TogglerLeftSideBar.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

export default TogglerLeftSideBar;
