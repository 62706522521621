import {
  compose, withHandlers, withProps, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { path, pathOr } from 'ramda';
import { withNamespaces } from 'react-i18next';

import { projectsActions, projectsSelectors } from '../../../../state/projects';
import { closeModal, openModal } from '../../../../state/ui/actions';
import { projectActions } from '../../../../state/project';
import ProjectsAsList from './projectsAsList';
import preloaderWhileLoading from '../../../../utils/enchancers/preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';

const setSelectedProjectIdStateHandler = () => id => ({ selectedProjectId: id });

const mapStateToProps = state => ({
  count: projectsSelectors.getProjectsListCount(state),
  projects: projectsSelectors.getProjectsList(state),
});

const mapDispatchToProps = ({
  setOpenModal: openModal,
  push,
  getProjectsRequest: projectsActions.getProjectsListRequest,
  onCloseModal: closeModal,
  deleteProject: projectsActions.deleteProjectRequest,
  getProject: projectActions.getProjectRequest,
  deleteFavoriteProject: projectsActions.removeFromFavoriteProjectsFetch,
  setFavoriteProject: projectsActions.addToFavoriteProjectsFetch,
});

const onOpenEditProjectModalHandler = ({
  setOpenModal, setSelectedProjectId, getProject,
}) => (id) => {
  getProject({ projectId: id });
  setOpenModal('editProjectModal');
  setSelectedProjectId(id);
};

const onOpenCompleteProjectModalHandler = ({ setOpenModal, setSelectedProjectId }) => (id) => {
  setOpenModal('completeProjectModal');
  setSelectedProjectId(id);
};

const onOpenStartProjectModalHandler = ({ setOpenModal, setSelectedProjectId }) => (id) => {
  setOpenModal('startProjectModal');
  setSelectedProjectId(id);
};

const onOpenDeleteProjectModalHandler = ({ setOpenModal, setSelectedProjectId }) => (id) => {
  setOpenModal('deleteProjectModal');
  setSelectedProjectId(id);
};

const onDeleteProjectHandler = ({
  deleteProject,
  selectedProjectId,
  onCloseModal,
  selectedProjectSortMeta,
}) => () => {
  deleteProject({ id: selectedProjectId }, selectedProjectSortMeta);
  onCloseModal('deleteProjectModal');
};

const setProgressProjectHandler = (project) => {
  const allTasks = path(['__meta__', 'tasksCount'], project);
  const closedTasks = allTasks - pathOr(0, ['__meta__', 'openTasksCount'], project);
  return allTasks ? (closedTasks / allTasks) * 100 : 0;
};

const onToggleFavoriteStatusHandler = ({
  deleteFavoriteProject,
  setFavoriteProject,
}) => (e, status, projectId) => {
  e.stopPropagation();
  if (status) {
    deleteFavoriteProject({ id: projectId });
  } else { setFavoriteProject({ id: projectId }); }
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['projects', 'common']),
  withProps({
    setProgressProject: setProgressProjectHandler,
  }),
  withStateHandlers(() => ({ selectedProjectId: null }), {
    setSelectedProjectId: setSelectedProjectIdStateHandler,
  }),
  withHandlers({
    onOpenEditProjectModal: onOpenEditProjectModalHandler,
    onOpenDeleteProjectModal: onOpenDeleteProjectModalHandler,
    onOpenCompleteProjectModal: onOpenCompleteProjectModalHandler,
    onOpenStartProjectModal: onOpenStartProjectModalHandler,
    onDeleteProject: onDeleteProjectHandler,
    onToggleFavoriteStatus: onToggleFavoriteStatusHandler,
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.EXTRA_SMALL,
    alignContainerCenter: true,
    delay: 600,
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
);

export default enhance(ProjectsAsList);
