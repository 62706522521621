const addTaskResponseSchema = ({
  ...data
}) => ({
  task: {
    id: data.created_id,
    ...data,
    isPending: true,
  },
}
);

const editTaskResponseSchema = data => (
  {
    task: {
      ...data,
      isUpdating: true,
    },
  }
);

const deleteTaskResponseSchema = ({ ...data }) => (
  {
    task: {
      ...data,
      isDeleting: true,
    },
  }
);

export {
  addTaskResponseSchema,
  editTaskResponseSchema,
  deleteTaskResponseSchema,
};
