import {
  compose, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik/dist/index';
import { withNamespaces } from 'react-i18next';

import rules from '../rules';
import { getModal } from '../../../../../state/ui/selectors';
import { projectActions, projectSelectors } from '../../../../../state/project';
import CreateNote from './createNote';
import { uiActions } from '../../../../../state/ui';

const mapStateToProps = state => ({
  isOpen: getModal(state)('createNoteModal'),
  isPending: projectSelectors.getAddNoteRequestPending(state),
});

const mapDispatchToProps = ({
  closeModal: () => uiActions.closeModal('createNoteModal'),
  addNote: projectActions.addNoteRequest,
});

const formSubmit = (formValues, { resetForm, closeModal, ...props }) => {
  const { addNote, projectId } = props.props;
  const data = { ...formValues, projectId };

  addNote(data, {
    callbacks: {
      success: () => {
        resetForm({});
        props.props.closeModal();
      },
    },
  });
};

const onCloseModalHandler = ({ closeModal, resetForm }) => () => {
  resetForm({});
  closeModal();
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common']),
  withFormik({
    mapPropsToValues: () => ({
      title: '',
    }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: formSubmit,
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
  }),
);

export default enhance(CreateNote);
