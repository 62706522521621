import React from 'react';
import PropTypes from 'prop-types';

import {
  Header, Chat,
} from '../../../../containers';
import './style.sass';
import HeaderDropDown from './components';
import { GROUP_CHANNEL, PRIVATE_CHANNEL } from '../../../../constants/messenger';

const MessengerContainer = ({
  channelId, activeChannel,
}) => (
  <>
    <Header className="header--messenger">
      <>
        {
          activeChannel.type === GROUP_CHANNEL && (
            <HeaderDropDown />
          )
        }
        {
          activeChannel.type === PRIVATE_CHANNEL && (
            <HeaderDropDown />
          )
        }
      </>
    </Header>
    <div className="main-container__content has-sidebar-left">
      <Chat channelId={Number(channelId)} />
    </div>
  </>
);

MessengerContainer.propTypes = {
  channelId: PropTypes.string,
  activeChannel: PropTypes.instanceOf(Object).isRequired,
};

MessengerContainer.defaultProps = {
  channelId: 0,
};

export default MessengerContainer;
