import {
  withStateHandlers, compose, withProps, lifecycle, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import Header from './header';
import { getFullName } from '../../utils/helpers/userHelpers';
import { getImageUrl } from '../../utils/helpers/requestHelpers';
import { userSelectors } from '../../state/user';
import { TITLES } from '../../constants/titles';
import { uiSelectors } from '../../state/ui';
import { getOfflineRequestsStatus } from '../../utils/middlewares/sagaRequestApi/state/offlineRequest/selectors';

const mapStateToProps = state => ({
  user: userSelectors.getUserData(state),
  page: uiSelectors.getPage(state),
  subTitle: uiSelectors.getSubTitle(state),
  mainTitle: uiSelectors.getMainTitle(state),
  offlineRequestsStatus: getOfflineRequestsStatus(state),
});

const toggleDisplaySearch = () => val => ({ displaySearch: val });

const enhance = compose(
  connect(mapStateToProps),
  withState('titlePage', 'setTitlePage', ({ page }) => TITLES[page]),
  withState('isOnline', 'setIsOnline', window.navigator.onLine),
  withStateHandlers(
    () => ({ displaySearch: false }), {
      displaySearchStateHandler: toggleDisplaySearch,
    },
  ),
  withProps(({ user }) => {
    const avatarUserId = pathOr(null, ['avatar', 'id'], user);
    return {
      userName: getFullName(user),
      userAvatar: avatarUserId ? getImageUrl(avatarUserId) : '',
    };
  }),
  lifecycle({
    componentDidMount() {
      window.addEventListener('online', () => this.props.setIsOnline(true));
      window.addEventListener('offline', () => this.props.setIsOnline(false));
    },
    componentDidUpdate({ page }) {
      if (this.props.page !== page) {
        this.props.setTitlePage(TITLES[this.props.page]);
      }
    },
  }),
);
export default enhance(Header);
