/* eslint-disable no-unused-vars,react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { propOr } from 'ramda';
import PieChart from 'react-minimal-pie-chart';

import {
  LabelPriorityTask, ButtonsCollection, DropDown, Avatar, Grid, PendingControl,
} from '../../../../components';
import './style.sass';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';
import PermissionElement from '../../../../components/permissionElement';

const TaskItem = ({
  categories,
  id,
  isItemDrag,
  onEdit,
  onDelete,
  classNameStatus,
  assigneeName,
  estimatedTime,
  spentTime,
  progress,
  author,
  assignee,
  authorName,
  data,
  deadline,
  P,
  t,
  onResetOfflineDelete,
  onResetOfflineEdit,
  onResetOfflineCreate,
  match: { params },
  isDisabledSprint,
}) => (
  <Grid.Row className={`project-task ${classNameStatus} ${isItemDrag ? 'project-task--drag' : ''} `}>
    <PendingControl
      onResetOfflineDelete={onResetOfflineDelete}
      onResetOfflineEdit={onResetOfflineEdit}
      onResetOfflineCreate={onResetOfflineCreate}
      isPending={data.isPending}
      isUpdating={data.isUpdating}
      isDeleting={data.isDeleting}
    />
    <Grid.ColData className="project-task__col--id">
      <div className="text--cut">
        {data.id}
      </div>
    </Grid.ColData>
    <Grid.ColData className="project-task__col--subject">
      <Link to={`/projects/${params.id}/task/${id}`} className="project-task__title-name-task text--cut">
        {data.title}
      </Link>
    </Grid.ColData>
    <Grid.ColData className="project-task__col--priority">
      <LabelPriorityTask className="project-task__priority-label" status={data.priority} />
    </Grid.ColData>
    <Grid.ColData className="project-task__col--category">
      <span className="text--cut">
        {propOr(t('None'), 'title', categories[data.category_id])}
      </span>
    </Grid.ColData>
    <Grid.ColData className="project-task__col--author">
      { author && (
        <Avatar className="project-task__avatar" src={getImageUrl(author.avatar)} alt={authorName} />
      )}
    </Grid.ColData>
    <Grid.ColData className="project-task__col--assignee">
      { assignee ? (
        <Avatar className="project-task__avatar" src={getImageUrl(assignee.avatar)} alt={assigneeName} />
      ) : (
        <Avatar className="project-task__avatar project-task__avatar-not">
          <div className="project-task--unassigned_icon-wrapper" title="Without Assignee">
            <span className="icon-author-user project-task--unassigned_icon" />
          </div>
        </Avatar>
      )
            }
    </Grid.ColData>
    <Grid.ColData className="project-task__col--estimation">
      <b className="project-task__estimation">
        {estimatedTime}
      </b>
    </Grid.ColData>
    <Grid.ColData className="project-task__col--spent">
      <b className="project-task__spent">
        {spentTime}
      </b>
    </Grid.ColData>
    <Grid.ColData className="project-task__col--deadline">
      <b className="project-task__deadline">
        {deadline}
      </b>
    </Grid.ColData>
    <Grid.ColData className="project-task__col--progress">
      <PieChart
        style={{ width: '42px' }}
        startAngel={0}
        radius={30}
        data={[{ value: 1, key: 1, color: '#E38627' }]}
        reveal={progress}
        lineWidth={13}
        background="#cecece"
        rounded
        lengthAngle={360}
        animate
      />
    </Grid.ColData>
    <Grid.ColData className="project-task__col--controls">
      <PermissionElement module={MODULES.TASKS} action={[ACTIONS.CREATE, ACTIONS.DELETE]}>
        {
          isDisabledSprint || (
            <DropDown
              className="controls-group-task"
              name="controls-group-task"
              label={(
                <ButtonsCollection.ButtonIcons
                  className="project-task__controls-button-task"
                  title="Controls for sprint"
                >
                  <div className="s-24 icon-more_vert" />
                </ButtonsCollection.ButtonIcons>
              )}
              list={
                [
                  ...(P(MODULES.TASKS, ACTIONS.CREATE) ? ([{
                    id: 0,
                    onClick: onEdit,
                    label: (
                      <>
                        <span className="controls-group-task__icon icon-pencil-2" />
                        <span className="controls-group-task__text">{t('Edit')}</span>
                      </>
                    ),
                  }]) : []),
                  ...(P(MODULES.TASKS, ACTIONS.DELETE) ? ([{
                    id: 2,
                    onClick: onDelete,
                    label: (
                      <>
                        <span className="controls-group-task__icon icon-delete-icon" />
                        <span className="controls-group-task__text">{t('Delete')}</span>
                      </>
                    ),
                  }]) : []),
                ]
              }
            />
          )
        }
      </PermissionElement>
    </Grid.ColData>
  </Grid.Row>
);

TaskItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isItemDrag: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};

export default TaskItem;
