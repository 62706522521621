import React from 'react';
import PropTypes from 'prop-types';

import { Layouts } from '../../components';
import { EditUser, DeleteUser } from './components';
import { LeftSideBar, Header, UsersContainer } from '../../containers';
import './style.sass';
import InviteMembersModal from '../messenger/modals/inviteMembersModal';
import PermissionElement from '../../components/permissionElement';
import { ACTIONS, MODULES } from '../../constants/pemissions';

const Users = ({ selectedUserId, onInviteUsersModal, sort }) => (
  <Layouts.Main className="page-project">
    <LeftSideBar>
      {/* <Categories /> */}
      {/* <CoWorkers /> */}
    </LeftSideBar>
    <div className="main-container page-project__main-container">
      <Header>
        <div className="row">
          <div className="col-12 p-0 d-flex align-items-center justify-content-end">
            <PermissionElement module={MODULES.USERS} action={ACTIONS.CREATE}>
              <button type="button" className="btn-primary btn-lg button-create-project" onClick={onInviteUsersModal}>
Invite
                <span className="hidden-sm">users</span>
              </button>
            </PermissionElement>
          </div>
        </div>
      </Header>
      <div className="page  has-sidebar-left height-full">
        <UsersContainer />
      </div>
    </div>
    <EditUser selectedUserId={selectedUserId} sort={sort} />
    <DeleteUser selectedUserId={selectedUserId} sort={sort} />
    <InviteMembersModal />
  </Layouts.Main>
);

Users.propTypes = {
  selectedUserId: PropTypes.number.isRequired,
  onInviteUsersModal: PropTypes.func.isRequired,
  sort: PropTypes.instanceOf(Object).isRequired,
};

export default Users;
