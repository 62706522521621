/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import TasksGrid from '../tasksGrid';
import './style.sass';
import { PermissionElement } from '../../../../components';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const Backlog = ({
  openModal, tasks, onSortBy, sort, order, gridName, t, isProjectCompleted,
}) => (
  <div
    className={`table-tasks__sprint table-tasks__sprint--backlog ${isProjectCompleted ? 'table-tasks__sprint--completed' : ''}`}
  >
    <div className="table-tasks__about-sprint">
      <h2 className="table-tasks__name-sprint">{t('Backlog')}</h2>
      <PermissionElement module={MODULES.TASKS} action={ACTIONS.CREATE}>
        <button
          type="button"
          onClick={() => openModal('createSprintModal')}
          className="btn btn-outline-primary w-150px"
        >
          {t('New Sprint')}
        </button>
      </PermissionElement>
    </div>
    <TasksGrid
      isDisabledSprint={isProjectCompleted}
      sort={sort}
      order={order}
      isTaskHasSprint={false}
      isSprint={false}
      onSortBy={onSortBy}
      name="backlog-grid"
      id={gridName}
      items={tasks}
    />
  </div>
);

Backlog.propTypes = {
  openModal: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.number),
};

Backlog.defaultProps = {
  tasks: [],
};

export default Backlog;
