import { createSelector } from 'reselect/lib/index';
import {
  pathOr, identity, memoizeWith,
} from 'ramda';

const myActivityPerDayEntities = createSelector(
  identity,
  state => memoizeWith(identity, userId => pathOr({}, ['statistics', [userId], 'entities', 'activityPerDay'], state)),
);

const myActivityPerDayResult = createSelector(
  identity,
  state => memoizeWith(identity, userId => pathOr([], ['statistics', [userId], 'result', 'activityPerDay'], state)),
);

const myActivityTotal = createSelector(
  identity,
  state => memoizeWith(identity, userId => pathOr({}, ['statistics', [userId], 'result', 'total'], state)),
);

export {
  myActivityPerDayEntities,
  myActivityPerDayResult,
  myActivityTotal,
};
