import React from 'react';
import PropTypes from 'prop-types';

import {
  Avatar, OnlineStatusLabel, BrillMask, ButtonsCollection,
} from '../../../../components';
import userAvatar from '../../../../assets/testAssets/avatar.jpeg';

const MemberItem = ({ user, t }) => (
  <li className="project-members__item">
    <BrillMask outsideChildren={<OnlineStatusLabel status={user.is_online} />}>
      <Avatar src={userAvatar} />
    </BrillMask>
    <button type="button" className="project-members__link">
      {`${user.first_name} ${user.last_name}`}
    </button>
    <ButtonsCollection.ButtonIcons
      className="project-members__button-remove"
      title={t('Remove member')}
    >
      <span className="icon-close" />
    </ButtonsCollection.ButtonIcons>
  </li>
);

MemberItem.propTypes = {
  user: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

export default MemberItem;
