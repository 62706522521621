/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import {
  propOr, prop, isEmpty, path,
} from 'ramda';

import {
  FormsCollection, ModalWindow, Button,
} from '../../components';
import DefaultAutoComplete, { AsyncAutoComplete } from '../../components/autoCompleteField';

const ModalView = ({
  isOpen,
  isOnline,
  isPending,
  onCloseModal,
  handleSubmit,
  handleChange,
  setFieldValue,
  handleBlur,
  touched,
  values,
  errors,
  action,
  priorities,
  name,
  modalTitle,
  t,
  getUsersAutocomplete,
  getProject,
  onChangeProject,
  sprintsList,
  isDisabledProjectField,
  getMaterials,
  match,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={() => onCloseModal(name)}
    className="create-task__modal"
    title={modalTitle}
  >
    <div id="step-1y" className="card-body p-1">
      <FormsCollection.FormWrapper
        onSubmit={handleSubmit}
        className="create-task"
      >
        <div className="form-row">
          <div className="col-lg-12">
            <FormsCollection.Input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              error={propOr('', 'message', errors.title)}
              touched={touched.title}
              name="title"
              label="Title"
              placeholderJump={t('Task name')}
              className="col-md-12"
              id="create-task__title"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-12">
            <FormsCollection.TextArea
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              error={propOr('', 'message', errors.description)}
              touched={touched.description}
              placeholderJump={t('Description')}
              name="description"
              className="col-lg-12"
              label="Description"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-12">
            <div
              className="form-group col-lg-12"
              title={!isOnline ? 'Only in online version' : ''}
            >
              <label className="col-form-label" htmlFor="materials-task">
                {t('Materials')}
              </label>
              <AsyncAutoComplete
                disabled={!isOnline}
                name="materials"
                placeholder={t('Choose materials')}
                error={propOr('', 'message', errors.materials)}
                closeMenuOnSelect
                isMulti
                touched={touched.materials}
                value={values.materials}
                defaultValue={values.materials}
                loadOptions={getMaterials}
                onChange={value => setFieldValue('materials', value)}
              />
            </div>
          </div>
        </div>
        <div className="create-task__row">
          <div
            className="col-lg-6"
            title={!isOnline ? 'Only in online version' : ''}
          >
            <label className="col-form-label">
              {t('Assignee')}
            </label>
            <AsyncAutoComplete
              name="assignee"
              placeholder={t('Choose assignee')}
              error={propOr('', 'message', errors.assignee)}
              closeMenuOnSelect
              touched={touched.assignee}
              value={values.assignee}
              defaultValue={values.assignee}
              disabled={!isOnline}
              loadOptions={getUsersAutocomplete}
              onChange={value => setFieldValue('assignee', value)}
            />
          </div>
          <div
            className="col-lg-6"
            title={!isOnline ? 'Only in online version' : ''}
          >
            <label className="col-form-label">
              {t('Project')}
            </label>
            <AsyncAutoComplete
              title={!isOnline ? 'Only in online version' : ''}
              name="project_id"
              placeholder={t('Choose project')}
              error={prop('id', errors.project_id)}
              closeMenuOnSelect
              disabled={isDisabledProjectField || !isOnline}
              value={isOnline ? values.project_id : path(['params', 'id'], match)}
              defaultValue={isOnline ? values.project_id : path(['params', 'id'], match)}
              loadOptions={getProject}
              onChange={onChangeProject}
              touched
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-12">
            <div
              className="form-group col-lg-12"
              title={!isOnline ? 'Only in online version' : ''}
            >
              <label className="col-form-label ">
                {t('Watchers')}
              </label>
              <AsyncAutoComplete
                isMulti
                name="watchers"
                placeholder={t('Choose watchers')}
                error={errors.watchers}
                closeMenuOnSelect={false}
                value={values.watchers}
                clearable
                autoload={false}
                defaultValue={values.watchers}
                loadOptions={getUsersAutocomplete}
                onChange={value => setFieldValue('watchers', value)}
                touched={touched.watchers}
                disabled={!isOnline}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-12">
            <div className="row m-0">
              <div className="col-lg-3 form-group">
                <label className="col-form-label">
                  {t('Estimation')}
                </label>
                <FormsCollection.Input
                  name="estimated_time"
                  id="create-task__estimated-time"
                  onChange={handleChange}
                  placeholder={t('1w 20m 21h 30m')}
                  onBlur={handleBlur}
                  value={values.estimated_time}
                  touched
                  error={errors.estimated_time}
                />
              </div>
              <div className="col-lg-3 form-group">
                <label className="col-form-label">
                  {t('Deadline')}
                </label>
                <FormsCollection.DatePicker
                  name="deadline"
                  onChange={handleChange}
                  value={values.deadline}
                  error={errors.deadline}
                  minDate={new Date()}
                  touched={touched.deadline}
                />
              </div>
              <div className="col-lg-3">
                <label className="col-form-label">
                  {t('Priority')}
                </label>
                <FormsCollection.Select
                  isRequired
                  className="col-lg-12"
                  onChange={handleChange}
                  name="priority"
                  value={values.priority}
                  error={prop('message', errors.priority)}
                  data={priorities}
                />
              </div>
              <div className="col-lg-3 form-group">
                <label className="col-form-label">
                  {t('Sprint')}
                </label>
                <DefaultAutoComplete
                  name="sprint_id"
                  placeholder={t('Choose sprint')}
                  error={propOr('', 'message', errors.sprint_id)}
                  closeMenuOnSelect
                  disabled={isEmpty(values.project_id)}
                  touched={touched.sprint_id}
                  value={values.sprint_id}
                  options={sprintsList}
                  defaultValue={values.sprint_id}
                  onChange={value => setFieldValue('sprint_id', value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-group">
          <Button
            className="btn btn-success m-1"
            type="submit"
            disabled={isPending}
            onClick={() => { setFieldValue('submitButton', 'create'); }}
          >
            {action}
          </Button>
          {
          name === 'createTaskModal' && (
            <Button
              disabled={isPending}
              className="btn btn-primary m-1"
              type="submit"
              onClick={() => { setFieldValue('submitButton', 'saveAndCreate'); }}
            >
              {t('Save and create another')}
            </Button>
          )
        }
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  onChangeProject: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  getUsersAutocomplete: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  priorities: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  sprintsList: PropTypes.arrayOf(Object).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  getProject: PropTypes.func.isRequired,
  isDisabledProjectField: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  getMaterials: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

export default ModalView;
