import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../../../../../../components';
import { EditRepositoryModal, DeleteRepositoryModal } from '../../../../modals';
import './style.sass';

/* todo:  Find solution for remove this error */
/* eslint-disable react/no-danger */

const RepositoryItem = ({
  isOpen,
  toggleIsOpen,
  name,
  openModal,
  id,
  url,
}) => (
  <div
    className={`repositories-list__table-row ${isOpen ? 'repositories-list__table-row--active' : ''}`}
  >
    <div className="repositories-list__table-col"><b>{name}</b></div>
    <div
      className="repositories-list__table-col repositories-list__table-col--description"
      dangerouslySetInnerHTML={{ __html: url }}
    />
    <div className="repositories-list__table-col">
      <div className="repositories-list__controls-group">
        <ButtonsCollection.ButtonIcons
          className="repositories-list__show-more-button"
          title={`${isOpen ? 'Hide information' : 'Show information'}`}
          onClick={() => toggleIsOpen(id)}
        >
          <span className="icon-down" />
        </ButtonsCollection.ButtonIcons>
        <ButtonsCollection.ButtonIcons
          className="repositories-list__edit-button"
          title="Edit note"
          onClick={() => { openModal(`editRepositoryModal${id}`); }}
        >
          <span className="icon-edit-icon" />
        </ButtonsCollection.ButtonIcons>
        <ButtonsCollection.ButtonIcons
          className="repositories-list__delete-button"
          onClick={() => { openModal(`deleteRepositoryModal${id}`); }}
          title="Delete note"
        >
          <span className="icon-delete-icon" />
        </ButtonsCollection.ButtonIcons>
      </div>
    </div>
    <EditRepositoryModal id={id} />
    <DeleteRepositoryModal id={id} />
  </div>
);
RepositoryItem.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleIsOpen: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};
export default RepositoryItem;
