import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import {
  ModalWindow, FormsCollection,
} from '../../../../components';
import './style.sass';

const ModalView = ({
  isOpen,
  onCloseModal,
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  errors,
  title,
  titleAction,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    className="crud-notes__modal"
    title={title}
  >
    <div id="step-1y" className="card-body p-1">
      <FormsCollection.FormWrapper
        onSubmit={handleSubmit}
        className="crud-notes"
      >
        <div className="form-row">
          <FormsCollection.Input
            placeholderJump="Note title"
            label="Title"
            className="col-lg-12"
            id="crud-notes__title"
            name="title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            touched
            error={propOr('', 'message', errors.title)}
          />
        </div>
        <div className="form-row">
          <FormsCollection.TextArea
            name="content"
            label="Description"
            className="col-lg-12"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.content}
            touched
            error={propOr('', 'message', errors.content)}
            placeholderJump="Description"
          />
        </div>
        <div className="form-row">
          <div className="col-lg-12">
            <button
              type="submit"
              className="btn btn-primary"
            >
              {titleAction}
            </button>
          </div>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  isPending: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
};

export default ModalView;
