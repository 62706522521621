/* eslint-disable max-len */
import {
  curry, pathOr, omit, filter,
} from 'ramda';
import {
  setBacklogTask,
  setBacklogTaskToList,
  setSprintTask,
  setSprintTaskToList,
  deleteSprintEntity,
  reorderSprint,
  reorderCategoryTask,
  deleteCategoryTaskEntity,
  reorderNotes,
  deleteNoteEntity,
  reorderProjectMembers,
} from '../../../state/project/actions';
import { getSprintsList, getProjectSprintsTasks } from '../../../state/project/selectors';

const setTask = curry((setItemToEntities, setItemToList, bauthor, assigneeUser, data, itemId, projectId, accum) => {
  accum(setItemToEntities(data));
  accum(setItemToList({ itemId, projectId, sprintId: data[itemId].sprint_id }));
});

const setTaskToSprint = setTask(
  setSprintTask,
  setSprintTaskToList,
);

const setTaskToBacklog = setTask(
  setBacklogTask,
  setBacklogTaskToList,
);

const deleteSprint = (sprintId, projectId, state, next) => {
  const result = getSprintsList(state)(projectId);
  const entities = getProjectSprintsTasks(state)(projectId);

  next(reorderSprint({ result: filter(id => id !== sprintId, result), projectId }));
  next(deleteSprintEntity({ result: omit([sprintId], entities), projectId }));
};

const deleteCategoryTask = (state, categoryId, next) => {
  const entities = pathOr({}, ['project', 'entities', 'categories'], state);
  const result = pathOr({}, ['project', 'result', 'categories'], state);
  next(reorderCategoryTask(filter(id => categoryId !== id, result)));
  next(deleteCategoryTaskEntity(omit([categoryId], entities)));
};

const deleteNote = (state, noteId, next) => {
  const entities = pathOr({}, ['project', 'entities', 'documents'], state);
  const result = pathOr({}, ['project', 'result', 'documents'], state);
  next(reorderNotes(filter(id => noteId !== id, result)));
  next(deleteNoteEntity(omit([noteId], entities)));
};

const deleteProjectMember = (state, memberId, next) => {
  const result = pathOr({}, ['project', 'result', 'members'], state);
  next(reorderProjectMembers(filter(id => memberId !== id, result)));
};

export {
  setTaskToSprint,
  setTaskToBacklog,
  deleteSprint,
  deleteCategoryTask,
  deleteNote,
  deleteProjectMember,
};
