const addMaterialResponseSchema = data => ({
  material: {
    id: new Date().getTime(),
    ...data,
    isPending: true,
  },
});

const editMaterialResponseSchema = data => ({
  material: {
    ...data,
    isUpdating: true,
  },
});

const deleteMaterialResponseSchema = data => ({
  material: {
    ...data,
    isDeleting: true,
  },
});


export {
  addMaterialResponseSchema,
  editMaterialResponseSchema,
  deleteMaterialResponseSchema,
};
