import React from 'react';
import PropTypes from 'prop-types';

import { ScrollWrapper } from '../../components';
import { FilterRow, DirectsList } from './components';
import './style.sass';

const Directs = ({
  onGoBack,
  directChannelsCount,
  entities,
  title,
  itemsOnPage,
  isNext,
  isPrev,
  currentPage, t,
  directChannelsList,
}) => (
  <ScrollWrapper>
    <div className="directs">
      <div className="directs__container">
        <button
          onClick={onGoBack}
          type="button"
          className="directs__button-close"
          title={t('Close page Directs')}
          aria-label={t('Close page Directs')}
        >
          <span className="icon-close" />
        </button>
        <FilterRow />
        <DirectsList
          title={title}
          entities={entities}
          result={directChannelsList}
          count={directChannelsCount}
          itemsOnPage={itemsOnPage}
          isNext={isNext}
          isPrev={isPrev}
          currentPage={currentPage}
        />
      </div>
    </div>
  </ScrollWrapper>
);

Directs.propTypes = {
  t: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  directChannelsCount: PropTypes.number.isRequired,
  entities: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  itemsOnPage: PropTypes.number.isRequired,
  isNext: PropTypes.bool.isRequired,
  isPrev: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  directChannelsList: PropTypes.instanceOf(Array).isRequired,
};
export default Directs;
