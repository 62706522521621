import React from 'react';
import PropTypes from 'prop-types';
import { TotalTimeItem, FieldsName } from './components';
import './style.sass';

const TotalTimeList = ({ data }) => (
  <div className="total-time-list">
    <FieldsName fields={data.fields} />
    {
      data.items.map(item => (
        <TotalTimeItem key={Math.random() * new Date()} data={item} />
      ))
    }
  </div>
);
TotalTimeList.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ])).isRequired,
};
export default TotalTimeList;
