import React from 'react';
import PropTypes from 'prop-types';

import { ScrollWrapper } from '../../components';
import { FilterRow, MaterialsList } from './components';
import './style.sass';

const MaterialsContainer = ({ onChangeSorting, sortBy }) => (
  <ScrollWrapper heightIs="100%">
    <FilterRow onChangeSorting={onChangeSorting} sortBy={sortBy} />
    <MaterialsList sortBy={sortBy} />
  </ScrollWrapper>
);

MaterialsContainer.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  onChangeSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.instanceOf(Object).isRequired,
};

export default MaterialsContainer;
