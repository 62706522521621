/* eslint-disable max-len */
import PropTypes from 'prop-types';

import { compose, withProps } from 'recompose';
import React from 'react';

const WithSorting = ({ onSortBy, values }) => (BaseComponent) => {
  const WrapperComponent = props => <BaseComponent {...props} onSortBy={onSortBy(props)} />;
  const enhancer = compose(
    withProps(props => ({
      sort: values.sort(props),
      order: values.order(props),
    })),
  );
  return enhancer(WrapperComponent);
};

WithSorting.propTypes = {
  sortBy: PropTypes.func.isRequired,
};

export default WithSorting;
