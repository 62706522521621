import {
  compose, withHandlers, defaultProps,
} from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import { withFormik } from 'formik';
import { propOr } from 'ramda';
import { withRouter } from 'react-router';

import { getModal } from '../../state/ui/selectors';
import { uiActions } from '../../state/ui';

const mapStateToProps = (state, { modalName }) => ({
  isOpen: getModal(state)(modalName),
});

const mapDispatchToProps = {
  closeModal: uiActions.closeModal,
};

const addDaysToDate = (date, count) => moment(date).add('days', count).toDate();

const setInitialValues = ({ sprint }) => ({
  title: propOr('', 'title', sprint),
  startDate: new Date(propOr(new Date(), 'start_time', sprint)),
  finishDate: new Date(propOr(addDaysToDate(new Date(), 14), 'end_time', sprint)),
  startTime: {
    hours: 0,
    minutes: 0,
  },
  finishTime: {
    hours: 0,
    minutes: 0,
  },
  description: propOr('', 'description', sprint),
});

const getValidFinishDate = ({ values }) => () => {
  const { finishDate, startDate } = values;

  if (!finishDate) return '';

  if (finishDate < startDate) {
    return moment(startDate).add(1, 'day').toDate();
  }
  return finishDate;
};

const combineTimeAndDate = (date, hours, minutes) => {
  const dateObject = new Date(date);
  dateObject.setMinutes(minutes);
  dateObject.setHours(hours);
  return dateObject;
};

const onCloseModalHandler = ({ closeModal, modalName }) => () => {
  closeModal(modalName);
};

const onChangeStartDateHandler = ({
  handleChange, setFieldValue,
}) => (e) => {
  const { target: { value } } = e;
  const finishDate = value ? moment(e.target.value).add(14, 'day').toDate() : '';

  setFieldValue('finishDate', finishDate);
  handleChange(e);
  return value;
};

const getObjectForSend = ({
  title, description, startDate, startTime, finishDate, finishTime,
}) => ({
  title,
  start_time: combineTimeAndDate(startDate, startTime.hours, startTime.minutes),
  end_time: combineTimeAndDate(finishDate, finishTime.hours, finishTime.minutes),
  description,
});

const withCRUDTask = ({
  rules,
  onSubmit,
  modalName,
  data,
}) => compose(
  defaultProps({
    modalName, onSubmit, ...data, rules,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withFormik({
    mapPropsToValues: setInitialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: (formData, props) => onSubmit(getObjectForSend(formData), props),
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
    onChangeStartDate: onChangeStartDateHandler,
    getValidFinishDate,
  }),
);
export default withCRUDTask;
