import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from '../../../../components';
import './style.sass';

const AvatarProfile = ({
  onUploadFile, fileUrl, value, name, alt, onResetAvatar,
}) => (
  <div className="avatar-profile">
    <div className="avatar-profile__title">Profile photo</div>
    <div
      className="avatar-profile__label"
    >
      <input
        id="field-profile__avatar-profile"
        className="avatar-profile__input"
        onChange={onUploadFile}
        name={name}
        type="file"
      />
      <Avatar src={fileUrl || value} alt={alt}>
        <label
          className="field-profile__pseudo-overlay brill-mask__ignore"
          htmlFor="field-profile__avatar-profile"
        >
          <span className="field-profile__pseudo-overlay-icon brill-mask__ignore icon-camera" />
          <div className="field-profile__pseudo-overlay-title brill-mask__ignore">Change Photo</div>
        </label>
      </Avatar>
    </div>
    {
      (fileUrl || value) && (
        <div className="row m-1">
          <div className="col-lg-12 d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-sm btn-danger w-150px"
              onClick={onResetAvatar}
            >
              Remove Avatar
            </button>
          </div>
        </div>
      )
    }
  </div>
);


AvatarProfile.propTypes = {
  onUploadFile: PropTypes.func.isRequired,
  fileUrl: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onResetAvatar: PropTypes.func.isRequired,
};

export default AvatarProfile;
