import React from 'react';
import PropTypes from 'prop-types';
import { DropDown } from '../../../../components';
import './style.sass';

const FiltersRow = ({ setGroupBy }) => (
  <div className="my-tasks-window__tasks-filter__row">
    <div className="my-tasks-window__tasks-filter__sort-group">
      <DropDown
        label="Sort by : "
        list={
          [
            {
              id: 0,
              label: 'Recent',
            },
            {
              id: 1,
              label: 'Old',
            },
          ]
        }
      />
      <DropDown
        label="Group by : "
        onChange={({ val }) => setGroupBy(val.type)}
        list={
          [
            {
              id: 0,
              type: 'tasks',
              label: 'Tasks',
            },
            {
              id: 1,
              type: 'projects',
              label: 'Project',
            },
          ]
        }
      />
    </div>
  </div>
);

FiltersRow.propTypes = {
  setGroupBy: PropTypes.func.isRequired,
};
export default FiltersRow;
