import {
  compose, lifecycle, pure, withHandlers, withState, defaultProps,
} from 'recompose';
import ButtonBrill from './buttonBrill';

const onSetDelayHandler = ({ setIsDelay, delay }) => () => {
  setIsDelay(true);
  setTimeout(() => setIsDelay(false), delay);
};

const enhance = compose(
  pure,
  defaultProps({
    delay: 0,
    onEndDelay: () => {},
  }),
  withState('isDelay', 'setIsDelay', false),
  withHandlers({
    onSetDelay: onSetDelayHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        pending, onSetDelay, isDelay, onEndDelay,
      } = this.props;
      if (pending !== prevProps.pending) {
        onSetDelay(false);
      }
      if (!isDelay && isDelay !== prevProps.isDelay) {
        onEndDelay(this.props);
      }
    },
  }),
);
export default enhance(ButtonBrill);
