import React from 'react';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  getContext,
  hoistStatics,
  lifecycle,
  renderNothing,
  withHandlers,
  withState,
  withStateHandlers,
} from 'recompose';

import { connect } from 'react-redux';
import withLifecycle from '@hocs/with-lifecycle';
import {
  subtract, path, prop, clone, mapObjIndexed, equals,
  isNil, forEachObjIndexed, dec, cond, T, isEmpty,
} from 'ramda';

import { MessageItem } from './components';
import MessageHistory from './messageHistory';

import { messengerActions, messengerSelectors } from '../../../../state/messenger';
import { userSelectors } from '../../../../state/user';
import { uiActions } from '../../../../state/ui';

import {
  convertToHtml,
  increaseMessageIndex,
} from '../../../../utils/helpers/messengerHelpers/messages';
import { withRefs } from '../../../../utils/enchancers';
import { debounce } from '../../../../utils/helpers/commonHelpers';

const DEFAULT_MESSAGE_HEIGHT = 98;

const mapStateToProps = state => ({
  lastMessageId: messengerSelectors.getLastMessageId(state)(path(['messenger', 'activeChannel', 'id'], state)),
  messageTimestamps: messengerSelectors.getMessageDays(state)(path(['messenger', 'activeChannel', 'id'], state)),
  submitMessageRequest: messengerSelectors.submitMessageRequest(state),
  bootData: userSelectors.getUserData(state),
});

const mapDispatchToProps = ({
  getMessages: messengerActions.getLatestMessagesRequest,
  redMessagesRequest: messengerActions.readMessagesRequest,
  updateMessagesRequest: messengerActions.updateMessageRequest,
  setLastMessageId: messengerActions.setLastMessageId,
  onUpdateMessage: messengerActions.updateMessage,
  setClosedModal: uiActions.closeModal,
});

const onRenderItemHandler = ({
  messages,
  channelId,
  setLoadedMessagesHeight,
  messageTimestamps,
  onSetOffset,
  onRenderContent,
  onResizeMessage,
  unreadIndex,
  setIsScrollToBottom,
// eslint-disable-next-line react/prop-types
}) => ({ style, index }) => (
  <MessageItem
    index={index}
    top={style.offset}
    key={messages[index]}
    id={messages[index]}
    channelId={channelId}
    isUnread={equals(index - 1, unreadIndex)}
    messageTimestamps={messageTimestamps}
    setMessagesDimensions={setLoadedMessagesHeight}
    onSetOffset={onSetOffset}
    onRenderContent={onRenderContent}
    setIsScrollToBottom={setIsScrollToBottom}
    onResizeMessage={onResizeMessage}
  />
);

const setLoadingMoreMessageStateHandler = () => value => ({ loadingMoreMessage: value });

const onScrollHandler = () => ({
  loadingMoreMessage, hasMore, getMessages, messagesCount, channelId,
  setLoadingMoreMessage, isAbleToGetMoreMessage, getRef, setIsScrollBottom,
}) => () => {
  const { clientHeight, scrollTop, scrollHeight } = getRef('scroll').getValues();
  const isMakeGetMessagesRequest = scrollTop < 300 && !loadingMoreMessage
    && hasMore && isAbleToGetMoreMessage;
  if (clientHeight + scrollTop === scrollHeight) {
    setIsScrollBottom(true);
  } else {
    setIsScrollBottom(false);
  }
  if (isMakeGetMessagesRequest) {
    setLoadingMoreMessage(true);
    getMessages({ offset: messagesCount, limit: 20, channelId }, { showMore: true });
  }
};

const onGetItemSizeHandler = ({ itemsHeight }) => index => (prop('offset', itemsHeight[index]) ? itemsHeight[index].offset : DEFAULT_MESSAGE_HEIGHT);

const setItemsHeightStateHandler = ({ itemsHeight }) => ({
  offset,
  index,
}) => ({
  itemsHeight: { ...itemsHeight, [index]: ({ offset, index, fresh: true }) },
});

const mergeItemsHeightStateHandler = ({ itemsHeight }) => items => ({
  itemsHeight: { ...itemsHeight, ...items },
});

const setNewItemsHeightStateHandler = () => data => ({ itemsHeight: data });

const setIsUpdateOffsetImmediatelyStateHandler = () => value => ({
  isUpdateOffsetImmediately: value,
});

const onSetOffsetHandler = ({ setItemsHeight, itemsHeight }) => (data) => {
  if (!isNil(itemsHeight[data.index])) {
    if (data.offset !== itemsHeight[data.index].offset) {
      setItemsHeight(data);
    }
  } else {
    setItemsHeight(data);
  }
};

const rerender = debounce((reset, index, prevScrollDistancePercent, getRef, height) => {
  requestAnimationFrame(() => {
    const scrollHeight = getRef('scroll').getScrollHeight() - height;
    const offset = scrollHeight * prevScrollDistancePercent / 100;
    getRef('scroll').scrollTop(offset);
  });
}, 150);

const onItemsRenderedHandler = ({
  setScrollToIndex, itemsHeight, mergeItemsHeight, loadingMoreMessage, setLoadingMoreMessage,
  setIsAbleToGetMoreMessage, scrollToIndex, lastScrollOffset, getRef, messagesCount,
  setLastScrollOffset, isUpdateOffsetImmediately, height, setIsUpdateOffsetImmediately,
}) => ({ start, reset }) => {
  let needRerender = false;
  const renderedMessages = clone(itemsHeight);
  const checkIfRerender = (item) => {
    if (item && item.fresh) {
      // eslint-disable-next-line no-param-reassign
      item.fresh = false;
      needRerender = true;
      return item;
    }
    return item;
  };
  const items = mapObjIndexed(checkIfRerender, renderedMessages);
  if (needRerender) {
    mergeItemsHeight(items);
    const resetIndex = start <= 0 ? 0 : start;
    if (!scrollToIndex) {
      // TODO should be with condition for rerender. Because sometimes fires ecxess rerender.
      if (isUpdateOffsetImmediately) {
        reset(resetIndex);
      } else {
        const scrollRef = getRef('scroll');
        const scrollHeight = scrollRef.getScrollHeight() - height;
        const scrollTop = scrollRef.getScrollTop();
        const prevScrollDistancePercent = scrollTop * 100 / scrollHeight;
        setIsUpdateOffsetImmediately(true);
        reset(resetIndex);
        rerender(reset, resetIndex, prevScrollDistancePercent, getRef, height);
      }
    }
    if (scrollToIndex) {
      setIsAbleToGetMoreMessage(true);
      reset(resetIndex);
      setScrollToIndex(scrollToIndex);
    }
    if (lastScrollOffset && loadingMoreMessage) {
      const scrollRef = getRef('scroll');
      getRef('list').props.sizeAndPositionManager.getUpdatedOffsetForIndex(
        scrollRef.getScrollTop(),
        messagesCount,
      );
      requestAnimationFrame(() => {
        if (scrollRef) {
          const scrollHeight = scrollRef.getScrollHeight();
          const offset = subtract(scrollHeight)(lastScrollOffset);
          scrollRef.scrollTop(offset);
          setLoadingMoreMessage(false);
          setLastScrollOffset(null);
        }
      });
    }
  }
};

const onRenderContentHandler = ({ members, bootData }) => (content,
  onHandler, messageId, options) => convertToHtml(
  bootData.id,
  members,
  onHandler,
  messageId,
  options,
)(content);

const onResizeMessageHandler = ({
  onSetOffset,
}) => (index, width, height) => {
  onSetOffset({ index, fresh: true, offset: height });
};

const getHeightMessengersTextAreaHandler = ({ updateHeightMessengersArea, getRef }) => (val) => {
  const scrollRef = getRef('scroll');
  const goScrollToBottom = () => {
    if (scrollRef && scrollRef.getValues().scrollTop + scrollRef.getValues().clientHeight - scrollRef.getScrollHeight() > -50) {
      scrollRef.scrollToBottom();
    }
  };
  updateHeightMessengersArea(`calc(100% - ${val}px)`);
  goScrollToBottom();
};
const updateHeightMessengersAreaHandler = () => val => ({
  heightMessengersArea: val,
});


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    channelId: PropTypes.number,
  }),
  withRefs(),
  branch(
    ({
      isChannelLoaded, activeChannel, height, messages,
    }) => {
      const isChannelReady = isNil(activeChannel) || !isChannelLoaded
        || !isChannelLoaded.loading || !height;
      if (isEmpty(messages) || isNil(messages)) {
        return true;
      }
      if (isChannelReady) {
        return true;
      }
      if (isChannelLoaded.loading) {
        return false;
      }
      return false;
    },
    renderNothing,
  ),
  withState('isAbleToGetMoreMessage', 'setIsAbleToGetMoreMessage', false),
  withState('isScrollBottom', 'setIsScrollBottom', true),
  withState('lastScrollOffset', 'setLastScrollOffset', null),
  withState('isItemsDimensionUpdated', 'setIsItemsDimensionUpdated', null),
  withStateHandlers(() => ({
    loadingMoreMessage: false,
    itemsHeight: {},
    isUpdateOffsetImmediately: true,
    selectedMessage: null,
    heightMessengersArea: 0,
  }), {
    setLoadingMoreMessage: setLoadingMoreMessageStateHandler,
    setItemsHeight: setItemsHeightStateHandler,
    setNewItemsHeight: setNewItemsHeightStateHandler,
    mergeItemsHeight: mergeItemsHeightStateHandler,
    setIsUpdateOffsetImmediately: setIsUpdateOffsetImmediatelyStateHandler,
    updateHeightMessengersArea: updateHeightMessengersAreaHandler,
  }),
  withHandlers({
    onSetOffset: onSetOffsetHandler,
    getHeightMessengersTextArea: getHeightMessengersTextAreaHandler,
  }),
  withHandlers({
    onRenderContent: onRenderContentHandler,
    onResizeMessage: onResizeMessageHandler,
  }),
  withHandlers({
    onScroll: onScrollHandler(null),
    onGetItemSize: onGetItemSizeHandler,
    onRenderItem: onRenderItemHandler,
    onItemsRendered: onItemsRenderedHandler,
  }),
  withLifecycle({
    onGetSnapshotBeforeUpdate(prevProps, props) {
      const { messagesCount, loadingMoreMessage, isItemsDimensionUpdated } = props;
      if (messagesCount !== prevProps.messagesCount && loadingMoreMessage) {
        const scrollRef = props.getRef('scroll');
        return scrollRef.getScrollHeight() - scrollRef.getScrollTop();
      }
      if (isItemsDimensionUpdated
        && prevProps.isItemsDimensionUpdated !== isItemsDimensionUpdated) {
        const scrollRef = props.getRef('scroll');
        const scrollHeight = scrollRef.getScrollHeight();
        return ({
          isUpdateDimensions: true,
          scrollPercent: Math.ceil(scrollRef.getScrollTop() * 100 / scrollHeight),
        });
      }

      return null;
    },
    onDidUpdate(prevProps, props, snapshot) {
      const {
        messagesCount: prevMessagesCount,
      } = prevProps;
      const {
        setScrollToIndex, mergeItemsHeight, setLastScrollOffset,
        messagesCount, itemsHeight, isScrollToBottom, setIsScrollToBottom,
        containerWidth, setIsUpdateOffsetImmediately,
      } = props;

      if (containerWidth !== prevProps.containerWidth) {
        setIsUpdateOffsetImmediately(false);
      }

      if (snapshot) {
        cond([
          [T, () => {
            const items = {};
            const differenceMessagesCount = messagesCount - prevMessagesCount;
            forEachObjIndexed(increaseMessageIndex(items, differenceMessagesCount), itemsHeight);
            mergeItemsHeight(items);
            setLastScrollOffset(snapshot);
          }],
        ])(snapshot);
      }
      if (isScrollToBottom) {
        setScrollToIndex(dec(messagesCount));
        setIsScrollToBottom(false);
      }

      // const isHasUnreadCount = prevProps.unreadCount !== unreadCount && unreadCount > 0;
      //
      // // TODO for current user when message was submitted must be false
      // if (isHasUnreadCount && !isScrollToBottom) {
      //   setScrollToIndex(dec(messagesCount));
      // }
    },
  }),
  lifecycle({
    shouldComponentUpdate(prevProps) {
      return prevProps.isScrollBottom === this.props.isScrollBottom;
    },
    componentDidUpdate(prevProps) {
      const { messagesCount, isScrollBottom, getRef } = this.props;

      if (messagesCount !== prevProps.messagesCount && isScrollBottom) {
        requestAnimationFrame(() => {
          if (getRef('scroll')) {
            getRef('scroll').scrollToBottom();
          }
        });
      }
    },
  }),
  hoistStatics,
);

export default enhance(MessageHistory);
