import React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonsCollection,
  Avatar,
  BrillMask,
} from '../../../../../../components';
import './style.sass';

const Project = ({
  children, toggleTab, name, tabStatus, image, spentTime, estimationTime, sortByProject,
}) => (
  <>
    <div
      key={new Date().getTime() * Math.random()}
      className={`my-tasks-table__sprint
             ${!tabStatus ? 'my-tasks-table__sprint--open' : ''} ${sortByProject === 'tasks' ? 'my-tasks-table__sprint--open' : ''}`}
    >
      {
        sortByProject === 'projects' ? (
          <div className="my-tasks-table__about-sprint">
            <ButtonsCollection.ButtonIcons
              className="my-tasks-table__sprint-toggle-button"
              title={`${!tabStatus ? 'Show less' : 'Show more'}`}
              onClick={() => toggleTab()}
            >
              <span className="icon-expand-arrow-dn" />
            </ButtonsCollection.ButtonIcons>
            <BrillMask>
              <Avatar src={image} />
            </BrillMask>
            <h2 className="my-tasks-table__name-sprint">{ name }</h2>
            <div className="sprint-info">
              <div className="sprint-info__field">
                <div className="sprint-info__field-label">
                  Spent:
                </div>
                <div className="sprint-info__field-value">{ spentTime }</div>
              </div>
              <div className="sprint-info__field">
                <div className="sprint-info__field-label">
                  Estimation:
                </div>
                <div className="sprint-info__field-value">{ estimationTime }</div>
              </div>
            </div>
          </div>
        ) : (
          null
        )
      }
      <div className="my-tasks-table__sprint-body">
        <div className={`my-tasks-table__row ${sortByProject === 'tasks' ? 'sort-by-tasks' : 'sort-by-projects'} my-tasks-table__row-controls`}>
          <div className="my-tasks-table__col--id">
            <div className="filter-button my-tasks-table__col--id" title="Sort by ID">#</div>
          </div>
          <div className="my-tasks-table__col--subject">
            <div className="filter-button" title="Sort by Subject">Subject</div>
          </div>
          <div className="my-tasks-table__col--priority">
            <div className="filter-button" title="Sort by Priority">Priority</div>
          </div>
          <div className="my-tasks-table__col--category">
            <div className="filter-button" title="Sort by Category">Category</div>
          </div>
          <div className="my-tasks-table__col--author">
            <div className="filter-button" title="Sort by Author">Author</div>
          </div>
          {sortByProject === 'tasks' ? (
            <div className="my-tasks-table__col--project">
              <div className="filter-button" title="Sort by Project">Project</div>
            </div>
          ) : (
            <div className="my-tasks-table__col--assignee">
              <div className="filter-button" title="Sort by Assignee">Assignee</div>
            </div>
          )}
          <div className="my-tasks-table__col--estimation">
            <div className="filter-button" title="Sort by Estimation time">Estimated</div>
          </div>
          <div className="my-tasks-table__col--spent">
            <div className="filter-button" title="Sort by Spent time">Spent</div>
          </div>
          <div className="my-tasks-table__col--deadline">
            <div className="filter-button" title="Sort by Deadline">Deadline</div>
          </div>
          <div className="my-tasks-table__col--progress">
            <div className="filter-button" title="Sort by Done">Done %</div>
          </div>
          <div />
        </div>
        {children}
      </div>
    </div>
  </>
);

Project.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  sortByProject: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string,
  toggleTab: PropTypes.func,
  tabStatus: PropTypes.bool,
  image: PropTypes.string,
  spentTime: PropTypes.string,
  estimationTime: PropTypes.string,
};

Project.defaultProps = {
  tabStatus: false,
  toggleTab: () => {},
  name: '',
  image: '',
  spentTime: '',
  estimationTime: '',
};

export default Project;
