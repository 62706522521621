import { createActions } from 'redux-actions';
import { identity, pathOr } from 'ramda';

import types from './types';
import { statisticsSchemas } from './index';

export const {
  getMyStatisticsRequest,
  setUserStatistic,
} = createActions(
  {
    [types.GET_MY_STATISTICS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: params.user_id ? '/stats' : '/stats/activity',
        selectorName: params.user_id ? 'getUserStatisticRequest' : 'getMyStatisticsRequest',
        method: 'GET',
        params,
        schema: {
          rules: {
            activityPerDay: statisticsSchemas.activityPerDaySchema,
            activityPerMonth: statisticsSchemas.activityPerMonthSchema,
          },
          pathData: [],
        },
        actions: {
          success: action => setUserStatistic({
            ...action,
            meta: { userId: params.user_id || meta.userId },
          }),
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
  },
  types.SET_USER_STATISTIC,
);
