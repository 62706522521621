import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Label } from '../../../../../../components';
import { messenger } from '../../../../../../constants';

const ChannelItem = ({
  channelId, groupChannels, isOnline, count,
}) => (
  <li className="treeview">
    <NavLink
      to={`/messenger/${channelId}/`}
      activeClassName="chanel-nav-group__item--active"
      onClick={e => !!((!isOnline && count(channelId) === null)) && e.preventDefault()}
      title={!isOnline && count(channelId) === null ? 'This channel is not available in offline' : ''}
      className={`chanel-nav-group__item
      ${groupChannels[channelId].unread_count ? 'chanel-nav-group__item--new' : null}`}
    >
      {
        groupChannels[channelId].type === messenger.PRIVATE_CHANNEL ? (
          <span className="chanel-nav-group__icon icon-chanel-lock-icon" />
        ) : (
          <span className="chanel-nav-group__icon icon-chanel-icon" />
        )
      }
      <span className="text--cut">{groupChannels[channelId].name}</span>
      {groupChannels[channelId].unread_count ? (
        <Label
          className="label--xs label--fill"
        >
          {groupChannels[channelId].unread_count}
        </Label>
      ) : null}
    </NavLink>
  </li>
);

ChannelItem.propTypes = {
  channelId: PropTypes.number.isRequired,
  groupChannels: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  isOnline: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
};

export default ChannelItem;
