import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';

import {
  Layouts, PermissionElement,
} from '../../components';
import { CreateTaskModal, EditTaskModal, DeleteTaskModal } from '../../containers/taskCRUD';

import {
  LeftSideBar, Header, Tasks, Task, AgendaBoard, ProjectsNotes,
} from '../../containers';
import { ProjectsNavigation } from './components';
import './style.sass';
import {
  NewSprintModal, StartSprintModal, DeleteSprintModal, EditSprintModal, CompleteSprintModal,
} from './components/sprintCRUD';
import Members from './components/members';
import { ACTIONS, MODULES } from '../../constants/pemissions';

const Project = ({
  onOpenCreateTaskModal, isTabNotesActive, onEditNotesModal, match: { params: { id } }, project,
  selectedMeta, selectedTaskId,
}) => (
  <Layouts.Main className="page-project">
    <LeftSideBar>
      <PermissionElement module={MODULES.PROJECTS} action={ACTIONS.UPDATE}>
        <ul className="sidebar-menu">
          <li className="header"><strong>MEMBERS</strong></li>
          <Members projectId={id} />
        </ul>
      </PermissionElement>
    </LeftSideBar>
    <div className="main-container page-project__main-container">
      <Header isInvertBg pendingTitle={project.isPending ? 'This is project was created offline' : ''}>
        <div className="row d-flex align-items-end justify-content-end">
          <ProjectsNavigation />
          {
              isTabNotesActive ? (

                <PermissionElement module={MODULES.PROJECTS_DOCUMENTS} action={ACTIONS.CREATE}>
                  <button type="button" className="btn btn-primary btn-plus" onClick={onEditNotesModal}>
                    <span>Add new documents</span>
                    <i className="icon-plus-square" />
                  </button>
                </PermissionElement>
              ) : (
                <PermissionElement module={MODULES.TASKS} action={ACTIONS.CREATE}>
                  <button type="button" className="btn btn-primary btn-plus" onClick={onOpenCreateTaskModal}>
                    <span>Add new task</span>
                    <i className="icon-plus-square" />
                  </button>
                </PermissionElement>
              )
            }
        </div>
      </Header>
      <div className="page page-task has-sidebar-left height-full">
        <Switch>
          <Route exact path="/projects/:projectId/agenda-board" component={AgendaBoard} />
          <Route exact path="/projects/:id/task/:taskId" component={Task} />
          <Route exact path="/projects/:id/notes" component={ProjectsNotes} />
          <Route exact component={Tasks} />
        </Switch>
      </div>
    </div>
    <CreateTaskModal projectId={id} />
    <EditTaskModal selectedMeta={selectedMeta} selectedTaskId={selectedTaskId} />
    <DeleteTaskModal selectedTaskId={selectedTaskId} />
    <NewSprintModal projectId={id} />
    <CompleteSprintModal projectId={id} />
    <DeleteSprintModal projectId={id} />
    <EditSprintModal projectId={id} />
    <StartSprintModal projectId={id} />
  </Layouts.Main>
);

Project.propTypes = {
  project: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedTaskId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  selectedMeta: PropTypes.instanceOf(Object).isRequired,
  onOpenCreateTaskModal: PropTypes.func.isRequired,
  isTabNotesActive: PropTypes.bool.isRequired,
  onEditNotesModal: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

export default Project;
