import * as yup from 'yup';
import { isValidLogTime } from '../../utils/helpers/dateHelpers';

const rules = yup.object().shape({
  title: yup
    .string()
    .required({
      message: 'Title required',
    }),
  estimated_time: yup
    .string()
    .test('estimated_time', 'Log time not valid',
      isValidLogTime),
});

export default rules;
