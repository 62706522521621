import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import { Filters, CardsActivityContainer } from './components';
import { ScrollWrapper } from '../../components';

const StatisticsContainer = ({
  view, changeViewStateHandler,
  selectedUserId,
  user,
  windowWidth,
}) => (
  <div className="statistics-container">
    <ScrollWrapper>
      <Filters filterBy={changeViewStateHandler} activeView={view} />
      <CardsActivityContainer windowWidth={windowWidth} userId={selectedUserId.value || user.id} />
    </ScrollWrapper>
  </div>
);

StatisticsContainer.propTypes = {
  changeViewStateHandler: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  selectedUserId: PropTypes.number.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default StatisticsContainer;
