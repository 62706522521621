import {
  branch,
  compose, renderNothing,
} from 'recompose';
import { connect } from 'react-redux';

import { withFormik } from 'formik';
import { propOr } from 'ramda';
import moment from 'moment';
import EditEvent from './editEvent';
import { uiSelectors, uiActions } from '../../../../state/ui';
import { eventsActions, eventsSelectors } from '../../../../state/events';
import rules from '../rules';

const mapStateToProps = (state, { selectedEventId }) => ({
  isOpen: uiSelectors.getModal(state)('editEventModal'),
  event: eventsSelectors.getEvent(state)(selectedEventId),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('editEventModal'),
  onEditEvent: eventsActions.updateEventRequest,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ event }) => !event.id, renderNothing),
  withFormik({
    mapPropsToValues: ({ event = {} }) => ({
      ...event,
      start_date: new Date(event.start_date),
      end_date: new Date(event.end_date),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: rules,
    handleSubmit: (formValues,
      {
        props: {
          onEditEvent,
          onCloseModal,
          selectedEventId,
        },
      }) => {
      onEditEvent(({
        ...formValues,
        start_date: moment(new Date(propOr('', 'start_date', formValues))).add(6, 'h').format(),
        end_date: moment(new Date(propOr('', 'end_date', formValues))).add(6, 'h').format(),
        eventId: selectedEventId,
      }), { callbacks: { success: onCloseModal } });
    },
  }),
);

export default enhance(EditEvent);
