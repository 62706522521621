import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Layouts, ButtonsCollection, DropDown, CreateTaskModal,
} from '../../components';
import {
  LeftSideBar, Header,
  MyTasksWindow,
} from '../../containers';
import { WINDOW_WIDTH } from '../../constants/ui';
import { RightSideBarContainer } from './components';
import './style.sass';

const MyTasks = ({
  match, openModal, windowWidth, t,
}) => (
  <Layouts.Main className="page-my-tasks">
    <LeftSideBar />
    <div className="main-container page-my-tasks__main-container">
      <Header>
        <h1 className="weight--medium title--xl title-page">
          {t('My tasks')}
        </h1>
        <>
          {
            windowWidth > WINDOW_WIDTH.MEDIUM ? (
              <ButtonsCollection.ButtonBrill
                onClick={() => openModal('createTaskModal')}
                className="button--sm header__control-button--project"
              >
                {t('New task')}
              </ButtonsCollection.ButtonBrill>
            ) : (
              <ButtonsCollection.ButtonPlus
                onClick={() => openModal('createTaskModal')}
                className="header__control-button--task"
              />
            )
          }
          <DropDown
            className="agenda-task-control"
            label={(
              <ButtonsCollection.ButtonIcons
                title={t('Control buttons for project')}
                className="header-col__project-burger"
              >
                <span className="icon-burger-dots-gorizont" />
              </ButtonsCollection.ButtonIcons>
            )}
            list={
              [
                {
                  label: (
                    <>
                      <span className="agenda-task-control__icon icon-pencil-2" />
                      <span className="agenda-task-control__text">{t('Edit')}</span>
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      <span className="agenda-task-control__icon icon-pin" />
                      <span className="agenda-task-control__text">{t('Pin')}</span>
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      <span className="agenda-task-control__icon icon-delete-icon" />
                      <span className="agenda-task-control__text">{t('Delete')}</span>
                    </>
                  ),
                },
              ]
            }
          />
        </>
      </Header>
      <div className="main-container__content page-my-tasks__content">
        <>
          <Switch>
            <Route exact path={`${match.url}`} component={MyTasksWindow} />
          </Switch>
        </>
        <RightSideBarContainer windowWidth={windowWidth} />
      </div>
    </div>
    <CreateTaskModal />
  </Layouts.Main>
);

MyTasks.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  openModal: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default MyTasks;
