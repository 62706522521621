import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { openModal } from '../../../../state/ui/actions';
import DirectChannels from './directChannels';
import { messengerSelectors } from '../../../../state/messenger';

const mapStateToProps = state => ({
  channelsList: messengerSelectors.getDirectChannelsList(state),
});

const mapDispatchToProps = ({
  openModal,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
);

export default enhance(DirectChannels);
