import { path } from 'ramda';
import { API } from '../../constants/config';

const getErrors = errors => path(['response', 'data', 'result', 'errors'], errors);

const getImageUrl = id => (id ? `${API}/files/${id}` : '');

const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
};

export {
  getErrors,
  getImageUrl,
  getQueryVariable,
};
