import React from 'react';
import PropTypes from 'prop-types';
import { prop, propOr, path } from 'ramda';
import moment from 'moment';

import {
  FormsCollection,
  ModalWindow,
  Button,
} from '../../../../components';
import './style.sass';
import { TYPE } from '../../../../constants/projects';
import AsyncAutoComplete
  from '../../../../components/autoCompleteField/asyncAutoComplete/asyncCompleteField';

const CreateProjectModal = ({
  isOpen,
  onCloseModal,
  handleSubmit,
  handleChange,
  name,
  setFieldValue,
  handleBlur,
  touched,
  values,
  errors,
  getValidFinishDate,
  t,
  modalTitle,
  getUsersAutocomplete,
  getCustomers,
  onChangeStartDate,
  isOnline,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={() => onCloseModal(name)}
    className="create-project__modal"
    modalName={modalTitle}
    title={modalTitle}
  >
    <div id="step-1y" className="card-body p-1">
      <FormsCollection.FormWrapper onSubmit={handleSubmit}>
        <div className="form-row">
          <FormsCollection.Input
            label="Name"
            className="col-md-12"
            id="project__name"
            name="title"
            placeholder="Project name"
            error={path(['title', 'message'], errors)}
            value={values.title}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <FormsCollection.AttachImage
            onChange={handleChange}
            value={values.image}
            name="image"
            disabled={!isOnline}
            title={!isOnline ? 'Only in online version' : ''}
            id="attached-logo__project"
            labelText="Add logo"
          />
        </div>
        <div className="form-row">
          <div className="new-sprint__date-fields-wrapper new-sprint__date-wrapper--start flex-row">
            <div className="date-field-label">
              <label htmlFor="date-picker-start__new-sprint-modal">Start</label>
            </div>
            <div className="new-sprint__inputs-wrapper">
              <FormsCollection.DatePicker
                className="date-picker"
                name="startDate"
                onChange={onChangeStartDate}
                onBlur={handleBlur}
                value={values.startDate}
                touched
                error={prop('message', errors.startDate)}
                id="date-picker-start__new-sprint-modal"
              />
              <FormsCollection.TimePicker
                className="time-picker"
                name="startTime"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!values.startDate}
                touched
                value={values.startTime}
              />
            </div>
          </div>
          <div className="new-sprint__date-fields-wrapper new-sprint__date-wrapper--finish flex-row">
            <div className="date-field-label">
              <label htmlFor="date-picker-finish__new-sprint-modal">Finish</label>
            </div>
            <div className="new-sprint__inputs-wrapper">
              <FormsCollection.DatePicker
                className="date-picker"
                name="finishDate"
                onChange={handleChange}
                onBlur={handleBlur}
                touched
                error={prop('message', errors.finishDate)}
                value={getValidFinishDate()}
                minDate={moment(values.startDate).add(1, 'day').toDate()}
                id="date-picker-finish__new-sprint-modal"
              />
              <FormsCollection.TimePicker
                className="time-picker"
                name="finishTime"
                disabled={!values.finishDate}
                onChange={handleChange}
                onBlur={handleBlur}
                touched
                value={values.finishTime}
              />
            </div>
          </div>
        </div>
        <div
          className="form-row"
          title={!isOnline ? 'Only in online version' : ''}
        >
          <label className="col-form-label">
            {t('Customer')}
          </label>
          <AsyncAutoComplete
            name="customer"
            placeholder={t('Choose customer')}
            error={propOr('', 'message', errors.customer)}
            closeMenuOnSelect
            touched={touched.customer}
            value={values.customer}
            defaultValue={values.customer}
            disabled={!isOnline}
            loadOptions={getCustomers}
            onChange={value => setFieldValue('customer', value)}
          />
        </div>
        <div className="form-row">
          <FormsCollection.TextArea
            label="Description"
            className="col-md-12 no-resize"
            id="edit-user__email"
            name="description"
            value={values.description}
            onChange={handleChange}
            placeholder="Description"
          />
        </div>
        <div className="form-row">
          <div className="create-project__row create-project__row--privacy">
            <div className="create-project__col-label">
              {t('Privacy')}
            </div>
            <div className="create-project__col-fields">
              <FormsCollection.Radio
                onChange={handleChange}
                onBlur={handleBlur}
                checked={parseInt(values.type, 10) === TYPE.PUBLIC}
                label="Public"
                name="type"
                value={TYPE.PUBLIC}
                id="create-project-modal__privacy--public"
              />
              <FormsCollection.Radio
                onChange={handleChange}
                onBlur={handleBlur}
                checked={parseInt(values.type, 10) === TYPE.PRIVATE}
                label="Private"
                name="type"
                value={TYPE.PRIVATE}
                id="create-project-modal__privacy--private"
              />
            </div>
          </div>
          {
            Number(values.type) === TYPE.PRIVATE && (
              <div className="create-project__row create-project__row--members">
                <div className="create-project__col-label">
                  Members
                </div>
                <div
                  className="create-project__col-fields"
                  title={!isOnline ? 'Only in online version' : ''}
                >
                  <AsyncAutoComplete
                    isMulti
                    name="members"
                    disabled={!isOnline}
                    loadOptions={getUsersAutocomplete}
                    placeholder="Choose members..."
                    closeMenuOnSelect={false}
                    value={values.members}
                    placeholderJump="Members"
                    touched={touched.members}
                    error={propOr('', 'message', errors.members)}
                    onChange={value => setFieldValue('members', value)}
                  />
                </div>
              </div>
            )
          }
        </div>
        <Button type="submit" className="btn btn-primary">Save Changes</Button>
      </FormsCollection.FormWrapper>
    </div>
  </ModalWindow>
);

CreateProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  getUsersAutocomplete: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  t: PropTypes.func.isRequired,
};

export default CreateProjectModal;
