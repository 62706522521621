import React from 'react';
import PropTypes from 'prop-types';

const ItalicText = ({ content }) => (<i>{content}</i>);

ItalicText.propTypes = {
  content: PropTypes.oneOfType([PropTypes.instanceOf(Array)]).isRequired,
};

export default ItalicText;
