import React from 'react';
import ModalView from '../modalView';

const AddEvent = ({
  ...props
}) => (
  <ModalView title="Add event" titleAction="Save" {...props} />
);

export default AddEvent;
