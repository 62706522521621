/* eslint-disable import/prefer-default-export */
const PRIORITY = {
  LOW: {
    color: '#2196f3',
    value: 0,
    title: 'Low',
  },
  MEDIUM: {
    color: '#4caf50',
    value: 1,
    title: 'Medium',
  },
  HIGH: {
    color: '#ffc107',
    value: 2,
    title: 'High',
  },
  VERY_HIGH: {
    color: '#f44336',
    value: 3,
    title: 'Urgent',
  },
};

const STATUS = {
  IN_PROGRESS: {
    id: 1,
    title: 'To Do',
  },
  IN_REVIEW: {
    id: 2,
    title: 'In Progress',
  },
  DONE: {
    id: 3,
    title: 'Done',
  },
};

export {
  PRIORITY,
  STATUS,
};
