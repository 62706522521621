import {
  compose, getContext, lifecycle, withHandlers, withStateHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LeftSideBar from './leftSideBar';
import withRefs from '../../utils/enchancers/withRefs';
import { uiActions, uiSelectors } from '../../state/ui';
import { withWindowWidth } from '../../utils/enchancers';
import * as messengerSelectors from '../../state/messenger/selectors';

const onToggleUserControlsStateHandlers = ({ isUserControls }) => () => ({
  isUserControls: !isUserControls,
});

const mapDispatchToProps = {
  setLeftSideBar: uiActions.changeLeftSidebarStatus,
};

const mapStateToProps = state => ({
  isLeftSideBar: uiSelectors.getLeftSidebar(state),
  totalUnreadCount: messengerSelectors.getTotalUnreadCount(state),
});

const onClickOutsideHandler = ({
  getRef, setLeftSideBar, isLeftSideBar, windowWidth,
}) => (e) => {
  if ((windowWidth < 1032 && !getRef('leftSideBarElement').contains(e.target)) && isLeftSideBar) {
    e.stopPropagation();
    e.preventDefault();
    setLeftSideBar(false);
  }
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWindowWidth(),
  withRefs(),
  getContext({
    setLeftSideBarRef: PropTypes.func,
  }),
  withHandlers({
    onClickOutside: onClickOutsideHandler,
  }),
  withStateHandlers(() => ({ isUserControls: false }), {
    onToggleUserControls: onToggleUserControlsStateHandlers,
  }),
  lifecycle({
    componentDidMount() {
      document.addEventListener('click', this.props.onClickOutside);
    },
    componentWillUnmount() {
      document.removeEventListener('click', this.props.onClickOutside);
    },
  }),
);
export default enhance(LeftSideBar);
