import React from 'react';
import PropTypes from 'prop-types';

import { ModalWindow, ButtonsCollection } from '../../../../components';
import './style.sass';

const DeleteRepositoryModal = ({ isOpen, closeModal, id }) => (
  <ModalWindow
    isOpen={isOpen(`deleteRepositoryModal${id}`)}
    onClose={() => closeModal(`deleteRepositoryModal${id}`)}
    modalName="deleteRepository"
    title="Delete repository"
    className="modal-window__delete-repositories"
  >
    <>
      <div className="row  text-align--left">
        <span className="delete-message__title title-md">
        Are you sure you want to delete this repository?
        </span>
      </div>
      <div className="delete-message__button-row">
        <ButtonsCollection.ButtonBrill
          type="button"
          className="button--md delete-repositories__button-control button-brill--fill"
        >
          Delete
        </ButtonsCollection.ButtonBrill>
        <button
          type="button"
          onClick={() => closeModal(`deleteRepositoryModal${id}`)}
          className="button--cancel"
        >
          Cancel
        </button>
      </div>
    </>
  </ModalWindow>
);

DeleteRepositoryModal.propTypes = {
  isOpen: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default DeleteRepositoryModal;
