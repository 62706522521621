const STATUS = {
  TO_DO: 0,
  IN_PROGRESS: 1,
};

const SPRINT_STATUS = {
  NOT_ACTIVE: 0,
  ACTIVE: 1,
  COMPLETED: 2,
};

export {
  SPRINT_STATUS,
};

export default STATUS;
