import React from 'react';
import AsyncSelect from 'react-select/lib/Async';
import PropTypes from 'prop-types';

import * as components from '../components';
import '../style.sass';
import { toTitle } from '../../../utils/helpers/commonHelpers';

const customStyles = {
  option: () => {},
};

const AsyncAutoComplete = ({
  error, onBlur, disabled, touched, onFocus, name, t, loadOptions, onInputChange, ...props
}) => (
  <div className={`auto-complete ${error && touched ? 'auto-complete--has-error' : ''}`}>
    <AsyncSelect
      cacheOptions
      styles={customStyles}
      components={components}
      className="auto-complete__field"
      classNamePrefix="auto-complete"
      loadOptions={loadOptions}
      {...props}
      onInputChange={onInputChange}
      isDisabled={disabled}
      defaultOptions
    />
    {error.length !== 0 && touched && (
      <span className="auto-complete__error field__error-text">
        {t(error, { key: toTitle(name) })}
      </span>
    )}
  </div>
);

AsyncAutoComplete.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  onBlur: PropTypes.func,
  t: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  touched: PropTypes.bool,
};

AsyncAutoComplete.defaultProps = {
  error: '',
  onFocus: () => {},
  onBlur: () => {},
  touched: false,
  disabled: false,
};

export default AsyncAutoComplete;
