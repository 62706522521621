import {
  curry, keys, map, propOr, reduce, sum, trim,
} from 'ramda';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';

const timeList = {
  w: 144000,
  d: 28800,
  h: 3600,
  m: 60,
};

const getSecondsFromTimeString = (value) => {
  if (!value) return '';
  if ((/^(\d){1,}$/).test(value)) return value * 60;
  const arrayTimes = value.match(/\d*[A-Z,a-z]/gm) || [];
  return sum(map((item) => {
    const count = item.match(/\d*/);
    const char = item.match(/[A-Z, a-z]{1}/);
    return count * propOr(0, char, timeList);
  }, arrayTimes));
};

const isValidLogTime = (value) => {
  if (!value) return true;
  const checkNumber = /^(\d){1,}$/;
  const checkLogTimeString = /^((^\d{1,}w)|^|$)(((^|\s)\d{1,}d)|^|$)(((^|\s)\d{1,}h)|^|$)(((^|\s)\d{1,}m)|^|$)$/;
  return (checkNumber).test(value) || (checkLogTimeString).test(value);
};

const getTimeStringBySeconds = (seconds) => {
  if (!seconds) {
    return '0m';
  }
  const results = reduce((accum, key) => {
    const { secondsCount, result } = accum;
    if (!secondsCount || secondsCount < 0) return accum;
    const c = Math.floor(secondsCount / timeList[key]) || 0;
    return {
      secondsCount: secondsCount - c * timeList[key],
      result: c ? `${result}${c}${key} ` : result,
    };
  }, {
    secondsCount: seconds,
    result: '',
  }, keys(timeList));
  return trim(results.result);
};


const convertToUNIX = date => moment(date).unix();
const convertTsWithTimeZone = curry((tz, format, ts) => momentTimeZone(ts).tz(tz).format(format));
const getMessageTimeInMs = () => Date.now() + window.performance.now().toString();
const getDate = () => moment().format();

export {
  convertToUNIX,
  getDate,
  getMessageTimeInMs,
  getSecondsFromTimeString,
  convertTsWithTimeZone,
  isValidLogTime,
  getTimeStringBySeconds,
};
