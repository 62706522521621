import React from 'react';

import {
  Layouts,
} from '../../components';
import {
  LeftSideBar,
  Header,
  StatisticsContainer,
} from '../../containers';
import './style.sass';

const Statistics = () => (
  <Layouts.Main rightSideBarStatus={false}>
    <LeftSideBar />
    <div className="main-container page-statistics__main-container">
      <Header />
      <div className="main-container__content page-statistics__activity-content page height-full has-sidebar-left">
        <StatisticsContainer />
      </div>
    </div>
  </Layouts.Main>
);

export default Statistics;
