import React from 'react';
import { prop, propOr } from 'ramda';
import PropTypes from 'prop-types';
import { BRILL_NAME } from '../constants';
import { Avatar, BrillMask, OnlineStatusLabel } from '../../../../../components';

const CoWorkerItem = ({
  id, user, customRef, userName, userAvatar, onOpenProfile,
}) => (
  <li
    ref={e => customRef(`${BRILL_NAME}${id}`, e)}
    className="co-workers__item"
  >
    <BrillMask
      asLink
      onClick={onOpenProfile}
      outsideChildren={
        propOr(false, 'is_online', user) ? (
          <OnlineStatusLabel
            className="co-workers__online-label"
            status={prop('is_online', user)}
          />
        ) : <></>
      }
    >
      <Avatar
        src={userAvatar}
        classNameImg="co-workers__img"
        alt={userName}
      />
    </BrillMask>
  </li>
);
CoWorkerItem.propTypes = {
  id: PropTypes.number.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  customRef: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  onOpenProfile: PropTypes.func.isRequired,
  userAvatar: PropTypes.string.isRequired,
};

export default CoWorkerItem;
