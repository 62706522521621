import {
  curry, filter, uniq,
} from 'ramda';
import { select, put } from 'redux-saga/effects';

import { getAgenda, getAgendaTasksList } from '../../../state/project/selectors';
import { reorderAgendaTasks } from '../../../state/project/actions';
import { updateTaskRequest } from '../../../state/tasks/actions';
import { makePayload } from './utils';
import { move } from '../commonHelpers';
import { projectSelectors } from '../../../state/project';

const addStatusParam = makePayload('status');

const updateBoard = curry(function* (status, action, projectId) {
  const selectedTasks = yield select(state => getAgenda(state)(projectId, status));
  const updatedTasks = action(selectedTasks);
  return addStatusParam(status, updatedTasks);
});

const dragTaskBetweenBoard = function* (data) {
  const {
    source, destination, itemId, projectId,
  } = data;

  if (source.droppableId === destination.droppableId) {
    const itemsInSource = yield updateBoard(
      source.droppableId,
      move(source.index, destination.index),
    );
    yield put(reorderAgendaTasks({ ...itemsInSource, projectId }));
  } else {
    const task = yield select(state => projectSelectors.getTask(state)(itemId, projectId));
    const itemsInSource = yield select(state => getAgendaTasksList(state)(projectId,
      source.droppableId));
    const itemsInSource2 = yield select(state => getAgendaTasksList(state)(projectId,
      destination.droppableId));

    const prevTasksList = yield filter(item => item !== task.id, itemsInSource);
    const nextTasksList = yield uniq([task.id, ...itemsInSource2]);

    yield put(reorderAgendaTasks({
      tasks: nextTasksList,
      status: destination.droppableId,
      projectId,
    }));

    yield put(reorderAgendaTasks({ tasks: prevTasksList, status: source.droppableId, projectId }));
    yield put(updateTaskRequest({ ...task, status: destination.droppableId }, { isUpdate: true }));
  }
};

export default dragTaskBetweenBoard;
