export default {
  GET_CUSTOMERS_REQUEST: 'GET_CUSTOMERS_REQUEST',
  ADD_CUSTOMER_REQUEST: 'ADD_CUSTOMER_REQUEST',
  DELETE_CUSTOMER_REQUEST: 'DELETE_CUSTOMER_REQUEST',
  UPDATE_CUSTOMER_REQUEST: 'UPDATE_CUSTOMER_REQUEST',
  EXPORT_CUSTOMERS_REQUEST: 'EXPORT_CUSTOMERS_REQUEST',
  IMPORT_CUSTOMERS_REQUEST: 'IMPORT_CUSTOMERS_REQUEST',
  GET_CUSTOMER_REQUEST: 'GET_CUSTOMER_REQUEST',
  GET_CUSTOMERS_LIST_AUTOCOMPLETE_REQUEST: 'GET_CUSTOMERS_LIST_AUTOCOMPLETE_REQUEST',

  SET_CUSTOMERS: 'SET_CUSTOMERS',
  SET_CUSTOMER: 'SET_CUSTOMER',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  SET_CUSTOMERS_LIST_AUTOCOMPLETE: 'SET_CUSTOMERS_LIST_AUTOCOMPLETE',
};
