import React from 'react';

import './style.sass';

const PermissionElement = ({ children }) => (
  <>
    {children}
  </>
);
export default PermissionElement;
