import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { ButtonsCollection } from '../../../../components';
import './style.sass';

const MainNav = ({ onCreateProjectModal, lastChannelId }) => (
  <nav className="main-nav">
    <NavLink to="/projects/" activeClassName="main-nav__item--active" className="main-nav__item">
      <span className="icon-box-icon-2 main-nav__icon" />
      <span className="hide-element--flex main-nav__text">Projects</span>
      <ButtonsCollection.ButtonPlus onClick={onCreateProjectModal} title="Create project" className="hide-element--flex" />
    </NavLink>
    <NavLink to="/tasks/" exact activeClassName="main-nav__item--active" className="main-nav__item">
      <span className="icon-ok-icon-2 main-nav__icon" />
      <span className="hide-element--flex main-nav__text">Tasks</span>
    </NavLink>
    <NavLink to={`/messenger/${lastChannelId}`} activeClassName="main-nav__item--active" className="main-nav__item">
      <span className="icon-talk-cloud-icon main-nav__icon" />
      <span className="hide-element--flex main-nav__text">Chats</span>
    </NavLink>
    <NavLink to="/activity/" activeClassName="main-nav__item--active" className="main-nav__item">
      <span className="icon-timer-2-icon main-nav__icon" />
      <span className="hide-element--flex main-nav__text">Activity</span>
    </NavLink>
    <NavLink to="/people/" activeClassName="main-nav__item--active" className="main-nav__item">
      <span className="icon-user-icon-nav2 main-nav__icon">
        <span className="path1" />
        <span className="path2" />
      </span>
      <span className="hide-element--flex main-nav__text">People</span>
    </NavLink>
  </nav>
);
MainNav.propTypes = {
  onCreateProjectModal: PropTypes.func.isRequired,
  lastChannelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
export default MainNav;
