import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { ButtonsCollection, PendingControl } from '../../../../../components';
import Avatar from '../../../../../components/avatar/avatar';

const TimeEntryItem = ({
  userName,
  estimatedTime,
  dateLog, isNewDateFlag, createdAt, userAvatar, onEditTimeEntry, onDeleteTimeEntry,
  item, onResetOfflineEdit,
  onResetOfflineDelete, onResetOfflineCreate, user,
}) => (
  <>
    {
      isNewDateFlag && (
        <div className="time-entry__history-title">{dateLog}</div>)
    }
    <li className="time-entry__history-item">
      <PendingControl
        onResetOfflineDelete={onResetOfflineDelete}
        onResetOfflineEdit={onResetOfflineEdit}
        onResetOfflineCreate={onResetOfflineCreate}
        isPending={item.isPending}
        isUpdating={item.isUpdating}
        isDeleting={item.isDeleting}
      />
      <Avatar src={userAvatar} alt={userName} />
      <div className="time-entry__history-time">{estimatedTime}</div>
      <div className="time-entry__history-date-log">{createdAt}</div>
      {
        user.id === item.user_id && (
          <>
            <ButtonsCollection.ButtonIcons
              className="time-entry__item-button"
              onClick={onEditTimeEntry}
              title="Edit log"
            >
              <span className="icon-pencil" />
            </ButtonsCollection.ButtonIcons>
            <ButtonsCollection.ButtonIcons
              className="time-entry__item-button time-entry__item-button-delete"
              onClick={onDeleteTimeEntry}
              title="Delete log"
            >
              <span className="icon-trash" />
            </ButtonsCollection.ButtonIcons>
          </>
        )
      }
    </li>
  </>
);

TimeEntryItem.propTypes = {
  userName: PropTypes.string.isRequired,
  userAvatar: PropTypes.string.isRequired,
  estimatedTime: PropTypes.func.isRequired,
  onEditTimeEntry: PropTypes.func.isRequired,
  onDeleteTimeEntry: PropTypes.func.isRequired,
  dateLog: PropTypes.string.isRequired,
  isNewDateFlag: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  onResetOfflineEdit: PropTypes.func.isRequired,
  onResetOfflineDelete: PropTypes.func.isRequired,
  onResetOfflineCreate: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};
export default TimeEntryItem;
