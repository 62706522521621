import React from 'react';
import PropTypes from 'prop-types';

import VirtualList from '../../../../utils/draftVirtualScroll/index';

const MessageHistory = ({
  messagesCount,
  setRef,
  onScroll,
  onGetItemSize,
  scrollToIndex,
  height,
  onItemsRendered,
  setScrollToIndex,
  onRenderItem,
}) => (
  <>
    <VirtualList
      height={height}
      scrollRef={scrollRef => setRef('scroll', scrollRef)}
      listRef={scrollRef => setRef('list', scrollRef)}
      initialOffset={0}
      onScrollRequest={onScroll}
      itemCount={messagesCount}
      setScrollToIndex={setScrollToIndex}
      overCount={20}
      defaultItemHeight={98}
      scrollToIndex={scrollToIndex}
      itemSize={onGetItemSize}
      onItemsRendered={onItemsRendered}
      renderItem={onRenderItem}
    />
  </>
);

MessageHistory.propTypes = {
  height: PropTypes.number.isRequired,
  messagesCount: PropTypes.number.isRequired,
  scrollToIndex: PropTypes.number.isRequired,
  selectedMessage: PropTypes.number.isRequired,
  onGetItemSize: PropTypes.func.isRequired,
  onRenderItem: PropTypes.func.isRequired,
  onItemsRendered: PropTypes.func.isRequired,
  setScrollToIndex: PropTypes.func.isRequired,
  onDeleteMessage: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,

};

export default MessageHistory;
