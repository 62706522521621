import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '..';
import './style.sass';

const Favorite = ({
  status, onClick, title, className, t,
}) => (
  <ButtonsCollection.ButtonIcons
    className={`favorite-button ${className} ${status ? 'favorite-button--active' : ''}`}
    onClick={onClick}
    title={`${status ? t(`Remove ${title} from favorite`) : t(`Add ${title} to favorite`)}`}
  >
    {
      status ? (
        <span className="icon-star-empty-project--active" />
      ) : (
        <span className="icon-star-empty-project" />
      )
    }
  </ButtonsCollection.ButtonIcons>
);
Favorite.propTypes = {
  status: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  t: PropTypes.func.isRequired,
};
Favorite.defaultProps = {
  status: false,
  className: '',
  onClick: () => {},
  title: '',
};
export default Favorite;
