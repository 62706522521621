import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection, DropDown } from '../../../../components';
import './style.sass';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import { formatSprintTime } from '../../../../utils/helpers/projectHelpers/utils';
import PermissionElement from '../../../../components/permissionElement';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';
import { formatDate } from '../../../../utils/helpers/commonHelpers';

const SprintTitle = ({ title }) => (
  <h2 className="agenda-header__title">
    { title }
  </h2>
);

SprintTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

const SprintInfo = ({
  isShowMore, description, startTime, endTime,
}) => (
  <div className={`agenda-header__row agenda-header__show-more ${isShowMore ? 'active' : ''}`}>
    <div className="agenda-header__date">
      { startTime && `${formatDate(startTime)} - ${formatDate(endTime)} ` }
    </div>
    <div className="agenda-header__helper">
      {description}
    </div>
  </div>
);

SprintInfo.propTypes = {
  isShowMore: PropTypes.bool.isRequired,
  description: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

SprintInfo.defaultProps = {
  description: null,
  endTime: null,
  startTime: null,
};

const SprintEstimation = ({ estimatedTime, spentTime }) => (
  <div className="agenda-header__row">
    <div className="agenda-header__log-time">
      <div className="agenda-header__log-item">
        Spent:
        <b>{estimatedTime}</b>
      </div>
      <div className="agenda-header__log-item">
        Estimation:
        <b>{spentTime}</b>
      </div>
    </div>
  </div>
);


const SelectedSprint = ({
  sprint, onEdit, onDelete, P, t,
}) => (
  <div className="agenda-header__title-group">
    <SprintTitle title={sprint.title} />
    <PermissionElement module={MODULES.TASKS} action={[ACTIONS.CREATE, ACTIONS.DELETE]}>
      <DropDown
        className="controls-group-task"
        name="controls-group-task"
        label={(
          <ButtonsCollection.ButtonIcons
            className="project-task__controls-button-task"
            title="Controls for sprint"
          >
            <div className="s-24 icon-more_vert" />
          </ButtonsCollection.ButtonIcons>
        )}
        list={
          [
            ...(P(MODULES.TASKS, ACTIONS.CREATE) ? ([{
              id: 0,
              onClick: onEdit,
              label: (
                <>
                  <span className="controls-group-task__icon icon-pencil-2" />
                  <span className="controls-group-task__text">{t('Edit')}</span>
                </>
              ),
            }]) : []),
            ...(P(MODULES.TASKS, ACTIONS.DELETE) ? ([{
              id: 2,
              onClick: onDelete,
              label: (
                <>
                  <span className="controls-group-task__icon icon-delete-icon" />
                  <span className="controls-group-task__text">{t('Delete')}</span>
                </>
              ),
            }]) : []),
          ]
        }
      />
    </PermissionElement>
  </div>
);

SprintEstimation.propTypes = {
  estimatedTime: PropTypes.string.isRequired,
  spentTime: PropTypes.string.isRequired,
};

SelectedSprint.propTypes = {
  sprint: PropTypes.instanceOf(Object),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

SelectedSprint.defaultProps = {
  sprint: null,
};

const BoardHeader = ({
  windowWidth,
  estimatedTime,
  spentTime,
  setIsShowMore, isShowMore, sprint, sprintId, onComplete, onEdit, onDelete, P, t,
}) => (
  <>
    <div className="agenda-header">
      <div className="agenda-header__col--left">
        <div className="agenda-header__row">
          <div className="agenda-header__title-group">
            { sprintId ? (
              <SelectedSprint
                onEdit={onEdit}
                onDelete={onDelete}
                sprint={sprint}
                P={P}
                t={t}
              />
            ) : <SprintTitle title="All sprints" /> }
          </div>
        </div>
        { sprintId && (
          <SprintInfo
            startTime={sprint.start_time}
            endTime={sprint.end_time}
            isShowMore={isShowMore}
            description={sprint.description}
          />
        )
        }
      </div>
      { sprintId && (
        <PermissionElement module={MODULES.TASKS} action={[ACTIONS.DELETE]}>
          <div className="agenda-header__col--right">
            <SprintEstimation
              estimatedTime={estimatedTime}
              spentTime={spentTime}
            />
            <div>
              <button
                onClick={onComplete}
                type="button"
                className="btn btn-primary w-150px"
              >
                Complete
              </button>
            </div>
          </div>
        </PermissionElement>
      )}
    </div>
    {
      windowWidth < WINDOW_WIDTH.SMALL && (
        <div className={`agenda-header__show-more-button ${isShowMore ? 'active' : ''}`}>
          <ButtonsCollection.ButtonIcons
            type="button"
            title="Show more"
            onClick={() => setIsShowMore(!isShowMore)}
          >
            <span className="icon-down" />
          </ButtonsCollection.ButtonIcons>
        </div>
      )
    }
  </>
);

BoardHeader.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  setIsShowMore: PropTypes.func.isRequired,
  sprint: PropTypes.instanceOf(Object),
  sprintId: PropTypes.number,
  isShowMore: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  estimatedTime: PropTypes.string.isRequired,
  spentTime: PropTypes.string.isRequired,
};

BoardHeader.defaultProps = {
  sprint: null,
  sprintId: null,
};

export default BoardHeader;
