import { compose, getContext } from 'recompose';
import PropTypes from 'prop-types';

import { withNamespaces } from 'react-i18next';
import MemberItem from './memberItem';

const enhance = compose(
  withNamespaces(['chat', 'common']),
  getContext(
    { setUserProfileId: PropTypes.func },
  ),
);

export default enhance(MemberItem);
