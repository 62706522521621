import { createActions } from 'redux-actions';
import { identity, pathOr } from 'ramda';

import types from './types';
import { materialsListSchema } from './schema';
import { addMaterialResponseSchema, deleteMaterialResponseSchema, editMaterialResponseSchema } from './responseSchema';

export const {
  addMaterialRequest,
  getMaterialsRequest,
  deleteMaterialRequest,
  updateMaterialRequest,
  setMaterials,
  deleteMaterial,
  reorderMaterials,
  setMaterial,
  updateMaterial,
} = createActions(
  {
    [types.ADD_MATERIAL_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/materials',
        selectorName: 'addMaterialRequest',
        method: 'POST',
        params,
        responseSchema: addMaterialResponseSchema,
        actions: {
          offlineSuccess: setMaterial,
          success: setMaterial,
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.DELETE_MATERIAL_REQUEST]: [
      identity,
      ({ materialId, ...params }, meta) => ({
        async: true,
        route: `/materials/${materialId}`,
        selectorName: 'deleteMaterialRequest',
        method: 'DELETE',
        params,
        responseSchema: data => deleteMaterialResponseSchema({ ...data, id: materialId }),
        actions: {
          offlineSuccess: setMaterial,
          success: deleteMaterial,
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.GET_MATERIALS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/materials',
        selectorName: 'getMaterialsRequest',
        method: 'GET',
        params,
        actions: {
          success: setMaterials,
        },
        schema: {
          rules: materialsListSchema,
          pathData: ['data'],
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.UPDATE_MATERIAL_REQUEST]: [
      identity,
      ({ materialId, ...params }, meta) => ({
        async: true,
        route: `/materials/${materialId}`,
        selectorName: 'updateMaterialRequest',
        method: 'PUT',
        params,
        responseStatePath: pathOr([], ['materials', 'entities', materialId]),
        responseSchema: data => editMaterialResponseSchema({ ...data, id: materialId }),
        actions: {
          offlineSuccess: updateMaterial,
          success: updateMaterial,
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
  },
  types.SET_MATERIALS,
  types.DELETE_MATERIAL,
  types.REORDER_MATERIALS,
  types.SET_MATERIAL,
  types.UPDATE_MATERIAL,
);
