import {
  compose,
  cond, curry, identity, isNil, path, T,
} from 'ramda';
import { Maybe } from 'ramda-fantasy';
import { isNodeHasSibling } from '../uiComponentHelpers/DOMhelpers';

const fireActionIfSiblingNotNil = curry((action, node) => cond([
  [isNil, identity],
  [T, sibling => action(sibling)],
])(node));

const concatWithSiblingTextOrReturnInitial = curry((
  concatMethod,
  getSiblingTextContent,
  text,
) => Maybe.maybe(
  text,
  compose(concatMethod(text), getSiblingTextContent),
));

const changedSiblingIfExist = curry((action, siblingPath) => compose(
  fireActionIfSiblingNotNil(action),
  path(siblingPath),
));

const concatTextWithSibling = curry((nodeType, pathToText, condition, initialText) => compose(
  concatWithSiblingTextOrReturnInitial(
    condition,
    path(pathToText),
    initialText,
  ),
  isNodeHasSibling(nodeType),
));

export {
  concatWithSiblingTextOrReturnInitial,
  changedSiblingIfExist,
  concatTextWithSibling,
  fireActionIfSiblingNotNil,
};
