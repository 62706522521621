import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const SearchGroup = ({
  placeholder, value, onChange, onSearch,
}) => (
  <div className="search-group">
    <input type="search" onChange={onChange} className="search-group__place-write" placeholder={placeholder} value={value} />
    <button type="button" className="search-group__button" onClick={onSearch}>
      <span className="icon-search" />
    </button>
  </div>
);

SearchGroup.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,

};
SearchGroup.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => {},
  onSearch: () => {},
};
export default SearchGroup;
