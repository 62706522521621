/* eslint-disable max-len */
import {
  compose, hoistStatics, withHandlers, withProps,
} from 'recompose';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ProjectNavigation from './projectNavigation';
import { projectSelectors } from '../../../../state/project';
import { hasActiveSprint } from '../../../../utils/helpers/projectHelpers/sprints';

const taskUrlReg = /(^\/projects\/\d*\/task\/\d*\/$)|(^\/projects\/\d*\/$)/;

const onSetIsActiveTaskLinkHandler = ({ history }) => () => taskUrlReg.test(history.location.pathname);

const mapStateToProps = (state, { match: { params: { id } } }) => ({
  sprints: projectSelectors.getProjectSprintsTasks(state)(id),
});

const enhancer = compose(
  withRouter,
  connect(mapStateToProps),
  withNamespaces(['common']),
  withHandlers({
    onSetIsActiveTaskLink: onSetIsActiveTaskLinkHandler,
  }),
  withProps(({ sprints }) => ({
    isHasActiveSprints: !hasActiveSprint(sprints),
  })),
);

export default hoistStatics(enhancer)(ProjectNavigation);
