import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const PriorityTask = ({ status, className, t }) => {
  switch (status) {
    case 0:
      return <span className={`priority-task__new ${className}`}>{t('Very Low')}</span>;
    case 1:
      return <span className={`priority-task__new ${className}`}>{t('Low')}</span>;
    case 2:
      return <span className={`priority-task__solved ${className}`}>{t('Middle')}</span>;
    case 3:
      return <span className={`priority-task__feedback ${className}`}>{t('High')}</span>;
    case 4:
      return <span className={`priority-task__closed ${className}`}>{t('Very High')}</span>;
    default:
      return <span className={`priority-task__new ${className}`}>{t('Very Low')}</span>;
  }
};

PriorityTask.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  status: PropTypes.number,
};

PriorityTask.defaultProps = {
  className: '',
  status: 0,
};

export default PriorityTask;
