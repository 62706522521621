import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { UserItem } from './components';
import { Pagination, ScrollWrapper } from '../../components';

const UsersContainer = ({
  usersList, usersCount, itemsOnPage, currentPage, isPrev, isNext,
}) => (
  <ScrollWrapper heightIs="100%" className="scroll-page">
    <div className="container-fluid">
      <div className="tab-content my-3" id="v-pills-tabContent">
        <div
          className="tab-pane animated  show active"
          id="v-pills-all"
          role="tabpanel"
          aria-labelledby="v-pills-all-tab"
        >
          <div className="form-row">
            <div className="col-md-12">
              <div className="card r-0 shadow">
                <div className="table-responsive">
                  <form>
                    <table className="table table-striped table-hover r-0">
                      <thead>
                        <tr className="no-b">
                          <th style={{ width: '30px' }}>
                          #ID
                          </th>
                          <th>USER NAME</th>
                          <th>POSITION</th>
                          <th>PHONE</th>
                          <th>ROLE</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {
                        usersList.map(userId => (
                          <UserItem userId={userId} />
                        ))
                    }
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            count={usersCount}
            limit={itemsOnPage}
            isNext={isNext}
            isPrev={isPrev}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  </ScrollWrapper>
);

UsersContainer.propTypes = {
  usersList: PropTypes.instanceOf(Array).isRequired,
  usersCount: PropTypes.number.isRequired,
  itemsOnPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  isPrev: PropTypes.bool.isRequired,
  isNext: PropTypes.bool.isRequired,
};

export default UsersContainer;
