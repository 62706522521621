import {
  compose, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { keys, map } from 'ramda';
import moment from 'moment';

import { stringToColor } from '../../../../components/avatar/utils';
import CardsActivityContainer from './cardsActivityContainer';
import { statisticsSelectors } from '../../../../state/statistics';
import { getTimeStringBySeconds } from '../../../../utils/helpers/dateHelpers';

const mapStateToProps = (state, { userId }) => ({
  myStatisticsDaysEntities: statisticsSelectors.myActivityPerDayEntities(state)(userId),
  myStatisticsDaysResult: statisticsSelectors.myActivityPerDayResult(state)(userId),
  myStatisticsTotal: statisticsSelectors.myActivityTotal(state)(userId),
});


const enhance = compose(
  connect(mapStateToProps),
  withProps(({ myStatisticsTotal: { projects, totalEstimated, totalSpend } }) => ({
    myStatisticsByProjects: {
      fields: { title: 'Project', spent: 'Spent' },
      items: map(itemId => ({ id: projects[itemId].id, title: projects[itemId].name, spent: `${getTimeStringBySeconds((projects[itemId].totalSpend || 0) * 3600)}` }), keys(projects)),
    },
    myStatisticsByProjectsPie: {
      labels: map(itemId => projects[itemId].title, keys(projects)),
      backgroundColor: map(itemId => stringToColor(projects[itemId].name), keys(projects)),
      datasets: [
        {
          data: map(itemId => projects[itemId].totalSpend || 0, keys(projects)),
          backgroundColor: map(itemId => stringToColor(projects[itemId].name), keys(projects)),
        },
      ],
    },
    myStatisticTotal: {
      fields: {
        title: 'Month',
        estimate: 'Total estimate',
        spent: 'Total spent',
      },
      items: [
        {
          id: 'total-time',
          title: moment().format('MMMM'),
          estimate: getTimeStringBySeconds((totalEstimated || 0) * 3600),
          spent: getTimeStringBySeconds((totalSpend || 0) * 3600),
        },
      ],
    },
  })),
);
export default enhance(CardsActivityContainer);
