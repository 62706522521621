import {
  compose, lifecycle, withHandlers, withState, withStateHandlers,
} from 'recompose';
import { remove, reduce } from 'ramda';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { closeModal, uploadTempFileRequest } from '../../../../state/ui/actions';
import { getModal, getTempFileUploadPending } from '../../../../state/ui/selectors';
import AttachFilesModal from './attachFilesModal';
import { taskActions, taskSelectors } from '../../../../state/task';

const mapStateToProps = state => ({
  isOpen: getModal(state)('attachFilesTaskModal'),
  isPending: getTempFileUploadPending(state),
  task: taskSelectors.getActiveTask(state),
});

const mapDispatchToProps = ({
  onCloseModal: () => closeModal('attachFilesTaskModal'),
  uploadTempFile: uploadTempFileRequest,
  updateTask: taskActions.updateActiveTaskRequest,
});

const onChangeHandler = ({ setFilesList }) => (e) => {
  const filesList = Array.from(e.currentTarget.files);
  setFilesList(filesList);
};

const onUploadFilesHandler = ({
  filesList, uploadTempFile, setLoadingFiles, loadingFiles,
}) => () => {
  filesList.map((file) => {
    const formData = new FormData();
    formData.append('file', file);
    return uploadTempFile(formData, {
      callbacks: {
        success: ({ model }) => {
          setLoadingFiles(loadingFiles.concat(model));
        },
      },
    });
  });
  // const previewData = URL.createObjectURL(e.currentTarget.files[0]);
  // const formData = new FormData();
  // formData.append('file', e.currentTarget.files[0]);
};

const setLoadingFilesStateHandler = ({ loadingFiles }) => item => ({ loadingFiles: loadingFiles.concat(item) });

const onDeleteFileStateHandler = ({ loadingFiles }) => index => ({ loadingFiles: remove(index, 1, loadingFiles) });

const onSetTaskFilesHandler = ({
  loadingFiles, onCloseModal, setFilesList, onResetLoadingFiles, match: { params: { taskId } }, updateTask, task,
}) => (e) => {
  e.preventDefault();
  const filesList = reduce((accum, { id }) => accum.concat(id), [], loadingFiles);
  updateTask({
    ...task,
    taskId,
    files: filesList,
  }, { callbacks: { success: () => { onCloseModal(); setFilesList([]); onResetLoadingFiles([]); } } });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withState('filesList', 'setFilesList', []),
  withStateHandlers(() => ({ loadingFiles: [] }), {
    onResetLoadingFiles: () => () => ({ loadingFiles: [] }),
    setLoadingFiles: setLoadingFilesStateHandler,
    onDeleteFile: onDeleteFileStateHandler,
  }),
  withHandlers({
    onChange: onChangeHandler,
    onUploadFiles: onUploadFilesHandler,
    onSetTaskFiles: onSetTaskFilesHandler,
  }),
  lifecycle({
    componentDidUpdate({ filesList }) {
      if (this.props.filesList !== filesList) {
        this.props.onUploadFiles();
      }
    },
  }),
);

export default enhance(AttachFilesModal);
