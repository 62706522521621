import React from 'react';
import PropTypes from 'prop-types';

import ChannelsItem from '../directsItem';
import './style.sass';
import { Pagination } from '../../../../components';

const DirectsList = ({
  title, entities, result, t, itemsOnPage, isNext, isPrev, currentPage, count,
}) => (
  <>
    <div className="directs-list">
      <h2 className="directs-list__title">{title}</h2>
      <ul className="directs-list__list">
        {
        result.length
          ? result.map(channelId => (
            <ChannelsItem
              channel={entities[channelId]}
              key={entities[channelId].id}
            />
          ))
          : (
            <li className="directs-list__empty">
              {t('There are no directs yet')}
            </li>
          )
      }
      </ul>
    </div>
    <Pagination
      count={count}
      limit={itemsOnPage}
      isNext={isNext}
      isPrev={isPrev}
      currentPage={currentPage}
    />
  </>
);

DirectsList.propTypes = {
  title: PropTypes.string.isRequired,
  entities: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  result: PropTypes.arrayOf(PropTypes.number).isRequired,
  itemsOnPage: PropTypes.number.isRequired,
  isNext: PropTypes.bool.isRequired,
  isPrev: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export default DirectsList;
