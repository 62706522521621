import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const MessageButtons = ({ onClickSaveContent }) => (
  <div className="input-group-btn ml-2">
    <button
      type="submit"
      onMouseDown={e => e.preventDefault()}
      className="btn btn-primary message-textArea__submit"
      onClick={onClickSaveContent}
    >
      Send
    </button>
  </div>
);

MessageButtons.propTypes = {
  onClickSaveContent: PropTypes.func.isRequired,
  onClickDiscardButton: PropTypes.func.isRequired,
  discardButton: PropTypes.bool.isRequired,
};

export default MessageButtons;
