import React from 'react';
import PropTypes from 'prop-types';

import { TabWrapper } from '../../../../components';

import './style.sass';

const NavGroup = ({
  title, className, children,
}) => (
  <div className={`nav-group ${className}`}>
    <nav className="nav-group__nav">
      <TabWrapper title={title}>
        {children}
      </TabWrapper>
    </nav>
  </div>
);

NavGroup.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
};

NavGroup.defaultProps = {
  className: '',
  children: '',
};
export default NavGroup;
