import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './style.sass';

const joinUrl = (url, goTo) => (url.slice(-1) === '/' ? `${url}${goTo}/` : `${url}/${goTo}/`);

const ProjectNavigation = ({
  match, t, onSetIsActiveTaskLink, isHasActiveSprints,
}) => (
  <>
    <NavLink className="project-nav__go-to-projects" to="/projects/">
      <span className="icon-expand-arrow-left" title="Back to project" />
    </NavLink>
    <nav className="header__project-nav project-nav">
      <NavLink exact isActive={onSetIsActiveTaskLink} to={`${match.url}`} activeClassName="project-nav__link--active" className="project-nav__link">{t('Tasks')}</NavLink>
      {
        isHasActiveSprints ? (
          <NavLink exact to={joinUrl(match.url, 'agenda-board')} activeClassName="project-nav__link--active" className="project-nav__link">{t('Agenda board')}</NavLink>
        ) : (
          <span
            title="There are no active sprints"
            className="project-nav__link project-nav__link--disabled"
          >
            {t('Agenda board')}
          </span>
        )
      }
      <NavLink exact to={joinUrl(match.url, 'notes')} activeClassName="project-nav__link--active" className="project-nav__link">{t('Documents')}</NavLink>
    </nav>
  </>
);

ProjectNavigation.propTypes = {
  title: PropTypes.string,
  onSetIsActiveTaskLink: PropTypes.func.isRequired,
  isHasActiveSprints: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

ProjectNavigation.defaultProps = {
  title: '',
};

export default ProjectNavigation;
