import {
  compose, pure, withContext, withProps, defaultProps, branch, renderNothing,
} from 'recompose';
import {
  identity, ifElse, always, equals, propOr,
} from 'ramda';
import PropTypes from 'prop-types';
import MessageElements from './messageElements';
import { parseStringWrappers, parseTextElements } from './utils';
import { setGlobalVariableForMention } from '../../utils/helpers/mentionHelpers/lookup';

const enhance = compose(
  branch(({ children }) => !children, renderNothing),
  pure,
  withProps(({
    children, messageId, options = {}, ownerId, onHandler, members,
  }) => compose(
    ifElse(equals(true),
      always({ content: parseTextElements(ownerId, onHandler, members, children) }),
      always({ content: parseStringWrappers(children, messageId) })), propOr(false, 'isEditing'),
  )(options)),
  defaultProps({
    members: [],
    ownerId: 0,
    onHandler: identity,
  }),
  withContext({
    members: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.instanceOf(Array)]),
    ownerId: PropTypes.number,
    onHandler: PropTypes.func,
  }, props => ({
    members: setGlobalVariableForMention(props.members),
    ownerId: props.ownerId,
    onHandler: props.onHandler,
  })),
);

export default enhance(MessageElements);
