import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection, PermissionElement } from '../../../../../../components';
import './style.sass';
import { ACTIONS, MODULES } from '../../../../../../constants/pemissions';

const NotesItem = ({
  isOpen,
  toggleIsOpen,
  noteId,
  note,
  onEdit,
  onDelete,
}) => (
  <div
    className={`notes-list__table-row ${isOpen ? 'notes-list__table-row--active' : ''}`}
  >
    <div className="notes-list__table-col"><h3 className="text--cut">{note.title}</h3></div>
    <div
      className="notes-list__table-col notes-list__table-col--description"
      dangerouslySetInnerHTML={{ __html: `<span>${note.content}</span>` }}
    />
    <div className="notes-list__table-col">
      <div className="notes-list__controls-group">
        <ButtonsCollection.ButtonIcons
          className="notes-list__show-more-button"
          title={`${isOpen ? 'Hide information' : 'Show information'}`}
          onClick={() => toggleIsOpen(noteId)}
        >
          <div className="s-18 icon-angle-down" />
        </ButtonsCollection.ButtonIcons>

        <PermissionElement module={MODULES.PROJECTS_DOCUMENTS} action={ACTIONS.UPDATE}>
          <ButtonsCollection.ButtonIcons
            className="notes-list__edit-button"
            title="Edit note"
            onClick={onEdit}
          >
            <span className="icon-pencil" />
          </ButtonsCollection.ButtonIcons>
        </PermissionElement>

        <PermissionElement module={MODULES.PROJECTS_DOCUMENTS} action={ACTIONS.DELETE}>
          <ButtonsCollection.ButtonIcons
            className="notes-list__delete-button"
            onClick={onDelete}
            title="Delete note"
          >
            <span className="icon-trash" />
          </ButtonsCollection.ButtonIcons>
        </PermissionElement>
      </div>
    </div>
  </div>
);
NotesItem.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleIsOpen: PropTypes.func.isRequired,
  noteId: PropTypes.number.isRequired,
  note: PropTypes.instanceOf(Object).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
export default NotesItem;
