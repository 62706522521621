import {
  branch,
  compose, renderNothing,
} from 'recompose';
import { connect } from 'react-redux';

import { withFormik } from 'formik';
import AddMaterial from './addMaterial';
import { uiSelectors, uiActions } from '../../../../state/ui';
import rules from '../rules';
import { materialsActions } from '../../../../state/materials';

const mapStateToProps = state => ({
  isOpen: uiSelectors.getModal(state)('addMaterial'),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('addMaterial'),
  onAddMaterial: materialsActions.addMaterialRequest,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ isOpen }) => !isOpen, renderNothing),
  withFormik({
    mapPropsToValues: () => ({}),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: rules,
    handleSubmit: (formValues,
      {
        props: {
          onAddMaterial,
          onCloseModal,
        },
      }) => {
      onAddMaterial(formValues, { callbacks: { success: onCloseModal } });
    },
  }),
);

export default enhance(AddMaterial);
