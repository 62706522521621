const TITLES = {
  SITE_NAME: 'Crew',
  PAGE_USERS: 'Users',
  PAGE_CUSTOMERS: 'Customers',
  PAGE_PROFILE: 'Profile',
  PAGE_PROJECTS: 'Projects',
  PAGE_MESSENGER: 'Messenger',
  PAGE_404: 'Page not found',
  PAGE_SIGN_IN: 'Sign In',
  PAGE_SIGN_UP: 'Sign up',
  PAGE_FORGOT_PASSWORD: 'Forgot password',
  PAGE_RESET_PASSWORD: 'Reset password',
  EVENTS: 'Calendar',
  PAGE_MATERIALS: 'Materials',
  PAGE_STATISTICS: 'Statistics',
  AGENDA: 'Agenda board',
  GROUP_CHANNELS: 'Group channels',
  DIRECT_CHANNELS: 'Direct channels',
  DOCUMENTS: 'Documents',
};

const TITLES_CONSTANTS = {
  SITE_NAME: 'SITE_NAME',
  PAGE_USERS: 'PAGE_USERS',
  PAGE_SIGN_IN: 'PAGE_SIGN_IN',
  PAGE_SIGN_UP: 'PAGE_SIGN_UP',
  PAGE_FORGOT_PASSWORD: 'PAGE_FORGOT_PASSWORD',
  PAGE_RESET_PASSWORD: 'PAGE_RESET_PASSWORD',
  PAGE_CUSTOMERS: 'PAGE_CUSTOMERS',
  PAGE_PROFILE: 'PAGE_PROFILE',
  PAGE_PROJECTS: 'PAGE_PROJECTS',
  PAGE_MESSENGER: 'PAGE_MESSENGER',
  PAGE_404: 'PAGE_404',
  EVENTS: 'EVENTS',
  PAGE_MATERIALS: 'PAGE_MATERIALS',
  PAGE_STATISTICS: 'PAGE_STATISTICS',
  AGENDA: 'AGENDA',
  GROUP_CHANNELS: 'GROUP_CHANNELS',
  DIRECT_CHANNELS: 'DIRECT_CHANNELS',
  DOCUMENTS: 'DOCUMENTS',
};

const SUB_TITLES = {
  PAGE_MESSENGER_CHANNELS: 'All channels',
  PAGE_MESSENGER_DIRECTS: 'Direct messages',
};


export {
  TITLES,
  TITLES_CONSTANTS,
  SUB_TITLES,
};
