import { compose, pure, withHandlers } from 'recompose';
import MentionTag from './mentionTag';

const onClickHandler = ({ id, onMentionClick }) => () => onMentionClick(id);

const enhance = compose(
  pure,
  withHandlers({
    onClick: onClickHandler,
  }),
);

export default enhance(MentionTag);
