import React from 'react';
import PropTypes from 'prop-types';

import {
  ConfirmModal,
} from '../../../../components';

const DeleteUser = ({
  isOpen,
  isPending,
  onCloseModal,
  user,
  onDelete,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    onAction={onDelete}
    onClose={onCloseModal}
    title="Delete user"
  >
    <span className="text-size--sm">
      Are you sure you want to delete a user
      {' '}
      <b>{user.username}</b>
?
    </span>
  </ConfirmModal>
);

DeleteUser.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteUser;
