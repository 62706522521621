import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const FieldsName = ({ fields }) => (
  <div className="total-time-list-fields-name">
    <div className="total-time-list-fields-name__row">
      <div className="total-time-list-fields-name__title">
        {fields.title}
      </div>
      <div className="total-time-list-fields-name__estimate">
        {fields.estimate}
      </div>
      <div className="total-time-list-fields-name__spent">
        {fields.spent}
      </div>
    </div>
  </div>
);
FieldsName.propTypes = {
  fields: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default FieldsName;
