import {
  compose, lifecycle, withHandlers, getContext,
} from 'recompose';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import TimeEntryHistory from './timeEntryHistory';
import { taskActions, taskSelectors } from '../../../../../state/task';
import { preloaderWhileLoading } from '../../../../../utils/enchancers';
import { PRELOADER_DIMENSION } from '../../../../../constants/ui';
import { uiActions } from '../../../../../state/ui';
import { userSelectors } from '../../../../../state/user';

const mapDispatchToProps = {
  getTimeEntries: taskActions.getTimeEntriesRequest,
  setOpenModal: uiActions.openModal,
};

const mapStateToProps = (state, { match: { params: { taskId } } }) => ({
  timeEntriesList: taskSelectors.getTimeEntriesList(state)(taskId),
  isPending: taskSelectors.getTimeEntriesRequestPending(state),
  user: userSelectors.getUserData(state),
});

const onEditHandler = ({ setOpenModal, setSelectedTimeEntryId }) => (id) => {
  setOpenModal('editTimeEntry');
  setSelectedTimeEntryId(id);
};

const onDeleteHandler = ({ setOpenModal, setSelectedTimeEntryId }) => (id) => {
  setOpenModal('deleteTimeEntry');
  setSelectedTimeEntryId(id);
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['tasks']),
  getContext({
    setSelectedTimeEntryId: PropTypes.func.isRequired,
  }),
  withHandlers({
    onDelete: onDeleteHandler,
    onEdit: onEditHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { getTimeEntries, match: { params: { id, taskId } } } = this.props;
      getTimeEntries({ projectId: id, taskId });
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.SMALL,
    alignContainerCenter: false,
    delay: 600,
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
);
export default enhance(TimeEntryHistory);
