import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Favorite } from '../../../../../components';
import './style.sass';

const ProjectNavItem = ({ item, handlerFavorite }) => (
  <div className="nav-project__item">
    <Favorite status={item.favorite} onClick={() => handlerFavorite(item.favorite, item.id)} />
    <Link to={`projects/${item.id}`} key={item.id} className="nav-project__link">{item.title}</Link>
  </div>
);

ProjectNavItem.propTypes = {
  item: PropTypes.shape({
    favorite: PropTypes.number,
    id: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  handlerFavorite: PropTypes.func,
};

ProjectNavItem.defaultProps = {
  handlerFavorite: () => {},
};

export default ProjectNavItem;
