import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../../../../../components';

import './style.sass';

const CategoriesItem = ({
  category, onEdit, onDelete, t,
}) => (
  <Link to={`categories/${category.id}`} key={category.id} className="category-nav__item">
    {category.title}
    <ButtonsCollection.ButtonIcons
      className="category-nav__item-button"
      onClick={onEdit}
      title={`${t('Edit category')} ${category.title}`}
    >
      <span className="icon-edit-icon" />
    </ButtonsCollection.ButtonIcons>
    <ButtonsCollection.ButtonIcons
      className="category-nav__item-button category-nav__item-button-delete"
      onClick={onDelete}
      title={`${t('Delete category')} ${category.title}`}
    >
      <span className="icon-trash" />
    </ButtonsCollection.ButtonIcons>
  </Link>
);

CategoriesItem.propTypes = {
  category: PropTypes.instanceOf(Object).isRequired,
  onEdit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CategoriesItem;
