import { compose } from 'recompose';

import { withNamespaces } from 'react-i18next';
import DefaultAutoComplete from './defaultAutoComplete';

const enhance = compose(
  withNamespaces(['validation']),
);

export default enhance(DefaultAutoComplete);
