import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const ButtonText = ({ children, className, disabled }) => (
  <button disabled={disabled} type="submit" className={`button-text ${className}`}>
    {children}
  </button>
);

ButtonText.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

ButtonText.defaultProps = {
  children: 'Submit',
  disabled: false,
  className: '',
};


export default ButtonText;
