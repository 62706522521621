import { handleActions } from 'redux-actions';

import { uniq } from 'ramda';
import types from './types';
import {
  mergeDeep,
  mergeIn,
} from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_EVENTS]: mergeIn(({ payload: { data: { entities: { events }, result } } }) => ({
    entities: { ...events },
    result,
  })),
  [types.REORDER_EVENTS]: mergeIn(({ payload }) => ({
    result: [...payload],
  })),
  [types.UPDATE_EVENT]: mergeDeep(({ payload: { event } }) => ({
    entities: { [event.id]: event },
  })),
  [types.SET_EVENT]: mergeDeep(({ payload: { event } }, { result }) => ({
    entities: { [event.id]: event },
    result: uniq([...result, event.id]),
  })),
}, {
});

export default reducer;
