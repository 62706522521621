import {
  compose, withHandlers, withProps, withState,
} from 'recompose';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { keys } from 'ramda';
import AttachedFiles from './attachedFiles';
import { uiActions } from '../../../../state/ui';
import { userSelectors } from '../../../../state/user';
import { taskSelectors } from '../../../../state/task';

const mapDispatchToProps = ({
  onOpenAttachFilesModal: () => uiActions.openModal('attachFilesTaskModal'),
  setOpenModal: uiActions.openModal,
});

const mapStateToProps = state => ({
  task: taskSelectors.getActiveTask(state),
  user: userSelectors.getUserData(state),
});

const onDeleteFileModalHandler = ({ setSelectedTaskFileId, setOpenModal }) => (fileId) => {
  setOpenModal('deleteTaskFileModal');
  setSelectedTaskFileId(fileId);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('selectedFileId', 'setSelectedTaskFileId', 0),
  withNamespaces(['common']),
  withProps(({ task }) => ({
    filesResult: keys(task.files),
    filesEntities: task.files,
  })),
  withHandlers({
    onDeleteFileModal: onDeleteFileModalHandler,
  }),
);
export default enhance(AttachedFiles);
