import React from 'react';
import PropTypes from 'prop-types';

const BoldText = ({ content }) => (<b>{content}</b>);

BoldText.propTypes = {
  content: PropTypes.oneOfType([PropTypes.instanceOf(Array)]).isRequired,
};

export default BoldText;
