import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { path } from 'ramda';

import u5Image from '../../../assets/img/dummy/u5.png';
import {
  FormsCollection, Layouts,
} from '../../../components';

import './style.sass';

const SignIn = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  errors,
}) => (
  <Layouts.Auth>
    <main>
      <div id="primary" className="p-t-b-100 height-full ">
        <div className="container">
          <div className="form-row">
            <div className="col-lg-4 mx-md-auto">
              <div className="text-center">
                <img src={u5Image} alt="" />
                <h3 className="mt-2">Sign In</h3>
              </div>
              <FormsCollection.FormWrapper onSubmit={handleSubmit}>
                <FormsCollection.Input
                  className="form-group has-icon"
                  classInput="form-control form-control-lg"
                  name="username"
                  error={path(['username', 'message'], errors)}
                  value={values.username}
                  placeholder="Email Address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <i className="icon-envelope-o" />
                </FormsCollection.Input>
                <FormsCollection.Input
                  className="form-group has-icon"
                  classInput="form-control form-control-lg"
                  name="password"
                  error={path(['usernameOrPassword'], errors) || path(['password', 'message'], errors)}
                  value={values.password}
                  placeholder="Password"
                  type="password"
                  onChange={handleChange}
                >
                  <i className="icon-user-secret" />
                </FormsCollection.Input>
                <button type="submit" className="btn btn-success btn-lg btn-block">Log In</button>
                <div className="row">
                  <div className="col-lg-12">
                    <Link to="/forgot-password" className="forget-pass">
                      Forgot password?
                    </Link>
                  </div>
                </div>
              </FormsCollection.FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layouts.Auth>
);

SignIn.propTypes = {
  errors: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    username: PropTypes.bool,
    password: PropTypes.bool,
  }).isRequired,
  authNotification: PropTypes.shape({
    username: PropTypes.object,
    password: PropTypes.object,
  }),
};

SignIn.defaultProps = {
  authNotification: {},
};

export default SignIn;
