import {
  compose, lifecycle, withHandlers, pure,
} from 'recompose';
import { identity } from 'ramda';

import EmojiAutoComplete from './emojiAutoComplete';
import { withRefs } from '../../../utils/enchancers';
import { callWithPreventEvent } from '../../../utils/helpers/uiComponentHelpers/DOMhelpers';

const onSetRefEmojiAutocompleteHandler = ({ setRef }) => e => setRef('emojiAutoComplete', e);

const onPreventHandler = () => e => callWithPreventEvent(identity, e);

const enhance = compose(
  withRefs(),
  withHandlers({
    onSetRefEmojiAutocomplete: onSetRefEmojiAutocompleteHandler,
    onPrevent: onPreventHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { emojisAutocomplete, setEmojiSelected } = this.props;
      if (prevProps.emojisAutocomplete.length !== emojisAutocomplete.length) {
        setEmojiSelected(0);
      }
    },
  }),
  pure,
);

export default enhance(EmojiAutoComplete);
