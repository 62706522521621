import { createActions } from 'redux-actions';
import { identity, pathOr, prop } from 'ramda';

import types from './types';
import { projectsListSchema, importantTasksSchema } from './schema';
import { addProjectResponseSchema, editProjectResponseSchema, deleteProjectResponseSchema } from './responseSchema';
import * as projectActions from '../project/actions';

export const {
  getProjectsListRequest,
  addToFavoriteProjectsFetch,
  removeFromFavoriteProjectsFetch,
  addProjectRequest,
  editProjectRequest,
  getProjectTasksRequest,
  getProjectsListMenuRequest,
  deleteProjectRequest,
  getProjectsSummaryRequest,
  getImportantTasksProjectsRequest,
  setProjectsFavorite,
  setProjectsListMenu,
  removeProjectsFavorite,
  setProjectsList,
  addProjectSuccess,
  setProject,
  setMoreProjects,
  setProjectsSummary,
  setImportantTasksProjects,
  setNewProject,
  deleteProject,
  reorderProject,
  updateProject,
} = createActions(
  {
    [types.GET_PROJECTS_LIST_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/projects',
        selectorName: 'getProjectsList',
        method: 'GET',
        params,
        schema: {
          rules: projectsListSchema,
          pathData: ['data'],
        },
        actions: {
          success: prop('more', meta) ? setMoreProjects : setProjectsList,
          meta: prop('metaAction', meta),
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.ADD_TO_FAVORITE_PROJECTS_FETCH]: [
      identity,
      ({ id }, meta) => ({
        async: true,
        route: `/favorite-projects/${id}`,
        selectorName: 'setProjectFavorite',
        method: 'POST',
        actions: {
          success: setProjectsFavorite,
          meta: { id },
        },
        ...meta,
      }),
    ],
    [types.REMOVE_FROM_FAVORITE_PROJECTS_FETCH]: [
      identity,
      ({ id }, meta) => ({
        async: true,
        route: `/favorite-projects/${id}`,
        selectorName: 'removeProjectFavorite',
        method: 'DELETE',
        actions: {
          success: removeProjectsFavorite,
          meta: { id },
        },
        ...meta,
      }),
    ],
    [types.ADD_PROJECT_REQUEST]: [
      identity,
      (params, meta) => {
        const offlineId = new Date().getTime();
        return {
          async: true,
          route: '/projects',
          selectorName: 'addProject',
          method: 'POST',
          params: { ...params, id: offlineId },
          responseSchema: data => addProjectResponseSchema({ ...data, id: offlineId }),
          actions: {
            success: getProjectsListRequest,
            offlineSuccess: setNewProject,
            callbacks: {
              success: pathOr(['callbacks', 'success'], meta),
            },
            meta: {
              goToProject: true,
            },
          },
          ...meta,
        };
      },
    ],
    [types.EDIT_PROJECT_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/projects/${id}`,
        selectorName: 'editProjectRequest',
        method: 'PUT',
        responseStatePath: pathOr(identity, ['projects', 'entities', 'projects', id]),
        responseSchema: data => editProjectResponseSchema({ id, ...data }),
        params,
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        actions: {
          offlineSuccess: updateProject,
          success: meta.isSoloProjectUpdate ? projectActions.setProject : updateProject,
        },
        ...meta,
      }),
    ],
    [types.GET_PROJECTS_LIST_MENU_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/projects',
        selectorName: 'getProjectsListMenuRequest',
        method: 'GET',
        params,
        schema: {
          rules: projectsListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setProjectsListMenu,
          meta: prop('metaAction', meta),
        },
        ...meta,
      }),
    ],
    [types.DELETE_PROJECT_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/projects/${id}`,
        params,
        selectorName: 'deleteProjectRequest',
        method: 'DELETE',
        responseStatePath: pathOr(identity, ['projects', 'entities', 'projects', id]),
        responseSchema: data => deleteProjectResponseSchema({ id, ...data }),
        actions: {
          offlineSuccess: updateProject,
          success: () => getProjectsListRequest({ ...meta }),
        },
      }),
    ],
    [types.GET_PROJECTS_SUMMARY_REQUEST]: [
      identity,
      params => ({
        async: true,
        route: '/projects/summary',
        selectorName: 'getProjectsSummaryRequest',
        method: 'GET',
        params,
        actions: {
          success: setProjectsSummary,
        },
      }),
    ],
    [types.GET_IMPORTANT_TASKS_PROJECTS_REQUEST]: [
      identity,
      params => ({
        async: true,
        route: '/tasks/important',
        selectorName: 'getImportantTasksProjectsRequest',
        method: 'GET',
        params,
        schema: {
          rules: importantTasksSchema,
          pathData: ['data'],
        },
        actions: {
          success: setImportantTasksProjects,
        },
      }),
    ],
  },
  types.SET_PROJECTS_FAVORITE,
  types.SET_PROJECTS_LIST_MENU,
  types.REMOVE_PROJECTS_FAVORITE,
  types.SET_PROJECTS_LIST,
  types.ADD_PROJECT_SUCCESS,
  types.SET_PROJECT,
  types.SET_MORE_PROJECTS,
  types.SET_PROJECTS_SUMMARY,
  types.SET_IMPORTANT_TASKS_PROJECTS,
  types.SET_NEW_PROJECT,
  types.DELETE_PROJECT,
  types.REORDER_PROJECT,
  types.UPDATE_PROJECT,
);
