import React from 'react';

import ModalView from '../modalView';

const EditSprintModal = props => (
  <ModalView
    title="Edit sprint"
    titleAction="Update"
    {...props}
  />
);

export default EditSprintModal;
