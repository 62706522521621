import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmModal } from '../../../../../components';
import './style.sass';

const CompleteProjectModal = ({
  isOpen,
  onCloseModal,
  project,
  onComplete,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    actionTitle="Complete"
    onAction={onComplete}
    onClose={onCloseModal}
    title="Complete project"
  >
    <span className="text-size--sm">
      Are you sure you want to complete a
      {' '}
      <b>{project.title}</b>
?
    </span>
  </ConfirmModal>
);

CompleteProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  project: PropTypes.objectOf(PropTypes.object).isRequired,
  onDeleteProject: PropTypes.func.isRequired,
};

export default CompleteProjectModal;
