import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { getUser } from '../../../../../state/users/selectors';
import CoWorkerItem from './coWorkerItem';
import { getImageUrl } from '../../../../../utils/helpers/requestHelpers';
import { getFullName } from '../../../../../utils/helpers/userHelpers';
import { withUserProfile } from '../../../../../utils/enchancers';

const mapStateToProps = (state, { id }) => ({
  user: getUser(state)(id),
});

const onOpenProfileHandler = ({
  onSetUserProfile, user,
}) => () => onSetUserProfile(user.id);

const enhance = compose(
  withUserProfile,
  connect(mapStateToProps, null),
  withHandlers({
    onOpenProfile: onOpenProfileHandler,
  }),
  withProps(({ user }) => ({
    userAvatar: user.avatar ? getImageUrl(user.avatar) : '',
    userName: getFullName(user),
  })),
);
export default enhance(CoWorkerItem);
