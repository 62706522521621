import {
  compose, hoistStatics, lifecycle, withHandlers, withStateHandlers,
} from 'recompose';

import { withRefs } from '../../../../utils/enchancers';
import DatePickerComponent from './datePicker';

const setFocusStateHandler = () => val => ({ inFocus: val });
const setPicker = () => val => ({ isPicker: val });

const clickOutsideHandler = ({ getRef, isPicker }) => (e) => {
  const field = getRef('field');
  if (isPicker && !field.contains(e.target)) {
    getRef('input').setOpen(false);
  }
};

const togglePickerHandler = ({ setPickerStateHandler, getRef, isPicker }) => (val = !isPicker) => {
  const field = getRef('input');
  if (field) {
    getRef('input').setOpen(val);
  }
  setPickerStateHandler(val);
};

const enhance = compose(
  withRefs(),
  withStateHandlers(() => (
    { inFocus: false, isPicker: false }
  ), {
    setPickerStateHandler: setPicker,
    setFocusStateHandler,
  }),
  withHandlers({
    clickOutsideHandler,
    togglePickerHandler,
  }),
  lifecycle({
    componentDidMount() {
      document.addEventListener('click', this.props.clickOutsideHandler, true);
    },
    componentWillUnmount() {
      document.removeEventListener('click', this.props.clickOutsideHandler, true);
    },
  }),
);
export default hoistStatics(enhance)(DatePickerComponent);
