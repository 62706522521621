import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  Users, Project, Page404, Statistics, Customers, Profile, Projects, Messenger, Events, Materials,
} from '../../../pages';
import { preloaderWhileLoading } from '../../../utils/enchancers';
import checkIsUserValid from '../../../utils/enchancers/checkIsUserValid';
import { userActions, userSelectors } from '../../../state/user';
import { uiActions } from '../../../state/ui';
import withDocumentVisibleChange from '../../../utils/enchancers/withDocumentVisibleChange';
import { CustomerPage } from '../../../pages/customers';

const mapDispatchToProps = ({
  checkCurrentUserRequest: userActions.checkCurrentUserRequest,
  setIsFocus: uiActions.setIsFocus,
});

const masStateToProps = state => ({
  bootData: userSelectors.getUserData(state),
});


const Routes = () => (
  <Switch>
    <Route exact path="/" component={Projects} />
    <Route exact path="/projects" component={Projects} />
    <Route exact path="/statistics" component={Statistics} />
    <Route exact path="/users/" component={Users} />
    <Route path="/customers/:customerId" component={CustomerPage} />
    <Route path="/customers/" component={Customers} />
    <Route exact path="/profile/" component={Profile} />
    <Route exact path="/events/" component={Events} />
    <Route exact path="/messenger/:id?/" component={Messenger} />
    <Route exact path="/messenger/channels/" component={Messenger} />
    <Route exact path="/materials/" component={Materials} />
    <Route exact path="/messenger/directs/" component={Messenger} />
    <Route path="/projects/:id" component={Project} />
    <Route component={Page404} />
  </Switch>
);

Routes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const enhance = compose(
  withRouter,
  connect(masStateToProps, mapDispatchToProps),
  checkIsUserValid('checkCurrentUserRequest'),
  preloaderWhileLoading({
    delay: 600,
    fullScreen: true,
    isLoading: props => !props.bootData,
  }),
  withDocumentVisibleChange,
);

export default enhance(Routes);
