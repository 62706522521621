import React from 'react';
import PropTypes from 'prop-types';
import { FileItem } from './components';
import './style.sass';
import DeleteFileModal from '../deleteFileModal';


const AttachedFiles = ({
  onOpenAttachFilesModal, filesResult, filesEntities, onDeleteFileModal, selectedFileId, user,
}) => (
  <div className="task-files">
    <div className="form-row">
      <div className="col-lg-12 p-3">
        <button className="btn btn-primary" type="button" onClick={onOpenAttachFilesModal}>Upload new files</button>
      </div>
    </div>
    <div className="task-files__container">
      {/* <div className="task-files__time-line"> */}
      {/*  January 2018 */}
      {/* </div> */}
      {
        filesResult.map(fileId => (
          <FileItem
            user={user}
            onDeleteFileModal={onDeleteFileModal}
            key={`task-file${fileId}`}
            file={filesEntities[fileId]}
          />
        ))
      }
    </div>
    <DeleteFileModal fileId={selectedFileId} />
  </div>
);

AttachedFiles.propTypes = {
  onOpenAttachFilesModal: PropTypes.func.isRequired,
  filesResult: PropTypes.instanceOf(Array).isRequired,
  filesEntities: PropTypes.instanceOf(Object).isRequired,
  onDeleteFileModal: PropTypes.func.isRequired,
  selectedFileId: PropTypes.number.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

export default AttachedFiles;
