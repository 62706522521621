import { createSelector } from 'reselect';
import { identity, pathOr } from 'ramda';

const getCategoriesList = createSelector(pathOr([], ['newTask', 'result', 'categories']), identity);
const getSprintsList = createSelector(pathOr([], ['newTask', 'result', 'sprints']), identity);
const getProjectsList = createSelector(pathOr([], ['newTask', 'result', 'projects']), identity);


const getCategoriesEntities = createSelector(pathOr({}, ['newTask', 'entities', 'categories']), identity);
const getSprintsEntities = createSelector(pathOr({}, ['newTask', 'entities', 'sprints']), identity);
const getProjectsEntities = createSelector(pathOr({}, ['newTask', 'entities', 'projects']), identity);

const getSelectedProjectIdCrud = createSelector(pathOr({}, ['newTask', 'selectedProjectId']), identity);


export {
  getSprintsEntities,
  getCategoriesEntities,
  getProjectsEntities,

  getSprintsList,
  getCategoriesList,
  getProjectsList,

  getSelectedProjectIdCrud,
};
