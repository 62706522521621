import React from 'react';
import PropTypes from 'prop-types';
import { RightSideBar } from '../../../../containers';
import { ScrollWrapper } from '../../../../components';
import { ProjectsSummary } from '../../../../containers/leftSideBar/components';
import { ActiveTabTitle } from '..';
import { ImportantTask } from '../../../../containers/rightSideBar/components';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import './style.sass';

const RightSideBarContainer = ({ setActiveTabTitleStateHandler, windowWidth, activeTabTitle }) => (
  <>
    {
      windowWidth > WINDOW_WIDTH.SMALL ? (
        <RightSideBar className="page-my-tasks__right-side-bar" title="Tasks summary">
          <ScrollWrapper className="right-side-bar__scroll-wrapper">
            <ProjectsSummary />
            <ImportantTask
              activeTabTitle={activeTabTitle}
              setActiveTitle={setActiveTabTitleStateHandler}
            />
            {/* <TabWrapper title={( */}
            {/*  <h2 className="title"> */}
            {/*    Pinned tasks */}
            {/*  </h2> */}
            {/* )} */}
            {/* > */}
            {/*  <TaskList className="pinned-tasks--right-side-bar" /> */}
            {/* </TabWrapper> */}
          </ScrollWrapper>
        </RightSideBar>
      ) : (
        <RightSideBar
          className="page-my-tasks__right-side-bar"
          titleComponentRender={activeTabTitle ? (
            () => (
              <ActiveTabTitle
                onClick={() => setActiveTabTitleStateHandler()}
                title={activeTabTitle}
              />
            )
          ) : (
            () => <h3>Tasks summary</h3>
          )}
        >
          <ScrollWrapper className="right-side-bar__scroll-wrapper">
            <ProjectsSummary />
            <ImportantTask
              activeTabTitle={activeTabTitle}
              setActiveTitle={setActiveTabTitleStateHandler}
            />
            {/* <TabWrapperMobile
                  setActiveTitle={setActiveTabTitleStateHandler}
                  title="Pinned tasks"
                  active={activeTabTitle}> */}
            {/*  <TaskList className="pinned-tasks--right-side-bar" /> */}
            {/* </TabWrapperMobile> */}
          </ScrollWrapper>
        </RightSideBar>
      )
    }
  </>
);

RightSideBarContainer.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  activeTabTitle: PropTypes.string,
  setActiveTabTitleStateHandler: PropTypes.func.isRequired,
};
RightSideBarContainer.defaultProps = {
  activeTabTitle: '',
};

export default RightSideBarContainer;
