import {
  compose, withHandlers, branch, renderNothing, pure, flattenProp, withProps,
} from 'recompose';
import { pipe } from 'ramda';
import { withNamespaces } from 'react-i18next';

import Pagination from './pagination';
import { withUrlParams } from '../../utils/enchancers';

const goNextPage = ({
  offset, count, limit, onPageChange, getUrlParam, onSetUrlParam, isNext,
}) => () => {
  if (offset + limit >= count || !isNext) return;
  const page = pipe(
    getUrlParam,
    parseInt,
  )(['page'], 1);
  const nextPage = page + 1;
  const nextOffset = limit * (nextPage - 1);

  onSetUrlParam({ page: nextPage });
  if (onPageChange) onPageChange({ offset: nextOffset });
};

const goPreviousPage = ({
  offset, getUrlParam, onSetUrlParam, limit, onPageChange, isPrev,
}) => () => {
  if (offset === 0 || !isPrev) return;
  const page = pipe(
    getUrlParam,
    parseInt,
  )(['page'], 1);
  const previousPage = page - 1 || 1;
  const previousOffset = limit * (previousPage - 1);

  onSetUrlParam({ page: previousPage });
  if (onPageChange) onPageChange({ offset: previousOffset });
};
const slicePagination = (list, currentPage = 1) => {
  let newList = list;
  if (newList.indexOf(currentPage) > 2) {
    newList = list.slice(newList.indexOf(currentPage - 3), list.length);
    if (newList.indexOf(currentPage) < list.length - 2) newList = newList.splice(newList.indexOf(currentPage) - 3, 5);
  } else if (newList.indexOf(currentPage) !== -1 && newList.indexOf(currentPage) < list.length - 2) {
    newList = newList.splice(0, 6 - newList.indexOf(currentPage));
  } else if (list.length > 5) {
    newList = newList.splice(newList.indexOf(currentPage) - 5, newList.length);
  }
  return newList;
};
const enhance = compose(
  pure,
  flattenProp('pagination'),
  branch(
    ({ limit, count }) => limit >= count,
    renderNothing,
  ),
  withNamespaces(['common']),
  withUrlParams({}),
  withProps(({ currentPage, count, limit }) => ({
    listPages: slicePagination(Array.from(Array(Math.ceil(count / limit)).keys()), currentPage),
  })),
  withHandlers({
    goNextPage,
    goPreviousPage,
  }),
);

export default enhance(Pagination);
