import React from 'react';
import PropTypes from 'prop-types';

import {
  TabWrapper, ShowMoreLazyLoad,
} from '../../../../components';
import ProjectNavItem from './projectNavItem/projectNavItem';
import './style.sass';

const ProjectsNav = ({
  projectListResult,
  projectListEntities,
  handlerFavorite,
  projectListHasMore,
  projectListRequest,
  projectCount,
  projectListPending,
  isProjectsTabOpen,
  changeProjectsTabStatus,
}) => (
  <div className="nav-project">

    <nav className="nav-project__nav">
      <TabWrapper title="Projects" onChangeStatusTab={changeProjectsTabStatus} defaultStatusTab={isProjectsTabOpen}>
        <ShowMoreLazyLoad
          data={projectListResult}
          limit={10}
          pending={projectListPending}
          handlerFetch={projectListRequest}
          hasMore={projectListHasMore}
          count={projectCount}
          emptyText="There are no projects yet"
          itemList={props => <ProjectNavItem key={`project-nav__item${props.idItem}`} handlerFavorite={handlerFavorite} item={projectListEntities[props.idItem]} />}
        />
      </TabWrapper>
    </nav>
  </div>
);

ProjectsNav.propTypes = {
  projectListResult: PropTypes.arrayOf(PropTypes.number).isRequired,
  projectListEntities: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.any),
  ]).isRequired,
  handlerFavorite: PropTypes.func.isRequired,
  projectListHasMore: PropTypes.bool.isRequired,
  isProjectsTabOpen: PropTypes.bool.isRequired,
  projectListRequest: PropTypes.func.isRequired,
  changeProjectsTabStatus: PropTypes.func.isRequired,
  projectCount: PropTypes.number.isRequired,
  projectListPending: PropTypes.bool,
  idItem: PropTypes.shape({
    favorite: PropTypes.any,
    id: PropTypes.string,
    title: PropTypes.string,
  }),
};

ProjectsNav.defaultProps = {
  idItem: {},
  projectListPending: false,
};

export default ProjectsNav;
