import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const ButtonClose = ({ title, onClick, className }) => (
  <button
    className={`close-button ${className}`}
    aria-label={`Close ${title}`}
    title={`Close ${title}`}
    type="button"
    onClick={onClick}
  >
    <span className="icon-close" />
  </button>
);
ButtonClose.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
ButtonClose.defaultProps = {
  title: '',
  onClick: () => {},
  className: '',
};
export default ButtonClose;
