import { compose, pure } from 'recompose';

import { withForwardingRef } from '../../../../utils/enchancers';

import MessageButtons from './messageButtons';

const enhance = compose(
  withForwardingRef,
  pure,
);

export default enhance(MessageButtons);
