import React from 'react';
import PropTypes from 'prop-types';

import UserProfile from './components/userProfile';
import { ButtonsCollection } from '../../components';

import './style.sass';

const RightSideBar = ({
  children, className, title, titleComponentRender, onToggle, setRightSideBarRef, userProfileId,
}) => (
  <div className={`right-side-bar ${className}`} ref={e => setRightSideBarRef('rightSideBar', e)}>
    <div className="right-side-bar__header">
      {titleComponentRender ? titleComponentRender()
        : (
          <h2 className="title--xl weight--bold right-side-bar__title">
            { userProfileId ? 'About User' : title }
          </h2>
        )}
      <ButtonsCollection.ButtonClose
        title="right sidebar"
        className="right-side-bar__close-button"
        onClick={onToggle}
      />
    </div>
    <div className="right-side-bar__body">
      {
        userProfileId ? (<UserProfile />) : (<>{children}</>)
      }
    </div>
  </div>
);
RightSideBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  title: PropTypes.string,
  titleComponentRender: PropTypes.func,
  onToggle: PropTypes.func.isRequired,
  setRightSideBarRef: PropTypes.func.isRequired,
  userProfileId: PropTypes.number,
};
RightSideBar.defaultProps = {
  children: '',
  title: '',
  className: '',
  userProfileId: null,
  titleComponentRender: null,
};

export default RightSideBar;
