import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import TogglerLeftSideBar from './togglerLeftSideBar';
import { withWindowWidth } from '../../../../utils/enchancers';
import { uiActions, uiSelectors } from '../../../../state/ui';

const { getLeftSidebar } = uiSelectors;

const mapStateToProps = state => ({
  leftSideBarStatus: getLeftSidebar(state),
  rightSideBarStatus: getLeftSidebar(state),
});

const mapDispatchToProps = {
  changeLeftSidebarStatus: uiActions.changeLeftSidebarStatus,
  changeRightSidebarStatus: uiActions.changeRightSidebarStatus,
};

const onToggleHandler = ({
  leftSideBarStatus,
  rightSideBarStatus,
  windowWidth,
  changeLeftSidebarStatus,
  changeRightSidebarStatus,
}) => (e) => {
  requestAnimationFrame(() => {
    changeLeftSidebarStatus(!leftSideBarStatus);
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWindowWidth(),
  withHandlers({
    onToggle: onToggleHandler,
  }),
);
export default enhance(TogglerLeftSideBar);
