/* eslint-disable */
import {
  compose, lifecycle, withStateHandlers, hoistStatics, withHandlers, pure, withProps,
} from 'recompose';
import { withNamespaces } from 'react-i18next';
import {
  always,
  ifElse,
  prop,
  length,
  path,
  objOf,
  identity,
  lt,
  when,
  isNil,
  cond,
  curry,
  is,
  T,
  equals,
  replace,
  invoker,
} from 'ramda';
import { notEqual } from 'ramda-extension';
import withRefs from '../../../../utils/enchancers/withRefs';
import TextArea from './textArea';
import { regexRules } from '../../../../utils/helpers/uiComponentHelpers/common';

const removeBreakers = when(length, replace(regexRules.breakHtmlElement, ''));

const onToggleFillHandler = ({ setFillStatus }) => compose(
  setFillStatus,
  ifElse(length,
    always(true),
    always(false)),
  path(['target', 'innerHTML']),
);

const setScrollHeightStateHandler = (state, { maxHeight }) => compose(
  objOf('scrollHeight'),
  ifElse(lt(maxHeight),
    always(maxHeight),
    identity),
);

const setFocusStatusStateHandler = () => objOf('isFocus');

const setFillStatusStateHandler = () => objOf('isFill');

const onSetRefFieldHandler = ({ childrenRef, setRef }) => element => compose(
  () => { if(childrenRef) childrenRef(element) },
  setRef('input'),
)(element);

const onSetRefScrollHandler = ({ childrenRef, setRef }) => setRef('scroll')

const setHeightToElement = curry((element, height) => cond([
  [is(Number), () => element.style.height = `${height}px`],
  [is(String), () => element.style.height = height],
  [T, always('Type of `height` is not correct')],
]));

const onGrowTextAreaHandler = ({ setScrollHeight, getRef }) => compose(
  (element) => {
    requestAnimationFrame(() => {
      compose((height) => {
        setScrollHeight(height);
        setHeightToElement(element, height);
      }, prop('scrollHeight'))(element);
      const scrollEl = getRef('scroll');
      if (scrollEl) scrollEl.scrollToBottom();
    });
    setHeightToElement(element, 0);
  },
  path(['e', 'target']),
);

const onBlurFieldHandler = ({ setFocusStatus }) => compose(
  setFocusStatus,
  ifElse(length,
    always(false),
    always(null)),
  removeBreakers, // todo: maybe we will has trouble with html elements in form without text
  path(['path', 'innerHTML']),
);

const onChangeValueHandler = ({ onChange }) => compose(
  onChange,
  mergeAll( {target: name })
);

const enhance = compose(
  pure,
  withRefs(),
  withNamespaces(['validation']),
  withStateHandlers(({ value }) => ({
    isFocus: false,
    scrollHeight: 32,
    isFill: ifElse(length, always(true), always(false))(value),
  }), {
    setScrollHeight: setScrollHeightStateHandler,
    setFocusStatus: setFocusStatusStateHandler,
    setFillStatus: setFillStatusStateHandler,
  }),
  withHandlers({
    onToggleFill: onToggleFillHandler,
    onGrowTextArea: onGrowTextAreaHandler,
    onBlurField: onBlurFieldHandler,
    onSetRefField: onSetRefFieldHandler,
    onSetRefScroll: onSetRefScrollHandler,
    onChangeValue: onChangeValueHandler,
  }),
  withProps(({ maxHeight, scrollHeight }) => ({
    stylesScroll: { maxHeight, height: scrollHeight }
  })),
  lifecycle({
    componentDidMount() {
      const {
        setFocusStatus, getRef, onToggleFill, isFocus,
        onGrowTextArea, onBlurField,
      } = this.props;
      const fieldElement = getRef('input').el.current;
      when(equals(true), () => compose(setFocusStatus, T, invoker('focus'))(fieldElement))(isFocus);
      fieldElement.addEventListener('blur', onBlurField);
      fieldElement.addEventListener('keyup', onToggleFill, true);
      fieldElement.addEventListener('keydown', onGrowTextArea, true);
    },
    componentDidUpdate(prevProps) {
      const { value, setFillStatus } = this.props;
      const { value: prevValue } = prevProps;
      when(notEqual(prevValue),
        compose(
          setFillStatus,
          ifElse(
            length,
            always(true),
            always(false),
          ),
        ))(value);
    },
    componentWillUnmount() {
      const { getRef, onToggleFill } = this.props;
      const fieldElement = getRef('input').el.current;

      fieldElement.removeEventListener('keyup', onToggleFill, true);
    },
  }),
);
export default hoistStatics(enhance)(TextArea);
