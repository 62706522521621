import React from 'react';
import PropTypes from 'prop-types';

import { Emoji } from 'emoji-mart';

const EmojiItem = ({ item, onClick }) => (
  <li className="emoji__picker-item">
    <Emoji
      emoji={item}
      set="messenger"
      onClick={onClick}
      size={24}
    />
  </li>
);

EmojiItem.propTypes = {
  item: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EmojiItem;
