import { compose, getContext } from 'recompose';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import FiltersRow from './filtersRow';

const enhance = compose(
  getContext({
    selectedSearch: PropTypes.string,
    selectedSort: PropTypes.shape({}),
    setSelectedSearch: PropTypes.func,
    setSelectedSort: PropTypes.func,
  }),
  withNamespaces(['common', 'chat']),
);
export default enhance(FiltersRow);
