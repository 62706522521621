import {
  compose, withProps,
} from 'recompose';
import { connect } from 'react-redux';

import { withFormik } from 'formik';
import { compose as RCompose, map, values } from 'ramda';
import EditUser from './editUser';
import { uiSelectors, uiActions } from '../../../../state/ui';
import { usersActions, usersSelectors } from '../../../../state/users';
import { permissionsSelectors } from '../../../../state/permissions';
import { renameKeys } from '../../../../utils/helpers/commonHelpers';

const mapStateToProps = (state, { selectedUserId }) => ({
  isOpen: uiSelectors.getModal(state)('addEvent'),
  user: usersSelectors.getUser(state)(selectedUserId),
  rolesEntities: permissionsSelectors.getRoles(state),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('addEvent'),
  onEditUser: usersActions.updateUserRequest,
};
const convertEntitiesToOptions = RCompose(
  map(renameKeys({ name: 'label', id: 'value' })),
  values,
);
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ rolesEntities }) => ({
    roles: convertEntitiesToOptions(rolesEntities),
  })),
  withFormik({
    mapPropsToValues: ({ user }) => ({
      ...user,
      email: user.email,
      username: user.username,
      position: user.position,
      status: 1,
    }),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    // validationSchema: rules,
    handleSubmit: (formValues,
      {
        props: {
          onEditUser,
          onCloseModal,
          selectedUserId,
          sort: {
            limit,
            offset,
          },
        },
      }) => {
      onEditUser({ ...formValues, role_id: parseInt(formValues.role_id, 10) }, {
        userId: selectedUserId,
        limit,
        offset,
        callbacks: {
          success: onCloseModal,
        },
      });
    },
  }),
);

export default enhance(EditUser);
