import React from 'react';
import PropTypes from 'prop-types';

import { CommentsTextArea, CommentsHistory } from './components';

import './style.sass';

const Comments = ({ onSendComment }) => (
  <div className="task-comments">
    <div className="task-comments__container">
      <CommentsHistory />
      <CommentsTextArea onSendComment={onSendComment} />
    </div>
  </div>
);

Comments.propTypes = {
  onSendComment: PropTypes.func.isRequired,
};

export default Comments;
