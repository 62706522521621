/* eslint-disable import/prefer-default-export */
import { keys, reduce, pathOr } from 'ramda';
import { deleteTimeEntry } from '../../helpers/taskHelpers/crudHelper';
import { taskActions, taskSelectors, taskTypes } from '../../../state/task';
import { tasksActions } from '../../../state/tasks';
import { projectSelectors } from '../../../state/project';


const reorderSpentTimeTask = (totalSpentTime, taskId, projectId, getState, dispatch, next) => {
  const task = projectSelectors.getTask(getState())(taskId, projectId);
  dispatch(tasksActions.setTask({
    task: { ...task, spent_time: totalSpentTime },
    isSetActiveTask: true,
  }));
  next(taskActions.reorderSpentTimeTask(totalSpentTime));
};

const deleteTimeEntryMiddleWare = ({ getState, dispatch }) => next => (action) => {
  if (action.type === taskTypes.DELETE_TIME_ENTRY) {
    const { payload: { timeEntry, meta: { taskId, projectId } } } = action;
    deleteTimeEntry(getState(), timeEntry.id, next, taskId);
    reorderSpentTimeTask(timeEntry.total_spent_time, taskId, projectId, getState, dispatch, next);
  } else {
    next(action);
  }
};

const editTimeEntryMiddleware = ({ getState, dispatch }) => next => (action) => {
  if (action.type === taskTypes.SET_TIME_ENTRY) {
    const { payload: { timeEntry, meta: { projectId, taskId } } } = action;
    next(taskActions.getTimeEntriesRequest({ projectId, taskId }, { isNotPending: true }));
    reorderSpentTimeTask(timeEntry.total_spent_time, taskId, projectId, getState, dispatch, next);
  } else {
    next(action);
  }
};

const deleteTaskFileMiddleware = ({ getState }) => next => (action) => {
  if (action.type === taskTypes.DELETE_TASK_FILE) {
    const { payload } = action;
    const files = pathOr({}, ['files'], taskSelectors.getActiveTask(getState()));
    const newFilesList = reduce((accum, index) => ((files[index]
      && files[index].id !== payload.file.id) ? { ...accum, [index]: files[index] } : accum),
    {}, keys(files));
    next(taskActions.resetTaskFiles(newFilesList));
  } else {
    next(action);
  }
};

export {
  deleteTimeEntryMiddleWare,
  editTimeEntryMiddleware,
  deleteTaskFileMiddleware,
};
