import {
  compose, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { pathOr, prop, path } from 'ramda';

import ChannelItem from './channelItem';
import { messengerSelectors } from '../../../../../../state/messenger';
import { usersSelectors } from '../../../../../../state/users';
import { userSelectors } from '../../../../../../state/user';

const mapStateToProps = (state, { channelId }) => ({
  channel: messengerSelectors.getDirectChannels(state)[channelId],
  user: userSelectors.getUserData(state),
  usersList: usersSelectors.getUsersEntities(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withProps(({ usersList, channel, user }) => ({
    channelStatusOnline: pathOr(0, [(prop('user_id', channel)), 'is_online'], usersList),
    channelName: channel.name,
    unreadCount: channel.unread_count,
    isOwnChannel: path(['user', 'id'], channel) === user.id,
  })),
);

export default enhance(ChannelItem);
