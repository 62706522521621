import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import {
  ConfirmModal,
} from '../../../../../components';

const CompleteSprintModal = ({
  isOpen,
  isPending,
  onCloseModal,
  sprint,
  onStart,
}) => (

  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Start"
    onAction={onStart}
    onClose={onCloseModal}
    title="Start Sprint"
  >
    Are you sure you want to start
    <b>{prop('title', sprint)}</b>
    sprint?
  </ConfirmModal>
);

CompleteSprintModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  sprint: PropTypes.instanceOf(Object).isRequired,
  onStart: PropTypes.func.isRequired,
};

export default CompleteSprintModal;
