import { compose } from 'recompose';
import { connect } from 'react-redux';
import MembersContainer from './membersContainer';
import { withWindowWidth } from '../../../../utils/enchancers';
import { uiActions, uiSelectors } from '../../../../state/ui';

const { changeProjectMembersTabStatus } = uiActions;
const { getTabStatus } = uiSelectors;

const mapStateToProps = state => ({
  isProjectMembersTabOpen: getTabStatus(state)('isProjectMembersTabOpen'),
});

const mapDispatchToProps = ({
  changeProjectMembersTabStatus,
});
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWindowWidth(),
);

export default enhance(MembersContainer);
