import React from 'react';
import PropTypes from 'prop-types';
import { InviteMemberButton } from '../../pages/messenger/components';

import { MemberItem, DeleteMemberModal, InviteMembersModal } from './components';

const MembersView = ({
  membersList, t, countOnlineMembers, countMembers,
  onSetModalDeleteMember, onSetModalInviteMember, selectedMemberId,
  onDelete, onInvite, isPendingDelete, isPendingInvite, ...props
}) => (
  <div className="project-members">
    <div className="project-members__content">
      <h5 className="project-members__title">
        {t('Online')}
        {` ${countOnlineMembers}/${countMembers}`}
      </h5>
      <ul className="project-members__list">
        {membersList.map(memberId => (
          <MemberItem
            key={memberId}
            memberId={memberId}
            onSetModalDeleteMember={onSetModalDeleteMember}
          />
        ))}
      </ul>
    </div>
    <DeleteMemberModal
      onDelete={onDelete}
      isPending={isPendingDelete}
      memberId={selectedMemberId}
      {...props}
    />
    <InviteMembersModal
      isPending={isPendingInvite}
      onInvite={onInvite}
      membersList={membersList}
      {...props}
    />
    <div className="row">
      <div className="col-lg-12 d-flex justify-content-center">
        <InviteMemberButton
          className="invite-members--right-side-bar"
          onClick={onSetModalInviteMember}
        />
      </div>
    </div>
  </div>
);

MembersView.propTypes = {
  onSetModalDeleteMember: PropTypes.func.isRequired,
  onSetModalInviteMember: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isPendingDelete: PropTypes.bool.isRequired,
  isPendingInvite: PropTypes.bool.isRequired,
  selectedMemberId: PropTypes.number.isRequired,
  membersList: PropTypes.arrayOf(PropTypes.number).isRequired,
  countMembers: PropTypes.number.isRequired,
  countOnlineMembers: PropTypes.number.isRequired,
};


export default MembersView;
