import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import {
  CreateProjectModal, DeleteProjectModal, EditProjectModal,
  CompleteProjectModal, StartProjectModal,
} from '../modals';
import ProjectItem from '../projectsBrill/components/projectItem';

const ProjectsAsList = ({
  projects,
  onOpenEditProjectModal, onOpenDeleteProjectModal, selectedProjectId,
  onDeleteProject, onOpenCompleteProjectModal, onOpenStartProjectModal,
}) => (
  <>
    <div className="row no-gutters pr-3 pl-3 py-2">
      <div className="col-md-12">
        {
            projects.map(projectId => (
              <ProjectItem
                onDelete={onOpenDeleteProjectModal}
                onEdit={onOpenEditProjectModal}
                onComplete={onOpenCompleteProjectModal}
                onStart={onOpenStartProjectModal}
                key={projectId}
                projectId={projectId}
              />
            ))
          }
      </div>
    </div>
    <DeleteProjectModal onDeleteProject={onDeleteProject} selectedProjectId={selectedProjectId} />
    <EditProjectModal selectedProjectId={selectedProjectId} />
    <CompleteProjectModal selectedProjectId={selectedProjectId} />
    <StartProjectModal selectedProjectId={selectedProjectId} />
    <CreateProjectModal />
  </>
);

ProjectsAsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  projects: PropTypes.objectOf(PropTypes.any).isRequired,
  gridName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sort: PropTypes.string,
  order: PropTypes.string,
  push: PropTypes.func.isRequired,
  setProgressProject: PropTypes.func.isRequired,
  onDeleteProject: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  selectedProjectId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  onSortBy: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
  }).isRequired,
  onToggleFavoriteStatus: PropTypes.func.isRequired,
  onOpenEditProjectModal: PropTypes.func.isRequired,
  onOpenDeleteProjectModal: PropTypes.func.isRequired,
};

ProjectsAsList.defaultProps = {
  order: '',
  selectedProjectId: null,
  sort: '',
};

export default ProjectsAsList;
