import {
  compose, pure,
} from 'recompose';

import List from './list';

const enhance = compose(
  pure,
);
export default enhance(List);
