import {
  compose, withProps, branch, renderNothing,
} from 'recompose';
import { connect } from 'react-redux';
import { path, prop } from 'ramda';

import AboutProject from './aboutProject';
import { FILES_URL } from '../../../../constants/config';
import { projectSelectors } from '../../../../state/project';

const mapStateToProps = (state, { projectId }) => ({
  project: projectSelectors.default(state)(projectId),
});

const getUrlImgById = id => (id ? `${FILES_URL}${id}` : '');

const enhance = compose(
  connect(mapStateToProps),
  branch(({ project }) => !prop('title', project), renderNothing),
  withProps(({ project }) => ({
    imgUrl: getUrlImgById(path(['image', 'id'], project)),
  })),
);
export default enhance(AboutProject);
