import React from 'react';
import PropTypes from 'prop-types';
import { MemberItem } from '..';
import './style.sass';

const Members = ({ membersList, t }) => (
  <div className="project-members">
    <h5 className="project-members__title">
      {t('Online')}
      {' '}
2/5
    </h5>
    <ul className="project-members__list">
      {membersList.map(user => <MemberItem key={user.id} user={user} />)}
    </ul>
  </div>
);

Members.propTypes = {
  membersList: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};


export default Members;
