import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const BrillWithIndex = ({ className, children }) => (
  <div className={`brill-count${className ? (` ${className}`) : ''}`}>
    <span className="brill-count__count">{children}</span>
    <span className="icon-hexagon-stroke brill-count__brill" />
  </div>
);

BrillWithIndex.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
};

BrillWithIndex.defaultProps = {
  className: '',
};

export default BrillWithIndex;
