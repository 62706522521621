import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const AttachedFiles = ({
  media, activeTabs, handlerStatusTab, t,
}) => (
  <div className="files-attached">
    <ul className="files-attached__list">
      {
        media.map((el, i) => (
          <li key={new Date().getTime() * Math.random()} className={`files-attached__item ${activeTabs[i] ? 'open' : ''}`}>
            <span className="files-attached__header">
              <span className="files-attached__info">
                <span className="files-attached__info-text">{t('upload an image:')}</span>
                <a href={el.src} target="__blank" className="files-attached__link">{el.name}</a>
                <span className="files-attached__size">
                  {' '}
                  (
                  {el.size}
                  )
                  {' '}
                </span>
              </span>
              <button type="button" className="files-attached__toggle-button" onClick={() => handlerStatusTab(i)} />
              <button type="button" className="files-attached__pin active">
                {/* { Just add className `active` if you want change color star} */}
              </button>
            </span>
            <span className="files-attached__body">
              <img src={el.src} alt="" />
            </span>
          </li>
        ))
      }
    </ul>
  </div>
);

AttachedFiles.propTypes = {
  media: PropTypes.arrayOf({
    name: PropTypes.string,
    source: PropTypes.string,
    size: PropTypes.string,
  }).isRequired,
  activeTabs: PropTypes.arrayOf(PropTypes.bool).isRequired,
  handlerStatusTab: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default AttachedFiles;
