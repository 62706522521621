import { prop } from 'ramda';
import { apiRequest, offlineRequest } from '../sagaRequestApi';

// eslint-disable-next-line import/prefer-default-export
export const asyncActionsMiddleware = () => next => (action) => {
  next(action);
  if (prop('async', action.meta)) {
    const { meta } = action;
    if (window.navigator.onLine) {
      next(apiRequest({
        ...meta,
      }));
    } else if (meta.method !== 'GET') {
      next(offlineRequest({
        ...meta,
      }));
    }
  }
};
