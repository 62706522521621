import { lifecycle } from 'recompose';

const checkIsUserValid = selector => lifecycle({
  componentDidMount() {
    const action = this.props[selector];
    action();
  },
});


export default checkIsUserValid;
