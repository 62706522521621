import {
  compose, withState, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import MaterialsContainer from './materialsContainer';
import { uiActions } from '../../state/ui';

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};

const onChangeSortingStateHandler = () => val => ({
  sortBy: val,
});

const enhance = compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withState('selectedNoteId', 'setSelectedNoteId', 0),
  withStateHandlers(() => ({ sortBy: { val: { id: 0 } } }), {
    onChangeSorting: onChangeSortingStateHandler,
  }),
);
export default enhance(MaterialsContainer);
