import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const Copyright = ({ t }) => (
  <div className="copyright">
    <div className="row text-align--center">
      {t('All right reserved. Avanga © 2018.')}
    </div>
  </div>
);

Copyright.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Copyright;
