import React from 'react';
import { ButtonsCollection, DropDown } from '../../../../../../components';

const HeaderDropDown = () => (
  <DropDown
    className="project-header-control"
    name="project-header"
    label={(
      <ButtonsCollection.ButtonIcons
        title="Controls for Tasks"
        className="header-col__project-burger"
      >
        <span className="icon-burger-dots-gorizont" />
      </ButtonsCollection.ButtonIcons>
    )}
    list={
      [
        {
          id: 0,
          label: (
            <>
              <span className="project-header-control__icon icon-edit-icon" />
              <span className="project-header-control__text">Edit channel</span>
            </>
          ),
        },
        {
          id: 3,
          label: (
            <>
              <span className="project-header-control__icon icon-delete-icon" />
              <span className="project-header-control__text">Delete channel</span>
            </>
          ),
        },
      ]
    }
  />
);


export default HeaderDropDown;
