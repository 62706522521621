import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './style.sass';

const ButtonBrill = ({
  children,
  className,
  href,
  asLink,
  type,
  onClick,
  isDelay,
  pending,
}) => (

  <>
    {
        asLink ? (
          <Link to={href} className={`button-brill ${className}`}>
            <span className="button-brill__wrapper">
              <span className="button-brill__text">{children}</span>
            </span>
            <span className="button-brill__pseudo-label">
              <svg
                version="1.1"
                stroke="red"
                fill="none"
                strokeWidth="2"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 19.4 48"
                style={{ enableBackground: 'new 0 0 19.4 48' }}
                className="button-brill__left-part"
              >

                <path
                  className="st0"
                  d="M21.1,46.1c1.6-4.3,1.2-8.8,1-13.3c-0.2-4.6-0.1-9.1-0.2-13.7C21.7,13.1,21.1,7,21.1,1h-3.5
c-2.8,0-5.4,1.5-6.8,3.9L2.4,19.4c-1.4,2.4-1.4,5.4,0,7.9l8.4,15.8c1.4,2.4,4,3.9,6.8,3.9h3.2C20.9,46.7,20.9,46.4,21.1,46.1z"
                />
              </svg>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                stroke="red"
                strokeWidth="2"
                fill="none"
                y="0px"
                viewBox="0 0 227.3 48"
                style={{ enableBackground: 'new 0 0 227.3 48' }}
                className="button-brill__middle-part"
                preserveAspectRatio="none"
              >
                <g>
                  <path className="st0" d="M-9.1,47h243" />
                  <path className="st0" d="M236.7,1H-9.5" />
                </g>
              </svg>
              <svg
                version="1.1"
                stroke="red"
                fill="none"
                strokeWidth="2"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 19.4 48"
                style={{ enableBackground: 'new 0 0 19.4 48' }}
                className="button-brill__right-part"
              >

                <path
                  className="st0"
                  d="M21.1,46.1c1.6-4.3,1.2-8.8,1-13.3c-0.2-4.6-0.1-9.1-0.2-13.7C21.7,13.1,21.1,7,21.1,1h-3.5c-2.8,0-5.4,1.5-6.8,3.9L2.4,19.4c-1.4,2.4-1.4,5.4,0,7.9l8.4,15.8c1.4,2.4,4,3.9,6.8,3.9h3.2C20.9,46.7,20.9,46.4,21.1,46.1z"
                />
              </svg>
            </span>
          </Link>
        ) : (
          // eslint-disable-next-line react/button-has-type
          <button type={type} onClick={(isDelay || pending) ? null : onClick} className={`button-brill ${className} ${(isDelay || pending) ? 'button--pending' : ''}`}>
            <span className="button-brill__wrapper">
              <span className="button-brill__text">{children}</span>
              <span className="button-brill__pending">
                <span className="button-brill__pending-part" />
                <span className="button-brill__pending-part" />
                <span className="button-brill__pending-part" />
                <span className="button-brill__pending-part" />
              </span>
            </span>
            <span className="button-brill__pseudo-label">
              <svg
                version="1.1"
                stroke="red"
                fill="none"
                strokeWidth="2"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 19.4 48"
                style={{ enableBackground: 'new 0 0 19.4 48' }}
                className="button-brill__left-part"
              >

                <path
                  className="st0"
                  d="M21.1,46.1c1.6-4.3,1.2-8.8,1-13.3c-0.2-4.6-0.1-9.1-0.2-13.7C21.7,13.1,21.1,7,21.1,1h-3.5
c-2.8,0-5.4,1.5-6.8,3.9L2.4,19.4c-1.4,2.4-1.4,5.4,0,7.9l8.4,15.8c1.4,2.4,4,3.9,6.8,3.9h3.2C20.9,46.7,20.9,46.4,21.1,46.1z"
                />
              </svg>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                stroke="red"
                strokeWidth="2"
                fill="none"
                y="0px"
                viewBox="0 0 227.3 48"
                style={{ enableBackground: 'new 0 0 227.3 48' }}
                className="button-brill__middle-part"
                preserveAspectRatio="none"
              >
                <g>
                  <path className="st0" d="M-9.1,47h243" />
                  <path className="st0" d="M236.7,1H-9.5" />
                </g>
              </svg>
              <svg
                version="1.1"
                stroke="red"
                fill="none"
                strokeWidth="2"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 19.4 48"
                style={{ enableBackground: 'new 0 0 19.4 48' }}
                className="button-brill__right-part"
              >

                <path
                  className="st0"
                  d="M21.1,46.1c1.6-4.3,1.2-8.8,1-13.3c-0.2-4.6-0.1-9.1-0.2-13.7C21.7,13.1,21.1,7,21.1,1h-3.5
c-2.8,0-5.4,1.5-6.8,3.9L2.4,19.4c-1.4,2.4-1.4,5.4,0,7.9l8.4,15.8c1.4,2.4,4,3.9,6.8,3.9h3.2C20.9,46.7,20.9,46.4,21.1,46.1z"
                />
              </svg>
            </span>
          </button>
        )
    }

  </>
);
ButtonBrill.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  href: PropTypes.string,
  asLink: PropTypes.bool,
  type: PropTypes.string,
  pending: PropTypes.bool,
  isDelay: PropTypes.bool.isRequired,
};
ButtonBrill.defaultProps = {
  children: 'Submit',
  onClick: () => {},
  className: '',
  href: '',
  asLink: false,
  pending: false,
  type: 'button',
};
export default ButtonBrill;
