import { createActions } from 'redux-actions';
import { identity, pathOr } from 'ramda';

import types from './types';
import { rolesSchema } from './schema';

export const {
  getRolesRequest,
  getModulesRequest,
  getRoleRequest,
  setRoles,
  setModules,
  setMyRole,
  updateRole,
} = createActions(
  {
    [types.GET_ROLES_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/roles',
        selectorName: 'getCustomersRequest',
        method: 'GET',
        params,
        actions: {
          success: setRoles,
        },
        schema: {
          rules: rolesSchema,
          pathData: ['data'],
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.GET_MODULES_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/modules',
        selectorName: 'getModulesRequest',
        method: 'GET',
        params,
        actions: {
          success: setModules,
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.GET_ROLE_REQUEST]: [
      identity,
      (params, meta = {}) => ({
        async: true,
        route: `/roles/${params.roleId}`,
        selectorName: 'getRoleRequest',
        method: 'GET',
        params,
        actions: {
          success: meta.isUpdate ? updateRole : setMyRole,
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
  },
  types.SET_ROLES,
  types.SET_MODULES,
  types.SET_MY_ROLE,
  types.UPDATE_ROLE,
);
