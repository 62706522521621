import {
  compose, getContext, withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CompleteSprintModal from './completeSprintModal';
import { projectActions, projectSelectors } from '../../../../../state/project';
import { withSprintsCRUDHandlers } from '../../../../../utils/enchancers';
import { getModal } from '../../../../../state/ui/selectors';
import { SPRINT_STATUS } from '../../../../../constants/sprints';

const mapStateToProps = (state, { selectedSprintId }) => ({
  isOpen: getModal(state)('completeSprintModal'),
  isPending: projectSelectors.getDeleteSprintRequestPending(state),
  sprint: projectSelectors.getProjectSprint(state)(selectedSprintId),
});

const mapDispatchToProps = {
  updateSprintRequest: projectActions.updateSprintRequest,
};

const onCompleteHandler = ({
  selectedSprintId, projectId, updateSprintRequest, sprint, onClose,
}) => () => updateSprintRequest({
  projectId,
  sprintId: selectedSprintId,
  ...sprint,
  status: SPRINT_STATUS.COMPLETED,
}, {
  callbacks: {
    success: onClose,
  },
});

const onCloseHandler = ({ onCloseModal }) => () => onCloseModal('completeSprintModal');

const enhance = compose(
  getContext({
    selectedSprintId: PropTypes.number.isRequired,
    sprintCRUDMeta: PropTypes.instanceOf(Object).isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withSprintsCRUDHandlers,
  withHandlers({
    onClose: onCloseHandler,
  }),
  withHandlers({
    onComplete: onCompleteHandler,
  }),
);

export default enhance(CompleteSprintModal);
