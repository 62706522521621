import React from 'react';
import { Link } from 'react-router-dom';

import { iconCircles } from '../../assets/img';
import './style.sass';

const Page404 = () => (
  <div className="height-full light">
    <div
      id="primary"
      className="content-area"
      data-bg-possition="center"
      data-bg-repeat="false"
      style={{ background: `url(${iconCircles})` }}
    >
      <div className="container">
        <div className="col-xl-8 mx-lg-auto p-t-b-80">
          <header className="text-center mb-5">
            <h1>oops!</h1>
            <p className="section-subtitle">Something went wrong. The page you are looking for is gone</p>

          </header>
          <div className="row d-flex justify-content-center">
            <Link to="/" className="btn btn-outline-primary w-150px text-uppercase">
              <span className="icon-home mr-2" />
                Go home
            </Link>
          </div>
          <div className="pt-5 p-t-100 text-center">
            <p className="s-256">404</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Page404;
