import {
  take,
  fork,
  put,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { sagasManager } from '../../utils';
import { userActions, userTypes } from '.';
import { uiActions } from '../ui';
import { ROUTE_TYPES } from '../../constants/ui';
import { userHelpers } from '../../utils/helpers';
import { messengerActions } from '../messenger';
import { usersActions } from '../users';
import {
  connectSocketToServer,
  subscribeChannel,
  listenEvent,
} from '../../utils/helpers/webSocket/state/actions';
import { LAST_CHANNEL_MESSENGER_STORAGE } from '../../constants/messenger';

function* loginFlow() {
  while (true) {
    const { payload: { token } } = yield take([
      userTypes.LOGIN_REQUEST_SUCCESS,
      userTypes.CONTINUE_SIGN_UP_REQUEST_SUCCESS,
    ]);
    const dataToken = token.token;
    yield localStorage.setItem('authToken', dataToken);
    yield window.token = token.token;
    yield put(userActions.setAuthenticated(true));
    yield put(uiActions.setRoutesType(ROUTE_TYPES.AUTHENTICATED));
    yield put(push('/profile'));
  }
}

function* logoutFlow() {
  while (true) {
    yield take(userTypes.LOGOUT);
    yield localStorage.removeItem(LAST_CHANNEL_MESSENGER_STORAGE);
    yield userHelpers.userLogout();
    yield window.ws.close();
    yield window.ws = null;
  }
}

function* forgotPasswordFlow() {
  while (true) {
    yield take(userTypes.FORGOT_PASSWORD_SUCCESS);
    yield put(push('/forgot-password-success/'));
  }
}

function* resetPasswordFlow() {
  while (true) {
    yield take(userTypes.RESET_PASSWORD_SUCCESS);
    yield put(push('/'));
  }
}

function* signUpFlow() {
  while (true) {
    yield take(userTypes.SIGN_UP_REQUEST_SUCCESS);
    yield put(push('/sign-up-success/'));
  }
}

function* userIsValidFlow() {
  while (true) {
    yield take(userTypes.SET_USER_IS_VALID);
    yield put(connectSocketToServer());
    yield put(subscribeChannel({ topic: 'common' }));
    yield put(listenEvent({
      topic: 'common',
      event: 'newMessage',
      action: messengerActions.emitNewMessage,
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'userOnlineStatus',
      action: usersActions.emitUsersStatus,
    }));
  }
}

function* userIsInvalidFlow() {
  while (true) {
    yield take(userTypes.SET_USER_IS_INVALID);
    yield userHelpers.userLogout();
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(loginFlow);
  yield fork(forgotPasswordFlow);
  yield fork(signUpFlow);
  yield fork(resetPasswordFlow);
  yield fork(logoutFlow);
  yield fork(userIsInvalidFlow);
  yield fork(userIsValidFlow);
});
