import React from 'react';
import PropTypes from 'prop-types';
import ModulesItem from './components';
import { TabWrapper } from '../../../../components';
import './style.sass';

const ModulesList = ({ data, isModulesTabOpen, changeModulesTabStatus }) => (
  <div className="modules-list">
    <div className="modules-list__container">
      <TabWrapper onChangeStatusTab={changeModulesTabStatus} defaultStatusTab={isModulesTabOpen} title="Modules">
        <ul className="modules-list__list">
          {
            data.map(item => (
              <ModulesItem key={Math.random() * new Date()} title={item.title} />
            ))
          }
        </ul>
      </TabWrapper>
    </div>
  </div>
);
ModulesList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isModulesTabOpen: PropTypes.bool.isRequired,
  changeModulesTabStatus: PropTypes.func.isRequired,
};

export default ModulesList;
