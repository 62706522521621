import { createActions } from 'redux-actions';
import { identity, pathOr } from 'ramda';

import types from './types';
import { customersListSchema } from './schema';

export const {
  getCustomersRequest,
  addCustomerRequest,
  deleteCustomerRequest,
  updateCustomerRequest,
  exportCustomersRequest,
  importCustomersRequest,
  getCustomerRequest,
  getCustomersListAutocompleteRequest,
  setCustomers,
  setCustomer,
  updateCustomer,
  setCustomersListAutocomplete,
} = createActions(
  {
    [types.GET_CUSTOMERS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/customers',
        selectorName: 'getCustomersRequest',
        method: 'GET',
        params,
        actions: {
          success: setCustomers,
        },
        schema: {
          rules: customersListSchema,
          pathData: ['data'],
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.ADD_CUSTOMER_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/customers',
        selectorName: 'addCustomerRequest',
        method: 'POST',
        params,
        actions: {
          success: getCustomersRequest,
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.DELETE_CUSTOMER_REQUEST]: [
      identity,
      (params, { customerId, ...meta }) => ({
        async: true,
        route: `/customers/${customerId}`,
        selectorName: 'deleteCustomerRequest',
        method: 'DELETE',
        params,
        actions: { success: () => getCustomersRequest({ limit: meta.limit, offset: meta.offset }) },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.UPDATE_CUSTOMER_REQUEST]: [
      identity,
      (params, { customerId, ...meta }) => ({
        async: true,
        route: `/customers/${customerId}`,
        selectorName: 'deleteCustomerRequest',
        method: 'PUT',
        params,
        actions: {
          success: updateCustomer,
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.EXPORT_CUSTOMERS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/customers/export-to-csv',
        selectorName: 'exportCustomersRequest',
        method: 'GET',
        params: { isFileLoading: true, ...params },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.IMPORT_CUSTOMERS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/customers/import-from-csv',
        selectorName: 'importCustomersRequest',
        method: 'POST',
        params,
        actions: {
          success: getCustomersRequest,
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.GET_CUSTOMER_REQUEST]: [
      identity,
      ({ customerId, ...params }, meta) => ({
        async: true,
        route: `/customers/${customerId}`,
        selectorName: 'getCustomerRequest',
        method: 'GET',
        params,
        actions: {
          success: setCustomer,
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.GET_CUSTOMERS_LIST_AUTOCOMPLETE_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        params,
        route: '/customers/autocomplete',
        selectorName: 'getCustomersListAutocompleteRequest',
        method: 'GET',
        actions: {
          success: setCustomersListAutocomplete,
        },
        ...meta,
      }),
    ],
  },
  types.SET_CUSTOMERS,
  types.SET_CUSTOMER,
  types.UPDATE_CUSTOMER,
  types.SET_CUSTOMERS_LIST_AUTOCOMPLETE,
);
