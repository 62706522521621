import { compose, withStateHandlers } from 'recompose';
import { withNamespaces } from 'react-i18next';

import AttachedFiles from './attachedFiles';

const enhance = compose(
  withNamespaces(['common', 'chat']),
  withStateHandlers(() => ({ element: '', activeTabs: {} }), {
    handlerStatusTab: ({ activeTabs }) => (id, val) => {
      const newObj = {};
      newObj[id] = val || (activeTabs[id] ? !activeTabs[id] : true);
      return {
        activeTabs: {
          ...activeTabs,
          ...newObj,
        },
      };
    },
    handlerInitTab: () => e => ({
      element: e,
    }),
  }),
);
export default enhance(AttachedFiles);
