import {
  lifecycle, withState, compose,
} from 'recompose';


const withOnline = compose(
  withState('isOnline', 'setIsOnline', true),
  lifecycle({
    componentDidMount() {
      this.props.setIsOnline(window.navigator.onLine);
      window.addEventListener('online', () => this.props.setIsOnline(true));
      window.addEventListener('offline', () => this.props.setIsOnline(false));
    },
  }),
);

export default withOnline;
