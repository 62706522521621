import React from 'react';

import { DropDown } from '../../../../components';
import './style.sass';

const FiltersRow = () => (
  <div className="repository-filters">
    <div className="repository-filters__sort-group">
      <DropDown
        label="View"
        list={
        [
          {
            id: 0,
            title: 'All Repositories',
          },
          {
            id: 1,
            title: 'Old Repositories',
          },
          {
            id: 2,
            title: 'Happy Repositories',
          },
        ]
      }
      />
    </div>
  </div>
);
export default FiltersRow;
