import {
  compose, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';

import { withFormik } from 'formik';
import {
  compose as RCompose, map, path, pathOr, prop, propOr, values,
} from 'ramda';
import AddCustomer from './addCustomer';
import { uiSelectors, uiActions } from '../../../../state/ui';
import { customersActions } from '../../../../state/customers';
import rules from '../rules';
import withAutocomplete from '../../../../utils/enchancers/withAutocomplete';
import { newTaskActions } from '../../../../state/newTask';
import { renameKeysTitleIntoValue } from '../../../../utils/helpers/uiHelpers';

const mapStateToProps = state => ({
  isOpen: uiSelectors.getModal(state)('addCustomer'),
  addCustomerError: pathOr({}, ['errors', 'addCustomerRequest'], state),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('addCustomer'),
  onAddCustomer: customersActions.addCustomerRequest,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAutocomplete({
    name: 'getProject',
    action: newTaskActions.getProjectsTaskCrudRequest,
    dataPath: RCompose(
      map(renameKeysTitleIntoValue),
      values,
      path(['data', 'entities', 'projects']),
    ),
    searchField: 'title',
  }),
  withFormik({
    mapPropsToValues: () => ({
      status: 1,
    }),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: rules,
    handleSubmit: (formValues,
      {
        resetForm,
        props: {
          onAddCustomer,
          onCloseModal,

        },
      }) => {
      onAddCustomer({ ...formValues, role_id: parseInt(formValues.role_id, 10), projects: map(prop('value'), propOr(['projects'], formValues)) }, {
        callbacks: {
          success: () => {
            onCloseModal();
            resetForm({});
          },
        },
      });
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.addCustomerError.username !== prevProps.addCustomerError.username) {
        this.props.setFieldError('username', { message: 'Username must be uniq' });
      }
    },
  }),
);

export default enhance(AddCustomer);
