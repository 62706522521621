import { compose } from 'recompose';
import { connect } from 'react-redux';

import ChannelItem from './channelItem';
import { messengerSelectors } from '../../../../../../state/messenger';
import withOnline from '../../../../../../utils/enchancers/withOnline';

const mapStateToProps = state => ({
  groupChannels: messengerSelectors.getGroupChannels(state),
  count: messengerSelectors.getCountLoadedMessages(state),
});

const enhance = compose(
  withOnline,
  connect(mapStateToProps),
);

export default enhance(ChannelItem);
