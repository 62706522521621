import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const View = ({
  children, leftSideBar,
}) => (
  <div className={`main-layout ${leftSideBar ? 'sidebar-open' : ''}`}>{children}</div>
);
View.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  leftSideBarStatus: PropTypes.bool.isRequired,
  onEnableNotification: PropTypes.func.isRequired,
  isNotificationPermissionDefault: PropTypes.bool.isRequired,
  rightSideBarStatus: PropTypes.bool.isRequired,
  setRef: PropTypes.func.isRequired,
  className: PropTypes.string,
};

View.defaultProps = {
  className: '',
};

export default View;
