import React from 'react';
import PropTypes from 'prop-types';

import { Header, Directs } from '../../../../containers';
import './style.sass';

const DirectsContainer = ({ history: { goBack } }) => (
  <div className="main-container">
    <Header />
    <div className="main-container__content main-container__content--profile">
      <Directs onGoBack={goBack} />
    </div>
  </div>
);

DirectsContainer.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default DirectsContainer;
