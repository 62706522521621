import {
  compose, withStateHandlers, getContext, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { withNamespaces } from 'react-i18next';

import { withRouter } from 'react-router';
import { uiActions } from '../../../../state/ui';
import { getProjectSprint } from '../../../../state/project/selectors';
import Sprint from './sprint';
import { getTimeStringBySeconds } from '../../../../utils/helpers/dateHelpers';
import { withPermissionHandlers } from '../../../../components/permissionElement';
import withOnline from '../../../../utils/enchancers/withOnline';
import { projectActions } from '../../../../state/project';
import { offlineRequestActions } from '../../../../utils/middlewares/sagaRequestApi/state/offlineRequest';
import { formatDate } from '../../../../utils/helpers/commonHelpers';

const mapStateToProps = (state, { id, match }) => ({
  item: getProjectSprint(state)(id, path(['params', 'id'], match)),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
  setSprint: projectActions.setSprint,
  resetOfflineAction: offlineRequestActions.resetOfflineAction,
  deleteSprint: projectActions.deleteSprint,
};

const onOpenTabSprintStateHandler = ({ isOpenSprintTab }) => () => ({
  isOpenSprintTab: !isOpenSprintTab,
});

const onEditHandler = ({ id, setOpenModal, setSelectedSprintId }) => () => {
  setSelectedSprintId(id);
  setOpenModal('editSprintModal');
};

const onDeleteHandler = ({ id, setOpenModal, setSelectedSprintId }) => () => {
  setSelectedSprintId(id);
  setOpenModal('deleteSprintModal');
};

const onCompleteHandler = ({ id, setOpenModal, setSelectedSprintId }) => () => {
  setSelectedSprintId(id);
  setOpenModal('completeSprintModal');
};

const onStartHandler = ({ id, setOpenModal, setSelectedSprintId }) => () => {
  setSelectedSprintId(id);
  setOpenModal('startSprintModal');
};

const onResetOfflineDeleteHandler = ({
  item, setSprint, resetOfflineAction,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: item.requestIdDelete, method: 'DELETE', selectorName: 'deleteSprintRequest' });
  setSprint({
    id: item.id,
    project_id: item.project_id,
    isDeleting: false,
  });
};
const onResetOfflineEditHandler = ({
  item, setSprint, resetOfflineAction,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: item.requestIdPut, method: 'PUT', selectorName: 'updateSprintRequest' });
  setSprint({
    id: item.id,
    project_id: item.project_id,
    ...item.oldResult,
    isUpdating: false,
  });
};
const onResetOfflineCreateHandler = ({
  item, resetOfflineAction, deleteSprint,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: item.requestIdPost, method: 'POST', selectorName: 'addSprintRequest' });
  deleteSprint({ sprint: item, meta: { } });
};


const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withOnline,
  withPermissionHandlers,
  withNamespaces(['common', 'tasks']),
  getContext({
    setSelectedSprintId: PropTypes.func.isRequired,
  }),
  withProps(({ item: { totalSpentTime, totalEstimatedTime, end_time } }) => ({
    totalSpent: getTimeStringBySeconds(totalSpentTime),
    totalEstimated: getTimeStringBySeconds(totalEstimatedTime),
    // eslint-disable-next-line camelcase
    deadline: end_time ? formatDate(end_time) : '-',
  })),
  withStateHandlers(() => ({ isOpenSprintTab: true }), {
    onOpenTabSprint: onOpenTabSprintStateHandler,
  }),
  withHandlers({
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
    onComplete: onCompleteHandler,
    onStart: onStartHandler,
  }),
  withHandlers({
    onResetOfflineDelete: onResetOfflineDeleteHandler,
    onResetOfflineEdit: onResetOfflineEditHandler,
    onResetOfflineCreate: onResetOfflineCreateHandler,
  }),
);
export default enhance(Sprint);
