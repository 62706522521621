import React from 'react';
import PropTypes from 'prop-types';

import { ModalWindow } from '../../../../components';
import './style.sass';

const DeleteFileModal = ({
  isOpen,
  onCloseModal,
  onDeleteProjectFile,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    className="delete-task__modal"
    title={t('Delete file')}
    modalName={t('Delete file')}
  >
    <>
      <div className="row  text-align--left">
        <span className="delete-task__title title-md">
          {t('Are you sure you want to delete this file ?')}
        </span>
      </div>
      <div className="delete-task__button-row">
        <button
          type="button"
          className="btn btn-primary w-100px"
          onClick={onDeleteProjectFile}
        >
          {t('Delete')}
        </button>
        <button
          type="button"
          className="button--cancel"
          onClick={onCloseModal}
        >
          {t('Cancel')}
        </button>
      </div>
    </>
  </ModalWindow>
);

DeleteFileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onDeleteProjectFile: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default DeleteFileModal;
