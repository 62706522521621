import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const BannerRequestNotification = ({ isVisible, onButtonClick }) => (
  <>
    { isVisible && (
    <div className="banner-request-notification">
      <span className="banner-request-notification__wrapper">
        <span className="banner-request-notification__label">
       Avanga needs your permission to
        </span>
        <button onClick={onButtonClick} type="button" className="banner-request-notification__button">
        enable notifications
        </button>
      </span>
    </div>
    )}
  </>
);

BannerRequestNotification.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default BannerRequestNotification;
