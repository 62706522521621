import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { path } from 'ramda';

import {
  Avatar, OnlineStatusLabel,
} from '../../../../components';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';

const DirectsItem = ({ channel }) => (
  <li key={channel.id} className="directs-list__item">
    <Link to={`/messenger/${channel.id}`} className="directs-list__link">
      <div className="directs-list__avatar">
        <Avatar src={getImageUrl(path(['user', 'image'], channel))} alt={getFullName(channel)} />
        <OnlineStatusLabel status={path(['user', 'is_online'], channel)} />
      </div>
      <h3 className="directs-list__title-group-title">{getFullName(channel)}</h3>
    </Link>
  </li>
);

DirectsItem.propTypes = {
  channel: PropTypes.instanceOf(Object).isRequired,
};

export default DirectsItem;
