/* eslint-disable */
import {
  branch,
  compose, withProps, renderNothing, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { pathOr, map, includes, is } from 'ramda';

import { userSelectors } from '../../state/user';
import PermissionElement from './permissionElement';

const mapStateToProps = state => ({
  myPermissions: userSelectors.getMyPermissions(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withProps(({
    module, action, myPermissions,
  }) => ({
    isNotAccess: is(Array, action) ? !includes(1, map(actionItem => pathOr(0, [module, actionItem], myPermissions), action)) : pathOr(0, [module, action], myPermissions) === 0,
    // isNotAccess: false,
  })),
  branch(({ isNotAccess }) => isNotAccess, renderNothing),
);

const withPermissionHandlers = compose(
  connect(mapStateToProps),
  withHandlers({
    P: ({ myPermissions }) => (module, action, cb) =>
      pathOr(0, [module, action], myPermissions),
  }),
);

export default enhance(PermissionElement);

export {
  withPermissionHandlers,
};
