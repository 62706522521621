import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import {
  pathOr, filter, uniq,
} from 'ramda';
import withWindowWidth from '../../../../utils/enchancers/withWindowWidth';
import TaskCard from './taskCard';
import { projectActions, projectSelectors } from '../../../../state/project';
import { withTasksCRUDHandlers } from '../../../../utils/enchancers';
import { withPermissionHandlers } from '../../../../components/permissionElement';
import { tasksActions } from '../../../../state/tasks';
import { offlineRequestActions } from '../../../../utils/middlewares/sagaRequestApi/state/offlineRequest';

const mapStateToProps = (state, { id, match: { params: { projectId } } }) => ({
  taskStatuses: pathOr([], ['project', projectId, 'entities', 'agenda'], state),
  categories: projectSelectors.getProjectCategoriesTasks(state),
  authors: projectSelectors.getProjectTasksAuthors(state),
  assignees: projectSelectors.getProjectTasksAssignees(state),
  task: projectSelectors.getTask(state)(id, projectId),
  getAgendaTasksList: projectSelectors.getAgendaTasksList(state),
});

const mapDispatchToProps = {
  setTask: tasksActions.setTask,
  deleteTask: tasksActions.deleteTask,
  resetOfflineAction: offlineRequestActions.resetOfflineAction,
  updateTaskRequest: tasksActions.updateTaskRequest,
  reorderAgendaTasks: projectActions.reorderAgendaTasks,
};

const onEditHandler = ({ onEditTask, match: { params: { projectId } }, id }) => () => {
  onEditTask({ taskId: id, projectId });
};
const onDeleteHandler = ({
  onDeleteTask,
  match: { params: { projectId } }, id, statusId,
}) => () => {
  onDeleteTask({ taskId: id, projectId, meta: { isAgendaBoard: true, statusId } });
};

const onResetOfflineDeleteHandler = ({
  data, setTask, resetOfflineAction,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: data.requestIdDelete, method: 'DELETE', selectorName: 'deleteTaskRequest' });
  setTask({
    task: {
      id: data.id,
      isDeleting: false,
    },
  });
};
const onResetOfflineEditHandler = ({
  data, setTask, resetOfflineAction,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: data.requestIdPut, method: 'PUT', selectorName: 'updateTaskRequest' });
  setTask({
    task: {
      id: data.id,
      ...data.oldResult,
      isUpdating: false,
    },
    meta: {
      isUpdate: true,
    },
  });
};
const onResetOfflineCreateHandler = ({
  data, resetOfflineAction, deleteTask,
}) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  resetOfflineAction({ requestId: data.requestIdPost, method: 'POST', selectorName: 'addTaskRequest' });
  deleteTask({ task: data });
};

const onSetTaskStatusHandler = ({
  task, updateTaskRequest, reorderAgendaTasks, getAgendaTasksList, match: { params: { projectId } },
}) => (statusId) => {
  const itemsInSource = getAgendaTasksList(projectId, task.status);
  const itemsInSource2 = getAgendaTasksList(projectId, statusId);

  const prevTasksList = filter(item => item !== task.id, itemsInSource);
  const nextTasksList = uniq([task.id, ...itemsInSource2]);

  reorderAgendaTasks({ tasks: prevTasksList, status: task.status, projectId });
  reorderAgendaTasks({ tasks: nextTasksList, status: statusId, projectId });

  updateTaskRequest({ ...task, status: statusId }, { isUpdate: true });
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withWindowWidth(),
  withPermissionHandlers,
  withNamespaces(['tasks', 'common']),
  withTasksCRUDHandlers,
  withHandlers({
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
    onSetTaskStatus: onSetTaskStatusHandler,
  }),
  withHandlers({
    onResetOfflineDelete: onResetOfflineDeleteHandler,
    onResetOfflineEdit: onResetOfflineEditHandler,
    onResetOfflineCreate: onResetOfflineCreateHandler,
  }),
);

export default enhance(TaskCard);
