import React from 'react';
import PropTypes from 'prop-types';

import {
  BrillMask, Avatar, OnlineStatusLabel,
} from '../../../../components';
import './style.sass';

const AboutConversation = ({
  avatar,
  fullName,
  nickName,
  onlineStatus,
}) => (
  <div className="about-conversation">
    <BrillMask className="about-conversation__avatar">
      <Avatar
        alt={fullName}
        src={avatar}
      />
    </BrillMask>
    <div className="about-conversation__info">
      <h2 className="about-conversation__info-name">
        <OnlineStatusLabel status={onlineStatus} />
        {fullName}
      </h2>
      <b className="about-conversation__info-nickname">
        {`@${nickName}`}
      </b>
    </div>
  </div>
);

AboutConversation.propTypes = {
  avatar: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  nickName: PropTypes.string,
  onlineStatus: PropTypes.bool.isRequired,
};

AboutConversation.defaultProps = {
  nickName: '',
};

export default AboutConversation;
