/* eslint-disable import/prefer-default-export */
import { filter, compose } from 'ramda';

import { eventsActions, eventsSelectors, eventsTypes } from '../../../state/events';

const deleteEventMiddleware = ({ getState }) => next => (action) => {
  if (action.type === eventsTypes.DELETE_EVENT) {
    const newResult = compose(filter(id => id !== action.payload.event.id),
      eventsSelectors.getEventsResult, getState)();
    next(eventsActions.reorderEvents(newResult));
  } else {
    next(action);
  }
};

export {
  deleteEventMiddleware,
};
