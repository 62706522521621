import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { setPage } from '../../state/ui/actions';
import { TITLES_CONSTANTS } from '../../constants/titles';
import Statistics from './statistics';
import withPermission from '../../utils/enchancers/withPemission';
import { MODULES } from '../../constants/pemissions';

const mapDispatchToProps = ({
  setCurrentPage: setPage,
});

const enhance = compose(
  withPermission([MODULES.STATS]),
  connect(null, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
  lifecycle({
    componentDidMount() {
      this.props.setCurrentPage({ page: TITLES_CONSTANTS.PAGE_STATISTICS });
    },
  }),
);
export default enhance(Statistics);
