import React from 'react';
import PropTypes from 'prop-types';

import MessageTextArea from '../messageInput';
import MessageButtons from '../messageButtons';
import MentionsDropdown from '../mentionsDropdown';
import AttachFilesModal from '../attachFilesModal';
import EmojiPicker from '../../../../components/messageTextArea/emojiPicker';
import EmojiAutoComplete
  from '../../../../components/messageTextArea/emojiAutoComplete/emojiAutoComplete';
import './style.sass';
import { keys, omit } from 'ramda';

const TypeMessage = ({
  messageContent,
  onTextareaKeyDown,
  onChangeContent,
  onSetEmoji,
  onOpenEmoji,
  onCloseEmoji,
  isEmojiOpen,
  onClickSaveContent,
  mentions,
  activeDropdownMember,
  isDropdownMentionsOpen,
  className,
  setRef,
  emojisAutocomplete,
  isEmojiAutocompleteOpen,
  onOpenEmojiAutocompleteOpen,
  onOpenEmojiAutocompleteClose,
  emojiSelected,
  emojiTyping,
  onSetFocusField,
  onSetEmojiByAutocomplete,
  onSetRefWrapTextArea,
  setOpenAttachFilesModal,
  setAttachedFiles,
  attachedFiles,
  isOnline,
  errorField,
}) => (
  <>
    <div className={`message-textArea__row ${errorField.name ? 'message-textArea--error' : ''}`} ref={onSetRefWrapTextArea}>
      <ui className="message-textArea__files-list">
        {
          keys(attachedFiles).map(key => (
            <li>
              <button type="button" onClick={() => setAttachedFiles(omit([key], attachedFiles))}>
                <i className="icon-trash" />
              </button>
              <i className="icon-file" />
              <h4>{attachedFiles[key].original}</h4>
            </li>
          ))
        }
      </ui>
      <EmojiAutoComplete
        onChange={onChangeContent}
        set="messenger"
        emojisAutocomplete={emojisAutocomplete}
        emojiSelected={emojiSelected}
        onSetEmoji={onSetEmoji}
        emojiTyping={emojiTyping}
        isOpen={isEmojiAutocompleteOpen}
        onSetEmojiByAutocomplete={onSetEmojiByAutocomplete}
        isEmojiPickerOpen={isEmojiOpen}
        onOpen={onOpenEmojiAutocompleteOpen}
        onClose={onOpenEmojiAutocompleteClose}
      />
      <div className="message-textArea__file-col">
        <button
          onClick={setOpenAttachFilesModal}
          disabled={!isOnline}
          title={!isOnline ? 'Only in online version' : 'Add files'}
          type="button"
          className="message-textArea__file-btn"
        >
          <i className="icon-file" />
        </button>
      </div>
      <EmojiPicker
        data-emoji="data-emoji"
        onSetFocusField={onSetFocusField}
        onOpen={onOpenEmoji}
        onClose={onCloseEmoji}
        isOpen={isEmojiOpen}
        onSetEmoji={onSetEmoji}
      />
      <div className="message-textArea__col message-textArea__col--fill">
        {errorField.name && (<span className="icon-error message-textArea__error-icon" title={errorField.title} />)}
        <MessageTextArea
          value={messageContent}
          onKeyDown={onTextareaKeyDown}
          childrenRef={ref => setRef('typeMessage', ref)}
          onChange={onChangeContent}
          placeholder="Type a message"
          className={className}
        />
      </div>
      <div className="message-textArea__col">
        <MessageButtons
          onClickSaveContent={onClickSaveContent}
        />
      </div>
    </div>
    <MentionsDropdown
      isOpen={isDropdownMentionsOpen}
      members={mentions}
      activeItem={activeDropdownMember}
      className="message-textArea__mentions"
    />
    <AttachFilesModal
      setAttachedFiles={setAttachedFiles}
      attachedFiles={attachedFiles}
    />
  </>
);

TypeMessage.propTypes = {
  onTextareaKeyDown: PropTypes.func.isRequired,
  onChangeContent: PropTypes.func.isRequired,
  onClickSaveContent: PropTypes.func.isRequired,
  onSetEmoji: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  onOpenEmoji: PropTypes.func.isRequired,
  onCloseEmoji: PropTypes.func.isRequired,
  isEmojiOpen: PropTypes.bool.isRequired,
  isDropdownMentionsOpen: PropTypes.bool.isRequired,
  mentions: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeDropdownMember: PropTypes.number.isRequired,
  messageContent: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  emojisAutocomplete: PropTypes.instanceOf(Object).isRequired,
  isEmojiAutocompleteOpen: PropTypes.bool.isRequired,
  onOpenEmojiAutocompleteOpen: PropTypes.func.isRequired,
  onOpenEmojiAutocompleteClose: PropTypes.func.isRequired,
  emojiSelected: PropTypes.number.isRequired,
  emojiTyping: PropTypes.number.isRequired,
  onSetFocusField: PropTypes.func.isRequired,
  onSetEmojiByAutocomplete: PropTypes.func.isRequired,
  onSetRefWrapTextArea: PropTypes.func.isRequired,
  setOpenAttachFilesModal: PropTypes.func.isRequired,
  setAttachedFiles: PropTypes.func.isRequired,
  attachedFiles: PropTypes.instanceOf(Array).isRequired,
  isOnline: PropTypes.bool.isRequired,
  errorField: PropTypes.instanceOf(Object).isRequired,
};

export default TypeMessage;
