import { compose, withHandlers, withState } from 'recompose';

import { withNamespaces } from 'react-i18next';
import AsyncAutoComplete from './asyncCompleteField';

const onInputChangeHandler = ({ setInputValue }) => (value) => {
  setInputValue(value);
};

const enhance = compose(
  withNamespaces(['validation']),
  withState('inputValue', 'setInputValue', ''),
  withHandlers({
    onInputChange: onInputChangeHandler,
  }),
);

export default enhance(AsyncAutoComplete);
