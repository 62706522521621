import {
  branch,
  compose, renderNothing, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';

import DeleteMaterial from './deleteMaterial';
import { uiSelectors, uiActions } from '../../../../state/ui';
import { materialsActions, materialsSelectors } from '../../../../state/materials';

const mapStateToProps = (state, { selectedMaterialId }) => ({
  isOpen: uiSelectors.getModal(state)('deleteMaterialModal'),
  material: materialsSelectors.getMaterial(state)(selectedMaterialId),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('deleteMaterialModal'),
  onDeleteMaterial: materialsActions.deleteMaterialRequest,
};

const onDeleteHandler = ({
  onDeleteMaterial, selectedMaterialId, onCloseModal,
}) => () => {
  onDeleteMaterial({
    materialId: selectedMaterialId,
  }, {
    callbacks: {
      success: onCloseModal,
    },
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ material }) => !material.id, renderNothing),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);

export default enhance(DeleteMaterial);
