import {
  compose, lifecycle, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import MaterialsList from './materialsList';
import { materialsActions, materialsSelectors } from '../../../../state/materials';
import preloaderWhileLoading from '../../../../utils/enchancers/preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';


const mapStateToProps = state => ({
  materials: materialsSelectors.getMaterialsResult(state),
  isPending: materialsSelectors.getPendingGetMaterials(state),
});

const mapDispatchToProps = ({
  getMaterials: materialsActions.getMaterialsRequest,
});

const changeActiveTab = ({ activeTab }) => (idTab) => {
  if (idTab === activeTab) {
    return {
      activeTab: 0,
    };
  }
  return {
    activeTab: idTab,
  };
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStateHandlers(
    () => ({ activeTab: 0 }), {
      changeActiveTabStateHandler: changeActiveTab,
    },
  ),
  lifecycle({
    componentDidMount() {
      const { getMaterials } = this.props;
      getMaterials();
    },
    componentDidUpdate(prevProps) {
      const {
        getMaterials, sortBy: { val: { order, sortBy } },
      } = this.props;
      if (this.props.sortBy !== prevProps.sortBy && prevProps.sortBy) {
        getMaterials({ sortBy, order });
      }
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    delay: 600,
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
);
export default enhance(MaterialsList);
